import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  Injector,
  INJECTOR,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Logger, Platform} from "core";
import {Attachment, createMessageId, Participant} from "messaging";
import {TranslateService} from "@ngx-translate/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ChatMessageTextEditorComponent} from "./chat-message-text-editor.component";
import {
  BasicContainerComponent,
  EmojiPicker,
  encodeUint8ArrayToBase64,
  enterLeaveAnimations,
  ResizeService
} from "shared";
import {ChatService} from "../../chat.service";
import {BehaviorSubject, combineLatest, Subscription, takeUntil} from "rxjs";
import {MediaRecording, MediaRecordingService, MediaService, MediaUploadOptions, MediaUploadRef} from "media";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  AudioRecordingAttachment,
  AudioRecordingAttachmentType,
  ChatMessageType,
  ChatTimelineMessage,
  MediaAttachment,
  MediaAttachmentType,
  MessageType
} from "../../store/models";
import {AuthenticationService} from "auth";
import {AttachmentComponentFactoryService} from "../attachment/attachment.component.factory.service";
import {AttachmentSectionContainer} from "../attachment/core/attachment.section.container";
import isEqual from "lodash/isEqual";

declare type ActionButtonSymbol = 'send'|'update'|'record'|'delete';
declare interface InternalTouchEvent {
  x: number;
  y: number;
  target:EventTarget;
  time:number;
}

@Component({
  selector: 'chat-message-editor',
  templateUrl: './chat-message-editor.component.html',
  styleUrls: ['./chat-message-editor.component.scss'],
  animations: [
    enterLeaveAnimations,
    trigger('replyVisibility', [
      state('visible', style({ height: '48px' })),
      state('hidden', style({ height: '0px', paddingTop:0, paddingBottom:0 })),
      transition('visible => hidden', animate(300)),
      transition('hidden => visible', animate(300))
    ]),
    trigger('attachmentVisibility', [
      state('visible1,visible2', style({ height: '{{height}}px' }), {params:{height:80}}),
      state('hidden', style({ height: '0px', paddingTop:0, paddingBottom:0 })),
      transition('visible1 => visible2, visible2 => visible1', animate(10000)),
      transition('* => *', animate(30000))
    ]),
    trigger('emojiVisibility', [
      state('visible', style({ height: '320px' })),
      state('hidden', style({ height: '0px', paddingTop:0, paddingBottom:0 })),
      transition('visible => hidden', animate(300)),
      transition('hidden => visible', animate(300))
    ]),
    trigger('uploadIndicatorVisibility', [
      state('visible', style({ height: '1px' })),
      state('hidden', style({ height: '0px' })),
      transition('visible => hidden', animate(100)),
      transition('hidden => visible', animate(100))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageEditorComponent extends BasicContainerComponent implements OnChanges {

  @ViewChild('attachmentsComposers', {read: AttachmentSectionContainer, static: true}) attachmentsComposers: AttachmentSectionContainer;
  @ViewChild('textEditor', { read: ChatMessageTextEditorComponent, static: true }) textEditor: ChatMessageTextEditorComponent;
  @ViewChild('emojiHost',  { read: ViewContainerRef }) emojiHost:ViewContainerRef;

  replyVisibility$ = new BehaviorSubject<'hidden'|'visible'>('hidden');
  attachmentVisibility$ = new BehaviorSubject<{value:'hidden'|'visible1'|'visible2',params:{height:number}}>({value:'hidden',params:{height:0}});
  emojiVisibility: 'hidden' | 'visible' = 'hidden';
  uploadIndicatorVisibility: 'hidden' | 'visible' = 'hidden';

  actionButtonSymbol:ActionButtonSymbol = undefined;
  actionButtonEnabled = false;
  showTypeSelector = true;

  @Input()
  draftMessageType = ChatMessageType;

  @Input()
  hasMessageTypeSelector = true;

  @Input()
  hasEmojiSelector = true;

  @Input()
  set hasTypeSelector(value:boolean) {
    this.showTypeSelector = value;
  }
  get hasTypeSelector():boolean {
    return this.showTypeSelector;
  }

  @Input()
  get conversationId(): string {
    return this._conversationId;
  }
  set conversationId(id:string) {
    //console.log("ChatMessageEditorComponent.conversationId",id);
    const updated = this._conversationId != id;
    if (updated) {
      this._conversationId = id;
      this._messageSubscription?.unsubscribe();
      this._messageSubscription =
        combineLatest([
          this.chatService.getReplyMessage$(id),
          this.chatService.getDraftMessage$(id)
        ]).pipe(
          takeUntil(this.onDestroy$),
          //tap(([replyMessage,draftMessage])=>console.log("XY.tap.replyMessage",replyMessage,"draftMessage",draftMessage)),
          //distinctUntilChanged((previous,current)=>_.isEqual(previous,current))
        ).subscribe(([replyMessage,draftMessage]) => {
          //console.log("UPDATE.DRAFT",{...draftMessage},"REPLY",{...replyMessage});
          //console.log("message.update.reply",{...replyMessage??{}},"draft",{...draftMessage??{}});
          this.replyMessage = replyMessage;
          this.draftMessage = draftMessage;
          // this.changeDetectorRef.detectChanges();
        });
      // shows/hides upload progress indicator....
      this.mediaUploadRefs$.next(this.mediaUploadRefs$.value);
    }
  }

  protected _conversationId;
  protected _messageSubscription:Subscription;
  protected _microphonePermission:PermissionState = undefined;
  protected _cameraPermission:PermissionState = undefined;

  public mediaUploadRefs$  = new BehaviorSubject<MediaUploadRef[]>([]);

  protected _replyMessage:ChatTimelineMessage;
  protected logger = new Logger('ChatMessageEditorComponent');

  public hasReplyMessage = false;
  get replyMessage():ChatTimelineMessage {
    return this._replyMessage;
  }
  set replyMessage(replyMessage) {
    //console.log("XY.set replyMessage",replyMessage,"updated",this._replyMessage!=replyMessage);
    if (this._replyMessage!==replyMessage) {
      this._replyMessage   = replyMessage ?? this._replyMessage;
      this.hasReplyMessage = !!replyMessage?.id;
      this.replyVisibility$.next(!!replyMessage?.id ? 'visible' : 'hidden');
      this.focus();
    }
    //console.log("set replyMessage.visibility",this.replyVisibility$.value);
    //console.log("reply",{...replyMessage??{}});
  }

  protected _draftMessage:ChatTimelineMessage;
  get draftMessage():ChatTimelineMessage {
    //console.log("draftMessage1:this._draftMessage",{...this._draftMessage},"conversationId",this.conversationId);
    if (!this._draftMessage || this._draftMessage.conversationId!=this.conversationId) {
      this.draftMessage = undefined;
    } else if (!!this.draftMessageType && this._draftMessage.type!=this.draftMessageType) {
      this._draftMessage.type = this.draftMessageType;
    }
    return this._draftMessage;
  }
  set draftMessage(draftMessage) {
    //console.log("set draftMessage",draftMessage);
    //console.trace("set draftMessage",draftMessage,"conversationId",this.conversationId);
    //console.log("XY.set draftMessage",{...draftMessage});
    if (!draftMessage || draftMessage.conversationId!=this.conversationId) {
      draftMessage = <ChatTimelineMessage>{
        type: this.draftMessageType ?? ChatMessageType,
        timeCreated: Date.now(),
        conversationId: this.conversationId,
        parentId: this.hasReplyMessage ? this.replyMessage.id : undefined,
        attachments: [],//this.getAttachments(),
        from: <Participant>{
          id: this.authenticationService.user?.id,
          name: this.authenticationService.user?.name
        },
        id: createMessageId()
      }
    }
    this._draftMessage = draftMessage;
    if (!!this.textEditor) {
      this.textEditor.value = draftMessage.bodyText || null;
    }
    if (!!this.attachmentsComposers &&
          this.attachmentsComposers.attachments!==draftMessage.attachments) {
      this.attachmentsComposers.attachments = draftMessage.attachments;
    }
    console.log("DRAFT_MESSAGE",this.draftMessage);
    this.updateActionButtonSymbol();
  }

  updateDraftMessageType(type:MessageType) {
    this.draftMessageType = type;
    this.draftMessage.type = type;
    this.draftMessage.timeCreated = Date.now();
    //console.log("updateDraftMessageType",type,{...this.draftMessage});
    this.chatService.setDraftMessage(this.conversationId,this.draftMessage);
  }

  updateActionButtonSymbol() {
    const draftMessage = this.draftMessage;
    const hasBody = !!draftMessage.bodyText;
    const hasAttachments = draftMessage.attachments?.length>0;
    const hasId = !!draftMessage?._id;
    const canRecord = this.isRecording ||
                      this._microphonePermission=='granted' ||
                      this._microphonePermission=='prompt';
    const canCancel = this.isRecording && this._mediaRecording?.recordingTime()==0;
    let symbol:ActionButtonSymbol = hasBody || hasAttachments || hasId
                                      ? hasId
                                        ? 'update'
                                        : 'send'
                                      : this.isRecording
                                          ? canCancel ? 'delete' : 'send'
                                          : canRecord ? 'record' : 'send';
    if (this.actionButtonSymbol != symbol) {
      this.actionButtonSymbol = symbol;
    }
    this.actionButtonEnabled = symbol=='record' || symbol=='delete' || (hasAttachments || hasBody || hasId) || (symbol=='send' && this.isRecording);
    // this.changeDetectorRef.detectChanges();
   console.log("updateActionButtonSymbol", {
     symbol,
     "actionButtonEnabled": this.actionButtonEnabled,
     draftMessage,
     hasBody,
     hasAttachments,
     hasId,
     isRecording: this.isRecording,
     canRecord,
     canCancel
    });
    setTimeout(() => this.changeDetectorRef.detectChanges());
    //console.log("ACTION",this.actionButtonEnabled,"symbol",symbol,"has",(hasAttachments || hasBody || hasId));
  }

  deletedAttachment(attachment:Attachment) {
    //console.log("DELETED ATTACHMENT",attachment,"\n",this.draftMessage?.attachments);
    const attachments = this.draftMessage?.attachments?.filter(a=>a!==attachment);
    if (this.draftMessage.bodyText?.length>0 || attachments.length>0) {
      this.draftMessage = {...this.draftMessage,attachments};
      this.chatService.setDraftMessage(this.conversationId,this.draftMessage);
    } else {
      this.chatService.setDraftMessage(this.conversationId,undefined);
    }
    //console.log("DELETE ATTACHMENT.done",this.draftMessage);
  }

  updatedAttachmentComposers() {
    //console.log("ATTACHMENTS.before",[...this.draftMessage.attachments??[]]);
    const height = this.draftMessage.attachments?.length > 0
                  ? this.attachmentsComposers?.elementRef?.nativeElement?.offsetHeight ?? 0
                  : 0;
    const containerElement = this.attachmentsComposers?.elementRef?.nativeElement?.parentElement;
    if (!!containerElement) {
      containerElement.style.height = height+'px';
    }
    //console.log("XY.height",height,this.attachmentsComposers?.elementRef?.nativeElement?.offsetHeight);
    //console.log("ATTACHMENTS.sections",this.attachmentsComposers.attachments);
    if (!isEqual(this.attachmentsComposers.attachments,this.draftMessage.attachments)) {
      console.log("EDITOR.ATTACHMENTS.UPDATED",this.attachmentsComposers.attachments);
      this.draftMessage = {...this.draftMessage,attachments:this.attachmentsComposers.attachments};
      //console.log("ATTACHMENTS.after",[...this.draftMessage.attachments??[]]);
      this.chatService.setDraftMessage(this.conversationId,this.draftMessage);
    }
  }

  _emojoPickerRef:ComponentRef<EmojiPicker>;
  _editorSubscription:Subscription;

  constructor(public chatService: ChatService,
              public dialog: MatDialog,
              public platform: Platform,
              public mediaService: MediaService,
              public mediaRecordingService: MediaRecordingService,
              public authenticationService:AuthenticationService,
              public attachmentService: AttachmentComponentFactoryService,
              public resizeService : ResizeService,
              public translateService : TranslateService,
              public changeDetectorRef: ChangeDetectorRef,
              public elementRef: ElementRef,
              private snackBar: MatSnackBar,
              @Inject(INJECTOR) public injector:Injector) {
    super();
    const uploadIds = new Set<string>();
    this.mediaUploadRefs$.subscribe(mediaUploadRefs=>{
      let visibility:'hidden'|'visible' = 'hidden';
      mediaUploadRefs.forEach(mediaUploadRef => {
        const uploadId = mediaUploadRef.uploadId;
        const options:MediaUploadOptions = mediaUploadRef.options;
        if (options?.media?.properties?.conversationId==this.conversationId) {
          visibility = 'visible';
        }
        if (!uploadIds.has(uploadId)) {
          uploadIds.add(uploadId);
          //console.log("UPLOADING.id",uploadId,"ref",mediaUploadRef);
          const onProgress = (progress) => {
            //console.log('PROGRESS [%]', { progress });
          };
          const onUploadProgress: any = (file, progress) => {
            //console.log('UPLOAD PROGRESS', { file, progress });
          };
          mediaUploadRef.uploadRef.on('progress', onProgress);
          mediaUploadRef.uploadRef.on('upload-progress', onUploadProgress);
          mediaUploadRef.complete
            .finally(()=> {
              mediaUploadRef.uploadRef.off('progress', onProgress);
              mediaUploadRef.uploadRef.off('upload-progress', onUploadProgress);
              uploadIds.delete(uploadId);
              this.mediaUploadRefs$.next(this.mediaUploadRefs$.value.filter(item=>item!==mediaUploadRef))
            })
            .then(mediaUploads=>{
              const medias = mediaUploads
                .filter(mediaUpload=>!!mediaUpload.media)
                .map(mediaUpload=>mediaUpload.media);
              //console.log("MEDIA_UPLOAD_DONE",mediaUploads);
              if (medias.length>0 && options?.media?.properties?.conversationId==this.conversationId) {
                //console.log("MEDIA_UPLOAD_DONE.sameConversation",this.conversationId,mediaUploads);
                const attachments = [...(this.draftMessage.attachments ?? []), ...medias.map(media=><MediaAttachment>{type:MediaAttachmentType,media:media})];
                this.draftMessage = {...this.draftMessage,attachments};
                this.chatService.setDraftMessage(this.conversationId,this.draftMessage);
                //console.log("MEDIA_UPLOAD_DONE.draft",this.draftMessage);
              }
            })
        }
      });
      if (this.uploadIndicatorVisibility!=visibility) {
        this.uploadIndicatorVisibility = visibility;
        // this.changeDetectorRef.detectChanges();
      }
    });
    this.mediaRecordingService.microphonePermission$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(permission=>{
        this._microphonePermission = permission;
        console.log("microphonePermission$", permission);
        this.updateActionButtonSymbol();
      });
    combineLatest([
      this.replyVisibility$,
      this.attachmentVisibility$
    ]).pipe(takeUntil(this.onDestroy$))
      .subscribe(([a,b])=>this.resizeService.triggerSync(300));
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.textEditor.value = this.draftMessage?.bodyText;
    this._editorSubscription = this.textEditor.changeEvent.subscribe(text => {
      text = text || null;
      let bodyText = this.draftMessage.bodyText || null;
      //console.log("EDIT.text",text,"bodyText",bodyText,"changed",bodyText!=text,"id||text",(!!this.draftMessage?._id || text?.length));
      if (bodyText!=text) {
        if (this.hasReplyMessage &&
           (this.replyMessage.id==this.draftMessage.id ||
            this.replyMessage.id==this.draftMessage.parentId)) {
          this.draftMessage.bodyText = text || undefined;
          this.chatService.setDraftMessage(this.conversationId,this.draftMessage);
        } else if (text?.length>0 || this.draftMessage.attachments?.length>0) {
          this.draftMessage.bodyText = text;
          this.draftMessage.timeCreated = Date.now();
          this.chatService.setDraftMessage(this.conversationId,this.draftMessage);
        } else { //if (!(this.mediaAttachments$.value.length>0)) {
          this.chatService.setDraftMessage(this.conversationId,undefined);
        }
      }
    });
  }

  ngOnAttach() {
  }

  ngOnDetach() {
  }

  ngOnDestroy() {
    this.deleteRecording(undefined);
    this._editorSubscription?.unsubscribe();
    this._messageSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  emojiClicked() : void {
    // this.emojiVisible = !this.emojiVisible;
    //console.log("this.emojiHost.length",this.emojiHost.length);
    if (!this._emojoPickerRef) {
      this._emojoPickerRef = EmojiPicker.create(this.emojiHost);
      this._emojoPickerRef.instance.emojiClick.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(event => {
        this.onEmojiSelect(event);
      });
    }
    this.emojiVisibility = this.emojiVisibility=='hidden' ? 'visible' : 'hidden';
    this.resizeService.triggerSync(300);
  }

  sendMessage(focus: boolean ,draftMessage?: ChatTimelineMessage): Promise<void> {
    draftMessage = draftMessage ?? this.draftMessage;
    console.log("SEND MESSAGE",draftMessage);
    if (draftMessage?.bodyText?.length > 0 || draftMessage.attachments?.length > 0) {
      const value = this.textEditor.value;
      //console.log("send clicked", value);
      const message:ChatTimelineMessage =
        draftMessage.id == this.replyMessage?.id ? draftMessage :
      !!draftMessage.parentId &&
        draftMessage.parentId == this.replyMessage?.id
        ? { ...draftMessage,
            id: createMessageId(),
            timeCreated: Date.now(),
            bodyText: value,
            persistent:true
          }
        : {
            id: createMessageId(),
            type: draftMessage?.type ?? this.draftMessageType ?? ChatMessageType,
            conversationId: this.conversationId,
            from: <Participant>{
              id: this.authenticationService.user?.id,
              name: this.authenticationService.user?.name
            },
            timeCreated: Date.now(),
            //attachments: this.getAttachments(),
            attachments: draftMessage?.attachments ?? [],
            bodyText: value,
            private: draftMessage?.private,
            persistent: true
          };
      delete message.draft;
      if (this.hasReplyMessage) {
        this.chatService.setReplyMessage(this.conversationId,undefined);
      }
      //console.log("SEND MESSAGE - set draft");
      return this.chatService.setDraftMessage(this.conversationId,undefined,true)
        .then(() => this.chatService.sendMessage(message))
        .then(() => {
          console.log("SEND MESSAGE - sent",message);
          if (focus) {
            this.textEditor.focus();
          }
          // reset the input later, so that the draft message gets killed when
          // the sent message already is set. so the conversation index position
          // does not change...
          window.setTimeout(() => {
            this.textEditor.reset();
            if (focus) {
              this.textEditor.focus();
            }
          }, 100);
        })
    }
    return Promise.resolve();
  }

  onEmojiSelect(event:any) {
    //console.log("emoji click",event);
    var selection = this.textEditor.selection;
    var value     = this.textEditor.value || '';
    if (selection) {
      const position = selection.start+event.emoji.native.length;
      //console.debug("value:",value,"selection",selection,"pre:",value.substring(0,selection.start),"post:"+value.substring(selection.end),"position",position);
      this.textEditor.value = value.substring(0,selection.start) +
        event.emoji.native +
        value.substring(selection.end);
      this.textEditor.selection = { start: position, end: position };
      //console.debug("selection:",this.textEditor.selection);
      this.focus();
    }
  }

  onTextareaFocus(event:any) : void {
    //console.log("onTextareaFocus",event);
  }

  onTextareaBlur(event:any) : void {
    //console.log("onTextareaBlur",event);
  }

  animationStart(event?: AnimationEvent|TransitionEvent) : void {
    //console.log("XY.ChatConversationContainerComponent.animationStart",event);
    this.resizeService.triggerSync();
  }

  animationDone(event?: AnimationEvent|TransitionEvent) : void {
    //console.log("XY.ChatConversationContainerComponent.animationDone",event);
    this.resizeService.triggerSync();
  }

  focus() : void {
    this.textEditor?.focus();
  }

  get selection() : { start:number, end:number } {
    return this.textEditor.selection;
  }

  set selection(selection : { start:number, end:number }) {
    this.textEditor.selection = selection;
  }

  get value() : string | null {
    return this.textEditor.value;
  }

  set value(value : string | null) {
    this.textEditor.value = value;
  }

  openActionSheet() {
    /*
        this.actionService
          .select(this.elementRef, [
              chatService.createChatMessageTypeAction(),
              chatService.createNoteMessageTypeAction(),
              chatService.createTaskAction({....config}),
              chatService.createPollAction()
              ......
              uploadService.createAction(UPLOAD_TYPE.IMAGE),
              uploadService.createAction(UPLOAD_TYPE.VIDEO),
              mediaService.createVideoLinkAction(),
              chatService.createLocationAction({...options like position or special interest spots....}),
              contactService.createSelectFromAddressBookAction(),
              ......
              mediaService.createDocumentAction(), // ?? maybe
            ])
          .subscribe(....
        // type actions (which attachments & quantity allowed) ... the current type is disabled...
        // attachment actions (limited to those allowed by actions)
        // -> poll editor is simple, each choice a textline (maybe even translated automatically)
        // -> task editor is simple and creates a todo
        //    ... but could be more complex ...
        //    ... due at, involve or transfer to others,
        //    ... subtask (just swipes the editor to new one... after ok -> back)
        or (i guess not that good as all in one sheet):
        this.actionService
          .select(this.elementRef, [
              chatService.createChatMessageTypeAction(),
              chatService.createNoteMessageTypeAction(),
              taskService.createTaskAction({....config}),
              pollService.createPollAction()
            ])
          .subscribe(....
     */
    if (!this.platform.is('hybrid') && !this.platform.is('phone')) {
      this.focus();
    }
    const options:MediaUploadOptions = { media: {
        tags:       [ 'conversation', 'draft' ],
        properties: { 'conversationId': this.conversationId }
    }};
    this.mediaService
      .uploadMedia(this.elementRef, options)
      .subscribe(result => {
        if (!!result) {
          //console.log("MEDIA_UPLOAD_REF",result);
          this.mediaUploadRefs$.next([...this.mediaUploadRefs$.value,result])
        }
        if (!result) {
          this.translateService.get('media.uploadResult.mediaError').subscribe((translatedMessage) => {
            this.snackBar.open(translatedMessage, this.translateService.instant('actions.close'), {
              duration: 2000,
              horizontalPosition: 'right',
              // verticalPosition: 'bottom'
            });
          })
        }
      });
  }

  /*
   * RECORDING UI EVENTS
   */

  pressRecording:boolean = undefined;
  recordingTime:string = undefined;

  protected _currentEvent:InternalTouchEvent = undefined;
  protected _startRecordingEvent:InternalTouchEvent = undefined;
  protected _stopRecordingEvent:InternalTouchEvent = undefined;
  protected _mediaRecording:MediaRecording = undefined;
  protected _isDown:boolean = false;

  get isRecording(): boolean {
    return !!this._startRecordingEvent;
  }

  touchstart(event: TouchEvent) {
    console.log("touchStart",event,"touches",event.touches.length);
    event.preventDefault();
    if (event.touches.length==1) {
      const touch = event.touches[0];
      this.onDown({
        x:touch.clientX,
        y:touch.clientY,
        target:event.target,
        time:event.timeStamp,
      });
    }
  }
  touchmove(event: TouchEvent) {
    console.log("touchMove",event,"touches",event.touches.length);
    event.preventDefault();
    if (event.touches.length==1) {
      const touch = event.touches[0];
      this.onMove({
        x:touch.clientX,
        y:touch.clientY,
        target:event.target,
        time:event.timeStamp,
      });
    }
  }
  touchcancel(event: TouchEvent) {
    console.log("touchCancel",event,"touches",event.touches.length);
    event.preventDefault();
    if (this.isRecording) {
      //console.log("touchCancel",event,"touches",event.touches.length,"same",event.target===this._startRecordingEvent?.target);
      this.onLeave(this._startRecordingEvent);
    }
  }
  touchend(event: TouchEvent) {
    console.log("touchEnd",event,"touches",event.touches.length);
    event.preventDefault();
    if (!!this._currentEvent) {
      //console.log("touchEnd", event, "touches", event.touches.length, "same", event.target === this._startRecordingEvent?.target);
      this.onUp({...this._currentEvent,time:event.timeStamp});
    }
  }

  mousedown(event:MouseEvent) {
    console.log("mousedown",event);
    this.onDown({x:event.x,y:event.y,target:event.target,time:event.timeStamp});
  }
  mousemove(event:MouseEvent) {
    console.log("mousemove",event);
    this.onMove({x:event.x,y:event.y,target:event.target,time:event.timeStamp});
  }
  mouseleave(event:MouseEvent) {
    console.log("mouseleave",event);
    this.onLeave({x:event.x,y:event.y,target:event.target,time:event.timeStamp});
  }
  mouseup(event:MouseEvent) {
    console.log("mouseup",event);
    this.onUp({x:event.x,y:event.y,target:event.target,time:event.timeStamp});
  }

  onDown(event:InternalTouchEvent) {
    console.log("onDown",event);
    this._isDown = true;
    this._currentEvent = event;
    const permission = this._microphonePermission ?? 'denied';
    if (permission!='denied') {
      if (this.isRecording) {
        this._stopRecordingEvent = event;
      } else if (this.actionButtonSymbol=='record') {
        const element:HTMLElement = <HTMLElement>event.target;
        element.style.left = "-100px";
        element.style.top = element.style.bottom = element.style.right = "-10px";
        this._startRecordingEvent = event;
        this.recordingTime  = undefined;
        this.pressRecording = true;
        // this.changeDetectorRef.detectChanges();
        this.mediaRecordingService.record(true)
          .then(recording =>  {
            if (!!this._startRecordingEvent) {
              let moreThan1Second = false;
              this.recordingTime = "0:00";
              this.changeDetectorRef.detectChanges();
              this._mediaRecording = recording;
              this._mediaRecording.recordingTime$.subscribe(secondsTotal => {
                //console.log("SECONDS.TOTAL",secondsTotal);
                const minutes = Math.floor(secondsTotal/60);
                const seconds = secondsTotal % 60;
                this.recordingTime = minutes.toString()+':'+seconds.toString().padStart(2,'0');
                if (!moreThan1Second && secondsTotal>=1) {
                  moreThan1Second = true;
                  this.actionButtonSymbol = 'send';
                  this.updateActionButtonSymbol();
                } else {
                  this.changeDetectorRef.detectChanges();
                }
              });
            } else {
              recording?.stop();
            }
          })
          .catch(error => {
            this.logger.error('Failed to record audio', error);
            this._startRecordingEvent = undefined;
            this.pressRecording = undefined;
            this.updateActionButtonSymbol();
          });
      }
    }
  }
  onMove(event:InternalTouchEvent) {
    console.log("onMove",event);
    if (this._isDown) {
      this._currentEvent = event;
      //console.log("onMove.isDown", {event, startRecordingEvent: this._startRecordingEvent, pressRecording: this.pressRecording});
      if (!!this._startRecordingEvent && this.pressRecording) {
        //console.log("onMove.isDown", {event, startRecordingEvent: this._startRecordingEvent, pressRecording: this.pressRecording});
        const diffX = Math.abs(event.x - this._startRecordingEvent.x);
        const diffY = Math.abs(event.y - this._startRecordingEvent.y);
        const time = event.time-this._startRecordingEvent.time;
        //console.log("onMove.isDown.2", event, { diffX, diffY,time });
        if (diffX>100 || diffY>30) {
          //console.log("onMove.deleteRecording", event);
          this.deleteRecording(event);
        }
      }
    }
  }
  onLeave(event:InternalTouchEvent) {
    console.log("onLeave",event);
    this._currentEvent = undefined;
    this._isDown = false;
    if (!!this._stopRecordingEvent) {
      //console.log("cancelClick",event);
      this._stopRecordingEvent = undefined;
      this.resetRecordingButton(event);
    } else if (this.isRecording && this.pressRecording) {
      //console.log("finishRecording",event);
      this.deleteRecording(event);
    } else {
      this.updateActionButtonSymbol();
    }
  }
  onUp(event:InternalTouchEvent) {
    this._currentEvent = event;
    console.log("onUp", {
      isDown: this._isDown,
      stopRecording: !!this._stopRecordingEvent,
      startRecording: !!this._startRecordingEvent,
      pressRecording: this.pressRecording,
      actionButtonEnabled: this.actionButtonEnabled
    });
    if (this._isDown) {
      this._isDown = false;
      if (!!this._stopRecordingEvent) {
        if (this.actionButtonSymbol=='delete') {
          //console.log("onUp.deleteRecording");
          this.deleteRecording(event);
        } else {
          //console.log("onUp.sendRecording");
          this.sendRecording(event);
        }
      } else if (!!this._startRecordingEvent && this.pressRecording) {
        //console.log("onUp.startPressRecording");
        const diffX = Math.abs(event.x - this._startRecordingEvent.x);
        const diffY = Math.abs(event.y - this._startRecordingEvent.y);
        const time = event.time-this._startRecordingEvent.time;
        if (time<300) {
          //console.log("clicked",event,"diffX",diffX,"diffY",diffY,"time",time);
          this.pressRecording = false;
          this.actionButtonSymbol = 'delete';
          this.resetRecordingButton(event);
          // this.changeDetectorRef.detectChanges();
        } else if (this._mediaRecording?.recordingTime()>0) {
          //console.log("move",event,"diffX",diffX,"diffY",diffY,"time",time);
          this.sendRecording(event);
        } else {
          this.deleteRecording(event);
        }
      } else if (!!this._startRecordingEvent) {
        //console.log("onUp._startRecordingEvent -> deleteRecording");
        // we still wait on recorder to initialize....
        this.deleteRecording(event);
      } else if (this.actionButtonEnabled) {
        //console.log("onUp.actionButtonEnabled -> sendMessage");
        this.sendMessage(false).then(() => this.resetRecordingButton())
      }
    }
  }

  sendRecording(event: InternalTouchEvent) {
    //console.log('sendRecording', event);
    this.resetRecordingButton(event);
    this.pressRecording = undefined;
    this._startRecordingEvent = this._stopRecordingEvent = undefined;
    const recording = this._mediaRecording;
    const mediaService = this.mediaService;
    const resetRecording = () => {
      //console.log('sendRecording -> resetRecording')
      this._mediaRecording = undefined;
      this.resetRecordingButton(event);
      // this.updateActionButtonSymbol();
    }
    if (!!recording) {
      //console.log('sendRecording -> stop');
      recording.stop()
        .then(blobs => {
          //console.log('sendRecording -> stoped');
          let sendResolve, sendReject;
          let promise = new Promise((resolve, reject) => { sendResolve = resolve; sendReject = reject });
          if (blobs?.length) {
            //console.log('RECORDED_BLOBS',blobs.length,blobs);
            const blob  =  blobs.length==1 ? blobs[0] : new Blob(blobs);
            const reader = new FileReader();
            reader.onloadend = function() {
              reader.onloadend = undefined;
              const audioData = <string>reader.result;
              //console.log('RECORDED_BASE64', audioData.length);
              mediaService.getAudioAnalysis$(audioData)
                .then(audioAnalysis => {
                  //console.log('AudioAnalysis', audioAnalysis);
                  const attachment:AudioRecordingAttachment = {
                    type: AudioRecordingAttachmentType,
                    data: audioData,
                    wave: encodeUint8ArrayToBase64(audioAnalysis.wave(200)),  // 200 points
                    duration: audioAnalysis.duration(),
                  };
                  //console.log("PREPARE SEND MESSAGE", blob);
                  const attachments = [...(this.draftMessage.attachments ?? []), attachment];
                  const focus = !this.platform.is('hybrid') && !this.platform.is('phone');
                  return this.sendMessage(focus,{...this.draftMessage, attachments})
                })
                .then(sendResolve)
                .catch(sendReject)
            }.bind(this);
            reader.readAsDataURL(blob);
          } else {
            sendReject("empty recorded media")
          }
          return promise;
        })
        .then(() => console.log("Recording done!"))
        .catch(error => {
          console.error("failed recording!", error);
        })
        .finally(resetRecording)
    } else {
      resetRecording();
    }
  }

  deleteRecording(event?:InternalTouchEvent) {
    //console.log("deleteRecording", event);
    if (!!this._startRecordingEvent) {
      this._mediaRecording?.stop().then(() => {
        //console.log("deleteRecording.2", event);
        this._startRecordingEvent = this._stopRecordingEvent = undefined;
        this.pressRecording = undefined;
        this._mediaRecording = undefined;
        this.resetRecordingButton(event);
      });
    }
  }

  protected resetRecordingButton(event?:InternalTouchEvent) {
    //console.log("reset",this._startRecordingEvent);
    if (!!this._startRecordingEvent) {
      const element:HTMLElement = <HTMLElement>this._startRecordingEvent.target;
      element.setAttribute("style","");
      //console.log("reset",element);
    }
    this.updateActionButtonSymbol();
  }

  // END OF RECORDING UI EVENTS

  onDrop(files:FileList) {
    const options:MediaUploadOptions = { media: {
        tags:       [ 'conversation', 'draft' ],
        properties: { 'conversationId': this.conversationId }
      }};
    this.mediaService.uploadMediaFiles(files, options).subscribe(mediaUploadRef => {
      const { uploadId, uploadRef, complete } = mediaUploadRef;
      this.mediaUploadRefs$.next([...this.mediaUploadRefs$.value, mediaUploadRef]);
      // complete.then(mediaUploads => {
      //   this.logger.debug('onDrop -> uploadMediaFiles -> complete', { uploadId, mediaUploads });
      //   const error = mediaUploads.find(mediaUpload => mediaUpload.error || !mediaUpload.media);
      //   if (error) {
      //     this.logger.debug('onDrop -> uploadMediaFiles -> error', { uploadId });
      //   }
      // });
    });
    this.focus();
  }
}
