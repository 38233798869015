import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {BasicContainerComponent, TopicDisplayInfo, TopicSelectionListComponent} from "shared";
import {PropertiesService} from "properties";
import {Topic} from "core";

@Component({
  selector: 'app-interests',
  templateUrl: './interests.component.html',
  styleUrls: ['./interests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterestsComponent extends BasicContainerComponent {

  DEFAULT_TOPIC_DISPLAY_INFO: (topic: Topic) => TopicDisplayInfo = (topic) => {
    const isSurveyTopic = topic.type=='survey';
    return {
      selectable: !isSurveyTopic,
      maxLevel: isSurveyTopic ? 0 : undefined,
      template: isSurveyTopic && this.readOnly ? this.surveyTemplate : undefined,
    }
  };

  @Input() interests$;
  @Input() readOnly = false;
  @Input() set topicDisplayInfo (topicDisplayInfo: (topic: Topic) => Partial<TopicDisplayInfo>)  {
    this._topicDisplayInfo = (topic: Topic) => {
      return {...this.DEFAULT_TOPIC_DISPLAY_INFO(topic), ...topicDisplayInfo(topic)};
    }
  };
  get topicDisplayInfo(): (topic: Topic) => TopicDisplayInfo {
    return this._topicDisplayInfo;
  }

  @Output() selectionChanged = new EventEmitter<[Topic, string[]]>();
  @ViewChild(TopicSelectionListComponent, { static: true }) topicSelectionList: TopicSelectionListComponent;
  @ViewChild('surveyTemplate', { static: true }) surveyTemplate: TemplateRef<any>;
  @ContentChild("actionsTemplate", { static: true }) actionsTemplateRef: TemplateRef<any>;
  @ContentChild("badgeTemplate", { static: true }) badgeTemplateRef: TemplateRef<any>;

  protected _topicDisplayInfo: (topic: Topic) => TopicDisplayInfo = this.DEFAULT_TOPIC_DISPLAY_INFO;

  constructor(public propertiesService: PropertiesService) {
    super();
    this.interests$ = this.propertiesService.interests$;
  }

  @Input()
  set selectedTags(tags: string[]) {
    this.topicSelectionList.selectedTags = tags;
  }

  get selectedTags(): string[] {
    return this.topicSelectionList.selectedTags;
  }
}
