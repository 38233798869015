/* angular:styles/component:css;af223b2a76dae7e005ce720736f64a8443504aaf98d6b0fc1d07c64cc4d3c0fc;/Users/mbratoev/Project/fbgroup/client.ng/projects/chat/src/lib/components/message-delivery-state/message-delivery-state.component.ts */
:host {
  display: inline-flex;
  vertical-align: top;
}
:host.invalid {
  display: none;
}
mat-icon {
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  display: inline-flex;
}
mat-icon.viewed {
  color: orangered;
}
:host-context(.color-scheme-dark) mat-icon {
}
/*# sourceMappingURL=message-delivery-state.component-NIC4MXYN.css.map */
