<button #button mat-mini-fab class="action mat-elevation-z0"
        (click)="onButtonClicked()"
        [disabled]="!!(playback?.stalled$() | async)"
        [ngSwitch]="playing">
  <mat-icon *ngSwitchCase="true">pause</mat-icon>
  <mat-icon *ngSwitchCase="false">play_arrow</mat-icon>
</button>
<div class="wave" (click)="onWaveClicked($event)" (onResizeAsync)="onResize()">
  <canvas #wave></canvas>
</div>
<div class="time" *ngIf="!!durationFormatted">
  <span [innerHTML]="'chat.recording.duration' | translate:{duration:durationFormatted} | safeHtml"></span>
</div>

