<virtual-section-scroller #scroller
                          [items$]="entities$"
                          [itemsId$]="cacheId$"
                          [log]="false"
                          [startFromTop]="true"
                          [itemDefaultSize]="itemMinSize?itemMinSize:itemDefaultSize"
                          [startGap]="startGap"
                          [endGap]="mainButtonSpace ? 100 : endGap"
                          (onResize)="onResize($event)">
  <div class="contact-item-container" *virtualFor="
                              let entity of viewportEntities(scroller.viewportItems)
                                offset scroller.viewportOffset
                                total scroller.items?.length;
                                let index = index;
                                let total = total;
                                let last  = last;
                                trackBy: trackContact"
                              onDrop
                              [dropForwarding]="{trigger:true,timeout:2000,callback:dropForwardCallback(entity)}">
    <ng-container *ngTemplateOutlet="!entity?.timeDeleted ? contactListItemTemplateRef : deletedListItemTemplateRef; context: { entity, contact: entity | safeContact, index, total, last, disabled }">
    </ng-container>
  </div>
</virtual-section-scroller>

<ng-template #contactListItemTemplate
             let-entity="entity"
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last"
             let-disabled="disabled">
  <div #item hover class="contact-item" [class.disabled]="disabled" (click)="select(index, contact, $event)">
    <div *ngIf="!disabled" matRipple [matRippleTrigger]="item"></div>
    <ng-container *ngIf="{ entity, contact, index, total, last } as context">
      <ng-container *ngTemplateOutlet="avatarContainerTemplateRef;   context: context"></ng-container>
      <ng-container *ngTemplateOutlet="contentContainerTemplateRef;  context: context"></ng-container>
      <ng-container *ngTemplateOutlet="controlsContainerTemplateRef; context: context"></ng-container>
      <ng-container *ngTemplateOutlet="avatarChromeTemplateRef;      context: context"></ng-container>
      <mat-divider *ngIf="!last" [style.marginLeft]="dividerLeftMargin ? dividerLeftMargin+'px' : undefined"></mat-divider>
    </ng-container>
  </div>
</ng-template>

<ng-template #deletedListItemTemplate
             let-entity="entity"
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last"
             let-disabled="disabled">
  <div class="deleted" [class.disabled]="disabled">
    <h4 class="contact">{{ 'results.deleted' | translate: { name: contact?.name ?? '?' } }}</h4>
    <mat-divider *ngIf="!last" [style.marginLeft]="dividerLeftMargin ? dividerLeftMargin+'px' : undefined"></mat-divider>
  </div>
</ng-template>

<ng-template #contentContainerTemplate
             let-entity="entity"
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last">
  <div class="content" [style.minHeight]="itemMinSize ? itemMinSize+'px' : undefined">
    <div class="contact">
      <div>
        <ng-container *ngTemplateOutlet="contactNameTemplateRef; context: { entity, contact, index, total, last }"></ng-container>
        <ng-container *ngTemplateOutlet="contactInfoTemplateRef; context: { entity, contact, index, total, last }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contactNameTemplate
             let-entity="entity"
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last">
  <h3>
    <span>{{contact.name}}</span>
    <!-- <a *ngIf="!context.noNameLink" href="/facebook/contact/{{contact.id}}" target="_blank">{{contact.name}}</a>
    <span *ngIf="context.noNameLink">{{contact.name}}</span> -->
  </h3>
</ng-template>

<ng-template #contactInfoTemplate
             let-entity="entity"
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last">
  <app-contact-join-info *ngIf="!renderingHints.noJoinDetails" [contact]="contact" [renderingHints]="renderingHints"></app-contact-join-info>
</ng-template>

<ng-template #controlsContainerTemplate
             let-entity="entity"
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last">
  <div class="controls">
    <ng-container *ngTemplateOutlet="controlsTemplateRef; context: { entity, contact, index, total, last }"></ng-container>
  </div>
</ng-template>

<ng-template #avatarContainerTemplate
             let-entity="entity"
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last">
  <div class="avatar">
    <ng-container *ngTemplateOutlet="avatarTemplateRef; context: {
        entity,
        contact,
        index,
        total,
        last,
        src: getAvatarUrl(contact) | sessionLink,
        width: avatarSize?avatarSize+'px':undefined,
        height: avatarSize?avatarSize+'px':undefined }">
    </ng-container>
  </div>
</ng-template>

<ng-template #avatarTemplate
             let-entity="entity"
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last"
             let-src="src"
             let-width="width"
             let-height="height">
  <img matListItemAvatar
       [src]="src"
       [style.width]="width"
       [style.height]="height"
       onload="this.style.opacity=1"/>
</ng-template>
