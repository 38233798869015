<div (swipedown)="close($event)" (click)="onTap($event)" (onResize)="onResize($event)">
  <conference-participant #conferenceParticipant class="full" *ngIf="arrange()=='full' || arrange()=='side'"
                          [participant]="focusParticipant()"
                          [mediaStream]="(focusParticipant()?.screen ?
                                conference()?.getScreenStream$(focusParticipant()?.id) :
                                conference()?.getMediaStream$(focusParticipant()?.id,true)) | async"
                          [conferenceInfo]="conferenceInfo()"
                          [showName]="false"
                          [showMedia]="focusParticipant()?.screen || focusParticipant()?.video"
                          [mirror]="focusParticipant()?.id==conferenceService.userId && !focusParticipant()?.screen">
  </conference-participant>
  <div class="side" *ngIf="arrange()=='side'">
    <ng-container *ngFor="let participant of unfocusParticipants(); let index=index; trackBy: trackByParticipant">
      <conference-participant *ngIf="index<3 "
                              [participant]="participant"
                              [mediaStream]="(participant.screen ?
                                              conference()?.getScreenStream$(participant?.id) :
                                              conference()?.getMediaStream$(participant?.id,true)) | async"
                              [conferenceInfo]="conferenceInfo()"
                              [showName]="true"
                              [showMedia]="participant.screen || participant.video"
                              [mirror]="participant.id==conferenceService.userId && !participant.screen"
                              (click)="setFocusParticipant(participant,$event)">
      </conference-participant>
    </ng-container>
  </div>
  <div class="grid" *ngIf="arrange()=='grid'" (onResizeAsync)="onGridResize($event)">
    <ng-container *ngIf="gridLayout() as gridLayout">
      <ng-container *ngFor="let participant of allParticipants(); let index=index; trackBy: trackByParticipant">
        <conference-participant *ngIf="index<(gridLayout.columns*gridLayout.rows)"
                                [focus]="participant===focusParticipant()"
                                [participant]="participant"
                                [mediaStream]="(participant.screen ?
                                              conference()?.getScreenStream$(participant?.id) :
                                              conference()?.getMediaStream$(participant?.id,true)) | async"
                                [conferenceInfo]="conferenceInfo()"
                                [showName]="true"
                                [showMedia]="participant.screen || participant.video"
                                [mirror]="participant.id==conferenceService.userId && !focusParticipant()?.screen"
                                [style.width]="gridLayout.columnsPercent"
                                [style.height]="gridLayout.rowsPercent"
                                (click)="setFocusParticipant(participant,$event)">
        </conference-participant>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="controls() && !!conversationName()" class="conversationName"
       (click)="$event.stopPropagation()"
       [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '80px' }}">
    <span translate>{{conversationName()}}</span>
  </div>
  <div class="controls" *ngIf="controls()"
       [class.buttons]="!addParticipant() && !videoInputChange() && !audioOutputChange()"
       (click)="$event.stopPropagation()"
       [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '120px' }}">

    <div class="name" *ngIf="!!focusParticipant()?.name">
      <div>
        <span>
          <conference-participant-indicator [participant]="focusParticipant()"
                                            [conferenceInfo]="conferenceInfo()">
          </conference-participant-indicator>
          <span>{{focusParticipant()?.name}}</span>
        </span>
      </div>
    </div>

    <mat-icon *ngIf="addParticipant()" class="cancel_add_participant" (click)="cancelAddParticipant()">close</mat-icon>

    <div #controls_content class="controls_content" *ngLet="controlsContentSize() as size"
         [style.width]="size.set && size.width>0 ? size.width+'px' : null"
         [style.height]="size.set && size.height>0 ? size.height+'px' : null"
         [class.absolute]="!!size.set"
         (transitionend)="onDone($event)">

      <div class="video_input_container" *ngIf="videoInputChange()"
           (onAttach)="onAttachContent($event)"
           [class.current]="videoInputChange()"
           [@enterLeaveOpacityAnimation]="{ value:':enter,:leave'}">
        <div class="back" (click)="cancelVideoInputChange()"><mat-icon>arrow_back</mat-icon></div>
        <div class="media_device_list">
          <div *ngFor="let device of videoInputDevices()"
               (click)="onVideoInputChange(conference(), device.deviceId)"
               class="media_device">
            {{device.label}}
          </div>
        </div>
      </div>

      <div class="add_participant_container" *ngIf="addParticipant()"
           (onAttach)="onAttachContent($event)"
           [class.current]="addParticipant()"
           [@enterLeaveOpacityAnimation]="{ value:':enter,:leave'}">
        <app-contact-list [entities$]="contacts$" [cacheId$]="cacheId$"
                          [contactInfoTemplateRef]="contactInfoTemplate"
                          [itemMinSize]="60" [avatarSize]="50" [dividerLeftMargin]="74"
                          (selectionChanged)="onAddParticipant($event)">
          <ng-template #contactInfoTemplate
                       let-contact="contact"
                       let-index="index"
                       let-total="total"
                       let-last="last">
            <presence-info *ngIf="contact?.id" [contactId]="contact.id"></presence-info>
          </ng-template>
        </app-contact-list>
        <app-search-bar [leftButton]="false" [rightButton]="false" [search]="true"
                        [value]="contactService.searchTerm$ | async"
                        (onSearchTermChange)="contactService.updateSearchTerm($event)">
        </app-search-bar>
      </div>

      <conference-bar *ngIf="!addParticipant() &&
                             !videoInputChange() &&
                             !audioOutputChange()"
                      [conferenceInfo]="conferenceInfo()"
                      (onAttach)="onAttachContent($event)"
                      [maxColumns]="4"
                      [class.current]="!addParticipant()"
                      [@enterLeaveOpacityAnimation]="{ value:':enter,:leave'}">

        <ng-container [ngSwitch]="more()">

          <div *ngSwitchCase="false">
            <button mat-icon-button (click)="showMore()">
              <mat-icon>expand_more</mat-icon>
            </button>
            <span translate>actions.more</span>
          </div>

          <ng-container *ngSwitchCase="true" [ngSwitch]="videoInputDevices()?.length>1">

            <ng-container *ngSwitchCase="true"
                          [ngSwitch]="platform.is('mobile') || videoInputDevices()?.length==2">
              <div *ngSwitchCase="true">
                <button mat-icon-button (click)="toggleVideoFacingMode(conference())">
                  <mat-icon>cameraswitch</mat-icon>
                </button>
                <span translate>actions.videoInputChange</span>
              </div>

              <div *ngSwitchCase="false">
                <button mat-icon-button (click)="startVideoInputChange()">
                  <mat-icon>video_camera_front</mat-icon>
                </button>
                <span translate>actions.videoInputChange</span>
              </div>
            </ng-container>
            <div *ngSwitchCase="false">
              <button mat-icon-button [matMenuTriggerFor]="settingsMenu" disabled="true">
                <mat-icon>settings</mat-icon>
              </button>
              <span translate>actions.settings</span>
              <mat-menu #settingsMenu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="group1Menu">GROUP 1</button>
                <button mat-menu-item [matMenuTriggerFor]="group2Menu">GROUP 2</button>
              </mat-menu>
              <mat-menu #group1Menu="matMenu">
                <button mat-menu-item>GR1 -> ITEM 1</button>
                <button mat-menu-item>GR1 -> ITEM 2</button>
                <button mat-menu-item>GR1 -> ITEM 3</button>
              </mat-menu>
              <mat-menu #group2Menu="matMenu">
                <button mat-menu-item>GR2 -> ITEM 1</button>
                <button mat-menu-item>GR2 -> ITEM 2</button>
                <button mat-menu-item>GR2 -> ITEM 3</button>
              </mat-menu>
            </div>

            <div>
              <button mat-icon-button (click)="startAddParticipant()">
                <mat-icon>add</mat-icon>
              </button>
              <span translate>actions.add</span>
            </div>

            <div>
              <button  #shareButton
                       [disabled]="!canShareScreen(conferenceId())"
                       mat-icon-button
                       [class.selected]="screenShared()"
                       (click)="shareScreen(conferenceId(),shareButton)">
                <mat-icon>ios_share</mat-icon>
              </button>
              <span translate>actions.share</span>
            </div>

            <div>
              <button mat-icon-button (click)="arrangeView()" *ngLet="arrange() as arrange">
                <mat-icon *ngIf="arrange=='full'" style="transform: scale(1.12);">account_box</mat-icon>
                <!--
                <mat-icon *ngIf="mode=='full'">crop_din</mat-icon>
                <mat-icon *ngIf="mode=='full'">fullscreen</mat-icon>
                -->
                <mat-icon *ngIf="arrange=='shared'" style="transform: scale(1.12);">slideshow</mat-icon>
                <mat-icon *ngIf="arrange=='grid'">grid_on</mat-icon>
                <mat-icon *ngIf="arrange=='side'"  style="transform: scale(1.12);"fontSet="material-icons-outlined">view_sidebar</mat-icon>
              </button>
              <span translate>actions.arrange</span>
            </div>

            <div *ngSwitchCase="false">
              <button mat-icon-button (click)="showMore(false)">
                <mat-icon>expand_less</mat-icon>
              </button>
              <span translate>actions.less</span>
            </div>

          </ng-container>

        </ng-container>
      </conference-bar>
    </div>
    <div class="close" (click)="close($event)"><mat-icon>expand_more</mat-icon></div>
  </div>
</div>
