import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {PropertiesService} from "properties";
import {map, tap} from "rxjs/operators";
import {Badge, isValidNumber} from "shared";
import {ChatService} from "chat";
import {APP_VERSION_UPDATE} from "common/lib/app.token";

@Injectable({
  providedIn: 'root'
})
export class BadgeService  {

  protected _appVersionBadge$ = new BehaviorSubject<Badge>(undefined);
  protected _profileBadge$  = new BehaviorSubject<Badge>(undefined);
  protected _surveysBadges$ = new BehaviorSubject<{[id:string]:Badge}>(undefined);
  protected _settingsBadge$ = new BehaviorSubject<Badge>(undefined);

  constructor(protected propertiesService: PropertiesService,
              protected chatService: ChatService,
              @Inject(APP_VERSION_UPDATE)
              protected appVersionUpdate$: Observable<string>) {
    console.log('BadgeService.ctor()');
    this.appVersionUpdate$.subscribe((version) => {
      this._appVersionBadge$.next({ label: version });
    });
    this.propertiesService.properties$.subscribe(properties => {
      //console.log("XY.properties",properties);
      const group = properties?.group;
      const user  = properties?.user;
      const tags  = properties?.user?.interestTags;
      this._profileBadge$.next((!!user && !user.memberId)
                         ? { label: '!' } // { icon: 'warning' }
                         : undefined);

      this._surveysBadges$.next(
        user?.app?.interests?.filter(topic=>{
          return topic.type=='survey' && !topic.tags?.includes('optional')
        }).filter(topic=>{
          const done = (tags?.includes(`interest.${topic?.id}.survey`));
          return !done;
        }).reduce((prev,topic)=> {
            prev[topic.id] = { label: '!' };
            return prev;
          },{})
        || {});
    });
    combineLatest([
      this._appVersionBadge$,
      this._profileBadge$,
      this._surveysBadges$
    ]).subscribe(([appVersionBadge, profileBadge,surveysBadge])=>{
      this._settingsBadge$.next(
        appVersionBadge ||
              profileBadge ||
              surveysBadge &&
              Object.keys(surveysBadge).length>0
        ? { label: '!' } : undefined);
    });
  }

  public get appVersionBadge$(): Observable<Badge> {
    return this._appVersionBadge$;
  }

  public get profileBadge$(): Observable<Badge> {
    return this._profileBadge$;
  }

  public get settingsBadge$(): Observable<Badge> {
    return this._settingsBadge$;
  }

  public get surveysBadge$(): Observable<Badge> {
    return this._surveysBadges$.pipe(
      map(surveys=>Object.keys(surveys).length>0 ? { label: '!' } : undefined)
    );
  }

  public surveyBadge$(id:string): Observable<Badge> {
    return this._surveysBadges$.pipe(
      //tap(surveys=>console.log("XY.surveyBadge$.1",id,surveys)),
      map(surveys=>surveys[id]) //,
      //tap(badge=>console.log("XY.surveyBadge$",id,badge))
    );
  }

  public get chatBadge$(): Observable<Badge> {
    return this.chatService.unseen$.pipe(map(value =>
        isValidNumber(value) && value > 0 ? { label: value.toString() } : undefined
    ));
  }

  public get menuBadge$(): Observable<Badge> {
    return combineLatest([
      this.settingsBadge$,
      this.chatBadge$
    ]).pipe(
      map(([settingsBadge,chatBadge])=>{
        console.log("BADGES",settingsBadge,chatBadge);
        return settingsBadge ?? chatBadge;
      })/*,
      distinctUntilChanged((prev,curr) => {
        if (prev!=curr) {
          const type1 = badgeType(prev);
          const type2 = badgeType(curr);
          if (type1==type2) {
            return
          }
          return false;
        }
        return true;
      })*/
      ,tap(badge => console.log("BADGE",badge))
    );
  }
}
