<div class="ratio" *ngIf="!!heightPct && heightPct>0" [style.padding-top.%]="heightPct">
  <div class="container" *ngIf="tiles?.length>0">
    <ng-container *ngFor="let tile of tiles; let index = index; trackBy:trackBy">
      <div
        [style.left]="tile.left.style"
        [style.top]="tile.top.style"
        [style.width]="tile.width.style"
        [style.height]="tile.height.style">
        <div>
          <media-card #card
            [media]="tile.media"
            [rounded]="false"
            [elevated]="false"
            [selectable]="false"
            [indicator]="false"
            (play)="onPlayMedia(tile.media)">
          </media-card>
          <ng-container *ngIf="card.mediaDuration(tile.media); let duration;">
            <span class="badge control bottom right">{{duration}}</span>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="more" *ngIf="!!more"><a
  (click)="onMore($event)"
  [innerHTML]="'chat.message.more' | translate:{more:more} | safeHtml"></a></div>
