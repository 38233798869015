import {Injectable} from "@angular/core";
import {ActionsSubject, Store} from "@ngrx/store";
import * as fromChat from "./store/state";
import {initialPresence, selectPresenceIds} from "./store/state";
import {asyncScheduler, Observable, Subscription, tap} from "rxjs";
import {
  PresenceMessage,
  PresenceMessageType,
  PresenceState,
  PresenceSubscriptionMessage,
  PresenceSubscriptionMessageType
} from "./store/models";
import {AuthenticationService} from "auth";
import {MessageEnvelope, MessagingService} from "messaging";
import {debounceTime, distinctUntilChanged, finalize, map} from "rxjs/operators";
import {presenceSubscribeAction, presenceUnsubscribeAction, presenceUpdateDoneAction} from "./store/actions";
import isEqual from "lodash/isEqual";
import {StoreService} from "store";
import {Presence} from "core";

@Injectable({
  providedIn: 'root'
})
export class PresenceService extends StoreService {

  protected _messageHandlers = new Map<string,(MessageEnvelope)=>void>();
  protected presenceSubscription:Subscription = undefined;
  //protected presenceSubscriptionId:string = undefined;

  addMessageHandler(type:string,handler:(envelope:MessageEnvelope)=>void) {
    this._messageHandlers.set(type,handler);
  }

  constructor(protected store$ : Store<fromChat.State>,
              protected action$: ActionsSubject,
              protected authenticationService: AuthenticationService,
              protected messagingService: MessagingService) {
    super(store$,action$);
    console.log('PresenceService.ctor');
    // update store with open state and if open, send all pending messages...
    this.messagingService.open$.pipe().subscribe(open => {
      //console.log("OPENSTATE",open);
      this.presenceSubscription?.unsubscribe();
      this.presenceSubscription = undefined;
      if (open) {
        this.presenceSubscription = this.store$.select(selectPresenceIds)
          .pipe(
            debounceTime(30),
            distinctUntilChanged((keys1:string[],keys2:string[])=>isEqual(keys1,keys2))
          )
          .subscribe(keys => {
            //console.log("presence.service.subscribe",keys);
            const presenceSubscriptionMessage = this.messagingService.initializeMessage(<PresenceSubscriptionMessage>{
              //id: this.presenceSubscriptionId,
              type: PresenceSubscriptionMessageType,
              contactIds: keys ?? []
            });
            //this.presenceSubscriptionId = presenceSubscriptionMessage.id;
            this.messagingService.sendMessage(presenceSubscriptionMessage);
          });
      }
    });
    let handlePresenceMessages = (envelope:MessageEnvelope):void => {
      let message = <PresenceMessage>envelope.message;
      //console.log("presence.service.handlePresenceMessages",message);
      if (!!message && !!message.presence?.contactId) {
        this.store$.dispatch(presenceUpdateDoneAction({ presence: message.presence }));
      }
    };
    // chat...
    this.addMessageHandler(PresenceMessageType,handlePresenceMessages);
    this.messagingService
      .register(envelope => this._messageHandlers.has(envelope.message?.type))
      .subscribe(envelope => {
        let handler:(envelope:MessageEnvelope)=>void = this._messageHandlers.get(envelope.message.type);
        if (handler) {
          handler(envelope);
        }
      });
  }

  getPresence$(id:string, defaultPresence?:Presence):Observable<Presence> {
    //console.log("presenceService.getPresence$",id,defaultPresence);
    let subscribed = false;
    let ensureSubscription = (presence:Presence) => {
      //console.log("presence.service.getPresence$.subscribe",id,"subscribed",subscribed);
      if (!subscribed) {
        subscribed = true;
        //console.log("presence.service.getPresence$.X.subscribe",id);
        //console.log("presence.service.getAvatarPresence$.subscribe",id,"subscribed",subscribed,"presence",presence);
        //console.log("presence.service.getPresence$.subscribe",id);
        asyncScheduler.schedule(() => this.store$.dispatch(presenceSubscribeAction({ id })));
      }
      //return of(presence);
    }
    return this.store$.select(fromChat.selectPresence(id)).pipe(
      //tap(presence =>  console.log("presence.service.getAvatarPresence$.tap1",id,presence)),
      //tap(presence =>  console.log("presence.service.getAvatarPresence$.tap2",id,presence)),
      distinctUntilChanged((presence1:Presence,presence2:Presence)=>isEqual(presence1,presence2)),
      finalize(() => {
        //console.log("presence.service.getPresence$.finalize",id,"subscribed",subscribed);
        if (subscribed) {
          //console.log("presence.service.getPresence$.X.finalize",id);
          subscribed = false;
          window.setTimeout(()=>
              this.store$.dispatch(presenceUnsubscribeAction({ id })),
              1000);
        }
      }),
      tap(presence => ensureSubscription(presence)),
      map(presence => {
        if (!presence) {
          return defaultPresence ?? { ...initialPresence, contactId:id };
        } else if (presence.timeActive>0) {
          return presence;
        } else {
          return defaultPresence ?? presence;
        }
      }),
      //tap(presence =>  console.log("presence.service.getAvatarPresence$.tap3",id,presence)),
    );
  }

  setPresenceState(state:PresenceState) {
    this.messagingService.sendMessage(this.messagingService.initializeMessage(<PresenceMessage>{
      type: PresenceMessageType,
      from: {
        type: 'chat',
        id: this.authenticationService.user.id
      },
      presence: {
        state: state,
        contactId: this.authenticationService.user.id,
        timeSince: Date.now(),
        timeActive: Date.now()
      }
    }));
  }
}
