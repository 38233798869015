import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BasicContainerComponent, ConfirmDialogComponent} from "shared";
import {filter, map, switchMap, takeUntil} from "rxjs/operators";
import {Logger, Platform} from "core";
import {TranslateService} from "@ngx-translate/core";
import {Product} from "../models/product";
import {Tax} from "../models/tax";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable, of} from "rxjs";
import {Saving} from "../models/saving";
import {PaymentService} from "../service/payment.service";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent extends BasicContainerComponent {

  @Input() products: Observable<Product[]> = of([]);
  @Output() link = new EventEmitter<string>();

  savings: {[key: string]: Saving}; // savings by productId
  tax: Tax;

  activeProduct: Product;
  expiryTime: number;
  cancelTime: number;

  protected logger = new Logger('PaymentComponent');

  constructor(public paymentService: PaymentService,
              protected translateService: TranslateService,
              protected dialog: MatDialog,
              protected snackBar: MatSnackBar,
              public platform: Platform /*,
              protected changeDetector: ChangeDetectorRef*/) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.products
      .pipe(
        takeUntil(this.onDestroy$),
        filter(products => !! products),
        switchMap((products: Product[]) => {
          for (let product of products) {
            if (product.owned) {
              return this.paymentService
                .selectProduct(product.id)
                .pipe(
                  map((activeProduct) =>
                    [ products, activeProduct ]
                  )
                );
              }
            }
            return of ([products, []]);
        })
      )
      .subscribe(([products, activeProduct]: [Product[], [Product, number, number]]) => {
        this.logger.debug(products, activeProduct);
        const [product, expiryTime, cancelTime] = activeProduct;
        this.activeProduct = product;
        this.expiryTime = expiryTime;
        this.cancelTime = cancelTime;
        this.updateSavings(products, product);
      });

    // this.paymentService.productIds
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(productIds => {
    //     const selects: Observable<Product>[] = [];
    //     productIds.forEach((productId) => selects.push(this.paymentService.select(productId)));
    //     combineLatest(selects)
    //       .pipe(takeUntil(this.onDestroy$))
    //       .subscribe(products => {
    //         this.products = products;
    //         this.updateSavings();
    //       });
    //   });

    // this.paymentService.selectProduct(ProductSelector.REALIZER_PRO)
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(([product, expiryTime, cancelTime]) => {
    //     this.logger.debug('selectProduct', product, expiryTime, cancelTime);
    //     this.activeProduct = product;
    //     this.expiryTime = expiryTime;
    //     this.cancelTime = cancelTime;
    //     this.updateSavings();
    //     // this.changeDetector.markForCheck();
    //   });

    this.paymentService.getTax()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tax => this.tax = tax);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  subscribe(product: Product, event: Event) {
    this.paymentService.purchase(product.id).catch((error) => {
      this.logger.error('Subscription failed.', error);
    });
  }

  restorePurchases(event: Event) {
    this.paymentService.restorePurchases();
  }

  get terms(): string {
    let processor = 'stripe';
    if (this.platform.is('hybrid')) {
      if (this.platform.is('android')) {
        processor = 'android';
      } else if (this.platform.is('ios')) {
        processor = 'apple';
      }
    }
    return `purchase.terms.${processor}`;
  }

  onTapCancel(event: MouseEvent, product: Product = this.activeProduct) {
    this.dialog.open(ConfirmDialogComponent,{
      data: {
        label: 'purchase.confirmCancel',
        parameters: {}
      },
      closeOnNavigation: true
    }).afterClosed().subscribe(result => {
      if (result) {
        this.logger.debug("Cancel",product);
        this.paymentService.cancel(product.id)
          .then(() => this.showSnackBar('purchase.cancelSuccess'))
          .catch((error) => {
            this.logger.error('Failed to cancel the subscription', error, this.activeProduct);
            this.showSnackBar('purchase.cancelError');
          });
      }
    });
  }

  onTapLink(url: string) {
    this.link.emit(url);
  }

  updateSavings(products: Product[], activeProduct: any) {
    this.savings = {};
    if (this.products && products.length > 0) {
      let refProduct: Product = activeProduct;
      if (!refProduct) {
        const min = (products: Product[]): Product => {
          let len = products.length, min = Infinity, product: Product = undefined;
          while (len--) {
            const interval = this.paymentService.getInterval(products[len].id);
            if (interval && interval.value < min) {
              min = interval.value;
              product = products[len];
            }
          }
          return product;
        };
        refProduct = min(products);
      }
      if (refProduct) {
        products.forEach((product) => {
          const saving = this.paymentService.getSaving(product, refProduct);
          this.logger.debug('SAVING', product.id, saving);
          if (saving.percentage > 0) {
            this.savings[product.id] = saving;
          }
        });
        // this.logger.debug('SAVINGS', this.savings);
      }
    }
  }

  showSnackBar(message: string, action = 'actions.close') {
    this.translateService.get(message).subscribe((translatedMessage) => {
      this.snackBar.open(translatedMessage, this.translateService.instant(action), {
        duration: 5000,
        horizontalPosition: 'right',
        // verticalPosition: 'bottom'
      });
    })
  }
}
