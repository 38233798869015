import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, signal, ViewChild} from '@angular/core';
import {BasicContainerComponent, ResizeService} from "shared";
import {LayoutService} from "layout";
import {ChatMessageEditorComponent} from "../message-editor/chat-message-editor.component";
import {ChatConversationComponent} from "../conversation/chat-conversation.component";
import {ChatService} from "../../chat.service";
import {ConferenceService} from "conference";
import {BehaviorSubject, combineLatest, distinctUntilChanged, firstValueFrom, Observable, Subscription} from "rxjs";
import {map, switchMap, takeUntil, tap} from "rxjs/operators";
import {ConversationData} from "../../store/models";
import {
  ChatConversationDetailsContainerComponent,
  ChatConversationDetailsContext
} from "../conversation-details-container/chat-conversation-details-container.component";
import {Action} from "core";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'chat-conversation-container',
  templateUrl: './chat-conversation-container.component.html',
  styleUrls: ['./chat-conversation-container.component.scss'],
  host: {
    '[class.readonly]': 'readonly()'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationContainerComponent extends BasicContainerComponent {

  @ViewChild('chatMessageEditor', { read: ChatMessageEditorComponent }) chatMessageEditor: ChatMessageEditorComponent;
  @ViewChild('chatConversationComponent', { static: true, read: ChatConversationComponent }) chatConversationComponent: ChatConversationComponent;

  public readonly = signal<boolean>(false);

  public conversationId: string;
  public searchTerm : string;

  @Input() featuresVisible:boolean = false;

  public conversationData$:Observable<ConversationData>;
  public hasFeatures$ = new BehaviorSubject<boolean>(false);
  public forcedFeatures$ = new BehaviorSubject<boolean>(false);
  public muted$ = new BehaviorSubject<boolean>(false);

  protected hasConferenceCallSubscription:Subscription = undefined;

  constructor(
    public chatService:ChatService,
    public conferenceService:ConferenceService,
    public layoutService:LayoutService,
    public resizeService : ResizeService,
    public route: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef) {
    super();
    this.conversationId = this.chatService.currentConversationId;
    //conversationIdChanged(this.conversationId);
    this.chatService.currentConversationId$
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap(id=>{
          this.conversationId = id;
          firstValueFrom(this.layoutService.details.state$)
            .then(state=>{
              //console.log("STATE",state,state?.content?.type==ChatConversationDetailsContainerComponent);
              if (state.open &&
                  state?.content?.type==ChatConversationDetailsContainerComponent &&
                  state?.content?.content?.conversationId!=id) {
                this.layoutService.details.setContent('component', {
                  type: ChatConversationDetailsContainerComponent,
                  context: <ChatConversationDetailsContext>{
                    conversationId: this.conversationId
                  }
                });
              }
            });
          /*
          this.layoutService.details.setContent('component', {
            type: ChatConversationDetailsContainerComponent,
            context: <ChatConversationDetailsContext>{
              conversationId: this.conversationId
            }
          });*/
          //conversationIdChanged(id);
          this.changeDetectorRef.markForCheck();
          return this.chatService.getConversationTimeMuted$(id);
        })
      ).subscribe((timeMuted) => {
        if (this.muted$.value != !!timeMuted) {
          this.muted$.next(!!timeMuted);
        }
      });
    this.conversationData$ = this.chatService.currentConversationId$
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap(id=>this.chatService.getConversationData$(id)),
        tap(data=>this.readonly.set(data?.canSend===false))
      );
    combineLatest([
      this.hasFeatures$,
      this.forcedFeatures$
    ]).pipe(takeUntil(this.onDestroy$),map(([a,b])=>a||b),distinctUntilChanged())
      .subscribe(value=>this.resizeService.triggerSync(300));
  }

  ngOnInit() {
    this.route.snapshot.data?.context
      ?.pipe(takeUntil(this.onDestroy$))
      .subscribe((action: Action) => {
        switch(action?.id) {
          case 'filter':  this.openDetails(); break;
          case 'search': this.updateSearchTerm(action.parameter || '');
        }
      });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.chatService.currentConversationId$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(conversationId=>{
      });
    //console.log("THIS",this,"\neditor",this.chatMessageEditor,"\nconv",this.chatConversationComponent);
  }

  ngOnDestroy() {
    this.hasConferenceCallSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  updateSearchTerm(term : string) : void {
    //console.info("SEARCH FOR "+term);
    //this.taskActions.updateSearchTerm(term || '');
  }

  switchMuted(muted: boolean) {
    this.chatService.setConversationMuted(this.conversationId, muted /*!this.muted$.value*/);
  }

  onPendingConversationCalls(conversationIds: string[]) {
    const hasConversationIds = conversationIds?.length > 0;
    if (this.forcedFeatures$.value != hasConversationIds) {
      this.forcedFeatures$.next(hasConversationIds);
      if (hasConversationIds && !this.hasFeatures$.value) {
        this.hasFeatures$.next(true);
      }
    }
  }

  onDrop(files:FileList) {
    this.chatMessageEditor?.onDrop(files);
  }

  openDetails() {
    this.layoutService.details.setContent('component', {
      type: ChatConversationDetailsContainerComponent,
      context: <ChatConversationDetailsContext>{
        conversationId: this.conversationId
      }
    });
    this.layoutService.details.ensure(true);
  }

  transitionDone(event?: TransitionEvent) : void {
    this.resizeService.triggerSync();
  }
}
