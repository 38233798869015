import {Action, ActionsSubject, select, Store} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {asapScheduler, combineLatest} from "rxjs";
import {
  CorrelationIdGenerator,
  EntityService,
  Logger,
  SearchService,
  SimpleFilterService,
  Topic,
  VersionedId
} from "core";
import {MessageEnvelope, MessageHandlerRegistry, MessagingService} from "messaging";
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {
  Calendar,
  CalendarSubscriptionMessage,
  CalendarSubscriptionMessageType,
  CalendarUpdateMessage,
  CalendarUpdateMessageType
} from "../models/calendar";
import {debounceTime, distinctUntilChanged, finalize, map, takeUntil, tap} from "rxjs/operators";
import {PropertiesService} from "properties";
import {
  calendarDeleteAction,
  calendarDeleteFailureAction,
  calendarDeleteSuccessAction,
  calendarLoadRequestAction,
  calendarSubscribeAction,
  calendarUnsubscribeAction,
  CalendarUpdateAction,
  calendarUpdateFailureAction,
  calendarUpdateFilterAction,
  calendarUpdateSearchTermAction,
  calendarUpdateSuccessAction
} from "../store/calendar/actions";
import {
  selectCalendar,
  selectCalendarCacheId,
  selectCalendarEntities,
  selectCalendarEntitiesLength,
  selectCalendarFilters,
  selectCalendarSearchTerm,
  selectCalendarsSubscribed
} from "../store/calendar/reducers";
import moment from "moment";
import isEqual from "lodash/isEqual";
import {StoreService} from "store";
import {AuthenticationService} from "auth";

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends StoreService implements SearchService, SimpleFilterService , EntityService<Calendar> {

  protected entities  : Observable<Calendar[]>;
  protected size      : Observable<number>;
  protected sections  : Observable<number[]>;
  protected searchTerm: Observable<string>;
  protected filters   : Observable<string[]>;

  protected messageHandlerRegistry = new MessageHandlerRegistry();
  protected calendarSubscription: Subscription;

  protected logger = new Logger('CalendarService');

  constructor(protected store$: Store<any>,
              protected action$: ActionsSubject,
              protected authenticationService: AuthenticationService,
              protected messagingService: MessagingService,
              protected translateService : TranslateService,
              protected propertiesService: PropertiesService,
              protected correlationIdGenerator: CorrelationIdGenerator) {
    super(store$, action$);
    console.log('CalendarService.ctor');
    combineLatest([
      this.authenticationService.user$,
      this.messagingService.open$
    ]).subscribe(([user,open]) => {
      this.calendarSubscription?.unsubscribe();
      this.calendarSubscription = undefined;
      if (open && !!user) {
        this.calendarSubscription = this.store$.select(selectCalendarsSubscribed)
          .pipe(
            debounceTime(30),
            distinctUntilChanged((keys1:VersionedId[],keys2:VersionedId[])=>isEqual(keys1,keys2))
          )
          .subscribe(versionedIds => {
            this.messagingService.sendMessage(this.messagingService.initializeMessage(<CalendarSubscriptionMessage>{
              type: CalendarSubscriptionMessageType,
              subscribed: versionedIds ?? []
            }));
          });
      }
    });
    let handleCalendarUpdateMessage = (envelope: MessageEnvelope):void => {
      let message = <CalendarUpdateMessage>envelope.message;
      this.store$.dispatch(calendarUpdateSuccessAction({ calendar: message.calendar }));
    };
    this.messageHandlerRegistry.addMessageHandler(CalendarUpdateMessageType,handleCalendarUpdateMessage);
    this.messagingService
      .register(envelope => this.messageHandlerRegistry.hasMessageHandler(envelope.message?.type))
      .subscribe(envelope => {
        this.messageHandlerRegistry.getMessageHandler(envelope.message.type)(envelope);
      });
    this.translateService.onLangChange.subscribe(() => {
      //this.loadRequest();
    });
  }

  loadRequest(): void {
    this.logger.debug('loadRequest');
    this.store$.dispatch(calendarLoadRequestAction());
  }

  get cacheId$(): Observable<string> {
    return this.store$.select(selectCalendarCacheId);
  }

  get entities$(): Observable<Calendar[]> {
    if (!this.entities) {
      const entities$ = new ReplaySubject<Calendar[]>(1);
      this.store$
        .pipe(
          select(selectCalendarEntities),
          tap(entities => this.logger.debug('ENTITIES', entities))
        )
        .subscribe(entities => entities$.next(entities));
      this.entities = entities$;
    }
    return this.entities;
  }

  get sections$(): Observable<number[]> {
    if (!this.sections) {
      const sections$ = new BehaviorSubject<number[]>([]);
      this.entities$
        .pipe(
          map(calendars => {
            return ((<any>calendars).backingArray || [])
              .map((calendar, index)=> ({ index, day: moment( calendar.timeCreated).format('YYYYMMDD') }))
              .filter((dateIndex,index,list) => index==0 || dateIndex.day != list[index-1].day)
              .map((dateIndex,index)=> dateIndex.index);
          }),
          distinctUntilChanged((previous, current) => {
            return previous == current ||
              previous && current &&
              previous.length == current.length &&
              previous.every(f => current.includes(f))
          })
        ).subscribe(sections => sections$.next(sections));
      this.sections = sections$;
    }
    return this.sections;
  }

  get size$(): Observable<number> {
    if (!this.size) {
      const size$ = new ReplaySubject<number>(1);
      this.store$
        .pipe(
          select(selectCalendarEntitiesLength),
          tap(size => this.logger.debug('SIZE', size))
        )
        .subscribe(length => size$.next(length));
      this.size = size$;
    }
    return this.size;
  }

  get filters$(): Observable<string[]>  {
    if (!this.filters) {
      const filters$ = new ReplaySubject<string[]>(1);
      this.store$
        .pipe(
          select(selectCalendarFilters),
          tap(filters => this.logger.debug('FILTERS', filters))
        )
        .subscribe(filters => filters$.next(filters));
      this.filters = filters$;
    }
    return this.filters;
  }

  get searchTerm$(): Observable<string> {
    if (!this.searchTerm) {
      const searchTerm$ = new ReplaySubject<string>();
      this.store$
        .pipe(
          select(selectCalendarSearchTerm),
          distinctUntilChanged((a,b) => isEqual(a,b)),
          tap(searchTerm => this.logger.debug('SEARCH TERM', searchTerm))
        )
        .subscribe(searchTerm => searchTerm$.next(searchTerm));
      this.searchTerm = searchTerm$;
    }
    return this.searchTerm;
  }

  updateFilter(filters: string[]): void {
    //this.logger.debug('updateFilter');
    this.store$.dispatch(calendarUpdateFilterAction({ filters: filters }));
  }

  updateSearchTerm(term: string): void {
    this.store$.dispatch(calendarUpdateSearchTermAction({ term: term }));
  }

  getCalendar$(id: string, defaultCalendar?: Calendar): Observable<Calendar> {
    let subscribed = false;
    let ensureSubscription = (calendar:Calendar) => {
      if (!subscribed) {
        subscribed = true;
        asapScheduler.schedule(() => this.dispatch(calendarSubscribeAction({ id })));
      }
    }
    return this.store$.select(selectCalendar(id)).pipe(
      distinctUntilChanged((calendar1: Calendar, calendar2: Calendar)=>isEqual(calendar1, calendar2)),
      finalize(() => {
        if (subscribed) {
          subscribed = false;
          window.setTimeout(()=>
              this.store$.dispatch(calendarUnsubscribeAction({ id })),
            1000
          );
        }
      }),
      tap(calendar => ensureSubscription(calendar)),
      map(calendar => calendar ?? defaultCalendar)
    );
  }

  typesTopic$(): Observable<Topic> {
    return this.propertiesService.groupId$
      .pipe(
        map(groupId => this.propertiesService.group?.calendarTypesTopic)
      );
  }

  canCreateCalendar$(): Observable<boolean> {
    return this.typesTopic$().pipe(map(topic => !!topic?.topics?.length));
  }

  save(calendar: Calendar, previous?: Calendar): Promise<Calendar> {
    if (calendar) {
      const correlationId = this.correlationIdGenerator.next();
      const promise = new Observable<any>(subscriber => {
        const reducer = `saveCalendar_${correlationId}`;
        // to improve type checking consider implementing CalendarUpdateAction as class
        // then it will be possible to probe for particular Action type with instanceof operator
        // and benefit from user-defined type guards support of typescript and the related type narrowing.
        this.store$.addReducer(reducer, (state, action: { calendar: Calendar, error?: any, correlationId?: string } & Action) => {
          if (action.type == calendarUpdateSuccessAction.type  ||
              action.type == calendarUpdateFailureAction.type) {
            console.debug(reducer, state, action);
            if (action.correlationId == correlationId) {
              if (action.type == calendarUpdateSuccessAction.type) {
                // this.loadRequest();
                subscriber.next(action.calendar);
              } else {
                subscriber.error(action.error);
              }
              subscriber.complete();
              this.store$.removeReducer(reducer);
            }
          }
          return state;
        });
      }).toPromise();
      this.store$.dispatch(new CalendarUpdateAction(calendar, previous, correlationId));
      return promise;
    } else {
      return Promise.reject('Invalid calendar');
    }
  }

  delete(calendar: Calendar): Promise<any> {
    let resolve: any, reject: any;
    const promise = new Promise((promiseResolve, promiseReject) => {
      resolve=promiseResolve; reject=promiseReject;
    });
    const reducer = `deleteCalendar_${calendar.id}`;
    this.store$.addReducer(reducer, (state, action: { calendar: Calendar, error?: any } & Action) => {
      if (action.type == calendarDeleteSuccessAction.type || action.type == calendarDeleteFailureAction.type) {
        console.debug(reducer, state, action);
        if (action.calendar?.id==calendar.id) {
          if (action.type == calendarDeleteSuccessAction.type) {
            this.loadRequest();
            resolve();
          } else {
            reject();
          }
          this.store$.removeReducer(reducer);
        }
      }
      return state;
    });
    this.store$.dispatch(calendarDeleteAction({ calendar }));
    return promise;
  }
}

