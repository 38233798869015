import {Inject, Injectable} from "@angular/core";
import {ActionsSubject, Store} from "@ngrx/store";
import * as fromChat from "./store/state";
import {createMessageId, MessageEnvelope, MessageHandlerRegistry, MessagingService,} from "messaging";
import {
  BehaviorSubject,
  combineLatest,
  concat,
  concatMap,
  firstValueFrom,
  from,
  fromEvent,
  Observable,
  of,
} from "rxjs";
import {
  ConferenceDataMessage,
  ConferenceDataMessageType,
  ConferenceHangUpMessage,
  ConferenceHangUpMessageType,
  ConferenceInfo,
  ConferenceInviteMessage,
  ConferenceInviteMessageType,
  ConferenceMediaMessage,
  ConferenceMediaMessageType,
  ConferenceParticipant,
  ConferencePauseMessage,
  ConferencePauseMessageType,
  ConferencePickUpMessage,
  ConferencePickUpMessageType,
  ConferenceStateMessage,
  ConferenceStateMessageType,
  ConferenceSynchronizeStatesMessage,
  ConferenceSynchronizeStatesMessageType,
  ConferenceType,
} from "./store/models";
import {AuthenticationService} from "auth";
import {filter, first, map, shareReplay, switchMap} from "rxjs/operators";
import {
  channelOpenStateAction,
  conferenceHangUpMessageAction,
  conferenceInviteMessageSendAction,
  conferenceMediaMessageAction,
  conferencePauseMessageAction,
  conferencePickUpMessageAction,
  conferenceStateMessageReceiveAction,
  conferenceSynchronizeStatesAction,
  conferenceTouchAction,
} from "./store/actions";
import {ConferenceSounds} from "./conference.sounds";
import {ENVIRONMENT, Logger, LogMessage, LogMessageType} from "core";
import {StoreService} from "store";
import {Conference, InternalMediaStreamWrapper, MediaStreamWrapper,} from "./conference";
import {addTrack, removeTrack} from "conference/lib/util";

export interface ConferenceConnection {
  tracks: Map<string, RTCRtpSender>;
  peer?: RTCPeerConnection;
  participant: ConferenceParticipant;
  mediaStreamWrapper?: MediaStreamWrapper;
  screenStreamWrapper?: MediaStreamWrapper;
  offering: boolean;
  polite: boolean;
  ignoreOffer: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ConferenceService extends StoreService {
  protected log = true;
  protected logWebRtcSignals = true;
  protected logStep = 0;
  protected logger = new Logger("ConferenceService").setSilent(!this.log);
  protected loggerWebRtc = new Logger(
    "ConferenceService.WebRtcSignal"
  ).setSilent(!this.logWebRtcSignals);

  protected _sounds: ConferenceSounds = new ConferenceSounds();
  public get sounds(): ConferenceSounds {
    return this._sounds;
  }

  protected _conferenceAudio = this.sounds.conferenceAudio();
  public get conferenceAudio(): HTMLAudioElement {
    return this._conferenceAudio;
  }

  public set conferenceAudio(audio: HTMLAudioElement) {
    this._conferenceAudio = audio;
  }

  protected messageHandlerRegistry = new MessageHandlerRegistry();

  public error$ = new BehaviorSubject<any>(undefined);

  protected _peerConnectionConfig: RTCConfiguration;
  public get peerConnectionConfig(): RTCConfiguration {
    if (!this._peerConnectionConfig) {
      this._peerConnectionConfig = {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          { urls: "stun:stun.brunner.at" },
          {
            urls: "turn:turn.brunner.at:3478",
            username: "turnuser",
            credential: "tah8waimai1Fae5johCui2ieye1Too1woof",
          },
        ],
      };
    }
    return this._peerConnectionConfig;
  }

  getDirectContactId(conversationId: string): string {
    let parts = conversationId?.split("."); // split with dot...
    if (parts?.length == 3) {
      let thisId = this.authenticationService.user?.id;
      return parts[1] == thisId ? parts[2] : parts[1];
    }
    return undefined;
  }

  protected ws = undefined;

  protected audioOutputDeviceId: string = (this.sounds.conferenceAudio() as any)
    ?.sinkId;
  protected static mediaDevices: Observable<MediaDeviceInfo[]>;

  constructor(
    protected store$: Store<fromChat.State>,
    protected action$: ActionsSubject,
    protected authenticationService: AuthenticationService,
    protected messagingService: MessagingService,
    @Inject(ENVIRONMENT) protected environment: any
  ) {
    super(store$, action$);
    this.logger.debug("ctor");
    /*
    this.ws = new WebSocket("wss://60e4c5ae2e3af.streamlock.net/webrtc-session.json");
    this.ws.onmessage = (message)=>{
      this.logger.info("WS.MESSAGE",message);
    }
    this.ws.onopen = ()=>{
      this.logger.info("WS.OPEN");
    }
    this.ws.onclose = ()=>{
      this.logger.info("WS.CLOSE");
    }*/

    // on every connection reopening or login, we sync calls as we
    // might have missed something..
    combineLatest([
      this.authenticationService.user$,
      this.messagingService.open$,
    ])
      .pipe(
        map(([user, open]) => !!user && open),
        filter((ok) => ok)
      )
      .subscribe((ok) => {
        this.synchronizeConferences();
      });
    // play call sounds....
    this.getAllConferenceInfos$().subscribe((conferenceInfos) => {
      //this.logger.info("WEBRTC.UPDATE.conferenceInfos",conferenceInfos);
      this.updateSounds(conferenceInfos);
      this.updateConferences(conferenceInfos);
    });
    let handleConferenceStateMessages = (envelope: MessageEnvelope): void => {
      let message = <ConferenceStateMessage>envelope.message;
      let userId = this.authenticationService.user.id;
      //this.logger.info("handleConferenceStateMessages",message);
      this.logger.info(
        "handleConferenceStateMessages",
        message,
        userId,
        "\nconnectionId",
        this.connectionId
      );
      this.store$.dispatch(
        conferenceStateMessageReceiveAction({ message, userId })
      );
    };
    let handleConferencePickUpMessages = (envelope: MessageEnvelope): void => {
      let message = <ConferencePickUpMessage>envelope.message;
      let userId = this.authenticationService.user.id;
      //this.logger.info("handleConferencePickUpMessages",message);
      this.logger.info(
        "handleConferencePickUpMessages",
        message,
        userId,
        "\nconnectionId",
        this.connectionId
      );
      this.store$.dispatch(
        conferencePickUpMessageAction({ message, userId, send: false })
      );
    };
    let handleConferenceMediaMessage = (envelope: MessageEnvelope): void => {
      let message = <ConferenceMediaMessage>envelope.message;
      let userId = this.authenticationService.user.id;
      this.logger.info(
        "handleConferenceMediaMessage",
        message,
        userId,
        "\nconnectionId",
        this.connectionId
      );
      //this.logger.info("updateConference.message",message,"audio",message.from.audio,"video",message.from.video);
      this.store$.dispatch(
        conferenceMediaMessageAction({ message, userId, send: false })
      );
    };
    let handleConferencePauseMessage = (envelope: MessageEnvelope): void => {
      let message = <ConferencePauseMessage>envelope.message;
      let userId = this.authenticationService.user.id;
      this.logger.info(
        "handleConferencePauseMessage",
        message,
        userId,
        "\nconnectionId",
        this.connectionId
      );
      this.store$.dispatch(
        conferencePauseMessageAction({ message, userId, send: false })
      );
    };
    let handleConferenceHangUpMessages = (envelope: MessageEnvelope): void => {
      let message = <ConferenceHangUpMessage>envelope.message;
      let userId = this.authenticationService.user.id;
      this.logger.info(
        "handleConferenceHangUpMessages",
        message,
        userId,
        "\nconnectionId",
        this.connectionId
      );
      this.store$.dispatch(
        conferenceHangUpMessageAction({ message, userId, send: false })
      );
    };
    let handleConferenceDataMessages = (envelope: MessageEnvelope): void => {
      let message = <ConferenceDataMessage>envelope.message;
      let userId = this.authenticationService.user.id;
      this.logger.info(
        "handleConferenceDataMessages",
        message,
        userId,
        "\nconnectionId",
        this.connectionId
      );
      this.handleConferenceDataMessages(message);
    };
    // calls...
    this.messageHandlerRegistry.addMessageHandler(
      ConferenceStateMessageType,
      handleConferenceStateMessages
    );
    this.messageHandlerRegistry.addMessageHandler(
      ConferencePickUpMessageType,
      handleConferencePickUpMessages
    );
    this.messageHandlerRegistry.addMessageHandler(
      ConferenceMediaMessageType,
      handleConferenceMediaMessage
    );
    this.messageHandlerRegistry.addMessageHandler(
      ConferencePauseMessageType,
      handleConferencePauseMessage
    );
    this.messageHandlerRegistry.addMessageHandler(
      ConferenceHangUpMessageType,
      handleConferenceHangUpMessages
    );
    this.messageHandlerRegistry.addMessageHandler(
      ConferenceDataMessageType,
      handleConferenceDataMessages
    );
    this.messagingService
      .register((envelope) =>
        this.messageHandlerRegistry.hasMessageHandler(envelope.message?.type)
      )
      .subscribe((envelope) => {
        /*if (this.authenticationService.user.isBeta) {
          this.messagingService.sendMessage(
            this.messagingService.initializeMessage(<LogMessage>{
              type: LogMessageType,
              object: { received: Date.now(), message: envelope.message },
            })
          );
        }*/
        this.messageHandlerRegistry.getMessageHandler(envelope.message.type)(
          envelope
        );
      });
    this.messagingService.open$.pipe().subscribe((open) => {
      this.store$.dispatch(
        channelOpenStateAction({
          open: open,
          connectionId: this.messagingService.connectionId,
        })
      );
    });
  }

  get userId(): string {
    return this.authenticationService.user?.id;
  }

  get connectionId(): string {
    return this.messagingService.connectionId;
  }

  /**
   * CALLS
   */
  getAllConferenceInfos$(): Observable<ConferenceInfo[]> {
    return this.store$.select(fromChat.selectAllConferencesArray);
  }

  getActiveConferenceInfo$(): Observable<ConferenceInfo> {
    return this.store$.select(fromChat.selectActiveConference);
  }

  getConferenceInfo$(conferenceId: string): Observable<ConferenceInfo> {
    return this.store$.select(fromChat.selectConference(conferenceId));
  }

  getConversationConferenceInfo$(
    conversationId: string
  ): Observable<ConferenceInfo> {
    return this.store$.select(fromChat.selectAllConferences).pipe(
      map((object) =>
        Object.keys(object)
          .map((key) => object[key])
          .find((conference) => conference?.conversationId == conversationId)
      )
    );
  }

  synchronizeConferences(): Observable<boolean> {
    return from(
      this.dispatch(
        conferenceSynchronizeStatesAction({
          message: this.messagingService.initializeMessage(<
            ConferenceSynchronizeStatesMessage
          >{
            type: ConferenceSynchronizeStatesMessageType,
            from: <ConferenceParticipant>{
              type: "conference",
              id: this.userId,
            },
          }),
          synchronized: false,
        })
      )
    ).pipe(map((action) => action.synchronized));
  }

  protected _previousActiveConferenceId: string = undefined;
  updateSounds(conferenceInfos: ConferenceInfo[]) {
    let userId = this.userId;
    let activeInfo = conferenceInfos?.find((info) => info.active);
    //this.logger.info("updateSounds.prev",this._previousActiveConferenceId==activeInfo?.conferenceId,this._previousActiveConferenceId,"\nactive",activeInfo);
    if (
      !!this._previousActiveConferenceId &&
      this._previousActiveConferenceId != activeInfo?.conferenceId &&
      !conferenceInfos.find(
        (info) => info.conferenceId == this._previousActiveConferenceId
      )
    ) {
      this.sounds.conferenceEnd().play();
    }
    this._previousActiveConferenceId = activeInfo?.conferenceId;
    // PLAY SOUNDS....
    if (!!activeInfo) {
      //this.logger.info("PLAY.stop.1");
      this.sounds.conferenceRing().stop();
      if (activeInfo.pickedUp.length + activeInfo.paused.length > 1) {
        this.sounds.conferenceFree().stop();
      } else {
        this.sounds.conferenceFree().play();
      }
    } else if (
      !!conferenceInfos.find((c) => c.ringing.find((p) => p.id == userId))
    ) {
      //this.logger.info("PLAY.play");
      this.sounds.conferenceFree().stop();
      this.sounds.conferenceRing().play();
    } else {
      //if (conferenceInfos.length==0) {
      //this.logger.info("PLAY.stop.2");
      this.sounds.conferenceRing().stop();
      this.sounds.conferenceFree().stop();
    }
  }

  protected conferences: { [key: string]: Conference } = {};

  getConference(conferenceId: string): Conference {
    return this.conferences[conferenceId];
  }

  updateConferences(conferenceInfos: ConferenceInfo[]) {
    //this.logger.info("updateConferences", { conferences: this.conferences, conferenceInfos });
    const closed: { [key: string]: Conference } = { ...this.conferences };
    const userId = this.userId;
    const connectionId = this.connectionId;
    conferenceInfos?.forEach((info) => {
      let conference = this.conferences[info.conferenceId];
      let pickedUp = !!info.pickedUp.find(
        (p) => p.id == userId && p.connectionId == connectionId
      );
      let paused =
        !pickedUp &&
        !!info.paused.find(
          (p) => p.id == userId && p.connectionId == connectionId
        );
      let participant = info.participant;
      //this.logger.info("CONFERENCE",info,"conference",conference,"pickedUp",pickedUp,"paused",paused,"closed",closed);
      if (!!pickedUp || !!paused) {
        delete closed[info.conferenceId];
        if (!conference) {
          this.logStep = 0;
          conference = new Conference(
            this.userId,
            info,
            participant.audio &&
              (!info.muted ||
                participant.role == "admin" ||
                participant.role == "owner"),
            participant.video,
            (conference) => this.updatedConferenceMediaStream(conference),
            this.log
          );
          this.conferences[info.conferenceId] = conference;
          this.logger.info(
            "CONFERENCE.CREATED",
            info.conferenceId,
            Date.now(),
            "\nconference",
            conference
          );
        } else {
          conference.info = info;
        }
        conference.active = info.active;
        // we only keep here pickedUp or paused conferences....
        this.updateConferenceConnections(conference, pickedUp);
      }
    });
    Object.keys(closed).forEach((conferenceId) => {
      //this.logger.info("WEBRTC.delete.key",conferenceId,"closed",closed);
      //this.logger.info("STOPPED",this.conferences[conferenceId]);

      delete this.conferences[conferenceId];
      let closedConference = closed[conferenceId];
      closedConference.stop();
      this.logger.info("CONFERENCE.CLOSED", conferenceId, Date.now());
    });
    //this.logger.info("WEBRTC:updateConferences.end",this.conferences);
  }

  async handleConferenceDataMessages(message: ConferenceDataMessage) {
    //this.logger.info("handleConferenceDataMessages",message);
    this.loggerWebRtc.info("handleConferenceDataMessages", message);
    if (!!message.conferenceId && !!message.from?.id) {
      let conference = this.conferences[message.conferenceId];
      let connection =
        !!conference &&
        (<BehaviorSubject<ConferenceConnection>>(
          conference.getConnection$(message.from.id)
        ))?.value;
      if (!!connection) {
        const info = conference.info;
        const data = message.data || {};
        if (data.offer) {
          //this.logger.info("handleConferenceDataMessages.offer",data.offer);
          this.loggerWebRtc.info("OFFER -> RECEIVED", { message, connection });
          const offerCollision =
            connection.offering || connection.peer.signalingState != "stable";
          // this.logger.info(++this.logStep, "handleConferenceDataMessages.offer",data.offer,"state",connection.peer.signalingState,"collision",offerCollision);
          if (offerCollision) {
            const { offering, signallingState, polite } = (({
              offering,
              peer,
              polite,
            }) => ({ offering, signallingState: peer.signalingState, polite }))(
              connection
            );
            this.loggerWebRtc.info("OFFER -> COLLISION", {
              message,
              offering,
              signallingState,
              polite,
            });
            connection.ignoreOffer = !connection.polite && offerCollision;
            if (connection.ignoreOffer) {
              this.loggerWebRtc.info(
                "OFFER -> COLLISION -> IGNORED (NOT POLITE)",
                { message, connection }
              );
              return;
            }
            await Promise.all([
              connection.peer
                .setLocalDescription({ type: "rollback" })
                .catch((error) =>
                  this.logger.error("OFFER -> COLLISION -> ROLLBACK (POLITE)")
                ),
              connection.peer.setRemoteDescription(message.data.offer),
            ]);
          } else {
            await connection.peer.setRemoteDescription(message.data.offer);
          }
          try {
            const answer = await connection.peer.createAnswer();
            this.logger.info(
              ++this.logStep,
              "handleConferenceDataMessages.offer.2",
              data.offer,
              "state",
              connection.peer.signalingState,
              "answer",
              answer
            );
            this.loggerWebRtc.info("ANSWER -> CREATED", { connection, answer });
            await connection.peer.setLocalDescription(answer);
            await this.messagingService.sendMessage(
              this.messagingService.initializeMessage(<ConferenceDataMessage>{
                type: ConferenceDataMessageType,
                from: info.participant,
                to: connection.participant,
                conversationId: info.conversationId,
                conferenceId: info.conferenceId,
                data: { answer: connection.peer.localDescription },
              })
            );
            this.loggerWebRtc.info("ANSWER -> SENT", connection);
          } catch (e) {
            this.loggerWebRtc.error("ANSWER -> ERROR", e, connection);
          }
        } else if (data.answer) {
          this.logger.info(
            ++this.logStep,
            "handleConferenceDataMessages.answer",
            data.answer,
            "state",
            connection.peer.signalingState
          );
          //this.logger.info("handleConferenceDataMessages.answer",data.answer);
          this.loggerWebRtc.info("ANSWER -> RECEIVED", {
            message,
            connection,
            signallingState: connection.peer.signalingState,
          });
          try {
            await connection.peer.setRemoteDescription(message.data.answer);
          } catch (e) {
            this.loggerWebRtc.error(
              "ANSWER -> RECEIVED (setRemoteDescription)",
              e,
              connection
            );
          }
        }
        if (data.candidate) {
          this.logger.info(
            ++this.logStep,
            "handleConferenceDataMessages.candidate",
            data.candidate,
            "state",
            connection.peer.signalingState
          );
          //this.logger.info("handleConferenceDataMessages.candidate",data.candidate);
          this.loggerWebRtc.info("ICECANDIDATE -> RECEIVED", {
            message,
            connection,
          });
          try {
            await connection.peer.addIceCandidate(message.data.candidate);
          } catch (e) {
            if (!connection.ignoreOffer) {
              this.loggerWebRtc.error("ICECANDIDATE -> ERROR", e, connection);
            } else {
              // impolite peer during a collision
              // drop the error since it doesn't matter in this context
            }
          }
        } else if (data.iceCandidates) {
          this.logger.info(
            ++this.logStep,
            "handleConferenceDataMessages.iceCandidates",
            data.iceCandidates,
            "state",
            connection.peer.signalingState
          );
          //this.logger.info("handleConferenceDataMessages.candidate",data.iceCandidates);
          this.loggerWebRtc.info("ICECANDIDATES -> RECEIVED", {
            message,
            connection,
          });
          try {
            for (let candidate of data.iceCandidates) {
              await connection.peer.addIceCandidate(candidate);
            }
          } catch (e) {
            if (!connection.ignoreOffer) {
              this.loggerWebRtc.error("ICECANDIDATES -> ERROR", e, connection);
            } else {
              // impolite peer during a collision
              // drop the error since it doesn't matter in this context
            }
          }
        }
      }
    }
  }

  updatedConferenceMediaStream(conference: Conference) {
    //this.logger.info("updatedConferenceMediaStream",conference);
    const userId = this.userId;
    const connectionId = this.connectionId;
    let pickdUp = !!conference.info.pickedUp.find(
      (p) => p.id == userId && p.connectionId == connectionId
    );
    //let screen  = !!conference.screenStreamWrapper.hasVideo;
    //this.logger.info("HAS_SCREEN",screen);
    this.updateConferenceConnections(conference, pickdUp, true);
  }

  updateConferenceConnections(
    conference: Conference,
    pickedUp: boolean,
    updatedMediaStream = false
  ) {
    this.loggerWebRtc.info("updateConferenceConnections", {
      conference,
      pickedUp,
      updatedMediaStream,
      active: conference.active,
      hasMediaStream: conference.hasMediaStream,
      audio: conference.mediaStreamWrapper.hasAudio,
      video: conference.mediaStreamWrapper.hasVideo,
    });
    const participant = conference.info?.participant;
    if (!conference.hasMediaStream || !participant) {
      //this.logger.warn('updateConferenceConnections - missing conference media stream or participant', conference);
      return;
    }
    const mediaStreamWrapper = <InternalMediaStreamWrapper>(
      conference.mediaStreamWrapper
    );
    const screenStreamWrapper = <InternalMediaStreamWrapper>(
      conference.screenStreamWrapper
    );
    const mediaStream = mediaStreamWrapper.mediaStream;
    const screenStream = screenStreamWrapper.mediaStream;
    const userId = this.userId;
    const audioTrack =
      mediaStream.getAudioTracks().length > 0
        ? mediaStream.getAudioTracks()[0]
        : undefined;
    const videoTrack =
      mediaStream.getVideoTracks().length > 0
        ? mediaStream.getVideoTracks()[0]
        : undefined;
    //const validTracks  = (participant?.audio ? !!audioTrack : true) &&
    //                     (participant?.video ? !!videoTrack : true);
    this.logger.info("updateConferenceConnections", {
      audio: mediaStreamWrapper.hasAudio,
      audioMuted: audioTrack?.muted,
      audioEnabled: audioTrack?.enabled,
      audioTrackId: audioTrack?.id,
      audioTracks: mediaStream.getAudioTracks(),

      video: mediaStreamWrapper.hasVideo,
      videoMuted: videoTrack?.muted,
      videoEnabled: videoTrack?.enabled,
      videoTrackId: videoTrack?.id,
      videoTracks: mediaStream.getVideoTracks(),
    });
    /*if (!validTracks) {
      this.logger.info("NOT_YET_READY",
        "\naudio",participant?.audio,"track",!!audioTrack,
        "\nvideo",participant?.video,"track",!!videoTrack);
    } else */
    if (conference.info.server) {
      /*
      let connection = (<BehaviorSubject<ConferenceConnection>>conference.getConnection$(userId))?.value;
      if (!connection) {
        connection = {
          tracks: new Map<string, RTCRtpSender>(),
          peer: new RTCPeerConnection(this.peerConnectionConfig),
          participant: participant,
          polite: false,
          offering: false,
          ignoreOffer: false
        };
        mediaStream.getTracks().forEach(track => {
          //this.loggerWebRtc.info("addTrack", {connection, track});
          connection.tracks.set(track.id, connection.peer.addTrack(track, mediaStream));
        });
        screenStream?.getTracks().forEach(track => {
          //this.loggerWebRtc.info("addScreenTrack", {connection, track, trackId: track.id});
          connection.tracks.set(track.id, connection.peer.addTrack(track, mediaStream));
        });
        //
        // Perfect negotiation paradigm:
        // https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Perfect_negotiation
        // onnegotiationneeded is only fired in stable state
        //
        connection.peer.onnegotiationneeded = async (event) => {
          try {
            connection.offering = true;
            const offer = await connection.peer.createOffer();
            await connection.peer.setLocalDescription(offer);
            let info = conference.info;
            await this.messagingService.sendMessage(this.messagingService.initializeMessage(<ConferenceDataMessage>{
              type: ConferenceDataMessageType,
              from: info.participant,
              to: connection.participant,
              conversationId: info.conversationId,
              conferenceId: info.conferenceId,
              data: { offer }
            }));
            //this.loggerWebRtc.info('OFFER -> SENT (onnegotiationneeded)', { offer, connection });
          } catch (e) {
            this.loggerWebRtc.error('onnegotiationneeded', e, connection);
          } finally {
            connection.offering = false;
          }
        };
        connection.peer.onicecandidate = async ({candidate}) => {
          //this.loggerWebRtc.info("onicecandidate", { connection, candidate });
          let info = conference.info;
          if (candidate) {
            await this.messagingService.sendMessage(this.messagingService.initializeMessage(<ConferenceDataMessage>{
              type: ConferenceDataMessageType,
              from: info.participant,
              to: connection.participant,
              conversationId: info.conversationId,
              conferenceId: info.conferenceId,
              data: { candidate: candidate }
            }));
            //this.loggerWebRtc.info("ICE CANDIDATE -> SENT", candidate);
          } else {
            //this.loggerWebRtc.info("ICE CANDIDATE -> SENDING COMPLETED");
          }
        };
        connection.peer.oniceconnectionstatechange = () => {
          //this.loggerWebRtc.info("oniceconnectionstatechange",  connection);
          if (connection.peer.iceConnectionState === "failed") {
            //this.loggerWebRtc.info("oniceconnectionstatechange -> ICE RESTART", connection);
            connection.peer.onnegotiationneeded({ iceRestart: true } as any);
          }
        };
      } else if (updatedMediaStream) {
        const tracks = new Map<string,RTCRtpSender>(connection.tracks);
        connection.tracks.clear();
        mediaStream.getTracks().forEach(track=> {
          if (!tracks.has(track.id)) {
            //this.loggerWebRtc.info("addTrack", { conference, pickedUp, active: conference.active, track });
            connection.tracks.set(track.id,connection.peer.addTrack(track, mediaStream));
          } else {
            connection.tracks.set(track.id,tracks.get(track.id));
          }
        });
        screenStream.getTracks().forEach(track=> {
          if (!tracks.has(track.id)) {
            //this.loggerWebRtc.info("addScreenTrack", { conference, pickedUp, active: conference.active, track, trackId:track.id });
            connection.tracks.set(track.id,connection.peer.addTrack(track, screenStream));
          } else {
            connection.tracks.set(track.id,tracks.get(track.id));
          }
        });
      }
      connection.participant = participant;
      conference.setConnection(participant.id,connection);
      */
    } else {
      this.logger.info(
        "updateConferenceConnections -> UPDATE PARTICIPANT",
        {
          participantAudio: participant?.audio,
          audioTrack: !!audioTrack,
          audioTrackReadyState: audioTrack?.readyState,
          participantVideo: participant?.video,
          videoTrack: !!videoTrack,
          videoTrackReadyState: videoTrack?.readyState,
        },
        { conferenceService: "updateConferenceConnections" }
      );
      [...conference.info.pickedUp, ...conference.info.paused].forEach(
        (participant) => {
          // const connections = new Set<string>(conference.getConnectedParticipantIds()||[]);
          if (participant.id != userId) {
            let connection = (<BehaviorSubject<ConferenceConnection>>(
              conference.getConnection$(participant.id)
            ))?.value;
            /* WHAT IS THIS DOING?
          if (!!connection && connection.participant.connectionId==participant.connectionId) {
            this.loggerWebRtc.info("connection.update.mediaUpdate", { connection, updatedMediaStream, participant });
            connections.delete(participant.id);
          } else {
            this.loggerWebRtc.info("connection.create.mediaUpdate", { connection, updatedMediaStream, participant });
          }
           */
            if (!connection) {
              connection = {
                tracks: new Map<string, RTCRtpSender>(),
                peer: new RTCPeerConnection(this.peerConnectionConfig),
                participant: participant,
                mediaStreamWrapper: new InternalMediaStreamWrapper(
                  this.log,
                  false,
                  false
                ),
                screenStreamWrapper: new InternalMediaStreamWrapper(
                  this.log,
                  false,
                  false
                ),
                polite: userId.localeCompare(participant.id) > 0,
                offering: false,
                ignoreOffer: false,
              };
              mediaStream.getTracks().forEach((track) => {
                //this.logger.info("TRACK.SEND.MEDIA","\n"+track.id,"\nstream",mediaStream.id,"count",mediaStream.getTracks().length);
                this.loggerWebRtc.info("addTrack", { connection, track });
                connection.tracks.set(
                  track.id,
                  connection.peer.addTrack(track, mediaStream)
                );
              });
              screenStream?.getTracks().forEach((track) => {
                //this.logger.info("TRACK.SEND.SCREEN","\n"+track.id,"\nstream",screenStream.id,"count",screenStream.getTracks().length);
                this.loggerWebRtc.info("addScreenTrack", {
                  connection,
                  track,
                  trackId: track.id,
                });
                connection.tracks.set(
                  track.id,
                  connection.peer.addTrack(track, mediaStream)
                );
              });
              connection.peer.ontrack = ({ track, streams }) => {
                // this.logger.info(++this.logStep,"webrtc.peer.ontrack",track,"state",connection.peer.signalingState);
                let videoTrack = track.kind == "video";
                let audioTrack = track.kind == "audio";
                console.log("### ontrack", {
                  connection,
                  track,
                  trackId: track.id,
                  streams
                });
                this.loggerWebRtc.info("ontrack", {
                  connection,
                  track,
                  trackId: track.id,
                  streams,
                });
                const peerMediaStreamWrapper = <InternalMediaStreamWrapper>(
                  connection.mediaStreamWrapper
                );
                const peerScreenStreamWrapper = <InternalMediaStreamWrapper>(
                  connection.screenStreamWrapper
                );
                peerMediaStreamWrapper.cleanup();
                peerScreenStreamWrapper.cleanup();
                // see https://developer.mozilla.org/en-US/docs/Web/API/RTCTrackEvent
                // streams:
                // An array of MediaStream objects, each representing one of the media streams to which the added track belongs.
                // By default, the array is empty, indicating a streamless track.
                track.onunmute = () => {
                  this.logger.info(
                    ++this.logStep,
                    "webrtc.peer.ontrack.onunmute",
                    track,
                    "state",
                    connection.peer.signalingState
                  );
                  let mediaTrack =
                    audioTrack ||
                    !connection.participant?.screen ||
                    connection.participant?.tracks?.screen != track.id;
                  const mediaWrapper = mediaTrack ? peerMediaStreamWrapper : peerScreenStreamWrapper;
                  console.log("### onunmute", connection.participant,connection.participant?.tracks?.screen,track.id,"media",mediaTrack,"screen",!mediaTrack,"track",track,!!mediaWrapper.mediaStream.getTrackById(track.id),"videoTracks",mediaWrapper.mediaStream.getVideoTracks());
                  if (videoTrack) {
                    mediaWrapper.mediaStream.getVideoTracks()
                      .filter(videoTrack=> videoTrack.id != track.id)
                      .forEach((videoTrack) => removeTrack(mediaWrapper.mediaStream,videoTrack));
                  }
                  if (!mediaWrapper.mediaStream.getTrackById(track.id)) {
                    this.loggerWebRtc.info("ontrack.onunmute", {
                      connection,
                      screen: !mediaTrack,
                      track,
                      trackId: track.id,
                      participant: connection.participant,
                    });
                    addTrack(mediaWrapper.mediaStream,track);
                    mediaWrapper.cleanup();
                    mediaWrapper.pushMediaStream();
                    this.dispatch(
                      conferenceTouchAction({
                        conferenceId: conference.info?.conferenceId,
                        participantId: participant?.id,
                      })
                    );
                  }
                };
                track.onmute = () => {
                  // this.logger.info(++this.logStep,"webrtc.peer.ontrack.onmute",track,"state",connection.peer.signalingState);
                  this.loggerWebRtc.info("ontrack.onmute", {
                    connection,
                    track,
                    trackId: track.id,
                  });
                  /*
                  if (
                    !!peerMediaStreamWrapper.mediaStream.getTrackById(track.id)
                  ) {
                    removeTrack(peerMediaStreamWrapper.mediaStream,track);
                    peerMediaStreamWrapper.cleanup();
                    peerMediaStreamWrapper.pushMediaStream();
                    this.dispatch(
                      conferenceTouchAction({
                        conferenceId: conference.info?.conferenceId,
                        participantId: participant?.id,
                      })
                    );
                  } else if (
                    !!peerScreenStreamWrapper.mediaStream.getTrackById(track.id)
                  ) {
                    trigger.set(handler => {
                      removeTrack(peerScreenStreamWrapper.mediaStream,track);
                      peerScreenStreamWrapper.cleanup();
                      peerScreenStreamWrapper.pushMediaStream();
                      this.dispatch(
                        conferenceTouchAction({
                          conferenceId: conference.info?.conferenceId,
                          participantId: participant?.id,
                        })
                      );
                    }, 5_000);
                  }*/
                };
                track.onended = () => {
                  this.logger.info(
                    ++this.logStep,
                    "webrtc.peer.ontrack.onended",
                    track,
                    "state"
                  );
                  //this.logger.info("PEER.ONTRACK.ONENDED")
                  peerMediaStreamWrapper.cleanup();
                  peerScreenStreamWrapper.cleanup();
                  this.dispatch(
                    conferenceTouchAction({
                      conferenceId: conference.info?.conferenceId,
                      participantId: participant?.id,
                    })
                  );
                };
              };
              /*
            Perfect negotiation paradigm:
            https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Perfect_negotiation
            onnegotiationneeded is only fired in stable state
            */
              connection.peer.onnegotiationneeded = async (event) => {
                try {
                  connection.offering = true;
                  this.logger.info(
                    ++this.logStep,
                    "webrtc.peer.onnegotiationneeded",
                    event,
                    "state",
                    connection.peer.connectionState
                  );
                  const offer = await connection.peer.createOffer();
                  await connection.peer.setLocalDescription(offer);
                  let info = conference.info;
                  await this.messagingService.sendMessage(
                    this.messagingService.initializeMessage(<
                      ConferenceDataMessage
                    >{
                      type: ConferenceDataMessageType,
                      from: info.participant,
                      to: connection.participant,
                      conversationId: info.conversationId,
                      conferenceId: info.conferenceId,
                      version: conference.info.version,
                      data: { offer },
                    })
                  );
                  this.loggerWebRtc.info(
                    "OFFER -> SENT (onnegotiationneeded)",
                    { offer, connection }
                  );
                } catch (e) {
                  this.loggerWebRtc.error("onnegotiationneeded", e, connection);
                } finally {
                  connection.offering = false;
                }
              };
              connection.peer.onicecandidate = async ({ candidate }) => {
                this.loggerWebRtc.info("onicecandidate", {
                  connection,
                  candidate,
                });
                // this.logger.info(++this.logStep,"webrtc.peer.onicecandidate",candidate,"state",connection.peer.connectionState);
                let info = conference.info;
                if (candidate) {
                  await this.messagingService.sendMessage(
                    this.messagingService.initializeMessage(<
                      ConferenceDataMessage
                    >{
                      type: ConferenceDataMessageType,
                      from: info.participant,
                      to: connection.participant,
                      conversationId: info.conversationId,
                      conferenceId: info.conferenceId,
                      version: conference.info.version,
                      data: { candidate: candidate },
                    })
                  );
                  this.loggerWebRtc.info("ICE CANDIDATE -> SENT", candidate);
                } else {
                  this.loggerWebRtc.info("ICE CANDIDATE -> SENDING COMPLETED");
                }
              };
              connection.peer.oniceconnectionstatechange = () => {
                this.logger.info(
                  ++this.logStep,
                  "webrtc.peer.oniceconnectionstatechange",
                  "state",
                  connection.peer.connectionState
                );
                this.loggerWebRtc.info(
                  "oniceconnectionstatechange",
                  connection
                );
                if (connection.peer.iceConnectionState === "failed") {
                  this.loggerWebRtc.info(
                    "oniceconnectionstatechange -> ICE RESTART",
                    connection
                  );
                  connection.peer.onnegotiationneeded({
                    iceRestart: true,
                  } as any);
                }
              };
            } else if (updatedMediaStream) {
              const tracks = new Map<string, RTCRtpSender>(connection.tracks);
              connection.tracks.clear();
              mediaStream.getTracks().forEach((track) => {
                if (!tracks.has(track.id)) {
                  this.loggerWebRtc.info("addTrack", {
                    conference,
                    pickedUp,
                    active: conference.active,
                    track,
                  });
                  connection.tracks.set(
                    track.id,
                    connection.peer.addTrack(track, mediaStream)
                  );
                } else {
                  connection.tracks.set(track.id, tracks.get(track.id));
                }
              });
              screenStream.getTracks().forEach((track) => {
                if (!tracks.has(track.id)) {
                  this.loggerWebRtc.info("addScreenTrack", {
                    conference,
                    pickedUp,
                    active: conference.active,
                    track,
                    trackId: track.id,
                  });
                  connection.tracks.set(
                    track.id,
                    connection.peer.addTrack(track, screenStream)
                  );
                } else {
                  connection.tracks.set(track.id, tracks.get(track.id));
                }
              });
              // removing tracks is not necessary and even messes all up. stopping track will remove
              // it automatically.
              // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/removeTrack
              // see also: connection.peer.ontrack
              tracks.forEach((sender, id) => {
                if (!connection.tracks.has(id)) {
                  this.loggerWebRtc.info("removeTrack", {
                    conference,
                    pickedUp,
                    active: conference.active,
                    track: sender.track,
                  });
                  connection.peer.removeTrack(sender);
                }
              });
              this.logger.info(
                ++this.logStep,
                "webrtc.peer.updatedMediaStream"
              );
            }
            connection.participant = participant;
            conference.setConnection(participant.id, connection);
          }
        }
      );
    }
    //this.logger.info("MEDIA.audio",!!participant.audio,"video",!!participant.video,"muted",!!participant.muted);
    conference.audio = !!participant.audio;
    conference.video = !!participant.video;
    conference.muted = !!participant.muted;
    if (conference.screen != !!participant.screen) {
      conference.shareScreen(!!participant.screen);
    }
  }

  updateMediaStream(
    conferenceId: string,
    audio: boolean = true,
    video: boolean = false
  ): Promise<ConferenceInfo> {
    //this.logger.info("updateMediaStream", { conferenceId, audio, video });
    return this.getConferenceInfo$(conferenceId)
      .pipe(
        first(),
        switchMap((info) => {
          let conference = this.conferences[info?.conferenceId];
          let hasAudio = !!conference?.mediaStreamWrapper?.hasAudio;
          let mute =
            !!info?.muted &&
            info?.participant?.role != "admin" &&
            info?.participant?.role != "owner";
          return from(
            this.dispatch(
              conferenceMediaMessageAction({
                message: this.messagingService.initializeMessage(<
                  ConferenceMediaMessage
                >{
                  type: ConferenceMediaMessageType,
                  from: <ConferenceParticipant>{
                    ...info.participant,
                    audio: (audio || hasAudio) && !mute,
                    video: video,
                    muted: (audio || hasAudio) && !mute && !audio,
                  },
                  conferenceId: conferenceId,
                }),
                userId: this.userId,
                send: true,
              })
            )
          ).pipe(map((action) => action.conference));
        })
      )
      .toPromise();
  }

  startConference(
    conferenceType: ConferenceType,
    participants: string[],
    conversationId?: string
  ): Promise<ConferenceInfo> {
    //this.logger.info("WEBRTC:startConference",conferenceType,"participants",participants,"conversationId",conversationId);
    let conferenceId = createMessageId();
    this.messagingService.sendMessage(this.messagingService.initializeMessage(<LogMessage>{
      type: LogMessageType,
      object: {
        system: 'conference',
        action: 'startConference',
        participantId: this.userId,
        participants: participants,
        conferenceId: conferenceId,
      }
    }));
    return from(this.pauseActiveConference())
      .pipe(
        switchMap((activeConference) => {
          this.messagingService.sendMessage(this.messagingService.initializeMessage(<LogMessage>{
            type: LogMessageType,
            object: {
              system: 'conference',
              action: 'startConference.2',
              participantId: this.userId,
              participants: participants,
              conferenceId: conferenceId,
              activeConference
            }
          }));
          return from(
            this.dispatch(
              conferenceInviteMessageSendAction({
                message: this.messagingService.initializeMessage(<
                  ConferenceInviteMessage
                >{
                  type: ConferenceInviteMessageType,
                  from: {
                    type: "conference",
                    id: this.userId,
                    conferenceType: conferenceType,
                    connectionId: this.connectionId,
                    role: "admin",
                    audio: true,
                    video: conferenceType == "video",
                  },
                  server: false,
                  conversationId,
                  conferenceId,
                  conferenceType: conferenceType,
                  invited: participants.map(
                    (id) => <ConferenceParticipant>{ type: "conference", id }
                  ),
                }),
              })
            )
          );
        }),
        map((action) => action.conference)
      )
      .toPromise();
  }

  shareScreen(conferenceId: string, screen: boolean): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      let conference = this.getConference(conferenceId);
      let user = conference?.info?.participant;
      //this.logger.info("shareScreen",screen,"user",user);
      if (!!user && !!user.screen != !!screen) {
        let shared = !!user.screen;
        let done = () => resolve(!!shared);
        await this.dispatch(
          conferenceMediaMessageAction({
            message: this.messagingService.initializeMessage(<
              ConferenceMediaMessage
              >{
              type: ConferenceMediaMessageType,
              from: <ConferenceParticipant>{ ...user, screen },
              conferenceId: conferenceId,
            }),
            userId: this.userId,
            send: false,
          })
        );
        try {
          shared = await conference.shareScreen(screen);
        } catch (error) {
          done = () => reject(error);
        } finally {
          //let streamTrack = shared ? (<BehaviorSubject<MediaStream>>conference?.screenStreamWrapper?.mediaStream$)?.value?.getTracks()?.find(t=>true)?.id : undefined;
          const tracks = { ...user.tracks };
          const send = !!shared != !!user.screen;
          if (shared && !!conference.screenStreamWrapper?.hasVideo) {
            tracks.screen = conference.screenStreamWrapper.videoTrackIds[0];
          } else {
            delete tracks['screen'];
          }
          await this.dispatch(
            conferenceMediaMessageAction({
              message: this.messagingService.initializeMessage(<
                ConferenceMediaMessage
              >{
                type: ConferenceMediaMessageType,
                from: <ConferenceParticipant>{
                  ...user,
                  screen: shared,
                  tracks
                },
                conferenceId: conferenceId,
              }),
              userId: this.userId,
              send
            })
          );
          done();
        }
      } else {
        resolve(!!user.screen);
      }
    });
  }

  /*
  shareScreen(conferenceId:string,screen:boolean):Promise<boolean> {
    let conference  = this.getConference(conferenceId);
    let participant = conference?.info?.participant;
    if (!!participant) {
      return this.internalShareScreen(conference,participant,screen);
    }
    return Promise.reject("conference:"+conferenceId+" invalid");
  }

  protected internalShareScreen(conference:Conference,participant:ConferenceParticipant,screen:boolean):Promise<boolean> {
    if (!!conference?.info?.conferenceId && !!participant?.id) {
      if (!!participant.screen == !!conference.screen &&
          !!participant.screen == !!screen) {
        return Promise.resolve(screen);
      } else {
        const conferenceId  = conference.info.conferenceId;
        return new Promise<boolean>(async (resolve, reject) => {
          let shared = !!participant.screen;
          let done   = ()=>resolve(!!shared);
          let getScreenTrackId = () => {
            const screenStreamWrapper = (<InternalMediaStreamWrapper>conference.screenStreamWrapper);
            const screenStream = screenStreamWrapper.mediaStream;
            const screenTrack  = screenStream?.getVideoTracks().length>0 ? screenStream.getVideoTracks()[0] : undefined;
            return screenTrack?.id;
          };
          if (!!conference.screen != !!screen) {
            try {
              shared = await conference.shareScreen(screen);
            } catch(error) {
              done = ()=>reject(error);
            }
          }
          if (!!shared != !!screen ||   // we tried share, but cannot...
              !!participant.screen != !!screen) {
            const screenTrackId = getScreenTrackId() ?? participant.tracks?.[SCREEN_TRACK];
            const tracks = {... participant.tracks ?? {}, SCREEN_TRACK:screenTrackId};
            await this.dispatch(conferenceMediaMessageAction({
              message: this.messagingService.initializeMessage(<ConferenceMediaMessage>{
                type: ConferenceMediaMessageType,
                from: <ConferenceParticipant>{ ...participant, screen, tracks },
                conferenceId:conferenceId
              }),
              userId: this.userId,
              send:true
            }));
          }
          done();
        });
      }
    }
    return Promise.reject("illegal parameter");
  }*/

  canShareScreen(conferenceId: string): boolean {
    return !!this.getConference(conferenceId)?.canShareScreen;
  }

  pickUpConference(
    conferenceId: string,
    audio: boolean = true,
    video: boolean = false
  ): Promise<ConferenceInfo> {
    return new Promise<ConferenceInfo>((resolve, reject) => {
      let userId = this.userId;
      const connectionId = this.connectionId;
      firstValueFrom(this.getConferenceInfo$(conferenceId))
        .then((info) => {
          let participant =
            info?.ringing.find((p) => p.id == userId) ??
            info?.pickedUp.find((p) => p.id == userId) ??
            info?.paused.find((p) => p.id == userId);
          this.logger.info(
            "pickup.info",
            info,
            "\nparticipant",
            participant,
            "\nconnectionId",
            connectionId
          );
          if (
            !!participant &&
            (!participant.connectionId ||
              participant.connectionId != connectionId)
          ) {
            let mute =
              !!info?.muted &&
              participant?.role != "admin" &&
              participant?.role != "owner";
            if (!this.conferences[conferenceId]) {
              this.conferences[conferenceId] = new Conference(
                userId,
                info,
                audio && !mute,
                video,
                (conference) => this.updatedConferenceMediaStream(conference),
                this.log
              );
            }
            this.dispatch(
              conferencePickUpMessageAction({
                message: this.messagingService.initializeMessage(<
                  ConferencePickUpMessage
                >{
                  type: ConferencePickUpMessageType,
                  from: <ConferenceParticipant>{
                    type: "conference",
                    id: userId,
                    connectionId: this.connectionId,
                    audio: audio && !mute,
                    video: video,
                  },
                  conferenceId: conferenceId,
                }),
                userId: userId,
                send: true,
              })
            )
              .then((action) => resolve(action.conference))
              .catch(reject);
          } else {
            return resolve(info);
          }
        })
        .catch(reject);
    });
  }

  pauseActiveConference(): Promise<ConferenceInfo> {
    return this.getActiveConferenceInfo$()
      .pipe(
        first(),
        switchMap((activeConference) => {
          return !activeConference?.conferenceId
            ? of(undefined)
            : from(
                this.dispatch(
                  conferencePauseMessageAction({
                    message: this.messagingService.initializeMessage(<
                      ConferencePauseMessage
                    >{
                      type: ConferencePauseMessageType,
                      from: <ConferenceParticipant>{
                        type: "conference",
                        id: this.userId,
                        connectionId: this.connectionId,
                        audio: false,
                        video: false,
                        muted: false,
                      },
                      conferenceId: activeConference.conferenceId,
                    }),
                    userId: this.userId,
                    send: true,
                  })
                )
              );
        })
      )
      .pipe(map((action) => action?.conference))
      .toPromise();
  }

  hangUpConference(conferenceId: string): Promise<ConferenceInfo> {
    return from(
      this.dispatch(
        conferenceHangUpMessageAction({
          message: this.messagingService.initializeMessage(<
            ConferenceHangUpMessage
          >{
            type: ConferenceHangUpMessageType,
            from: {
              type: "conference",
              id: this.userId,
              connectionId: this.connectionId,
            },
            conferenceId: conferenceId,
          }),
          userId: this.userId,
          send: true,
        })
      )
    )
      .pipe(map((action) => action.conference))
      .toPromise();
  }

  inviteToConference(
    conferenceId: string,
    participantId: string
  ): Promise<ConferenceInfo> {
    this.messagingService.sendMessage(this.messagingService.initializeMessage(<LogMessage>{
      type: LogMessageType,
      object: {
        system: 'conference',
        action: 'inviteToConference',
        participantId: this.userId,
        invitedId: participantId,
        conferenceId: conferenceId,
      }
    }));
    return from(
      this.dispatch(
        conferenceInviteMessageSendAction({
          message: this.messagingService.initializeMessage(<
            ConferenceInviteMessage
          >{
            type: ConferenceInviteMessageType,
            from: {
              type: "conference",
              id: this.userId,
              connectionId: this.connectionId,
            },
            conferenceId: conferenceId,
            invited: [
              {
                id: participantId,
              },
            ],
          }),
        })
      )
    )
      .pipe(map((action) => action.conference))
      .toPromise();
  }

  getMediaDevices(
    kind?: "audioinput" | "audiooutput" | "videoinput"
  ): Observable<
    (MediaDeviceInfo & { default?: boolean; selected?: boolean })[]
  > {
    // ATTENTION: on chrome audioinput and audiooutput devices are swapped
    if (!ConferenceService.mediaDevices) {
      const mediaDevices = () =>
        navigator.mediaDevices?.enumerateDevices
          ? navigator.mediaDevices.enumerateDevices()
          : Promise.reject("mediaDevices.enumerateDevices() API not supported");
      ConferenceService.mediaDevices = concat(
        from(mediaDevices()),
        fromEvent(navigator.mediaDevices, "devicechange").pipe(
          concatMap((event) => from(mediaDevices()))
        )
      ).pipe(
        map((mediaDevices) => {
          // The order is significant — the default capture devices will be listed first.
          // Default device id should be handled specifically for Chrome and Opera
          // because the default device is listed twice with different ids (incl. deviceId: 'default'):
          // see https://stackoverflow.com/questions/53304934/why-does-mediadevices-enumeratedevices-list-some-devices-twice-what-is-default
          return mediaDevices.reduce(
            (accumulator, device) => {
              if (!kind || kind == device.kind) {
                if (device.deviceId == this.audioOutputDeviceId) {
                  device["selected"] = true;
                }
                const index =
                  device.kind == "audioinput"
                    ? 0
                    : device.kind == "audiooutput"
                    ? 1
                    : 2;
                const defaultDeviceIndex = accumulator.defaultIndexes[index];
                if (defaultDeviceIndex == undefined) {
                  device["default"] = true;
                  accumulator.devices.push(device);
                  accumulator.defaultIndexes[index] =
                    accumulator.devices.length - 1;
                } else {
                  const currentDefault =
                    accumulator.devices[defaultDeviceIndex];
                  if (device.deviceId == "default") {
                    if (currentDefault.groupId == device.groupId) {
                      currentDefault.default = true;
                    } else {
                      accumulator.devices[defaultDeviceIndex] = device;
                    }
                  } else if (
                    currentDefault.groupId == device.groupId &&
                    currentDefault.deviceId == "default"
                  ) {
                    accumulator.devices[defaultDeviceIndex] = device;
                  } else {
                    accumulator.devices.push(device);
                  }
                }
              }
              return accumulator;
            },
            {
              defaultIndexes: [],
              devices: [] as (MediaDeviceInfo & { default?: boolean })[],
            }
          ).devices;
        }),
        shareReplay(1)
      );
    }
    return ConferenceService.mediaDevices.pipe(
      map((devices) => devices.filter((device) => !kind || device.kind == kind))
    );
  }

  setAudioOutputDevice(
    deviceId: string,
    element?: HTMLMediaElement & {
      setSinkId?: (sinkId) => Promise<void>;
      sinkId?: string;
    }
  ) {
    if (deviceId && element) {
      if (
        this.canChangeAudioOutputDevice &&
        typeof element.setSinkId !== "undefined"
      ) {
        if (element.sinkId != deviceId) {
          return element
            .setSinkId(deviceId)
            .then(() => {
              this.audioOutputDeviceId = deviceId;
              this.logger.info(
                `Audio output device set: [ element: ${element}, deviceId: ${deviceId} ]`
              );
            })
            .catch((error) =>
              this.logger.error(
                `Failed to set audio output device: ${error.message}`
              )
            );
        } else {
          if (this.audioOutputDeviceId != deviceId) {
            this.audioOutputDeviceId = deviceId;
          }
          return Promise.resolve(
            `Audio output device is already set [ element: ${element}, deviceId: ${deviceId} ] - the operation had no effect`
          );
        }
      } else {
        return Promise.reject("Audio output device selection is not supported");
      }
    } else {
      return Promise.all([
        this.setAudioOutputDevice(deviceId, this.sounds.conferenceAudio()),
        this.setAudioOutputDevice(deviceId, this.sounds.conferenceRing().audio),
        this.setAudioOutputDevice(deviceId, this.sounds.conferenceFree().audio),
        this.setAudioOutputDevice(deviceId, this.sounds.conferenceBusy().audio),
        this.setAudioOutputDevice(deviceId, this.sounds.conferenceEnd().audio),
      ]).then((result) => Promise.resolve());
    }
  }

  canChangeAudioOutputDevice(): boolean {
    // https://caniuse.com/mdn-api_htmlmediaelement_setsinkid
    // currently only supported by desktop chrome and opera
    return "sinkId" in HTMLMediaElement.prototype;
  }
}
