/* projects/contact/src/lib/components/contact-detail/contact-detail.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: column;
}
.header-container {
  position: relative;
  z-index: 1;
  box-shadow: none;
  display: flex;
  align-items: center;
}
.header-container > .header {
  flex: 1;
  padding: 16px 20px;
  transition: padding 0.5s ease;
}
.header-container > .header-control {
  position: absolute;
  bottom: 40px;
  z-index: 1;
}
.header-container > .header-control:first-of-type {
  left: 0;
}
.header-container > .header-control:last-of-type {
  right: 0;
}
.header-container.large > .header {
  flex-direction: column;
  align-items: flex-start;
}
.header-container.large > .header > img {
  margin-right: 0;
  border-radius: 8px;
}
.header-container.large > .header > div {
  margin-top: 8px;
}
.header-container.large > .header > div.name-container {
  min-height: 42px;
}
.header-container.large > button {
  bottom: 28px;
}
.header {
  display: flex;
  align-items: center;
  height: auto;
  min-height: 64px;
  border-radius: 4px;
}
.header > img {
  width: 60px;
  height: 60px;
  margin-right: 16px;
  transition:
    width 500ms,
    height 500ms,
    border-radius 500ms;
  pointer-events: unset;
}
.header > div {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.header > div .name {
  word-break: break-word;
}
.header > div app-partner-id {
  margin-top: 4px;
}
.header > div.name-container {
  justify-content: center;
}
accordion-container {
  flex: 1;
}
.mat-expansion-indicator {
  position: absolute;
  right: 20px;
  bottom: 8px;
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
  font-size: 15px;
  font-weight: 400;
  overflow: visible;
  transition: transform 300ms;
}
.expanded .mat-expansion-indicator {
  transform: rotate(180deg);
}
:host-context(.color-scheme-light.pure) .header-container {
  box-shadow: 0 0 4px 0 grey;
}
:host-context(.color-scheme-dark) .header {
  background-color: #282828;
}
:host-context(.color-scheme-dark) button.close {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) h4 {
  color: #AEAFB0;
}
/*# sourceMappingURL=contact-detail.component-GA6QNN2W.css.map */
