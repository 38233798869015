import {Injectable} from "@angular/core";
import {
  Contact,
  CorrelationIdGenerator,
  EMPTY_ARRAY,
  EntityService,
  Logger,
  SearchService,
  Topic,
  TypedFilterService,
  User,
  VersionedId
} from "core";
import {Action, ActionsSubject, Store} from "@ngrx/store";
import {asyncScheduler, firstValueFrom, from, Observable, Subscription, tap, throwError} from "rxjs";
import * as fromContact from "./store/state";
import {selectContactLoading, selectSubscribed} from "./store/state";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  first,
  map,
  mergeMap,
  take,
  takeUntil
} from "rxjs/operators";
import isEqual from "lodash/isEqual";

import {
  contactDeleteAction,
  contactDeleteDoneAction,
  contactDeleteFailedAction,
  contactLoadRequestAction,
  contactSelectAction,
  contactSetTypedFiltersAction,
  contactSubscribeAction,
  contactUnsubscribeAction,
  contactUpdateAction,
  contactUpdateDoneAction,
  contactUpdateFailedAction,
  contactUpdateSearchTermAction,
  groupAuthorsLoadAction
} from "./store/actions";
import {AuthenticationService} from "auth";
import {PropertiesService} from "properties";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import {HttpClient} from "@angular/common/http";
import {StoreService} from "store";
import {MessageEnvelope, MessageHandlerRegistry, MessagingService} from "messaging";
import {
  ContactReachValues,
  ContactSubscriptionMessage,
  ContactSubscriptionMessageType,
  ContactUpdateMessage,
  ContactUpdateMessageType,
  TermTestMessage,
  TermTestMessageType,
  TermTestResult,
  TermTestResultMessage
} from "./store/models";

// bound in menu service, where we know whats allowed in which group....
export interface ConversationHook {
  canConnect$(contactId?:string):Promise<boolean>; // if we can connect at all
  connect(contactId?:string):Promise<void>;        // if contactId is undefined, it should fall on hosts conversation....
  disconnect(contactId?:string):Promise<void>;     // hangup (only calls)
  // shows if conversation exists or call is established
  // chat: the conversationId if already there
  // call: undefined (no call), 'in' (incoming), 'out' (outgoing), 'paused'
  state$(contactId?:string):Observable<string>;
}

@Injectable({
  providedIn: 'root'
})
export class ContactService extends StoreService implements SearchService, TypedFilterService, /*SelectableService,*/ EntityService<Contact>  {

  protected _chatHook: ConversationHook = undefined;
  protected _callHook: ConversationHook = undefined;

  protected logger = new Logger('ContactService');

  protected messageHandlerRegistry = new MessageHandlerRegistry();
  protected contactSubscription:Subscription = undefined;

  constructor(
    protected store$: Store<any>,
    protected action$: ActionsSubject,
    protected correlationIdGenerator: CorrelationIdGenerator,
    protected messagingService: MessagingService,
    protected authenticationService: AuthenticationService,
    protected propertiesService: PropertiesService,
    protected httpClient: HttpClient) {
    super(store$,action$);
    console.log('ContactService.ctor');
    this.messagingService.open$.pipe().subscribe(open => {
      //console.log("OPENSTATE",open);
      this.contactSubscription?.unsubscribe();
      this.contactSubscription = undefined;
      if (open) {
        this.contactSubscription = this.store$.select(selectSubscribed)
          .pipe(
            debounceTime(30),
            distinctUntilChanged((keys1:VersionedId[],keys2:VersionedId[])=> {
              // before: isEqual(keys1,keys2)
              // now:
              // we compare only the ids, not the versions, as first time we request
              // with version==0, and after server sent the object, we request with the
              // real version, so we get a change, but we don't want to send a message
              const keys1Length = keys1?.length ?? 0;
              const keys2Length = keys2?.length ?? 0;
              return keys1Length == keys2Length &&
                    (keys1Length == 0 || keys1.every((key1:VersionedId,index:number)=>keys2[index].id==key1.id));
            })
          )
          .subscribe(versionedIds => {
            // console.log("contact.service.subscribe",versionedIds);
            this.messagingService.sendMessage(this.messagingService.initializeMessage(<ContactSubscriptionMessage>{
              type: ContactSubscriptionMessageType,
              subscribed: versionedIds ?? []
            }));
          });
      }
    });
    let handleContactUpdateMessage = (envelope:MessageEnvelope):void => {
      //console.log("xbug.handleContactUpdateMessage",envelope);
      let message = <ContactUpdateMessage>envelope.message;
      asyncScheduler.schedule(() => this.store$.dispatch(contactUpdateDoneAction({contact:message.contact})));
    };
    this.messageHandlerRegistry.addMessageHandler(ContactUpdateMessageType,handleContactUpdateMessage);
    this.messagingService
      .register(envelope => this.messageHandlerRegistry.hasMessageHandler(envelope.message?.type))
      .subscribe(envelope => {
        this.messageHandlerRegistry.getMessageHandler(envelope.message.type)(envelope);
      });
  }

  selectAuthors$():Observable<Contact[]> {
    return this.store$.select(fromContact.selectGroupAuthors,this.propertiesService.groupId);
  }

  loadAuthors$(force?:boolean):Observable<Contact[]> {
    this.dispatch(groupAuthorsLoadAction({groupId:this.propertiesService.groupId, force}));
    return this.selectAuthors$();
  }

  getContact$(id:string, defaultContact?:Contact):Observable<Contact> {
    //console.log("xbug.contactService.getContact$",id,defaultContact);
    let subscribed = false;
    let ensureSubscription = (contact:Contact) => {
      if (!subscribed) {
        subscribed = true;
        //console.log("xbug.contactService.getContact$.subscribe",id);
        asyncScheduler.schedule(() => this.dispatch(contactSubscribeAction({ id, contact: contact ?? defaultContact })));
      }
    }
    return this.store$.select(fromContact.selectContact,id).pipe(
      distinctUntilChanged((contact1:Contact,contact2:Contact)=>isEqual(contact1,contact2)),
      finalize(() => {
        //console.log("media.service.getMedia$.finalize",id,"subscribed",subscribed);
        if (subscribed) {
          //console.log("xbug.contactService.getContact$.finalize",id);
          subscribed = false;
          window.setTimeout(()=>
             this.store$.dispatch(contactUnsubscribeAction({ id })),
            1000);
        }
      }),
      tap(contact => ensureSubscription(contact)),
      map(contact => {
        return contact?.timeDeleted ? undefined :
          contact ?? defaultContact;
      })
      //tap(contact =>  console.log("xbug.contactService.getContact$.tap3",id,{...contact}))
    );
  }

  get chatHook(): ConversationHook {
    return this._chatHook;
  }

  set chatHook(hook: ConversationHook) {
    this._chatHook = hook;
  }

  get callHook(): ConversationHook {
    //TODO: enable calls from contact sidebar later when it works...
    return undefined;//this._callHook;
  }

  set callHook(hook: ConversationHook) {
    this._callHook = hook;
  }

  get contactId(): string {
    return this.authenticationService.user?.id;
  }

  loadRequest() : void {
    //console.log("CONTACT.loadRequest()");
    this.store$.dispatch(contactLoadRequestAction());
  }

  get entities$() : Observable<Contact[]> {
    return this.store$.select(fromContact.selectContactEntities);
  }

  get cacheId$() : Observable<string> {
    return this.store$.select(fromContact.selectContactCacheId);
  }

  get size$() : Observable<number> {
    return this.store$.select(fromContact.selectContactEntitiesLength);
  }

  get filters$() : Observable<string[]> {
    return this.getCombinedFilters$();
  }

  /**
   * set typed filters for all types at once...
   * @param typedFilters  0.n typed filters
   * @param remove        callback to check if existing type/filters other the given types should be removed....
   * @returns boolean true if updated, else false
   */
  setTypedFilters(typedFilters:{[key:string]:string[]},remove?:(type:string,filters:string[])=>boolean) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      typedFilters = {...typedFilters};
      Object.keys(typedFilters).forEach(type=>[...typedFilters[type]].sort());
      let updated = false;
      let added   = {...typedFilters};
      firstValueFrom(this.getTypedFilters$((type,previous)=>{
        delete added[type];
        if (!updated) {
          const filters = typedFilters[type];
          updated = !!filters || !remove ? !isEqual(filters,previous) : remove(type,previous);
        }
        return true;
      }))
        .then(allFilters => {
          updated = updated || Object.keys(added).length>0;
          this.dispatch(contactSetTypedFiltersAction({filters:typedFilters,remove}))
            .then(()=>resolve(updated))
            .catch(error=>reject(error));
        })
        .catch(error=>reject(error));
    });
  }

  /**
   * get all typed filters which are not excluded by given callback
   * @param exclude     callback to check if this type of filter should not be returned
   * @returns a copy of all requested typed filters
   */
  getTypedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<{[key:string]:string[]}> {
    return this.store$.select(fromContact.selectContactFilters).pipe(
      map(filters=>{
        const result:{[key:string]:string[]} = {};
        Object.keys(filters).forEach(type=>{
          if (!exclude || !exclude(type,filters[type])) {
            result[type] = [...filters[type]];
          }
        });
        return result;
      })
    );
  }

  loadContextFilters$(contactId:string, path?: string)
    :Promise<{
      filters:Topic[],
      canViewDomains?:boolean,        // previously by tags in menu topics, now by context
      canEditDomains?:boolean,        // -"-
      canViewInterests?:boolean}> {   // -"-
    return new Promise((resolve, reject) => {
      firstValueFrom(this.httpClient.get<any>(`/v1.0/contacts/filters/${contactId}`, { params: path ? { path } : {}}))
        .then(resolve)
        .catch(reject);
    });
  }

  loadContactReach$(contactId:string, tags:string[])
    :Promise<ContactReachValues[]> {
    return new Promise((resolve, reject) => {
      firstValueFrom(this.httpClient.get<any>(`/v1.0/contacts/reach/${contactId}`, { params: { filters:tags }}))
        .then(result=>{
          resolve(result.values ?? EMPTY_ARRAY);
        })
        .catch(reject);
    });
  }


  /**
   * get all typed filters which are not excluded by given callback in one string array
   * @param exclude     callback to check if this type of filter should not be returned
   * @returns a combined array of all requested typed filters
   */
  getCombinedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<string[]> {
    return this.store$.select(fromContact.selectContactFilters).pipe(
      map(filters=>{
        const result:string[] = [];
        Object.keys(filters).forEach(type=>{
          if (!exclude || !exclude(type,filters[type])) {
            result.push(...filters[type]);
          }
        });
        return result.sort();
      })
    );
  }

  get uplines$() : Observable<{[key: string]: Contact[]}> {
    return this.store$.select(fromContact.selectContactUplines);
  }

  updateSearchTerm(term : string) : Promise<boolean> {
    return new Promise<boolean>(resolve => {
      //console.log("CONTACT.updateSearchTerm",term);
      this.store$.select(fromContact.selectContactSearchTerm).pipe(first())
        .subscribe(current => {
          term = (term||'').trim();
          current = (current||'').trim();
          if (term != current) {
            this.dispatch(contactUpdateSearchTermAction({term}))
              .finally(()=>resolve(true))
          } else {
            resolve(false);
          }
        })
    });
  }

  get searchTerm$() : Observable<string> {
    return this.store$.select(fromContact.selectContactSearchTerm).pipe(
      distinctUntilChanged((a,b) =>isEqual(a,b))
    );
  }

  select(selectedIndex: number) : void {
    //of(new ContactSelectAction(selectedIndex)).pipe(first()).subscribe(this.store$);
    this.store$.dispatch(contactSelectAction({selectedIndex}));
  }

  get loading$() : Observable<boolean> {
    return this.store$.select(selectContactLoading);
  }

  update(contact: Contact, previous?: Contact): Promise<Contact> {
    if (contact && contact.id) {
      const correlationId = this.correlationIdGenerator.next();
      const promise = new Observable<any>(subscriber => {
        const reducer = `updateContact_${correlationId}`;
        this.store$.addReducer(reducer, (state, action: {
          correlationId?: string,
          contact: Contact,
          error?: any
        } & Action) => {
          if (action.type == contactUpdateDoneAction.type  ||
            action.type == contactUpdateFailedAction.type) {
            console.debug(reducer, state, action);
            if (action.contact && action.contact.id==contact.id) {
              if (action.type == contactUpdateDoneAction.type) {
                subscriber.next(action.contact);
              } else {
                subscriber.error(action.error);
              }
              subscriber.complete();
              this.store$.removeReducer(reducer);
            }
          }
          return state;
        });
      }).toPromise();
      this.store$.dispatch(contactUpdateAction({contact, previous, correlationId}));
      return promise;
    } else {
      return Promise.reject('Invalid contact');
    }
  }

  delete(contact: Contact): Promise<Contact> {
    return new Promise<Contact>((resolve, reject) => {
      const correlationId = this.correlationIdGenerator.next();
      const reducer = `deleteContact_${correlationId}`;
      this.store$.addReducer(reducer, (state, action: {
        correlationId?: string,
        contact: Contact,
        error?: any
      } & Action) => {
        if (action.type == contactDeleteDoneAction.type  ||
            action.type == contactDeleteFailedAction.type) {
          console.debug(reducer, state, action);
          if (action.correlationId == correlationId) {
            if (action.type == contactDeleteDoneAction.type) {
              //this.loadRequest();
              resolve(action.contact);
            } else {
              reject(action.error);
            }
            this.store$.removeReducer(reducer);
          }
        }
        return state;
      });
      this.store$.dispatch(contactDeleteAction({correlationId,contact}));
    });
  }

  syncContact(contact: Contact): void {
    this.store$.dispatch(contactUpdateDoneAction({contact}));
  }

  get languages(): Observable<string[]> {
    return this.selectLru<string>('languages');
  }

  updateLanguages(languages: string[]): Promise<void> {
    return this.updateLru('languages', languages);
  }

  get timezones(): Observable<string[]> {
    return this.selectLru<string>('timezones');
  }

  updateTimezones(timezones: string[]): Promise<void> {
    return this.updateLru('timezones', timezones);
  }

  get taskLists(): Observable<string[]> {
    return this.selectLru<string>('taskLists');
  }

  updateTaskLists(taskLists: string[]): Promise<void> {
    return this.updateLru('taskLists', taskLists);
  }

  selectLru <T = any> (key: string): Observable<T[]>{
    return this.propertiesService.user$.pipe(
      map((user) => get(user, `app.lru.${key}`, []).slice())
    );
  }

  protected updateLru(key: string, value: any): Promise<void> {
    const properties = cloneDeep(this.propertiesService.properties);
    const user = properties.user;
    set(properties.user, `app.lru.${key}`, value);
    this.logger.debug('updateLru', { key, value });
    return this.updateUser(user)
      .then(user => {
          this.logger.debug('updateLru -> SERVER UPDATE COMPLETED', { user });
          // The following assignment causes issues maybe due to discrepancy
          // in data structure of Properties.user and the object which comes back from this api call
          // properties.user = user;
          this.propertiesService.update(properties);
          this.logger.debug('updateUser -> PROPERTIES UPDATED', { properties: this.propertiesService.properties });
        },
        error => this.logger.error('updateLru', { error })
      );

    /*
    return this.update(properties.user, this.propertiesService.user)
      .then((contact) => {
        this.logger.debug('updateLru -> CONTACT UPDATED', { contact });
        this.propertiesService.update(properties);
        this.logger.debug('updateLru -> PROPERTIES UPDATED', { properties: this.propertiesService.properties });
      })
      .catch(error => this.logger.error('updateLru', { error }));
     */
  }

  protected updateUser(user: User): Promise<User> {
    if (user.id === this.propertiesService.user.id) {
      this.logger.debug('updateUser', { user });
      return this.httpClient.post<any>(`/v1.0/contacts/update/${user.id}`, user)
        .toPromise()
        .then(response => {
            if (response?.done && response.contact) {
              const user = new User(response.contact);
              this.logger.debug('updateUser -> USER UPDATED', { user });
              this.syncContact(user);
              return user;
            } else {
              throw new Error(`Failed to update current user [id: ${user.id}]. Server response: ${response}`);
            }
          }
        );
    } else {
      this.logger.warn('Attempt to update another user');
    }
  }

  updatePartnerId(userId = this.propertiesService.user.id, partnerId: string, cancel: Observable<any>) {
    const command = partnerId.length>0 ? partnerId.trim() : "remove";
    if (userId) {
      return this.httpClient.get<any>(`/v1.0/contacts/partner/${userId}/${command}`)
        .pipe(
          takeUntil(cancel),
          debounceTime(300, asyncScheduler),
          mergeMap((response) => {
            if (response && response.done) {
              return from(this.propertiesService.reload())
            } else {
              throwError(new Error('Failed to update Partner Number. Server reported error code'));
            }
          }),
          catchError((error, caught) => {
            this.logger.error('updatePartnerNumber. Error', error);
            return throwError('Failed to update partner number', error);
          })
        )
    } else {
      throwError("Invalid userId");
    }
  }

  testTerm$(term:string):Promise<TermTestResult>{
    return new Promise<TermTestResult>((resolve, reject) => {
      this.messagingService.sendMessage(this.messagingService.initializeMessage(<TermTestMessage>{
        type: TermTestMessageType,
        term
      }),true)
        .then((result:MessageEnvelope) => {
          resolve(<TermTestResultMessage>(result?.message));
        })
        .catch(reject);
    });
  }
}
