/* projects/contact/src/lib/components/contact-detail/contact-detail-info/contact-detail-info.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.header {
  display: flex;
  align-items: center;
  min-height: 64px;
  padding: 4px 8px;
  border-radius: 4px;
}
.header > img {
  width: 60px;
  height: 60px;
  margin-right: 16px;
  transition:
    width 500ms,
    height 500ms,
    border-radius 500ms;
}
.header > div {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.header > div .name {
  flex: 1;
  word-break: break-word;
}
.header > div app-partner-id {
  margin-top: 4px;
}
.header.large {
  flex-direction: column;
  align-items: flex-start;
}
.header.large > img {
  margin-right: 0;
  border-radius: 8px;
}
.header.large > div {
  margin-top: 8px;
}
.address {
  padding: 8px;
}
.address > div {
  padding-top: 2px;
  padding-bottom: 2px;
}
.address > div > span {
  padding-right: 4px;
}
.address > div > span:last-child {
  padding-right: 0;
}
.address > div:last-child {
  padding-bottom: 0;
}
.links {
  display: flex;
  flex-direction: column;
  color: grey;
  padding: 8px;
}
.links > div.birthday,
.links > div.location-map-link {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.links > div.birthday mat-icon,
.links > div.location-map-link mat-icon {
  color: var(--color-primary);
  margin: 1px;
}
.links > div.birthday svg,
.links > div.location-map-link svg {
  width: 24px;
  height: 24px;
}
.links > div.birthday span,
.links > div.location-map-link span {
  color: rgba(0, 0, 0, 0.87);
  padding-top: 4px;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.links > div.location-map-link:hover {
  cursor: pointer;
}
.links > div.location-map-link:hover span {
  color: rgba(0, 0, 0, 0.47);
}
.links > a {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}
.links > a svg {
  width: 24px;
  height: 24px;
}
.links > a span {
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.links > a.email > mat-icon {
  color: var(--color-primary);
  margin: 1px;
}
.links > a.chat > mat-icon {
  color: var(--color-primary);
  margin: 1px;
}
.links > a.phone > mat-icon {
  color: var(--color-primary);
  margin: 1px;
}
.links > a.phone.accept,
.links > a.phone.accept:hover:not(.disabled) {
  cursor: pointer;
}
.links > a.phone.accept mat-icon,
.links > a.phone.accept:hover:not(.disabled) mat-icon {
  position: relative;
  color: white;
  margin: 1px;
}
.links > a.phone.accept mat-icon:before {
  content: "";
  position: absolute;
  background-color: limegreen;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  z-index: -1;
  border-radius: 17px;
}
.links > a.phone.hangUp,
.links > a.phone.hangUp:hover:not(.disabled) {
  cursor: pointer;
}
.links > a.phone.hangUp mat-icon,
.links > a.phone.hangUp:hover:not(.disabled) mat-icon {
  position: relative;
  color: white;
  margin: 1px;
}
.links > a.phone.hangUp mat-icon:before {
  content: "";
  position: absolute;
  background-color: var(--color-accent);
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  z-index: -1;
  border-radius: 17px;
}
.links > a.phone.hangup:hover {
  cursor: pointer;
}
.links > a.phone > span.copy {
  user-select: text;
}
.links > a.whatsapp svg {
  width: 26px;
  height: 26px;
  margin: 0;
  fill: #29B540;
}
.links > a.messenger svg {
  width: 22px;
  height: 22px;
  margin: 2px;
  fill: #0084ff;
}
.links > a.wechat svg {
  width: 22px;
  height: 22px;
  margin: 2px;
  fill: #2DC100;
}
.links > a.telegram svg {
  width: 22px;
  height: 22px;
  margin: 2px;
}
.links > a.telegram svg #background,
.links > a.telegram svg #shadow {
  fill: #2CA5E0;
}
.links > a:hover:not(.disabled) {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.47);
}
.links > a:hover:not(.disabled).whatsapp svg {
  fill: limegreen;
}
.links > a:hover:not(.disabled).messenger svg {
  fill: deepskyblue;
}
.links > a:hover:not(.disabled).wechat svg {
  fill: yellowgreen;
}
.links > a:hover:not(.disabled).telegram svg #background,
.links > a:hover:not(.disabled).telegram svg #shadow {
  fill: #2CB3E8;
}
h4 {
  display: block;
  margin: 0 0 10px;
  padding: 0;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.54);
}
.location-map {
  position: relative;
  padding: 0 8px 0 8px;
}
.location-map > div {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  height: 160px;
}
.location-map > div > map {
  height: 100%;
}
:host-context(.color-scheme-dark) .links {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .links > div.birthday mat-icon,
:host-context(.color-scheme-dark) .links > div.location-map-link mat-icon {
  color: var(--color-primary);
  margin: 1px;
}
:host-context(.color-scheme-dark) .links > div.birthday span,
:host-context(.color-scheme-dark) .links > div.location-map-link span {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .links > div.location-map-link:hover span {
  color: rgba(229, 229, 229, 0.7);
}
:host-context(.color-scheme-dark) .links > a {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .links > a.email mat-icon {
  color: var(--color-primary);
  margin: 1px;
}
:host-context(.color-scheme-dark) .links > a.phone mat-icon {
  color: var(--color-primary);
  margin: 1px;
}
:host-context(.color-scheme-dark) .links > a.phone.accept mat-icon,
:host-context(.color-scheme-dark) .links > a.phone.accept:hover:not(.disabled) mat-icon {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .links > a.phone.hangUp mat-icon,
:host-context(.color-scheme-dark) .links > a.phone.hangUp:hover:not(.disabled) mat-icon {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .links > a.whatsapp svg {
  width: 26px;
  height: 26px;
  margin: 0;
  fill: rgba(41, 181, 64, 0.7);
}
:host-context(.color-scheme-dark) .links > a.messenger svg {
  width: 22px;
  height: 22px;
  margin: 2px;
  fill: rgba(0, 132, 255, 0.7);
}
:host-context(.color-scheme-dark) .links > a.wechat svg {
  width: 22px;
  height: 22px;
  margin: 2px;
  fill: rgba(45, 193, 0, 0.7);
}
:host-context(.color-scheme-dark) .links > a.telegram svg {
  width: 22px;
  height: 22px;
  margin: 2px;
}
:host-context(.color-scheme-dark) .links > a.telegram svg #background,
:host-context(.color-scheme-dark) .links > a.telegram svg #shadow {
  fill: rgba(44, 165, 224, 0.7);
}
:host-context(.color-scheme-dark) .links > a:hover:not(.disabled) {
  color: rgba(187, 187, 187, 0.8);
}
:host-context(.color-scheme-dark) .links > a:hover:not(.disabled).whatsapp svg {
  fill: rgba(50, 205, 50, 0.4);
}
:host-context(.color-scheme-dark) .links > a:hover:not(.disabled).messenger svg {
  fill: rgba(0, 191, 255, 0.4);
}
:host-context(.color-scheme-dark) .links > a:hover:not(.disabled).wechat svg {
  fill: rgba(154, 205, 50, 0.4);
}
:host-context(.color-scheme-dark) .links > a:hover:not(.disabled).telegram svg #background,
:host-context(.color-scheme-dark) .links > a:hover:not(.disabled).telegram svg #shadow {
  fill: rgba(44, 179, 232, 0.4);
}
:host-context(.color-scheme-dark) h4 {
  color: #AEAFB0;
}
/*# sourceMappingURL=contact-detail-info.component-R62PBEVJ.css.map */
