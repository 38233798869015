import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output} from '@angular/core';
import {ResizeService} from "shared";
import {Media, MediaService, MediaViewerOverlayService} from "media";
import {Attachment} from "messaging";
import {Distance} from "../../core/distance";
import {Space} from "../../core/space";
import {MediaAttachmentComponent} from "../core/media.attachment.component";
import {MediaAttachment} from "../../../../store/models";

@Component({
  selector: 'media-attachment-file-viewer',
  templateUrl: './media-attachment-file-viewer.component.html',
  styleUrls: ['./media-attachment-file-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaAttachmentFileViewerComponent extends MediaAttachmentComponent {

  mediae:Media[] = [];
  //protected static UNDEFINED = 'UNDEFINED';
  //protected _extensions = {};

  protected _space = undefined;//{fixedWidth:false,fixedRatio:false,fixedHeight:false};

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    super.attachments = attachments;
    this.mediae = [];
    this.attachments.forEach((attachment,index) => {
      const media:Media = (<MediaAttachment>attachment)?.media;
      if (!!media) {
        //console.log("MEDIA",media);
        this.mediae.push(media);
      }
    });
  }
  get attachments():Attachment[] {
    return super.attachments;
  }

  toStyleDistance(distance:Distance):Distance {
    distance.style = (distance.distance==0 ? '0' : distance.distance.toFixed(2)+(distance.realtive?'%':'px'));
    return distance;
  }

  get space():Space {
    return this._space;
  }

  constructor(
    protected mediaService: MediaService,
    protected resizeService: ResizeService,
    protected mediaViewerOverlayService: MediaViewerOverlayService,
    protected changeDetectorRef: ChangeDetectorRef) {
    super(mediaViewerOverlayService,changeDetectorRef);
  }

  trackBy = (index: number, media: Media) => {
    return media?.id + media?.version;
  };
}
