<!--<h4 [innerHTML]="'app.openInStore' | translate | safeHtml"></h4>-->
<div>
  <!--
  TODO:CHANGE TEXTS!!!
  <qr-code-all *ngIf="link" class="qr-code" #qrCode [qrCodeValue]="link" [qrCodeECLevel]="'L'"></qr-code-all>
  -->
  <!--
  <ngx-qrcode #qrCode
              *ngIf="link"
              class="qr-code"
              [qrc-value]="link"
              qrc-class="invitation-qr-code"
              qrc-errorCorrectionLevel="L"></ngx-qrcode>
              -->
  <div class="links">
    <!--
      google and apple badges:
      https://play.google.com/intl/en_us/badges/
      https://developer.apple.com/app-store/marketing/guidelines/#section-badges
    -->
    <div *ngIf="iosOrDesktop && appleStoreLink; let link" [class.space]="platform.is('desktop')">
      <div>
        <a [href]="link"
           target="_blank"
           class="badge apple"
           (click)="onTapLink($event)">
<!--       <img src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2010-04-02&kind=iossoftware&bubble=ios_apps" (load)="imageLoaded($event)"/>-->
          <img [src]="'/assets/icons/store/appstore_download.svg' | sessionLink" (load)="imageLoaded($event)"/>
        </a>
      </div>
    </div>
    <div *ngIf="androidOrDesktop && googlePlayLink; let link">
      <div>
        <a [href]="link"
           target="_blank"
           class="badge google"
           (click)="onTapLink($event)">
          <img src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" (load)="imageLoaded($event)"/>
        </a>
      </div>
    </div>
    <div *ngIf="android" class="alternative">
      <div *ngIf="!download" (click)="toDownload()"><span translate>app.install.googlePlayAlternative</span></div>
      <div #download *ngIf="download" (click)="onDownload(download)" class="install"><span translate>app.install.download</span></div>
    </div>
  </div>

  <!--<mat-nav-list>-->
  <!--<a href="https://play.google.com/store/apps/details?id=info.groupmanager.app" >-->
  <!--<span mat-line>Realizer</span>-->
  <!--<span mat-line>Install from Google Play Store</span>-->
  <!--</a>-->
  <!--<a href="https://www.apple.com/lae/ios/app-store/" mat-list-item (click)="openLink($event)">-->
  <!--<span mat-line>Realizer</span>-->
  <!--<span mat-line>Install from Apple App Store</span>-->
  <!--</a>-->
  <!--</mat-nav-list>-->
</div>
<!--<h4 [innerHTML]="'app.openInStore' | translate | safeHtml"></h4>-->

<h4 [innerHTML]="openInStore | async"></h4>
<!--<h4 [innerHTML]="translateService.stream('app.openInStore') | async"></h4>-->

<!--
<div class="info" *ngIf="!!info">{{info | translate}}</div>
-->
<button mat-icon-button
        class="close"
        [style.visibility]="imagesLoaded ? 'visible':'hidden'"
        [style.opacity]="imagesLoaded ? '1' : '0'"
        (click)="onTapClose($event)">
  <mat-icon>close</mat-icon>
</button>
