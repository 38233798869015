import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordovaPropertyGet, cordovaPropertySet } from '@ionic-native/core';
var WebView = /** @class */function (_super) {
  __extends(WebView, _super);
  function WebView() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Object.defineProperty(WebView.prototype, "convertFileSrc", {
    get: function () {
      return cordovaPropertyGet(this, "convertFileSrc");
    },
    set: function (value) {
      cordovaPropertySet(this, "convertFileSrc", value);
    },
    enumerable: false,
    configurable: true
  });
  WebView.pluginName = "WebView";
  WebView.plugin = "cordova-plugin-ionic-webview";
  WebView.pluginRef = "window.Ionic.WebView";
  WebView.repo = "https://github.com/ionic-team/cordova-plugin-ionic-webview";
  WebView.platforms = ["Android", "iOS"];
  WebView.install = "ionic cordova plugin add cordova-plugin-ionic-webview";
  WebView.decorators = [{
    type: Injectable
  }];
  return WebView;
}(IonicNativePlugin);
export { WebView };
