import { ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { AccordionSectionComponentFactory, AccordionSectionFactoryService } from 'shared';
import { ContactDetailFiltersComponent } from './contact-detail-filters/contact-detail-filters.component';
import { ContactDetailInfoComponent } from './contact-detail-info/contact-detail-info.component';
import { ContactDetailInterestsComponent } from './contact-detail-interests/contact-detail-interests.component';
import { ContactDetailReachComponent } from './contact-detail-reach/contact-detail-reach.component';
import {ContactDetailSurveyComponent} from "./contact-detail-survey/contact-detail-survey.component";


@Injectable({
  providedIn: 'root'
})
export class ContactDetailComponentFactoryService implements AccordionSectionFactoryService {

  private factories: { [key: string]: AccordionSectionComponentFactory | AccordionSectionComponentFactory[] } = {};

  constructor() {
    this.factories['info'] = this.createInfoComponentFactory();
    this.factories['reach'] = this.createReachComponentFactory();
    this.factories['filters'] = this.createFiltersComponentFactory();
    this.factories['interests'] = this.createInterestsComponentFactory();
    this.factories['survey'] = this.createSurveyComponentFactory();
  }

  getViewerFactory(type: string, context?: any): AccordionSectionComponentFactory {
    return this.getFactory(type, context, this.factories);
  }

  protected getFactory(type: string, context?: any,
    factories?: { [key: string]: AccordionSectionComponentFactory | AccordionSectionComponentFactory[] }): AccordionSectionComponentFactory {
    const sameTypeFactories = factories[type];
    return Array.isArray(sameTypeFactories) ?
      sameTypeFactories.find(factory => factory.canHandle(context)) :
      sameTypeFactories?.canHandle(context) ? sameTypeFactories : undefined;
  }

  createInfoComponentFactory(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<ContactDetailInfoComponent> {
        return resolver
          .resolveComponentFactory(ContactDetailInfoComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createFiltersComponentFactory(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<ContactDetailFiltersComponent> {
        return resolver
          .resolveComponentFactory(ContactDetailFiltersComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createReachComponentFactory(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<ContactDetailReachComponent> {
        return resolver
          .resolveComponentFactory(ContactDetailReachComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createInterestsComponentFactory(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<ContactDetailInterestsComponent> {
        return resolver
          .resolveComponentFactory(ContactDetailInterestsComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createSurveyComponentFactory(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<ContactDetailSurveyComponent> {
        return resolver
          .resolveComponentFactory(ContactDetailSurveyComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }
}
