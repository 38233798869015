/* projects/payment/src/lib/components/payment.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: block;
}
.product {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.product .title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 16px 0;
}
.product > button {
  min-width: 250px;
  white-space: normal;
  word-break: break-word;
}
.product > button:not(:last-child) {
  margin-bottom: 16px;
}
.product > button[disabled] {
  position: relative;
  padding-bottom: 32px;
  color: white;
  background-color: var(--color-primary-300);
}
.product > button[disabled] span.name {
  border-bottom-color: var(--color-primary);
}
.product > button[disabled] span.cancel {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  line-height: 2.4;
  background-color: white;
  color: var(--color-accent);
  border: 1px solid var(--color-primary-300);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.product > button[disabled] span.cancel a {
  color: var(--color-accent);
  font-style: italic;
}
.product > button[disabled] span.cancel a:hover {
  color: var(--color-accent-700);
  text-decoration: underline;
}
.product > button span.name {
  display: block;
  border-bottom: 1px solid var(--color-primary-700);
}
.product > button span.saving {
  font-size: 0.9em;
  font-style: italic;
  display: block;
  margin-top: -6px;
  line-height: 20px;
  padding-bottom: 5px;
}
.product a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.product a.restore-purchases {
  font-size: 0.85em;
  margin: 16px 0;
}
.product a.restore-purchases:hover {
  text-decoration: underline;
}
.product a.cancel {
  font-size: 0.7em;
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 16px;
}
.product a.cancel:hover {
  color: rgba(0, 0, 0, 0.4);
  text-decoration: underline;
}
.asterisk {
  display: block;
}
.asterisk.after:after {
  content: "*";
  display: inline-block;
  vertical-align: baseline;
}
.asterisk.before:before {
  content: "*";
  display: inline-block;
  vertical-align: baseline;
}
.note {
  font-size: 0.7em;
  font-style: italic;
}
.info {
  font-size: 0.76em;
}
.info > * {
  vertical-align: middle;
}
.info > mat-icon:first-child {
  margin-right: 6px;
}
.taxes {
  align-self: center;
}
.terms {
  margin: 25px 0 10px;
  text-align: justify;
}
.instructions {
  text-align: center;
  font-size: 0.8em;
  line-height: 1.6em;
  margin: 20px 0;
}
/*# sourceMappingURL=payment.component-L6ON5E5Y.css.map */
