import {Injectable} from '@angular/core';
import {SearchService, SimpleFilterService, Topic} from "core";
import {PropertiesService} from "properties";
import {Store} from "@ngrx/store";
import {BehaviorSubject, Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import get from "lodash/get";
import {
  PersonalityColorDefaults,
  PersonalityColors,
  PersonalityColorsComponent,
} from "./components/survey/result/personality/personality.colors.component";
import isEqual from "lodash/isEqual";
import {PersonalityResult} from "./components/survey/result/personality/personality.result.component";
import {
  VitalityImagesComponent,
  VitalityTypeDefaults
} from "./components/survey/result/vitality/vitality.images.component";
import {VitalityResult} from "./components/survey/result/vitality/vitality.result.component";

interface SurveyResult<T> {
  questions: Topic[],
  answers: {[id: string]: string},
  result?: T
}

interface SurveyResultCache<T> {
  parameters: [Topic, string[], string, string],
  result: SurveyResult<T>
}

@Injectable({
  providedIn: 'root'
})
export class InterestsService implements SearchService, SimpleFilterService {

  protected filtersSubject$ = new BehaviorSubject<string[]>([]);

  protected personalityResultCache: SurveyResultCache<PersonalityResult>;
  protected vitalityResultCache: SurveyResultCache<VitalityResult>;

  constructor(protected store$: Store<any>,
              protected http: HttpClient,
              protected propertiesService: PropertiesService) {
  }

  updateFilter(filters: string[]): void {
    this.filtersSubject$.next(filters)
  }

  get filters$(): Observable<string[]> {
    return this.filtersSubject$;
  }

  updateSearchTerm(term: string): void {
  }

  get searchTerm$(): Observable<string> {
    return of("");
  }

  /*
  update(interests: string[], userId?: string): Promise<void | never> {
    return new Promise((resolve, reject) => {
      userId = userId ?? this.propertiesService.user.id;
      if (userId) {
        firstValueFrom(this.http.post<any>(
          `/v1.0/contacts/update/${userId}`,
          { interestTags: interests.map(interest => 'interest.'+interest) }
        ).pipe(debounceTime(300, asyncScheduler)))
          .then(resolve)
          .catch(reject);
      } else {
        reject(new Error(`Failed to update Interests. Invalid userId: ${userId}`));
      }
    });
  }

  updateInterests(interests: {[id:string]:boolean}, userId?: string): Observable<void | never> {
    userId = userId ?? this.propertiesService.user.id;
    const mappedInterests:{[id:string]:boolean} = {};
    Object.keys(interests).forEach(id=>mappedInterests['interest.'+id]=interests[id]);
    if (userId) {
      return this.http.post<any>(
        `/v1.0/contacts/update/${userId}`,
        { interests: mappedInterests }
      ).pipe(
        debounceTime(300, asyncScheduler),
        mergeMap((response) => {
          if (!!response?.done) {
            this.propertiesService.reload();
            return of<void>();
          } else {
            return throwError(new Error('Failed to update Interests. Server reported error code'))
          }
        })
      );
    } else {
      return throwError(new Error(`Failed to update Interests. Invalid userId: ${userId}`));
    }
  }*/

  resolveTags(topic: Topic, prefix?:string, tags?:string[]): string[] {
    const tag = !!prefix ? prefix+'.'+topic.id : topic.id;
    tags = tags ?? [];
    if (topic.type=='yesno') {
      tags.push(tag+'.yes');
      tags.push(tag+'.no');
    } else {
      tags.push(tag);
    }
    topic.topics?.forEach(topic=>this.resolveTags(topic,tag,tags));
    return tags;
  }

  resolveLink(topic: Topic, surveyTags: string[]): string {
    const prefix = 'path:';
    const topicPaths = (topic: Topic): string[] =>
      get(topic, 'tags', [] as string[]).reduce(
        function (paths: string[], tag: string) {
          if (tag.startsWith(prefix)) {
            const path = tag.substring(prefix.length);
            path && paths.push(path);
          }
          return paths;
        }, []);
    const collectPaths = (topic: Topic, paths: string[], parentSurveyTag = '') => {
      const surveyTag = `${parentSurveyTag ? `${parentSurveyTag}.` : ''}${topic.id}`;
      surveyTags.includes(surveyTag) && paths.push.apply(paths, topicPaths(topic));
      get(topic, 'topics', [] as Topic[]).forEach((topic) => {
        collectPaths(topic, paths, surveyTag);
      })
    };
    const paths = [];
    collectPaths(topic, paths);
    if (paths && paths.length > 0) {
      paths.sort((path1, path2) => {
        const tokenizePath = (path: string): [string, number] => {
          const tokens = path.split(':');
          return [tokens[0], tokens.length>0 ? +tokens[1] : 0];
        };
        const [link1, order1] = [...tokenizePath(path1)] as [string, number];
        const [link2, order2] = [...tokenizePath(path2)] as [string, number];
        return order1!=order2 ? order1 - order2 : link1.localeCompare(link2);
      });
      return paths[0];
    }
    return undefined;
  }

  public personalityResult(survey: Topic, selectedTags: string[], displayTag: string, badgeData?: string): PersonalityResult {
    let cache = this.personalityResultCache;
    if (cache) {
      if (isEqual(cache.parameters, [ survey, selectedTags, displayTag, badgeData ])) {
        return cache.result?.result;
      }
    }
    const computeResult = (): SurveyResult<PersonalityResult> => {
      if (!!survey) {
        if (!!badgeData && !(selectedTags?.length>2)) {
          const params = badgeData.match(PersonalityColorsComponent.pattern);
          if (params?.length == 5) {
            return {
              result: {
                colors: {
                  red: parseInt(params[1]),
                  yellow: parseInt(params[2]),
                  green: parseInt(params[3]),
                  blue: parseInt(params[4])
                } as PersonalityColors,
                ready: true,
                display: true
              },
              questions: undefined,
              answers: undefined
            };
          }
        }
        if (!!displayTag && selectedTags?.length>=0) {
          const sameSurvey = isEqual(cache?.parameters[0], survey)
          let questions = sameSurvey ? cache.result?.questions : undefined;
          let answers = sameSurvey ? cache.result?.answers : undefined;
          if (!(questions && answers)) {
            answers   = {};
            questions = survey.topics
              ?.filter(topic => topic.type=='section')
              .map(topic => {
                const prefix = `${survey.id}.${topic.id}`;
                topic.topics?.forEach(question=>{
                  question?.topics?.forEach(answer=>{
                    if (answer.type?.startsWith('c#')) {
                      const color = answer.type.substring(2);
                      const id = `${prefix}.${question.id}.${answer.id}`;
                      answers[id] = <any>color;
                      //console.log("SURVEY.answer.id",id,"color",color);
                    }
                  });
                });
                return topic.topics;
              }).flat(1);
          }
          const colors = {...PersonalityColorDefaults}
          let ready    = false;
          let display  = false;
          if (questions?.length>0 &&
            selectedTags?.length>=questions.length &&
            !!displayTag) {
            const questionIds:{[id: string]: number} = {};
            selectedTags.forEach(tag=>{
              const color = answers[tag];
              if (!!color) {
                const id = tag.substring(0,tag.lastIndexOf('.'));
                questionIds[id] = (questionIds[id] ?? 0) + 1;
                colors[color] = (colors[color] ?? 0)+1;
              }
              display = display || tag == displayTag;
            });
            if (Object.keys(questionIds).length>=questions.length) {
              Object.keys(colors).forEach(color=>{
                colors[color] = Math.round(100*colors[color]/questions.length);
              });
              ready = true;
            }
          }
          return {
            result: {
              colors,
              ready,
              display: ready && display
            },
            questions,
              answers
          }
        }
      }
      return {
          result: {
            colors: PersonalityColorDefaults,
            ready: false,
            display: false
          },
          questions: undefined,
          answers: undefined,
        }
      };
    const parameters: [Topic, string[], string, string] = [survey, selectedTags, displayTag, badgeData];
    const result = computeResult();
    if (!cache) {
      cache = { parameters, result };
      this.personalityResultCache = cache;
    } else {
      cache.parameters = parameters;
      cache.result = result;
    }
    return cache.result.result;
  }

  public vitalityResult(survey: Topic, selectedTags: string[], displayTag: string, badgeData?: string): VitalityResult {
    let cache = this.vitalityResultCache;
    if (cache) {
      if (isEqual(cache.parameters, [ survey, selectedTags, displayTag, badgeData ])) {
        return cache.result?.result;
      }
    }
    const computeResult = (): SurveyResult<VitalityResult> => {
      if (!!survey) {
        //console.log("SURVEY.GET.result",this._result,"survey",this.survey);
        if (!!badgeData && !(selectedTags?.length > 2)) {
          const params = badgeData.match(VitalityImagesComponent.pattern);
          if (params?.length == 4) {
            return {
              result: {
                vitality: {
                  health: parseInt(params[1]),
                  sports: parseInt(params[2]),
                  weight: parseInt(params[3])
                },
                ready: true,
                display: true
              },
              questions: undefined,
              answers: undefined,
            };
          }
        }
        if (!!displayTag && selectedTags?.length >= 0) {
          const sameSurvey = isEqual(cache?.parameters[0], survey)
          let questions = sameSurvey ? cache.result?.questions : undefined;
          let answers = sameSurvey ? cache.result?.answers : undefined;
          if (!(questions && answers)) {
            answers = {};
            questions = survey.topics
              .map(question => {
                let result = undefined;
                if (question.type == 'yesno') {
                  if (!!question.properties?.yes) {
                    const id = `${survey.id}.${question.id}.yes`;
                    const type = question.properties.yes.substring(2);
                    answers[id] = <any>type;
                    result = question;
                  }
                  if (!!question.properties?.no) {
                    const id = `${survey.id}.${question.id}.no`;
                    const type = question.properties.no.substring(2);
                    answers[id] = <any>type;
                    result = question;
                  }
                } else if (question?.topics?.length > 0) {
                  question?.topics?.forEach(answer => {
                    if (answer.type?.startsWith('v#')) {
                      const type = answer.type.substring(2);
                      const id = `${survey.id}.${question.id}.${answer.id}`;
                      answers[id] = <any>type;
                      result = question;
                    }
                  });
                }
                return result;
              }).filter(question => !!question);
          }
          const vitality = {...VitalityTypeDefaults};
          const result: VitalityResult = { vitality: vitality, ready: false, display: false};
          const questionIds: { [id: string]: number } = {};
          let max = 0;
          let consultation = false;
          selectedTags.forEach(tag => {
            const value = answers[tag];
            const index = tag.lastIndexOf('.');
            const id = tag.substring(0, index < 0 ? undefined : index);
            if (!!value) {
              questionIds[id] = (questionIds[id] ?? 0) + 1;
              max = Math.max(max, vitality[value] = (vitality[value] ?? 0) + 1);
            } else if (id == 'vitality.consultation') {
              consultation = true;
            }
            result.display = result.display || tag == displayTag;
          });
          //console.log("SURVEY.RESULT",result,"questionIds",questionIds);
          if (max >= 2) {
            result.ready = consultation;
          }
          result.display = result.ready && result.display;
          return {result, questions, answers};
        }
      }
    }
    const parameters: [Topic, string[], string, string] = [survey, selectedTags, displayTag, badgeData];
    const result = computeResult();
    if (!cache) {
      cache = { parameters, result };
      this.vitalityResultCache = cache;
    } else {
      cache.parameters = parameters;
      cache.result = result;
    }
    return cache.result.result;
  }
}
