import {Injectable} from '@angular/core';
import {
  CorrelationIdGenerator,
  EMPTY_ARRAY,
  EntityService,
  LazyLoadingArray,
  Logger,
  SearchService,
  Topic,
  TypedFilterService,
  VersionedId
} from "core";
import {BehaviorSubject, combineLatest, map, Observable, of, ReplaySubject, Subscription, switchMap} from "rxjs";
import {FilteredListView, More, StoreService} from "store";
import {MessagingService} from "messaging";
import {Task} from "../models/task";
import {Action, ActionsSubject, Store} from "@ngrx/store";
import {PropertiesService} from "properties";
import {TranslateService} from "@ngx-translate/core";
import {
  taskDeleteAction,
  taskDeleteFailureAction,
  taskDeleteSuccessAction,
  TaskUpdateAction,
  taskUpdateFailureAction,
  taskUpdateSuccessAction
} from "../store/task/actions";
import {FilterKeys, TaskList} from "tasks/lib/models/task-list";
import {SynchronizationService} from "synchronization";
import {DEFAULT_SEGMENT_SIZE, ListViewState} from "synchronization/lib/store/state";
import {MAX_JAVA_INTEGER} from "shared";
import moment from "moment";
import {TaskListService} from "tasks/lib/services/task-list.service";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TaskService extends StoreService implements SearchService, TypedFilterService , EntityService<Task> {
  protected _entities$ : Observable<Task[]>;
  protected _size$ = new ReplaySubject<number>(1);
  protected _sections$= new BehaviorSubject<number[]>([]);

  protected taskView:FilteredListView<Task>;

  protected logger = new Logger('TaskService').setSilent(false);

  constructor(protected store$: Store<any>,
              protected action$: ActionsSubject,
              protected messagingService: MessagingService,
              protected propertiesService: PropertiesService,
              protected translateService: TranslateService,
              protected taskListService: TaskListService,
              protected synchronizationService: SynchronizationService,
              protected correlationIdGenerator: CorrelationIdGenerator) {
    super(store$, action$);
    console.log('TaskService.ctor');
    this.synchronizationService.setEntityFactory("Task",versionedId=>new Task(versionedId));
    this.synchronizationService.setEntityFactory("TaskList",versionedId=>new TaskList(versionedId));
    let endOfToday = 0;
    let upperTime = 0;
    let removeTime = 0;
    let currentFilters:{[type:string]:string[]} = {};
    let currentSearchTerm = '';
    let currentSearchTerms:string[] = [];
    let filtersPredicate:(entity:VersionedId)=>boolean = undefined;
    this.taskView = this.synchronizationService.createFilteredListView<Task>(
      'Task',
      (filters:{[type:string]:string[]},searchTerm:string)=>filters?.['navigation']?.length>0,
      this.preprocessTasks.bind(this),
      (filters:{[type:string]:string[]})=>{ return {}},
      (term:string)=>'',
      (entity:VersionedId,filters:{[type:string]:string[]})=>{
        const currentTime = Date.now();
        if (!filtersPredicate || endOfToday<=currentTime || filters!==currentFilters) {
          currentFilters = filters;
          const skipListIds = new Set(filters?.['taskFilters']?.filter(filter=>filter.startsWith(FilterKeys.TASK_LIST_EXCLUDE_PREFIX)).map(filter=>filter.substring(FilterKeys.TASK_LIST_EXCLUDE_PREFIX.length)) ?? EMPTY_ARRAY);
          const view = filters?.['navigation']?.find(value=>value!='tasks');
          endOfToday = moment().add(1,'day').startOf('day').valueOf();
          upperTime  = view=='all' ? Number.MAX_VALUE : view=='today' ? endOfToday : view=='tomorrow' ? moment().add(2,'day').startOf('day').valueOf() : Number.MAX_VALUE;
          filtersPredicate = (entity:VersionedId)=>{
            const task = <Task>entity;
            const taskListId = !task.taskList?.id ? '0' : task.taskList.id;
            if (!skipListIds.has(taskListId)) {
              const isCompleted = task.isCompleted();
              if ((!isCompleted || task.timeCompleted>removeTime) &&
                  (isCompleted || Math.max(task.timeCreated??0,task.timeOrder??0,task.timeScheduled??0)<upperTime)) {
                return true;
              }
              //console.log("sync:view",view,"timeCreated",task.timeCreated,"timeOrder",task.timeOrder,"timeScheduled",task.timeScheduled,"timeDeadline",task.timeDeadline,"removeTime",removeTime,"upperTime",upperTime,Math.max(task.timeCreated??0,task.timeOrder??0,task.timeScheduled??0)<upperTime,"isCompleted",isCompleted,"skipListIds",skipListIds,"taskListId",taskListId,"task",task);
            }
            return false;
          };
        }
        //console.log("sync:filterPredicate",entity?.id,(<any>entity)?.title,"add",filtersPredicate(entity));
        return filtersPredicate(entity);
      },
      (entity:VersionedId,searchTerm:string)=>{
        if (!!entity) {
          const task = <Task>entity;
          if (searchTerm!==currentSearchTerm) {
            currentSearchTerm = searchTerm ?? '';
            currentSearchTerms = currentSearchTerm.toLowerCase().split(/\s+/);
          }
          if (!task.searchString) {
            task.searchString =
              (task.title?.length>0 ? task.title.toLowerCase()+' ' : '') +
              (task.info?.length>0 ? task.info.toLowerCase() : '');
            /*+ ' '
              + task.participants?.map(participant=>participant.name).join(' ').toLowerCase() + ' '
              + task.author?.join(' ').toLowerCase();*/
          }
          return currentSearchTerms.every(term=>task.searchString.includes(term));
        }
        return true;
      },
      ()=>{ return { index: MAX_JAVA_INTEGER, size: DEFAULT_SEGMENT_SIZE }},
      (state:ListViewState)=>{
        return {
          moreActiveSize: 0,
          morePassiveSize: state.passiveSize,
          prefetch: 'active'
        };
      });
    //window.setTimeout(()=>{
    //  this.synchronizationService.setEntityDraft('Task','draft',{id:'abcde',version:1234,title:'Test'})
    //},Math.floor(Math.random() * (10_000 - 2_000 + 1)) + 2_000);
  }

  get cacheId$(): Observable<string> {
    return this.taskView.id$;
  }

  preprocessTasks(entities:{entity:Task}[]):{entity:Task}[] {
    return entities?.reverse();
  }

  get morePassive$(): Observable<More> {
    return this.taskView.morePassive$;
  }

  get entities$(): Observable<Task[]> {
    if (!this._entities$) {
      const taskListMap = new Map<string,Observable<Partial<TaskList>>>();
      this._entities$ = this.taskView.entities$.pipe(
        map(entities=><LazyLoadingArray<Task|undefined>>entities),
        tap(entities=> this._size$.next(entities.length)),
        switchMap(entities=>{
          const backingArray = entities.backingArray ?? EMPTY_ARRAY;
          //console.log("sync:taskLists.0",backingArray);
          let taskListIds = undefined;
          taskListIds = backingArray.reduce((set, task) => {
            const taskListId = task?.taskList?.id;
            if (!!taskListId && !set.has(taskListId)) {
              set.add(taskListId);
              taskListMap.set(taskListId,this.taskListService.getTaskList$(taskListId,task.taskList));
            }
            return set;
          }, new Set<string>());
          //console.log("sync:taskLists.1",taskListIds);
          [...taskListMap.keys()].forEach(taskListId=>{
            if (!taskListIds.has(taskListId)) {
              taskListMap.delete(taskListId);
            }
          });
          //console.log("sync:taskLists.2",taskListMap);
          if (taskListMap.size==0) {
            //console.log("sync:taskLists.a",backingArray);
            return of(entities);
          } else {
            //console.log("sync:taskLists.b",taskListMap.size,backingArray);
            return combineLatest([...taskListMap.values()]).pipe(
              map(taskLists => {
                //console.log("sync:taskLists",taskLists);
                const map = taskLists.reduce((map, taskList) => {
                  map.set(taskList.id, new TaskList(taskList));
                  return map;
                }, new Map<string, Partial<TaskList>>());
                backingArray?.forEach(task => {
                  if (!!task?.taskList?.id) {
                    const taskList = map.get(task.taskList.id);
                    if (!!taskList) {
                      task.taskList = taskList;
                      //console.log("sync:taskList", task, task.taskList);
                    }
                  }
                });
                //console.log("sync:taskLists.result",backingArray);
                return entities;
              })
            );
          }
        })
      );
    }
    return this._entities$;
  }

  get sections$(): Observable<number[]> {
    return this._sections$;
  }

  get size$(): Observable<number> {
    return this._size$;
  }

  get filters$() : Observable<string[]> {
    return this.taskView.filters$;
  }

  getCombinedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<string[]> {
    return this.taskView.typedFilters$.pipe(
      map(filters=>{
        const result:string[] = [];
        Object.keys(filters).forEach(type=>{
          if (!exclude || !exclude(type,filters[type])) {
            result.push(...filters[type]);
          }
        });
        return result.sort();
      })
    );
  }

  setTypedFilters(typedFilters:{[key:string]:string[]},removePredicate?:(type:string,filters:string[])=>boolean) : Promise<boolean> {
    return this.taskView.setTypedFilters(typedFilters,removePredicate);
  }

  getTypedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<{[key:string]:string[]}> {
    return this.taskView.typedFilters$.pipe(
      map(filters=>{
        const result:{[key:string]:string[]} = {};
        Object.keys(filters).forEach(type=>{
          if (!exclude || !exclude(type,filters[type])) {
            result[type] = [...filters[type]];
          }
        });
        return result;
      })
    );
  }

  get searchTerm$(): Observable<string> {
    return this.taskView.searchTerm$;
  }

  updateSearchTerm(term: string): void {
    //console.log("sync:updateSearchTerm",term);
    this.taskView.setSearchTerm(term);
  }

  get canEditTasks$(): Observable<boolean> {
    // return combineLatest([
    //   this.taskListService.entities$.pipe(map((entities) =>
    //     !!entities?.find(taskList => taskList?.editable)
    //   )),
    //   this.taskListService.canCreateTaskList$()]
    // ).pipe(map(([editTasks, canCreateTaskList])=> editTasks || canCreateTaskList));
    return of(true);
  }

  typesTopic$(): Observable<Topic> {
    return this.propertiesService.groupId$
      .pipe(
        map(groupId => this.propertiesService.group?.taskTypesTopic)
      );
  }

  getTask$(id: string, defaultTask?: Task): Observable<Task> {
    return this.synchronizationService.getEntity$(id,'Task',defaultTask)
      .pipe(map(entity=><Task>entity));
  }

  save(task: Task, previous?: Task): Promise<Task> {
    if (task) {
      const correlationId = this.correlationIdGenerator.next();
      const promise = new Observable<any>(subscriber => {
        const reducer = `saveTask_${correlationId}`;
        // to improve type checking consider implementing TaskUpdateAction as class
        // then it will be possible to probe for particular Action type with instanceof operator
        // and benefit from user-defined type guards support of typescript and the related type narrowing.
        this.store$.addReducer(reducer, (state, action: { task: Task, error?: any, correlationId?: string } & Action) => {
          if (action.type == taskUpdateSuccessAction.type  ||
            action.type == taskUpdateFailureAction.type) {
            console.debug(reducer, state, action);
            if (action.correlationId == correlationId) {
              if (action.type == taskUpdateSuccessAction.type) {
                // this.loadRequest();
                subscriber.next(new Task(action.task));
              } else {
                subscriber.error(action.error);
              }
              subscriber.complete();
              this.store$.removeReducer(reducer);
            }
          }
          return state;
        });
      }).toPromise();
      this.logger.debug({task, previous});
      this.store$.dispatch(new TaskUpdateAction(task, previous, correlationId));
      return promise;
    } else {
      return Promise.reject('Invalid task');
    }
  }

  delete(task: Task): Promise<void> {
    let resolve: any, reject: any;
    const promise = new Promise<void>((promiseResolve, promiseReject) => {
      resolve=promiseResolve; reject=promiseReject;
    });
    const reducer = `deleteTask_${task.id}`;
    this.store$.addReducer(reducer, (state, action: { task: Task, error?: any } & Action) => {
      if (action.type == taskDeleteSuccessAction.type ||
          action.type == taskDeleteFailureAction.type ||
         (action.type == taskUpdateSuccessAction.type ||
          action.type == taskUpdateFailureAction.type) &&
          action.task.version < 0) {
        console.debug(reducer, state, action);
        if (action.task?.id==task.id) {
          if (action.type == taskDeleteSuccessAction.type ||
              action.type == taskUpdateSuccessAction.type) {
            //this.loadRequest();
            resolve();
          } else {
            reject();
          }
          this.store$.removeReducer(reducer);
        }
      }
      return state;
    });
    this.store$.dispatch(taskDeleteAction({ task }));
    return promise;
  }

  get draft$(): Observable<Task> {
    return this.synchronizationService.getEntityDraft$('Task','draft').pipe(
      map(entity=>entity as Task)
    );
  }

  protected draftSubscription: Subscription;
  set draft$(task$: Observable<Task>) {
    this.draftSubscription?.unsubscribe();
    this.draftSubscription = task$.subscribe((task) => {
      this.synchronizationService.setEntityDraft('Task','draft',task);
    });
  }

  syncTask(task: Task):Promise<Task> {
    return new Promise<Task>((resolve, reject) => {
      this.dispatch(taskUpdateSuccessAction({task}))
        .then(()=>resolve(task))
        .catch(error=>reject(error));
    });
  }
}
