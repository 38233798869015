import {Directive, Injectable, Input, OnInit} from "@angular/core";

@Injectable()
@Directive({
  selector: '[callback]'
})
export class CallbackDirective implements OnInit {
  @Input() callback: () => void;
  ngOnInit(): void {
    this.callback();
  }
}
