import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {CalendarEffects} from "./calendar/effects";
import {EventEffects} from "./event/effects";
import {AttendeeEffects} from "./attendee/effects";
import {calendarReducer} from "./calendar/reducers";
import {eventReducer} from "./event/reducers";
import {attendeeReducer} from "./attendee/reducers";
import {calendarFeatureKey, CalendarState} from "./state";

export const calendarReducers: ActionReducerMap<CalendarState> = {
  calendars: calendarReducer,
  events: eventReducer,
  attendees: attendeeReducer
};

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(calendarFeatureKey, calendarReducers),
    EffectsModule.forFeature([CalendarEffects, EventEffects, AttendeeEffects])
  ]
})
export class CalendarStoreModule {}
