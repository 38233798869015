/* projects/contact/src/lib/components/contact-list-item/contact-list-item.component.scss */
:host {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
div.avatar {
  line-height: 0;
}
div.avatar img {
  opacity: 0;
  width: 60px !important;
  height: 60px !important;
}
.content {
  position: relative;
  flex: 1;
  padding: 8px 3px 8px 12px;
  min-height: 76px;
}
.content > .contact {
  padding: 8px 0px 8px 12px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
}
.content > .contact > div {
  width: 100%;
  overflow: hidden;
}
.content > .contact > div > h3 {
  display: block;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content > .contact > div > h3 > span {
  white-space: nowrap;
}
.content > .contact > div > h3 > a {
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
  white-space: nowrap;
}
.content > .contact > div > app-contact-join-info {
  display: block;
  overflow: hidden;
}
app-partner-id {
  margin: 2px 0 0 6px;
  display: inline-block;
}
/*# sourceMappingURL=contact-list-item.component-77MZTTVH.css.map */
