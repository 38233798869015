import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EMPTY, of} from "rxjs";
import {Action, select, Store} from "@ngrx/store";
import {catchError, concatMap, debounceTime, map, mergeMap, switchMap, withLatestFrom} from "rxjs/operators";
import {addUrlParameter, Logger} from "core";
import {HttpClient} from "@angular/common/http";

import {State} from "../state";
import {
  AttendeeActionIds,
  attendeeLoadPageAction,
  attendeeLoadPageFailureAction,
  attendeeLoadPageSuccessAction,
  attendeeLoadRequestAction,
  AttendeeUpdateAction,
  attendeeUpdateFailureAction,
  attendeeUpdateFilterAction,
  attendeeUpdateSearchTermAction,
  attendeeUpdateSuccessAction
} from "./actions";
import {Attendee} from "../../models/attendee";
import {AttendeeService} from "../../services/attendee.service";
import {EMPTY_ARRAY} from "core";


@Injectable()
export class AttendeeEffects {

  attendeeLoadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attendeeLoadRequestAction, attendeeUpdateFilterAction, attendeeUpdateSearchTermAction),
      debounceTime(300),
      withLatestFrom(this.store$.pipe(select(state => state.calendar.attendees))),
      map(([action, state]) => attendeeLoadPageAction({
        eventId: state.eventId,
        cacheId: state.currentCacheId,
        index:0,
        size:25
      }))
    )
  );

  attendeeLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attendeeLoadPageAction),
      // debounceTime(this.debounce || 300, this.scheduler || asyncScheduler),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store$.pipe(select(state => state.calendar.events)))
      )),
      switchMap(([action,state]) => {
        if (!action.cacheId || action.index < 0) {// || action.size <= action.index) {
          return EMPTY;
        }
        const filters = <string[]>[].concat(...Object.keys(state.filters).map(type=>state.filters[type]??EMPTY_ARRAY));
        const query = encodeURI(state.term.trim().replace(/\s+/g, ','));
        const parameters  = addUrlParameter("filter", filters.join(','),true)
          .add("query", query,true)
          .add("eventId",action.eventId ? encodeURI(action.eventId) : '',true)
          .toString();
        const path    = `/v1.0/calendar/event/attendee/segment/${action.cacheId}/${action.index}/${action.size}${parameters}`;
        this.logger.debug(`eventLoadPage. path:${path}`);
        return this.http.get(path).pipe(
          map(result => {
            return attendeeLoadPageSuccessAction({
              eventId: action.eventId,
              cacheId:action.cacheId,
              index:action.index,
              data:result['data'],
              total:result['size'],
              dispatch:(dispatchAction : Action) => {
                this.store$.dispatch(dispatchAction);
              }})
          }),
          catchError(() => {
            return of(attendeeLoadPageFailureAction({
              eventId: action.eventId,
              cacheId: action.cacheId,
              index: action.index,
              size: action.size
            }))
          })
        )
      })
    )
  );

  // @Effect()
  attendeeUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AttendeeUpdateAction>(AttendeeActionIds.UPDATE),
      withLatestFrom(
        this.store$.pipe(select(state => state.calendar.events)),
        this.attendeeService.size$
      ),
      mergeMap(([action, state])  => {
        const path = `/v1.0/calendar/event/attendee`;
        return this.http.post(path, action.attendee).pipe(
          map((attendee: Attendee) => {
            const props = action;
            return attendeeUpdateSuccessAction({attendee, correlationId: action.correlationId});
          }),
          catchError((error) => {
            this.logger.error('ERROR', error);
            return of(attendeeUpdateFailureAction({
              attendee: action.previous,
              reason: error,
              correlationId: action.correlationId
            }));
          })
        )
      })
    )
  );

  protected logger = new Logger('AttendeeEffects');

  constructor(private http: HttpClient,
              private actions$: Actions,
              private store$: Store<State>,
              private attendeeService: AttendeeService) {
  }
}
