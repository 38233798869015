<div class="product">
<!--  <mat-icon>motorcycle</mat-icon>-->
<!--  <span class="title" translate>product.pro.name</span>-->
<!--
  <ng-container [ngSwitch]="platform.is('hybrid')">
    <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="payment"></ng-container>
    <ng-container *ngSwitchCase="true"  [ngTemplateOutlet]="instructions"></ng-container>
  </ng-container>
-->
  <ng-container *ngTemplateOutlet="payment"></ng-container>

  <ng-template #payment>
    <!--
    <span *ngIf="activeProduct && paymentService.getInterval(activeProduct.id)?.name; let interval" class="info">
      {{'purchase.upgradeDowngrade' | translate: {
        plan: 'product.pro.plan.'+interval+'.name'  | translate,
        date: expiryTime | date
      } }}
    </span>
    <span *ngIf="cancelTime!=0" class="info" >
      <mat-icon class="mat-accent">info</mat-icon>
      <span translate>purchase.cancelled</span>
    </span>
    -->
    <ng-container *ngFor="let product of products | async; let index = index"
                  [ngTemplateOutlet]="productTemplate"
                  [ngTemplateOutletContext]="{
                      product: product,
                      interval: paymentService.getInterval(product.id)?.name
                    }">
    </ng-container>
    <ng-template #productTemplate let-product="product" let-interval="interval">
<!--      <ng-container *ngIf="product?.canPurchase && interval">-->
<!--      <button  mat-flat-button type="button" (click)="subscribe(product, $event)">-->
      <ng-container *ngIf="interval">
        <button #button
                mat-raised-button
                type="button"
                color="primary"
                [disabled]="!product?.canPurchase"
                (click)="subscribe(product, $event)">
          <span class="name">{{product.description}}</span>
          <span class="price asterisk after">
            {{'product.pro.plan.'+interval+'.price' | translate:{
              'price': product.price,
              'currency': product.currency}
            }}
          </span>
          <span *ngIf="savings[product.id]; let saving;" class="saving">
            {{'product.pro.plan.'+interval+'.save' | translate:{
              'monthlyPrice': saving.price,
              'savePercentage': saving.percentage.toFixed(2)}
            }}
          </span>
          <span *ngIf="button.disabled" class="cancel">
            {{'purchase.currentlySubscribed' | translate}}
            <ng-container *ngIf="!cancelTime">
              - <a  (click)="onTapCancel(product)">{{'purchase.cancel' | translate}}</a>
            </ng-container>
          </span>
        </button>
      </ng-container>
    </ng-template>
    <span class="note taxes asterisk before">
      {{ 'purchase.taxIncluded' | translate:{'tax': tax?.name , 'rate': tax?.rate} }}
    </span>
    <!--
    <a *ngIf="platform.is('hybrid')" class="restore-purchases" (click)="restorePurchases($event)">
      <span translate>purchase.restore</span>
    </a>
    <span class="info terms" [innerHTML]="terms | translate | safeHtml"></span>
    <span class="info more">
      <span [innerHTML]="'purchase.more' | translate | translateFragment:0 | safeHtml"></span>
      <a (click)="onTapLink('/rules')">
        {{'purchase.more' | translate | translateFragment:1}}
      </a>
    </span>
    <a *ngIf="activeProduct && !cancelTime" class="cancel"
       [innerHTML]="'purchase.cancel' | translate"
       (click)="onTapCancel($event)">
    </a>
    -->
  </ng-template>

<!--  <ng-template #instructions>-->
<!--    <div class="instructions">-->
<!--      <span [innerHTML]="'purchase.instructions' | translate | translateFragment:0 | safeHtml"></span>-->
<!--      <a (click)="onTapLink('/team/hosts')">{{'purchase.instructions' | translate | translateFragment:1}}</a>-->
<!--      <span [innerHTML]="'purchase.instructions' | translate | translateFragment:2 | safeHtml"></span>-->
<!--    </div>-->
<!--  </ng-template>-->
</div>
