import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  Injector,
  INJECTOR
} from '@angular/core';
import {ChatMessageComponent} from "../chat-message/chat-message.component";
import {AuthenticationService} from "auth";
import {ChatService} from "../../../chat.service";
import {LayoutService} from "layout";
import {AttachmentComponentFactoryService} from "../../attachment/attachment.component.factory.service";
import {ENVIRONMENT} from "core";

@Component({
  selector: 'chat-note-message',
  templateUrl: '../chat-message/chat-message.component.html',
  styleUrls: [
    '../chat-message/chat-message.component.scss',
    './chat-note-message.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatNoteMessageComponent extends ChatMessageComponent {

  constructor(public authenticationService: AuthenticationService,
              public elementRef: ElementRef,
              public chatService: ChatService,
              public layoutService:LayoutService,
              public attachmentService: AttachmentComponentFactoryService,
              public resolver: ComponentFactoryResolver,
              @Inject(INJECTOR) public injector:Injector,
              @Inject(ENVIRONMENT) public environment: any) {
    super(authenticationService,elementRef,chatService,layoutService,attachmentService,resolver,injector,environment);
  }
}
