import {Action, createAction, props} from "@ngrx/store";
import {TaskList} from "../../models/task-list";

export enum TaskListActionIds {
  UPDATE             = 'taskList.update',
  UPDATE_SUCCESS     = 'taskList.update.success',
  UPDATE_FAILURE     = 'taskList.update.failure',
  DELETE             = 'taskList.delete',
  DELETE_SUCCESS     = 'taskList.delete.success',
  DELETE_FAILURE     = 'taskList.delete.failure',
}

export class TaskListUpdateAction implements Action {
  readonly type = TaskListActionIds.UPDATE;
  constructor(public taskList: TaskList,
              public previous?: TaskList,
              public correlationId?: string) {
    if (!this.correlationId) {
      this.correlationId = this.taskList.id;
    }
  }
}

// export const taskListUpdateAction = createAction(TaskListActionIds.UPDATE, props<{ taskList: TaskList, previous: TaskList, correlationId: string  }>());
export const taskListUpdateSuccessAction = createAction(TaskListActionIds.UPDATE_SUCCESS, props<{ taskList: TaskList, correlationId?: string }>());
export const taskListUpdateFailureAction = createAction(TaskListActionIds.UPDATE_FAILURE, props<{ taskList: TaskList, reason: any, correlationId: string }>());
export const taskListDeleteAction = createAction(TaskListActionIds.DELETE, props<{ taskList: TaskList }>());
export const taskListDeleteSuccessAction = createAction(TaskListActionIds.DELETE_SUCCESS, props<{ taskList: TaskList }>());
export const taskListDeleteFailureAction = createAction(TaskListActionIds.DELETE_FAILURE, props<{ taskList: TaskList, reason: any }>());
