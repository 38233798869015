import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {PresenceService} from "../../presence.service";
import {PropertiesService} from "properties";
import {PresenceComponent} from "./presence.component";
import {PresenceState} from "../../store/models";
import {Presence} from "core";

@Component({
  selector: 'presence-menu',
  template: `
    <div>
      <div [matMenuTriggerFor]="menu">
        <presence #indicator [all]="true" [contactId]="propertiesService?.user?.id"></presence>
        <span>{{getLabel((indicator.presence$ | async)?.state) | translate}}</span>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="setPresenceState(stateInvisible)">
          <presence-indicator [all]="true" [presence]="createPresence(stateInvisible)"></presence-indicator><span>{{getLabel(stateInvisible) | translate}}</span>
        </button>
        <button mat-menu-item (click)="setPresenceState(stateAbsent)">
          <presence-indicator [all]="true" [presence]="createPresence(stateAbsent)"></presence-indicator><span>{{getLabel(stateAbsent) | translate}}</span>
        </button>
        <button mat-menu-item (click)="setPresenceState(stateUnavailable)">
          <presence-indicator [all]="true" [presence]="createPresence(stateUnavailable)"></presence-indicator><span>{{getLabel(stateUnavailable) | translate}}</span>
        </button>
        <button mat-menu-item (click)="setPresenceState(stateLoggedIn)">
          <presence-indicator [all]="true" [presence]="createPresence(stateLoggedIn)"></presence-indicator><span>{{getLabel(stateLoggedIn) | translate}}</span>
        </button>
      </mat-menu>
    </div>
  `,
  styleUrls: ['./presence-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresenceMenuComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('indicator', {read: PresenceComponent})
  indicator: PresenceComponent;

  stateInvisible   = PresenceState.invisible;
  stateAbsent      = PresenceState.absent;
  stateUnavailable = PresenceState.unavailable;
  stateLoggedIn    = PresenceState.loggedIn;

  constructor(
    public presenceService: PresenceService,
    public propertiesService: PropertiesService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  getLabel(state:PresenceState):string {
    let label =
      state==PresenceState.unavailable ? 'chat.presence.unavailable' :
      state==PresenceState.absent ? 'chat.presence.absent' :
      state==PresenceState.invisible ? 'chat.presence.invisible' :
      'chat.presence.online.now';
    //console.log("getLabel",state,label);
    return label;
  }

  createPresence(state:PresenceState):Presence {
    return {
      state,
      contactId:undefined,
      timeActive:Number.MAX_SAFE_INTEGER,
      timeSince:Number.MAX_SAFE_INTEGER
    };
  }

  setPresenceState(state:PresenceState) {
    this.presenceService.setPresenceState(state);
  }
}
