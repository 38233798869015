import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit, TemplateRef} from '@angular/core';
import {BasicContainerComponent, SlideEvent} from "shared";
import {LayoutService} from "layout";
import {ChatTimelineMessage} from "../../store/models";

export interface ChatMessageDetailsContext {
  messageTemplateRef: TemplateRef<any>;
  message: ChatTimelineMessage;
  view: 'replies'|'delivery';
}

@Component({
  selector: 'chat-message-details-container',
  templateUrl: './chat-message-details-container.component.html',
  styleUrls: ['./chat-message-details-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageDetailsContainerComponent extends BasicContainerComponent {

  @Input() view:'replies'|'delivery' = 'delivery'

  message: ChatTimelineMessage;
  searchTeam:string = undefined;

  constructor(public layoutService: LayoutService,
              protected zone: NgZone,
              protected changeDetectorRef: ChangeDetectorRef) {
    super();
    //console.log("ChatMessageDetailsContainerComponent.ctor()");
  }

  protected _context:ChatMessageDetailsContext;
  get context():ChatMessageDetailsContext {
    return this._context;
  }

  set context(context: ChatMessageDetailsContext) {
    console.log("ChatMessageDetailsContainerComponent.context(",context,")");
    if (!!context.message) {
      this.zone.run(() => {
        this._context = context;
        this.message = context?.message;
        this.view = context?.view ?? 'delivery';
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  onShowReplies() {
    console.log("onShowReplies()",this.view);
    this.view = 'replies';
  }

  onShowDeliveryState() {
    console.log("onShowDeliveryState()",this.view);
    this.view = 'delivery';
  }

  onPanelSlide(event: SlideEvent) {
  }
}
