<!--
<agm-map #map
         [zoom]="zoom"
         [mapTypeId]="mapTypeId"
         [latitude]="center.lat()"
         [longitude]="center.lng()"
         (mapReady)="onMapReady($event)"
         (boundsChange)="onMapBoundsChange($event)"
         (mapClick)="onMapClick($event)">
-->
<!--
<agm-map #map
         (mapReady)="onMapReady($event)"
         (boundsChange)="onMapBoundsChange($event)"
         (mapClick)="onMapClick($event)">
  <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
  <agm-marker-cluster #cluster [zoomOnClick]="false" (clusterClick)="onClusterClick($event)">
    <agm-marker *ngFor="let marker of markers; let index = index"
                [latitude]="marker.lat"
                [longitude]="marker.lng"
                [label]="marker.label"
                [iconUrl]="marker.icon?.image"
                [markerDraggable]="marker.draggable"
                (markerClick)="onMarkerClick(marker, index, $event)"
                (dragEnd)="onMarkerDragEnd(marker, index, $event)">
      <agm-info-window>
        <strong>Marker Info</strong>
      </agm-info-window>
    </agm-marker>
  </agm-marker-cluster>
  <agm-manipulator [map]="map" [markers]="markers" (selectionChanged)="onSelectionChanged($event)"></agm-manipulator>
</agm-map>
-->

<!--<agm-map #map-->
<!--         (mapReady)="onMapReady($event)"-->
<!--         (boundsChange)="onMapBoundsChange($event)"-->
<!--         (mapClick)="onMapClick($event)">-->
<!--  <agm-manipulator [map]="map" [markers]="markers" (selectionChanged)="onSelectionChanged($event)"></agm-manipulator>-->
<!--</agm-map>-->
<div #mapContainer class="mapContainer"></div>
