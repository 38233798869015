export const ProvisioningTypes = ['online', 'offline', 'hybrid'] as const;
export type  ProvisioningTuple = typeof ProvisioningTypes;
export type  ProvisioningType  = ProvisioningTuple[number];
export namespace ProvisioningType {
  export const online  : ProvisioningType = ProvisioningTypes[0];
  export const offline : ProvisioningType = ProvisioningTypes[1];
  export const hybrid  : ProvisioningType = ProvisioningTypes[2];
  export function isOnline<T extends ProvisioningType>(type: T): boolean {
    return type === ProvisioningType.online || type === ProvisioningType.hybrid;
  }
  export function isOffline<T extends ProvisioningType>(type: T): boolean {
    return type === ProvisioningType.offline || type === ProvisioningType.hybrid;
  }
  export function isHybrid<T extends ProvisioningType>(type: T): boolean {
    return type === ProvisioningType.hybrid;
  }
  export function types<T extends ProvisioningType>(): readonly T[] {
    return ProvisioningTypes as any;
  }
}
