<div (onAttach)="ngOnAttach()" (onDetach)="ngOnDetach()">
  <div class="reply-row" [@replyVisibility]="replyVisibility$ | async" (@replyVisibility.done)="animationDone($event)">
    <ng-container *ngIf="!!replyMessage">
      <chat-message-container [message]="replyMessage" [mode]="'reply'"></chat-message-container>
      <button mat-icon-button (click)="chatService.setReplyMessage(conversationId,undefined)">
        <mat-icon>clear</mat-icon>
      </button>
    </ng-container>
  </div>
  <div class="attachment-row" (transitionstart)="animationStart($event)" (transitionend)="animationDone($event)" (transitioncancel)="animationDone($event)">
    <attachment-section-container #attachmentsComposers
      [message]="draftMessage"
      [factoryAccessor]="attachmentService.getComposerFactory.bind(attachmentService)"
      [attachments]="draftMessage?.attachments ?? []"
      (updated)="updatedAttachmentComposers()"
      (deleted)="deletedAttachment($event)"
      [log]="false">
    </attachment-section-container>
  </div>
  <div class="upload-indicator-row" [@uploadIndicatorVisibility]="uploadIndicatorVisibility">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="emoji-row" [@emojiVisibility]="emojiVisibility" >
    <div #emojiHost class="primary"></div>
  </div>
  <div class="message-row">
    <div>
      <div [class.hide]="pressRecording === undefined" class="recording">
        <button mat-icon-button class="mat-mdc-icon-mini-button">
          <mat-icon>mic</mat-icon>
        </button>
        <div class="time">{{recordingTime}}</div>
        <div class="message"><span translate>chat.recording.slideToCancel</span></div>
        <button mat-icon-button *ngIf="!pressRecording && actionButtonSymbol!='delete'"
                class="mat-mdc-icon-mini-button"
                (click)="deleteRecording()">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
      <div [class.hide]="pressRecording !== undefined" class="editing">
        <button mat-icon-button (click)="openActionSheet()" class="mat-mdc-icon-mini-button">
          <!--
          <mat-icon>attach_file</mat-icon>
          <mat-icon style="transform:rotate(180deg);">arrow_drop_down_circle</mat-icon>
          -->
          <mat-icon>add_circle</mat-icon>
        </button>
        <chat-message-text-editor
          #textEditor
          (sendEvent)="sendMessage(true)"
          (focusEvent)="onTextareaFocus($event)"
          (blurEvent)="onTextareaBlur($event)">
          <!--
          <div *ngIf="!textEditor.value" class="message-type {{draftMessage.type}}"
               [@enterLeaveOpacityAnimation]="{ value:':enter,:leave'}"
               [matMenuTriggerFor]="typeMenu">{{('chat.message.type.'+(draftMessage.type ?? 'chat') | translate)}}</div>
          <mat-menu #typeMenu="matMenu">
            <button mat-menu-item (click)="updateDraftMessageType(TYPE_NOTE)">
              <mat-icon>sticky_note_2</mat-icon><span translate>chat.message.type.note</span>
            </button>
            <button mat-menu-item (click)="updateDraftMessageType(TYPE_MESSAGE)">
              <mat-icon>message</mat-icon><span translate>chat.message.type.chat</span>
            </button>
          </mat-menu>
          -->
        </chat-message-text-editor>
        <chat-message-type-selector
          *ngIf="hasMessageTypeSelector"
          (changeEvent)="updateDraftMessageType($event)"
          [type]="draftMessage?.type ?? draftMessageType ?? 'chat'"></chat-message-type-selector>
        <button mat-icon-button
          *ngIf="platform.is('desktop') && hasEmojiSelector"
          (click)="emojiClicked()"
          class="mat-mdc-icon-mini-button">
          <mat-icon>emoji_emotions</mat-icon>
        </button>
      </div>
    </div>
    <button mat-mini-fab class="action mat-elevation-z0"
            [disabled]="!actionButtonEnabled"
            [ngSwitch]="actionButtonSymbol">
      <div class="grip"
           (touchstart)="touchstart($event)"
           (touchmove)="touchmove($event)"
           (touchcancel)="touchcancel($event)"
           (touchend)="touchend($event)"
           (mousedown)="mousedown($event)"
           (mousemove)="mousemove($event)"
           (mouseup)="mouseup($event)"
           (mouseleave)="mouseleave($event)"></div>
      <mat-icon *ngSwitchCase="'send'">send</mat-icon>
      <mat-icon *ngSwitchCase="'update'">done</mat-icon>
      <mat-icon *ngSwitchCase="'record'">mic</mat-icon>
      <mat-icon *ngSwitchCase="'delete'">delete_forever</mat-icon>
    </button>
  </div>
</div>
<!--
<p style="font-size: 0.85em"> {{'draftMessage: '+ {body: draftMessage?.bodyText, attachments: draftMessage?.attachments?.length, _id: draftMessage?._id} }}</p>
<p style="font-size: 0.85em">{{'pressRecording: '+pressRecording}}</p>
<p style="font-size: 0.85em">{{'actionButtonEnabled: '+actionButtonEnabled}}</p>
<p style="font-size: 0.85em">{{'actionButtonSymbol: '+actionButtonSymbol}}</p>
<p style="font-size: 0.85em">{{'_startRecordingEvent: '+!!_startRecordingEvent}}</p>
<p style="font-size: 0.85em">{{'_stopRecordingEvent: '+!!_stopRecordingEvent}}</p>
<p style="font-size: 0.85em">{{'_mediaRecording: '+!!_mediaRecording}}</p>
<p style="font-size: 0.85em">{{'_isDown: '+_isDown}}</p>
-->
