import {ComponentRef, Inject, Injectable, Injector} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {Company, Contact, ENVIRONMENT, loadScript, Logger} from "core";
import {PropertiesService} from "properties";
import {TranslateService} from "@ngx-translate/core";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal, PortalInjector} from "@angular/cdk/portal";
import {DOCUMENT} from "@angular/common";
import {Product, ProductCallback, ProductEvents} from "../models/product";
import {Tax} from "../models/tax";
import {STRIPE_OVERLAY_DATA, StripeOverlayComponent, StripeOverlayRef} from "./stripe-overlay.component";
import {HttpClient} from "@angular/common/http";
import {PaymentServiceImpl} from "../service/payment-service.factory";
import {ProductSelector} from "../service/product-selector.service";
import {ProductAdapter} from "../service/product-adapter.service";
import {map, take} from "rxjs/operators";
import get from "lodash/get";

/// <reference types="stripe-v3" />
import Source = stripe.Source;

// NOTE: Stripe does not allow dot in subscription plan names
// (in contrast with Apple and Google)

/** @deprecated */
enum ProductId {
  REALIZER_PRO_SUBSCRIPTION_MONTHLY   = 'realizer_pro_subscription_monthly',
  REALIZER_PRO_SUBSCRIPTION_BIANNUAL  = 'realizer_pro_subscription_biannual',
  REALIZER_PRO_SUBSCRIPTION_ANNUAL    = 'realizer_pro_subscription_annual'
}

declare var Quaderno: any;

export type PaymentMethod = 'creditCard' | 'sepa';

@Injectable({
  providedIn: 'root'
})
export class StripePaymentService implements PaymentServiceImpl {

  public static SOURCE = 'stripe';

  stripePublishableKey: string;
  quadernoPublishableKey: string;

  protected initialized: ReplaySubject<void>;
  protected products:  {[key: string]: Product};
  protected callbacks: {[key: string]: ProductCallback[]}  = {};
  protected taxes: { [key: string]: {[key: string]: Tax }} = {}; // taxes by productId and country
  protected stripe: stripe.Stripe;
  protected stripeElements: stripe.elements.Elements;
  protected stripeElementsPool: { [key: string]: { element: stripe.elements.Element, references: number, releaseTime?: number } };
  protected stripeOverlayRef: OverlayRef;
  protected currentOrder: { resolve : (value: boolean) => void, reject: (reason?: any) => void };

  protected logger = new Logger('StripeService');

  constructor(protected propertiesService: PropertiesService,
              protected translateService: TranslateService,
              protected httpClient: HttpClient,
              protected productSelector: ProductSelector,
              protected productAdapter: ProductAdapter,
              protected overlay: Overlay,
              @Inject(DOCUMENT)    protected readonly document: Document,
              @Inject(ENVIRONMENT) protected readonly environment: any,
              protected injector: Injector) {
    //console.debug('StripePaymentService.ctor()');
    this.stripePublishableKey = this.environment.production                                                              // obsolete Ing. Karl-Heinz Troyer Stripe account keys
        ? 'pk_live_51I9UNcDGPvE4ENcDXJjbLeNIxi8pxJpxfaSDwFxao6l50uLxmOINenGk9F062wAkNGdBKL3UG7GSQ0z9yHFcPQp000heEiMxBe'  // pk_live_oDJEGDL6mtCeKEJv9aIGmssr00EhrqzR39
        : 'pk_test_51I9UNcDGPvE4ENcDz1yQuNBDUtqoVTBAbY8Ksd1DWz9G4J089aZBaIVMBKEEnisDoNtO8xlCuUfCz31MVxGK21Ya00VfHO7Y77'; // pk_test_pbOnQoTVUHOmVIMCoYbkHBnt00WCU3oJMw

    this.quadernoPublishableKey = this.environment.production   // obsolete Ing. Karl-Heinz Troyer Quaderno account keys
        ? 'pk_live_RyvxKtWyqfdjAxsmVzCx'                        // pk_live_RyvxKtWyqfdjAxsmVzCx
        : 'pk_test_n_CbtT4n2zTtJPgdYyBb';                       // pk_test_-kAeNPutWE5sSP9sGnDg
  }

  initialize(): Observable<void> {
    if (!this.initialized) {
      this.initialized = new ReplaySubject(); //new ReplaySubject(1, Number.POSITIVE_INFINITY)
      this.loadStripe()
        .then(() =>
          this.stripe = Stripe(
            this.stripePublishableKey,
            // https://stripe.com/docs/stripe-js/reference
            // stripe will detect browser locale if locale option is not supplied
            // only a limited set of locales are supported
            // {'locale': this.contact.country_code}
          )
        )
        .then(() => this.loadQuaderno())
        .then(() => this.initializeProducts())
        .then(() => {
          const safeCallbacks = (product: Product) => {
            const callbacks = this.callbacks[product.id];
            if (callbacks && callbacks.length>0) {
              callbacks.forEach((callback) => {
                try {
                  callback(product);
                } catch (e) {
                  this.logger.error('Failed to invoke callback', callback, product);
                }
              });
            }
          };
          // this.productSelector.selectRealizerPro().subscribe(([productId, source, expiryTime, cancelTime]) => { // all current products are for Realizer Pro
          this.productSelector.select().subscribe(([productId, source, expiryTime, cancelTime]) => {
            Object.values(this.products).forEach((p) => {
                // product can be purchased only if there no active product/subscription at all, subscription has been cancelled or the product is not already purchased
                p.canPurchase = !productId || cancelTime!=0 || !(p.owned = this.productAdapter.match(p.id, productId));
                safeCallbacks(p);
            });
          });
          this.translateService.onLangChange.subscribe(() => {
            this.initializeProducts().then(() => {
              Object.values(this.products).forEach((p) => safeCallbacks(p) );
            }).catch(error => this.logger.error('Failed to update products after lang change', error));
          });
        })
        .then(() => this.initialized.next())
        .catch((error) => {
          this.logger.error('StripeService initialization failed!', error);
          this.initialized.error(error);
        })
        .then(() => this.initialized.complete());
    }
    return this.initialized.asObservable();
  }

  /*** PUBLIC API ***/

  get source(): string {
    return StripePaymentService.SOURCE;
  }

  // get productIds(): string[] {
  //   return Object.keys(ProductId)
  //     .map(productId => ProductId[productId]);
  //     // .filter((productId) => productId!=ProductId.REALIZER_PRO_SUBSCRIPTION_ANNUAL); // we do not have annual for now!
  // }

  get productIds(): Observable<string[]> {
    return this.initialize().pipe(map(() => Object.keys(this.products || {})));
  }

  async get(productId: string): Promise<Product> {
    await this.initialize().toPromise();
    const product = this.products && this.products[productId];
    return product!=null ? {...product} : null;
  }

  when(productId: string): ProductEvents {
    return {
      updated: (callback) => {
        if (productId && callback) {
          let callbacks = this.callbacks[productId];
          if (!callbacks) {
            this.callbacks[productId] = callbacks = [];
          }
          if (!callbacks.includes(callback)) {
            callbacks.push(callback);
          }
        }
      }
    }
  }

  off(callback: ProductCallback) {
    for (let productId in this.callbacks) {
      const callbacks: ProductCallback[] = this.callbacks[productId];
      if (callbacks && callbacks.includes(callback)) {
        callbacks.splice(callbacks.indexOf(callback), 1);
      }
    }
  }

  order(productId: string): Promise<boolean> {
    return this.initialize().toPromise()
      .then(() => this.get(productId))
      .then((product) => {
        // prepare a new promise for this order. the old one is already completed
        let promise = new Promise<boolean>((resolve, reject) => {
          this.currentOrder = { resolve: resolve, reject: reject }
        });
        if (!this.stripeOverlayRef) {
          this.stripeOverlayRef = this.displayStripeOverlay(product, this.propertiesService.user, (result: any) => {
            // result can be:
            // true  - purchase success,
            // false - overlay is closed without purchase attempt
            // {error: string} - purchase failed with error
            if (typeof result === "boolean") {
              this.currentOrder.resolve(result);
            } else {
              this.currentOrder.reject(result.error || `Failed to order ${productId}`);
            }
          });
          this.stripeOverlayRef.detachments().subscribe(() => {
            this.stripeOverlayRef = null;
          });
        }
        return promise;
      })
      .catch((error) => {
        // this.logger.error('Product order error:', error);
        throw error;
      });
  }

  cancel(productId: string): Promise<void> {
    return this.get(productId).then(((product)=> {
      if (product && product.type == 'subscription') {
        return this.httpClient
          .post(`/v1.0/payment/subscription/cancel`, { source: 'stripe', productId: productId })
          .toPromise()
          .then((response: any) => {
            if (response && !response.error ) {
              return this.propertiesService
                .reload()
                .then(properties => void 0);
            } else {
              throw (response && response.error) || new Error ('Server Error');
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw new Error (`Failed to cancel the subscription: ${error}`);
          })
      } else {
        throw new Error(`Invalid productId=${productId}`);
      }
    }))
  }

  refund(productId: string): Promise<void> {
    return this.get(productId).then(((product)=> {
      if (product) {
        return this.httpClient
          .post(`/v1.0/payment/refund`, { source: 'stripe', productId: productId })
          .toPromise()
          .then((response: any) => {
            if (response && !response.error ) {
              return this.propertiesService.reload().then(() => void 0);
            } else {
              throw (response && response.error) || new Error ('Server Error');
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw new Error (`Failed to refund payment: ${error}`);
          })
      } else {
        throw new Error(`Invalid productId=${productId}`);
      }
    }))
  }

  async getTax(country: string, productId: string): Promise<Tax> {
    productId = productId || ProductId.REALIZER_PRO_SUBSCRIPTION_MONTHLY;
    if (this.taxes[productId] && this.taxes[productId][country]) {
      return this.taxes[productId][country];
    // the following code line compiles successfully with angular v7 but angular v9 compiler reports an error:
    // "Argument of type 'string' is not assignable to parameter of type 'ProductId'"
    // } else if (Object.values(ProductId).includes(productId)) {
    } else { // } else if (Object.keys(ProductId).filter(key => ProductId[key]==productId)) {
      await this.initialize().toPromise();
      if (Object.keys(this.products).filter(key => key==productId).length) {
        if (Quaderno) {
          if (Quaderno.country != country) {
            Quaderno.destroy();
            await Quaderno.init(
              this.quadernoPublishableKey,
              productId,
              { /* postalCode: '', */ country: country }
            );
          } else if (Quaderno.productId != productId){
            Quaderno.productId = productId;
            Quaderno.reload();
          }
          // upgrading to Quaderno.js v4 showed a discrepancy in behavior compared
          // with v4 if country is not specified in calculateTaxes() api call the tax object is:
          // {"country":"US","region":null,"name":null,"rate":0.0,"extraName":null,"extraRate":null,"taxClass":"eservice"}
          // in v3 country was taken from the already initialized Quaderno object
          let options = { country: country };
          const tax = await Quaderno.calculateTaxes(options).catch((error: any) => {
            this.logger.error('Failed to get tax.', error);
            throw error;
          });
          // const price = await Quaderno.calculatePrice();
          // this.logger.debug('TAX:', tax);
          if (this.taxes[productId]) {
            this.taxes[productId][country] = tax;
          } else {
            this.taxes[productId] = { [country]: tax };
          }
          return tax;
        } else {
          throw new Error('Failed to get tax. Quaderno is not initialized.');
        }
      } else {
        throw new Error(`Invalid productId=${productId}`);
      }
    }
  }

  async validateBusinessNumber(businessNumber: string, country?: string): Promise<boolean> {
    // IMPORTANT:
    // https://developers.quaderno.io/#quaderno-js-reference-validatebusinessnumber
    // "Please keep in mind that business numbers are not actually validated while using the Sandbox."
    // tested with sandbox but gives undefined result
    if (this.environment.production) {
      await this.initialize().toPromise();
      if (Quaderno) {
        country = country || this.propertiesService.user.countryCode;
        this.logger.debug('validateBusinessNumber', businessNumber, country);
        Quaderno.businessNumber = businessNumber;
        Quaderno.country = country;
        try {
          const result = await Quaderno.validateBusinessNumber(); // result is json: {valid: true/false}
          console.log('validateBusinessNumber. result', result);
          return result && result.valid;
        } catch (e) {
          throw new Error(`Failed to validate business number. ${e.message}`);
        }
      } else {
        throw new Error('Failed to validate business number. Quaderno is not initialized.');
      }
    }
    return true;
  }

  async acquireCard(options?: stripe.elements.ElementsOptions): Promise<stripe.elements.Element> {
    return this.acquireElement('card', { hidePostalCode: true, ...options || {} })
  }

  async acquireIban(options?: stripe.elements.ElementsOptions): Promise<stripe.elements.Element> {
    return this.acquireElement('iban', {
      supportedCountries: ['SEPA'],
      placeholderCountry: this.propertiesService.user.countryCode || 'DE',
      ...options || {}
    });
  }

  async acquireElement(type: stripe.elements.elementsType, options?: stripe.elements.ElementsOptions): Promise<stripe.elements.Element> {
    let entry = get(this.stripeElementsPool, type, null);
    if (!entry) {
      if (!this.stripeElements) {
        await this.initialize().toPromise();
        if (!this.stripeElements) {
          this.stripeElements = this.stripe.elements();
        }
      }
      // https://stripe.com/docs/stripe-js/reference#element-options
      const element = this.stripeElements.create(type, options);
      entry = { element: element, references: 1 };
      if (!this.stripeElementsPool) {
        this.stripeElementsPool = { [type]: entry };
      } else {
        this.stripeElementsPool[type] = entry;
      }
      if (Object.keys(this.stripeElementsPool).length==1) {
        this.triggerElementsCollector();
      }
    } else {
      entry.references++;
    }
    return entry.element;
  }

  releaseElement(element: stripe.elements.Element) {
    if (element) {
      for (let key of Object.keys(this.stripeElementsPool)) {
        const entry = this.stripeElementsPool[key];
        if (entry.element == element) {
          entry.references--;
          if (entry.references==0) {
            entry.releaseTime = new Date().getTime();
          }
          element.unmount();
          break;
        }
      }
    }
  }

  protected triggerElementsCollector() {
    const timeout = 1000 * 60 * 2; // 2 min timeout after last release
    const interval = window.setInterval(() => {
      const now = new Date().getTime();
      Object.keys(this.stripeElementsPool).forEach((key) => {
        const entry = this.stripeElementsPool[key];
        if (entry.references==0 && entry.releaseTime < now - timeout) {
          entry.element.destroy();
          delete this.stripeElementsPool[key];
        }
      });
      if (Object.keys(this.stripeElementsPool).length==0) {
        clearInterval(interval);
      }
    }, 15000);  // check every 15 sec.
  }

  // async purchase(productId: string, card: stripe.elements.Element, taxId: string) {
  async purchase(productId: string, element: stripe.elements.Element, customer: Company, paymentMethod?: PaymentMethod): Promise<{source: Source, response: any}> {
    await this.initialize().toPromise();
    const user = this.propertiesService.user;
    const options: any = {
      owner: {
        name: user.name,
        address: {
          city: user.community,
          country: user.countryCode,
          line1: user.address,
          // line2: '',
          postal_code: user.zip,
        },
        email: user.email,
        phone: user.phone
      }
    };
    if (paymentMethod=='sepa') {
      options.type = 'sepa_debit';
      options.currency = 'eur';
      options.mandate = {
        // Automatically send a mandate notification email to your customer
        // once the source is charged.
        notification_method: 'email',
      }
    }
    //see https://stripe.com/docs/billing/subscriptions/fixed-price for updated examples using new stripe api
    // Source API is now obsolete!
    const { source, error } = await this.stripe.createSource(element, options);
    // source.client_secret:
    // A secret available to the web client that created the Source,
    // for purposes of retrieving the Source later from that same client.
    if (!error) {
      // const transaction = { type: 'stripe', source: source, taxId: taxId };
      const transaction = { type: 'stripe', source: source, customer: customer };
      const body = {id: productId, transaction: transaction};
      this.logger.debug('REQUEST', body);
      const response: any = await this.httpClient.post('/v1.0/payment/process', body).toPromise();
      this.logger.debug('RESPONSE', response);
      if (response && !response.error) {
        if (get(response, 'transaction.status') == 'requires_action') {
          // https://stripe.com/docs/billing/subscriptions/payment#handling-action-required
          // const { paymentIntent, error } = await this.stripe.handleCardAction(get(response, 'transaction.client_secret'));
          const { paymentIntent, error } = await this.stripe.handleCardPayment(get(response, 'transaction.client_secret'));
          if (!error) {
            // this.httpClient.post(
            //   '/v1.0/payment/validate',
            //   { ...body, transaction: { ...transaction, payment_intent_id: paymentIntent.id }}
            //   ).subscribe((response: any) => {
            //     if (!response.error) {
            //       this.paymentCompleted.emit();
            //     } else {
            //       this.cardError = response.error || 'Failed server payment confirmation after handling card action';
            //       this.paymentCompleted.emit( { error:  this.cardError});
            //     }
            //     this.loading = false;
            //   });
            // return;
            return { source: source, response: response };
          } else {
            throw error;
          }
        }
        return { source: source, response: response };
      } else {
        throw (response && response.error) || new Error('Server Error');
      }
    } else {
      throw error;
    }
  }

  restorePurchases() {
    // NOOP for now (and maybe forever)
  }

  getInterval(productId: string): {value: number, name: string} {
    // const name  = productId?.slice(productId.lastIndexOf('_')+1)?.toLowerCase();
    // // const value = productId == ProductId.REALIZER_PRO_SUBSCRIPTION_MONTHLY  ? 1  :
    // //               productId == ProductId.REALIZER_PRO_SUBSCRIPTION_BIANNUAL ? 6  :
    // //               productId == ProductId.REALIZER_PRO_SUBSCRIPTION_ANNUAL   ? 12 : 0;
    // const value = name == 'monthly'  ? 1  :
    //               name == 'biannual' ? 6  :
    //               name == 'annual'   ? 12 : 0;
    // return { value: value, name: name };
    return this.productAdapter.interval(productId);
  }

  getType(productId: string): string {
    // let parts = productId?.split('_');
    // return parts?.length > 1 ? parts[1] : undefined;
    return this.productAdapter.type(productId);
  }

  /*** PUBLIC API END ***/

  protected async initializeProducts(): Promise<void> {
    const promises: Promise<Product>[] = [];
    this.products = this.products || {};
    if (this.environment.production) {
      Object.keys(ProductId).forEach((productId) => {
        const promise = this.initializeProduct(
          ProductId[productId],
          this.products && this.products[ProductId[productId]]
        );
        promises.push(promise);
      });
    } else {
      const products: any[] = await this.httpClient.get<any[]>('/v1.0/payment/products').toPromise();
      this.logger.debug('Products', products);
      Object.keys(this.products)
        .filter(productId => !products.find(p => p.id == productId))
        .forEach(productId => delete this.products[productId]);
      products.map(async (product) => {
        const current = this.products && this.products[product.id];
        promises.push(this.initializeProduct(product, current));
      });
    }
    return Promise.all(promises).then((products) => {
      this.products = {};
      products.forEach((product) => {
        this.products[product.id] = product;
      });
    });
  }

  protected loadStripe(): Promise<any> {
    return loadScript(this.document, 'https://js.stripe.com/v3/');
  }

  protected loadQuaderno(): Promise<any> {
    return loadScript(this.document, 'https://js.quaderno.io/v4/');
  }

  protected async initializeProduct(product: string | Partial<Product>, current?: Product): Promise<Product> {
    this.logger.info('initializeProduct', product, current);
    const result: any = {
      loaded: true,
      valid: true,
      canPurchase: current ? current.canPurchase : true,
      owned: current ? current.owned : false
    };
    if (typeof(product) == "string") {
      // complete client-side product initialization
      // now the product list should come from server )see initializeProducts())
      Object.assign(result, {
        id: product,
        type: 'subscription',
        state: '',
        currency: '€',
      });
      switch(product) {
        case ProductId.REALIZER_PRO_SUBSCRIPTION_MONTHLY  : result.price = '14.99';  break;
        case ProductId.REALIZER_PRO_SUBSCRIPTION_BIANNUAL : result.price = '59.94';  break;
        case ProductId.REALIZER_PRO_SUBSCRIPTION_ANNUAL   : result.price = '101.88'; break;
      }
      result.priceMicros = result.price * 1000000;
    } else {
      Object.assign(result, product);
    }
    const productId = typeof(product) == "string" ? product : product.id;
    const parts = productId?.split('_');
    if (parts.length>=4) {
      const type = parts[1];
      const plan = parts[3]; //`productId.slice(productId.lastIndexOf('_')+1)`;
      const titleKey = `product.${type}.name`;
      const descriptionKey = `plan.${plan}.name`;
      return this.translateService.get([titleKey, descriptionKey])
        .toPromise()
        .then(labels => {
          result.title = labels[titleKey];
          result.description = labels[descriptionKey];
          return result;
        });
    } else {
      return Promise.reject('Invalid product');
    }
  }

  protected displayStripeOverlay(product: Product, contact: Contact, callback?: (result: any) => void): OverlayRef  {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      // backdropClass: 'cdk-overlay-transparent-backdrop',
      // panelClass: ['app-stripe', 'overlay'],
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      disposeOnNavigation: true
    });
    const overlayRef = this.overlay.create(overlayConfig);
    const overlayComponentRef = new StripeOverlayRef(overlayRef);
    const injectionTokens = new WeakMap();
    injectionTokens.set(StripeOverlayRef, overlayComponentRef);
    injectionTokens.set(STRIPE_OVERLAY_DATA, {
      product: product,
      contact: contact,
      onPaymentCompleted: callback
    });
    const injector = new PortalInjector(this.injector, injectionTokens);
    const containerPortal = new ComponentPortal(StripeOverlayComponent, null, injector);
    const containerRef: ComponentRef<StripeOverlayComponent> = overlayRef.attach(containerPortal);
    // overlayRef.backdropClick().subscribe(() => {
    //   overlayComponentRef.close();
    // });
    return overlayRef;
  }
}
