/* projects/tasks/src/lib/components/task-list-item/task-list-item.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;
}
mat-icon {
  color: gray;
}
.date {
  background-color: #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 3px;
}
.date > span {
  font-size: 22px;
  line-height: 1em;
}
.date > span.day {
  font-size: 36px;
}
.date > span.month {
  font-weight: bold;
  text-transform: uppercase;
}
.date.dense {
  font-size: 20px;
}
.date.dense.day {
  font-size: 36px;
}
.details {
  padding: 4px 6px 4px 6px;
  overflow: hidden;
  flex: 1;
}
.details > div:first-child .title,
.details > div:first-child .info {
  display: block;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.details > div:first-child .title > span,
.details > div:first-child .info > span {
  white-space: nowrap;
}
.details > div:first-child .title {
  font-size: 0.9em !important;
}
.details > div:first-child .info {
  display: flex;
  flex-direction: column;
  color: #9e9e9e;
  font-size: 0.85em !important;
}
.details > .time {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.details > .time mat-icon {
  vertical-align: middle;
}
.details > .time mat-icon + span {
  margin-left: 6px;
  font-size: 14px;
}
.details > .time.overdue mat-icon {
  color: #f44336;
}
.details > .time:not(last) {
  margin-right: 8px;
}
.target {
  display: flex;
  position: relative;
}
.target.media .media-card-container {
  position: relative;
}
.target.media .media-card-container media-card,
.target.media .media-card-container .media-card {
  width: 48px;
  height: 48px;
  font-size: 14px;
}
.target.media .media-card-container .badge {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 0.5em;
  text-transform: uppercase;
  min-height: 14px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2666666667);
  color: white;
}
.target.media .media-card-container .badge.played {
  top: -8px;
  right: -8px;
  padding: 2px 3px;
  line-height: 14px;
}
.target.media .media-card-container .badge.played.new {
  background-color: var(--color-accent);
  color: white;
}
.target.media .media-card-container .badge.played.touched {
  background-color: #ddd;
  color: var(--color-accent);
}
.target.media .media-card-container .badge.played.touched > span {
  margin-left: 4px;
}
.target.media .media-card-container .badge.played.touched > mat-icon {
  color: var(--color-accent);
}
.target.media .media-card-container .badge.played.completed {
  background-color: var(--color-primary);
  color: white;
}
.target.media .media-card-container .badge.played.completed > mat-icon {
  color: white;
}
.target.media .media-card-container .badge.played > mat-icon {
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.target.media .media-card-container .badge.played > mat-icon.eye {
  font-size: 1.3em;
  width: 1.3em;
  height: 1.3em;
}
.target > * {
  margin-left: 10px;
}
presence {
  position: absolute;
  right: 0;
  bottom: 0;
}
personality-colors {
  font-size: 45px;
  border: 1px solid;
  border-radius: 12px;
}
personality-colors.red {
  border-color: red;
}
personality-colors.yellow {
  border-color: #FEDD2C;
}
personality-colors.blue {
  border-color: #0a84ff;
}
personality-colors.green {
  border-color: #A5DB2E;
}
:host-context(.color-scheme-dark) mat-icon {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .date {
  background-color: #555;
  color: #E5E5E5;
}
/*# sourceMappingURL=task-list-item.component-PFTPTCM4.css.map */
