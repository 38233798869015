/* projects/maps/src/lib/components/map-component/map.component.scss */
:host {
  display: block;
  position: relative;
}
agm-map {
  position: absolute;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=map.component-2TSNK3MO.css.map */
