<ng-container *ngIf="result() as result">
<!--  <div>{{selectedTags | json}}</div>-->
<!--  <div>{{result | json}}</div>-->
  <ng-container [ngSwitch]="result?.display">
    <ng-container *ngSwitchCase="true">
      <div class="label" translate>survey.vitality.type.result</div>
      <vitality-images
        [survey]="survey"
        [vitalityTypes]="result.vitality"
        [autoWidth]="true"
        [badgeData]="badgeData"
        [defaultInfo]="defaultInfo"
        [iconOnly]="false">
      </vitality-images>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="label" *ngIf="defaultInfo" translate>survey.vitality.type.which</div>
      <ng-container *ngIf="missingResultTemplate"
                    [ngTemplateOutlet]="missingResultTemplate">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
