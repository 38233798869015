/* projects/chat/src/lib/components/message/chat-info-message/chat-info-message.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 8px;
  max-width: 300px;
}
.bubble {
  position: relative;
  word-wrap: break-word;
  vertical-align: top;
  border-radius: 7px;
  padding: 7px 7px 6px 9px;
  font-size: 14px;
}
.bubble.center {
  background-color: #f0f0f0;
}
.header > div.reply {
  padding-bottom: 8px;
}
.attachments > div {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.attachments.sections {
  padding-bottom: 8px;
}
.body {
  display: flex;
  flex-direction: row;
}
.body > .attachment {
  position: relative;
  display: flex;
}
.body > .attachment > attachment-square-container {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}
.body > .content {
  flex: 1;
  position: relative;
}
.body > .content span.text {
  text-rendering: optimizelegibility;
  word-break: break-word;
}
.body > .content span.space {
  display: inline-block;
  width: 74px;
  height: 1px;
}
.body > .content div.time {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 13px;
  line-height: 1em;
  font-weight: lighter;
}
.body > .content div.time > message-delivery-state {
  margin-left: 2px;
}
.center > .body > .content > span::ng-deep a {
  text-decoration: none;
  color: #444;
}
.center > .body > .content > span::ng-deep a:hover {
  text-decoration: underline;
  color: #333;
}
:host-context(.color-scheme-light.pure) .bubble.center {
  color: gray;
  background-color: white;
  border: 1px solid #ddd;
}
:host-context(.color-scheme-dark) .bubble.center > .body > .content > span::ng-deep a {
  text-decoration: none;
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .bubble.center > .body > .content > span::ng-deep a:hover {
  text-decoration: underline;
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .bubble.center {
  color: #E5E5E5;
  background-color: #383838;
}
/*# sourceMappingURL=chat-info-message.component-3NN442N7.css.map */
