import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact, Contacts} from '@ionic-native/contacts';
import {Platform} from "core";
import {WebView} from "@ionic-native/ionic-webview/ngx";
import {MaterialService} from "material";

@Component({
  selector: 'app-contact-local-list',
  templateUrl: './contact-local-list.component.html',
  styleUrls: ['./contact-local-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // onPush - otherwise contacts will get different colors on each change detection cycle
})
export class ContactLocalListComponent implements OnInit {

  contacts: Promise<Contact[]>;
  @Input() filter: string;
  @Output() selectionChanged = new EventEmitter<Contact>();
  selectedIndex: number;

  constructor(private nativeContacts: Contacts,
              private platform: Platform,
              private webview: WebView,
              private materialService: MaterialService) {
  }

  ngOnInit() {
    this.contacts = this.findContacts();
    // this.contacts = new Promise((resolve, reject) => {
    //   resolve([
    //     {
    //       name: {
    //         givenName: 'Mihail',
    //         familyName: 'Smith'
    //       },
    //       phoneNumbers: [{value: '999999999'}],
    //       emails: [{value: 'mihailb@gmail.com13123123123123123123123123123'}]
    //     },
    //     {
    //       name: {
    //         givenName: 'John2',
    //         familyName: 'Smith2'
    //       },
    //       phoneNumbers: [{value: '111111111123123123123123123'}],
    //       emails: [{value: 'mihailb@gmail.com123123123123123123123123123123'}]
    //     }
    //   ]);
    // });

    // photos: Array(1)
    // 0: {id: "66", pref: false, type: "url", value: "content://com.android.contacts/contacts/12/photo"}

  }

  findContacts(): Promise<Contact[]> {
    return this.platform.ready().then(() =>
      this.nativeContacts
        .find(['displayName', 'name', 'phoneNumbers', 'emails'],
          {filter: '', multiple: true}
        ).then((contacts: Contact[]) => {
          // console.log("Contacts", contacts);
          return contacts;
        })
    )
  }

  selectContact(contact: Contact, index: number) {
    this.selectionChanged.emit(contact);
    this.selectedIndex = index;
  }

  initials(contact: Contact, index: number) {
    let name = contact.name.givenName || contact.name.familyName || '';
    return name.charAt(0);
  }

  photoUrl(contact: Contact) {
    let url = '';
    if (contact && contact.photos) {
      let pref = contact.photos.some(photo => {
        url = photo.value;
        return photo.pref;
      });
      if (!pref && url!==contact.photos[0].value) {
        url = contact.photos[0].value;
      }
      url = this.webview.convertFileSrc(url); //window['Ionic']['WebView'].convertFileSrc(url);
      console.log('URL:'+url);
    }
    return url;
  }

  color(index: number): string {
    return this.materialService.color(index, { fallback: 'pink' });
  }
}
