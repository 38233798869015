<div>
  <ng-container>
    <video #video muted playsinline
           [class.hide]="!showMediaStream()"
           [class.mirror]="mirror()"
           [autoplay]="true"
           [srcObject]="mediaStream()"></video>
    <div #avatar class="avatar"
         *ngIf="!!participant() && !showMediaStream()"
         (onResize)="onResize($event)">
      <div>
        <div>
          <img matListItemAvatar
               [src]="avatarUrl() | sessionLink"
               onload="this.style.opacity=1"/>
        </div>
      </div>
    </div>
    <div #name class="name" *ngIf="showName() && !!participant()?.name">
      <span><conference-participant-indicator
        [participant]="participant()"
        [conferenceInfo]="conferenceInfo()"></conference-participant-indicator><span>{{participant().name}}</span></span>
    </div>
  </ng-container>
</div>
<div class="cover" [class.focus]="focus()"></div>
