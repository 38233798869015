/* projects/calendar/src/lib/components/calendar/calendar.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: block;
}
.header {
  text-align: center !important;
  display: flex;
  flex-wrap: wrap;
}
.header .container {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.header + * {
  margin-top: 20px;
}
div.header {
  height: 20px;
  position: relative;
}
div.header > div {
  position: absolute;
  margin: 0;
  display: block;
  bottom: 4px;
  left: 0;
  right: 0;
  text-align: center;
}
mwl-calendar-month-view,
mwl-calendar-week-view,
mwl-calendar-week-day-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
}
mwl-calendar-month-view {
  font-size: var(--monthViewFontSize, 1em);
}
mwl-calendar-month-view ::ng-deep .cal-week-view,
mwl-calendar-week-view ::ng-deep .cal-week-view,
mwl-calendar-week-day-view ::ng-deep .cal-week-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  overflow-y: auto;
}
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers {
  padding-left: 20px;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: rgba(255, 255, 255, 0.8156862745);
}
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-time-label-column,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-time-label-column,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-time-label-column {
  width: 20px;
}
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-time,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-time,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-time {
  font-weight: normal;
  font-size: 13.5px;
  line-height: 1em;
  padding: 0;
  width: 58px;
  transform: rotate(-90deg) translateX(-23.5px) translateY(-20px);
}
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-time-events,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-time-events,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-time-events {
  border: none;
  margin-bottom: 90px;
}
mwl-calendar-month-view ::ng-deep mwl-calendar-week-view-header,
mwl-calendar-week-view ::ng-deep mwl-calendar-week-view-header,
mwl-calendar-week-day-view ::ng-deep mwl-calendar-week-view-header {
  position: sticky;
  top: 0;
  z-index: 5;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-header,
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers .cal-header,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-header,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers .cal-header,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-header,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers .cal-header {
  font-weight: inherit;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-header > b,
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers .cal-header > b,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-header > b,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers .cal-header > b,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-header > b,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers .cal-header > b {
  font-weight: inherit;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-header > span,
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers .cal-header > span,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-header > span,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers .cal-header > span,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-header > span,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers .cal-header > span {
  font-size: 13.5px;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-day-cell.cal-today,
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-header.cal-today,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-day-cell.cal-today,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-header.cal-today,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-day-cell.cal-today,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-header.cal-today {
  background-color: var(--color-primary-alpha-03);
}
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-header span,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-header span,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-header span {
  color: #222;
}
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-header.cal-weekend b,
mwl-calendar-month-view ::ng-deep .cal-week-view .cal-header.cal-weekend span,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-header.cal-weekend b,
mwl-calendar-week-view ::ng-deep .cal-week-view .cal-header.cal-weekend span,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-header.cal-weekend b,
mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-header.cal-weekend span {
  color: #777;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-day-cell.cal-weekend .cal-day-number,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-day-cell.cal-weekend .cal-day-number,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #777;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-day-number,
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-day-cell.cal-today .cal-day-number,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-day-number,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-day-cell.cal-today .cal-day-number,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-day-number,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-day-cell.cal-today .cal-day-number {
  opacity: inherit;
  font-size: inherit;
}
mwl-calendar-month-view ::ng-deep .cal-month-view,
mwl-calendar-week-view ::ng-deep .cal-month-view,
mwl-calendar-week-day-view ::ng-deep .cal-month-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
mwl-calendar-month-view ::ng-deep .cal-month-view > .cal-days,
mwl-calendar-week-view ::ng-deep .cal-month-view > .cal-days,
mwl-calendar-week-day-view ::ng-deep .cal-month-view > .cal-days {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
mwl-calendar-month-view ::ng-deep .cal-month-view > .cal-days > div:last-child,
mwl-calendar-week-view ::ng-deep .cal-month-view > .cal-days > div:last-child,
mwl-calendar-week-day-view ::ng-deep .cal-month-view > .cal-days > div:last-child {
  margin-bottom: 90px;
}
mwl-calendar-month-view ::ng-deep .cal-month-view mwl-calendar-month-cell,
mwl-calendar-week-view ::ng-deep .cal-month-view mwl-calendar-month-cell,
mwl-calendar-week-day-view ::ng-deep .cal-month-view mwl-calendar-month-cell {
  position: relative;
  min-height: 64px;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-cell-top,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-cell-top,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-cell-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: unset;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-cell-space,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-cell-space,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-cell-space {
  position: relative;
  padding-top: 75%;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-events,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-events,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-events {
  display: none;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-day-number,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-day-number,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-day-number {
  position: absolute;
  display: block;
  bottom: unset;
  left: unset;
  top: 0;
  right: 0;
  margin: 8px 8px;
}
mwl-calendar-month-view ::ng-deep .cal-month-view .cal-day-badge,
mwl-calendar-week-view ::ng-deep .cal-month-view .cal-day-badge,
mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-day-badge {
  background-color: var(--color-accent);
  color: var(--color-accentContrast);
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  top: unset;
  right: unset;
  margin: 0 0 8px 8px;
}
.button-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.button-group .button {
  position: relative;
  flex: 1 1 auto;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.button-group .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.button-group .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.button-group .button.button-primary {
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.button-group .button.button-primary:hover {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
}
.button-group .button.button-outline-secondary {
  color: #424242;
  border-color: #424242;
}
.button-group .button.button-outline-secondary:hover {
  color: #f5f5f5;
  background-color: #9e9e9e;
}
h3 {
  margin-top: 16px;
}
:host-context(.color-scheme-dark) {
  background-color: #181818;
}
:host-context(.color-scheme-dark) div.header {
  background-color: #282828;
}
:host-context(.color-scheme-dark) div.controls > div {
  background-color: var(--color-primary);
}
:host-context(.color-scheme-dark) div.controls > div.disabled {
  background-color: #333;
  color: gray;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-month-view,
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-month-view,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-month-view,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view {
  background-color: #181818;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers {
  background-color: #282828;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-time-label-column,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-time-label-column,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-time-label-column {
  width: 20px;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-month-view .cal-day-cell.cal-today,
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-header.cal-today,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-month-view .cal-day-cell.cal-today,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-header.cal-today,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-day-cell.cal-today,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-header.cal-today {
  background-color: var(--color-primary-alpha-05);
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-hour-odd,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-hour-odd,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-hour-odd {
  background-color: #282828;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers {
  border-color: #777;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: #777;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-column,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-column,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-column {
  border-left-color: #777;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:first-child,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:first-child,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: #282828;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:not(:last-child),
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:not(:last-child),
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-left-color: #282828;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:not(:last-child),
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:not(:last-child),
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: #282828;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:hover,
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-day-headers .cal-drag-over,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:hover,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-day-headers .cal-drag-over,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers .cal-header:hover,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-day-headers .cal-drag-over {
  background-color: #444;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
  background-color: #444;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-header span,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-header span,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-header span {
  color: #E5E5E5;
  opacity: 1;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-header.cal-weekend b,
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-week-view .cal-header.cal-weekend span,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-header.cal-weekend b,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-week-view .cal-header.cal-weekend span,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-header.cal-weekend b,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-week-view .cal-header.cal-weekend span {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-month-view .cal-day-cell:not(:last-child),
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-month-view .cal-day-cell:not(:last-child),
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #777;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-month-view .cal-days,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-month-view .cal-days,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-days {
  border-color: #777;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-month-view .cal-days .cal-cell-row,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-month-view .cal-days .cal-cell-row,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: #777;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-month-view .cal-cell-row:hover,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-month-view .cal-cell-row:hover,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-cell-row:hover {
  background-color: #282828;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-month-view .cal-header,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-month-view .cal-header,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-header {
  background-color: #282828;
}
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-month-view .cal-cell-row .cal-cell:hover,
:host-context(.color-scheme-dark) mwl-calendar-month-view ::ng-deep .cal-month-view .cal-cell.cal-has-events.cal-open,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-month-view .cal-cell-row .cal-cell:hover,
:host-context(.color-scheme-dark) mwl-calendar-week-view ::ng-deep .cal-month-view .cal-cell.cal-has-events.cal-open,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-cell-row .cal-cell:hover,
:host-context(.color-scheme-dark) mwl-calendar-week-day-view ::ng-deep .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #444;
}
/*# sourceMappingURL=calendar.component-3PIMNHYN.css.map */
