var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { IonicNativePlugin, checkAvailability, cordovaInstance, instanceAvailability, instancePropertyGet, instancePropertySet, getPromise } from '@ionic-native/core';
var Contact = /** @class */function () {
  function Contact() {
    if (checkAvailability('navigator.contacts', 'create', 'Contacts') === true) {
      this._objectInstance = navigator.contacts.create();
    }
  }
  Contact.prototype.clone = function () {
    var _this = this;
    return function () {
      if (instanceAvailability(_this) === true) {
        var newContact = new Contact();
        for (var prop in _this) {
          if (prop === 'id') return;
          newContact[prop] = _this[prop];
        }
        return newContact;
      }
    }();
  };
  Contact.prototype.remove = function () {
    return cordovaInstance(this, "remove", {}, arguments);
  };
  Contact.prototype.save = function () {
    var _this = this;
    return function () {
      if (instanceAvailability(_this) === true) {
        return getPromise(function (resolve, reject) {
          _this._objectInstance.save(function (contact) {
            _this._objectInstance = contact;
            resolve(_this);
          }, reject);
        });
      }
    }();
  };
  Object.defineProperty(Contact.prototype, "id", {
    get: function () {
      return instancePropertyGet(this, "id");
    },
    set: function (value) {
      instancePropertySet(this, "id", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "displayName", {
    get: function () {
      return instancePropertyGet(this, "displayName");
    },
    set: function (value) {
      instancePropertySet(this, "displayName", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "name", {
    get: function () {
      return instancePropertyGet(this, "name");
    },
    set: function (value) {
      instancePropertySet(this, "name", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "nickname", {
    get: function () {
      return instancePropertyGet(this, "nickname");
    },
    set: function (value) {
      instancePropertySet(this, "nickname", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "phoneNumbers", {
    get: function () {
      return instancePropertyGet(this, "phoneNumbers");
    },
    set: function (value) {
      instancePropertySet(this, "phoneNumbers", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "emails", {
    get: function () {
      return instancePropertyGet(this, "emails");
    },
    set: function (value) {
      instancePropertySet(this, "emails", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "addresses", {
    get: function () {
      return instancePropertyGet(this, "addresses");
    },
    set: function (value) {
      instancePropertySet(this, "addresses", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "ims", {
    get: function () {
      return instancePropertyGet(this, "ims");
    },
    set: function (value) {
      instancePropertySet(this, "ims", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "organizations", {
    get: function () {
      return instancePropertyGet(this, "organizations");
    },
    set: function (value) {
      instancePropertySet(this, "organizations", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "birthday", {
    get: function () {
      return instancePropertyGet(this, "birthday");
    },
    set: function (value) {
      instancePropertySet(this, "birthday", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "note", {
    get: function () {
      return instancePropertyGet(this, "note");
    },
    set: function (value) {
      instancePropertySet(this, "note", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "photos", {
    get: function () {
      return instancePropertyGet(this, "photos");
    },
    set: function (value) {
      instancePropertySet(this, "photos", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "categories", {
    get: function () {
      return instancePropertyGet(this, "categories");
    },
    set: function (value) {
      instancePropertySet(this, "categories", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Contact.prototype, "urls", {
    get: function () {
      return instancePropertyGet(this, "urls");
    },
    set: function (value) {
      instancePropertySet(this, "urls", value);
    },
    enumerable: false,
    configurable: true
  });
  return Contact;
}();
export { Contact };
var ContactName = /** @class */function () {
  function ContactName(formatted, familyName, givenName, middleName, honorificPrefix, honorificSuffix) {
    this.formatted = formatted;
    this.familyName = familyName;
    this.givenName = givenName;
    this.middleName = middleName;
    this.honorificPrefix = honorificPrefix;
    this.honorificSuffix = honorificSuffix;
  }
  return ContactName;
}();
export { ContactName };
var ContactField = /** @class */function () {
  function ContactField(type, value, pref) {
    this.type = type;
    this.value = value;
    this.pref = pref;
  }
  return ContactField;
}();
export { ContactField };
var ContactAddress = /** @class */function () {
  function ContactAddress(pref, type, formatted, streetAddress, locality, region, postalCode, country) {
    this.pref = pref;
    this.type = type;
    this.formatted = formatted;
    this.streetAddress = streetAddress;
    this.locality = locality;
    this.region = region;
    this.postalCode = postalCode;
    this.country = country;
  }
  return ContactAddress;
}();
export { ContactAddress };
var ContactOrganization = /** @class */function () {
  function ContactOrganization(type, name, department, title, pref) {
    this.type = type;
    this.name = name;
    this.department = department;
    this.title = title;
    this.pref = pref;
  }
  return ContactOrganization;
}();
export { ContactOrganization };
var ContactFindOptions = /** @class */function () {
  function ContactFindOptions(filter, multiple, desiredFields, hasPhoneNumber) {
    this.filter = filter;
    this.multiple = multiple;
    this.desiredFields = desiredFields;
    this.hasPhoneNumber = hasPhoneNumber;
  }
  return ContactFindOptions;
}();
export { ContactFindOptions };
var Contacts = /** @class */function (_super) {
  __extends(Contacts, _super);
  function Contacts() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * Create a single contact.
   * @returns {Contact} Returns a Contact object
   */
  Contacts.prototype.create = function () {
    return new Contact();
  };
  Contacts.prototype.find = function (fields, options) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return getPromise(function (resolve, reject) {
          navigator.contacts.find(fields, function (contacts) {
            resolve(contacts.map(processContact));
          }, reject, options);
        });
      }
    }();
  };
  Contacts.prototype.pickContact = function () {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return getPromise(function (resolve, reject) {
          navigator.contacts.pickContact(function (contact) {
            return resolve(processContact(contact));
          }, reject);
        });
      }
    }();
  };
  Contacts.pluginName = "Contacts";
  Contacts.plugin = "cordova-plugin-contacts";
  Contacts.pluginRef = "navigator.contacts";
  Contacts.repo = "https://github.com/apache/cordova-plugin-contacts";
  Contacts.platforms = ["Android", "BlackBerry 10", "Browser", "Firefox OS", "iOS", "Ubuntu", "Windows", "Windows 8", "Windows Phone"];
  return Contacts;
}(IonicNativePlugin);
export { Contacts };
/**
 * @hidden
 */
function processContact(contact) {
  var newContact = new Contact();
  for (var prop in contact) {
    if (typeof contact[prop] === 'function') continue;
    newContact[prop] = contact[prop];
  }
  return newContact;
}
