import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {BasicContainerComponent, enterLeaveAnimations} from "shared";
import {getCurrentThemeMode, themeMode$, Topic} from "core";
import {takeUntil} from "rxjs";
import isEqual from "lodash/isEqual";
import {MediaService} from "media";

export type VitalityType = 'health'|'sports'|'weight';
export type VitalityTypes = {
  health: number,
  sports: number,
  weight: number
}

export const VitalityTypeDefaults: VitalityTypes = {
  health:0,
  sports:0,
  weight:0
}

@Component({
  selector: 'vitality-images',
  templateUrl: './vitality.images.component.html',
  styleUrls: ['./vitality.images.component.scss'],
  animations: [
    enterLeaveAnimations
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VitalityImagesComponent extends BasicContainerComponent {

  protected _survey: Topic;
  @Input() set survey(survey: Topic) {
    if (!isEqual(this._survey,survey)) {
      this._survey = survey;
      const mediaIds = this._survey?.properties?.mediaIds;
      this.darkMediaIds = this.lightMediaIds = {};
      Object.keys(mediaIds?.dark ?? {}).forEach(id=>this.darkMediaIds[id]=mediaIds.dark[id]);
      Object.keys(mediaIds?.light ?? {}).forEach(id=>this.lightMediaIds[id]=mediaIds.light[id]);
      this.darkMediaIds  = { ...this.lightMediaIds, ...this.darkMediaIds };
      this.lightMediaIds = { ...this.darkMediaIds, ...this.lightMediaIds };
    }
  }
  get survey(): Topic { return this._survey; }

  @Input()  autoWidth = false;
  @Input()  iconOnly  = false;
  @Input()  defaultInfo = false;
  @Input()
  set vitalityTypes(vitalityTypes: VitalityTypes) {
    if (!!vitalityTypes && !isEqual(vitalityTypes,this._vitalityTypes)) {
      this._vitalityTypes = vitalityTypes;
      this.displayCount = Object.values(vitalityTypes).filter(value => value >= 2).length;
      this.onUpdate.emit(vitalityTypes);
    }
  }

  get vitalityTypes(): VitalityTypes {
    return this._vitalityTypes;
  }

  protected _badgeData:string = undefined;
  @Input()
  set badgeData(badgeData:string) {
    //console.log("SURVEY.DATA.badge.set",badgeData);
    if (this._badgeData!=badgeData) {
      if (!!badgeData) {
        const params = badgeData.match(VitalityImagesComponent.pattern);
        if (params?.length == 4) {
          this._badgeData = badgeData;
          this.vitalityTypes = { health: parseInt(params[1]), sports: parseInt(params[2]), weight: parseInt(params[3]) };
        } else {
          this._badgeData = undefined;
        }
      } else {
        this._badgeData = undefined;
      }
    }
  }
  get badgeData():string {
    return this._badgeData;
  }

  @Output() onUpdate  = new EventEmitter<VitalityTypes>();
  @Output() onDisplay = new EventEmitter<boolean>();

  protected _displayCount = 0;
  set displayCount(displayCount: number) {
    if (this._displayCount!=displayCount) {
      const display = displayCount>0
      if ((this._displayCount>0) != display) {
        this.onDisplay.emit(display);
      }
      this._displayCount = displayCount;
    }
  }

  get displayCount(): number {
    return this._displayCount;
  }

  get display(): boolean {
    return this._displayCount>0 || this.showDefault;
  }

  protected _vitalityTypes: VitalityTypes = VitalityTypeDefaults;
  protected darkMediaIds:  {[key:string]:string} = {};
  protected lightMediaIds: {[key:string]:string} = {};

  get mediaIds():{[key:string]:string} {
    return getCurrentThemeMode()=='light' ? this.lightMediaIds : this.darkMediaIds;
  }

  static pattern = /badge\.vitality\.(\d+)\.(\d+)\.(\d+)$/;

  constructor(
    public mediaService: MediaService,
    protected changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    themeMode$.pipe(takeUntil(this.onDestroy$)).subscribe(mode=>{
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  get showDefault():boolean {
    const vitalityTypes = this.vitalityTypes;
    return this.defaultInfo &&
          (!vitalityTypes ||
            vitalityTypes.health==0 &&
            vitalityTypes.sports==0 &&
            vitalityTypes.weight==0);
  }
}
