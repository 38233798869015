import {Action, createAction, props} from "@ngrx/store";
import {Attendee} from "../../models/attendee";

export enum AttendeeActionIds {
  LOAD_REQUEST       = 'calendar.event.attendee.load.request',
  LOAD_PAGE          = 'calendar.event.attendee.load.page',
  LOAD_PAGE_SUCCESS  = 'calendar.event.attendee.load.page.success',
  LOAD_PAGE_FAILURE  = 'calendar.event.attendee.load.page.failure',
  UPDATE_FILTER      = "calendar.event.attendee.update.filter",
  UPDATE_SEARCH_TERM = "calendar.event.attendee.update.searchTerm",
  CREATE             = "calendar.event.attendee.create",
  CREATE_SUCCESS     = "calendar.event.attendee.create.success",
  CREATE_FAILURE     = "calendar.event.attendee.create.failure",
  UPDATE             = "calendar.event.attendee.update",
  UPDATE_SUCCESS     = "calendar.event.attendee.update.success",
  UPDATE_FAILURE     = "calendar.event.attendee.update.failure",
  DELETE             = "calendar.event.attendee.delete",
  DELETE_SUCCESS     = "calendar.event.attendee.delete.success",
  DELETE_FAILURE     = "calendar.event.attendee.delete.failure",
  SUBSCRIBE          = 'calendar.event.attendee.subscribe',
  UNSUBSCRIBE        = 'calendar.event.attendee.unsubscribe'
}

export const attendeeLoadRequestAction = createAction(AttendeeActionIds.LOAD_REQUEST, props<{ eventId: string }>());

export const attendeeLoadPageAction = createAction(
  AttendeeActionIds.LOAD_PAGE,
  props<{
    eventId     : string,
    cacheId     : string,
    index       : number,
    size        : number
  }>()
);
export const attendeeLoadPageSuccessAction = createAction(
  AttendeeActionIds.LOAD_PAGE_SUCCESS,
  props<{
    eventId     : string,
    cacheId     : string,
    index       : number,
    data        : Attendee[],
    total       : number,
    dispatch    : (action: Action) => void
  }>()
);
export const attendeeLoadPageFailureAction = createAction(
  AttendeeActionIds.LOAD_PAGE_FAILURE,
  props<{
    eventId     : string,
    cacheId     : string,
    index       : number,
    size        : number
  }>()
);
export const attendeeUpdateFilterAction = createAction(AttendeeActionIds.UPDATE_FILTER, props<{ filters?: string[] }>());
export const attendeeUpdateSearchTermAction = createAction(AttendeeActionIds.UPDATE_SEARCH_TERM, props<{ term: string }>());

export class AttendeeUpdateAction implements Action {
  readonly type = AttendeeActionIds.UPDATE;
  constructor(public attendee: Attendee,
              public previous?: Attendee,
              public correlationId?: string) {
    if (!this.correlationId) {
      this.correlationId = this.attendee.id;
    }
  }
}

export const attendeeUpdateSuccessAction = createAction(AttendeeActionIds.UPDATE_SUCCESS, props<{ attendee: Attendee, correlationId?: string }>());
export const attendeeUpdateFailureAction = createAction(AttendeeActionIds.UPDATE_FAILURE, props<{ attendee: Attendee, reason: any, correlationId: string }>());

export const attendeeDeleteAction = createAction(AttendeeActionIds.DELETE, props<{ attendee: Attendee }>());
export const attendeeDeleteSuccessAction = createAction(AttendeeActionIds.DELETE_SUCCESS, props<{ attendee: Attendee }>());
export const attendeeDeleteFailureAction = createAction(AttendeeActionIds.DELETE_FAILURE, props<{ attendee: Attendee, reason: any }>());

export const attendeeSubscribeAction = createAction(AttendeeActionIds.SUBSCRIBE, props<{ id: string, attendee?: Attendee }>());
export const attendeeUnsubscribeAction = createAction(AttendeeActionIds.UNSUBSCRIBE, props<{ id: string, attendee?: Attendee }>());
