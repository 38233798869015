import {Inject, Injectable, InjectionToken, Optional} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EMPTY, of, SchedulerLike} from "rxjs";
import {Action, select, Store} from "@ngrx/store";
import {catchError, concatMap, debounceTime, map, mergeMap, switchMap, withLatestFrom} from "rxjs/operators";
import {addUrlParameter, APP_ID, ENVIRONMENT, Logger} from "core";
import {HttpClient} from "@angular/common/http";

import {State} from "../state";
import {
  CalendarEventActionIds,
  calendarEventDeleteAction,
  calendarEventDeleteFailureAction,
  calendarEventDeleteSuccessAction,
  calendarEventLoadPageAction,
  calendarEventLoadPageFailureAction,
  calendarEventLoadPageSuccessAction,
  calendarEventLoadRequestAction,
  CalendarEventUpdateAction,
  calendarEventUpdateFailureAction,
  calendarEventSetTypedFiltersAction,
  calendarEventUpdateSearchTermAction,
  calendarEventUpdateSuccessAction
} from "./actions";
import {CalendarEvent} from "../../models/event";
import {EventService} from "../../services/event.service";
import {PropertiesService} from "properties";
import {EMPTY_ARRAY} from "core";

export const EVENT_LOAD_DEBOUNCE = new InjectionToken<number>('Event Load Debounce');
export const EVENT_LOAD_SHEDULER = new InjectionToken<SchedulerLike>(
  'Event Load Scheduler'
);

@Injectable()
export class EventEffects {

  // @Effect()
  eventLoadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarEventLoadRequestAction, calendarEventSetTypedFiltersAction, calendarEventUpdateSearchTermAction),
      debounceTime(300),
      withLatestFrom(this.store$.pipe(select(state => state.calendar.events))),
      map(([action, state]) => calendarEventLoadPageAction({
        calendarId: state.calendarId,
        cacheId: state.currentCacheId,
        index:0,
        size:25
      }))
    )
  );

  // @Effect()
  eventLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarEventLoadPageAction),
      // debounceTime(this.debounce || 300, this.scheduler || asyncScheduler),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store$.pipe(select(state => state.calendar.events)))
      )),
      switchMap(([action,state]) => {
        //console.log("LOADED.PAGE.REQUEST",state.filters);
        const filters = <string[]>[].concat(...Object.keys(state.filters).map(type=>state.filters[type]??EMPTY_ARRAY));
        if (filters?.length > 0) {
          if (!action.cacheId || action.index < 0) { // || action.size <= action.index) {
            return EMPTY;
          }
          const filters = <string[]>[].concat(...Object.keys(state.filters).map(type=>state.filters[type]??EMPTY_ARRAY));
          const query = encodeURI(state.term.trim().replace(/\s+/g, ','));
          const parameters  = addUrlParameter("filter", filters.join(','),true)
            .add("query", query,true)
            .add("calendar",action.calendarId ? encodeURI(action.calendarId) : '',true)
            .toString();
          // const nextLoad$ = this.actions$.pipe(
          //   ofType(calendarEventLoadPageAction),
          //   skip(1)
          // );
          const path    = `/v1.0/calendar/event/segment/${action.cacheId}/${action.index}/${action.size}${parameters}`;
          this.logger.debug(`eventLoadPage. path:${path}`);
          // return of({data: this.events, size: this.events.length}).pipe( //TODO: used for testing
          return this.http.get(path).pipe(
            // takeUntil(nextLoad$),
            map(result => {
              //this.logger.debug("loaded...");
              return calendarEventLoadPageSuccessAction({
                calendarId: action.calendarId,
                cacheId:action.cacheId,
                index:action.index,
                data:result['data'],
                total:result['size'],
                dispatch:(dispatchAction : Action) => {
                  // this.logger.debug("dispatch loaded ["+action.index+"/"+action.size+"]");
                  this.store$.dispatch(dispatchAction);
                }})
            }),
            catchError(() => {
              return of(calendarEventLoadPageFailureAction({
                calendarId: action.calendarId,
                cacheId: action.cacheId,
                index: action.index,
                size: action.size
              }))
            })
          )
        } else {
          //console.log("LOADED.NO!");
          return of(calendarEventLoadPageSuccessAction({
            calendarId: action.calendarId,
            cacheId:action.cacheId,
            index:0,
            data:[],
            total:0,
            dispatch:(dispatchAction : Action) => this.store$.dispatch(dispatchAction)}));
        }
      })
    )
  );

  // @Effect()
  eventUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CalendarEventUpdateAction>(CalendarEventActionIds.UPDATE),
      withLatestFrom(
        this.store$.pipe(select(state => state.calendar.events)),
        this.eventService.size$
      ),
      mergeMap(([action, state])  => {
        const path = `/v1.0/calendar/event`;
        return this.http.post(path, action.event).pipe(
          map((event: CalendarEvent) => {
            const props = { event, correlationId: action.correlationId };
            return calendarEventUpdateSuccessAction(props);
          }),
          catchError((error) => {
            this.logger.error('ERROR', error);
            return of(calendarEventUpdateFailureAction({
              event: action.previous,
              reason: error,
              correlationId: action.correlationId
            }));
          })
        )
      })
    )
  );

  // @Effect()
  eventDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarEventDeleteAction),
      withLatestFrom(this.store$.pipe(select(state => state.calendar.events))),
      mergeMap(([action, state])  => {
        const path = `/v1.0/calendar/event/${action.event.id}`;
        return this.http.delete(path).pipe(
          map((event: CalendarEvent) => {
            return calendarEventDeleteSuccessAction({ event: event || action.event } );
          }),
          catchError((error) => {
            this.logger.error('ERROR', error);
            return of(calendarEventDeleteFailureAction({ event: action.event, reason: error }));
          })
        )
      })
    )
  );


  // protected events: CalendarEvent[] = []; // dummy events for testing
  protected logger = new Logger('EventEffects');

  constructor(private http: HttpClient,
              private actions$: Actions,
              private store$: Store<State>,
              private propertiesService: PropertiesService,
              private eventService: EventService,
              @Inject(APP_ID) private appId: number,
              @Inject(ENVIRONMENT) private environment: any,
              @Optional()
              @Inject(EVENT_LOAD_DEBOUNCE)
              private debounce: number,
              // scheduler injected for mock out purposes to assist testing
              @Optional()
              @Inject(EVENT_LOAD_SHEDULER)
              private scheduler: SchedulerLike) {
    /*
    const events = [];
    let time = new Date().getTime();
    let end = new Date(2020, 11, 31).getTime();
    for (let index = 0; index<=100; index++) {
      const from = new Date(time + Math.random() * (end - time)).getTime();
      const to = new Date(from + Math.random() * (end - from)).getTime();
      const event = new  CalendarEvent({
        id: `${index}`,
        author: this.propertiesService.user,
        name: `Event ${index} with Content`,
        info: `Event Info ${index}`,
        timeCreated: time,
        timeUpdated: time,
        countryCodes: [this.propertiesService.user.country_code],
        countryCodesExcluded: false,
        tags: [],
        filters: {},
        properties: undefined,
        time_zone_id: undefined,
        timeFrom: from,
        timeTo: to,
        timeBookingFrom: new Date(time),
        timeBookingTo: new Date(time),
        calendar: {id: '1'},
        attachmentIds: [],
        location: 'Vienna',
        remote: false,
        seats: 200 + index * index,
        remote_system: undefined,
        language: 'en',
        latitude: 48.20767 + Math.max(0, index - 5),
        longitude: 13.49531 + index,
        latLngHash: undefined
      });
      events.push(event);
    }
    this.events = events.sort((c1, c2) => c1.timeFrom - c2.timeFrom);
    */
  }
}
