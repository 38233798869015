import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from "@angular/forms";
import {Contact, ENVIRONMENT, GroupMembership, Logger, Platform, Topic} from "core";
import {NULL_TASK, Task, TaskTarget} from "../../models/task";
import {BasicContainerComponent, FormChangeDetector} from "shared";
import {PropertiesService} from "properties";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {TaskService} from "../../services/task.service";
import moment from "moment";
import {FilterTagEvent} from "filter";
import isEqual from "lodash/isEqual";
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from "rxjs";
import {map, startWith, takeUntil} from "rxjs/operators";
import {ErrorStateMatcher} from "@angular/material/core";
import {MatExpansionPanel} from "@angular/material/expansion";
import {NullTaskList, TaskList} from "../../models/task-list";
import cloneDeep from "lodash/cloneDeep";
import {NgxMatDateAdapter} from "@angular-material-components/datetime-picker";
import {NgxMatDatepickerInputEvent} from "@angular-material-components/datetime-picker/lib/datepicker-input-base";
import {Color, ColorAdapter} from "@angular-material-components/color-picker";
import {Media, MediaReview, MediaReviewState, MediaService, SurveyLink} from "media";
import {MatSnackBar} from "@angular/material/snack-bar";
import {InterestsService, PersonalityColors, VitalityTypes} from "interests";

type State = {
  valid: boolean;
  dirty: boolean;
}

// for cross datetime form field validation we need to override the default mat-error behavior
// and display the error even when the control is not touched
class TaskDatetimeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && control.invalid && (!control.hasError('required') || control.touched);
  }
}

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDetailComponent extends BasicContainerComponent implements OnChanges {

  @ContentChild(TemplateRef, { static: true }) controlsTemplate: TemplateRef<any>;
  @ViewChildren('scroller', { read: ElementRef }) scrollers: QueryList<ElementRef>;

  @Input() editMode  = false;
  @Input() autoFocus = false;
  @Input() defaultTaskList: TaskList;
  @Input() lruTaskList: TaskList;
  @Input() contact: Contact;

  @Output() targetSelect = new EventEmitter<(target: TaskTarget) => void>();
  @Output() targetDisplay = new EventEmitter<TaskTarget>();
  @Output() participantsSelect = new EventEmitter<(contacts: Contact[]) => void>();
  @Output() participantsChange = new EventEmitter<Contact[]>();
  @Output() participantDisplay = new EventEmitter<Contact>();
  @Output() attachmentDisplay = new EventEmitter<Media>();
  @Output() taskListSelect = new EventEmitter<(taskList: TaskList) => void>();
  @Output() taskListCreate = new EventEmitter<(taskList: TaskList) => void>();
  @Output() state = new BehaviorSubject<State>({ valid: undefined, dirty: undefined });

  @ViewChild('participantsPanel') participantsPanel: MatExpansionPanel;

  taskForm: FormGroup;
  taskTypeLabel: string;

  timeScheduledMin: moment.Moment;
  timeScheduledMax: moment.Moment;
  timeDeadlineMin: moment.Moment;
  timeDeadlineMax: moment.Moment;
  dateTimeErrorStateMatcher = new TaskDatetimeErrorStateMatcher();
  overdue:boolean = undefined;
  taskListColor = signal<string>(undefined);

  protected _task: Task;
  protected _typesTopic: Topic;
  protected formChangeSubscription: Subscription;
  protected formChangeReset: Subject<void>;
  protected initialized = false;
  surveyResult: [Topic, PersonalityColors | VitalityTypes, string?]; // [survey, result, className]
  protected logger = new Logger('TaskDetailComponent');

  constructor(protected formBuilder: FormBuilder,
              protected formChangeDetector: FormChangeDetector,
              public propertiesService: PropertiesService,
              public translateService: TranslateService,
              public taskService: TaskService,
              public mediaService: MediaService,
              protected interestsService: InterestsService,
              public platform: Platform,
              protected dateAdapter: NgxMatDateAdapter<moment.Moment>,
              protected colorAdapter: ColorAdapter,
              protected elementRef: ElementRef,
              protected snackBar: MatSnackBar,
              protected changeDetector: ChangeDetectorRef,
              @Inject(NULL_TASK) protected nullTask: Task,
              @Inject(ENVIRONMENT) public environment: any) {
    super();
    this.taskForm   = this.createTaskForm();
  }

  ngOnInit() {
    super.ngOnInit();
    this.translateService.onLangChange
      .pipe(
        map((event: LangChangeEvent) => event.lang),
        startWith(this.translateService.currentLang),
        takeUntil(this.onDestroy$)
      )
      .subscribe(language => this.dateAdapter.setLocale(language));
    this.taskForm.get('taskList').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((taskList) => {
        const typeControl = this.taskForm.get('type');
        const type = taskList.type || 'private';
        if (type!=typeControl.value) {
          typeControl.setValue(type);
          const participantsControl = this.taskForm.get('participants');
          if (typeControl.value=='private' && participantsControl.value?.length) {
            participantsControl.setValue([]);
          }
        }
        try {
          const color: Color = taskList?.color ? this.colorAdapter.parse(taskList.color) : undefined;
          const hexColor =  color?.toHexString(true);
          if (hexColor!=this.taskListColor()) {
            this.taskListColor.set(color?.a != 0 ? hexColor : undefined);
          }
        } catch (e) {
          this.logger.warn(`invalid task list color: ${taskList.color}`)
        }

    });
    if (!this.formChangeSubscription) {
      this.formChangeReset = new Subject();
      this.formChangeSubscription =  this.taskForm.statusChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((status: FormControlStatus) => {
          this.state.next({ ...this.state.getValue(), valid: this.taskForm.valid });
        });
      this.formChangeSubscription.add(this.formChangeDetector
        .detect(this.taskForm, {
            normalizer: (value) => Promise.resolve(this.current),
            resetter: this.formChangeReset,
            log: true
          })
          .subscribe((pristine: boolean) => {
            this.logger.debug('PRISTINE', pristine);
            // this.updateFormState();
            if (this.taskForm.pristine && !pristine) {
              this.taskForm.markAsDirty();
            } else if (!this.taskForm.pristine && pristine) {
              this.taskForm.markAsPristine();
            }
            this.state.next({ ...this.state.getValue(), dirty: !pristine });
          })
        );
        this.formChangeSubscription.add(() => {
          this.formChangeSubscription = null;
          this.formChangeReset.complete();
          this.formChangeReset = null;
          this.logger.debug('formChangeSubscription -> unsubscribed!');
        });
    }
    this.update(this.task, this.typesTopic);
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    const taskChange = changes['task'];
    const typesTopicChange = changes['typesTopic'];
    const typesTopic: Topic = typesTopicChange?.currentValue || this.typesTopic;
    const task: Task = taskChange?.currentValue || this.task;
    if (taskChange) {
      const now = new Date()
      this.overdue = this.task.timeDeadline <= now.getTime();
      const media = this.task.getTarget('media')?.media;
      if (media?.mediaType=='survey') {
        let survey = media;
        // TODO: the following code is duplicated on some places - should be moved to a service
        // if survey link does not contain selected tags
        // lookup for interestTags in properties
        const link = survey.links?.[0] as SurveyLink;
        if (link?.survey && !link.selectedTags) {
          survey = cloneDeep(survey);
          let clonedLink = survey.links[0];
          const interestTags = this.propertiesService.user.interestTags || [];
          const surveyTags = interestTags
            .map(tag => tag?.startsWith('interest.') ? tag.substring('interest.'.length) : tag)
            .reduce((surveyTags, interestTag) => {
              if (interestTag?.startsWith(`${link.survey.id}`) &&
                !surveyTags.includes(interestTag)) {
                surveyTags.push(interestTag);
              }
              return surveyTags;
            }, []);
          survey.links[0] = {
            ...clonedLink,
            selectedTags: surveyTags
          };
          const surveyId = link.survey.id;
          if (surveyId=='personality') {
            const result = this.interestsService.personalityResult(link.survey, surveyTags, 'personality.survey');
            if (result?.colors) {
              const className = ((colors: PersonalityColors) => {
                return Object.entries(colors)
                  ?.reduce((max, [color, value]) => {
                    if (!max || max[1]<value) max = [color, value];
                    return max;
                  }, undefined as [string, number])?.[0];
              })(result?.colors);
              this.surveyResult = [link.survey, result.colors, className];
            }
          } else if (surveyId=='vitality') {
            const result =  this.interestsService.vitalityResult(link.survey, surveyTags, 'vitality.survey');
            this.surveyResult = [link.survey, result.vitality];
          }
        } else {
          this.surveyResult = undefined;
        }
      }
    }
    if (task && typesTopic) {
      this.taskTypeLabel = typesTopic.topics?.find(topic => topic.id == task.taskList?.type)?.label;
    } else if (this.taskTypeLabel){
      this.taskTypeLabel = undefined;
    }
    const editModeChange = changes['editMode'];
    if (editModeChange) {
      this.logger.debug('EDIT MODE CHANGE');
      this.formChangeReset?.next();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formChangeSubscription?.unsubscribe();
  }

  get current(): Task {
    const task = cloneDeep(this._task);
    // const value = this.taskForm.value;
    // Object.entries(value).reduce((task, [key, value]: [string, any]) => {
    //   if (task.hasOwnProperty(key)) {
    //     task[key] = value;
    //   }
    //   return task;
    // }, task)
    task.title = this.taskForm.get('title').value;
    task.info = this.taskForm.get('info').value;

    // HttpClient uses JSON.stringify() which strips out undefined properties.
    // Undefined properties have no JSON representation by design
    task.timeScheduled = this.taskForm.get('timeScheduled').value?.valueOf() || null;
    task.timeDeadline = this.taskForm.get('timeDeadline').value?.valueOf() || null;
    task.editorsTerm = this.taskForm.get('editorsTerm').value || null;
    task.viewersTerm = this.taskForm.get('viewersTerm').value || null;
    // task.type = this.taskForm.value.type;

    const taskContactTarget = task.getTarget('contact');
    const taskGroupMembership: GroupMembership = taskContactTarget?.contact?.groups?.[this.propertiesService.groupId];
    const contactTarget = this.taskForm.get('target').getRawValue();
    const groupMembership: GroupMembership = this.taskForm.get('groupMembership').value;
    const dirtyContactTarget =  taskContactTarget?.contact?.id!=contactTarget?.id;
    if (dirtyContactTarget) {
      task.target = new TaskTarget('contact', new Contact({ id: contactTarget?.id }));
    } else if (taskContactTarget?.contact && !isEqual(taskGroupMembership, groupMembership)) {
      taskContactTarget.contact.groups[this.propertiesService.groupId] = groupMembership;
    }
    const taskMediaTarget = task.getTarget('media');
    const taskMediaReview = taskMediaTarget?.media?.review;
    const mediaReview: MediaReview = this.taskForm.get('mediaReview').value;
    const mediaReviewDirty = taskMediaReview?.state != mediaReview?.state ||
                                      !taskMediaReview?.state && mediaReview?.state=='pending' ||
                                      taskMediaReview?.reason !== mediaReview?.reason &&
                                    !!mediaReview.reason && !!taskMediaReview.reason;
    if (mediaReviewDirty) {
      this.logger.debug('mediaReviewDirty', mediaReviewDirty);
      mediaReview && (mediaReview.contact = (({id, name}) => ({id, name}))(this.contact));
      task.target = new TaskTarget('media', { ...taskMediaTarget.media, review:  mediaReview});
    }
    task.participants = this.taskForm.get('participants').value;
    task.attachments = this.taskForm.get('attachments').value;
    const taskList = this.taskForm.get('taskList').getRawValue();
    task.taskList = taskList?.id ? taskList : this.defaultTaskList;
    return task;
  }

  @Input()
  set task(task: Task) {
    this.logger.debug('SET TASK', {task});
    if (this.initialized) {
      this.update(task, this.typesTopic);
    } else {
      this._task = task;
    }
  }

  get task(): Task {
    return this._task;
  }

  @Input()
  set typesTopic(typesTopic: Topic) {
    if (this.initialized) {
      this.update(this.task, typesTopic);
    } else {
      this._typesTopic = typesTopic;
    }
  }

  get typesTopic() {
    return this._typesTopic;
  }
  timeCreated(task: Task) {
    const timeCreated = task ? task.timeCreated : null;
    if (timeCreated) {
      return new Date(timeCreated);
    }
    return new Date();
  }

  date(time: number) {
    return time ? new Date(time) : undefined;
  }

  onTimeScheduledChange(event: NgxMatDatepickerInputEvent<any>) {
    const timeScheduled = event.value;
    this.timeDeadlineMin = timeScheduled;
  }

  onTimeDeadlineChange(event: NgxMatDatepickerInputEvent<any>) {
    // const timeDeadline = event.value;
    // this.timeScheduledMax = timeDeadline;
  }

  onTapTypeFilter(event: FilterTagEvent) {
    const type = event.filter.id;
    const value = this.taskForm.getRawValue();
    if (type=='private' && value.participants?.length) {
      value.participants = [];
    }
    this.taskForm.setValue({...value, type});
  }

  protected createTaskForm(): FormGroup {
    const now = moment();
    return this.formBuilder.group({
      title           : [ '', Validators.required ],
      author          : [ '' ],
      info            : [ '' ],
      taskList        : this.formBuilder.group({ id : [ null ], title: { value: '', disabled: true }, type: { value: 'private'}, color: [ null ]}),
      target          : this.formBuilder.group({ id : [ null ], name: { value: '', disabled: true }}),
      groupMembership : [],
      mediaReview     : [],
      participants    : [[]],
      attachments     : [[]],
      timeCreated     : [ { value: now, disabled: false }, Validators.required ],
      timeScheduled   : [ { value: now, disabled: false } ],
      timeDeadline    : [ { value: now, disabled: false } ],
      editorsTerm     : [],
      viewersTerm     : [],
      type            : ['private'],
    });
  }

  protected update(task: Task, typesTopic: Topic) {
    if (!this.initialized || !isEqual(this.task, task)) {
      task = new Task({...this.nullTask, ...task});
      this._task = task;
    }
    if (!isEqual(this.typesTopic, typesTopic)) {
      this._typesTopic = typesTopic;
    }
    const timeCreated = this.timeCreated(this.task);
    // this.timeScheduledMin = moment(timeCreated);
    this.timeDeadlineMin = task.timeScheduled ? moment(task.timeScheduled) : undefined; // || this.timeScheduledMin);
    const taskList = task.taskList instanceof NullTaskList ? (this.lruTaskList || this.defaultTaskList) : task.taskList;
    const targetMedia = task.getTarget('media');
    const mediaReview= targetMedia?.actions?.find(action => action.id == 'review')
      ? targetMedia.media.review || { state: MediaReviewState.Pending }
      : undefined;
    const targetContact = task.getTarget('contact');
    const groupMembership = targetContact?.actions?.find(action => action.id == 'groupMembership.review')
      ? targetContact.contact?.groups?.[this.propertiesService.group.id]
      : undefined
    const value: any = {
      title           : task.title,
      author          : task.author.name,
      info            : task.info,
      taskList        : taskList,
      target          : targetContact?.contact,
      groupMembership : groupMembership,
      mediaReview     : mediaReview,
      participants    : task.participants || [],
      attachments     : task.attachments || [],
      timeCreated     : timeCreated,
      timeScheduled   : task.timeScheduled ?  moment(task.timeScheduled) : undefined,
      timeDeadline    : task.timeDeadline  ?  moment(task.timeDeadline)  : undefined,
      editorsTerm     : task.editorsTerm,
      viewersTerm     : task.viewersTerm,
      type            : taskList.type //task.type && !task.isNew ? task.type : typesTopic?.topics?.find(topic => topic.preselected)?.id
    };
    this.taskForm.reset(value);
    this.logger.debug('UPDATE', task);
    this.formChangeReset.next();
  }

  onTapDatePicker(controlName: string, input: HTMLInputElement, datePicker: any, event: MouseEvent) {
    const target: HTMLElement = event.target as HTMLElement;
    if (target.classList.contains('mat-mdc-button-touch-target')) { // clear button clicked?
      this.taskForm.get(controlName).setValue(null);
      if (controlName=='timeScheduled') {
        this.timeDeadlineMin = null;
      }
      input.blur();
    } else {
      datePicker.open();
    }
  }

  onTaskTargetSelect(event: MouseEvent) {
    this.targetSelect.emit((target: TaskTarget) => {
      if (target.isContact()) {
        const contact = target.contact;
        this.logger.debug('onTaskTargetSelect.callback', { contact });
        const targetId = this.taskForm.get('target').value?.id;
        if (contact?.id != targetId) {
          const pick = ({ id, name }) => ({ id, name });
          this.taskForm.get('target').setValue(pick(contact || { id: null, name: '' }));
        }
      } else if (target.isMedia()) {
      }
    });
  }

  onParticipantSelect(event: Event) {
    this.participantsSelect.emit((contacts: Contact[]) => {
      this.logger.debug('onTaskParticipantsSelect.callback', { contacts });
      const pick = ({ id, name }) => ({ id, name });
      const participants = contacts?.reduce((acumulator, participant) => {
        acumulator.push(pick(participant || ({ id: null, name: '' } as Contact)));
        return acumulator;
      }, [] as {id: string, name: string}[])
      this.taskForm.get('participants').setValue(participants);
    });
  }

  onParticipantRemove(participant: Contact) {
    const participantControl = this.taskForm.get('participants');
    this.logger.debug('onParticipantRemove', { participants: participantControl.value, participant });
    const participants = participantControl.value?.filter(c => c!=participant)
    participantControl.setValue(participants);
    this.logger.debug('onParticipantRemove.2', {participants, value: this.taskForm.getRawValue()});
  }

  onTaskListSelect(event: Event) {
    this.taskListSelect.emit((taskList: TaskList) => {
      this.logger.debug('onTaskListSelect.callback', { taskList });
      const taskListControl = this.taskForm.get('taskList');
      const taskListId = taskListControl.value?.id;
      if (taskList?.id != taskListId) {
        const pick = ({id, title, type, color}) => ({id, title, type, color});
        const taskListValue = pick(
            taskList ||
            this.lruTaskList ||
            this.defaultTaskList ||
            {id: null, title: '', type: 'private', color: null}
        )
        taskListValue.color = taskListValue.color || null;
        taskListControl.setValue(taskListValue, {});
      }
    });
  }

  onTaskListCreate($event: MouseEvent) {
    this.taskListCreate.emit((taskList: TaskList) => {
      this.logger.debug('onTaskListCreate.callback', { taskList });
      const currentTaskList = this.taskForm.get('taskList').value;
      if (taskList?.id && taskList?.title && !isEqual(taskList, currentTaskList)) {
        const pick = ({ id, title, type }) => ({ id, title, type });
        this.taskForm.get('taskList').setValue(pick(taskList));
      }
    });
  }

  onTaskListClear(event: MouseEvent) {
    event.stopPropagation();
    const defaultTaskList = {
          id    : this.defaultTaskList?.id    || null,
          title : this.defaultTaskList?.title || '',
          type  : this.defaultTaskList?.type  || 'private',
          color : null
    }
    this.taskForm.get("taskList").setValue(defaultTaskList)
  }

  onAttachmentAdd($event: MouseEvent) {
    // this.mediaDefaultName().subscribe((mediaName) => {
      // this.logger.info('onUploadMedia', 'mediaName', mediaName);
      const language = this.translateService.currentLang;
      const media = { /*name: mediaName,*/ tags: ['tasks.attachment'], language };
      const options = { media };
      this.mediaService
        .uploadMedia(this.elementRef, options)
        .subscribe(mediaUploadRef => {
          const {uploadId, uploadRef, complete} = mediaUploadRef;
          complete.then(mediaUploads => {
            const error = mediaUploads.find(mediaUpload => mediaUpload.error || !mediaUpload.media);
            if (error) {
              this.translateService.get('media.uploadResult.mediaError').subscribe((translatedMessage) => {
                this.snackBar.open(translatedMessage, this.translateService.instant('actions.close'), {
                  duration: 2000,
                  horizontalPosition: 'right',
                  // verticalPosition: 'bottom'
                });
              });
            } else {
              mediaUploads.forEach((mediaUpload) => {
                let attachments: Media[] = this.taskForm.get('attachments').value;
                attachments?.unshift(mediaUpload.media);
                this.taskForm.get('attachments').setValue(attachments);
                this.changeDetector.detectChanges();
              });
            }
          });
        });
  }

  onAttachmentRemove(attachment: Media) {
    const attachmentsControl = this.taskForm.get('attachments');
    this.logger.debug('onAttachmentRemove', { attachments: attachmentsControl.value, attachment });
    const attachments = attachmentsControl.value?.filter(a => a!=attachment);
    attachmentsControl.setValue(attachments);
    this.logger.debug('onAttachmentRemove.2', { attachments, value: this.taskForm.getRawValue() });
  }

  getAvatarUrl(contact:Contact): string {
    return `${this.environment.serverUrl}/v1.0/content/avatar/${contact?.version || 0}/${contact?.id}.jpg`;
  }

  media$(media: Media): Observable<Media> {
    // the task is not reloaded when target media changes (this behaviour could be changed in the future)
    // therefore if its media target is modified externally
    // we need to update the ui
    return combineLatest([
      this.mediaService.getMedia$(media?.id),
      of(media)
    ]).pipe(map(([m1, m2]) => m1 || m2));
  }
}
