import {ChangeDetectionStrategy, Component, effect, signal, ViewChild} from '@angular/core';
import {BasicContainerComponent} from "shared";
import {LayoutService} from "layout";
import {BehaviorSubject, Subscription, takeUntil} from "rxjs";
import {
  ChatConversationCreateContainerComponent
} from "../conversation-create-container/chat-conversation-create-container.component";
import {ConversationData} from "../../store/models";
import {ChatService} from "../../chat.service";
import {Contact} from "core";
import {Selection} from "contact";
import {createMessageId, Participant} from "messaging";
import {MatMenuTrigger} from "@angular/material/menu";
import {filter} from "rxjs/operators";

export interface ChatConversationDetailsContext {
  conversationId: string;
}

@Component({
  selector: 'chat-conversation-details-container',
  templateUrl: './chat-conversation-details-container.component.html',
  styleUrls: ['./chat-conversation-details-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatConversationDetailsContainerComponent extends BasicContainerComponent {

  @ViewChild('edit') editContainer: ChatConversationCreateContainerComponent;
  @ViewChild(MatMenuTrigger) roleMenu: MatMenuTrigger;
  roleMenuContext:{
    x?:string,
    y?:string,
    participant?:Participant,
    previousRole?:number,
    selectedRole?:number
  } = {};

  editMode = signal<'group'|undefined>(undefined);
  isGroupConversation = signal<boolean>(false);
  conversation = signal<ConversationData>(undefined);
  contactsIds = signal<string[]>([]);
  current = signal<Contact>(undefined);

  role(contact:Contact):number {
    if (!!contact) {
      const participant = <Participant>contact;
      switch(participant.role) {
        case "blocked":
          return this.BLOCKED;
        case "owner":
          return this.OWNER;
        case "admin":
          return this.ADMIN;
        case "member":
          return this.MEMBER;
        case "guest":
          return this.GUEST;
      }
    }
    return this.UNDEFINED;
  }

  contacts$ = new BehaviorSubject<Contact[]>(undefined);
  cacheId$  = new BehaviorSubject<string>(undefined);

  OWNER     = 1_000_000;
  ADMIN     = 100_000;
  MEMBER    = 10_000;
  GUEST     = 1_000;
  BLOCKED   = 100;
  UNDEFINED = 0;

  protected conversationSubscription:Subscription = undefined;

  constructor(public chatService: ChatService,
              public layoutService: LayoutService) {
    super();
    //this.log = true;
    effect(() => {
      const context = this.internalContext();
      if (this.log) console.log("CONTEXT",context, context?.conversationId);
      if (!!context?.conversationId) {
        this.conversationSubscription?.unsubscribe();
        this.conversationSubscription = this.chatService.getConversationData$(context.conversationId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(data => {
            if (this.log) console.log("CONTEXT.CONVERSATION",data);
            if (data?.id == context?.conversationId) {
              this.conversation.set(data);
              this.isGroupConversation.set(data.type=='group');
            }
          });
        this.chatService.getParticipants$(context.conversationId)
          .then(participants=>{
            if (this.log) console.log("CONTEXT.PARTICIPANTS",participants);
            const userId = this.chatService.userId;
            this.current.set(<Contact>participants.find(p=>p.id==userId));
            this.cacheId$.next(createMessageId());
            this.contacts$.next(participants.map(participant => new Contact(participant)));
            this.contactsIds.set(participants.map(p=>p.id));
            //console.log("PARTICIPANTS",participants);
          });
      } else {
        this.conversation.set(undefined);
        this.isGroupConversation.set(false);
      }
    }, { allowSignalWrites:true });
    //console.log("ChatConversationDetailsContainerComponent.ctor()");
  }

  protected internalContext = signal<ChatConversationDetailsContext>(undefined);
  protected internalContextSubscription:Subscription = undefined;
  get context():ChatConversationDetailsContext {
    return this.internalContext();
  }

  set context(context: ChatConversationDetailsContext) {
    if (this.log) console.log("ChatConversationDetailsContainerComponent.context(",context,")","attached",this.attached$.value);
    //console.trace("ChatConversationDetailsContainerComponent.context(",context,") attached",this.attached$.value,"size",this._onResize$.value);
    this.internalContextSubscription?.unsubscribe();
    this.internalContextSubscription = this.attached$.pipe(
      takeUntil(this.onDestroy$),
      filter(a=>!!a))
      .subscribe(attached=>{
      this.internalContext.set(context);
    });
  }

  triggerEditContainerAnimationDone(event:TransitionEvent) {
    if (!this.editMode() && event.propertyName=='top') {
      this.editContainer.disconnect();
    }
  }

  closeEditContainer(changed:boolean) {
    this.editMode.set(undefined);
    this.editContainer.disconnect();
    if (changed) {
      this.context = {...this.context};
    }
    //this.editContainer.reset(false);
  }

  openEditContainer() {
    this.editMode.set(this.isGroupConversation()?'group':undefined);
    this.editContainer.connect();
  }

  onSelect(selection: Selection) {
  }

  debug(context:any):true {
    if (this.log) console.log("DEBUG",context);
    return true;
  }

  openRoleMenu(event: MouseEvent,contact:Contact,role:number) {
    if (this.log) console.log("CLICK",event);
    event.preventDefault();
    this.roleMenuContext.x = event.clientX + 'px';
    this.roleMenuContext.y = event.clientY + 'px';
    this.roleMenuContext.participant = <Participant>contact;
    this.roleMenuContext.selectedRole = this.roleMenuContext.previousRole = role;
    this.roleMenu.openMenu();
  }

  select(event:MouseEvent, selected:number) {
    if (this.roleMenuContext.selectedRole==selected &&
        this.roleMenuContext.selectedRole!=this.roleMenuContext.previousRole) {
      const updateParticipant = {...this.roleMenuContext.participant};
      updateParticipant.role = selected==this.BLOCKED ? 'blocked' :
                               selected==this.OWNER   ? 'owner'   :
                               selected==this.ADMIN   ? 'admin'   :
                               selected==this.MEMBER  ? 'member'  : 'guest';
      this.chatService.updateParticipant$(this.conversation()?.id,updateParticipant)
        .then(participant=>{
          const index = this.contacts$.value.findIndex((contact)=>contact.id==participant.id);
          if (index>=0) {
            const contact = new Contact(participant);
            if (this.current()==this.contacts$.value[index]) {
              this.current.set(contact);
            }
            this.contacts$.value[index] = contact;
            this.contacts$.next([...this.contacts$.value]);
          }
        });
    } else {
      event.stopPropagation();
      event.preventDefault();
      this.roleMenuContext.selectedRole = selected;
    }
  }
}
