<ng-container *ngIf="current() as groupMembership">
  <mat-form-field>
    <mat-label translate>contact.partnerId</mat-label>
    <mat-icon matPrefix>card_membership</mat-icon>
    <input type="text"
           unmodified-input
           [value]="groupMembership.manual_partner_id || ''"
           (input)="onPartnerIdChange($event)"
           matInput
           name="partnerId"
           aria-label="partnerId"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label translate>contact.partnerStartDate</mat-label>
    <input matInput
           readonly
           (dateChange)="onPartnerStartDateChange($event)"
           [value]="groupMembership.startDate"
           [matDatepicker]="groupMembershipStartDatePicker"
           [matDatepickerFilter]="groupMembershipStartDateFilter">
    <mat-datepicker-toggle matSuffix [for]="groupMembershipStartDatePicker">
    </mat-datepicker-toggle>
    <mat-datepicker #groupMembershipStartDatePicker
                    disabled="false"
                    [touchUi]="platform.is('hybrid')">
    </mat-datepicker>
  </mat-form-field>
  <filter-tag-section *ngIf="groupMembershipTypeTopic?.topics?.length"
                      [filter]="groupMembershipTypeTopic"
                      [selected]="groupMembership.tags || []"
                      (onFilter)="onGroupMembershipTypeChange($event)">
  </filter-tag-section>
</ng-container>

<!--
<div class="chip" *ngIf="!editMode">
    <div>
      <span>{{'Partner' | translate}}</span>
      <strong>{{partnerId}}</strong>
    </div>
    <button mat-button (click)="edit()">
      <mat-icon class="action" *ngIf="true">mode_edit</mat-icon>
    </button>
</div>
<div class="edit" *ngIf="editMode">
  <input  unmodified-input matInput placeholder="{{'Partner Number' | translate}}" [(ngModel)]="partnerId"/>
  <button mat-mini-fab color="warn" (click)="save()" disabled="{{!partnerId}}"><mat-icon >done</mat-icon></button>
  <button mat-mini-fab color="accent" (click)="cancel()" *ngIf="!!previousPartnerId"><mat-icon >cancel</mat-icon></button>
</div>
-->
