/* projects/chat/src/lib/components/conversation-list/chat-conversation-item.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
  overflow: hidden;
}
:host > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 0 4px 0 10px;
}
:host > div.hover {
  background: rgba(0, 0, 0, 0.04);
}
:host > div.selected {
  background-color: #eee;
}
:host > div > .mat-ripple {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
div.avatar {
  position: relative;
  line-height: 0;
}
div.avatar img {
  opacity: 0;
  width: 50px;
  height: 50px;
}
div.avatar presence {
  position: absolute;
  right: 0;
  bottom: 0;
}
div.badge {
  position: absolute;
  right: 12px;
  top: 8px;
  font-size: 13.5px;
  line-height: 13.5px;
  padding: 2px 4px 1px 4px;
  border-radius: 4px;
  background-color: var(--color-accent);
  color: var(--color-accentContrast);
}
div.content {
  position: relative;
  flex: 1;
  padding: 8px 3px 8px 10px;
  min-height: 74px;
}
div.content > .conversation {
  padding: 8px 0 8px 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
div.content > .conversation > div.new {
  position: absolute;
  right: 2px;
  top: 6px;
  background-color: var(--color-accent);
  color: white;
  border-radius: 4px;
  padding: 0 4px;
}
div.content > .conversation > div.infos {
  width: 100%;
  overflow: hidden;
}
div.content > .conversation > div.infos > h3 {
  display: block;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.content > .conversation > div.infos > h3 > span {
  white-space: nowrap;
}
div.content > .conversation > div.infos > span {
  font-size: 13.5px;
  display: block;
  overflow: hidden;
}
div.content > .conversation > div.infos .last_action {
  color: gray;
}
div.content > .conversation > div.infos .last_action .typing {
  position: relative;
}
div.content > .conversation > div.infos .last_action .typing .dot {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-right: 3px;
  background: gray;
  animation: wave 1500ms linear infinite;
}
div.content > .conversation > div.infos .last_action .typing .dot:nth-child(1) {
  animation-delay: -800ms;
}
div.content > .conversation > div.infos .last_action .typing .dot:nth-child(2) {
  animation-delay: -1000ms;
}
div.content > .conversation > div.infos .last_action .typing .dot:nth-child(3) {
  animation-delay: -1200ms;
}
div.content > .conversation > div.infos .last_action .draft {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
div.content > .conversation > div.infos .last_action .draft > span:first-child {
  color: var(--color-accent);
  margin-right: 6px;
}
div.content > .conversation > div.infos .last_action .created {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
div.content > .conversation > div.infos .last_action .created > span:first-child {
  margin-right: 6px;
}
div.content > .conversation > div.infos .last_action .message {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
div.content > .conversation > div.infos .last_action .message > message-delivery-state {
  font-size: 1.2em;
  padding-right: 2px;
}
div.content > .conversation > div.infos .last_action .message > .you {
  position: relative;
  padding-right: 4px;
  color: rgba(0, 0, 0, 0.87);
}
div.content > .conversation > div.infos .last_action .message > .you:after {
  content: ":";
}
@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
}
:host-context(.color-scheme-dark) > div.hover {
  background: rgba(255, 255, 255, 0.1);
}
:host-context(.color-scheme-dark) > div.selected {
  background-color: #383838;
}
:host-context(.color-scheme-dark) div.content > .conversation > div > span {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) div.content > .conversation > div.infos .last_action {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) div.content > .conversation > div.infos .last_action .typing .dot {
  background: #AEAFB0;
}
:host-context(.color-scheme-dark) div.content > .conversation > div.infos .last_action .message > .you {
  color: #E5E5E5;
}
:host.dropForwardingTriggered.dropFilesOver > div:before {
  font-family: "Material Icons";
  content: "\e2c6";
  color: var(--color-accent);
  font-size: 36px;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  right: 12px;
}
:host.dropForwardingTriggered.dropFilesOver > div:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-width: 2px;
  border-style: solid;
}
:host.dropForwardingTriggered.dropFilesOver > div:before,
:host.dropForwardingTriggered.dropFilesOver > div:after {
  animation: pulse-light 1000ms infinite;
}
:host.dropForwardingTriggered.dropFilesOver > div.selected:before,
:host.dropForwardingTriggered.dropFilesOver > div.selected:after {
  display: none;
}
:host-context(.color-scheme-dark).dropForwardingTriggered.dropFilesOver > div:before,
:host-context(.color-scheme-dark).dropForwardingTriggered.dropFilesOver > div:after {
  animation-name: pulse-dark;
}
@keyframes pulse-light {
  0% {
    color: var(--color-accent-A200);
    border-color: rgba(208, 208, 208, 0.5019607843);
  }
  50% {
    color: var(--color-accent);
    border-color: #d0d0d0;
  }
  100% {
    color: var(--color-accent-A200);
    border-color: rgba(208, 208, 208, 0.5019607843);
  }
}
@keyframes pulse-dark {
  0% {
    color: var(--color-accent-A200);
    border-color: rgba(128, 128, 128, 0.5019607843);
  }
  50% {
    color: var(--color-accent);
    border-color: #808080;
  }
  100% {
    color: var(--color-accent-A200);
    border-color: rgba(128, 128, 128, 0.5019607843);
  }
}
/*# sourceMappingURL=chat-conversation-item.component-ZFSC3T5G.css.map */
