import {AfterViewInit, Component, EventEmitter, Inject, InjectionToken} from '@angular/core';
import {OverlayRef} from '@angular/cdk/overlay';
import {animate, state, style, transition, trigger} from "@angular/animations";

export const WELCOME_OVERLAY_DATA = new InjectionToken<any>('WELCOME_OVERLAY_DATA');

export class WelcomeOverlayRef {

  constructor(private overlayRef: OverlayRef) { }
  onAction = new EventEmitter<string>();
  close(): void {
    this.onAction.emit('close');
    this.onAction.complete();
    this.overlayRef.dispose();
  }
}

const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'welcome-overlay',
  template: `
    <welcome
      [@animation]="animationState"
      (@animation.start)="onAnimationStart($event)"
      (@animation.done)="onAnimationDone($event)"
      (onClose)="close()"
      style="opacity: 0">
    </welcome>
    <button  mat-flat-button color="primary"
             (click)="close()"
             aria-label="continue">
      <mat-icon>play_circle</mat-icon>
      <span translate>actions.continue</span>
    </button>
    <button mat-icon-button
            class="close"
            (click)="close()"
            aria-label="close">
      <mat-icon>close</mat-icon>
    </button>
  `,
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    button mat-icon {
      margin-right: 6px;
    }
    button.close {
        position: absolute;
        top: 0;
        right: 0;
    }
    button.close mat-icon {
      color: #aaa;
      top: constant(safe-area-inset-top);
      top: env(safe-area-inset-top);
      right: constant(safe-area-inset-right);
      right: env(safe-area-inset-right);
    }
  `],
  animations: [
    trigger('animation', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn',  style({ opacity: 1 })),
      // transition('* => fadeOut', animate(ANIMATION_TIMINGS))
      transition('* => *', animate(ANIMATION_TIMINGS))
    ])
  ]
})
export class WelcomeOverlayComponent implements AfterViewInit {

  animationState: 'fadeIn' | 'fadeOut' = 'fadeOut';

  constructor(public overlayRef: WelcomeOverlayRef,
              @Inject(WELCOME_OVERLAY_DATA) public data: any) {
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.animationState = 'fadeIn';
    }, 0);
  }

  close() {
    this.animationState = 'fadeOut';
  }

  onAnimationStart(event: AnimationEvent) {
  }

  onAnimationDone(event: AnimationEvent) {
    if (this.animationState == 'fadeOut') {
      this.animationState = undefined;
      this.overlayRef.close();
    }
  }
}
