import {
  CachedState,
  createCacheId,
  createPageLoadingArray,
  createPageLoadingProxy,
  EntityState,
  IoState,
  Logger,
  SearchableState1,
  SelectableState,
  SubscribableState,
  TypedFilterableState,
  VersionedId
} from "core";
import {CalendarEvent} from "../../models/event";
import isEqual from "lodash/isEqual";
import {createReducer, createSelector, on} from "@ngrx/store";
import {
  CalendarEventActionIds,
  calendarEventDeleteFailureAction,
  calendarEventDeleteSuccessAction,
  calendarEventDraftUpdateAction,
  calendarEventLoadPageAction,
  calendarEventLoadPageSuccessAction,
  calendarEventLoadRequestAction,
  calendarEventSetTypedFiltersAction,
  calendarEventSubscribeAction,
  calendarEventUnsubscribeAction,
  CalendarEventUpdateAction,
  calendarEventUpdateFailureAction,
  calendarEventUpdateSearchTermAction,
  calendarEventUpdateSuccessAction
} from "./actions";
import {selectCalendarState} from "../state";
import {isValidNumber, logAction} from "shared";

const logger = new Logger('Event.reducer');

export interface CalendarEventsState extends
  SearchableState1,
  TypedFilterableState,
  IoState,
  CachedState,
  SelectableState,
  EntityState<CalendarEvent>,
  SubscribableState<CalendarEvent> {
  draft: CalendarEvent;
  calendarId?: string;
}

export const initialCalendarEventsState: CalendarEventsState = {
  term: '',
  filters: {},
  entities: [],
  indices: {},
  subscriptions: {},
  currentCacheId: null,
  previousCacheId: null,
  selectedIndex: null,
  draft: undefined,
  calendarId: undefined,
  loading: false,
  error: null
};

export const eventReducer = createReducer(
  initialCalendarEventsState,
  on(calendarEventLoadRequestAction,(state, {calendarId}) => ({
    ...state,
    currentCacheId: createCacheId(),
    calendarId: calendarId
  })),
  on(calendarEventSetTypedFiltersAction,(state, action) => {
    return logAction("calendarEventSetTypedFiltersAction",true,state,action,()=>{
      if (!isEqual(state.filters, action.filters)) {
        const filters = {...action.filters??{}};
        Object.keys(filters).forEach(type=>filters[type]?.sort())
        const updated = {...state.filters,...filters};
        if (!!action.remove) {
          Object.keys(updated).forEach(type=>{
            if (!filters[type] && action.remove(type,updated[type])) {
              delete updated[type];
            }
          });
        }
        return {
          ...state,
          entities: createPageLoadingArray<CalendarEvent>(0,25, (index: number, size: number): void => {}),
          indices: {},
          filters: updated,
          currentCacheId: createCacheId()
        }
      }
      return state;
    });
  }),
    /*

    isEqual(state.filters, filters)
      ? state
      : {
        ...state,
        entities: createPageLoadingArray<CalendarEvent>(0,25, (index: number, size: number): void => {}),
        indices: {},
        filters: filters,
        currentCacheId: createCacheId()
      }
  ),*/
  on(calendarEventUpdateSearchTermAction,(state,{term})=>
    isEqual(state.term, term)
      ? state
      : { ...state, term: term, currentCacheId: createCacheId() }
  ),
  on(calendarEventLoadPageSuccessAction,(state, { cacheId, index, data, total, dispatch })=> {
    let { entities, indices, previousCacheId } = { ...state };
    const ioState = { loading: false, error: null };
    if (cacheId == state.currentCacheId && data) {
      if (state.previousCacheId != state.currentCacheId) {
        entities = createPageLoadingArray<CalendarEvent>(
          total,
          25,
          (index: number, size: number):void => {
            dispatch(calendarEventLoadPageAction({ cacheId, index, size }));
          });
        indices = {};
        previousCacheId = cacheId;
      } else {
        const backingArray: CalendarEvent[] = (<any>entities).backingArray ?? [];
        const loadPageFunction: (pageIndex: number, pageSize: number) => void = (
          (<any>state.entities).loadPageFunction ||
          (() => ((pageIndex:number, pageSize:number): void => {}))
        )() ;
        entities = createPageLoadingProxy<CalendarEvent>(backingArray,25, loadPageFunction)
      }
      entities['loaded'] = Math.max(entities['loaded'] || 0, index + data.length);
      for (let i=0, max=data.length; i<max; i++) {
        entities[index+i] = new CalendarEvent(data[i]);
        indices[data[i].id] = index+i;
      }
    }
    return { ...state, entities, indices, previousCacheId, ioState };
  }),
  {
    types: [CalendarEventActionIds.UPDATE],
    reducer: (state, action: CalendarEventUpdateAction) => {
      // optimistic update. if server reports an error we rollback - see EventService.save()
      return eventReducer(state, calendarEventUpdateSuccessAction({
        event: action.event, correlationId: action.correlationId
      }));
    }
  },
  on(calendarEventUpdateSuccessAction,(state, { event }) => {
    let index: number = state.indices[event.id];
    logger.debug(calendarEventUpdateSuccessAction.type, 'index', index);
    if (index !== undefined) {
      let backingArray : CalendarEvent[] = (<any>state.entities).backingArray;
      let loadPageFunction : (pageIndex:number,pageSize:number)=>void = (<any>state.entities).loadPageFunction();
      state = {
        ...state,
        entities: createPageLoadingProxy<CalendarEvent>(backingArray,25, loadPageFunction),
        error: null
      };
      state.entities[index] = event instanceof CalendarEvent ? event : new CalendarEvent(event);
    }
    return state;
  }),
  on(calendarEventUpdateFailureAction,(state, { event, reason,  correlationId }) => {
    logger.error(calendarEventUpdateFailureAction.type);
    if (!event.isNew) {
      // restore previous event.
      // we have optimistic event updates => new event is already in the store
      // if server reports an error have to revert the event to its original version
      return eventReducer(
        {...state, error: reason },
        calendarEventUpdateSuccessAction({ event, correlationId })
      );
    }  else {
      // failed to create new event -> keep the current state
      return state;
    }
  }),
  on(calendarEventDeleteSuccessAction,(state, { event }) => {
    let index: number = state.indices[event.id];
    if (index !== undefined) {
      let backingArray: CalendarEvent[] = (<any>state.entities).backingArray;
      let loadPageFunction: (index: number, size: number) => void = (<any>state.entities).loadPageFunction();
      // TODO: remove from store!!! - For now the store is just reloaded - see effects
      state = {
        ...state,
        entities: createPageLoadingProxy<CalendarEvent>(backingArray,25, loadPageFunction),
        error: null,
        selectedIndex: state.selectedIndex==index ? undefined : state.selectedIndex
      };
      state.entities[index] = event instanceof CalendarEvent ? event : new CalendarEvent(event);
    }
    return state;
  }),
  on(calendarEventDeleteFailureAction,(state, { event, reason }) => {
    return {...state, error: reason }
  }),
  on(calendarEventDraftUpdateAction,(state,{event}) =>
    isEqual(state.draft, event)
      ? state
      : { ...state, draft: event }
  ),
  on(calendarEventSubscribeAction,(state, action) => {
    let subscribed = state.subscriptions[action.id];
    if (!subscribed) {
      let subscriptions = { ...state.subscriptions };
      let index  = state.indices[action.id];
      let entity = action.event = isValidNumber(index) ? state.entities[index] : undefined;
      subscriptions[action.id] = {
        subscribed: 1,
        entity:entity
      };
      return {
        ...state,
        subscriptions
      }
    } else {
      action.event = subscribed.entity;
      subscribed.subscribed++;
    }
    return state;
  }),
  on(calendarEventUnsubscribeAction,(state, action) => {
    let subscribed = state.subscriptions[action.id];
    action.event = subscribed?.entity;
    if (subscribed?.subscribed>1) {
      subscribed.subscribed--;
    } else if (!!subscribed) {
      let subscriptions = { ...state.subscriptions };
      delete subscriptions[action.id];
      return {
        ...state,
        subscriptions
      }
    }
    return state;
  })
);

export const selectEventsState         = createSelector(selectCalendarState, state => state.events);
export const selectEventEntities       = createSelector(selectEventsState, state => state.entities);
export const selectEventEntitiesLength = createSelector(selectEventEntities, state => state.length);
export const selectEventFilters        = createSelector(selectEventsState, state => state.filters);
export const selectEventSearchTerm     = createSelector(selectEventsState, state => state.term);
export const selectEventCacheId        = createSelector(selectEventsState, state => state.currentCacheId);

export const selectEventDraft          = createSelector(selectEventsState, state => state.draft);

export const selectEvent = (eventId: string) => createSelector(
  selectEventsState,
  (state: CalendarEventsState): CalendarEvent => {
    let index = state.indices[eventId];
    return index >= 0 && index < state.entities.length ? state.entities[index] : null;
  }
);

export const selectEventsSubscribed = createSelector(
  selectEventsState,
  state => Object
    .keys(state.subscriptions)
    .map(id=><VersionedId>{
      id:id,
      version:state.subscriptions[id]?.entity?.version??0
    })
);
