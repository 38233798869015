import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Contact, ContactReference, ENVIRONMENT, HasContact, Logger, NULL_CONTACT} from "core";
import {ContactListItemRenderingHints} from "../contact-list-item/contact-list-item.component";
import {ResizeEvent, VirtualSectionScrollerComponent} from "shared";
import {PropertiesService} from "properties";
import {ContactService} from "../../contact.service";

export interface Selection {
  index: number;
  contact: Contact;
}

declare type ContactEntity = Contact | HasContact;

@Pipe({name: 'safeContact'})
export class SafeContactPipe implements PipeTransform {
  private logger = new Logger('SafeContactPipe').setSilent(true);
  constructor(@Inject(NULL_CONTACT) protected nullContact: Contact) {}
  transform (entity: ContactEntity): ContactReference {
    return entity instanceof Contact ? entity : (entity?.contact || this.nullContact);
  }
}

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactListComponent implements OnInit, OnDestroy {
  @HostBinding('class.trigger') trigger: boolean = false;

  @Output() selectionChanged = new EventEmitter<Selection>();

  @Input()  renderingHints: ContactListItemRenderingHints = {};
  @Input()  entities$: Observable<ContactEntity[]>;
  // @Input()  entities$: Observable<Contact[]>;
  @Input()  cacheId$: Observable<string>;
  @Input()  disabled = false;
  @Input()  subscribe = true;
  @Input()  mainButtonSpace: boolean = false;
  @Input()  startGap: number = 8;
  @Input()  endGap: number = 8;
  @Input()  itemDefaultSize: number = 76;
  @Input()  itemMinSize: number = undefined;
  @Input()  avatarSize: number = undefined;
  @Input()  dividerLeftMargin: number = undefined;

  @Input()  contactListItemTemplateRef: TemplateRef<any>;
  @Input()  deletedListItemTemplateRef: TemplateRef<any>;
  @Input()  avatarContainerTemplateRef: TemplateRef<any>;
  @Input()  avatarTemplateRef: TemplateRef<any>;
  @Input()  avatarChromeTemplateRef: TemplateRef<any>;
  @Input()  contentContainerTemplateRef: TemplateRef<any>;
  @Input()  contactNameTemplateRef: TemplateRef<any>;
  @Input()  contactInfoTemplateRef: TemplateRef<any>;
  @Input()  controlsContainerTemplateRef: TemplateRef<any>;
  @Input()  controlsTemplateRef: TemplateRef<any>;

  @ViewChild('contactListItemTemplate', { static: true }) contactListItemTemplate: TemplateRef<any>;
  @ViewChild('deletedListItemTemplate', { static: true }) deletedListItemTemplate: TemplateRef<any>;
  @ViewChild('avatarContainerTemplate', { static: true }) avatarContainerTemplate: TemplateRef<any>;
  @ViewChild('avatarTemplate', { static: true }) avatarTemplate: TemplateRef<any>;
  @ViewChild('avatarChromeTemplate', { static: true }) avatarChromeTemplate: TemplateRef<any>;
  @ViewChild('contentContainerTemplate', { static: true }) contentContainerTemplate: TemplateRef<any>;
  @ViewChild('contactNameTemplate', { static: true }) contactNameTemplate: TemplateRef<any>;
  @ViewChild('contactInfoTemplate', { static: true }) contactInfoTemplate: TemplateRef<any>;
  @ViewChild('controlsContainerTemplate', { static: true }) controlsContainerTemplate: TemplateRef<any>;
  @ViewChild('controlsTemplate', { static: true }) controlsTemplate: TemplateRef<any>;

  @ViewChild('scroller', {read: VirtualSectionScrollerComponent})
  scrollerComponent: VirtualSectionScrollerComponent;

  subscriptions:{[key:string]:Subscription} = {};
  subscribedEntities: ContactEntity[];
  entitiesSubscription: Subscription;

  private static counter: number = 0;
  protected logger:Logger = new Logger('ContactListComponent.'+ContactListComponent.counter++);

  // arrow function is used (instead of a method) to have "this"
  // initialized with an instance of the class inside the function body.
  // If we call a method from an event handler, the method no longer has
  // its this bound to an instance of the class.
  trackContact = (index: number, contact: ContactEntity) => {
    //console.log("index:"+index+" contact",contact);
    const trackedContact = this.safeContact.transform(contact);
    return trackedContact.id + '.' + trackedContact.version;
  };

  constructor(@Inject(ENVIRONMENT) protected environment: any,
              public contactService: ContactService,
              protected safeContact: SafeContactPipe,
              protected propertiesService: PropertiesService,
              protected zone: NgZone,
              protected changeDetector: ChangeDetectorRef) {
    //this.logger.debug("ctor()");
  }

  ngOnInit() {
    this.entitiesSubscription = this.entities$.subscribe(entities => {
      this.logger.debug('Entities', entities?.length);
    })
    this.entitiesSubscription.add(() => this.subscribed(undefined));
    this.contactListItemTemplateRef   = this.contactListItemTemplateRef ?? this.contactListItemTemplate;
    this.deletedListItemTemplateRef   = this.deletedListItemTemplateRef ?? this.deletedListItemTemplate;
    this.avatarContainerTemplateRef   = this.avatarContainerTemplateRef ?? this.avatarContainerTemplate;
    this.avatarTemplateRef            = this.avatarTemplateRef ?? this.avatarTemplate;
    this.avatarChromeTemplateRef      = this.avatarChromeTemplateRef ?? this.avatarChromeTemplate;
    this.contentContainerTemplateRef  = this.contentContainerTemplateRef ?? this.contentContainerTemplate;
    this.contactNameTemplateRef       = this.contactNameTemplateRef ?? this.contactNameTemplate;
    this.contactInfoTemplateRef       = this.contactInfoTemplateRef ?? this.contactInfoTemplate;
    this.controlsContainerTemplateRef = this.controlsContainerTemplateRef ?? this.controlsContainerTemplate;
    this.controlsTemplateRef          = this.controlsTemplateRef ?? this.controlsTemplate;
  }

  ngOnDestroy() {
    this.logger.debug("ngOnDestroy()");
    // this.subscribed(undefined);
    this.entitiesSubscription.unsubscribe();
  }

  select(index: number, contact: Contact, event: Event) {
    //console.log("index",index,"contact",contact);
    this.selectionChanged.emit({ index: index, contact: contact });
    // this.changeDetector.markForCheck();
    // this.changeDetector.detectChanges();
  }

  // safeContact(contact: ContactEntity): ContactReference {
  //   this.logger.info("SAFE", contact, contact instanceof Contact);
  //   return contact instanceof Contact ? contact : (contact?.contact || this.nullContact);
  //   // return contact as Contact || this.nullContact;
  // }

  // trackContact(index: number, contact: Contact): string {
  //   console.log("index:"+index+" contact",contact);
  //   return contact.id;
  // }

  onResize(event:ResizeEvent) {
    if (this.scrollerComponent) {
      this.scrollerComponent.refresh();
    }
  }

  getAvatarUrl(contact:Contact): string {
    return `${this.environment.serverUrl}/v1.0/content/avatar/${contact.version || 0}/${contact.id}.jpg`;
  }

  dropForwardCallback(entity: ContactEntity):() => Promise<void> {
    return () => this.contactService.chatHook?.connect(this.safeContact.transform(entity).id)
  }

  viewportEntities(entities: ContactEntity[]): ContactEntity[] {
    return this.subscribe ? this.subscribed(entities) : entities;
  }

  subscribed(entities: ContactEntity[]): ContactEntity[] {
    if (this.subscribedEntities!==entities) {
      this.subscribedEntities = entities;
      const subscriptions:{[key:string]:Subscription} = {};
      entities?.forEach(entity => {
        const contactReference = this.safeContact.transform(entity);
        if (!!contactReference?.id) {
          subscriptions[contactReference.id] =
                this.subscriptions[contactReference.id] ??
                this.contactService.getContact$(
                  contactReference.id,
                  new Contact(contactReference)
                ).subscribe();
        }
      });
      Object.keys(this.subscriptions).forEach(contactId=>{
        if (!subscriptions[contactId]) {
          this.subscriptions[contactId].unsubscribe();
        }
      });
      this.subscriptions = subscriptions;
    }
    return entities;
  }
}
