/* projects/payment/src/lib/stripe/stripe.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
mat-card {
  min-width: 310px;
  text-align: center;
  padding: 0;
  overflow: hidden;
  position: relative;
}
mat-card mat-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  margin-bottom: 16px;
  padding: 10px 0;
}
mat-card mat-card-header mat-card-title,
mat-card mat-card-header .title {
  margin-top: 8px;
  margin-bottom: 8px;
  color: white;
}
mat-card mat-card-header mat-icon {
  font-size: 120px;
  width: 120px;
  height: 120px;
  color: var(--color-primary-300);
}
mat-card mat-card-content {
  position: relative;
  margin: 0 !important;
  padding: 8px !important;
  height: auto;
  transition: height 0.25s ease-in-out;
}
mat-card mat-card-content > div {
  display: flex;
  flex-direction: column !important;
}
mat-card form {
  width: 100%;
  flex: 1;
}
mat-card form,
mat-card form > [formgroupname] {
  display: flex;
  flex-direction: column;
}
mat-card form > div:first-child {
  margin-bottom: 10px;
}
mat-card form div.location {
  flex: 1;
  display: flex;
  flex-direction: row;
}
mat-card form div.location mat-form-field {
  margin-right: 8px;
  max-width: 120px;
}
mat-card form div.location mat-form-field:last-child {
  margin-right: 0;
  flex: 1;
  max-width: inherit;
}
mat-card form > :not([formgroupname]):not(mat-form-field) mat-error {
  text-align: initial;
  font-size: 0.8em;
}
mat-card form > mat-error {
  font-size: 0.9em;
  margin-bottom: 10px;
}
.page {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  padding: 8px;
  min-height: 160px;
}
.page.payment-method > div:first-of-type {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.page.payment-method > div:first-of-type button svg {
  width: 40px;
  height: 30px;
  fill: white;
}
.page.payment-method > div:first-of-type button span:first-of-type {
  margin-left: 8px;
}
.page.payment-completed > div:first-of-type {
  position: relative;
  flex: 1;
}
.page.payment-completed > div:nth-of-type(2) {
  justify-self: flex-end;
  align-self: flex-end;
  margin: 12px 12px 0 0;
}
.page.payment-completed > div:nth-of-type(2) mat-icon {
  vertical-align: text-bottom;
  margin-right: 8px;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: var(--color-primary);
}
.page.payment-completed > div:nth-of-type(2) a {
  font-size: 0.8em;
  font-style: italic;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
.page.payment-completed > div:nth-of-type(2):hover mat-icon {
  color: var(--color-primary-300);
}
.page.payment-completed > div:nth-of-type(2):hover a {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: underline;
}
.processing {
  color: var(--color-accent);
  padding-top: 5px;
}
.sepa-mandate {
  font-size: 0.8em;
  line-height: 1.2em;
  font-style: italic;
  text-align: justify;
  color: #777;
  padding: 10px 3px 0;
  position: relative;
}
.sepa-mandate:before {
  content: "*";
  display: inline-block;
  vertical-align: baseline;
  margin-right: 3px;
  font-size: 1.15em;
  color: var(--color-accent);
}
/*# sourceMappingURL=stripe.component-UUYYKVXB.css.map */
