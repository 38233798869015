<topic-selection-list #topicSelectionList
                      [topics$]="interests$"
                      [readOnly]="readOnly"
                      [topicDisplayInfo]="topicDisplayInfo"
                      (selectionChanged)="selectionChanged.emit($event)">
  <ng-template #actionsTemplate let-topic="topic">
    <ng-container *ngTemplateOutlet="actionsTemplateRef; context: { topic: topic, tags: selectedTags }"></ng-container>
  </ng-template>
  <ng-template #badgeTemplate let-topic="topic">
    <ng-container *ngTemplateOutlet="badgeTemplateRef; context: { topic: topic, tags: selectedTags }"></ng-container>
  </ng-template>
</topic-selection-list>
<ng-template #surveyTemplate let-topic="topic" let-selectedTags="selectedTags">
  <app-survey [survey]="topic" [selectedTags]="selectedTags" [readOnly]="readOnly"></app-survey>
</ng-template>
