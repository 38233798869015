<div *ngLet="position as position"
  class="positioned-container"
  [class]="position"
  [class.before]="space?.before"
  [class.after]="space?.after">

  <div class="name" *ngIf="!!space?.name">{{space.name}}</div>
  <chat-message-container
    [pressTriggerTime]="1000"
    (onContextMenu)="onTapMessage($event)"
    [manualMenuTriggerFor]="contextMenu"
    #contextMenuTrigger="manualMenuTrigger"
    [message]="message"
    [index]="index"
    [header]="header"
    [parent]="parent"
    [space]="space">
  </chat-message-container>

  <!--
  <div class="attachments" style="position:absolute;left:0;bottom:0;white-space:nowrap;">
    <div *ngFor="let attachment of message?.attachments; let index=index;"
      style="position:relative;display:inline-block;width:20px;height:20px;margin-right:2px;background-color:orange;"
      (click)="onPlayMediaNext(attachment,index)"></div>
  </div>
  <div class="footer">
    <img matListItemAvatar [src]="photo | sessionLink"/>
    < !-- <span id="name">{{ message.contact.firstname }}</span> -- >
    <span>{{ message.from?.name }}</span>
  </div>
  -->

  <mat-menu #contextMenu="matMenu" class="message-context-menu"
            backdropClass="strong-blur-backdrop" overlapTrigger="false">
    <div class="emojiContainer" (click)="$event.stopPropagation();$event.preventDefault();">
      <div #emojiHost class="primary"></div>
    </div>
    <!-- Row of Emoji Buttons -->
    <div class="menuContainer">
      <!--
      <div class="emoji-buttons-container">
        <button mat-icon-button (click)="selectEmoji($event,'😊')">😊</button>
        <button mat-icon-button (click)="selectEmoji($event,'😂')">😂</button>
        <button mat-icon-button (click)="selectEmoji($event,'❤️')">❤️</button>
        <button mat-icon-button (click)="selectEmoji($event,'👍')">👍</button>
        <button mat-icon-button (click)="selectEmoji($event,'😢')">😢</button>
        <button class="more" mat-icon-button (click)="openEmojiSelector($event)">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </div>
      -->
      <button mat-menu-item (click)="chatService.setReplyMessage(message.conversationId,message)">
        <mat-icon>reply</mat-icon><span translate>actions.reply</span>
      </button>
      <button *ngIf="mine && !blockEdit" mat-menu-item (click)="chatService.setDraftMessage(message.conversationId,message)">
        <mat-icon>edit</mat-icon><span translate>actions.edit</span>
      </button>
      <button *ngIf="hasBody" mat-menu-item (click)="copyBody()">
        <mat-icon>content_copy</mat-icon><span translate>actions.copy</span>
      </button>
      <!--
      <button mat-menu-item>
        <mat-icon class="flip">reply</mat-icon><span translate>actions.forward</span>
      </button>
      <button *ngIf="self" mat-menu-item (click)="openMessageDeliveryInfo(message)">
        <mat-icon>info</mat-icon><span translate>actions.infos</span>
      </button>
      <button mat-menu-item (click)="openMessageDeliveryInfo(message)">
        <mat-icon>info</mat-icon><span translate>actions.infos</span>
      </button>
      -->
      <button mat-menu-item (click)="openMessageReplyInfos(message)">
        <mat-icon>info</mat-icon><span translate>actions.infos</span>
      </button>
      <mat-divider *ngIf="mine"></mat-divider>
      <button *ngIf="mine" mat-menu-item (click)="onTapDelete($event)" [class.delete]="delete">
        <mat-icon>delete</mat-icon><span translate>actions.delete</span>
      </button>
    </div>
  </mat-menu>
</div>

<ng-template #messageTemplate></ng-template>
