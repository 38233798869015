import {Sound} from "shared";

export class ConferenceSounds {
  protected initialized = false;

  constructor() {}

  public async initialize(): Promise<void> {
    if (!this.initialized) {
      // console.log('ConferenceSounds - INITIALIZING');
      this.initialized = true;
      return Promise.all([
        this.conferenceSound().initialize(),
        this.conferenceRing().initialize(),
        this.conferenceFree().initialize(),
        this.conferenceBusy().initialize(),
        this.conferenceEnd().initialize(),
      ]).then((result) => {
        // console.log('ConferenceSounds - INITIALIZED', {
        //   conferenceSound: this.conferenceSound(),
        //   conferenceRing: this.conferenceRing(),
        //   conferenceFree: this.conferenceFree(),
        //   conferenceBusy: this.conferenceBusy(),
        //   conferenceEnd: this.conferenceEnd()
        // });
        return void result;
      });
    }
    return Promise.resolve();
  }

  protected _conferenceSound: Sound;
  protected conferenceSound(): Sound {
    return (this._conferenceSound =
      this._conferenceSound ??
      new Sound("/assets/sounds/initialize.mp3", false));
  }
  conferenceAudio(): HTMLAudioElement {
    return this.conferenceSound().audio;
  }

  protected _conferenceRing: Sound;
  conferenceRing(): Sound {
    // console.log('SOUNDS.conferenceRing - CURRENT', this._conferenceRing);
    // if (!this._conferenceRing) {
    //   this._conferenceRing = new Sound("/assets/sounds/conference_incoming.mp3",true);
    // }
    // console.log('SOUNDS.conferenceRing - RESULT', this._conferenceRing);
    // return this._conferenceRing;
    return (this._conferenceRing =
      this._conferenceRing ??
      new Sound("/assets/sounds/conference_incoming.mp3", true));
  }

  protected _conferenceFree: Sound;
  conferenceFree(): Sound {
    return (this._conferenceFree =
      this._conferenceFree ??
      new Sound("/assets/sounds/conference_free.mp3", true));
  }

  protected _conferenceBusy: Sound;
  conferenceBusy(): Sound {
    return (this._conferenceBusy =
      this._conferenceBusy ??
      new Sound("/assets/sounds/conference_busy.mp3", true));
  }

  protected _conferenceEnd: Sound;
  conferenceEnd(): Sound {
    return (this._conferenceEnd =
      this._conferenceEnd ??
      new Sound("/assets/sounds/conference_end.mp3", false));
  }
}
