import {Injectable} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef, MatBottomSheetConfig} from "@angular/material/bottom-sheet";

import {ConferencePanelComponent} from "./conference-panel.component";

// https://stackblitz.com/edit/custom-overlay-step-5

export interface ConferencePanelOverlayConfig extends MatBottomSheetConfig {
  panelClass?: string;
  data?: any;
}

@Injectable(
  // root injector cannot provide instances of certain types
  // (e.g. SafeContactPipe used by contact list in ConferencePanelComponent)
  { providedIn: "root" }
)
export class ConferencePanelOverlayService {

  constructor(private bottomSheet: MatBottomSheet) {
    // console.debug('ConferencePanelOverlayService.ctor');
  }

  open(config: ConferencePanelOverlayConfig = {
    panelClass: 'full-size',
    backdropClass: 'strong-blur-backdrop'
  }): MatBottomSheetRef<ConferencePanelComponent> {
    // you can access the CallPanelComponent by the returned reference...
    return this.bottomSheet.open(ConferencePanelComponent, config);
  }
}
