import {Action, createAction, props} from "@ngrx/store";
import {Calendar} from "../../models/calendar";

export enum CalendarActionIds {
  LOAD_REQUEST       = 'calendar.load.request',
  LOAD_PAGE          = 'calendar.load.page',
  LOAD_PAGE_SUCCESS  = 'calendar.load.page.success',
  LOAD_PAGE_FAILURE  = 'calendar.load.page.failure',
  UPDATE_FILTER      = 'calendar.update.filter',
  UPDATE_SEARCH_TERM = 'calendar.update.searchTerm',
  UPDATE             = 'calendar.update',
  UPDATE_SUCCESS     = 'calendar.update.success',
  UPDATE_FAILURE     = 'calendar.update.failure',
  DELETE             = 'calendar.delete',
  DELETE_SUCCESS     = 'calendar.delete.success',
  DELETE_FAILURE     = 'calendar.delete.failure',
  SUBSCRIBE          = 'calendar.subscribe',
  UNSUBSCRIBE        = 'calendar.unsubscribe'
}

export const calendarLoadRequestAction = createAction(CalendarActionIds.LOAD_REQUEST);
export const calendarLoadPageAction = createAction(
  CalendarActionIds.LOAD_PAGE,
  props<{
    cacheId : string,
    index   : number,
    size    : number
  }>()
);
export const calendarLoadPageSuccessAction = createAction(
  CalendarActionIds.LOAD_PAGE_SUCCESS,
  props<{
    cacheId  : string,
    index    : number,
    data     : Calendar[],
    total    : number,
    dispatch : (action: Action) => void
  }>()
);
export const calendarLoadPageFailureAction = createAction(CalendarActionIds.LOAD_PAGE_FAILURE, props<{ cacheId: string, index: number, size: number }>());
export const calendarUpdateFilterAction = createAction(CalendarActionIds.UPDATE_FILTER, props<{ filters: string[] }>());
export const calendarUpdateSearchTermAction = createAction(CalendarActionIds.UPDATE_SEARCH_TERM, props<{ term: string }>());

export class CalendarUpdateAction implements Action {
  readonly type = CalendarActionIds.UPDATE;
  constructor(public calendar: Calendar,
              public previous?: Calendar,
              public correlationId?: string) {
    if (!this.correlationId) {
      this.correlationId = this.calendar.id;
    }
  }
}

// export const calendarUpdateAction = createAction(CalendarActionIds.UPDATE, props<{ calendar: Calendar, previous: Calendar, correlationId: string  }>());
export const calendarUpdateSuccessAction = createAction(CalendarActionIds.UPDATE_SUCCESS, props<{ calendar: Calendar, correlationId?: string }>());
export const calendarUpdateFailureAction = createAction(CalendarActionIds.UPDATE_FAILURE, props<{ calendar: Calendar, reason: any, correlationId: string }>());
export const calendarDeleteAction = createAction(CalendarActionIds.DELETE, props<{ calendar: Calendar }>());
export const calendarDeleteSuccessAction = createAction(CalendarActionIds.DELETE_SUCCESS, props<{ calendar: Calendar }>());
export const calendarDeleteFailureAction = createAction(CalendarActionIds.DELETE_FAILURE, props<{ calendar: Calendar, reason: any }>());

export const calendarSubscribeAction = createAction(CalendarActionIds.SUBSCRIBE, props<{ id: string, calendar?: Calendar }>());
export const calendarUnsubscribeAction = createAction(CalendarActionIds.UNSUBSCRIBE, props<{ id: string, calendar?: Calendar }>());
