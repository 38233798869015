import {Message, Participant} from "messaging";

/**
 * PREREQUISIT FOR THE CONFERENCE:
 * ChatTimelineMessages are sorted by timeCreated only.
 *
 * ChatConversationsState and
 * ChatConversationState ....
 *      both are filterable (and searchable). if a filter is applied to ChatConversationsState
 *      all ChatConversationStates get unfiltered and if any conversation is viewed, that global
 *      filter is then applied.
 *      if a filter is applied directly to a conversation, the global filter gets wiped out.
 */

export interface AvatarName {
  name: string;
  version: number;
}

/*
    CONFERENCE RELATED
 */

/**
 * Base class for all phone/video conference control messages....
 */
export interface ConferenceRelatedMessage extends Message {
}

export interface ConferenceControlMessage extends ConferenceRelatedMessage {
  // conversationId could be specified
  conferenceId: string;   // unique conference id!
  version: number;        // version number updated on each manipulation...
}

export const SCREEN_TRACK : string = "screen";  // screen sharing track url (media server) or track id (webRTC)
export const AUDIO_TRACK : string = "audio";    // audio url (media server) or track id (webRTC)
export const VIDEO_TRACK : string = "video";    // large audio/video track url (media server) or track id (webRTC)
export const THUMBNAIL_TRACK : string = "thumbnail"; // small audio/video track url (media server) ... maybe later also for webRTC
export interface ConferenceParticipant extends Participant {
  timeAdded?: number;     // since when is the participant added to the conference...
  timeSince?: number;     // since when is the participant in that state...
  conferenceType?: ConferenceType;    // conference type of this participant: either audio or video
  connectionId?: string;  // connectionId operating this participant
  muted?:  boolean;       // in case there is an audio stream, it can mute (disabled)
  audio?:  boolean;       // audio stream available
  video?:  boolean;       // video stream available
  screen?: boolean;       // screen sharing stream available
  unmuted?: boolean;      // unmuted by admin/owner -> can now have audio and screen share
  tracks?: {[key:string]:string}; // all tracks
  // ui only data:
  uid?: string;           // unique id, id+':'+'s'/'v' (screen/video)
}

export type ConferenceType = 'audio'|'video';
export interface ConferenceInfo {
  timeCreated?: number;               // when was this conference created?
  conferenceType: ConferenceType;     // initial conference type. can be overridden by any participant
  conferenceId: string;               // unique conference id!
  version: number;                    // version of the conference (increased by each incoming manipulation message)
  conversationId?: string;            // is the conference attachd to a chat conversation?
  conversationName?: string;          // name of conversation (can be direct chat partner name)
  server?: boolean;                   // is this conference using the media server?
  muted?: boolean;                    // whole conversation muted and screen sharing not allowed for all non admins / non owners
  ringing?:  ConferenceParticipant[]; // those invited, where server was able to send them conference info...
  paused?:   ConferenceParticipant[];
  pickedUp?: ConferenceParticipant[];
  // client only data:
  active:    boolean;                 // picked up and not paused (participant is within pickedUp)
  participant: ConferenceParticipant; // current user
}

export const ConferenceInviteMessageType : string = "conferenceInvite";
export interface ConferenceInviteMessage extends ConferenceControlMessage {
  from:           ConferenceParticipant;
  conferenceType: ConferenceType;
  invited:        ConferenceParticipant[];
  server?:        boolean;            // is this conference using the media server?
}

export const ConferenceStateMessageType : string = "conference";
export interface ConferenceStateMessage extends ConferenceInfo, ConferenceControlMessage {
}

export const ConferencePickUpMessageType : string = "conferencePickUp";
export interface ConferencePickUpMessage extends ConferenceControlMessage {
  from: ConferenceParticipant;
}

export const ConferenceMediaMessageType : string = "conferenceMedia";
export interface ConferenceMediaMessage extends ConferenceControlMessage {
  from: ConferenceParticipant;
}

export const ConferencePauseMessageType : string = "conferencePause";
export interface ConferencePauseMessage extends ConferenceControlMessage {
  from: ConferenceParticipant;
}

export const ConferenceHangUpMessageType : string = "conferenceHangUp";
export interface ConferenceHangUpMessage extends ConferenceControlMessage {
  from: ConferenceParticipant;
}

export const ConferenceDataMessageType : string = "conferenceData";
export interface ConferenceDataMessage extends ConferenceControlMessage {
  from: ConferenceParticipant;
  to:   ConferenceParticipant;
  data: {
    offer?: RTCSessionDescriptionInit,
    answer?: RTCSessionDescription,
    candidate?: RTCIceCandidate,
    iceCandidates?: RTCIceCandidate[]
  }
}

export interface ConferenceVersion {
  conferenceId:string;
  version:number;
}

export const ConferenceSynchronizeStatesMessageType : string = "conferenceSynchronizeStates";
export interface ConferenceSynchronizeStatesMessage extends ConferenceControlMessage {
  conferences:ConferenceVersion[];
}
