import {Injectable} from '@angular/core';
import {SimpleFilterService, SearchService} from "core";
import {Store} from "@ngrx/store";
import {BehaviorSubject, Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MetricsService implements SearchService, SimpleFilterService {

  protected filtersSubject$ = new BehaviorSubject<string[]>([]);

  constructor(protected store$: Store<any>) {
  }

  updateFilter(filters: string[]): void {
    this.filtersSubject$.next(filters)
  }

  get filters$(): Observable<string[]> {
    return this.filtersSubject$;
  }

  updateSearchTerm(term: string): void {
  }

  get searchTerm$(): Observable<string> {
    return of("");
  }
}
