import {Message} from "messaging";
import {Presence} from "core"

export enum PresenceState {
  loggedOut="loggedOut",        // the application is opened (e.g. in the browser) and server connection is established, but not logged in...
  loggedIn="loggedIn",          // the user is logged in
  absent="absent",              // user status: not online....
  unavailable="unavailable",    // user status: do not disturb
  invisible="invisible"         // user status: do not show actual status to other contacts
}

// export interface Presence {
//   contactId: string;
//   state: PresenceState;
//   timeSince: number;
//   timeActive: number;           // time last active (comes from session update)....
//   timeDevice?: number;          // time last active on device
//   timeBrowser?: number;         // time last active on browser
//   devices?:number;              // number of logged in devices
//   pushDevices?:number;          // number of logged in devices with push notification
//   sessions?:number;             // number of total non expired sessions (browser and devices)
// }

export interface SubscribedPresence {
  subscribed: number;  // how many have subscribed
  presence: Presence;
}

/**
 * Base class for all messages regarding presence
 */
export interface PresenceRelatedMessage extends Message {
}

/*
    STATUS RELATED
 */

export const PresenceMessageType : string = "presence";
export interface PresenceMessage extends PresenceRelatedMessage {
  presence : Presence;
}

export const PresenceSubscriptionMessageType : string = "presence_subscription";
export interface PresenceSubscriptionMessage extends PresenceRelatedMessage {
  contactIds: string[]  // all contact ids for which we want to be notified...
}
