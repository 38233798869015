/* projects/launcher/src/lib/components/bootstrap-retry/bootstrap-retry.component.scss */
.message {
  width: 100%;
  word-break: break-word;
  margin: 0;
  padding: 0;
}
.message p:first-child {
  text-align: center;
}
/*# sourceMappingURL=bootstrap-retry.component-4G6LJRNE.css.map */
