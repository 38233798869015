import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subscription, timer} from "rxjs";
import {Contact, ENVIRONMENT, Logger, Topic} from "core";
import {DateAdapter} from "@angular/material/core";
import {Task, TaskTarget} from "../../models/task";
import moment from "moment";
import {Media, MediaService, SurveyLink} from "media";
import {map} from "rxjs/operators";
import {InterestsService, PersonalityColors, VitalityTypes} from "interests";
import cloneDeep from "lodash/cloneDeep";
import {PropertiesService} from "properties";

@Component({
  selector: 'app-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskListItemComponent implements OnInit, OnChanges {

  @Input() index: number;
  @Input() task: Task;
  @HostBinding('class.dense')
  @Input() dense = false;
  @Output() select = new EventEmitter<{index: number, task: Task, target?: TaskTarget}>();

  @ViewChild('targetContainer', { read: ViewContainerRef, static: true }) targetContainer: ViewContainerRef

  year$ = new BehaviorSubject<number>(undefined);
  overdue$ = new BehaviorSubject(undefined);
  taskTargets: TaskTarget[] = [];
  surveyResults: {[mediaId: string]: [Topic, PersonalityColors | VitalityTypes, string?]}; // [survey, result, className]
  protected timerSubscription: Subscription;

  logger: Logger = new Logger('TaskListItemComponent');

  constructor(protected dateAdapter: DateAdapter<moment.Moment>,
              public mediaService: MediaService,
              public interestsService: InterestsService,
              protected propertiesService: PropertiesService,
              protected changeDetector: ChangeDetectorRef,
              @Inject(ENVIRONMENT) public environment: any) {
  }

  ngOnInit(): void {
    this.timerSubscription = timer(0,5000) // probe current year every 5 seconds
      .subscribe((period) => {
        const now = new Date();
        this.year$.next(now.getFullYear());
        if (this.task?.timeDeadline) {
          this.overdue$.next(this.task.timeDeadline <= now.getTime())
        } else if (this.overdue$.getValue()!=undefined) {
          this.overdue$.next(undefined);
        }
      });
  }

  public ngAfterViewInit(): void {
    // const media = this.taskTargets.find(target => target.type=='media')?.media;
    // if (media) {
    //   const link = media.links?.[0];
    //   if (link?.type=='survey') {
    //     const surveyLink: SurveyLink = link as SurveyLink;
    //     const type = PersonalityColorsComponent;
    //     const result = this.interestsService.personalityResult(surveyLink.survey, surveyLink.selectedTags, 'personality.survey');
    //     const componentRef = this.targetContainer.createComponent(type);
    //     componentRef.instance.colors = result.colors;
    //     // const type = surveyResult([PersonalityResultComponent]);
    //     // componentRef.instance.survey = link.survey;
    //     // componentRef.instance.selectedTags = link.selectedTags;
    //     // componentRef.instance.displayTag = 'personality.survey';
    //   }
    // }
  }

  ngOnDestroy() {
    this.timerSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const taskChange = changes['task'];
    const task: Task = taskChange?.currentValue || this.task;
    this.surveyResults = {};
    const targets = ['media', 'contact'];
    this.taskTargets = (task?.targets || [])
      .map(target => {
        if (target.type=='media') {
          if (target.media?.mediaType=='survey') {
            let survey = target.media;
            // if survey link does not contain selected tags
            // lookup for interestTags in properties
            const link = survey.links?.[0] as SurveyLink;
            if (link.survey && !link.selectedTags) {
              survey = cloneDeep(survey);
              let clonedLink = survey.links[0];
              const interestTags = this.propertiesService.user.interestTags || [];
              const surveyTags = interestTags
                .map(tag => tag?.startsWith('interest.') ? tag.substring('interest.'.length) : tag)
                .reduce((surveyTags, interestTag) => {
                  if (interestTag?.startsWith(`${link.survey.id}`) &&
                    !surveyTags.includes(interestTag)) {
                    surveyTags.push(interestTag);
                  }
                  return surveyTags;
                }, []);
              survey.links[0] = {
                ...clonedLink,
                selectedTags: surveyTags
              };
              target = new TaskTarget('media', survey);
              const surveyId = link.survey.id;
              if (surveyId=='personality') {
                const result = this.interestsService.personalityResult(link.survey, surveyTags, 'personality.survey');
                if (result?.colors) {
                  const className = ((colors: PersonalityColors) => {
                    return Object.entries(colors)
                      ?.reduce((max, [color, value]) => {
                        if (!max || max[1]<value) max = [color, value];
                        return max;
                      }, undefined as [string, number])?.[0];
                  })(result?.colors);
                  this.surveyResults[survey.id] = [link.survey, result.colors, className];
                }
              } else if (surveyId=='vitality') {
                const result =  this.interestsService.vitalityResult(link.survey, surveyTags, 'vitality.survey');
                this.surveyResults[survey.id] = [link.survey, result.vitality];
              }
            }
          }
        }
        return target;
      })
      .sort((t1, t2) => {
        const index1 = targets.indexOf(t1.type);
        const index2 = targets.indexOf(t2.type);
        return index1 > index2 ? 1 : -1;
      });
  }

  yearMonthDayTime(time: number): {year: string, month: string, day: number, time: string} {
    if (time) {
      const date = new Date(time);
      const m: moment.Moment = moment(date);
      return {
        year  : this.dateAdapter.getYearName(m),
        month : this.dateAdapter.getMonthNames('short')[date.getMonth()],
        day   : this.dateAdapter.getDate(m),
        time  : this.dateAdapter.format(m, 'hh:mm A')
      };
    }
    return undefined;
  }

  media$(media: Media): Observable<Media> {
      // the task is not reloaded when target media changes (this behaviour could be changed in the future)
      // therefore if its media target is modified externally
      // we need to update the ui
      return combineLatest([
          this.mediaService.getMedia$(media?.id),
          of(media)
      ]).pipe(map(([m1, m2]) => m1 || m2));
  }

  photoUrl(contact: Contact): string {
    return contact ? `${this.environment.serverUrl}/v1.0/content/avatar/${contact.version || 0}/${contact.id}.jpg` : undefined;
  }
}
