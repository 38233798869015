<div [class]="type" [matMenuTriggerFor]="messageTypeMenu" [innerHTML]="type=='chat' ? '▲' : 'chat.message.type.'+type | translate"></div>
<mat-menu #messageTypeMenu="matMenu" yPosition="above">
  <button mat-menu-item (click)="value='chat'">
    <mat-icon>message</mat-icon>
    <span translate>chat.message.type.chat</span>
  </button>
  <button mat-menu-item (click)="value='note'">
    <mat-icon>sticky_note_2</mat-icon>
    <div>
      <div translate>chat.message.type.note</div>
      <div class="remark" translate>chat.message.private</div>
    </div>
  </button>
</mat-menu>
