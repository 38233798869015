import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Calendar, NULL_CALENDAR, NullCalendar} from "../../models/calendar";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PropertiesService} from "properties";
import {TranslateService} from "@ngx-translate/core";
import {BasicContainerComponent, Country, FormChangeDetector, Language, LanguageSelectorComponent} from "shared";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {map, shareReplay, switchMap, takeUntil} from "rxjs/operators";
import {ENVIRONMENT, Logger, Platform, Topic} from "core";
import moment from "moment";
import isEqual from "lodash/isEqual";
import values from "lodash/values";
import mapValues from "lodash/mapValues";
import {EventService} from "../../services/event.service";
import {FilterTagEvent} from "filter";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import {Color, ColorAdapter, NgxMatColorPickerComponent} from "@angular-material-components/color-picker";

type State = {
  valid: boolean;
  dirty: boolean;
}

type Panel = 'main' | 'languages';

@Component({
  selector: 'app-calendar-detail',
  templateUrl: './calendar-detail.component.html',
  styleUrls: ['./calendar-detail.component.scss']
})
export class CalendarDetailComponent extends BasicContainerComponent {

  @ViewChild(LanguageSelectorComponent) languageSelector: LanguageSelectorComponent;
  @ContentChild(TemplateRef, { static: true }) controlsTemplate: TemplateRef<any>;
  @ViewChildren('scroller', { read: ElementRef }) scrollers: QueryList<ElementRef>;

  @Input() editMode  = false;
  @Input() autoFocus = false;

  @Input() set languageLru(languageLru: Observable<string[]>) {
    this.logger.debug('languageLru', languageLru);
    this.languageLruSubscription?.unsubscribe();
    this.languageLruSubscription = languageLru
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(languageLru => {
          this.logger.debug('languageLru', languageLru);
          this.languageLru$.next(languageLru);
        })
  };

  @Input() set languageSearchTerm(languageSearchTerm: Observable<string>) {
    this.languageSearchTermSubscription?.unsubscribe();
    this.languageSearchTermSubscription = languageSearchTerm
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(term => {
          this.logger.debug('languageSearchTerm.term', term);
          this.onLanguageSearchTermChange(term);
        });
  };

  @Output() state = new BehaviorSubject<State>({ valid: undefined, dirty: undefined });
  @Output() onPanelChange = new EventEmitter<Panel>();
  @Output() onLanguageLruChange = new EventEmitter<string[]>();

  countries$: Observable<Country[]>;
  calendarForm: FormGroup;

  languages$: Observable<Language[]>;
  languageLru$ = new BehaviorSubject<string[]>(undefined);
  languageSearchTerm$ = new BehaviorSubject<string>(undefined);
  protected languageLruSubscription: Subscription;
  protected languageSearchTermSubscription: Subscription;

  translationsSelector: (code: string) => Observable<string>;

  protected _panel: Panel = 'main';
  protected _calendar: Calendar;
  protected formChangeSubscription: Subscription;

  protected languageSelectorContext: { trigger: AbstractControl, multi?: boolean };

  @Input() typesTopic: Topic;

  protected logger = new Logger('CalendarDetailComponent');

  constructor(protected formBuilder: FormBuilder,
              protected formChangeDetector: FormChangeDetector,
              protected propertiesService: PropertiesService,
              public translateService: TranslateService,
              public eventService: EventService,
              protected colorAdapter: ColorAdapter,
              public platform: Platform,
              @Inject(NULL_CALENDAR)
              protected nullCalendar: NullCalendar,
              @Inject(ENVIRONMENT) public environment: any) {
    super();
    this.calendarForm   = this.createCalendarForm();
  }

  ngOnInit(): void {
    super.ngOnInit();
    const languages$ = this.translateService.stream('languages').pipe(shareReplay(1));
    this.languages$ = languages$.pipe(map(result => {
      const language = this.translateService.currentLang;
      return values(mapValues(result, (value, key) => { return {code: key, name: value } }))
        .sort((l1, l2) => l1.name.localeCompare(l2.name, language));
    }));
    this.translationsSelector = (code: string) => languages$.pipe(map(languages => languages[code]));

    this.calendarForm.statusChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() =>
            this.state.next({ ...this.state.getValue(), valid: this.calendarForm.valid })
        );

    this.countries$ = this.translateService
      .stream('countries')
      .pipe(
        map((result) => {
          const language = this.translateService.currentLang;
          return values(mapValues(result, (value, key) => { return {code: key, name: value } }))
                .sort((c1,c2) => c1.name.localeCompare(c2.name,language));
        })
      );

    // keep languageCode and languageName in sync
    this.calendarForm.get('languageCode').valueChanges.pipe(
        takeUntil(this.onDestroy$),
        switchMap(change => languages$)
    ).subscribe(languages => {
      const code = this.calendarForm.get('languageCode').value;
      const name = languages[code];
      if (this.calendarForm.get('languageName').value != name) {
        this.updateFormState();
        this.calendarForm.patchValue({'languageName': name});
      }
    });

    // translations are updated imperatively using FormControl.setValue() api
    // which does not produce FormGroup.valueChanges emission
    // => change detector cannot react on this => we need to update dirty state manually
    this.calendarForm.get('translations').valueChanges.pipe(
        takeUntil(this.onDestroy$),
        switchMap(change => languages$)
    ).subscribe(languages => this.updateFormState());

    this.calendarForm.valueChanges.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(languages => this.updateFormState());

    /*
    this.typeFilter = {
      id:'type',
      label:'events.calendar.type.label',
      topics: [
        { id: 'private', label: 'events.calendar.type.private'},
        { id: 'team', label: 'events.calendar.type.team' },
        { id: 'public', label: 'events.calendar.type.public' }
      ]
    };
     */
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formChangeSubscription?.unsubscribe();
  }

  @Input()
  set calendar(calendar: Calendar) {
    if (!this.formChangeSubscription) {
      this.formChangeSubscription = this.formChangeDetector.detect(this.calendarForm)
        .subscribe((pristine: boolean) => {
          this.logger.debug('PRISTINE', pristine);
          this.state.next({ ...this.state.getValue(), dirty: !pristine });
        });
    }
    calendar = new Calendar({...this.nullCalendar, ...calendar});
    const timeCreated = this.timeCreated(calendar);
    const value: any = {
      name:        calendar.name,
      author:      calendar.author.name,
      info:        calendar.info,
      timeCreated: timeCreated,
      languageCode: calendar.language || this.translateService.currentLang,
      translations: calendar.translations ? [...calendar.translations] : [],
      countryCodes: calendar.countryCodes ? [...calendar.countryCodes] : [],
      countryCodesExcluded: !!calendar.countryCodesExcluded,
      editorsTerm : calendar.editorsTerm,
      viewersTerm : calendar.viewersTerm,
      eventsTerm  : calendar.eventsTerm,
      type        : calendar.type
    };
    if (calendar.color) {
      try {
        value.color = this.colorAdapter.parse(calendar.color);
      } catch (e) {
        this.logger.warn(`Invalid task list color: ${calendar.color}`);
      }
    }
    if (!value.color) {
      value.color = this.colorAdapter.parse('#fff0');
    }
    const promise: Promise<void> = value.languageCode
      ? this.translateService.get('languages')
        .toPromise()
        .then(languages => value.languageName = languages[value.languageCode])
      : Promise.resolve();
    promise.then(() => {
      this.calendarForm.reset(value);
      this.updateFormState()
    });
    if (!isEqual(this._calendar, calendar)) {
      this._calendar = calendar;
    }
  }

  protected createCalendarForm(): FormGroup {
    const now = moment();
    return this.formBuilder.group({
      name:          [ '', Validators.required ],
      author:        [ '' ],
      info:          [ '' ],
      timeCreated  : [ { value: now, disabled: false }, Validators.required ],
      languageName : [ { value: '', disabled: true } ], // NOTE: values of disabled controls are not included in form value -> use rawValue instead
      languageCode : [ '', Validators.required ],
      translations:  [],
      countryCodes : [],
      countryCodesExcluded: [ false ],
      editorsTerm     : [],
      viewersTerm     : [],
      eventsTerm      : [],
      type            : [ 'private' ],
      color           : [ this.colorAdapter.parse('#fff0') ]
    });
  }

  get calendar(): Calendar {
    return this._calendar;
  }

  get current(): Calendar {
    const calendar: Calendar = new Calendar({...this._calendar});
    calendar.name = this.calendarForm.get('name').value;
    calendar.info = this.calendarForm.get('info').value;
    calendar.language  = this.calendarForm.get('languageCode').value;
    calendar.countryCodes = this.calendarForm.get('countryCodes').value;
    const countryCodesExcludedControl = this.calendarForm.get('countryCodesExcluded');
    if (countryCodesExcludedControl.touched &&
      countryCodesExcludedControl.dirty) {
      calendar.countryCodesExcluded = countryCodesExcludedControl.value;
    }
    calendar.editorsTerm = this.calendarForm.get('editorsTerm').value;
    calendar.viewersTerm = this.calendarForm.get('viewersTerm').value;
    calendar.eventsTerm   = this.calendarForm.get('eventsTerm').value;
    calendar.type = this.calendarForm.value.type;
    calendar.color = this.calendarForm.get('color').value?.toHex8(true);
    return calendar;
  }

  set panel(panel: Panel) {
    if (this._panel!=panel) {
      this.onPanelChange.emit(this._panel = panel);
    }
  }

  get panel(): Panel {
    return this._panel;
  }

  timeCreated(calendar: Calendar) {
    const timeCreated = calendar ? calendar.timeCreated : null;
    if (timeCreated) {
      return new Date(timeCreated);
    }
    return new Date();
  }

  onLanguageSelect(trigger: AbstractControl, multi = false) {
    this.languageSelectorContext = { trigger: trigger, multi: multi };
    this.panel = 'languages';
    window.setTimeout(() => this.languageSelector.scrollToPosition(0));
  }

  onLanguageSearchTermChange(term: string) {
    this.languageSearchTerm$.next(term);
  }

  onLanguageSelectorSelectionChange(language: Language) {
    this.logger.debug('onLanguageSelected', language);
    this.panel='main';
    const trigger = this.languageSelectorContext?.trigger;
    if (trigger) {
      const code = language.code;
      const value = this.languageSelectorContext.multi
        ? [...trigger.value || [], code].filter((code, index, codes) => codes.indexOf(code) === index) // ensure codes are unique
        : code;
      trigger.setValue(value);
    }
  }

  onLanguageSelectorLruChange(languages: string[]) {
    this.logger.debug('onLanguageSelectorLruChange', languages);
    this.onLanguageLruChange.emit(languages);
  }

  onTapTypeFilter(event: FilterTagEvent) {
    const type = event.filter.id;
    const value = this.calendarForm.getRawValue();
    this.calendarForm.setValue({...value, type});
    this.updateFormState();
  }

  openColorPicker(colorPicker: NgxMatColorPickerComponent) {
    if (!colorPicker.opened) {
      colorPicker.open();
    }
  }

  scrollToStart() {
    const element = this.scrollers.first?.nativeElement;
    if (element) {
      element.scrollTop = 0;
    }
  }

  scrollToEnd() {
    const element = this.scrollers.first?.nativeElement;
    if (element) {
      element.scrollTop = Math.max(0, element.scrollHeight - element.clientHeight);
    }
  }

  protected updateFormState() {
    // lodash isEqual compares also object types not just plain json
    // therefore we need to construct a new calendar instance using the output of omitBy
    const pristine = isEqual(this.calendar, new Calendar(omitBy(this.current, isNil)));
    if (this.calendarForm.pristine && !pristine) {
      this.calendarForm.markAsDirty();
    } else if (!this.calendarForm.pristine && pristine) {
      this.calendarForm.markAsPristine();
    }
  }
}
