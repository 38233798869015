/* projects/payment/src/lib/stripe/stripe-overlay.component.scss */
app-stripe {
  display: block;
  height: 100%;
  overflow: auto;
}
.result {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 8px;
}
.result > div {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.result > div > mat-icon {
  color: darkseagreen;
  margin-right: 8px;
  vertical-align: middle;
}
.result > div > mat-icon + span {
  font-size: 1.1em;
  vertical-align: middle;
}
.result > button {
  align-self: flex-end;
  width: 100%;
}
button.mat-mdc-icon-button {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
}
/*# sourceMappingURL=stripe-overlay.component-D2BMUAEZ.css.map */
