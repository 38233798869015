<mat-toolbar color="primary" fxLayout="row">
  <div class="from">
    <div class="source-name">{{message?.from?.name}}</div>
    <div class="source-date" *ngIf="!!message?.timeCreated">{{message?.timeCreated | date}}</div>
  </div>
  <button mat-icon-button (click)="layoutService.details.toggle()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div class="source-message">
  <!-- cannot find the bug. this is not updated when a attachment within the
       thread is selected....
    <div>{{message.id}} att:{{message.attachments?.length}} body:{{message.bodyText}}</div>
  -->
  <chat-message-container [message]="message" [mode]="'header'" [parent]="true"></chat-message-container>
</div>
<slide-panel [id]="view" (onSlide)="onPanelSlide($event)">
  <!--
  <div *slidePanelLeft="'delivery'" class="panel">
    YES
  </div>
  -->
  <chat-conversation *slidePanelRightLazy="'replies'" class="panel"
    [conversationId]="message?.conversationId"
    [parentId]="message?.id"
    [startFromTop]="true"
    [searchTerm]="searchTeam"></chat-conversation>
</slide-panel>
<!--
<app-status-bar>
  <div (click)="onShowDeliveryState()" [class.selected]="view=='delivery'">
    <mat-icon>done_all</mat-icon>
    <span translate>chat.message.delivery</span>
  </div>
  <div (click)="onShowReplies()" [class.selected]="view=='replies'">
    <mat-icon>reply</mat-icon>
    <span translate>chat.message.allReplies</span>
  </div>
</app-status-bar>
-->
