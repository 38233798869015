<ng-container [ngSwitch]="editMode">
  <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="view"></ng-container>
  <ng-container *ngSwitchCase="true"  [ngTemplateOutlet]="edit"></ng-container>
</ng-container>

<ng-template #view>
  <div #scroller *ngIf="calendar" class="view">
    <mat-form-field *ngIf="calendar.name" class="no-disabled-color">
      <!--
        ATTENTION: autofocus=true moves the focused element into view (at least on chrome)
        This is a problem when the element is in the dom but is not supposed to be visible e.g.
        when using app-slide-panel.
      -->
      <mat-label translate>events.calendar.name</mat-label>
      <input type="text"
             unmodified-input
             matInput
             [value]="calendar.name"
             [autoFocus]="false"
             disabled/>
    </mat-form-field>
    <mat-form-field *ngIf="calendar.info" class="no-disabled-color">
      <mat-label translate>events.calendar.info</mat-label>
      <textarea matInput
                unmodified-input
                [value]="calendar.info"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8"
                disabled>
      </textarea>
    </mat-form-field>
    <mat-form-field  class="no-disabled-color">
      <mat-label translate>events.calendar.dateCreated</mat-label>
      <input type="text"
             unmodified-input
             matInput
             [value]="timeCreated(calendar) | date"
             disabled/>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #edit>
  <app-slide-panel [panel]="panel=='main' ? 'left' : 'right'" class="edit">
    <form #scroller left [formGroup]="calendarForm" class="panel calendar">
      <mat-form-field>
        <mat-label translate>events.calendar.name</mat-label>
        <input type="text"
               unmodified-input
               matInput
               formControlName="name"
               name="name"
               [autoFocus]="autoFocus"
               required/>
        <mat-error *ngIf="calendarForm.controls.name.invalid && calendarForm.controls.name.touched">
          <span translate>events.calendar.validation.nameRequired</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label translate>events.calendar.info</mat-label>
        <!--<mat-icon matPrefix>theaters</mat-icon>-->
        <textarea matInput
                  unmodified-input
                  formControlName="info"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="8">
        </textarea>
      </mat-form-field>
      <mat-form-field (click)="onLanguageSelect(calendarForm.controls.languageCode)" class="language selector">
        <mat-icon matPrefix>language</mat-icon>
        <mat-label translate>events.calendar.language</mat-label>
        <input matInput
               type="text"
               unmodified-input
               required
               formControlName="languageName"
               aria-label="language"/>
        <mat-icon matSuffix>arrow_right</mat-icon>
      </mat-form-field>
      <mat-form-field class="hidden">
        <input matInput type="text" formControlName="languageCode"/>
      </mat-form-field>
      <!--
      <div class="section translations">
        <h4 class="header" matSubheader disabled translate>events.calendar.translations</h4>
        <app-multi-select formControlName="translations"
                          [selector]="translationsSelector"
                          (onAdd)="onLanguageSelect(calendarForm.controls.translations, true)">
        </app-multi-select>
      </div>
      -->
      <div class="section countries">
        <h4 class="header" matSubheader disabled translate>events.calendar.countries</h4>
        <mat-checkbox formControlName="countryCodesExcluded" aria-label="excluded countries">
          <span translate>actions.filter.exclude</span>
        </mat-checkbox>
        <app-country-select formControlName="countryCodes" [countries]="countries$"></app-country-select>
      </div>
      <mat-form-field *ngFor="let term of ['editors', 'viewers', 'events']">
        <mat-icon matPrefix>{{term=='editors' ? 'edit' : term=='viewers' ? 'preview' : 'event'}}</mat-icon>
        <mat-label translate>{{'events.terms.'+term}}</mat-label>
        <input type="text"
               unmodified-input
               matInput
               [name]="term+'Term'"
               [formControlName]="term+'Term'"
               [attr.aria-label]="term+' term'"/>
      </mat-form-field>
      <filter-tag-section [filter]="typesTopic"
                          [selected]="calendarForm.value['type']"
                          (onFilter)="onTapTypeFilter($event)"
                          class="section">
      </filter-tag-section>
      <mat-form-field class="selector" (click)="openColorPicker(colorPicker)">
        <div class="colorPrefix" [style.backgroundColor]="calendarForm.controls.color.value?.toHexString()" matPrefix></div>
        <input matInput [ngxMatColorPicker]="colorPicker"
               formControlName="color"
               disabled>
        <ngx-mat-color-toggle matSuffix [for]="colorPicker">
          <mat-icon ngxMatColorpickerToggleIcon>keyboard_arrow_down</mat-icon>
        </ngx-mat-color-toggle>
        <ngx-mat-color-picker #colorPicker
                              [touchUi]="platform.is('mobile')"
                              [color]="'primary'">
        </ngx-mat-color-picker>
        <mat-error *ngIf="calendarForm.controls.color.invalid && calendarForm.controls.color.touched">
          <span translate>tasks.validation.colorInvalid</span>
        </mat-error>
      </mat-form-field>
      <ng-container *ngTemplateOutlet="controlsTemplate; context: { calendar: current }"></ng-container>
    </form>
    <div right class="panel languages">
      <app-language-selector #languageSelector
                             [languages$]="languages$"
                             [lru$]="languageLru$"
                             [searchTerm$]="languageSearchTerm$"
                             [currentLanguage]="translateService.currentLang"
                             (selectionChange)="onLanguageSelectorSelectionChange($event)"
                             (lruChange)="onLanguageSelectorLruChange($event)">
      </app-language-selector>
      <button mat-raised-button
              color="primary"
              type="button"
              (click)="panel='main'">
        <span translate>actions.cancel</span>
      </button>
    </div>
  </app-slide-panel>
</ng-template>

<!-- test -->
<!--<calendar *ngIf="!environment.production" [entities$]="eventService.entities$"></calendar>-->
