import {Contact, HasContact, VersionedId} from "core";
import {Seats} from "./seats";
import {Message} from "messaging";

export class Attendee extends HasContact {
  readonly id: string;
  readonly version: string;
  eventId: string;
  author: Partial<Contact>;
  speaker: boolean;
  seats: Seats;
  tags: string[];
  properties: any;
  timeCreated: number;
  timeUpdated: number;

  constructor(init?: Partial<Attendee>) {
    super();
    Object.assign(this, init, { seats: Seats.merge(init?.seats)});
  }

  get isNew(): boolean {
    return !this.id;
  }
}

export interface AttendeeRelatedMessage extends Message {
}

export const AttendeeSubscriptionMessageType : string = "calendar.event.attendee.subscription";
export interface AttendeeSubscriptionMessage extends AttendeeRelatedMessage {
  subscribed: VersionedId[];
}

export const AttendeeUpdateMessageType : string = "calendar.event.attendee.update";
export interface AttendeeUpdateMessage extends AttendeeRelatedMessage {
  attendee: Attendee;
}
