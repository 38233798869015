/* projects/interests/src/lib/components/survey/result/personality/personality.colors.component.scss */
:host {
  width: 1em;
  height: 1em;
  line-height: inherit;
}
:host > div.chart {
  width: 1em;
  height: 1em;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}
:host > div.chart > div {
  flex: 1;
  position: relative;
}
:host > div.chart > div > div {
  display: block;
  position: absolute;
  border: 0.01em solid transparent;
  box-sizing: border-box;
}
:host > div.chart > div > div > div {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: width 300ms, height 300ms;
}
:host > div.chart > div > div.red {
  right: 50%;
  bottom: 50%;
}
:host > div.chart > div > div.red > div {
  right: 0;
  bottom: 0;
  border-bottom-right-radius: 2%;
  background-color: red;
}
:host > div.chart > div > div.yellow {
  left: 50%;
  bottom: 50%;
}
:host > div.chart > div > div.yellow > div {
  left: 0;
  bottom: 0;
  border-bottom-left-radius: 2%;
  background-color: #FEDD2C;
}
:host > div.chart > div > div.green {
  left: 50%;
  top: 50%;
}
:host > div.chart > div > div.green > div {
  left: 0;
  top: 0;
  border-top-left-radius: 2%;
  background-color: #A5DB2E;
}
:host > div.chart > div > div.blue {
  right: 50%;
  top: 50%;
}
:host > div.chart > div > div.blue > div {
  right: 0;
  top: 0;
  border-top-right-radius: 2%;
  background-color: #0a84ff;
}
/*# sourceMappingURL=personality.colors.component-CIVNCVUO.css.map */
