import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  signal,
  TemplateRef
} from '@angular/core';
import {BasicContainerComponent, ResizeEvent, VirtualScrollEvent} from "shared";
import {asapScheduler, BehaviorSubject, Observable, Subscription} from "rxjs";
import {CalendarEvent, NULL_CALENDAR_EVENT} from "../../models/event";
import {Logger} from "core";
import {MaterialService} from "material";
import {takeUntil} from "rxjs/operators";
import moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {DateAdapter} from "@angular/material/core";
import {EventService} from "../../services/event.service";
import {Calendar} from "../../models/calendar";
import {CalendarService} from "../../services/calendar.service";

// TODO:
// event map -> on click open dialog and after confirmation display 'create event' form with calendar select / create options - dialogs could be annoying
// event list -> add fab + button to  add events which opens the same form 'create event'
// create event form -> currently selected language and relevant calendars should be pre-filled
// (can we change calendar for existing events? can we have recurring events?)
// create calendar form -> should be implemented
// main sidenav:
//  -> language selection: opens separate view with languages most recently selected on top)
//  -> timeframe selection: week, month, quarter, year, all (dropdown list) + forward/backward toggle/checkbox,
//                          last selected forward timeframe option should be stored and pre-filed on next visit
//                          (backward selection is not persisted).
//  Events displayed in the map and in the list are always filtered with respect to the language and timeframe filters
//  The currently selected timeframe should be visible in the main view:
//  maybe hide during map panning, but how to display it when event list is visible?
//
//  -> calendar list should contain only calendars which are in the selected language

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventListComponent extends BasicContainerComponent {
  // @Input() entities$: Observable<CalendarEvent[]>;
  // @Input() sections$: Observable<number[]>;

  protected _entities$ = new BehaviorSubject<CalendarEvent[]>([]);
  protected _sections$ = new BehaviorSubject<number[]>([]);
  protected entitiesSubscription: Subscription;
  protected sectionsSubscription: Subscription;

  entitiesId$ = new BehaviorSubject<string>('0');

  eventSubscriptions: {[key:string]:Subscription} = {};
  subscribedEvents: CalendarEvent[];

  calendarSubscriptions: {[key:string]:Subscription} = {};
  subscribedCalendars = signal<{ [eventId: string]: Calendar }>({});

  @Input()
  set entities$(entities: Observable<CalendarEvent[]>) {
    this.entitiesSubscription?.unsubscribe();
    this.entitiesSubscription = entities
      ?.pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this.logger.debug('entities', value);
        this._entities$.next(value);
      });
  }

  get entities$(): Observable<CalendarEvent[]> {
    return this._entities$;
  }

  @Input() cacheId$: Observable<string>;

  @Input()
  set sections$(sections: Observable<number[]>) {
    this.sectionsSubscription?.unsubscribe();
    this.sectionsSubscription = sections
      ?.pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        // this.logger.debug('SECTIONS', value);
        this._sections$.next(value);
      });
  }

  get sections$(): Observable<number[]> {
    return this._sections$;
  }

  @Output() selectionChanged = new EventEmitter<{ index: number; event: CalendarEvent; }>();
  @ContentChild(TemplateRef, { static: true }) controlsTemplate: TemplateRef<any>;
  @HostBinding('class.dense') dense = false;

  itemHeaderElementAccessor = (element: Element): Element => {
    return element ? element.firstElementChild : undefined;
  };

  public trackEvent = (index: number, event: CalendarEvent) => {
    return this.safeCalendarEvent(event);
  };

  protected logger = new Logger('EventListComponent');

  constructor(protected eventService: EventService,
              protected calendarService: CalendarService,
              public materialService: MaterialService,
              protected translateService: TranslateService,
              protected dateAdapter: DateAdapter<moment.Moment>,
              @Inject(NULL_CALENDAR_EVENT) protected nullCalendarEvent: CalendarEvent) {
    super();
    this.cacheId$ = this.eventService.cacheId$;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.entitiesSubscription?.unsubscribe();
    this.sectionsSubscription?.unsubscribe();
    this.subscribed(undefined);
  }

  onScrolled(event: VirtualScrollEvent) {

  }

  onResize(event: ResizeEvent) {
    super.onResize(event);
    this.dense = event.currentSize.width < 400;
  }

  select(index: number, calendarEvent: CalendarEvent, event: Event) {
    // this.logger.debug('select', calendarEvent);
    this.selectionChanged.emit({ index: index, event: calendarEvent });
  }

  color(index: number) {
    // if we put the call to MaterialService.color() directly into the template
    // the options are no passed to the method for indexes 20 - 24.
    // it is currently unclear what is the reason for this issue
    // as a workaround the MaterialService.color() method call is done in ts.
    const options = { fallback: index + 10 };
    return this.materialService.color(index, options);
  }

  monthYear(time: number) {
    const date = new Date(time);
    if (window && window.Intl && window.Intl.DateTimeFormat) {
      const options: Intl.DateTimeFormatOptions = {
        // day: '2-digit',
        // weekday: 'short',
        month: 'long',
        year: 'numeric'
      };
      const locales = [this.translateService.currentLang, 'en-US'];
      const supportedLocales = Intl.DateTimeFormat.supportedLocalesOf(locales, options);
      if (supportedLocales.length) {
        return new Intl.DateTimeFormat(locales, options).format(date);
      }
    }
    const mnt = moment(date);
    const ymd = this.dateAdapter.format(mnt, 'll');
    const day = this.dateAdapter.format(mnt, 'D');
    return ymd.replace(new RegExp('[^\.]?' + day + '.?'), '')
  }

  safeCalendarEvent(event: CalendarEvent): CalendarEvent {
    return event || this.nullCalendarEvent;
  }

  subscribed(events: CalendarEvent[]): CalendarEvent[] {
      if (this.subscribedEvents!==events) {
        this.subscribedEvents = events;
        const subscriptions: {[key:string]: Subscription} = {};
        const calendarSubscriptions: {[key:string]: Subscription} = {};
        events?.forEach(event => {
          if (!!event?.id) {
            subscriptions[event.id] =
                  this.eventSubscriptions[event.id] ??
                  this.eventService.getCalendarEvent$(event.id,event).subscribe();
            const calendarId = event.calendar?.id;
            if (calendarId) {
              calendarSubscriptions[calendarId] =
                this.calendarSubscriptions[calendarId] ??
                this.calendarService.getCalendar$(calendarId)
                  .subscribe((calendar) => {
                    this.logger.debug('CALENDAR UPDATE', calendar);
                    const subscribedCalendars = this.subscribedCalendars();
                    subscribedCalendars[event.id] = calendar;
                    asapScheduler.schedule(() => {
                      this.subscribedCalendars.set(subscribedCalendars);
                      // this.changeDetector.detectChanges();
                    });
                  });
            }
          }
        });
        Object.keys(this.eventSubscriptions).forEach(eventId=>{
          if (!subscriptions[eventId]) {
            this.eventSubscriptions[eventId].unsubscribe();
          }
        });
        this.eventSubscriptions = subscriptions;

        Object.keys(this.calendarSubscriptions).forEach(calendarId=>{
          if (!calendarSubscriptions[calendarId]) {
            this.calendarSubscriptions[calendarId].unsubscribe();
          }
        });
        this.calendarSubscriptions = calendarSubscriptions;
      }
      return events;
  }
}
