import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import {NULL_TASK_LIST, NullTaskList, TaskList} from "../../models/task-list";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PropertiesService} from "properties";
import {TranslateService} from "@ngx-translate/core";
import {BasicContainerComponent, FormChangeDetector} from "shared";
import {BehaviorSubject, Subscription} from "rxjs";
import {map, takeUntil} from "rxjs/operators";
import {ENVIRONMENT, Logger, Platform, Topic} from "core";
import moment from "moment";
import {FilterTagEvent} from "filter";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import {Color, ColorAdapter, NgxMatColorPickerComponent} from "@angular-material-components/color-picker";


type State = {
  valid: boolean;
  dirty: boolean;
}

type Panel = 'main' | 'languages';

@Component({
  selector: 'app-tasklist-detail',
  templateUrl: './tasklist-detail.component.html',
  styleUrls: ['./tasklist-detail.component.scss']
})
export class TaskListDetailComponent extends BasicContainerComponent {

  @ContentChild(TemplateRef, { static: true }) controlsTemplate: TemplateRef<any>;
  @ViewChildren('scroller', { read: ElementRef }) scrollers: QueryList<ElementRef>;

  @Input() editMode  = false;
  @Input() autoFocus = false;

  @Output() state = new BehaviorSubject<State>({ valid: undefined, dirty: undefined });
  @Output() onPanelChange = new EventEmitter<Panel>();

  taskListForm: FormGroup;

  protected _panel: Panel = 'main';
  protected _taskList: TaskList;
  protected _typesTopic: Topic;
  protected _displayedTypesTopic: Topic;
  protected formChangeSubscription: Subscription;
  protected initialized = false;

  protected logger = new Logger('TaskListDetailComponent');

  constructor(protected formBuilder: FormBuilder,
              protected formChangeDetector: FormChangeDetector,
              protected propertiesService: PropertiesService,
              public translateService: TranslateService,
              protected colorAdapter: ColorAdapter,
              public platform: Platform,
              @Inject(NULL_TASK_LIST) protected nullTaskList: NullTaskList,
              @Inject(ENVIRONMENT) public environment: any) {
    super();
    this.taskListForm   = this.createTaskListForm();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.taskListForm.statusChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() =>
            this.state.next({ ...this.state.getValue(), valid: this.taskListForm.valid })
        );

    this.taskListForm.valueChanges.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(languages => this.updateFormState());

    if (!this.formChangeSubscription) {
      this.formChangeSubscription = this.formChangeDetector.detect(this.taskListForm)
        .subscribe((pristine: boolean) => {
          this.logger.debug('PRISTINE', pristine);
          this.state.next({ ...this.state.getValue(), dirty: !pristine });
        });
    }
    this.update(this.taskList, this.typesTopic);
    this.initialized = true;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formChangeSubscription?.unsubscribe();
  }

  @Input()
  set taskList(taskList: TaskList) {
    if (this.initialized) {
      this.update(taskList, this.typesTopic);
    } else {
      this._taskList = taskList;
    }
  }

  get taskList(): TaskList {
    return this._taskList;
  }

  @Input()
  set typesTopic(typesTopic: Topic) {
    if (this.initialized) {
      this.update(this.taskList, typesTopic);
    } else {
      this._typesTopic = typesTopic;
    }
  }

  get typesTopic() {
    return this._typesTopic;
  }

  get displayedTypesTopic() {
    return this._displayedTypesTopic;
  }

  get current(): TaskList {
    const taskList: TaskList = new TaskList({...this._taskList});
    taskList.title = this.taskListForm.get('title').value;
    taskList.info = this.taskListForm.get('info').value;
    taskList.editorsTerm = this.taskListForm.get('editorsTerm').value || null;
    taskList.viewersTerm = this.taskListForm.get('viewersTerm').value || null;
    taskList.type = this.taskListForm.value.type;
    taskList.color = this.taskListForm.get('color').value?.toHex8(true);
    return taskList;
  }

  set panel(panel: Panel) {
    if (this._panel!=panel) {
      this.onPanelChange.emit(this._panel = panel);
    }
  }

  get panel(): Panel {
    return this._panel;
  }

  timeCreated(taskList: TaskList) {
    const timeCreated = taskList ? taskList.timeCreated : null;
    if (timeCreated) {
      return new Date(timeCreated);
    }
    return new Date();
  }

  onTapTypeFilter(event: FilterTagEvent) {
    const type = event.filter.id;
    const value = this.taskListForm.getRawValue();
    this.taskListForm.setValue({...value, type});
    this.updateFormState();
  }

  scrollToStart() {
    const element = this.scrollers.first?.nativeElement;
    if (element) {
      element.scrollTop = 0;
    }
  }

  scrollToEnd() {
    const element = this.scrollers.first?.nativeElement;
    if (element) {
      element.scrollTop = Math.max(0, element.scrollHeight - element.clientHeight);
    }
  }

  protected createTaskListForm(): FormGroup {
    const now = moment();
    return this.formBuilder.group({
      title       : [ '', Validators.required ],
      author      : [ '' ],
      info        : [ '' ],
      timeCreated : [ { value: now, disabled: false }, Validators.required ],
      editorsTerm : [],
      viewersTerm : [],
      type        : ['private'],
      color       : [this.colorAdapter.parse('#fff0')/*, Validators.pattern('^#([a-fA-F0-9]{8}|[a-fA-F0-9]{6}|[a-fA-F0-9]{3})$')*/]
    });
  }

  protected update(taskList: TaskList, typesTopic: Topic) {
    const taskListChange = !isEqual(this.taskList, taskList);
    if (!this.initialized || taskListChange) {
      this._taskList = new TaskList({...this.nullTaskList, ...taskList});
    }
    const typesTopicChange = !isEqual(this.typesTopic, typesTopic);
    if (!this.initialized || taskListChange || typesTopicChange) {
      this._typesTopic = typesTopic;
      // display only enabled types (topics) and the type of existing task list
      const topics = typesTopic
        ?.topics
        ?.filter(topic => topic?.enabled || this._taskList.type==topic?.id);
      this._displayedTypesTopic = {...typesTopic, topics};
    }
    const timeCreated = this.timeCreated(taskList);
    const value: any = {
      title:       this.taskList.title,
      author:      this.taskList.author?.name,
      info:        this.taskList.info,
      timeCreated: timeCreated,
      editorsTerm : this.taskList.editorsTerm,
      viewersTerm : this.taskList.viewersTerm,
      type        : this.taskList.type && !this.taskList.isNew
        ? this.taskList.type
        : this._displayedTypesTopic?.topics?.find(topic => topic.preselected)?.id,
      // color       : this.taskList.color ? this.colorAdapter.parse(this.taskList.color) : undefined
    };

    if (this.taskList.color) {
      try {
        value.color = this.colorAdapter.parse(this.taskList.color);
      } catch (e) {
        this.logger.warn(`Invalid task list color: ${this.taskList.color}`);
      }
    }
    if (!value.color) {
      value.color = this.colorAdapter.parse('#fff0');
    }
    this.taskListForm.reset(value);
    this.updateFormState();
  }

  protected updateFormState() {
    // lodash isEqual compares also object types not just plain json
    // therefore we need to construct a new taskList instance using the output of omitBy
    const pristine = isEqual(this.taskList, new TaskList(omitBy(this.current, isNil)));
    if (this.taskListForm.pristine && !pristine) {
      this.taskListForm.markAsDirty();
    } else if (!this.taskListForm.pristine && pristine) {
      this.taskListForm.markAsPristine();
    }
  }

  openColorPicker(colorPicker: NgxMatColorPickerComponent) {
    if (!colorPicker.opened) {
      colorPicker.open();
    }
  }
}
