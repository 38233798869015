import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordova } from '@ionic-native/core';
var CallNumber = /** @class */function (_super) {
  __extends(CallNumber, _super);
  function CallNumber() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  CallNumber.prototype.callNumber = function (numberToCall, bypassAppChooser) {
    return cordova(this, "callNumber", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  CallNumber.prototype.isCallSupported = function () {
    return cordova(this, "isCallSupported", {}, arguments);
  };
  CallNumber.pluginName = "CallNumber";
  CallNumber.plugin = "call-number";
  CallNumber.pluginRef = "plugins.CallNumber";
  CallNumber.repo = "https://github.com/Rohfosho/CordovaCallNumberPlugin";
  CallNumber.platforms = ["Android", "iOS"];
  CallNumber.decorators = [{
    type: Injectable
  }];
  return CallNumber;
}(IonicNativePlugin);
export { CallNumber };
