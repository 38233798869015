import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContactService } from '../../../contact.service';
import { ContactReachValues } from '../../../store/models';
import {Contact, Logger, prettyDateDiff, Topic, Presence} from 'core';
import { PropertiesService } from 'properties';
import {BehaviorSubject, combineLatest, Observable, of, Subscription, takeUntil, tap} from 'rxjs';
import { ContactDetailDataShareService } from '../contact-detail-data-share.service';
import { ContactDetailSectionComponent } from '../contact-detail-section/contact-detail-section.component';
import {TranslateService} from "@ngx-translate/core";
import {PresenceService} from "presence";

@Component({
  selector: 'contact-detail-reach',
  templateUrl: './contact-detail-reach.component.html',
  styleUrls: ['./contact-detail-reach.component.scss']
})
export class ContactDetailReachComponent extends ContactDetailSectionComponent {

  logger = new Logger('ContactDetailReachComponent');

  contact: Contact;

  presence$:BehaviorSubject<Presence>;
  presenceSubscribedId:string;
  presenceSubscription:Subscription;

  reachFilters: Topic[] = [];
  reachValues$ = new BehaviorSubject<ContactReachValues[]>([]);

  constructor(
    private dataShareService: ContactDetailDataShareService,
    private contactService: ContactService,
    private translateService: TranslateService,
    public presenceService: PresenceService,
    protected changeDetector: ChangeDetectorRef,
    public propertiesService: PropertiesService) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.dataShareService.getReachFilter$,
      this.dataShareService.getContact$
    ]).pipe(takeUntil(this.onDestroy$)).subscribe(([reachFilter, contact]) => {
      this.contact = contact;
      this.reachFilters = reachFilter;
      this.changeDetector.markForCheck();
    });
  }

  onReachFilter(event: Topic) {
    if (!!this.contact) {
      this.contactService.loadContactReach$(this.contact.id,[event.id])
        .then(values=>{
          this.reachValues$.next(values);
        })
        .catch(error=>this.logger.error("cannot load reach values",error));
    }
  }

  getPresence$(contactId:string, defaultPresence?: Presence):Observable<Presence> {
    this.presence$ = this.presence$ ?? new BehaviorSubject(defaultPresence);
    //console.log("DEFAULT.PRESENCE",defaultPresence,"contact",contactId);
    if (this.presenceSubscribedId!=contactId) {
      this.presenceSubscribedId = contactId;
      this.presenceSubscription?.unsubscribe();
      this.presenceSubscription = this.presenceService.getPresence$(contactId,defaultPresence)
        //.pipe(tap(presence=>console.log("UPDATED.PRESENCE",presence,"from",defaultPresence,"contact",contactId)))
        .subscribe(presence=>this.presence$.next(presence));
    }
    return this.presence$;
  }

  isActive(accessed: number, days: number): boolean {
    if (!!accessed) {
      const daymillis = 86400000 * days;
      if (accessed > (Date.now()-daymillis)) {  // inactive is at least 7 days not active
        return true;
      }
    }
    return false;
  }

  time(time: number): Observable<string> {
    if (time) {
      const [units, unitName] = prettyDateDiff(time, new Date());
      if (unitName=='second' || (unitName=='minute' && units<3)) {
        return this.translateService.get("chat.presence.online.now");
      } else if (units >= 0) {
        const key = units > 0 ? `moment.past.${unitName}${units>1 ? 's' : ''}` : 'moment.now';
        return this.translateService.get(key, {units: units});
      }
    }
    return of('');
  }
}
