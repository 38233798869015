<ng-container *ngIf="{selection: selection$ | async} as context">
  <app-contact-list [entities$]="entities$" [cacheId$]="cacheId$"
                    [avatarTemplateRef]="avatarTemplate"
                    [contactInfoTemplateRef]="contactInfoTemplate"
                    [controlsTemplateRef]="controlsTemplate"
                    [itemMinSize]="60" [avatarSize]="50" [dividerLeftMargin]="74"
                    (selectionChanged)="onSelectionChanged($event)">
    <ng-template #avatarTemplate
                 let-contact="contact"
                 let-src="src"
                 let-width="width"
                 let-height="height"
                 let-index="index"
                 let-total="total"
                 let-last="last">
      <!--img matListItemAvatar
           [callback]="callback(contact, index, last)"
           [src]="src"
           [style.width]="width"
           [style.height]="height"
           onload="this.style.opacity=1"/-->
      <img matListItemAvatar
           [src]="src"
           [style.width]="width"
           [style.height]="height"
           onload="this.style.opacity=1"/>
      <presence [contactId]="contact?.id"></presence>
    </ng-template>
    <ng-template #contactInfoTemplate
                 let-contact="contact"
                 let-index="index"
                 let-total="total"
                 let-last="last">
      <presence-info *ngIf="contact?.id" [contactId]="contact.id"></presence-info>
    </ng-template>
    <ng-template #controlsTemplate
                 let-contact="contact"
                 let-index="index"
                 let-total="total"
                 let-last="last">
      <mat-icon *ngIf="context.selection?.[contact.id]">check_circle</mat-icon>
    </ng-template>
  </app-contact-list>
</ng-container>
