<ng-container *ngLet="{count:filters.length} as filterSections">
    <div *ngIf="contact?.id!='techsupport' && canViewFilters && filterSections.count>0" class="filters">
        <ng-container *ngFor="let filter of filters">
            <filter-tag-section [filter]="filter"
                                [selected]="this.contactFilterTags"
                                [readOnly]="!canEditFilters"
                                [editableFilter]="editableFilter"
                                [visibleFilter]="displayFilter"
                                (onFilter)="onTapFilter($event)" class="section">
            </filter-tag-section>
        </ng-container>
        <button *ngIf="tagsDirty && filterSections.count>0" mat-mini-fab type="button" (click)="onSaveFilters()"
                aria-label="save filters">
            <mat-icon>done</mat-icon>
        </button>
    </div>
</ng-container>
