import {inject, InjectionToken} from '@angular/core';
import {AppUpdateMessage, AppUpdateMessageType, ENVIRONMENT} from "core";
import {MessagingService} from "messaging";
import {mergeWith, Observable, ReplaySubject} from "rxjs";
import {distinctUntilChanged, filter, map} from "rxjs/operators";
import {PropertiesService} from "properties";
import {AuthenticationRoutingGuard} from "auth";

export const APP_VERSION_UPDATE = new InjectionToken<Observable<string>>('appVersionUpdate', {
  providedIn: 'root',
  factory: () => {
    const messagingService: MessagingService = inject(MessagingService);
    const propertiesService: PropertiesService = inject(PropertiesService);
    const environment: any = inject(ENVIRONMENT);
    const version$ = new ReplaySubject<string>(1);
    messagingService
      .register(envelope => envelope.message?.type==AppUpdateMessageType)
      .pipe(
        map((envelope) => (<AppUpdateMessage>envelope.message).version),
        mergeWith(propertiesService.app$.pipe(map(app => app?.storeVersion))),
        filter(storeVersion => !!storeVersion),
        distinctUntilChanged(),
        filter(version => version!=environment.version)
      ).subscribe(version$);
    return version$;
  }
});

export const APP_DEFAULT_PATH_PROVIDER = new InjectionToken<(ignoreStoredUrl: boolean) => string>('appDefaultPathProvider', {
  providedIn: 'root',
  factory: () => {
    const authenticationRoutingGuard: AuthenticationRoutingGuard = inject(AuthenticationRoutingGuard);
    return authenticationRoutingGuard.getDefaultUrl;
  }
});
