/* projects/chat/src/lib/components/message-editor/chat-message-text-editor.component.scss */
:host {
  display: block;
}
:host mat-form-field {
  width: 100%;
}
::ng-deep .mat-mdc-form-field.chat-message-text-editor {
  margin-bottom: -25px;
}
::ng-deep .mat-mdc-form-field.chat-message-text-editor .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding-top: 10px !important;
  min-height: 30px !important;
}
::ng-deep .mat-mdc-form-field.chat-message-text-editor .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix textarea.mat-mdc-input-element {
  padding: 0;
  margin: 0;
}
/*# sourceMappingURL=chat-message-text-editor.component-Q5UKMZ3L.css.map */
