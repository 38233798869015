/* projects/presence/src/lib/components/presence/presence-menu.component.scss */
:host > div > div {
  cursor: pointer;
  color: inherit;
  font-weight: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
}
presence {
  position: relative;
  display: inline-block;
  margin-right: 8px;
}
button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
presence-indicator {
  margin-right: 8px;
}
/*# sourceMappingURL=presence-menu.component-NS3JNH3W.css.map */
