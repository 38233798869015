/* projects/conference/src/lib/components/conference-panel/conference-panel.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-primary-alpha-09);
}
:host:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2509803922);
}
.conversationName {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 0 16px 16px 16px;
  padding-top: max(16px, constant(safe-area-inset-top));
  padding-top: max(16px, env(safe-area-inset-top));
  color: white;
  font-size: 20px;
  font-weight: 500;
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
}
.conversationName > span {
  display: inline-block;
}
conference-participant {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.side {
  position: absolute;
  right: 16px;
  top: max(16px, constant(safe-area-inset-top));
  top: max(16px, env(safe-area-inset-top));
}
.side conference-participant {
  position: relative;
  width: 200px;
  height: 150px;
}
.grid {
  position: absolute;
  left: 16px;
  right: 16px;
  top: max(16px, constant(safe-area-inset-top));
  top: max(16px, env(safe-area-inset-top));
  bottom: max(16px, constant(safe-area-inset-bottom));
  bottom: max(16px, env(safe-area-inset-bottom));
  display: flex !important;
  flex-wrap: wrap;
  overflow: hidden;
}
.grid conference-participant {
  position: relative;
  width: 200px;
  height: 120px;
}
.controls {
  z-index: 10;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.controls > .controls_content {
  position: relative;
  transition: width 300ms, height 300ms;
  overflow: hidden;
}
.controls > .controls_content > conference-bar {
  position: relative;
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 16px 16px 8px 16px;
  display: block;
  width: 300px;
}
.controls > .controls_content.absolute > conference-bar {
  position: absolute;
}
.controls > .controls_content > conference-bar ::ng-deep > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  min-width: 60px;
}
.controls > .controls_content > conference-bar ::ng-deep > div > div > button {
  background-color: rgba(255, 255, 255, 0.1254901961);
  height: 44px;
  width: 44px;
  line-height: 44px;
}
.controls > .controls_content > conference-bar ::ng-deep > div > div > button mat-icon {
  height: 44px;
  width: 44px;
  line-height: 44px;
  font-size: 24px;
}
.controls > .controls_content > conference-bar ::ng-deep > div > div > button.mat-button-disabled,
.controls > .controls_content > conference-bar ::ng-deep > div > div > button.selected.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.062745098);
  color: var(--color-primary-300);
}
.controls > .controls_content > conference-bar ::ng-deep > div > div > button.selected {
  background-color: rgba(255, 255, 255, 0.6901960784);
  color: var(--color-primary-400);
}
.controls > .controls_content > conference-bar ::ng-deep > div > div > button.decline {
  background-color: var(--color-accent);
}
.controls > .controls_content > conference-bar ::ng-deep > div > div > button.accept {
  background-color: limegreen;
}
.controls > .controls_content > conference-bar ::ng-deep > div > div > span {
  display: block;
  font-size: 12.6px;
  font-weight: lighter;
}
.controls > .controls_content > .add_participant_container {
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 480px;
  max-height: 80vh;
  min-width: 300px;
  max-width: 320px;
  width: 90vw;
  display: flex;
  flex-direction: column;
}
.controls > .controls_content > .add_participant_container > app-contact-list {
  position: relative;
  min-height: 0;
  flex: 1;
  color: #333;
  background-color: white;
}
.controls > .controls_content > .add_participant_container > app-search-bar {
  position: relative;
}
.controls > .controls_content > .add_participant_container > app-search-bar ::ng-deep mat-toolbar.mat-primary {
  background-color: transparent;
}
.controls > .controls_content.absolute > .add_participant_container {
  position: absolute;
}
.controls .cancel_add_participant {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: white;
  cursor: pointer;
}
.controls > .controls_content > .video_input_container,
.controls > .controls_content > .audio_output_container {
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 150px;
  max-height: 80vh;
  min-width: 300px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
}
.controls > .controls_content > .video_input_container > .media_device_list,
.controls > .controls_content > .audio_output_container > .media_device_list {
  position: relative;
  min-height: 0;
  flex: 1;
  color: #333;
  background-color: white;
  overflow-y: scroll;
}
.controls > .controls_content > .video_input_container > .media_device_list .media_device,
.controls > .controls_content > .audio_output_container > .media_device_list .media_device {
  vertical-align: middle;
  line-height: 1em;
  padding: 10px;
  cursor: pointer;
}
.controls > .controls_content.absolute > .add_participant_container,
.controls > .controls_content.absolute > .video_input_container,
.controls > .controls_content.absolute > .audio_output_container {
  position: absolute;
}
.controls .cancel_add_participant {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: white;
  cursor: pointer;
}
.controls .close {
  display: flex;
  justify-content: center;
  line-height: 1em;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}
.controls .name {
  position: relative;
  margin-top: 12px;
  height: 1em;
  margin-bottom: 12px;
  line-height: 1em;
}
.controls .name > div {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
}
.controls .name > div > span > conference-participant-indicator {
  margin-right: 0.25em;
}
.controls.buttons .name {
  margin-bottom: -4px;
}
.conversationName,
.controls {
  background-color: var(--color-primary);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
:host-context(.color-scheme-light.pure) {
  background-color: rgba(238, 238, 238, 0.9411764706);
}
:host-context(.color-scheme-light.pure) .conversationName,
:host-context(.color-scheme-light.pure) .controls {
  background-color: white;
  color: #333;
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div {
  color: #333;
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div > button {
  color: var(--color-primary);
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.mat-button-disabled,
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.selected.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.062745098);
  color: var(--color-primary-300);
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.selected {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div > button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 22px;
  border: 1px solid #ddd;
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.decline {
  background-color: var(--color-accent);
  color: white;
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.decline::before {
  border-color: var(--color-accent);
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.accept {
  background-color: limegreen;
  color: white;
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.accept::before {
  border-color: limegreen;
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > .add_participant_container > app-contact-list {
  color: #333;
  background-color: white;
}
:host-context(.color-scheme-light.pure) .controls > .controls_content > .add_participant_container > app-search-bar ::ng-deep mat-toolbar.mat-primary {
  background-color: transparent;
}
:host-context(.color-scheme-light.pure) .controls .cancel_add_participant {
  color: white;
}
:host-context(.color-scheme-light.pure) .controls .close {
  background-color: rgba(0, 0, 0, 0.05);
  background-color: var(--color-primary);
  color: #333;
  color: var(--color-primaryContrast);
}
:host-context(.color-scheme-light.pure) .controls .name > div {
  color: #333;
}
:host-context(.color-scheme-dark) {
  background-color: #282828;
}
:host-context(.color-scheme-dark):before {
  background-color: unset;
}
:host-context(.color-scheme-dark) .conversationName,
:host-context(.color-scheme-dark) .controls {
  background-color: #383838;
  box-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.5);
}
:host-context(.color-scheme-dark) .conversationName > .controls_content > conference-bar ::ng-deep > div > div > button,
:host-context(.color-scheme-dark) .controls > .controls_content > conference-bar ::ng-deep > div > div > button {
}
:host-context(.color-scheme-dark) .conversationName > .controls_content > conference-bar ::ng-deep > div > div > button.mat-button-disabled,
:host-context(.color-scheme-dark) .conversationName > .controls_content > conference-bar ::ng-deep > div > div > button.selected.mat-button-disabled,
:host-context(.color-scheme-dark) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.mat-button-disabled,
:host-context(.color-scheme-dark) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.selected.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.062745098);
  color: rgba(174, 175, 176, 0.4392156863);
}
:host-context(.color-scheme-dark) .conversationName > .controls_content > conference-bar ::ng-deep > div > div > button.selected,
:host-context(.color-scheme-dark) .controls > .controls_content > conference-bar ::ng-deep > div > div > button.selected {
  background-color: rgba(255, 255, 255, 0.6901960784);
  color: rgba(0, 0, 0, 0.4392156863);
}
:host-context(.color-scheme-dark) .conversationName > .controls_content > .add_participant_container > app-contact-list,
:host-context(.color-scheme-dark) .controls > .controls_content > .add_participant_container > app-contact-list {
  background-color: transparent;
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .conversationName > .controls_content > .add_participant_container > app-search-bar ::ng-deep mat-toolbar.mat-primary,
:host-context(.color-scheme-dark) .controls > .controls_content > .add_participant_container > app-search-bar ::ng-deep mat-toolbar.mat-primary {
  background-color: transparent;
}
:host-context(.color-scheme-dark) .conversationName > .controls_content.absolute > .video_input_container > .media_device_list,
:host-context(.color-scheme-dark) .conversationName > .controls_content.absolute > .audio_output_container > .media_device_list,
:host-context(.color-scheme-dark) .controls > .controls_content.absolute > .video_input_container > .media_device_list,
:host-context(.color-scheme-dark) .controls > .controls_content.absolute > .audio_output_container > .media_device_list {
  background-color: transparent;
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .conversationName .cancel_add_participant,
:host-context(.color-scheme-dark) .controls .cancel_add_participant {
  color: #AEAFB0;
}
/*# sourceMappingURL=conference-panel.component-NYM7PJAH.css.map */
