import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-bootstrap-retry',
  templateUrl: './bootstrap-retry.component.html',
  styleUrls: ['./bootstrap-retry.component.scss']
})
export class BootstrapRetryComponent implements OnInit {

  onRetry = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<BootstrapRetryComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  retry(event: Event) {
    this.onRetry.emit();
  }
}
