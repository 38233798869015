import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {PropertiesService} from "properties";
import {ENVIRONMENT, Logger} from "core";
import {ChatService} from "../../chat.service";
import {
  ChatParticipationsAddMessageType,
  ChatParticipationsChangeMessageType, ChatParticipationsRemoveMessageType,
  ConversationData, TRANSLATE_TAG
} from "../../store/models";
import {participantMessageTypes} from "../../store/state";

@Component({
  selector: 'chat-conversation-item',
  templateUrl: './chat-conversation-item.component.html',
  styleUrls: ['./chat-conversation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationItemComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.trigger') trigger: boolean = false;

  public ACTION_TYPING = "typing";
  public ACTION_DRAFT = "draft";
  public ACTION_MESSAGE = "message";
  public ACTION_MESSAGE_ME = "message.me";
  public ACTION_TRANSLATE_MESSAGE = "translate.message";
  public ACTION_TRANSLATE_MESSAGE_ME = "translate.message.me";
  public ACTION_ATTACHMENT = "attachment";
  public ACTION_ATTACHMENT_ME = "attachment.me";
  public ACTION_PARTICIPANTS_ADDED = "participants.added";
  public ACTION_PARTICIPANTS_CHANGED = "participants.changed";
  public ACTION_PARTICIPANTS_REMOVED = "participants.removed";

  @Input()
  set conversation(conversationData:ConversationData) {
    //if (conversationData?.id=='direct.1679741802076687.3-TFNESWQHQDTKFXQYNJSUBEM6E') {
    //  console.log("XY.setConversation.lastMessage",conversationData?.lastMessage?.id,conversationData?.lastMessage?.timeUpdated,conversationData?.lastMessage,conversationData);
    //}
    this._conversation = conversationData;
  }
  get conversation():ConversationData {
    return this._conversation;
  }
  protected _conversation:ConversationData;

  @ContentChild('conversationInfoTemplate', { static: true })
  conversationInfoTemplate: TemplateRef<any>;

  logger: Logger = new Logger('ChatConversationItemComponent');

  //constructor(protected store$ : Store<State>, protected chatService : ChatService) {
  constructor(public propertiesService: PropertiesService,
              public chatService:ChatService,
              @Inject(ENVIRONMENT) protected environment: any) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let change = changes['conversation'];
    if (!!change) {
      if (change.currentValue?.id != change.previousValue?.id) {

      }
    }
  }

  get photoUrl(): string {
    return `${this.environment.serverUrl}/v1.0/content/avatar/${this.conversation.version || 0}/${this.conversation.id}.jpg`;
  }

  lastAction(conversation:ConversationData): string {
    //console.info("UPDATE",conversation);
    return (conversation?.typingMessages?.length ? conversation?.typingMessages[0] : undefined) ? this.ACTION_TYPING :
      conversation?.draftMessage?.bodyText?.length>0 || conversation?.draftMessage?.attachments?.length>0 || !!conversation?.draftMessage?._id ? this.ACTION_DRAFT :
      conversation?.lastMessage?.bodyText?.length>0 && conversation?.lastMessage?.tags?.includes(TRANSLATE_TAG) ? conversation.lastMessage.from?.id==this.chatService.userId ? this.ACTION_TRANSLATE_MESSAGE_ME : this.ACTION_TRANSLATE_MESSAGE :
      conversation?.lastMessage?.bodyText?.length>0 ? conversation.lastMessage.from?.id==this.chatService.userId ? this.ACTION_MESSAGE_ME : this.ACTION_MESSAGE :
      conversation?.lastMessage?.attachments?.length>0 ? conversation.lastMessage.from?.id==this.chatService.userId ? this.ACTION_ATTACHMENT_ME : this.ACTION_ATTACHMENT :
      participantMessageTypes.has(conversation?.lastMessage?.type) ?
        (conversation.lastMessage.type==ChatParticipationsAddMessageType    ? this.ACTION_PARTICIPANTS_ADDED :
         conversation.lastMessage.type==ChatParticipationsRemoveMessageType ? this.ACTION_PARTICIPANTS_REMOVED : this.ACTION_PARTICIPANTS_CHANGED) :
      undefined;
  }
}
