<ng-container *ngLet="propertiesService.properties$ | async as properties">
  <ng-container *ngIf="contact?.groups?.[properties?.group?.id]; let membership">
    <ng-container *ngTemplateOutlet="joinTemplate; context: {
      contact, membership, properties,
      referrerName:membership.net_referrer_name ?? membership.sync_referrer_name,
      joinDate:membership.manual_referrer_ymd ?? membership.sync_referrer_ymd ?? membership.ymd_started ?? contact.ymdCreated,
      hints:renderingHints}"></ng-container>
  </ng-container>
</ng-container>

<ng-template #joinTemplate let-contact="contact" let-membership="membership" let-properties="properties" let-referrerName="referrerName" let-joinDate="joinDate" let-hints="hints">
  <h4 class="join-info">
    <ng-container *ngTemplateOutlet="sinceTemplate; context: { joinDate, hints }"></ng-container>
    <ng-container *ngIf="contact.timeExpired">
      <span class='label'> - </span>
      <ng-container *ngTemplateOutlet="expirationTemplate; context: { timeExpired:contact.timeExpired,hints }"></ng-container>
    </ng-container>
    <ng-container *ngIf="!contact.timeExpired && membership.net_firstline_size && membership.net_team_size">
      <span class='label'> - </span>
      <ng-container *ngTemplateOutlet="teamTemplate; context: { firstlineSize:membership.net_firstline_size,teamSize:membership.net_team_size,hints }"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="referrerTemplate; context: { referrerName, hints }"></ng-container>
  </h4>
  <!--
  <h4 class="join-info" [innerHTML]="joinInfo | async | safeHtml"></h4>
  -->
</ng-template>

<ng-template #expirationTemplate let-timeExpired="timeExpired" let-hints="hints">
  <ng-container *ngLet="unitLabel$ | async as unitLabel">
    <ng-container *ngLet="units$ | async as units">
      <ng-container *ngLet="class$ | async as class">
        <span class='label {{class}}'>{{unitLabel | translate:{units} }}</span>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #teamTemplate let-firstlineSize="firstlineSize" let-teamSize="teamSize" let-hints="hints">
  <span class='label' translate>{{'contact.join.teamSize' | translate}} {{firstlineSize.toLocaleString()}}/{{teamSize.toLocaleString()}}</span>
</ng-template>

<ng-template #sinceTemplate let-joinDate="joinDate" let-hints="hints">
  <span class='label' translate>contact.join.date</span>&nbsp;<span>{{joinDate | ymdFormat}}</span>
</ng-template>

<ng-template #referrerTemplate let-referrerName="referrerName" let-hints="hints">
  <ng-container *ngIf="referrerName && !hints.noReferrer">
    <br/><span class='label'>{{'contact.join.by' | translate}} {{referrerName}}</span>
  </ng-container>
</ng-template>
