import {Component} from '@angular/core';
import {combineLatest, Observable, takeUntil, tap} from 'rxjs';
import {ContactDetailSectionComponent} from '../contact-detail-section/contact-detail-section.component';
import {Topic} from "core";

@Component({
  selector: 'contact-detail-interests',
  templateUrl: './contact-detail-interests.component.html',
  styleUrls: ['./contact-detail-interests.component.scss']
})
export class ContactDetailInterestsComponent extends ContactDetailSectionComponent {

  interests$: Observable<Topic[]>
  selectedTags: string[] = [];

  ngOnInit() {
    super.ngOnInit();
    this.interests$ = this.context?.interests$.pipe(tap((interests: Topic[]) => this.isHidden.emit(!interests?.length)));
    if (this.interests$  && this.context.selectedTags$) {
      combineLatest([
        this.context.selectedTags$ as Observable<string[]>,
        this.interests$.pipe(tap(interests => this.isHidden.emit(true))),
      ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([selectedTags, interests]: [string[], Topic[]]) => this.selectedTags = selectedTags);
    }
  }
}
