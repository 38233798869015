import {Action, ActionsSubject, select, Store} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {CorrelationIdGenerator, EntityService, Logger, SearchService, TypedFilterService, VersionedId} from "core";
import {MessageEnvelope, MessageHandlerRegistry, MessagingService} from "messaging";
import {
  asapScheduler,
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  merge,
  Observable,
  ReplaySubject,
  Subscription
} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {
  CalendarEvent,
  CalendarEventSubscriptionMessage,
  CalendarEventSubscriptionMessageType,
  CalendarEventUpdateMessage,
  CalendarEventUpdateMessageType
} from "../models/event";
import {PropertiesService} from "properties";
import {debounceTime, distinctUntilChanged, finalize, map, scan, tap} from "rxjs/operators";
import {
  calendarEventDeleteAction,
  calendarEventDeleteFailureAction,
  calendarEventDeleteSuccessAction,
  calendarEventDraftUpdateAction,
  calendarEventLoadRequestAction,
  calendarEventSetTypedFiltersAction,
  calendarEventSubscribeAction,
  calendarEventUnsubscribeAction,
  CalendarEventUpdateAction,
  calendarEventUpdateFailureAction,
  calendarEventUpdateSearchTermAction,
  calendarEventUpdateSuccessAction
} from "../store/event/actions";

import {
  selectEvent,
  selectEventCacheId,
  selectEventDraft,
  selectEventEntities,
  selectEventEntitiesLength,
  selectEventFilters,
  selectEventSearchTerm,
  selectEventsSubscribed
} from "../store/event/reducers";
import isEqual from "lodash/isEqual";
import moment from "moment";
import {AttendeeService} from "./attendee.service";
import {CalendarService} from "./calendar.service";
import {StoreService} from "store";

@Injectable({
  providedIn: 'root'
})
export class EventService extends StoreService implements SearchService, TypedFilterService , EntityService<CalendarEvent> {
  protected entities  : Observable<CalendarEvent[]>;
  protected size      : Observable<number>;
  protected sections  : Observable<number[]>;
  protected searchTerm: Observable<string>;
  protected filters   : Observable<string[]>;

  protected messageHandlerRegistry = new MessageHandlerRegistry();
  protected calendarEventSubscription: Subscription;

  protected logger = new Logger('EventService').setSilent(false);

  constructor(protected store$: Store<any>,
              protected action$: ActionsSubject,
              protected messagingService: MessagingService,
              protected propertiesService: PropertiesService,
              protected translateService: TranslateService,
              protected calendarService: CalendarService,
              protected attendeeService: AttendeeService,
              protected correlationIdGenerator: CorrelationIdGenerator) {
    super(store$, action$);
    console.log('EventService.ctor');
    this.messagingService.open$.pipe().subscribe(open => {
      this.calendarEventSubscription?.unsubscribe();
      this.calendarEventSubscription = undefined;
      if (open) {
        this.calendarEventSubscription = this.store$.select(selectEventsSubscribed)
          .pipe(
            debounceTime(30),
            distinctUntilChanged((keys1:VersionedId[],keys2:VersionedId[])=>isEqual(keys1,keys2))
          )
          .subscribe(versionedIds => {
            this.messagingService.sendMessage(this.messagingService.initializeMessage(<CalendarEventSubscriptionMessage>{
              type: CalendarEventSubscriptionMessageType,
              subscribed: versionedIds ?? []
            }));
          });
      }
    });
    let handleCalendarEventUpdateMessage = (envelope: MessageEnvelope):void => {
      let message = <CalendarEventUpdateMessage>envelope.message;
      this.store$.dispatch(calendarEventUpdateSuccessAction({ event: message.event }));
    };
    this.messageHandlerRegistry.addMessageHandler(CalendarEventUpdateMessageType,handleCalendarEventUpdateMessage);
    this.messagingService
      .register(envelope => this.messageHandlerRegistry.hasMessageHandler(envelope.message?.type))
      .subscribe(envelope => {
        this.messageHandlerRegistry.getMessageHandler(envelope.message.type)(envelope);
      });
    this.translateService.onLangChange.subscribe(() => this.loadRequest());
  }

  loadRequest(): void {
    this.logger.debug('loadRequest');
    this.store$.dispatch(calendarEventLoadRequestAction({}));
  }

  get cacheId$(): Observable<string> {
    return this.store$.select(selectEventCacheId);
  }

  get entities$(): Observable<CalendarEvent[]> {
    if (!this.entities) {
      const entities$ = new ReplaySubject<CalendarEvent[]>(1);
      this.store$
        .pipe(
          select(selectEventEntities),
          // tap(entities => this.logger.debug('ENTITIES', entities))
        )
        .subscribe(entities => entities$.next(entities));
      this.entities = entities$;
    }
    return this.entities;
  }

  get sections$(): Observable<number[]> {
    if (!this.sections) {
      const sections$ = new BehaviorSubject<number[]>([]);
      this.entities$
        .pipe(
          map(events =>
            // NOTE: events is proxy to the backing array!
            // It could return null when accessing events at certain indexes
            // (and trigger loading of the corresponding segment from the server)
            // section indexes are calculated based solely on the currently loaded entities
            ((<any>events).backingArray || [])
              .map((event, index) => ({ index: index, date: moment(event?.timeFrom) }))
              .filter((dateIndex,index,list) => {
                // this.logger.debug('SECTION', dateIndex, dateIndex.date.month(), index>0 && list[index-1].date.month());
                return index==0 || dateIndex.date.month()!=list[index-1].date.month();
              })
              .map((dateIndex,index) => dateIndex.index)
          ),
          distinctUntilChanged((previous, current) => {
            return previous == current ||
              previous && current &&
              previous.length == current.length &&
              previous.every(f => current.includes(f))
          })
        ).subscribe(sections => sections$.next(sections));
      this.sections = sections$;
    }
    return this.sections;
  }

  get size$(): Observable<number> {
    if (!this.size) {
      const size$ = new ReplaySubject<number>(1);
      this.store$
        .pipe(
          select(selectEventEntitiesLength),
          //tap(size => this.logger.debug('SIZE', size))
        )
        .subscribe(length => size$.next(length));
      this.size = size$;
    }
    return this.size;
  }

  get filters$() : Observable<string[]> {
    return this.getCombinedFilters$();
  }

  /**
   * get all typed filters which are not excluded by given callback in one string array
   * @param exclude     callback to check if this type of filter should not be returned
   * @returns a combined array of all requested typed filters
   */
  getCombinedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<string[]> {
    return this.store$.select(selectEventFilters).pipe(
      map(filters=>{
        const result:string[] = [];
        Object.keys(filters).forEach(type=>{
          if (!exclude || !exclude(type,filters[type])) {
            result.push(...filters[type]);
          }
        });
        return result.sort();
      })
    );
  }

  /**
   * set typed filters for all types at once...
   * @param typedFilters  0.n typed filters
   * @param remove        callback to check if existing type/filters other the given types should be removed....
   * @returns boolean true if updated, else false
   */
  setTypedFilters(typedFilters:{[key:string]:string[]},remove?:(type:string,filters:string[])=>boolean) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      typedFilters = {...typedFilters};
      Object.keys(typedFilters).forEach(type=>[...typedFilters[type]].sort());
      let updated = false;
      let added   = {...typedFilters};
      firstValueFrom(this.getTypedFilters$((type,previous)=>{
        delete added[type];
        if (!updated) {
          const filters = typedFilters[type];
          updated = !!filters || !remove ? !isEqual(filters,previous) : remove(type,previous);
        }
        return true;
      }))
        .then(allFilters => {
          updated = updated || Object.keys(added).length>0;
          this.dispatch(calendarEventSetTypedFiltersAction({filters:typedFilters,remove}))
            .then(()=>resolve(updated))
            .catch(error=>reject(error));
        })
        .catch(error=>reject(error));
    });
  }

  /**
   * get all typed filters which are not excluded by given callback
   * @param exclude     callback to check if this type of filter should not be returned
   * @returns a copy of all requested typed filters
   */
  getTypedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<{[key:string]:string[]}> {
    return this.store$.select(selectEventFilters).pipe(
      map(filters=>{
        const result:{[key:string]:string[]} = {};
        Object.keys(filters).forEach(type=>{
          if (!exclude || !exclude(type,filters[type])) {
            result[type] = [...filters[type]];
          }
        });
        return result;
      })
    );
  }

  /*
  get filters$(): Observable<string[]>  {
    if (!this.filters) {
      const filters$ = new ReplaySubject<string[]>(1);
      this.store$
        .pipe(
          select(selectEventFilters),
          //tap(filters => this.logger.debug('FILTERS', filters))
        )
        .subscribe(filters => filters$.next(filters));
      this.filters = filters$;
    }
    return this.filters;
  }

  updateFilter(filters: string[]): void {
    //this.logger.debug('updateFilter', filters);
    this.store$.dispatch(calendarEventUpdateFilterAction({ filters: filters }));
  }

   */

  get searchTerm$(): Observable<string> {
    if (!this.searchTerm) {
      const searchTerm$ = new ReplaySubject<string>();
      this.store$
        .pipe(
          select(selectEventSearchTerm),
          distinctUntilChanged((a,b) => isEqual(a,b)),
          tap(searchTerm => this.logger.debug('SEARCH TERM', searchTerm))
        )
        .subscribe(searchTerm => searchTerm$.next(searchTerm));
      this.searchTerm = searchTerm$;
    }
    return this.searchTerm;
  }

  updateSearchTerm(term: string): void {
    this.store$.dispatch(calendarEventUpdateSearchTermAction({ term: term }));
  }

  get canEditEvents$(): Observable<boolean> {
    return combineLatest([
      this.calendarService.entities$.pipe(map((entities) =>
        !!entities?.find(calendar => calendar?.permission?.editEvents)
      )),
      this.calendarService.canCreateCalendar$()]
    ).pipe(map(([editEvents, canCreateCalendar])=> editEvents || canCreateCalendar));
  }

  getCalendarEvent$(id: string, defaultCalendarEvent?: CalendarEvent): Observable<CalendarEvent> {
    let subscribed = false;
    let ensureSubscription = (event:CalendarEvent) => {
      if (!subscribed) {
        subscribed = true;
        asapScheduler.schedule(() => this.dispatch(calendarEventSubscribeAction({ id })));
      }
    }
    return this.store$.select(selectEvent(id)).pipe(
      distinctUntilChanged((calendarEvent1: CalendarEvent, calendarEvent2: CalendarEvent)=>isEqual(calendarEvent1, calendarEvent2)),
      finalize(() => {
        if (subscribed) {
          subscribed = false;
          window.setTimeout(()=>
            this.store$.dispatch(calendarEventUnsubscribeAction({ id })),
          1000
          );
        }
      }),
      tap(calendarEvent => ensureSubscription(calendarEvent)),
      map(calendarEvent => calendarEvent ?? defaultCalendarEvent)
    );
  }

  save(event: CalendarEvent, previous?: CalendarEvent): Promise<CalendarEvent> {
    if (event) {
      const correlationId = this.correlationIdGenerator.next();
      const promise = new Observable<any>(subscriber => {
        const reducer = `saveCalendarEvent_${correlationId}`;
        // to improve type checking consider implementing CalendarUpdateAction as class
        // then it will be possible to probe for particular Action type with instanceof operator
        // and benefit from user-defined type guards support of typescript and the related type narrowing.
        this.store$.addReducer(reducer, (state, action: { event: CalendarEvent, error?: any, correlationId?: string } & Action) => {
          if (action.type == calendarEventUpdateSuccessAction.type  ||
              action.type == calendarEventUpdateFailureAction.type) {
            console.debug(reducer, state, action);
            if (action.correlationId == correlationId) {
              if (action.type == calendarEventUpdateSuccessAction.type) {
                // this.loadRequest();
                subscriber.next(new CalendarEvent(action.event));
              } else {
                subscriber.error(action.error);
              }
              subscriber.complete();
              this.store$.removeReducer(reducer);
            }
          }
          return state;
        });
      }).toPromise();
      this.logger.debug({event, previous});
      if (!isEqual(event.seats.total, previous?.seats.total)) {
        const delta = event.seats.total.remove(previous.seats.total);
        event.seats.available = delta.add(previous.seats.available, false);
      }
      this.store$.dispatch(new CalendarEventUpdateAction(event, previous, correlationId));
      return promise;
    } else {
      return Promise.reject('Invalid event');
    }
  }

  delete(event: CalendarEvent): Promise<void> {
    let resolve: any, reject: any;
    const promise = new Promise<void>((promiseResolve, promiseReject) => {
      resolve=promiseResolve; reject=promiseReject;
    });
    const reducer = `deleteCalendarEvent_${event.id}`;
    this.store$.addReducer(reducer, (state, action: { event: CalendarEvent, error?: any } & Action) => {
      if (action.type == calendarEventDeleteSuccessAction.type || action.type == calendarEventDeleteFailureAction.type) {
        console.debug(reducer, state, action);
        if (action.event?.id==event.id) {
          if (action.type == calendarEventDeleteSuccessAction.type) {
            this.loadRequest();
            resolve();
          } else {
            reject();
          }
          this.store$.removeReducer(reducer);
        }
      }
      return state;
    });
    this.store$.dispatch(calendarEventDeleteAction({ event }));
    return promise;
  }

  get draft$(): Observable<CalendarEvent> {
    return this.store$.select(selectEventDraft);
  }

  protected draftSubscription: Subscription;
  set draft$(event$: Observable<CalendarEvent>) {
    this.draftSubscription?.unsubscribe();
    this.draftSubscription = event$.subscribe((event) => {
      this.store$.dispatch(calendarEventDraftUpdateAction({event}));
    });
  }
}

