import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import {PresenceService} from "../../presence.service";
import {PresenceState} from "../../store/models";
import {Presence} from "core";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'presence-indicator',
  template: `
    <div *ngLet="{
       state:state$|async,
       inactive:inactive
    } as view"
      [class.inactive]="view.state==stateLoggedIn && view.inactive"
      [class.unavailable]="view.state==stateUnavailable"
      [class.absent]="view.state==stateAbsent"
      [class.invisible]="view.state==stateInvisible"
      [class.undefined]="!view.state"></div>
  `,
  styleUrls: ['./presence-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresenceIndicatorComponent implements OnDestroy, OnChanges {

  @Input() presence: Presence;
  @Input() all: boolean = false;

  state$ = new BehaviorSubject<PresenceState>(undefined);

  stateLoggedIn     = PresenceState.loggedIn;
  stateInvisible    = PresenceState.invisible;
  stateAbsent       = PresenceState.absent;
  stateUnavailable  = PresenceState.unavailable;

  timerHandler      = undefined;
  inactive:boolean  = false;
  invisible:boolean = false;

  constructor(
    public preseceService: PresenceService,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("ngOnChanges."+this.constructor.name,change);
    this.updateState();
  }

  updateState() {
    this.clearTimeout();
    if (this.presence?.state==PresenceState.loggedIn && !this.all) {
      let inactiveTime = Date.now()-this.presence.timeActive;
      this.inactive  = inactiveTime>300_000; // 5min
      this.invisible = inactiveTime>10_800_000; // 3h
      if (!this.invisible) {
        this.timerHandler = window.setTimeout(()=> {
          this.timerHandler = undefined;
          this.updateState();
          this.changeDetector.detectChanges();
        },60_000);
      }
    } else {
      this.inactive  = false;
      this.invisible = false;
    }
    this.state$.next(
      !this.presence?.state || this.presence.state==PresenceState.loggedOut || this.invisible ? <PresenceState>undefined :
       this.presence.state==PresenceState.invisible ? (this.all ? PresenceState.invisible : <PresenceState>undefined) :
       this.presence.state);
  }

  clearTimeout(): void {
    if (this.timerHandler) {
      window.clearTimeout(this.timerHandler);
      this.timerHandler = undefined;
    }
  }
}
