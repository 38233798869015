/* projects/contact/src/lib/components/contact-detail/contact-detail-reach/contact-detail-reach.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px 24px;
}
.reach {
  padding: 8px;
}
.reach > h4 {
  z-index: 1;
}
.last-access {
  display: flex;
  flex-direction: column;
  color: grey;
  margin-top: 16px;
}
.last-access > h4 {
  margin: 0;
  display: block;
  padding: 0 0 4px 0;
  font-size: 13.5px;
}
.last-access > div {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}
.last-access > div > div {
  position: relative;
  display: flex;
  color: #bbb;
}
.last-access > div > div.week {
  color: gray;
}
.last-access > div > div.days {
  color: var(--color-primary);
}
.last-access > div > div > div.warning {
  background-color: var(--color-accent);
  color: var(--color-accentContrast);
  border-radius: 4px;
  font-size: 10px;
  padding: 2px 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: -2px;
  right: -2px;
  line-height: 1em;
}
.last-access > div > div > div.push {
  background-color: var(--color-primary);
  color: var(--color-primaryContrast);
  border-radius: 4px;
  font-size: 10px;
  padding: 2px 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: -2px;
  right: -2px;
  line-height: 1em;
}
.last-access > div span {
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
:host-context(.color-scheme-dark) .last-access {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .last-access > div {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .last-access > div > mat-icon {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .last-access > div > div {
  color: #777;
}
:host-context(.color-scheme-dark) .last-access > div > div.week {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .last-access > div > div.days {
  color: var(--color-primary);
}
:host-context(.color-scheme-dark) .last-access > div > div > div.warning {
  background-color: var(--color-accent);
  color: white;
}
/*# sourceMappingURL=contact-detail-reach.component-F2GVX3EL.css.map */
