import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {ConferencePanelOverlayService} from "../conference-panel/conference-panel-overlay.service";
import {takeUntil} from "rxjs/operators";
import {BasicContainerComponent, SpeakerRegistration, SpeakerService} from "shared";
import {ConferenceService} from "../../conference.service";
import {ConferenceInfo, ConferenceParticipant} from "../../store/models";
import {Logger, Platform} from "core";

export interface MediaShare {
  audio?:boolean;
  video?:boolean;
  screen?:boolean;
}

@Component({
  selector: 'conference-status-bar',
  templateUrl: './conference-status-bar.component.html',
  styleUrls: ['./conference-status-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConferenceStatusBarComponent extends BasicContainerComponent {

  activeCalls$  = new BehaviorSubject<number>(0);
  activeShare$  = new BehaviorSubject<MediaShare>({});
  pendingCalls$ = new BehaviorSubject<number>(0);

  protected conferenceInfos:ConferenceInfo[] = [];
  protected handledConferenceIds:Set<string> = new Set<string>();
  protected audio: HTMLAudioElement;
  protected audioContext: AudioContext;
  protected audioNode: MediaStreamAudioSourceNode;
  protected gainNode: GainNode;
  protected audioStreams = new MediaStream();
  protected audioSubscription:Subscription = undefined;
  protected speakerRegistration: SpeakerRegistration;

  protected logger = new Logger('ConferenceStatusBarComponent').setSilent(true);

  constructor(
    public conferenceService:ConferenceService,
    public conferencePanelOverlayService:ConferencePanelOverlayService,
    public platform: Platform,
    public speakerService: SpeakerService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const participants = new Map<string,ConferenceParticipant>();
    // this.audio = this.audioRef.nativeElement;
    // this.conferenceService.conferenceAudio = this.audio;
    // TODO: tried fix safari on ios: https://bugs.webkit.org/show_bug.cgi?id=230902
    if (false && this.platform.is("ios")) {
      // @ts-ignore
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();
      this.gainNode = this.audioContext.createGain();
      this.gainNode.gain.value = this.gainNode.gain.maxValue;
      // The following line produces InvalidStateError: Media stream has no audio tracks
      // const audioNode = context.createMediaStreamSource(this.audioStreams);
      // const gainNode: GainNode = context.createGain();
      // // some device volume too low ex) iPad
      // gainNode.gain.value = gainNode.gain.maxValue;
      // audioNode.connect(gainNode);
      // gainNode.connect(context.destination);
    } else {
      this.audio = this.conferenceService.sounds.conferenceAudio();
      this.audio.srcObject = this.audioStreams;
    }
    this.speakerRegistration = this.speakerService.register();
    this.conferenceService.getAllConferenceInfos$().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(conferenceInfos=> {
      console.log("ConferenceStatusBarComponent.CONFERENCES",conferenceInfos);
      //this.logger.debug("CONFERENCES",conferenceInfos);
      this.conferenceInfos = conferenceInfos;
      let trigger   = false;
      let active    = 0;
      let pending   = 0;
      let userId    = this.conferenceService.userId;
      let currShare:MediaShare = {};
      let prevShare = this.activeShare$.value;
      conferenceInfos?.forEach(conferencInfo=>{
        const conference = this.conferenceService.getConference(conferencInfo.conferenceId);
        if (!!conferencInfo.ringing.find(p=>p.id==userId) || !conference?.active) {
          pending++;
        } else {
          active++;
          currShare.audio  = conference.audio && !conference.muted;
          currShare.video  = conference.video;
          currShare.screen = conference.screen;
          this.logger.debug("share",currShare);
          participants.clear();
          [...conference.info.pickedUp, ...conference.info.paused].forEach(participant => {
            participants.set(participant.id,participant);
          });
          let streams:Observable<MediaStream>[] = [];
          conference.getConnectedParticipantIds().forEach(id=>{
            const mediaStreamWrapper = id==userId || !participants.get(id)?.audio ? undefined : conference.getConnection(id)?.mediaStreamWrapper;
            if (!!mediaStreamWrapper) {
              this.logger.debug("CONFERENCE.user",id);
              streams.push(mediaStreamWrapper.mediaStream$);
            }
          });
          this.logger.debug("CONFERENCE.subscribe.streams.length",streams.length,"\nattached",this.audioStreams.getTracks().map(track=>track.id));
          this.audioSubscription?.unsubscribe();
          this.audioSubscription = combineLatest(streams)
            .subscribe(streams=> {
              this.logger.debug("CONFERENCE.subscribed.audiotracks",streams.map(stream=>stream.getAudioTracks().length),"\nattached",this.audioStreams.getTracks().map(track=>track.id));
              let ids = new Set<string>(this.audioStreams.getTracks().map(track=>track.id));
              streams.forEach((stream,index)=>{
                //stream.getAudioTracks().forEach(track=>this.logger.debug("TRACK.COMBINE.AUDIO","\n"+track.id+"\nstream",stream.id));
                //stream.getVideoTracks().forEach(track=>this.logger.debug("TRACK.COMBINE.VIDEO","\n"+track.id+"\nstream",stream.id));
                stream.getAudioTracks().forEach(track=>{
                  this.logger.debug("CONFERENCE.subscribed["+index+"].audiotrack",track.id);
                  if (!ids.delete(track.id)) {
                    this.logger.debug("CONFERENCE.subscribed["+index+"].audiotrack",track.id,"ADD");
                    this.audioStreams.addTrack(track);
                  }
                })
              });
              this.logger.debug("CONFERENCE.remove.tracks",ids);
              ids.forEach(id=>{
                this.audioStreams.removeTrack(this.audioStreams.getTrackById(id));
              });
              if (!this.platform.is("ios")) {
                this.audioNode?.disconnect();
                // this.gainNode.disconnect(); // gainNode is initialized only on ios
              }
              if (this.audioStreams.getTracks().length > 0) {
                if (!this.platform.is("ios")) {
                  this.audio.play();
                } else {
                  this.audioNode = this.audioContext.createMediaStreamSource(this.audioStreams);
                  this.audioNode.connect(this.gainNode);
                  this.gainNode.connect(this.audioContext.destination);
                }
              }
              this.speakerRegistration.update(this.audioStreams.getTracks());
            });
        }
        if (!this.handledConferenceIds.has(conferencInfo.conferenceId)) {
          this.handledConferenceIds.add(conferencInfo.conferenceId);
          trigger = true;
        }
      })
      if (active==0) {
        this.removeAudioTracks();
      }
      if (this.activeCalls$.value!=active) {
        this.activeCalls$.next(active);
      }
      if (this.pendingCalls$.value!=pending) {
        this.pendingCalls$.next(pending);
      }
      if (!!prevShare.audio != !!currShare.audio ||
        !!prevShare.video != !!currShare.video ||
        !!prevShare.screen != !!currShare.screen) {
        this.activeShare$.next(currShare);
      }
      if (trigger) {
        this.openConferencePanel();
      }
    });
  }

  ngOnDestroy() {
    this.audioSubscription?.unsubscribe();
    this.speakerRegistration?.deregister();
    this.removeAudioTracks();
    super.ngOnDestroy();
  }

  protected removeAudioTracks() {
    this.audioStreams.getTracks().forEach(track=>this.audioStreams.removeTrack(track));
  }

  public openConferencePanel() {
    this.conferencePanelOverlayService.open();
  }
}
