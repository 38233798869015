<div class="avatar">
  <img matListItemAvatar [src]="photoUrl | sessionLink" onload="this.style.opacity=1"/>
</div>
<div class="content">
  <div class="contact">
    <div>
      <h3><span>{{contact.name}}</span><!--
        <a *ngIf="!renderingHints.noNameLink" href="/facebook/contact/{{contact.id}}" target="_blank">{{contact.name}}</a>
        <span *ngIf="renderingHints.noNameLink">{{contact.name}}</span>
      --></h3>
      <app-contact-join-info *ngIf="!renderingHints.noJoinDetails" [contact]="contact" [renderingHints]="renderingHints"></app-contact-join-info>
    </div>
  </div>
  <!--
  <app-partner-id *ngIf="!renderingHints.noPartnerId" [membership]="membership | async" (click)="$event.stopPropagation()"></app-partner-id>
  -->
</div>
