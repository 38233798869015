<mat-selection-list class="mat-accent"
                    [hideSingleSelectionIndicator]="true"
                    [multiple]="multiple"
                    [class.multiple]="multiple"
                    (selectionChange)="onSelectionChange($event)">
  <cdk-virtual-scroll-viewport itemSize="48" orientation="vertical">
    <ng-container *cdkVirtualFor="let entity of entities$;
                                  let index  = index;
                                  let first  = first;
                                  let last   = last;
                                  let even   = even;
                                  let odd    = odd;
                                  trackBy: trackCalendar;">
      <mat-list-option #option
                       *ngIf="safeCalendar(entity); let calendar"
                       [checkboxPosition]="'before'"
                       [selected]="isSelected(calendar.id)"
                       [value]="calendar.id"
                       [style.borderColor]="calendar.color ? '#'+calendar.color : undefined">
        <div class="container">
          <div class="calendar">
            <span>{{calendar.name}}</span>
          </div>
          <div class="controls" (click)="$event.stopPropagation()">
            <ng-container *ngTemplateOutlet="controlsTemplate; context: { index: index, calendar: calendar }"></ng-container>
          </div>
        </div>
        <mat-divider *ngIf="!last"></mat-divider>
      </mat-list-option>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</mat-selection-list>
<!--<div *ngIf="mainButtonSpace" class="main-button-space"></div>-->

