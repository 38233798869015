<div hover #item [class.selected]="conversation.id==(chatService.currentConversationId$ | async)">
  <div matRipple [matRippleTrigger]="item"></div>
  <div class="avatar">
    <img matListItemAvatar [src]="photoUrl | sessionLink" onload="this.style.opacity=1"/>
    <ng-container *ngIf="chatService.getDirectContactId(conversation?.id) as contactId">
      <presence [contactId]="contactId"></presence>
    </ng-container>
  </div>
  <div class="content">
    <div class="conversation">
      <div class="new"><!--33--></div>
      <div class="infos">
        <h3><span>{{conversation?.name | translate}}</span></h3>
        <div [ngSwitch]="lastAction(conversation)" class="last_action">
          <div class="typing" *ngSwitchCase="ACTION_TYPING">
            <span class="dot"></span><span class="dot"></span><span class="dot"></span>
          </div>
          <div class="draft" *ngSwitchCase="ACTION_DRAFT">
            <span translate>chat.conversation.draft</span><span>{{conversation.draftMessage?.bodyText | markup:{clean:true} }}</span>
          </div>
          <div class="message" *ngSwitchCase="ACTION_TRANSLATE_MESSAGE">
            <message-delivery-state [log]="false" [message]="conversation.lastMessage" [conversationData]="conversation"></message-delivery-state>
            <span>{{conversation.lastMessage?.bodyText | translate | markup:{clean:true} }}</span>
          </div>
          <div class="message" *ngSwitchCase="ACTION_TRANSLATE_MESSAGE_ME">
            <message-delivery-state [log]="false" [message]="conversation.lastMessage" [conversationData]="conversation"></message-delivery-state>
            <span class="you" translate>chat.conversation.you</span>
            <span>{{conversation.lastMessage?.bodyText | translate | markup:{clean:true} }}</span>
          </div>
          <div class="message" *ngSwitchCase="ACTION_MESSAGE">
            <message-delivery-state [log]="false" [message]="conversation.lastMessage" [conversationData]="conversation"></message-delivery-state>
            <span>{{conversation.lastMessage?.bodyText | markup:{clean:true} }}</span>
          </div>
          <div class="message" *ngSwitchCase="ACTION_MESSAGE_ME">
            <message-delivery-state [log]="false" [message]="conversation.lastMessage" [conversationData]="conversation"></message-delivery-state>
            <span class="you" translate>chat.conversation.you</span>
            <span>{{conversation.lastMessage?.bodyText | markup:{clean:true} }}</span>
          </div>
          <div class="message" *ngSwitchCase="ACTION_ATTACHMENT">
            <message-delivery-state [log]="false" [message]="conversation.lastMessage" [conversationData]="conversation"></message-delivery-state>
            <span translate>chat.conversation.attachment</span>
          </div>
          <div class="message" *ngSwitchCase="ACTION_ATTACHMENT_ME">
            <message-delivery-state [log]="false" [message]="conversation.lastMessage" [conversationData]="conversation"></message-delivery-state>
            <span class="you" translate>chat.conversation.you</span>
            <span translate>chat.conversation.attachment</span>
          </div>
          <div class="message" *ngSwitchCase="ACTION_PARTICIPANTS_ADDED">
            <span translate>chat.conversation.participants.added</span>
          </div>
          <div class="message" *ngSwitchCase="ACTION_PARTICIPANTS_CHANGED">
            <span translate>chat.conversation.participants.changed</span>
          </div>
          <div class="message" *ngSwitchCase="ACTION_PARTICIPANTS_REMOVED">
            <span translate>chat.conversation.participants.removed</span>
          </div>
          <div class="created" *ngSwitchDefault>
            <span translate>chat.conversation.created</span><span>{{conversation.timeSorted | date:('chat.conversation.time' | translate)}}</span>
          </div>
        </div>
        <ng-container
          *ngTemplateOutlet="conversationInfoTemplate; context: { conversation: conversation }">
        </ng-container>
        <!-- <span>{{conversation?.type}} {{conversation?.id}}</span> -->
      </div>
    </div>
  </div>
  <div *ngIf="conversation.unseen>0" class="badge">{{conversation.unseen}}</div>
</div>
