/* projects/tasks/src/lib/components/task-contact-list/task-contact-list.component.scss */
:host {
  display: flex;
}
app-contact-list {
  flex: 1;
  position: relative;
}
app-contact-list mat-icon {
  color: var(--color-primary);
}
presence {
  position: absolute;
  right: 0;
  bottom: 0;
}
presence-info {
  display: block;
  font-size: 70%;
  line-height: 1em;
  font-weight: lighter;
}
/*# sourceMappingURL=task-contact-list.component-YGMUAXWZ.css.map */
