/* projects/contact/src/lib/components/contact-reach/contact-reach.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
  display: block;
  overflow: hidden;
}
:host > div.diagram {
  position: relative;
}
:host > div.diagram:before {
  content: "";
  position: relative;
  display: block;
  padding-bottom: 54%;
}
:host > div.diagram > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 8px;
}
:host > div.diagram > div:before {
  content: "";
  position: relative;
  display: block;
  padding-bottom: 100%;
}
:host > div.diagram > div > div {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
:host > div.diagram > div > div > div.circle {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  border: 1px solid #ddd;
  top: 50%;
  left: 50%;
}
:host > div.diagram > div > div > div.label {
  position: absolute;
  line-height: 1em;
  font-size: 13.5px;
  left: 50%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6666666667);
  padding: 0 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding-left: 12px;
  transform: translateX(0) translateY(-0.5em);
}
:host > div.diagram > div > div > div.value-container {
  position: absolute;
  line-height: 1em;
  font-size: 13.5px;
  left: 50%;
  z-index: 1;
  transform: translateX(0) translateY(-0.5em);
}
:host > div.diagram > div > div > div.value-container > div.label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.6666666667);
  padding: 0 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding-left: 12px;
}
:host > div.diagram > div > div > div.value-container > div.total {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.6666666667);
  padding: 0 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-left: 12px;
}
:host > div.diagram > div > div > div.counter {
  position: absolute;
  transform: translateX(-50%) translateY(-0.6em);
  line-height: 1em;
  font-size: 13.5px;
  left: 50%;
  z-index: 1;
  width: 1.3em;
  border-radius: 50%;
  background-color: #ddd;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  text-align: center;
  padding: 0.1em;
}
:host > div.diagram > div > div > div.counter.value {
  background-color: var(--color-primary);
  color: var(--color-primaryContrast);
}
:host > div.diagram > div > div > div.value-set {
  position: absolute;
  line-height: 1em;
  font-size: 13.5px;
  right: 50%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6666666667);
  padding: 0 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 16px;
  transform: translateX(0) translateY(-0.5em);
}
:host > div.controls-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
:host > div.controls-container > div.controls {
  position: relative;
}
:host > div.controls-container > div.controls > button.select {
  padding: 0 9px;
}
:host > div.controls-container > div.controls > div.total {
  position: absolute;
  top: 50%;
  left: 100%;
  line-height: 1em;
  font-size: 13.5px;
  transform: translateY(-50%);
  padding: 0 10px;
}
:host-context(.color-scheme-dark) > div.diagram > div > div > div.circle {
  border: 1px solid #444;
}
:host-context(.color-scheme-dark) > div.diagram > div > div > div.label {
  background-color: rgba(255, 255, 255, 0.6666666667);
  border: 1px solid #ccc;
}
:host-context(.color-scheme-dark) > div.diagram > div > div > div.value-container > div.label {
  background-color: rgba(255, 255, 255, 0.6666666667);
  border: 1px solid #ccc;
  color: #333;
}
:host-context(.color-scheme-dark) > div.diagram > div > div > div.value-container > div.total {
  background-color: rgba(255, 255, 255, 0.6666666667);
  border: 1px solid #ccc;
  color: #333;
}
:host-context(.color-scheme-dark) > div.diagram > div > div > div.counter {
  background-color: #ddd;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
:host-context(.color-scheme-dark) > div.diagram > div > div > div.counter.value {
  background-color: var(--color-primary);
  color: var(--color-primaryContrast);
}
:host-context(.color-scheme-dark) > div.diagram > div > div > div.value-set {
  background-color: rgba(255, 255, 255, 0.6666666667);
  border: 1px solid #ccc;
  color: #333;
}
/*# sourceMappingURL=contact-reach.component-D2BCEMWX.css.map */
