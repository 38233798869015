import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-app-install-bottom-sheet',
  template: `<app-install 
                [code]="data?.code" 
                [link]="data?.link"
                [user]="data.user"
                (onLink)="bottomSheetRef.dismiss()"
                (onClose)="bottomSheetRef.dismiss()">
             </app-install>`,
})
export class AppInstallBottomSheetComponent {
  constructor(public bottomSheetRef: MatBottomSheetRef<AppInstallBottomSheetComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
  }
}
