import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {Logger} from "core";
import {CalendarEvent} from "../../models/event";
import moment from "moment";
import {DateAdapter} from "@angular/material/core";
import {BehaviorSubject, Observable, Subscription, timer} from "rxjs";


@Component({
  selector: 'app-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['./event-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventListItemComponent implements OnInit {

  @Input() index: number;
  @Input() event: CalendarEvent;
  @Input() color: string;
  @HostBinding('class.dense')
  @Input() dense = false;

  protected year$: BehaviorSubject<number>;
  protected subscription: Subscription;
  logger: Logger = new Logger('EventListItemComponent');

  constructor(protected dateAdapter: DateAdapter<moment.Moment>) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    // this.year$?.complete();
    this.year$ = null;
  }

  get yearMonthDayTime(): {year: string, month: string, day: number, time: string} {
    const date = new Date(this.event?.timeFrom || Date.now());
    const m: moment.Moment = moment(date);
    return {
      year  : this.dateAdapter.getYearName(m),
      month : this.dateAdapter.getMonthNames('short')[date.getMonth()],
      day   : this.dateAdapter.getDate(m),
      // time  : this.dateAdapter.format(m, 'hh:mm A'),
      // time  : moment(date).local().format('LT')
      time  : this.dateAdapter.format(m, 'LT'),

    };
  }

  get year(): Observable<number> {
    if (!this.year$) {
      this.year$ = new BehaviorSubject(new Date().getFullYear());
      this.subscription = timer(0,5000) // probe current year every 5 seconds
        .subscribe((period) => {
          this.year$.next(new Date().getFullYear());
        });
    }
    return this.year$;
  }
}
