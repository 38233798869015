<div class="diagram" *ngLet="(50/(circles>0 ? circles : 1)) as margin" [style.marginTop.%]="margin">
  <div>
    <div>
      <ng-container *ngFor="let i of [].constructor(circles+1); let circle=index;">
        <ng-container *ngLet="{
                height: circles>0 ? (100*(circle))/(circles) : 0,
                top: circles>0 ? (100*(circle+1))/(circles) : 0,
                value: (values.array?.length>circle ?
                        circle==circles ? summary(circle) :
                        values.array[circle] : undefined),
                circle: circle<circles ? (circle+1).toLocaleString() : '+',
                label: values.label | translate
              } as context"><!-- circle==circles ? values. -->
          <div class="circle"
               [style.width.%]="context.height"
               [style.height.%]="context.height">
          </div>
          <div class="value-container"
               [style.top.%]="50-(context.top/2)">
            <div *ngIf="context.value>0" class="label">{{context.value}}</div>
            <div *ngIf="circle==0" class="total">{{summary(0)}}</div>
          </div>
          <div class="counter" [class.value]="context.value>0"
               [style.top.%]="50-(context.top/2)">{{context.circle}}</div>
          <!--<div class="total">{{summary(0)}}</div>

           *ngIf="context.value>0" class="label"{{context.value}}
           -->
        </ng-container>
      </ng-container>
      <div *ngIf="!!values?.label" [style.top.%]="50-((circles>0 ? 100/circles : 0)/2)" class="value-set">{{values.label | translate}}</div>
    </div>
  </div>
</div>
<div class="controls-container">
  <div class="controls">
    <button *ngIf="valuesArray?.length>1" mat-icon-button color="primary" (click)="nextValues(true)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button class="select" mat-flat-button color="primary"
      [disabled]="!selected"
      [matMenuTriggerFor]="filterMenu">
      <span>{{selected?.label | translate}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <button *ngIf="valuesArray?.length>1" mat-icon-button color="primary" (click)="nextValues(false)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <mat-menu #filterMenu="matMenu">
      <ng-container *ngFor="let filter of filters">
        <button mat-menu-item (click)="select(filter)">
          <span>{{filter.label | translate}}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>
<!--
<div class="codntrols">
</div>
-->
