import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {PropertiesService} from "properties";
import {Contact, Logger, prettyDateDiff} from "core";
import {ContactListItemRenderingHints} from "../contact-list-item/contact-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-contact-join-info',
  templateUrl: './contact-join-info.component.html',
  styleUrls: ['./contact-join-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ContactJoinInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() renderingHints: ContactListItemRenderingHints = {};
  @Input() contact: Contact;

  unitLabel$ = new BehaviorSubject<string>(undefined);
  units$ = new BehaviorSubject<number>(undefined);
  class$ = new BehaviorSubject<'long'|'mid'|'short'>('long');
  timer:number = undefined;

  logger: Logger = new Logger('ContactJoinInfoComponent');

  constructor(public propertiesService: PropertiesService,
              public translateService: TranslateService) {}

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  protected stopTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  protected startTimer(timeExpired:number) {
    this.stopTimer();
    if (timeExpired) {
      const now = Date.now();
      const difference = timeExpired-Date.now();
      if (difference>=1_000) {
        const hours = Math.floor(difference/3_600_000);
        let nextLabel = 'moment.remaining.hours';
        let nextUnits = hours;
        let nextClass:'long'|'mid'|'short' = hours>=24 ? 'long' : hours>=12 ? 'mid' : 'short';
        if (hours<2) {
          const [units, unitName] = prettyDateDiff(now, now + difference);
          nextLabel = units > 0 ? `moment.remaining.${unitName}${units > 1 ? 's' : ''}` : 'moment.expired';
          nextUnits = units;
        }
        if (this.unitLabel$.value!=nextLabel) {
          this.unitLabel$.next(nextLabel);
        }
        if (this.units$.value!=nextUnits) {
          this.units$.next(nextUnits);
        }
        if (this.class$.value!=nextClass) {
          this.class$.next(nextClass);
        }
        this.timer = window.setTimeout(()=>{
          this.startTimer(timeExpired);
        }, hours>=2 ? 60_000 : Math.floor(difference/60_000)>=2 ? 5_000 : 500);
      } else {
        this.unitLabel$.next('moment.expired');
        this.units$.next(undefined);
        this.class$.next('long');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contact']) {
      //this.startTimer(Date.now()+(4*60_000));
      this.startTimer(this.contact?.timeExpired);
    }
  }
}
