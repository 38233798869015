import {ContactState, initialContactListState, initialContactUplineState, initialGroupAuthorsState} from "./state";
import {
  asContact,
  contactDeleteDoneAction,
  contactDeleteFailedAction,
  contactLoadPageAction,
  contactLoadPageDoneAction,
  contactLoadPageFailedAction,
  contactLoadRequestAction,
  contactSelectAction,
  contactSetTypedFiltersAction,
  contactSubscribeAction,
  contactUnsubscribeAction,
  contactUpdateAction,
  contactUpdateDoneAction,
  contactUpdateFailedAction,
  contactUpdateSearchTermAction,
  contactUplineLoadAction,
  contactUplineLoadDoneAction,
  contactUplineLoadFailedAction,
  groupAuthorsLoadAction,
  groupAuthorsLoadDoneAction,
  groupAuthorsLoadFailedAction
} from "./actions";
import {Contact, createCacheId, createPageLoadingArray, createPageLoadingProxy, EMPTY_ARRAY, isValidNumber} from "core";
import isEqual from "lodash/isEqual";
import {ActionReducerMap, createReducer, on} from "@ngrx/store";
import {logAction} from "shared";

export const contactReducer = createReducer(
  initialContactListState,
  on(contactLoadRequestAction,(state, action) => {
    return logContactAction("contactLoadRequestAction",true,state,action,()=>{
      return {
        ...state,
        currentCacheId: createCacheId(),
        parent: null,
        loading: true
      }
    });
  }),
  on(contactSetTypedFiltersAction,(state, action) => {
    return logContactAction("contactSetTypedFiltersAction",true,state,action,()=>{
      if (!isEqual(state.filters, action.filters)) {
        const filters = {...action.filters??{}};
        Object.keys(filters).forEach(type=>filters[type]?.sort())
        const updated = {...state.filters,...filters};
        if (!!action.remove) {
          Object.keys(updated).forEach(type=>{
            if (!filters[type] && action.remove(type,updated[type])) {
              delete updated[type];
            }
          });
        }
        return {
          ...state,
          entities: createPageLoadingArray<Contact>(0, 25, (pageIndex: number, pageSize: number): void => {}),
          indices: {},
          selectedIndex: undefined,
          filters:updated,
          currentCacheId: createCacheId(),
          parent: null,
          loading: true,
        }
      }
      return state;
    });
  }),
  on(contactUpdateSearchTermAction,(state, action) => {
    return logContactAction("contactUpdateSearchTermAction",true,state,action,()=>{
      return isEqual(state.term, action.term) ? state : {
        ...state,
        term: action.term,
        currentCacheId: createCacheId(),
        parent: null,
        loading: true
      }
    });
  }),
  on(contactSelectAction,(state, action) => {
    return logContactAction("contactSelectAction",true,state,action,()=>{
      return isEqual(state.selectedIndex, action.selectedIndex) ? state : {
        ...state,
        selectedIndex: action.selectedIndex
      }
    });
  }),
  on(contactLoadPageDoneAction,(state, action) => {
    return logContactAction("contactLoadPageDoneAction",true,state,action,()=>{
      let { entities, indices, previousCacheId, parent, subscriptions } = { ...state };
      const ioState = { loading: false, error: null };
      if (action.cacheId == state.currentCacheId && (!!action.data || !!action.parent)) {
        if (state.previousCacheId != state.currentCacheId) {
          entities = createPageLoadingArray<Contact>(action.total,25, (pageIndex:number, pageSize:number):void => {
            action.dispatch(contactLoadPageAction({
              cacheId:action.cacheId,
              index:pageIndex,
              size:pageSize
            }));
          });
          indices = {};
          previousCacheId = action.cacheId;
        } else {
          const backingArray: Contact[] = (<any>entities).backingArray ?? [];
          const loadPageFunction: (pageIndex: number, pageSize: number) => void = (
            (<any>state.entities).loadPageFunction ||
            (() => ((pageIndex:number, pageSize:number): void => {}))
          )() ;
          entities = createPageLoadingProxy<Contact>(backingArray,25, loadPageFunction)
        }
        const dataLength = action.data?.length ?? 0;
        const dataIndex  = action.index ?? 0;
        entities['loaded'] = Math.max(entities['loaded'] || 0, dataIndex + dataLength);
        for (let i=0, max=dataLength; i<max; i++) {
          entities[action.index+i] = action.data[i];
          indices[action.data[i].id] = action.index+i;
        }
        parent = action.parent;
        if (!!parent?.id) {
          const subscription = subscriptions[parent.id];
          if (!!subscription) {
            subscriptions = {...subscriptions};
            subscriptions[parent.id] = {...subscriptions[parent.id],entity:parent};
          }
        }
      }
      return { ...state, entities, indices, previousCacheId, ioState, parent, subscriptions };
    });
  }),
  on(contactLoadPageFailedAction,(state, action) => {
    return logContactAction("contactLoadPageFailedAction",true,state,action,()=>{
      return {
        ...state,
        error: action.error,
        loading: false
      };
    });
  }),
  on(contactUpdateAction, contactUpdateDoneAction,(state, action) => {
    return logContactAction("contactUpdateDoneAction",true,state,action,()=>{
      let index : number = state.indices[action.contact.id];
      //console.debug('CONTACT UPDATE', 'action', action, 'index', index);
      //console.log("xbug.reducer.contactUpdateDoneAction",action,state);
      const isInArray = isValidNumber(index) &&
        state.entities[index]?.version != action.contact.version;
      const isInSubscriptions =
        !!state.subscriptions[action.contact.id] &&
          state.subscriptions[action.contact.id]?.entity?.version != action.contact.version;
      if (isInArray||isInSubscriptions) {
        const contact = asContact(action.contact);
        // we artificially set timeDeleted if contact leaves group....
        if (!contact.timeDeleted &&
             Object.keys(contact.groups).map(id=>contact.groups[id]).filter(group=>!!group.ymd_ended).length>0) {
          contact.timeDeleted = contact.timeUpdated;
        }
        state = {
          ...state,
          error: null
        };
        if (isInArray) {
          let backingArray: Contact[] = (<any>state.entities).backingArray;
          let loadPageFunction: (pageIndex: number, pageSize: number) => void = (<any>state.entities).loadPageFunction();
          backingArray[index] = contact;
          state.entities = createPageLoadingProxy<Contact>(backingArray,25, loadPageFunction);
        }
        if (isInSubscriptions) {
          state.subscriptions = {
            ...state.subscriptions
          };
          state.subscriptions[contact.id] = {
            ...state.subscriptions[contact.id],
            entity:contact
          };
          //console.log("xbug.reducer.contactUpdateDoneAction.subscription",state.subscriptions[contact.id]);
        }
      }
      return state;
    });
  }),
  on(contactUpdateFailedAction,(state, action) => {
    return logContactAction("contactUpdateFailedAction",true,state,action,()=>{
      // restore previous contact.
      // we have optimistic contact updates => new contact is already in the store
      // if server reports an error have to revert the contact to its original version
      return contactReducer(
        {...state, error: action.error },
        contactUpdateDoneAction({contact:action.contact})
      );
    });
  }),
  on(contactDeleteDoneAction,(state, action) => {
    return logContactAction("contactDeleteDoneAction",true,state,action,()=>{
      let index : number = state.indices[action.contact.id];
      //console.debug('CONTACT UPDATE', 'action', action, 'index', index);
      //console.log("CONTACT_.UPDATEX",action,state);
      const isInArray = isValidNumber(index);
      const isInSubscriptions = !!state.subscriptions[action.contact.id];
      if ((isInArray||isInSubscriptions) && !!action.contact?.id && !!action.contact.timeDeleted) {
        const contact = asContact(action.contact);
        state = {
          ...state,
          selectedIndex: state.selectedIndex==index ? undefined : state.selectedIndex,
          error: null
        };
        if (isInArray) {
          let backingArray: Contact[] = (<any>state.entities).backingArray;
          let loadPageFunction: (pageIndex: number, pageSize: number) => void = (<any>state.entities).loadPageFunction();
          backingArray[index] = contact;
          state.entities = createPageLoadingProxy<Contact>(backingArray,25, loadPageFunction);
        }
        if (isInSubscriptions) {
          state.subscriptions = {
            ...state.subscriptions
          };
          state.subscriptions[contact.id] = {...state.subscriptions[contact.id], entity:contact};
        }
      }
      return state;
    });
  }),
  on(contactDeleteFailedAction,(state, action) => {
    return logContactAction("contactDeleteFailedAction",true,state,action,()=>{
      return {...state, error: action.error }
    });
  }),
  on(contactSubscribeAction,(state, action) => {
    return logContactAction("contactSubscribeAction",true,state,action,()=>{
      let subscribed = state.subscriptions[action.id];
      if (!subscribed) {
        let subscriptions = { ...state.subscriptions };
        let index  = state.indices[action.id];
        let entity = action.contact = isValidNumber(index) ? state.entities[index] : undefined;
        subscriptions[action.id] = {
          subscribed: 1,
          entity: entity ?? <Contact>{ id: action.id, version: 0 }
        };
        return {
          ...state,
          subscriptions
        }
      } else {
        action.contact = subscribed.entity;
        subscribed.subscribed++;
      }
      return state;
    });
  }),
  on(contactUnsubscribeAction,(state, action) => {
    return logContactAction("contactUnsubscribeAction",true,state,action,()=>{
      let subscribed = state.subscriptions[action.id];
      action.contact = subscribed?.entity;
      if (subscribed?.subscribed>1) {
        subscribed.subscribed--;
      } else if (!!subscribed) {
        let subscriptions = { ...state.subscriptions };
        delete subscriptions[action.id];
        return {
          ...state,
          subscriptions
        }
      }
      return state;
    });
  })
);

export function logContactAction<S,A>(name:string,log:boolean,state:S,action:A,reducer:()=>S):S {
  return logAction(name, false, state, action, reducer);
}

export const contactUplineReducer = createReducer(
  initialContactUplineState,
  on(contactUplineLoadAction,(state, action) => {
    return logContactAction("contactUplineLoadAction",true,state,action,()=>{
      return {
        ...state,
        loading: true,
      };
    });
  }),
  on(contactUplineLoadDoneAction,(state, action) => {
    return logContactAction("contactUplineLoadDoneAction",true,state,action,()=>{
      return {
        loading: false,
        uplines: {
          ...state.uplines,
          [action.contactId]: action.upline
        }
      };
    });
  }),
  on(contactUplineLoadFailedAction,(state, action) => {
    return logContactAction("contactUplineLoadFailedAction",true,state,action,()=>{
      return {
        ...state,
        loading: false
      };
    });
  })
);

export const groupAuthorsReducer = createReducer(
  initialGroupAuthorsState,
  on(groupAuthorsLoadAction,(state, action) => {
    return logContactAction("groupAuthorsLoadAction",true,state,action,()=>{
      action.force = !!state.loading ? false : action.force || state.authors[action.groupId]==undefined;
      if (action.force) {
        return {
          ...state,
          loading: true,
        };
      }
      return state;
    });
  }),
  on(groupAuthorsLoadDoneAction,(state, action) => {
    return logContactAction("groupAuthorsLoadDoneAction",true,state,action,()=>{
      return {
        loading: false,
        authors: {
          ...state.authors,
          [action.groupId]: action.authors ?? EMPTY_ARRAY
        }
      };
    });
  }),
  on(groupAuthorsLoadFailedAction,(state, action) => {
    return logContactAction("groupAuthorsLoadFailedAction",true,state,action,()=>{
      return {
        ...state,
        loading: false
      };
    });
  })
);

export const contactReducers: ActionReducerMap<ContactState> = {
  contactList: contactReducer,
  contactUpline: contactUplineReducer,
  groupAuthors: groupAuthorsReducer
};

export const contactFeatureKey = 'contact';
