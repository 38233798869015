import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  HostBinding,
  input,
  Output,
  ViewChild
} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {BasicContainerComponent, VirtualScrollerComponent} from "shared";
import {ConversationData} from "../../store/models";
import {ChatService} from "../../chat.service";

@Component({
  selector: 'chat-conversation-list',
  templateUrl: './chat-conversation-list.component.html',
  styleUrls: ['./chat-conversation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationListComponent extends BasicContainerComponent {
  @HostBinding('class.trigger') trigger: boolean = false;
  @ViewChild('list') virtualList: VirtualScrollerComponent;

  @Output() onSelectConversationId = new EventEmitter<string>();

  conversations = input.required<ConversationData[]>();

  conversations$:BehaviorSubject<ConversationData[]> = new BehaviorSubject([]);
  conversationsId$:BehaviorSubject<string> = new BehaviorSubject("please get as input param from store!!")

  //constructor(protected store$ : Store<State>, protected chatService : ChatService) {
  constructor(public chatService:ChatService) {
    super();
    effect(() => {
      this.conversations$.next(this.conversations() ?? []);
    });
    //console.info("ChatConversationListComponent.ctor");
  }

  trackByConversation(index: number, conversation: ConversationData) {
    return `${conversation.id}.${conversation.version}`;
  }

  ngOnInit() {
    //this.entities$ = this.store$.pipe(takeUntil(this.onDestroy$), select(state => state.chat.conversations.entities));
    //this.size$ = this.store$.pipe(takeUntil(this.onDestroy$), select(state => state.chat.conversations.entities.length));
    //this.taskActions.updateFilter(["open","all"]);
    super.ngOnInit();
  }

  dropForwardCallback(conversation:ConversationData):()=>Promise<void> {
    return ()=> {
      return new Promise((resolve,reject)=>{
        this.chatService.setCurrentConversationId(conversation.id)
          .then(()=>resolve())
          .catch(reject);
      });
    };
  }

  setCurrentConversationId(id:string) {
    this.chatService.setCurrentConversationId(id);
    this.onSelectConversationId.emit(id);
  }
}
