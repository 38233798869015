import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'chat-conversation-search-container',
  templateUrl: './chat-conversation-search-container.component.html',
  styleUrls: ['./chat-conversation-search-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatConversationSearchContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
