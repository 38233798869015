/*

THE SYNCHRONIZATION FRAMEWORK
=================================================

1) Prerequisites:
- The synchronization framework is a set of ngrx store features that provide
  a way to synchronize data between the client and the server.
- Entities: are the synchronized data objects.
  They are all of a certain entityType and isolated by this entityType.
  The store has identical isolated state handling for each entityType.
- Entities implement the VersionedId interface, so each entity has
  - id: a unique string identifier. its uniqueness is guaranteed
    for a given entityType by the server.
  - version: a number that is incremented by the server with each change.
    In most cases it is the timestamp of the last update.
    It is used to detect changes and to prevent conflicts.
  With a negative version, the server indicates the object was deleted.
  The same is true if the entity contains a timeDeleted property.
- ListViews: are lists of entities and exist on the server side for each
  client connection and only accessed entities or prefetched areas are
  synchronized to the client.
  Any change in the list is detected by the server and the client gets a
  notification about the change.
  The list has a version number that is incremented by the server with each
  change, and if the client requests a list, it passes -1.
  Synchronization can cope with poor network conditions:
  - Each change event has a unique version, which is the ListViews version at
    the time when the insert/update/delete operation was executed. The
    version increases on each of these changes.
  - The server keeps each change event, even those already sent to the client
    as long as the client does not send acknowledgment of the receipt of the
    event. The client rejects updates with a version number higher than the
    last received version number. This is how we handle lost change events.
  - On disconnect/connect, the client sends for all ListViews their filters
    and search term, the version and totalSize/activeSize/passiveSize.
    The server answers with the current version and sends all change events
    which occured since the client's last version.
    If the server cannot provide the list (e.g. because server was switched,
    or the list was deleted), the server compiles the list, but lets it start
    with version 0. The client has to resynchronize the list from scratch.
    **Later** a synchronization could be done just for the missing objects.
- Subscriptions: are the client's interest in a certain entity (specified by its
  entityType and id. The client can subscribe to an entity and gets notified
  about changes to the entity.

*/

import {
  EntityState,
  IoState,
  isValidNumber,
  SearchableState,
  SelectableState,
  SubscribableState,
  TypedFilterableState,
  VersionedId
} from "core";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {MoreActiveState, MorePassiveState} from "core/lib/state/more.state";

export const DEFAULT_SEGMENT_SIZE = 25;
export const DEFAULT_LOAD_MORE_SIZE = 100;
export const DEFAULT_LOAD_MORE_DELAY = 100;

export const synchronizationFeatureKey = 'synchronization';

export interface PrefetchInfo {
  moreActiveSize:number;    // number of active "more" entities (not visible ... available with "more" button)
  morePassiveSize:number;   // number of passive "more" entities (not visible ... available with "more" button)
  prefetch:'active'|'passive'|'all' // start from active size and then only when accessed, or passive side, or prefetch all
}

export interface Segment {
  index: number;  // index into active size of the list
  size: number;   // number of entities
}

export interface ListViewState {
  totalSize: number;	  // total number of entitys
  passiveSize: number;	// totalSize-activeSize = passiveSize
  activeSize: number;	  // from index (totalSize-activeSize) to index (totalSize-1)
  version: number;		  // count of changes!
}
export interface ListView extends
  SearchableState,
  TypedFilterableState,
  IoState,
  SelectableState,
  EntityState<VersionedId>,
  MoreActiveState,
  MorePassiveState {
  viewId?: string;
  entityType?: string;
  synchronized?: boolean; // true if the view is synchronized
  state?: ListViewState;
  delay?: number; // milliseconds to wait after term/filter change before loading, undefined or 0 means immediate
  internal?: any;
}

export interface SynchronizationState extends
  SubscribableState<VersionedId> {
  listViews: {[key: string]: ListView};
  entityDrafts: {[key: string]: VersionedId};
  entityFactory?:(VersionedId)=>VersionedId;
}

export interface  SynchronizationStates {
  entityStates: {[entityType: string]: SynchronizationState};
  initialized: boolean;
  connected: boolean;
  authenticated: boolean;
}

export interface State {
  readonly [synchronizationFeatureKey]: SynchronizationStates
}

export const defaultSynchronizationState:SynchronizationState = {
  subscriptions: {},
  listViews: {},
  entityDrafts: {},
  entityFactory: (entity:VersionedId)=>entity
};

export const selectState = createFeatureSelector<SynchronizationStates>(synchronizationFeatureKey);

export const selectEntityState = (entityType: string) => createSelector(
  selectState,
  (states: SynchronizationStates):SynchronizationState => states.entityStates[entityType] ?? defaultSynchronizationState
);

export const selectEntity = (entityType: string, entityId: string) => createSelector(
  selectEntityState(entityType),
  (states: SynchronizationState):VersionedId =>
    states?.subscriptions[entityId]?.entity ??
    Object.keys(states.listViews ?? {}).reduce<VersionedId | undefined>((acc, viewId) => {
      if (acc) return acc;  // If an entity has already been found, return it immediately
      // Get the index of the entity from the indices map
      const index = states.listViews[viewId].indices[entityId];
      // If the index is found, return the entity from the entities array using the index
      return isValidNumber(index) ? states.listViews[viewId].entities[index] : undefined;
    }, undefined)
);

export const selectEntityView = (entityType: string, viewId: string) => createSelector(
  selectEntityState(entityType),
  (states: SynchronizationState):ListView | undefined =>
    states?.listViews[viewId]
);

export const selectEntityDraft = (entityType: string, channelId: string) => createSelector(
  selectEntityState(entityType),
  (states: SynchronizationState):VersionedId | undefined =>
    states?.entityDrafts[channelId]
);

export const selectEntityViewFilters = (entityType: string, viewId: string) => createSelector(
  selectEntityView(entityType,viewId),
  (view: ListView):{[key:string]:string[]}|undefined =>
    view?.filters
);

export const selectEntityViewSearchTerm = (entityType: string, viewId: string) => createSelector(
  selectEntityView(entityType,viewId),
  (view: ListView):string|undefined =>
    view?.searchTerm
);

export const selectEntityViewState = (entityType: string, viewId: string) => createSelector(
  selectEntityView(entityType,viewId),
  (view: ListView):ListViewState|undefined =>
    view?.state
);
