import {
  CachedState,
  createCacheId,
  createPageLoadingArray,
  createPageLoadingProxy,
  EntityState,
  IoState,
  Logger,
  SearchableState1,
  SelectableState,
  SimpleFilterableState,
  SubscribableState,
  VersionedId
} from "core";
import isEqual from "lodash/isEqual";
import {createReducer, createSelector, on} from "@ngrx/store";
import {
  AttendeeActionIds,
  attendeeDeleteFailureAction,
  attendeeDeleteSuccessAction,
  attendeeLoadPageAction,
  attendeeLoadPageSuccessAction,
  attendeeLoadRequestAction,
  attendeeSubscribeAction,
  attendeeUnsubscribeAction,
  AttendeeUpdateAction,
  attendeeUpdateFailureAction,
  attendeeUpdateFilterAction,
  attendeeUpdateSearchTermAction,
  attendeeUpdateSuccessAction
} from "./actions";
import {Attendee} from "../../models/attendee";
import {selectCalendarState} from "../state";
import {isValidNumber} from "shared";

const logger = new Logger('Attendee.reducer');

export interface AttendeesState extends
  SearchableState1,
  SimpleFilterableState,
  IoState,
  CachedState,
  SelectableState,
  EntityState<Attendee>,
  SubscribableState<Attendee>{
  eventId: string;
}

export const initialAttendeesState: AttendeesState = {
  term: '',
  filters: [],
  entities: [],
  indices: {},
  subscriptions: {},
  currentCacheId: null,
  previousCacheId: null,
  selectedIndex: null,
  eventId: undefined,
  loading: false,
  error: null
};

export const attendeeReducer = createReducer(
  initialAttendeesState,
  on(attendeeLoadRequestAction,(state, {eventId}) => ({
    ...state,
    currentCacheId: createCacheId(),
    eventId: eventId
  })),
  on(attendeeUpdateFilterAction,(state, { filters }) =>
    isEqual(state.filters, filters)
      ? state
      : {
        ...state,
        entities: createPageLoadingArray<Attendee>(0,25, (index: number, size: number): void => {}),
        indices: {},
        filters: filters,
        currentCacheId: createCacheId()
      }
  ),
  on(attendeeUpdateSearchTermAction,(state,{term})=>
    isEqual(state.term, term)
      ? state
      : { ...state, term: term, currentCacheId: createCacheId() }
  ),
  on(attendeeLoadPageSuccessAction,(state, { cacheId, index, data, total, dispatch })=> {
    logger.info('attendeeLoadPageSuccessAction', state, cacheId, total);
    let { entities, indices, previousCacheId } = { ...state };
    const ioState = { loading: false, error: null };
    if (cacheId == state.currentCacheId && data) {
      if (state.previousCacheId != state.currentCacheId) {
        entities = createPageLoadingArray<Attendee>(
          total,
          25,
          (index: number, size: number):void => {
            dispatch(attendeeLoadPageAction({ eventId: state.eventId, cacheId, index, size }));
          });
        indices = {};
        previousCacheId = cacheId;
      } else {
        const backingArray: Attendee[] = (<any>entities).backingArray ?? [];
        const loadPageFunction: (pageIndex: number, pageSize: number) => void = (
          (<any>state.entities).loadPageFunction ||
          (() => ((pageIndex:number, pageSize:number): void => {}))
        )() ;
        entities = createPageLoadingProxy<Attendee>(backingArray,25, loadPageFunction)
      }
      entities['loaded'] = Math.max(entities['loaded'] || 0, index + data.length);
      for (let i=0, max=data.length; i<max; i++) {
        entities[index+i] = new Attendee(data[i]);
        indices[data[i].id] = index+i;
      }
    }
    return { ...state, entities, indices, previousCacheId, ioState };
  }),
  {
    types: [AttendeeActionIds.UPDATE],
    reducer: (state, action: AttendeeUpdateAction) => {
      // optimistic update. if server reports an error we rollback - see AttendeeService.save()
      return attendeeReducer(state, attendeeUpdateSuccessAction({
        attendee: action.attendee, correlationId: action.correlationId
      }));
    }
  },
  on(attendeeUpdateSuccessAction,(state, { attendee }) => {
    let index: number = state.indices[attendee.id];
    logger.debug(attendeeUpdateSuccessAction.type, 'index', index);
    if (index !== undefined) {
      let backingArray : Attendee[] = (<any>state.entities).backingArray;
      let loadPageFunction : (pageIndex:number,pageSize:number)=>void = (<any>state.entities).loadPageFunction();
      state = {
        ...state,
        entities: createPageLoadingProxy<Attendee>(backingArray,25, loadPageFunction),
        error: null
      };
      state.entities[index] = attendee instanceof Attendee ? attendee : new Attendee(attendee);
    }
    return state;
  }),
  on(attendeeUpdateFailureAction,(state, { attendee, reason,  correlationId }) => {
    logger.error(attendeeUpdateFailureAction.type);
    if (!attendee.isNew) {
      // restore previous attendee.
      // we have optimistic attendee updates => new attendee is already in the store
      // if server reports an error have to revert the attendee to its original version
      return attendeeReducer(
        {...state, error: reason },
        attendeeUpdateSuccessAction({ attendee, correlationId })
      );
    }  else {
      // failed to create new attendee -> keep the current state
      return state;
    }
  }),
  on(attendeeDeleteSuccessAction,(state, { attendee }) => {
    let index: number = state.indices[attendee.id];
    if (index !== undefined) {
      let backingArray: Attendee[] = (<any>state.entities).backingArray;
      let loadPageFunction: (index: number, size: number) => void = (<any>state.entities).loadPageFunction();
      // TODO: remove from store!!! - For now the store is just reloaded - see effects
      state = {
        ...state,
        entities: createPageLoadingProxy<Attendee>(backingArray,25, loadPageFunction),
        error: null,
        selectedIndex: state.selectedIndex==index ? undefined : state.selectedIndex
      };
      state.entities[index] = attendee instanceof Attendee ? attendee : new Attendee(attendee);
    }
    return state;
  }),
  on(attendeeDeleteFailureAction,(state, { attendee, reason }) => {
    return {...state, error: reason }
  }),
  on(attendeeSubscribeAction,(state, action) => {
    let subscribed = state.subscriptions[action.id];
    if (!subscribed) {
      let subscriptions = { ...state.subscriptions };
      let index  = state.indices[action.id];
      let entity = action.attendee = isValidNumber(index) ? state.entities[index] : undefined;
      subscriptions[action.id] = {
        subscribed: 1,
        entity:entity
      };
      return {
        ...state,
        subscriptions
      }
    } else {
      action.attendee = subscribed.entity;
      subscribed.subscribed++;
    }
    return state;
  }),
  on(attendeeUnsubscribeAction,(state, action) => {
    let subscribed = state.subscriptions[action.id];
    action.attendee = subscribed?.entity;
    if (subscribed?.subscribed>1) {
      subscribed.subscribed--;
    } else if (!!subscribed) {
      let subscriptions = { ...state.subscriptions };
      delete subscriptions[action.id];
      return {
        ...state,
        subscriptions
      }
    }
    return state;
  })
);

export const selectAttendeesState     = createSelector(selectCalendarState, state => state.attendees);
export const selectAttendeeEntities   = createSelector(selectAttendeesState, state => state.entities);
export const selectAttendeeEntitiesLength     = createSelector(selectAttendeeEntities, state => state.length);
export const selectAttendeeFilters    = createSelector(selectAttendeesState, state => state.filters);
export const selectAttendeeSearchTerm = createSelector(selectAttendeesState, state => state.term);
export const selectAttendeeCacheId    = createSelector(selectAttendeesState, state => state.currentCacheId);

export const selectAttendee = (attendeeId: string) => createSelector(
  selectAttendeesState,
  (state: AttendeesState): Attendee => {
    let index = state.indices[attendeeId];
    return index >= 0 && index < state.entities.length ? state.entities[index] : null;
  }
);

export const selectAttendeesSubscribed = createSelector(
  selectAttendeesState,
  state => Object
    .keys(state.subscriptions)
    .map(id=><VersionedId>{
      id:id,
      version:state.subscriptions[id]?.entity?.version??0
    })
);

