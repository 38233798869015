import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';

import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "material";
import {SharedModule} from "shared";
import {FormsModule} from "@angular/forms";
import {ConferenceStoreModule} from "./store/module";
import {MatDialogModule} from "@angular/material/dialog";
import {MomentModule} from "ngx-moment";
import {LayoutModule} from "layout";
import {SessionModule} from "session";
import {ContactModule} from "contact";
import {MatDividerModule} from '@angular/material/divider';
import {ConferenceStatusBarComponent} from "./components/conference-status-bar/conference-status-bar.component";
import {ConferenceBarComponent} from "./components/conference-bar/conference-bar.component";
import {ConferencePanelComponent} from "./components/conference-panel/conference-panel.component";
import {ConferenceParticipantComponent} from "./components/conference-participant/conference-participant.component";
import {ConferenceParticipantIndicatorComponent} from "./components/conference-participant/conference-participant-indicator.component";
import {ConferencePanelOverlayService} from "./components/conference-panel/conference-panel-overlay.service";
import {PresenceModule} from "presence";

export const PROVIDERS = [
  // ConferenceService,
  ConferencePanelOverlayService
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MaterialModule,
    MatDialogModule,
    FormsModule,
    SharedModule,
    PresenceModule,
    ConferenceStoreModule,
    MomentModule,
    LayoutModule,
    SessionModule,
    ContactModule,
    MatDividerModule
  ],
  exports: [
    ConferenceStatusBarComponent,
    ConferenceBarComponent,
    // ConferencePanelComponent,
    // ConferenceParticipantComponent,
    // ConferenceParticipantIndicatorComponent
  ],
  declarations: [
    ConferenceStatusBarComponent,
    ConferenceBarComponent,
    ConferencePanelComponent,
    ConferenceParticipantComponent,
    ConferenceParticipantIndicatorComponent
  ],
  providers: PROVIDERS
  /*,
  entryComponents: [
    ConferencePanelComponent
  ]
  */
})
export class ConferenceModule {}
