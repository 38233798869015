/* angular:styles/component:css;17c37ddf2c40c1c0748236d8bee1332109f926d609b7db433fa09de12a895262;/Users/mbratoev/Project/fbgroup/client.ng/projects/chat/src/lib/components/attachment/recording/square/audio-recording-attachment-square-viewer.component.ts */
mat-icon {
  padding: 2px;
  width: 36px;
  height: 36px;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff40;
}
:host-context(.left) > mat-icon {
  color: #00000040;
}
:host-context(.color-scheme-light.pure) > mat-icon {
  color: #ffffff80;
}
:host-context(.color-scheme-dark) > mat-icon {
  color: #ffffff40;
}
/*# sourceMappingURL=audio-recording-attachment-square-viewer.component-ABRZDPVD.css.map */
