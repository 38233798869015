<ng-container *ngLet="{
    activeCalls:activeCalls$ | async,
    activeShare:activeShare$ | async,
    pendingCalls:pendingCalls$ | async
  } as current">
  <app-status-bar (click)="openConferencePanel()">
    <div *ngIf="current.activeCalls">
      <span class="number">{{current.activeCalls}}</span><span class="label" translate>chat.conference.active</span>
      <div class="icons">
        <mat-icon *ngIf="!!current?.activeShare?.audio">volume_up</mat-icon>
        <mat-icon *ngIf="!current?.activeShare?.audio" class="off">volume_off</mat-icon>
        <mat-icon *ngIf="!!current?.activeShare?.video">videocam</mat-icon>
        <mat-icon *ngIf="!current?.activeShare?.video" class="off">videocam_off</mat-icon>
      </div>
      <div class="screen" *ngIf="!!current?.activeShare?.screen"><mat-icon *ngIf="!!current?.activeShare?.audio">ios_share</mat-icon><span translate>chat.conference.screen</span></div>
    </div>
    <div style="flex:1;"></div>
    <div *ngIf="current.pendingCalls"><span class="number">{{current.pendingCalls}}</span><span translate>chat.conference.pending</span></div>
  </app-status-bar>
  <div class="up-arrow" (click)="openConferencePanel()">
    <mat-icon>expand_less</mat-icon>
  </div>
</ng-container>

