import {NgModule} from "@angular/core";
import {StripeOverlayComponent} from "./stripe/stripe-overlay.component";
import {StripeComponent} from "./stripe/stripe.component";
import {MaterialModule} from "material";
import {PipesModule} from "shared";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {PaymentComponent} from "./components/payment.component";
import {InAppPurchase2} from "@ionic-native/in-app-purchase-2/ngx";
import {PaymentService} from "./service/payment.service";
import {PaymentServiceFactory} from "./service/payment-service.factory";
import {PropertiesService} from "properties";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ENVIRONMENT} from "core";
import {ProductSelector} from "./service/product-selector.service";

export function paymentServiceFactory(paymentServiceFactory: PaymentServiceFactory,
                                      propertiesService: PropertiesService,
                                      productSelector: ProductSelector,
                                      environment: any): PaymentService {
  return new PaymentService(paymentServiceFactory.get(), propertiesService, productSelector, environment);
}

export const PROVIDERS = [
  InAppPurchase2,
  PaymentServiceFactory,
  ProductSelector,
  {
    provide: PaymentService,
    deps: [PaymentServiceFactory, PropertiesService, ProductSelector, ENVIRONMENT],
    useFactory: paymentServiceFactory
  }
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    PaymentComponent,
    StripeComponent,
    StripeOverlayComponent
  ],
  exports: [
    PaymentComponent
  ],
  providers: PROVIDERS
  /*,
  entryComponents: [
    StripeOverlayComponent
  ]
  */
})
export class PaymentModule {}

