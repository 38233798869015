import {ComponentFactoryResolver, ComponentRef, Injectable, Injector} from "@angular/core";
import {AttachmentComponent} from "./core/attachment.component";
import {MediaAttachmentCoverViewerComponent} from "./media/viewer/media-attachment-cover-viewer.component";
import {MediaAttachmentComposerComponent} from "./media/composer/media-attachment-composer.component";
import {Attachment} from "messaging";
import {AudioRecordingAttachmentViewerComponent} from "./recording/viewer/audio-recording-attachment-viewer.component";
import {GenericAttachmentViewerComponent} from "./core/generic.attachment.viewer.component";
import {MediaAttachment} from "../../store/models";
import {MediaAttachmentFileViewerComponent} from "./media/viewer/media-attachment-file-viewer.component";
import {MediaAttachmentSquareViewerComponent} from "./media/square/media-attachment-square-viewer.component";
import {AudioRecordingAttachmentSquareViewerComponent} from "./recording/square/audio-recording-attachment-square-viewer.component";

export abstract class AttachmentComponentFactory {
  abstract isSingleAttachmentView():boolean;
  abstract canHandle(attachment:Attachment):boolean;
  abstract createComponent(injector : Injector, resolver: ComponentFactoryResolver):ComponentRef<AttachmentComponent>;
}

@Injectable(
  {providedIn: 'root'}
)
export class AttachmentComponentFactoryService {

  protected composerFactories: {[key:string]:AttachmentComponentFactory|AttachmentComponentFactory[]} = {};
  protected viewerFactories: {[key:string]:AttachmentComponentFactory|AttachmentComponentFactory[]} = {};
  protected squareFactories: {[key:string]:AttachmentComponentFactory|AttachmentComponentFactory[]} = {};

  constructor() {
    this.composerFactories['media']          = this.createMediaComposerFactory();
    this.squareFactories['media']            = this.createMediaSquareViewerFactory();
    this.squareFactories['audioRecording']   = this.createAudioRecordingSquareViewerFactory();
    this.viewerFactories['media']            = [ this.createMediaCoverViewerFactory(),
                                                 this.createMediaFileViewerFactory() ];
    this.viewerFactories['audioRecording']   = this.createAudioRecordingViewerFactory();
    // not implemened:
    this.composerFactories['audioRecording'] = this.createGenericAttachmentViewerFactory();
  }

  getEditorFactory(type:string,attachment?:Attachment):AttachmentComponentFactory {
    return undefined;
  }

  getViewerFactory(type:string,attachment?:Attachment):AttachmentComponentFactory {
    return this.getFactory(type,attachment,this.viewerFactories);
  }

  // square component shows only one (or summary) attachment in square shape.
  // events: none, view only
  // usecase: reply message view
  getSquareFactory(type:string,attachment?:Attachment):AttachmentComponentFactory {
    return this.getFactory(type,attachment,this.squareFactories);
  }

  getComposerFactory(type:string,attachment?:Attachment):AttachmentComponentFactory {
    return this.getFactory(type,attachment,this.composerFactories);
  }

  protected getFactory(
    type:string,
    attachment?:Attachment,
    factories?:{[key:string]:AttachmentComponentFactory|AttachmentComponentFactory[]})
    : AttachmentComponentFactory {
    const sameTypeFactories = factories[type];
    return Array.isArray(sameTypeFactories) ?
      sameTypeFactories.find(factory=>factory.canHandle(attachment)) :
      sameTypeFactories.canHandle(attachment) ? sameTypeFactories : undefined;
  }

  createMediaComposerFactory():AttachmentComponentFactory {
    //const resolver = this.resolver;
    return new class extends AttachmentComponentFactory {
      createComponent(injector : Injector, resolver: ComponentFactoryResolver):ComponentRef<AttachmentComponent> {
        return resolver
          .resolveComponentFactory(MediaAttachmentComposerComponent)
          .create(injector);
      }
      canHandle(attachment: Attachment): boolean {
        return !!(<MediaAttachment>attachment)?.media;
      }
      isSingleAttachmentView(): boolean {
        return false;
      }
    }
  }

  createMediaSquareViewerFactory():AttachmentComponentFactory {
    return new class extends AttachmentComponentFactory {
      createComponent(injector : Injector, resolver: ComponentFactoryResolver):ComponentRef<AttachmentComponent> {
        return resolver
          .resolveComponentFactory(MediaAttachmentSquareViewerComponent)
          .create(injector);
      }
      canHandle(attachment: Attachment): boolean {
        const media = (<MediaAttachment>attachment)?.media;
        return !!media;
        /*
        return !!media &&
          ((media.cover?.width>0 && media.cover.height>0) ||
           (media.links?.length>0 &&
          !!media.links.find(link=>link.type==='image' && link.width>0 && link.height>0)));*/
      }
      isSingleAttachmentView(): boolean {
        return true;
      }
    }
  }

  createMediaCoverViewerFactory():AttachmentComponentFactory {
    return new class extends AttachmentComponentFactory {
      createComponent(injector : Injector, resolver: ComponentFactoryResolver):ComponentRef<AttachmentComponent> {
        return resolver
          .resolveComponentFactory(MediaAttachmentCoverViewerComponent)
          .create(injector);
      }
      canHandle(attachment: Attachment): boolean {
        const media = (<MediaAttachment>attachment)?.media;
        return !!media &&
          ((media.cover?.width>0 && media.cover.height>0) ||
           (media.links?.length>0 &&
          !!media.links.find(link=>link.type=='image' && link.width>0 && link.height>0)));
      }
      isSingleAttachmentView(): boolean {
        return false;
      }
    }
  }

  createMediaFileViewerFactory():AttachmentComponentFactory {
    return new class extends AttachmentComponentFactory {
      createComponent(injector : Injector, resolver: ComponentFactoryResolver):ComponentRef<AttachmentComponent> {
        return resolver
          .resolveComponentFactory(MediaAttachmentFileViewerComponent)
          .create(injector);
      }
      canHandle(attachment: Attachment): boolean {
        return !!(<MediaAttachment>attachment)?.media;
      }
      isSingleAttachmentView(): boolean {
        return false;
      }
    }
  }

  createAudioRecordingSquareViewerFactory():AttachmentComponentFactory {
    return new class extends AttachmentComponentFactory {
      createComponent(injector : Injector, resolver: ComponentFactoryResolver):ComponentRef<AttachmentComponent> {
        return resolver
          .resolveComponentFactory(AudioRecordingAttachmentSquareViewerComponent)
          .create(injector);
      }
      canHandle(attachment: Attachment): boolean {
        return true;
      }
      isSingleAttachmentView(): boolean {
        return true;
      }
    }
  }
  createAudioRecordingViewerFactory():AttachmentComponentFactory {
    return new class extends AttachmentComponentFactory {
      createComponent(injector : Injector, resolver: ComponentFactoryResolver):ComponentRef<AttachmentComponent> {
        return resolver
          .resolveComponentFactory(AudioRecordingAttachmentViewerComponent)
          .create(injector);
      }
      canHandle(attachment: Attachment): boolean {
        return true;
      }
      isSingleAttachmentView(): boolean {
        return true;
      }
    }
  }

  createGenericAttachmentViewerFactory():AttachmentComponentFactory {
    return new class extends AttachmentComponentFactory {
      createComponent(injector : Injector, resolver: ComponentFactoryResolver):ComponentRef<AttachmentComponent> {
        return resolver
          .resolveComponentFactory(GenericAttachmentViewerComponent)
          .create(injector);
      }
      canHandle(attachment: Attachment): boolean {
        return true;
      }
      isSingleAttachmentView(): boolean {
        return false;
      }
    }
  }
}
