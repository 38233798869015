import {AttachmentComponent} from "../../core/attachment.component";
import {ChatTimelineMessage, MediaAttachment} from "../../../../store/models"
import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";
import {Attachment} from "messaging";
import {Media, MediaAction, MediaViewerOverlayService} from "media";
import {isValidNumber} from "shared";
import {Space} from "../../core/space";

@Component({
  selector: 'W9GBQdfwrpHiN8z',  // not of interest
  template: '',
  styles: []
})
export abstract class MediaAttachmentComponent implements AttachmentComponent {
  mediaAttachments:MediaAttachment[] = [];

  set message(message:ChatTimelineMessage) {
  }

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    //console.log("set attachments",attachments);
    this.mediaAttachments = [];
    attachments?.forEach(attachment=>{
      if (!!(<any>attachment).media) {
        this.mediaAttachments.push(<MediaAttachment>attachment);
      }
    });
    this.changeDetectorRef.markForCheck();
  }

  get attachments():Attachment[] {
    //console.log("get attachments =>",this.mediaAttachments);
    return this.mediaAttachments;
  }

  get space(): Space {
    return undefined;
  }

  constructor(
    protected mediaViewerOverlayService: MediaViewerOverlayService,
    protected changeDetectorRef: ChangeDetectorRef) {
  }

  onPlayMedia(media: any) {
    let medias = this.attachments.map(attachment=>(<MediaAttachment>attachment).media).filter(media=>!!media);
    let valid  = (index?:number)=> isValidNumber(index) && index>=0 && index<medias.length;
    let index  = medias.findIndex(m=>m.id==media.id);
    this.mediaViewerOverlayService.openBottomSheet({
      media:(newIndex?:number)=>{
        return valid(newIndex) ? medias[newIndex] : medias[index];
      },
      index:(newIndex?:number)=>{
        return index = valid(newIndex) ? newIndex : index;
      },
      total:()=>medias.length,
      outer:(select?:boolean)=>undefined,
      // actionsTemplate: isProjectMedia ? this.mediaActions : null,
      onAction: (action: MediaAction) => {
      },
      onComplete: (media: Media) => {}
    });
  }

  get deleted(): EventEmitter<Attachment> | undefined {
    return undefined;
  }

  get updated(): EventEmitter<AttachmentComponent> | undefined {
    return undefined;
  }
}
