import {Sound} from "shared";

export class ChatSounds {
  protected initialized = false;
  constructor() {
  }

  public async initialize(): Promise<void> {
    if (!this.initialized) {
      this.initialized = true;
      return Promise.all([
        this.messageOut().initialize(),
        this.messageIn().initialize()
      ]).then((result) => void result);
    }
    return Promise.resolve();
  }

  protected _messageOut:Sound;
  messageOut():Sound {
    return this._messageOut = this._messageOut ?? new Sound("/assets/sounds/chat_outgoing.mp3");
  }

  protected _messageIn:Sound;
  messageIn():Sound {
    return this._messageIn = this._messageIn ?? new Sound("/assets/sounds/chat_incoming.mp3");
  }
}
