/* projects/chat/src/lib/components/conversation-container/chat-conversation-container.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: white;
  z-index: 1;
  margin-bottom: -8px;
}
:host(.readonly) {
  margin-bottom: 0;
}
:host > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
:host > div > div.drop-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
  overflow: hidden;
}
:host > div.dropForwardingTarget > div.drop-container,
:host > div.dropFilesOver > div.drop-container {
  display: flex;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  justify-content: center;
  align-items: center;
}
:host > div.dropForwardingTarget > div.drop-container > div,
:host > div.dropFilesOver > div.drop-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  padding: 32px;
  background-color: white;
  border: 1px solid #ddd;
  color: var(--color-primary);
}
:host > div.dropForwardingTarget > div.drop-container > div > div,
:host > div.dropFilesOver > div.drop-container > div > div {
  display: flex;
}
:host > div.dropForwardingTarget > div.drop-container > div > mat-icon,
:host > div.dropFilesOver > div.drop-container > div > mat-icon {
  width: 128px;
  height: 128px;
  display: flex;
  font-size: 128px;
}
mat-icon.toggle {
  transform: rotate(0);
  transition: transform 300ms;
  margin-right: 12px;
}
mat-icon.toggle.close {
  transform: rotate(180deg);
}
presence-info {
  display: inline-block;
  font-size: 12.6px;
  line-height: 1em;
  font-weight: lighter;
}
.title-container {
  width: 100%;
  overflow: hidden;
  display: flex;
}
.title-and-status {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  left: 0;
  overflow: hidden;
  transform: translateX(0);
  transition: transform 300ms ease, left 300ms ease;
}
.title-and-status > * {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0;
}
.title-and-status.centered {
  left: calc(50% + 20px);
  transform: translateX(-50%);
  text-align: center;
}
.features-container {
  position: relative;
  overflow: hidden;
  max-height: 0;
  transition: max-height 300ms ease;
}
app-search-bar.hasFeatures .features-container {
  position: relative;
  overflow: hidden;
  max-height: 100px;
}
.features {
  display: flex;
  flex-direction: row;
  background-color: var(--color-primary);
  color: gray;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 8px;
}
.content {
  position: relative;
  flex: 1 1 auto;
}
chat-conversation {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
:host-context(.color-scheme-light.pure) .features {
  background-color: white;
}
:host-context(.color-scheme-dark) {
  background-color: #181818;
}
:host-context(.color-scheme-dark) .features {
  background-color: #282828;
}
:host-context(.color-scheme-dark) .features > div {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .features > div > button {
  background-color: rgba(255, 255, 255, 0.062745098);
}
:host-context(.color-scheme-dark) > div > div.drop-container > div {
  background-color: #282828;
  color: #AEAFB0;
  border-color: #AEAFB0;
}
/*# sourceMappingURL=chat-conversation-container.component-H3WN3OQJ.css.map */
