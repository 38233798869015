import {Action, createAction, props} from "@ngrx/store";
import {
  ChatTimelineMessage,
  ChatTimelineMessageInfo,
  ConversationDefinition,
  ConversationFilter,
  ConversationHeader,
  ConversationSegment,
  ConversationSegmentData,
  DeliveryMessage,
  TypingMessage
} from "./models";
import {IndexedArrayHooks} from "core";

/*
  CONVERSATIONS LIST
 */

export const conversationsLoadRequestAction = createAction(
  'chat.conversations.load.request',
  props<{
    filter?: Partial<ConversationFilter>,   // updated by reducer (e.g. if new filterId necessary)
    newestMessageVersion?: number
  }>()
);

export const conversationsLoadResultAction = createAction(
  'chat.conversations.load.result',
  props<{
    filter?: ConversationFilter,
    size?: number,     // filtered size, or total size if no filter
    hash?: number,     // filtered hash, or total hash if no filter
    newestVersion?: number,
    oldestVersion?: number,
    newestMessageVersion: number
  }>()
);

export const setDraftMessageAction = createAction(
  'chat.conversations.set.draftMessage',
  props<{
    conversationId?: string,
    draftMessage: ChatTimelineMessage}>()
);

export const setFilteredMessageAction = createAction(
  'chat.conversations.set.filteredMessage',
  props<{
    conversationId?: string,
    filteredMessage: ChatTimelineMessage}>()
);

export const setReplyMessageAction = createAction(
  'chat.conversations.set.replyMessage',
  props<{
    conversationId?: string,
    replyMessage?: ChatTimelineMessage}>()
);

export const setTypingMessageAction = createAction(
  'chat.conversations.set.typingMessage',
  props<{
    conversationId?: string,
    typingMessage: TypingMessage}>()
);

export const setCurrentConversationIdAction = createAction(
  'chat.conversations.set.currentConversationId',
  props<{
    conversationId?: string  // conversationId if creation was successful
  }>()
);

export const addQueueMessagesAction = createAction(
  'chat.conversations.queue.add',
  props<{
    messages: ChatTimelineMessage[]
  }>()
);

export const removeQueueMessagesAction = createAction(
  'chat.conversations.queue.remove',
  props<{
    messages: ChatTimelineMessage[]
  }>()
);

/*
export const participantsLoadAction = createAction(
  'chat.conversation.participants.load',
  props<{
    readonly conversationId: string,
    readonly participants$:Subject<Participant[]>,
    loaded:boolean
  }>()
);

export const participantUpdateAction = createAction(
  'chat.conversation.participant.update',
  props<{
    readonly conversationId: string,
    readonly participant:Participant,
    readonly callback:(Participant)=>void, // result
    loaded:boolean
  }>()
);*/
/*
  CREATE CONVERSATION
 */

export const conversationCreateOrUpdateAction = createAction(
  'chat.conversation.create.update',
  props<{
    readonly conversation: Pick<ConversationDefinition,"id"|"type"|"name"|"term">,   // type: 'direct', 'group', 'resource'...
    readonly participantIds?: string[]   // group chat: ids of all participants if no term..
    readonly updatedIds?: string[]       // group chat: ids of all participants which where maniplated..
    readonly resourceId?: string,        // for resource type: remarks of one user for one resource....
    readonly temporary?: boolean,        // maybe for notes, but not loaded in chat conversations list....
    readonly onSuccess: (header:ConversationHeader) => IndexedArrayHooks<ChatTimelineMessage>; // callback onSuccess
    readonly onError: (errorLabel:string)=>void;    // callback onError
  }>()
);

export const showErrorAction = createAction(
  'chat.error',
  props<{
    readonly errorLabel: string         // error label if not ok
  }>()
);

/*
  CURRENT CONVERSATION
 */

export const conversationFilterAction = createAction(
  'chat.conversation.filter',
  props<{
    readonly conversation: Partial<ConversationDefinition>, // the current conversation
    readonly conversationId: string,      // the current conversation
    readonly filter?: ConversationFilter, // filtered loading
    readonly newestTime: number,          // backward from this time....
    readonly hooks: IndexedArrayHooks<ChatTimelineMessage>,
    readonly dispatch: (action:Action)=>void,
    // enrichted by reducer before effect handles it
    segments: ConversationSegment[],  // all client side conversation segments synchronized...
  }>() // see LoadConversationSegmentChunk,LoadConversationSegment,LoadConversationNewestChunk,VerifyConversationSegment
);

export const conversationFilterResultAction = createAction(
  'chat.conversation.filter.result',
  props<{
    readonly conversation: ConversationDefinition, // the current conversation
    readonly filter?: ConversationFilter, // filtered loading
    readonly newestTime: number,          // backward from this time....
    readonly segments: ConversationSegment[],  // all client side conversation segments synchronized...
    readonly messages: (ChatTimelineMessage|ChatTimelineMessageInfo)[],   // the requested (whole for unfilterd, partly for filtered) chunk of messages or message ids
    readonly leadingSize: number,         // number of filtered messages before the segment
    readonly trailingSize: number,        // number of filtered messages after the segment
    readonly hooks: IndexedArrayHooks<ChatTimelineMessage>,
    readonly dispatch: (action:Action)=>void,
  }>()
);

export const conversationSynchronizeSegmentAction = createAction(
  'chat.conversation.synchronize.segment',
  props<{
    readonly conversation: Partial<ConversationDefinition>, // the current conversation
    readonly hooks: IndexedArrayHooks<ChatTimelineMessage>,
    readonly dispatch: (action:Action)=>void,
    // enrichted by reducer before effect handles it
    current?: Partial<ConversationSegmentData>,  // current segment or undefined
    segments?: ConversationSegment[],    // currently loaded conversation segments...
  }>()
);

export const conversationUpdateSegmentAction = createAction(
  'chat.conversation.update.segment',
  props<{
    readonly conversation: ConversationDefinition, // the current conversation
    readonly current: Partial<ConversationSegmentData>,  // requested segment or the newest segment if it was undefined
    readonly segments: ConversationSegment[],   // all segments passed in request updated by server
    readonly timeReceived?: number;    // last received time (one, some or all participants ... depending on chat type)
    readonly timeViewed?: number;      // last viewed time (one, some or all participants ... depending on chat type)
    readonly timeSelfReceived?: number;// last received time of this user....
    readonly timeSelfViewed?: number;  // last viewed time of this user....
    readonly hooks: IndexedArrayHooks<ChatTimelineMessage>,
    readonly dispatch: (action:Action)=>void
  }>()         // the number of messages before segment...
);

export const attachmentPayloadRequestAction = createAction(
  'chat.attachment.payload.request',
  props<{
    conversationId: string,
    messageId: string,
    callback: (AudioRecordingData)=>void
  }>()
);

export const attachmentPayloadResultAction = createAction(
  'chat.attachment.payload.result',
  props<{
    conversationId: string,
    messageId: string,
    data?: string,
    wave?: string}>()
);

export const conversationMuteAction = createAction(
  'chat.conversations.mute',
  props<{
    conversationId?: string,
    timeMuted?: number}>()    // undefined = unmuted, else time of muting...
);

export const conversationUpdateFiltersAndTermAction = createAction(
  'chat.conversation.update.filtersAndTerm',
  props<{
    readonly conversationId: string,      // reset filter means only conversationId is set...
    readonly filter?: Partial<ConversationFilter>  // the filter or undefined
  }>()
);

/*
  TIMELINE MESSAGES
 */

export const sendChatTimelineMessageAction = createAction(
  'chat.conversation.message.send',
  props<{
    readonly message: ChatTimelineMessage,
    readonly callback: (boolean)=>void
  }>()
);

export const receiveChatTimelineMessageAction = createAction(
  'chat.conversation.message.receive',
  props<{
    readonly message: ChatTimelineMessage,
    readonly headers?: {conversation:ConversationHeader},
    readonly temporary?: boolean, // maybe for notes, but not loaded in chat conversations list....
    readonly hooks: IndexedArrayHooks<ChatTimelineMessage>,
    readonly dispatch: (action:Action)=>void
  }>()
);

export const triggerTimeViewedAction = createAction(
  'chat.conversation.trigger.time.viewed',
  props<{
    readonly conversationId: string,      // reset filter means only conversationId is set...
    readonly timeViewed: number,
    send:boolean
  }>()
);

export const receiveDeliveryMessageAction = createAction(
  'chat.conversation.delivery.receive',
  props<{
    readonly message: DeliveryMessage
  }>()
);
/*
  STATE
 */

export const setParticipantAction = createAction(
  'chat.set.participant',
  props<{
    readonly participantId?: string
  }>()
);

export const channelOpenStateAction = createAction(
  'chat.channel.open.state',
  props<{
    readonly open: boolean
  }>()
);

export const testAction = createAction(
  'test',
  props<{
    reducer: boolean,
    effect: boolean
  }>()
);

export const setErrorAction = createAction(
  'chat.error.set',
  props<{
    readonly label?: string,
    readonly params: {[key:string]:any}
  }>()
);
