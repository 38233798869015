/* projects/calendar/src/lib/components/attendee-list/attendee-list.component.scss */
:host {
  display: flex;
}
app-contact-list {
  flex: 1;
}
presence {
  position: absolute;
  right: 0;
  bottom: 0;
}
presence-info {
  display: block;
  font-size: 70%;
  line-height: 1em;
  font-weight: lighter;
}
.seats {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.seats mat-icon {
  color: gray;
  vertical-align: middle;
}
.seats mat-icon + span {
  margin-left: 6px;
  font-size: 14px;
}
.seats ~ .seats {
  margin-left: 8px;
}
:host-context(.color-scheme-dark) mat-icon {
  color: #AEAFB0;
}
/*# sourceMappingURL=attendee-list.component-HAN5U3WR.css.map */
