<!--<mat-action-list dense>-->
<mat-action-list>
  <ng-container *ngFor="let contact of contacts | async; let index = index; let first = first; let last = last;">
    <mat-list-item class="contact"
                   [class.selected]="index===selectedIndex"
                   (click)="selectContact(contact, index)">
        <!--<img matListItemAvatar [src]="photoUrl(contact)"/>-->
        <div matListItemAvatar class="picture" [style.background-color]="color(index)">{{initials(contact, index)}}</div>
        <div class="details">
          <h3 *ngIf="contact.name" class="name">
            {{contact.name.givenName || ''}} {{contact.name.familyName || ''}}
          </h3>
          <h4 class="links">
            <div *ngIf="contact?.phoneNumbers?.length>0">{{contact.phoneNumbers[0].value}}</div>
            <div *ngIf="contact?.emails?.length>0">{{contact.emails[0].value}}</div>
          </h4>
        </div>
    </mat-list-item>
    <mat-divider *ngIf="!last"></mat-divider>
  </ng-container>
</mat-action-list>
