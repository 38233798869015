import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  INJECTOR,
  Injector,
  Input
} from '@angular/core';
import 'moment-duration-format';
import {ENVIRONMENT, I18nService} from "core";
import {AuthenticationService} from "auth";
import {AttachmentComponentFactoryService} from "../../attachment/attachment.component.factory.service";
import {ChatService} from "../../../chat.service";
import {LayoutService} from "layout";
import {ChatMessageCoreComponent} from "../chat-message-container/chat-message-core.component";
import {CallStatusMessage} from "../../../store/models";
import {BehaviorSubject, switchMap, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'call-info-message',
  templateUrl: './call-info-message.component.html',
  styleUrls: ['./call-info-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallInfoMessageComponent extends ChatMessageCoreComponent {

  public time$ = new BehaviorSubject<string>('');
  public view$ = new BehaviorSubject<'canceled'|'running'|'finished'>(undefined);
  protected _seconds:string = '{{time}} Seconds';
  protected _minutes:string = '{{time}} Minutes';
  protected _hours:string = '{{time}} Hours';
  protected _difference:number = 0;

  constructor(public authenticationService: AuthenticationService,
              public elementRef: ElementRef,
              public chatService: ChatService,
              public layoutService:LayoutService,
              public attachmentService: AttachmentComponentFactoryService,
              public translateService: TranslateService,
              public i18nService: I18nService,
              public resolver: ComponentFactoryResolver,
              @Inject(INJECTOR) public injector:Injector,
              @Inject(ENVIRONMENT) public environment: any) {
    super(authenticationService, elementRef, chatService, layoutService, attachmentService, resolver, injector, environment);
    const labels = ['chat.conference.info.seconds','chat.conference.info.minutes','chat.conference.info.hours'];
    this.i18nService.language$.pipe(
      takeUntil(this.onDestroy$),
      switchMap(language=>this.translateService.get(labels))
    ).subscribe(strings=>{
      //console.log("STRINGS",strings);
      this._seconds = strings[labels[0]] ?? this._seconds;
      this._minutes = strings[labels[1]] ?? this._minutes;
      this._hours   = strings[labels[2]] ?? this._hours;
      this.updateCallTime();
    });
  }

  @Input()
  set message(message: CallStatusMessage) {
    super.message = message;
    const hasParticipants = message?.participants?.length>1;
    const isEnded = !!message?.timeEnded;
    //const isStarted = !!message?.timeStarted;
    //const isScheduled = !!message?.timeScheduled && message.timeScheduled>Date.now();
    const view:'canceled'|'running'|'finished' =
          hasParticipants && isEnded ? 'finished' :
          hasParticipants ? 'running' :
            isEnded ? 'canceled' : 'running';
    /*if (message.participants?.length>1) {

    } else {
      console.log("MESSAGE",message);
    }*/
    if (this.view$.value!=view) {
      this.view$.next(view);
    }
    this._difference = isEnded ? message.timeEnded-message.timeStarted : undefined;
    this.updateCallTime();
    //moment.duration()
    //this._time = (message.timeEnded)
  }

  get message():CallStatusMessage {
    //console.log("message",super.message);
    return <CallStatusMessage>super.message;
  }

  updateCallTime() {
    let time = '';
    if (this._difference>3_600_000) {
      time = this._hours.replace('{{time}}',(this._difference/3_600_000).toLocaleString(undefined,{maximumFractionDigits:1}))
    } else if (this._difference>60_000) {
      time = this._minutes.replace('{{time}}',(this._difference/60_000).toLocaleString(undefined,{maximumFractionDigits:1}))
    } else {
      time = this._seconds.replace('{{time}}',(this._difference/1_000).toLocaleString(undefined,{maximumFractionDigits:1}))
    }
    //const time = !!this._difference && !!this._format ? moment.duration(this._difference,'milliseconds').format(this._format) : '';
    if (this.time$.value != time) {
      //console.log("TIME",time);
      this.time$.next(time);
    }
  }
}
