import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {AttachmentComponent} from "./attachment.component";
import {Attachment} from "messaging";
import {Space} from "./space";
import {ChatTimelineMessage} from "../../../store/models";

@Component({
  selector: 'generic-attachment-viewer',
  template: '',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericAttachmentViewerComponent implements AttachmentComponent {
  protected _attachments: Attachment[] = [];

  @Input()
  set message(message:ChatTimelineMessage) {
  }

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    this._attachments = attachments;
  }

  get attachments():Attachment[] {
    return this._attachments;
  }

  get space():Space|undefined {
    return undefined;
  }

  get deleted(): EventEmitter<Attachment> | undefined {
    return undefined;
  }

  get updated(): EventEmitter<AttachmentComponent> | undefined {
    return undefined;
  }
}
