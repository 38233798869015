<mat-form-field #textfield class="chat-message-text-editor" floatLabel="never">
<!--  <mat-label translate>Edit Message</mat-label>-->
  <textarea #textarea matInput
            placeholder="Edit Message"
            [cdkTextareaAutosize]="true"
            [cdkAutosizeMinRows]="1"
            [cdkAutosizeMaxRows]="5"
            unmodified-input
            (onDomModified)="onChange($event)"
            [subtreeDomMutations]="true"
            (keydown)="onKeydownEvent($event)"
            (focus)="onFocus($event)"
            (blur)="onBlur($event)"></textarea>
</mat-form-field>
<ng-content></ng-content>
