import {Component, Input} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {Attendee} from "../../models/attendee";
import {AttendeeService} from "../../services/attendee.service";
import {takeUntil} from "rxjs/operators";
import {BasicContainerComponent} from "shared";
import {Selection} from 'contact';
import {Logger} from "core";

@Component({
  selector: 'app-attendee-list',
  templateUrl: './attendee-list.component.html',
  styleUrls: ['./attendee-list.component.scss']
})
export class AttendeeListComponent extends BasicContainerComponent  {

  @Input() entities$: Observable<Attendee[]>;
  @Input() cacheId$: Observable<string>;
  selection$ = new BehaviorSubject<Selection>(undefined);

  protected attendeeSubscriptions: {[key:string]:Subscription} = {};
  protected subscribedAttendees: Attendee[];

  protected logger = new Logger('AttendeeListComponent');

  constructor(protected attendeeService: AttendeeService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cacheId$?.pipe(takeUntil(this.onDestroy$)).subscribe((cacheId) => {
      this.logger.debug("cacheId", cacheId);
    });
    const entitiesSubscription = this.entities$?.pipe(takeUntil(this.onDestroy$)).subscribe((attendees) => {
      this.logger.debug("Attendees", attendees?.length);
      this.subscribed(attendees);
    });
    entitiesSubscription.add(() => this.subscribed(undefined));
  }

  onSelectionChanged(selection: Selection) {
    this.selection$.next(selection)
  }

  subscribed(attendees: Attendee[]): Attendee[] {
    if (this.subscribedAttendees!==attendees) {
      this.subscribedAttendees = attendees;
      const subscriptions: {[key:string]: Subscription} = {};
      attendees?.forEach(attendee => {
        if (!!attendee?.id) {
          subscriptions[attendee.id] =
            this.attendeeSubscriptions[attendee.id] ??
            this.attendeeService.getAttendee$(attendee.id,attendee).subscribe();
        }
      });
      Object.keys(this.attendeeSubscriptions).forEach(attendeeId=>{
        if (!subscriptions[attendeeId]) {
          this.attendeeSubscriptions[attendeeId].unsubscribe();
        }
      });
      this.attendeeSubscriptions = subscriptions;
    }
    return attendees;
  }
}
