<mat-sidenav-container fxFlexFill>
  <mat-sidenav-content>
    <chat-conversation-container #conversation></chat-conversation-container>
  </mat-sidenav-content>
  <mat-sidenav #conversationListSidenav position="start" [opened]="isConversationListOpened()" [mode]="getConversationListMode()">
    <chat-conversation-list-container #conversationList></chat-conversation-list-container>
  </mat-sidenav>
  <mat-sidenav #conversationDetailsSidenav position="end" opened="false" [mode]="getConversationDetailsMode()">
    <chat-conversation-details-container [log]="true" #conversationDetails></chat-conversation-details-container>
  </mat-sidenav>
</mat-sidenav-container>
<!--
<chat-conversation-list-container fxShow=”true” fxHide.xs=”true” fxFlex.lt-sm="100%" fxFlex.sm="300px" fxFlex.gt-sm="400px"></chat-conversation-list-container>
<chat-conversation-container fxFlex></chat-conversation-container>
-->
