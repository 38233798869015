import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component } from '@angular/core';
import { ContactService } from '../../../contact.service';
import { Contact, Logger, Topic } from 'core';
import { FilterTagEvent } from 'filter';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContactDetailDataShareService } from '../contact-detail-data-share.service';
import { ContactDetailSectionComponent } from '../contact-detail-section/contact-detail-section.component';

@Component({
  selector: 'contact-detail-filters',
  templateUrl: './contact-detail-filters.component.html',
  styleUrls: ['./contact-detail-filters.component.scss']
})
export class ContactDetailFiltersComponent extends ContactDetailSectionComponent {

  contact: Contact;
  filters: Topic[] = [];
  canViewFilters: boolean = false;
  canEditFilters: boolean = false;
  canDelete: boolean = false;

  contactTags: string[];
  contactFilterTags: string[];

  logger = new Logger('ContactDetailFiltersComponent');

  editableFilter = (filter: Topic): boolean => !!filter?.editable;

  constructor(
    private dataShareService: ContactDetailDataShareService,
    protected contactService: ContactService,
    protected http: HttpClient,
    protected changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.dataShareService.getContact$,
      this.dataShareService.getContactFilter$
    ]).pipe(takeUntil(this.onDestroy$)).subscribe(([contact, contactFilter]) => {
      this.contact = contact;
      this.filters = contactFilter;
      this.contactTags = contact.app?.tags ? [...contact.app.tags] : [];
      this.contactFilterTags = contact.filterTags ? [...contact.filterTags] : [];
      this.canViewFilters = this.canEditFilters = this.canDelete = false;
      (this.contact.tags || []).forEach(tag => {
        switch (tag) {
          case 'can_view_filters': this.canViewFilters = true; break;
          case 'can_edit_filters': this.canEditFilters = true; break;
          case 'can_delete_contact': this.canDelete = true; break;
        }
      });

      this.changeDetector.markForCheck();
    });
  }


  displayFilter = (filter: Topic): boolean => {
    const containsEditable = (topic: Topic): boolean => {
      if (!topic) return false;
      if (topic.editable) return true;
      if (topic.editable==undefined) {
        return topic.topics?.filter(containsEditable)?.length > 0;
      }
    }
    return filter.tags?.includes('contact')
      ? containsEditable(filter)
        ? true
        : this.contactFilterTags?.includes(filter.id)
      : false;
  }

  get tagsDirty(): boolean {
    const contactFilterTags = this.contact?.filterTags || [];
    const pristine = this.contactFilterTags.length == contactFilterTags.length &&
      this.contactFilterTags.every((tag) => contactFilterTags.includes(tag));
    return !pristine;
  }

  onTapFilter(event: FilterTagEvent) {
    const index = this.contactFilterTags.indexOf(event.filter.id);
    if (index >= 0) {
      this.contactFilterTags.splice(index, 1);
    } else {
      this.contactFilterTags.push(event.filter.id);
    }
  }

  onSaveFilters() {
    let membershipTags = {};
    this.filters.forEach(filter => {
      const filters = filter.topics
        .filter(filter => this.displayFilter(filter))
        .map(filter => filter.id)
        .reduce((filters: string[], filter: string) => {
          filters[filter] = this.contactFilterTags.includes(filter);
          return filters;
        }, [] as string[]);
      Object.assign(membershipTags, filters);
    });
    if (Object.keys(membershipTags).length > 0) {
      this.http.post<any>(
        `/v1.0/contacts/update/${this.contact.id}`,
        { setGroupTags: membershipTags }
      ).subscribe(
        result => {
          if (result?.done && result.contact) {
            const contact = new Contact(result.contact);
            // FYI: Currently the contact sidenav component does not subscribe to contact changes
            this.dataShareService.setContact(contact);
            this.contactService.syncContact(contact);
          } else {
            this.logger.error(`Failed to update contactId ${this.contact.id}`);
          }
        },
        error => this.logger.error(error)
      );
    }
  }
}
