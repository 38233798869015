/* projects/contact/src/lib/components/partner-detail/partner-detail.component.scss */
:host {
  display: inline-block;
}
.chip {
  display: block;
  max-width: 100%;
  border-radius: 16px;
  padding: 0 12px;
  background: rgb(224, 224, 224);
  color: rgb(66, 66, 66);
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 32px;
}
.chip > :first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 30px;
}
.chip > :first-child > :not(:last-child) {
  padding-right: 5px;
}
.chip button {
  position: absolute;
  right: 10px;
  top: 4px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
  min-width: 24px;
  line-height: 24px;
}
.chip button mat-icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.edit {
  display: flex;
  width: 100%;
  height: 32px;
}
.edit input {
  margin-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.edit button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  min-width: 30px;
  line-height: 30px;
  margin: 0 5px;
}
.edit button mat-icon {
  font-size: 18px;
  margin-top: -5px;
}
mat-form-field {
  width: 100%;
}
/*# sourceMappingURL=partner-detail.component-JQ5YFI4U.css.map */
