/* projects/chat/src/lib/components/conversation-bar-container/chat-conversation-bar-container.component.scss */
::ng-deep .cb-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  min-width: 60px;
}
::ng-deep .cb-control > button {
  background-color: rgba(255, 255, 255, 0.1254901961);
  height: 44px;
  width: 44px;
  line-height: 44px;
}
::ng-deep .cb-control > button.mat-mdc-icon-button {
  --mdc-icon-button-state-layer-size: 44px;
  padding: 0;
}
::ng-deep .cb-control > button > mat-icon {
  height: 44px;
  width: 44px;
  line-height: 44px;
  font-size: 24px;
}
::ng-deep .cb-control > span {
  display: block;
  font-size: 12.6px;
  font-weight: lighter;
}
:host-context(.color-scheme-light.pure) ::ng-deep .cb-control {
  color: #333;
}
:host-context(.color-scheme-light.pure) ::ng-deep .cb-control > button {
  color: var(--color-primary);
}
:host-context(.color-scheme-light.pure) ::ng-deep .cb-control > button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 22px;
  border: 1px solid #ddd;
}
:host-context(.color-scheme-dark) ::ng-deep .cb-control > button {
  background-color: rgba(255, 255, 255, 0.1254901961);
}
/*# sourceMappingURL=chat-conversation-bar-container.component-JBTU7PSN.css.map */
