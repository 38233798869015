import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { Calendar } from '@ionic-native/calendar/ngx';
import {APP_ID, ENVIRONMENT, Logger, Platform} from "core";

@Component({
  selector: 'app-calendar',
  template: `
    <ng-container *ngIf="platform.is('hybrid')">
      <button mat-raised-button
              color="primary"
              type="button"
              (click)="onAddEvent()">
        Add Event
      </button>
      <button mat-raised-button
              color="primary"
              type="button"
              (click)="onRefreshCalendars()">
        Refresh Calendars
      </button>
      {{calendars | json}}
    </ng-container>
  `,
  styles: [
  ]
})
export class CalendarNativeComponent implements OnInit {

  calendars: any;
  protected logger = new Logger('CalendarNativeComponent');

  constructor(private calendar: Calendar,
              @Inject(ENVIRONMENT)
              private environment: any,
              public platform: Platform,
              private changeDetector: ChangeDetectorRef,
              @Inject(APP_ID) appId: number) {

  }

  ngOnInit(): void {

  }

  onAddEvent() {
    const getCalendar = (): Promise<{id: number, name: string}> => {
      const calendarName = this.environment?.app;
      return this.calendar
        .listCalendars()
        .then(calendars => {
          this.logger.info('CALENDARS', calendars);
          let calendar = calendars?.find(c => c.name == calendarName);
          if (calendar) {
            this.logger.info('CALENDAR', calendar);
            return calendar;
          } else {
            this.logger.info('CALENDAR CREATE', calendar);
            return this.calendar
              .createCalendar(calendarName)
              .then(() => {
                return getCalendar();
              });
          }
        });
    };
    this.platform.ready()
      .then(() => { return getCalendar(); })
      .then((calendar) => {
        this.logger.info('CALENDAR.2', calendar);
        const options = this.calendar.getCalendarOptions(); // grab the defaults
        options.firstReminderMinutes = 120; // default is 60, pass in null for no reminder (alarm)
        options.secondReminderMinutes = 5;

        // Added these options in version 4.2.4:
        // options.recurrence = "monthly"; // supported are: daily, weekly, monthly, yearly
        // options.recurrenceEndDate = new Date(2016,10,1,0,0,0,0); // leave null to add events into infinity and beyond
        options.calendarName = calendar.name; // iOS only
        options.calendarId = calendar.id; // Android only, use id obtained from listCalendars() call which is described below. This will be ignored on iOS in favor of calendarName and vice versa. Default: 1.

        // This is new since 4.2.7:
        // options.recurrenceInterval = 2; // once every 2 months in this case, default: 1

        // And the URL can be passed since 4.3.2 (will be appended to the notes on Android as there doesn't seem to be a sep field)
        options.url = "https://www.realizer.app";

        return this.calendar.createEventWithOptions(
          'My Event',
          'New Zealand',
          'Fitline Meeting',
          new Date(2020, 8, 1),
          new Date(2020, 8, 2),
          options
        );
      })
      .catch((error) => this.logger.error('Failed to add event', error));
  }

  onRefreshCalendars() {
    this.calendar.listCalendars().then(calendars => {
      this.calendars = calendars || [];
      this.changeDetector.detectChanges();
    })
  }
}
