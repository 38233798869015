import {Inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {of} from "rxjs";
import {catchError, map, mergeMap} from "rxjs/operators";
import {Logger, NULL_USER, User} from "core";
import {HttpClient} from "@angular/common/http";

import {Task,} from "../../models/task";

import {
  TaskActionIds,
  taskDeleteAction,
  taskDeleteFailureAction,
  taskDeleteSuccessAction,
  TaskUpdateAction,
  taskUpdateFailureAction,
  taskUpdateSuccessAction,
} from "./actions";

@Injectable()
export class TaskEffects {

  // @Effect()
  taskUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TaskUpdateAction>(TaskActionIds.UPDATE),
      mergeMap(action => {
        const path = `/v1.0/task`;
        return this.http.post(path, action.task).pipe(
          map((task: Task) => {
            const props = { task, correlationId: action.correlationId };
            return taskUpdateSuccessAction(props);
          }),
          catchError((error) => {
            this.logger.error('ERROR', error);
            return of(taskUpdateFailureAction({
              task: action.previous,
              reason: error,
              correlationId: action.correlationId
            }));
          })
        )
      })
    )
  );

  // @Effect()
  taskDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskDeleteAction),
      mergeMap(action  => {
        const path = `/v1.0/task/${action.task.id}`;
        return this.http.delete(path).pipe(
          map((task: Task) => {
            return taskDeleteSuccessAction({ task: new Task(task) || action.task } );
          }),
          catchError((error) => {
            this.logger.error('ERROR', error);
            return of(taskDeleteFailureAction({ task: action.task, reason: error }));
          })
        )
      })
    )
  );

  protected tasks: Task[]   = []; // dummy tasks for testing
  protected logger = new Logger('TaskEffects');

  constructor(private http: HttpClient,
              private actions$: Actions,
              @Inject(NULL_USER) protected nullUser: User) {
  }
}
