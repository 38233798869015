import { __decorate, __metadata, __param, __awaiter } from 'tslib';
import { Injectable, NgZone, Input, Directive, EventEmitter, Output, Self, ElementRef, Component, Inject, PLATFORM_ID, QueryList, ContentChildren, forwardRef, ɵɵdefineInjectable, ɵɵinject, InjectionToken, Optional, LOCALE_ID, NgModule } from '@angular/core';
import { Observable, BehaviorSubject, from, timer, Subject, fromEventPattern, merge, ReplaySubject, bindCallback, of, throwError } from 'rxjs';
import { flatMap, sample, switchMap, map, shareReplay, distinctUntilChanged, takeUntil, startWith, skip, multicast } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
let MapsAPILoader = class MapsAPILoader {};
MapsAPILoader = __decorate([Injectable()], MapsAPILoader);

/**
 * Wrapper class that handles the communication with the Google Maps Javascript
 * API v3
 */
let GoogleMapsAPIWrapper = class GoogleMapsAPIWrapper {
  constructor(_loader, _zone) {
    this._loader = _loader;
    this._zone = _zone;
    this._map = new Promise(resolve => {
      this._mapResolver = resolve;
    });
  }
  createMap(el, mapOptions) {
    return this._zone.runOutsideAngular(() => {
      return this._loader.load().then(() => {
        const map = new google.maps.Map(el, mapOptions);
        this._mapResolver(map);
        return;
      });
    });
  }
  setMapOptions(options) {
    return this._zone.runOutsideAngular(() => {
      this._map.then(m => {
        m.setOptions(options);
      });
    });
  }
  /**
   * Creates a google map marker with the map context
   */
  createMarker(options = {}, addToMap = true) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => {
        if (addToMap) {
          options.map = map;
        }
        return new google.maps.Marker(options);
      });
    });
  }
  createInfoWindow(options) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(() => {
        return new google.maps.InfoWindow(options);
      });
    });
  }
  /**
   * Creates a google.map.Circle for the current map.
   */
  createCircle(options) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => {
        if (typeof options.strokePosition === 'string') {
          options.strokePosition = google.maps.StrokePosition[options.strokePosition];
        }
        options.map = map;
        return new google.maps.Circle(options);
      });
    });
  }
  /**
   * Creates a google.map.Rectangle for the current map.
   */
  createRectangle(options) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => {
        options.map = map;
        return new google.maps.Rectangle(options);
      });
    });
  }
  createPolyline(options) {
    return this._zone.runOutsideAngular(() => {
      return this.getNativeMap().then(map => {
        let line = new google.maps.Polyline(options);
        line.setMap(map);
        return line;
      });
    });
  }
  createPolygon(options) {
    return this._zone.runOutsideAngular(() => {
      return this.getNativeMap().then(map => {
        let polygon = new google.maps.Polygon(options);
        polygon.setMap(map);
        return polygon;
      });
    });
  }
  /**
   * Creates a new google.map.Data layer for the current map
   */
  createDataLayer(options) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(m => {
        let data = new google.maps.Data(options);
        data.setMap(m);
        return data;
      });
    });
  }
  /**
   * Creates a TransitLayer instance for a map
   * @param {TransitLayerOptions} options - used for setting layer options
   * @returns {Promise<TransitLayer>} a new transit layer object
   */
  createTransitLayer(options) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => {
        let newLayer = new google.maps.TransitLayer();
        newLayer.setMap(options.visible ? map : null);
        return newLayer;
      });
    });
  }
  /**
   * Creates a BicyclingLayer instance for a map
   * @param {BicyclingLayerOptions} options - used for setting layer options
   * @returns {Promise<BicyclingLayer>} a new bicycling layer object
   */
  createBicyclingLayer(options) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => {
        let newLayer = new google.maps.BicyclingLayer();
        newLayer.setMap(options.visible ? map : null);
        return newLayer;
      });
    });
  }
  /**
   * Determines if given coordinates are insite a Polygon path.
   */
  containsLocation(latLng, polygon) {
    return google.maps.geometry.poly.containsLocation(latLng, polygon);
  }
  subscribeToMapEvent(eventName) {
    return new Observable(observer => {
      this._map.then(m => {
        m.addListener(eventName, arg => {
          this._zone.run(() => observer.next(arg));
        });
      });
    });
  }
  clearInstanceListeners() {
    return this._zone.runOutsideAngular(() => {
      this._map.then(map => {
        google.maps.event.clearInstanceListeners(map);
      });
    });
  }
  setCenter(latLng) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.setCenter(latLng));
    });
  }
  getZoom() {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.getZoom());
    });
  }
  getBounds() {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.getBounds());
    });
  }
  getMapTypeId() {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.getMapTypeId());
    });
  }
  setZoom(zoom) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.setZoom(zoom));
    });
  }
  getCenter() {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.getCenter());
    });
  }
  panTo(latLng) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.panTo(latLng));
    });
  }
  panBy(x, y) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.panBy(x, y));
    });
  }
  fitBounds(latLng, padding) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.fitBounds(latLng, padding));
    });
  }
  panToBounds(latLng, padding) {
    return this._zone.runOutsideAngular(() => {
      return this._map.then(map => map.panToBounds(latLng, padding));
    });
  }
  /**
   * Returns the native Google Maps Map instance. Be careful when using this instance directly.
   */
  getNativeMap() {
    return this._map;
  }
  /**
   * Triggers the given event name on the map instance.
   */
  triggerMapEvent(eventName) {
    return this._map.then(m => google.maps.event.trigger(m, eventName));
  }
};
GoogleMapsAPIWrapper.ctorParameters = () => [{
  type: MapsAPILoader
}, {
  type: NgZone
}];
GoogleMapsAPIWrapper = __decorate([Injectable(), __metadata("design:paramtypes", [MapsAPILoader, NgZone])], GoogleMapsAPIWrapper);

/**
 * This class manages Transit and Bicycling Layers for a Google Map instance.
 */
let LayerManager = class LayerManager {
  constructor(_wrapper) {
    this._wrapper = _wrapper;
    this._layers = new Map();
  }
  /**
   * Adds a transit layer to a map instance.
   * @param {AgmTransitLayer} layer - a TransitLayer object
   * @param {TransitLayerOptions} options - TransitLayerOptions options
   * @returns void
   */
  addTransitLayer(layer, options) {
    const newLayer = this._wrapper.createTransitLayer(options);
    this._layers.set(layer, newLayer);
  }
  /**
   * Adds a bicycling layer to a map instance.
   * @param {AgmBicyclingLayer} layer - a bicycling layer object
   * @param {BicyclingLayerOptions} options - BicyclingLayer options
   * @returns void
   */
  addBicyclingLayer(layer, options) {
    const newLayer = this._wrapper.createBicyclingLayer(options);
    this._layers.set(layer, newLayer);
  }
  /**
   * Deletes a map layer
   * @param {AgmTransitLayer|AgmBicyclingLayer} layer - the layer to delete
   * @returns  Promise<void>
   */
  deleteLayer(layer) {
    return this._layers.get(layer).then(currentLayer => {
      currentLayer.setMap(null);
      this._layers.delete(layer);
    });
  }
  /**
   * Hide/Show a google map layer
   * @param { AgmTransitLayer|AgmBicyclingLayer} layer - the layer to hide/show
   * @param {TransitLayerOptions|BicyclingLayerOptions} options - used to set visibility of the layer
   * @returns Promise<void>
   */
  toggleLayerVisibility(layer, options) {
    return this._layers.get(layer).then(currentLayer => {
      if (!options.visible) {
        currentLayer.setMap(null);
        return;
      } else {
        return this._wrapper.getNativeMap().then(map => {
          currentLayer.setMap(map);
        });
      }
    });
  }
};
LayerManager.ctorParameters = () => [{
  type: GoogleMapsAPIWrapper
}];
LayerManager = __decorate([Injectable(), __metadata("design:paramtypes", [GoogleMapsAPIWrapper])], LayerManager);
let layerId = 0;
/*
 * This directive adds a bicycling layer to a google map instance
 * <agm-bicycling-layer [visible]="true|false"> <agm-bicycling-layer>
 * */
let AgmBicyclingLayer = class AgmBicyclingLayer {
  constructor(_manager) {
    this._manager = _manager;
    this._addedToManager = false;
    this._id = (layerId++).toString();
    /**
     * Hide/show bicycling layer
     */
    this.visible = true;
  }
  ngOnInit() {
    if (this._addedToManager) {
      return;
    }
    this._manager.addBicyclingLayer(this, {
      visible: this.visible
    });
    this._addedToManager = true;
  }
  ngOnChanges(changes) {
    if (!this._addedToManager) {
      return;
    }
    if (changes['visible'] != null) {
      this._manager.toggleLayerVisibility(this, {
        visible: changes['visible'].currentValue
      });
    }
  }
  /** @internal */
  id() {
    return this._id;
  }
  /** @internal */
  toString() {
    return `AgmBicyclingLayer-${this._id.toString()}`;
  }
  /** @internal */
  ngOnDestroy() {
    this._manager.deleteLayer(this);
  }
};
AgmBicyclingLayer.ctorParameters = () => [{
  type: LayerManager
}];
__decorate([Input(), __metadata("design:type", Object)], AgmBicyclingLayer.prototype, "visible", void 0);
AgmBicyclingLayer = __decorate([Directive({
  selector: 'agm-bicycling-layer'
}), __metadata("design:paramtypes", [LayerManager])], AgmBicyclingLayer);
let CircleManager = class CircleManager {
  constructor(_apiWrapper, _zone) {
    this._apiWrapper = _apiWrapper;
    this._zone = _zone;
    this._circles = new Map();
  }
  addCircle(circle) {
    this._circles.set(circle, this._apiWrapper.createCircle({
      center: {
        lat: circle.latitude,
        lng: circle.longitude
      },
      clickable: circle.clickable,
      draggable: circle.draggable,
      editable: circle.editable,
      fillColor: circle.fillColor,
      fillOpacity: circle.fillOpacity,
      radius: circle.radius,
      strokeColor: circle.strokeColor,
      strokeOpacity: circle.strokeOpacity,
      strokePosition: circle.strokePosition,
      strokeWeight: circle.strokeWeight,
      visible: circle.visible,
      zIndex: circle.zIndex
    }));
  }
  /**
   * Removes the given circle from the map.
   */
  removeCircle(circle) {
    return this._circles.get(circle).then(c => {
      c.setMap(null);
      this._circles.delete(circle);
    });
  }
  setOptions(circle, options) {
    return this._circles.get(circle).then(c => {
      if (typeof options.strokePosition === 'string') {
        options.strokePosition = google.maps.StrokePosition[options.strokePosition];
      }
      c.setOptions(options);
    });
  }
  getBounds(circle) {
    return this._circles.get(circle).then(c => c.getBounds());
  }
  getCenter(circle) {
    return this._circles.get(circle).then(c => c.getCenter());
  }
  getRadius(circle) {
    return this._circles.get(circle).then(c => c.getRadius());
  }
  setCenter(circle) {
    return this._circles.get(circle).then(c => {
      return c.setCenter({
        lat: circle.latitude,
        lng: circle.longitude
      });
    });
  }
  setEditable(circle) {
    return this._circles.get(circle).then(c => {
      return c.setEditable(circle.editable);
    });
  }
  setDraggable(circle) {
    return this._circles.get(circle).then(c => {
      return c.setDraggable(circle.draggable);
    });
  }
  setVisible(circle) {
    return this._circles.get(circle).then(c => {
      return c.setVisible(circle.visible);
    });
  }
  setRadius(circle) {
    return this._circles.get(circle).then(c => {
      return c.setRadius(circle.radius);
    });
  }
  getNativeCircle(circle) {
    return this._circles.get(circle);
  }
  createEventObservable(eventName, circle) {
    return new Observable(observer => {
      let listener = null;
      this._circles.get(circle).then(c => {
        listener = c.addListener(eventName, e => this._zone.run(() => observer.next(e)));
      });
      return () => {
        if (listener !== null) {
          listener.remove();
        }
      };
    });
  }
};
CircleManager.ctorParameters = () => [{
  type: GoogleMapsAPIWrapper
}, {
  type: NgZone
}];
CircleManager = __decorate([Injectable(), __metadata("design:paramtypes", [GoogleMapsAPIWrapper, NgZone])], CircleManager);
var AgmCircle_1;
let AgmCircle = AgmCircle_1 = class AgmCircle {
  constructor(_manager) {
    this._manager = _manager;
    /**
     * Indicates whether this Circle handles mouse events. Defaults to true.
     */
    this.clickable = true;
    /**
     * If set to true, the user can drag this circle over the map. Defaults to false.
     */
    // tslint:disable-next-line:no-input-rename
    this.draggable = false;
    /**
     * If set to true, the user can edit this circle by dragging the control points shown at
     * the center and around the circumference of the circle. Defaults to false.
     */
    this.editable = false;
    /**
     * The radius in meters on the Earth's surface.
     */
    this.radius = 0;
    /**
     * The stroke position. Defaults to CENTER.
     * This property is not supported on Internet Explorer 8 and earlier.
     */
    this.strokePosition = 'CENTER';
    /**
     * The stroke width in pixels.
     */
    this.strokeWeight = 0;
    /**
     * Whether this circle is visible on the map. Defaults to true.
     */
    this.visible = true;
    /**
     * This event is fired when the circle's center is changed.
     */
    this.centerChange = new EventEmitter();
    /**
     * This event emitter gets emitted when the user clicks on the circle.
     */
    this.circleClick = new EventEmitter();
    /**
     * This event emitter gets emitted when the user clicks on the circle.
     */
    this.circleDblClick = new EventEmitter();
    /**
     * This event is repeatedly fired while the user drags the circle.
     */
    this.drag = new EventEmitter();
    /**
     * This event is fired when the user stops dragging the circle.
     */
    this.dragEnd = new EventEmitter();
    /**
     * This event is fired when the user starts dragging the circle.
     */
    this.dragStart = new EventEmitter();
    /**
     * This event is fired when the DOM mousedown event is fired on the circle.
     */
    this.mouseDown = new EventEmitter();
    /**
     * This event is fired when the DOM mousemove event is fired on the circle.
     */
    this.mouseMove = new EventEmitter();
    /**
     * This event is fired on circle mouseout.
     */
    this.mouseOut = new EventEmitter();
    /**
     * This event is fired on circle mouseover.
     */
    this.mouseOver = new EventEmitter();
    /**
     * This event is fired when the DOM mouseup event is fired on the circle.
     */
    this.mouseUp = new EventEmitter();
    /**
     * This event is fired when the circle's radius is changed.
     */
    this.radiusChange = new EventEmitter();
    /**
     * This event is fired when the circle is right-clicked on.
     */
    this.rightClick = new EventEmitter();
    this._circleAddedToManager = false;
    this._eventSubscriptions = [];
  }
  /** @internal */
  ngOnInit() {
    this._manager.addCircle(this);
    this._circleAddedToManager = true;
    this._registerEventListeners();
  }
  /** @internal */
  ngOnChanges(changes) {
    if (!this._circleAddedToManager) {
      return;
    }
    if (changes['latitude'] || changes['longitude']) {
      this._manager.setCenter(this);
    }
    if (changes['editable']) {
      this._manager.setEditable(this);
    }
    if (changes['draggable']) {
      this._manager.setDraggable(this);
    }
    if (changes['visible']) {
      this._manager.setVisible(this);
    }
    if (changes['radius']) {
      this._manager.setRadius(this);
    }
    this._updateCircleOptionsChanges(changes);
  }
  _updateCircleOptionsChanges(changes) {
    let options = {};
    let optionKeys = Object.keys(changes).filter(k => AgmCircle_1._mapOptions.indexOf(k) !== -1);
    optionKeys.forEach(k => {
      options[k] = changes[k].currentValue;
    });
    if (optionKeys.length > 0) {
      this._manager.setOptions(this, options);
    }
  }
  _registerEventListeners() {
    let events = new Map();
    events.set('center_changed', this.centerChange);
    events.set('click', this.circleClick);
    events.set('dblclick', this.circleDblClick);
    events.set('drag', this.drag);
    events.set('dragend', this.dragEnd);
    events.set('dragstart', this.dragStart);
    events.set('mousedown', this.mouseDown);
    events.set('mousemove', this.mouseMove);
    events.set('mouseout', this.mouseOut);
    events.set('mouseover', this.mouseOver);
    events.set('mouseup', this.mouseUp);
    events.set('radius_changed', this.radiusChange);
    events.set('rightclick', this.rightClick);
    events.forEach((eventEmitter, eventName) => {
      this._eventSubscriptions.push(this._manager.createEventObservable(eventName, this).subscribe(value => {
        switch (eventName) {
          case 'radius_changed':
            this._manager.getRadius(this).then(radius => eventEmitter.emit(radius));
            break;
          case 'center_changed':
            this._manager.getCenter(this).then(center => eventEmitter.emit({
              lat: center.lat(),
              lng: center.lng()
            }));
            break;
          default:
            eventEmitter.emit({
              coords: {
                lat: value.latLng.lat(),
                lng: value.latLng.lng()
              }
            });
        }
      }));
    });
  }
  /** @internal */
  ngOnDestroy() {
    this._eventSubscriptions.forEach(function (s) {
      s.unsubscribe();
    });
    this._eventSubscriptions = null;
    this._manager.removeCircle(this);
  }
  /**
   * Gets the LatLngBounds of this Circle.
   */
  getBounds() {
    return this._manager.getBounds(this);
  }
  getCenter() {
    return this._manager.getCenter(this);
  }
};
AgmCircle._mapOptions = ['fillColor', 'fillOpacity', 'strokeColor', 'strokeOpacity', 'strokePosition', 'strokeWeight', 'visible', 'zIndex', 'clickable'];
AgmCircle.ctorParameters = () => [{
  type: CircleManager
}];
__decorate([Input(), __metadata("design:type", Number)], AgmCircle.prototype, "latitude", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmCircle.prototype, "longitude", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmCircle.prototype, "clickable", void 0);
__decorate([Input('circleDraggable'), __metadata("design:type", Object)], AgmCircle.prototype, "draggable", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmCircle.prototype, "editable", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmCircle.prototype, "fillColor", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmCircle.prototype, "fillOpacity", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmCircle.prototype, "radius", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmCircle.prototype, "strokeColor", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmCircle.prototype, "strokeOpacity", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmCircle.prototype, "strokePosition", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmCircle.prototype, "strokeWeight", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmCircle.prototype, "visible", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmCircle.prototype, "zIndex", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "centerChange", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "circleClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "circleDblClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "drag", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "dragEnd", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "dragStart", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "mouseDown", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "mouseMove", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "mouseOut", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "mouseOver", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "mouseUp", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "radiusChange", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmCircle.prototype, "rightClick", void 0);
AgmCircle = AgmCircle_1 = __decorate([Directive({
  selector: 'agm-circle'
}), __metadata("design:paramtypes", [CircleManager])], AgmCircle);

/**
 * Manages all Data Layers for a Google Map instance.
 */
let DataLayerManager = class DataLayerManager {
  constructor(_wrapper, _zone) {
    this._wrapper = _wrapper;
    this._zone = _zone;
    this._layers = new Map();
  }
  /**
   * Adds a new Data Layer to the map.
   */
  addDataLayer(layer) {
    const newLayer = this._wrapper.createDataLayer({
      style: layer.style
    }).then(d => {
      if (layer.geoJson) {
        this.getDataFeatures(d, layer.geoJson).then(features => d.features = features);
      }
      return d;
    });
    this._layers.set(layer, newLayer);
  }
  deleteDataLayer(layer) {
    this._layers.get(layer).then(l => {
      l.setMap(null);
      this._layers.delete(layer);
    });
  }
  updateGeoJson(layer, geoJson) {
    this._layers.get(layer).then(l => {
      l.forEach(function (feature) {
        l.remove(feature);
        var index = l.features.indexOf(feature, 0);
        if (index > -1) {
          l.features.splice(index, 1);
        }
      });
      this.getDataFeatures(l, geoJson).then(features => l.features = features);
    });
  }
  setDataOptions(layer, options) {
    this._layers.get(layer).then(l => {
      l.setControlPosition(options.controlPosition);
      l.setControls(options.controls);
      l.setDrawingMode(options.drawingMode);
      l.setStyle(options.style);
    });
  }
  /**
   * Creates a Google Maps event listener for the given DataLayer as an Observable
   */
  createEventObservable(eventName, layer) {
    return new Observable(observer => {
      this._layers.get(layer).then(d => {
        d.addListener(eventName, e => this._zone.run(() => observer.next(e)));
      });
    });
  }
  /**
   * Extract features from a geoJson using google.maps Data Class
   * @param d : google.maps.Data class instance
   * @param geoJson : url or geojson object
   */
  getDataFeatures(d, geoJson) {
    return new Promise((resolve, reject) => {
      if (typeof geoJson === 'object') {
        try {
          const features = d.addGeoJson(geoJson);
          resolve(features);
        } catch (e) {
          reject(e);
        }
      } else if (typeof geoJson === 'string') {
        d.loadGeoJson(geoJson, null, resolve);
      } else {
        reject(`Impossible to extract features from geoJson: wrong argument type`);
      }
    });
  }
};
DataLayerManager.ctorParameters = () => [{
  type: GoogleMapsAPIWrapper
}, {
  type: NgZone
}];
DataLayerManager = __decorate([Injectable(), __metadata("design:paramtypes", [GoogleMapsAPIWrapper, NgZone])], DataLayerManager);
var AgmDataLayer_1;
let layerId$1 = 0;
/**
 * AgmDataLayer enables the user to add data layers to the map.
 *
 * ### Example
 * ```typescript
 * import { Component } from 'angular2/core';
 * import { AgmMap, AgmDataLayer } from
 * 'angular-google-maps/core';
 *
 * @Component({
 *  selector: 'my-map-cmp',
 *  directives: [AgmMap, AgmDataLayer],
 *  styles: [`
 *    .agm-container {
 *      height: 300px;
 *    }
 * `],
 *  template: `
 * <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
 * 	  <agm-data-layer [geoJson]="geoJsonObject" (layerClick)="clicked($event)" [style]="styleFunc">
 * 	  </agm-data-layer>
 * </agm-map>
 *  `
 * })
 * export class MyMapCmp {
 *   lat: number = -25.274449;
 *   lng: number = 133.775060;
 *   zoom: number = 5;
 *
 * clicked(clickEvent) {
 *    console.log(clickEvent);
 *  }
 *
 *  styleFunc(feature) {
 *    return ({
 *      clickable: false,
 *      fillColor: feature.getProperty('color'),
 *      strokeWeight: 1
 *    });
 *  }
 *
 *  geoJsonObject: Object = {
 *    "type": "FeatureCollection",
 *    "features": [
 *      {
 *        "type": "Feature",
 *        "properties": {
 *          "letter": "G",
 *          "color": "blue",
 *          "rank": "7",
 *          "ascii": "71"
 *        },
 *        "geometry": {
 *          "type": "Polygon",
 *          "coordinates": [
 *            [
 *              [123.61, -22.14], [122.38, -21.73], [121.06, -21.69], [119.66, -22.22], [119.00, -23.40],
 *              [118.65, -24.76], [118.43, -26.07], [118.78, -27.56], [119.22, -28.57], [120.23, -29.49],
 *              [121.77, -29.87], [123.57, -29.64], [124.45, -29.03], [124.71, -27.95], [124.80, -26.70],
 *              [124.80, -25.60], [123.61, -25.64], [122.56, -25.64], [121.72, -25.72], [121.81, -26.62],
 *              [121.86, -26.98], [122.60, -26.90], [123.57, -27.05], [123.57, -27.68], [123.35, -28.18],
 *              [122.51, -28.38], [121.77, -28.26], [121.02, -27.91], [120.49, -27.21], [120.14, -26.50],
 *              [120.10, -25.64], [120.27, -24.52], [120.67, -23.68], [121.72, -23.32], [122.43, -23.48],
 *              [123.04, -24.04], [124.54, -24.28], [124.58, -23.20], [123.61, -22.14]
 *            ]
 *          ]
 *        }
 *      },
 *      {
 *        "type": "Feature",
 *        "properties": {
 *          "letter": "o",
 *          "color": "red",
 *          "rank": "15",
 *          "ascii": "111"
 *        },
 *        "geometry": {
 *          "type": "Polygon",
 *          "coordinates": [
 *            [
 *              [128.84, -25.76], [128.18, -25.60], [127.96, -25.52], [127.88, -25.52], [127.70, -25.60],
 *              [127.26, -25.79], [126.60, -26.11], [126.16, -26.78], [126.12, -27.68], [126.21, -28.42],
 *              [126.69, -29.49], [127.74, -29.80], [128.80, -29.72], [129.41, -29.03], [129.72, -27.95],
 *              [129.68, -27.21], [129.33, -26.23], [128.84, -25.76]
 *            ],
 *            [
 *              [128.45, -27.44], [128.32, -26.94], [127.70, -26.82], [127.35, -27.05], [127.17, -27.80],
 *              [127.57, -28.22], [128.10, -28.42], [128.49, -27.80], [128.45, -27.44]
 *            ]
 *          ]
 *        }
 *      },
 *      {
 *        "type": "Feature",
 *        "properties": {
 *          "letter": "o",
 *          "color": "yellow",
 *          "rank": "15",
 *          "ascii": "111"
 *        },
 *        "geometry": {
 *          "type": "Polygon",
 *          "coordinates": [
 *            [
 *              [131.87, -25.76], [131.35, -26.07], [130.95, -26.78], [130.82, -27.64], [130.86, -28.53],
 *              [131.26, -29.22], [131.92, -29.76], [132.45, -29.87], [133.06, -29.76], [133.72, -29.34],
 *              [134.07, -28.80], [134.20, -27.91], [134.07, -27.21], [133.81, -26.31], [133.37, -25.83],
 *              [132.71, -25.64], [131.87, -25.76]
 *            ],
 *            [
 *              [133.15, -27.17], [132.71, -26.86], [132.09, -26.90], [131.74, -27.56], [131.79, -28.26],
 *              [132.36, -28.45], [132.93, -28.34], [133.15, -27.76], [133.15, -27.17]
 *            ]
 *          ]
 *        }
 *      },
 *      {
 *        "type": "Feature",
 *        "properties": {
 *          "letter": "g",
 *          "color": "blue",
 *          "rank": "7",
 *          "ascii": "103"
 *        },
 *        "geometry": {
 *          "type": "Polygon",
 *          "coordinates": [
 *            [
 *              [138.12, -25.04], [136.84, -25.16], [135.96, -25.36], [135.26, -25.99], [135, -26.90],
 *              [135.04, -27.91], [135.26, -28.88], [136.05, -29.45], [137.02, -29.49], [137.81, -29.49],
 *              [137.94, -29.99], [137.90, -31.20], [137.85, -32.24], [136.88, -32.69], [136.45, -32.36],
 *              [136.27, -31.80], [134.95, -31.84], [135.17, -32.99], [135.52, -33.43], [136.14, -33.76],
 *              [137.06, -33.83], [138.12, -33.65], [138.86, -33.21], [139.30, -32.28], [139.30, -31.24],
 *              [139.30, -30.14], [139.21, -28.96], [139.17, -28.22], [139.08, -27.41], [139.08, -26.47],
 *              [138.99, -25.40], [138.73, -25.00], [138.12, -25.04]
 *            ],
 *            [
 *              [137.50, -26.54], [136.97, -26.47], [136.49, -26.58], [136.31, -27.13], [136.31, -27.72],
 *              [136.58, -27.99], [137.50, -28.03], [137.68, -27.68], [137.59, -26.78], [137.50, -26.54]
 *            ]
 *          ]
 *        }
 *      },
 *      {
 *        "type": "Feature",
 *        "properties": {
 *          "letter": "l",
 *          "color": "green",
 *          "rank": "12",
 *          "ascii": "108"
 *        },
 *        "geometry": {
 *          "type": "Polygon",
 *          "coordinates": [
 *            [
 *              [140.14, -21.04], [140.31, -29.42], [141.67, -29.49], [141.59, -20.92], [140.14, -21.04]
 *            ]
 *          ]
 *        }
 *      },
 *      {
 *        "type": "Feature",
 *        "properties": {
 *          "letter": "e",
 *          "color": "red",
 *          "rank": "5",
 *          "ascii": "101"
 *        },
 *        "geometry": {
 *          "type": "Polygon",
 *          "coordinates": [
 *            [
 *              [144.14, -27.41], [145.67, -27.52], [146.86, -27.09], [146.82, -25.64], [146.25, -25.04],
 *              [145.45, -24.68], [144.66, -24.60], [144.09, -24.76], [143.43, -25.08], [142.99, -25.40],
 *              [142.64, -26.03], [142.64, -27.05], [142.64, -28.26], [143.30, -29.11], [144.18, -29.57],
 *              [145.41, -29.64], [146.46, -29.19], [146.64, -28.72], [146.82, -28.14], [144.84, -28.42],
 *              [144.31, -28.26], [144.14, -27.41]
 *            ],
 *            [
 *              [144.18, -26.39], [144.53, -26.58], [145.19, -26.62], [145.72, -26.35], [145.81, -25.91],
 *              [145.41, -25.68], [144.97, -25.68], [144.49, -25.64], [144, -25.99], [144.18, -26.39]
 *            ]
 *          ]
 *        }
 *      }
 *    ]
 *  };
 * }
 * ```
 */
let AgmDataLayer = AgmDataLayer_1 = class AgmDataLayer {
  constructor(_manager) {
    this._manager = _manager;
    this._addedToManager = false;
    this._id = (layerId$1++).toString();
    this._subscriptions = [];
    /**
     * This event is fired when a feature in the layer is clicked.
     */
    this.layerClick = new EventEmitter();
    /**
     * The geoJson to be displayed
     */
    this.geoJson = null;
  }
  ngOnInit() {
    if (this._addedToManager) {
      return;
    }
    this._manager.addDataLayer(this);
    this._addedToManager = true;
    this._addEventListeners();
  }
  _addEventListeners() {
    const listeners = [{
      name: 'click',
      handler: ev => this.layerClick.emit(ev)
    }];
    listeners.forEach(obj => {
      const os = this._manager.createEventObservable(obj.name, this).subscribe(obj.handler);
      this._subscriptions.push(os);
    });
  }
  /** @internal */
  id() {
    return this._id;
  }
  /** @internal */
  toString() {
    return `AgmDataLayer-${this._id.toString()}`;
  }
  /** @internal */
  ngOnDestroy() {
    this._manager.deleteDataLayer(this);
    // unsubscribe all registered observable subscriptions
    this._subscriptions.forEach(s => s.unsubscribe());
  }
  /** @internal */
  ngOnChanges(changes) {
    if (!this._addedToManager) {
      return;
    }
    var geoJsonChange = changes['geoJson'];
    if (geoJsonChange) {
      this._manager.updateGeoJson(this, geoJsonChange.currentValue);
    }
    let dataOptions = {};
    AgmDataLayer_1._dataOptionsAttributes.forEach(k => dataOptions[k] = changes.hasOwnProperty(k) ? changes[k].currentValue : this[k]);
    this._manager.setDataOptions(this, dataOptions);
  }
};
AgmDataLayer._dataOptionsAttributes = ['style'];
AgmDataLayer.ctorParameters = () => [{
  type: DataLayerManager
}];
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmDataLayer.prototype, "layerClick", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmDataLayer.prototype, "geoJson", void 0);
__decorate([Input(), __metadata("design:type", Function)], AgmDataLayer.prototype, "style", void 0);
AgmDataLayer = AgmDataLayer_1 = __decorate([Directive({
  selector: 'agm-data-layer'
}), __metadata("design:paramtypes", [DataLayerManager])], AgmDataLayer);

/**
 * Class to implement when you what to be able to make it work with the auto fit bounds feature
 * of AGM.
 */
class FitBoundsAccessor {}
/**
 * The FitBoundsService is responsible for computing the bounds of the a single map.
 */
let FitBoundsService = class FitBoundsService {
  constructor(loader) {
    this._boundsChangeSampleTime$ = new BehaviorSubject(200);
    this._includeInBounds$ = new BehaviorSubject(new Map());
    this.bounds$ = from(loader.load()).pipe(flatMap(() => this._includeInBounds$), sample(this._boundsChangeSampleTime$.pipe(switchMap(time => timer(0, time)))), map(includeInBounds => this._generateBounds(includeInBounds)), shareReplay(1));
  }
  _generateBounds(includeInBounds) {
    const bounds = new google.maps.LatLngBounds();
    includeInBounds.forEach(b => bounds.extend(b));
    return bounds;
  }
  addToBounds(latLng) {
    const id = this._createIdentifier(latLng);
    if (this._includeInBounds$.value.has(id)) {
      return;
    }
    const map = this._includeInBounds$.value;
    map.set(id, latLng);
    this._includeInBounds$.next(map);
  }
  removeFromBounds(latLng) {
    const map = this._includeInBounds$.value;
    map.delete(this._createIdentifier(latLng));
    this._includeInBounds$.next(map);
  }
  changeFitBoundsChangeSampleTime(timeMs) {
    this._boundsChangeSampleTime$.next(timeMs);
  }
  getBounds$() {
    return this.bounds$;
  }
  _createIdentifier(latLng) {
    return `${latLng.lat}+${latLng.lng}`;
  }
};
FitBoundsService.ctorParameters = () => [{
  type: MapsAPILoader
}];
FitBoundsService = __decorate([Injectable(), __metadata("design:paramtypes", [MapsAPILoader])], FitBoundsService);

/**
 * Adds the given directive to the auto fit bounds feature when the value is true.
 * To make it work with you custom AGM component, you also have to implement the {@link FitBoundsAccessor} abstract class.
 * @example
 * <agm-marker [agmFitBounds]="true"></agm-marker>
 */
let AgmFitBounds = class AgmFitBounds {
  constructor(_fitBoundsAccessor, _fitBoundsService) {
    this._fitBoundsAccessor = _fitBoundsAccessor;
    this._fitBoundsService = _fitBoundsService;
    /**
     * If the value is true, the element gets added to the bounds of the map.
     * Default: true.
     */
    this.agmFitBounds = true;
    this._destroyed$ = new Subject();
    this._latestFitBoundsDetails = null;
  }
  /**
   * @internal
   */
  ngOnChanges() {
    this._updateBounds();
  }
  /**
   * @internal
   */
  ngOnInit() {
    this._fitBoundsAccessor.getFitBoundsDetails$().pipe(distinctUntilChanged((x, y) => x.latLng.lat === y.latLng.lat && x.latLng.lng === y.latLng.lng), takeUntil(this._destroyed$)).subscribe(details => this._updateBounds(details));
  }
  /*
   Either the location changed, or visible status changed.
   Possible state changes are
   invisible -> visible
   visible -> invisible
   visible -> visible (new location)
  */
  _updateBounds(newFitBoundsDetails) {
    // either visibility will change, or location, so remove the old one anyway
    if (this._latestFitBoundsDetails) {
      this._fitBoundsService.removeFromBounds(this._latestFitBoundsDetails.latLng);
      // don't set latestFitBoundsDetails to null, because we can toggle visibility from
      // true -> false -> true, in which case we still need old value cached here
    }
    if (newFitBoundsDetails) {
      this._latestFitBoundsDetails = newFitBoundsDetails;
    }
    if (!this._latestFitBoundsDetails) {
      return;
    }
    if (this.agmFitBounds === true) {
      this._fitBoundsService.addToBounds(this._latestFitBoundsDetails.latLng);
    }
  }
  /**
   * @internal
   */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    if (this._latestFitBoundsDetails !== null) {
      this._fitBoundsService.removeFromBounds(this._latestFitBoundsDetails.latLng);
    }
  }
};
AgmFitBounds.ctorParameters = () => [{
  type: FitBoundsAccessor,
  decorators: [{
    type: Self
  }]
}, {
  type: FitBoundsService
}];
__decorate([Input(), __metadata("design:type", Object)], AgmFitBounds.prototype, "agmFitBounds", void 0);
AgmFitBounds = __decorate([Directive({
  selector: '[agmFitBounds]'
}), __param(0, Self()), __metadata("design:paramtypes", [FitBoundsAccessor, FitBoundsService])], AgmFitBounds);
let MarkerManager = class MarkerManager {
  constructor(_mapsWrapper, _zone) {
    this._mapsWrapper = _mapsWrapper;
    this._zone = _zone;
    this._markers = new Map();
  }
  convertAnimation(uiAnim) {
    return __awaiter(this, void 0, void 0, function* () {
      if (uiAnim === null) {
        return null;
      } else {
        return this._mapsWrapper.getNativeMap().then(() => google.maps.Animation[uiAnim]);
      }
    });
  }
  deleteMarker(marker) {
    const m = this._markers.get(marker);
    if (m == null) {
      // marker already deleted
      return Promise.resolve();
    }
    return m.then(m => {
      return this._zone.run(() => {
        m.setMap(null);
        this._markers.delete(marker);
      });
    });
  }
  updateMarkerPosition(marker) {
    return this._markers.get(marker).then(m => m.setPosition({
      lat: marker.latitude,
      lng: marker.longitude
    }));
  }
  updateTitle(marker) {
    return this._markers.get(marker).then(m => m.setTitle(marker.title));
  }
  updateLabel(marker) {
    return this._markers.get(marker).then(m => {
      m.setLabel(marker.label);
    });
  }
  updateDraggable(marker) {
    return this._markers.get(marker).then(m => m.setDraggable(marker.draggable));
  }
  updateIcon(marker) {
    return this._markers.get(marker).then(m => m.setIcon(marker.iconUrl));
  }
  updateOpacity(marker) {
    return this._markers.get(marker).then(m => m.setOpacity(marker.opacity));
  }
  updateVisible(marker) {
    return this._markers.get(marker).then(m => m.setVisible(marker.visible));
  }
  updateZIndex(marker) {
    return this._markers.get(marker).then(m => m.setZIndex(marker.zIndex));
  }
  updateClickable(marker) {
    return this._markers.get(marker).then(m => m.setClickable(marker.clickable));
  }
  updateAnimation(marker) {
    return __awaiter(this, void 0, void 0, function* () {
      const m = yield this._markers.get(marker);
      m.setAnimation(yield this.convertAnimation(marker.animation));
    });
  }
  addMarker(marker) {
    const markerPromise = new Promise(resolve => __awaiter(this, void 0, void 0, function* () {
      return this._mapsWrapper.createMarker({
        position: {
          lat: marker.latitude,
          lng: marker.longitude
        },
        label: marker.label,
        draggable: marker.draggable,
        icon: marker.iconUrl,
        opacity: marker.opacity,
        visible: marker.visible,
        zIndex: marker.zIndex,
        title: marker.title,
        clickable: marker.clickable,
        animation: yield this.convertAnimation(marker.animation)
      }).then(resolve);
    }));
    this._markers.set(marker, markerPromise);
  }
  getNativeMarker(marker) {
    return this._markers.get(marker);
  }
  createEventObservable(eventName, marker) {
    return new Observable(observer => {
      this._markers.get(marker).then(m => {
        m.addListener(eventName, e => this._zone.run(() => observer.next(e)));
      });
    });
  }
};
MarkerManager.ctorParameters = () => [{
  type: GoogleMapsAPIWrapper
}, {
  type: NgZone
}];
MarkerManager = __decorate([Injectable(), __metadata("design:paramtypes", [GoogleMapsAPIWrapper, NgZone])], MarkerManager);
let InfoWindowManager = class InfoWindowManager {
  constructor(_mapsWrapper, _zone, _markerManager) {
    this._mapsWrapper = _mapsWrapper;
    this._zone = _zone;
    this._markerManager = _markerManager;
    this._infoWindows = new Map();
  }
  deleteInfoWindow(infoWindow) {
    const iWindow = this._infoWindows.get(infoWindow);
    if (iWindow == null) {
      // info window already deleted
      return Promise.resolve();
    }
    return iWindow.then(i => {
      return this._zone.run(() => {
        i.close();
        this._infoWindows.delete(infoWindow);
      });
    });
  }
  setPosition(infoWindow) {
    return this._infoWindows.get(infoWindow).then(i => i.setPosition({
      lat: infoWindow.latitude,
      lng: infoWindow.longitude
    }));
  }
  setZIndex(infoWindow) {
    return this._infoWindows.get(infoWindow).then(i => i.setZIndex(infoWindow.zIndex));
  }
  open(infoWindow) {
    return this._infoWindows.get(infoWindow).then(w => {
      if (infoWindow.hostMarker != null) {
        return this._markerManager.getNativeMarker(infoWindow.hostMarker).then(marker => {
          return this._mapsWrapper.getNativeMap().then(map => w.open(map, marker));
        });
      }
      return this._mapsWrapper.getNativeMap().then(map => w.open(map));
    });
  }
  close(infoWindow) {
    return this._infoWindows.get(infoWindow).then(w => w.close());
  }
  setOptions(infoWindow, options) {
    return this._infoWindows.get(infoWindow).then(i => i.setOptions(options));
  }
  addInfoWindow(infoWindow) {
    const options = {
      content: infoWindow.content,
      maxWidth: infoWindow.maxWidth,
      zIndex: infoWindow.zIndex,
      disableAutoPan: infoWindow.disableAutoPan
    };
    if (typeof infoWindow.latitude === 'number' && typeof infoWindow.longitude === 'number') {
      options.position = {
        lat: infoWindow.latitude,
        lng: infoWindow.longitude
      };
    }
    const infoWindowPromise = this._mapsWrapper.createInfoWindow(options);
    this._infoWindows.set(infoWindow, infoWindowPromise);
  }
  /**
   * Creates a Google Maps event listener for the given InfoWindow as an Observable
   */
  createEventObservable(eventName, infoWindow) {
    return new Observable(observer => {
      this._infoWindows.get(infoWindow).then(i => {
        i.addListener(eventName, e => this._zone.run(() => observer.next(e)));
      });
    });
  }
};
InfoWindowManager.ctorParameters = () => [{
  type: GoogleMapsAPIWrapper
}, {
  type: NgZone
}, {
  type: MarkerManager
}];
InfoWindowManager = __decorate([Injectable(), __metadata("design:paramtypes", [GoogleMapsAPIWrapper, NgZone, MarkerManager])], InfoWindowManager);
var AgmInfoWindow_1;
let infoWindowId = 0;
/**
 * AgmInfoWindow renders a info window inside a {@link AgmMarker} or standalone.
 *
 * ### Example
 * ```typescript
 * import { Component } from '@angular/core';
 *
 * @Component({
 *  selector: 'my-map-cmp',
 *  styles: [`
 *    .agm-map-container {
 *      height: 300px;
 *    }
 * `],
 *  template: `
 *    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
 *      <agm-marker [latitude]="lat" [longitude]="lng" [label]="'M'">
 *        <agm-info-window [disableAutoPan]="true">
 *          Hi, this is the content of the <strong>info window</strong>
 *        </agm-info-window>
 *      </agm-marker>
 *    </agm-map>
 *  `
 * })
 * ```
 */
let AgmInfoWindow = AgmInfoWindow_1 = class AgmInfoWindow {
  constructor(_infoWindowManager, _el) {
    this._infoWindowManager = _infoWindowManager;
    this._el = _el;
    /**
     * Sets the open state for the InfoWindow. You can also call the open() and close() methods.
     */
    this.isOpen = false;
    /**
     * Emits an event when the info window is closed.
     */
    this.infoWindowClose = new EventEmitter();
    this._infoWindowAddedToManager = false;
    this._id = (infoWindowId++).toString();
  }
  ngOnInit() {
    this.content = this._el.nativeElement.querySelector('.agm-info-window-content');
    this._infoWindowManager.addInfoWindow(this);
    this._infoWindowAddedToManager = true;
    this._updateOpenState();
    this._registerEventListeners();
  }
  /** @internal */
  ngOnChanges(changes) {
    if (!this._infoWindowAddedToManager) {
      return;
    }
    if ((changes['latitude'] || changes['longitude']) && typeof this.latitude === 'number' && typeof this.longitude === 'number') {
      this._infoWindowManager.setPosition(this);
    }
    if (changes['zIndex']) {
      this._infoWindowManager.setZIndex(this);
    }
    if (changes['isOpen']) {
      this._updateOpenState();
    }
    this._setInfoWindowOptions(changes);
  }
  _registerEventListeners() {
    this._infoWindowManager.createEventObservable('closeclick', this).subscribe(() => {
      this.isOpen = false;
      this.infoWindowClose.emit();
    });
  }
  _updateOpenState() {
    this.isOpen ? this.open() : this.close();
  }
  _setInfoWindowOptions(changes) {
    let options = {};
    let optionKeys = Object.keys(changes).filter(k => AgmInfoWindow_1._infoWindowOptionsInputs.indexOf(k) !== -1);
    optionKeys.forEach(k => {
      options[k] = changes[k].currentValue;
    });
    this._infoWindowManager.setOptions(this, options);
  }
  /**
   * Opens the info window.
   */
  open() {
    return this._infoWindowManager.open(this);
  }
  /**
   * Closes the info window.
   */
  close() {
    return this._infoWindowManager.close(this).then(() => {
      this.infoWindowClose.emit();
    });
  }
  /** @internal */
  id() {
    return this._id;
  }
  /** @internal */
  toString() {
    return 'AgmInfoWindow-' + this._id.toString();
  }
  /** @internal */
  ngOnDestroy() {
    this._infoWindowManager.deleteInfoWindow(this);
  }
};
AgmInfoWindow._infoWindowOptionsInputs = ['disableAutoPan', 'maxWidth'];
AgmInfoWindow.ctorParameters = () => [{
  type: InfoWindowManager
}, {
  type: ElementRef
}];
__decorate([Input(), __metadata("design:type", Number)], AgmInfoWindow.prototype, "latitude", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmInfoWindow.prototype, "longitude", void 0);
__decorate([Input(), __metadata("design:type", Boolean)], AgmInfoWindow.prototype, "disableAutoPan", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmInfoWindow.prototype, "zIndex", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmInfoWindow.prototype, "maxWidth", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmInfoWindow.prototype, "isOpen", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmInfoWindow.prototype, "infoWindowClose", void 0);
AgmInfoWindow = AgmInfoWindow_1 = __decorate([Component({
  selector: 'agm-info-window',
  template: `<div class='agm-info-window-content'>
      <ng-content></ng-content>
    </div>
  `
}), __metadata("design:paramtypes", [InfoWindowManager, ElementRef])], AgmInfoWindow);

/**
 * Manages all KML Layers for a Google Map instance.
 */
let KmlLayerManager = class KmlLayerManager {
  constructor(_wrapper, _zone) {
    this._wrapper = _wrapper;
    this._zone = _zone;
    this._layers = new Map();
  }
  /**
   * Adds a new KML Layer to the map.
   */
  addKmlLayer(layer) {
    const newLayer = this._wrapper.getNativeMap().then(m => {
      return new google.maps.KmlLayer({
        clickable: layer.clickable,
        map: m,
        preserveViewport: layer.preserveViewport,
        screenOverlays: layer.screenOverlays,
        suppressInfoWindows: layer.suppressInfoWindows,
        url: layer.url,
        zIndex: layer.zIndex
      });
    });
    this._layers.set(layer, newLayer);
  }
  setOptions(layer, options) {
    this._layers.get(layer).then(l => l.setOptions(options));
  }
  deleteKmlLayer(layer) {
    this._layers.get(layer).then(l => {
      l.setMap(null);
      this._layers.delete(layer);
    });
  }
  /**
   * Creates a Google Maps event listener for the given KmlLayer as an Observable
   */
  createEventObservable(eventName, layer) {
    return new Observable(observer => {
      this._layers.get(layer).then(m => {
        m.addListener(eventName, e => this._zone.run(() => observer.next(e)));
      });
    });
  }
};
KmlLayerManager.ctorParameters = () => [{
  type: GoogleMapsAPIWrapper
}, {
  type: NgZone
}];
KmlLayerManager = __decorate([Injectable(), __metadata("design:paramtypes", [GoogleMapsAPIWrapper, NgZone])], KmlLayerManager);
var AgmKmlLayer_1;
let layerId$2 = 0;
let AgmKmlLayer = AgmKmlLayer_1 = class AgmKmlLayer {
  constructor(_manager) {
    this._manager = _manager;
    this._addedToManager = false;
    this._id = (layerId$2++).toString();
    this._subscriptions = [];
    /**
     * If true, the layer receives mouse events. Default value is true.
     */
    this.clickable = true;
    /**
     * By default, the input map is centered and zoomed to the bounding box of the contents of the
     * layer.
     * If this option is set to true, the viewport is left unchanged, unless the map's center and zoom
     * were never set.
     */
    this.preserveViewport = false;
    /**
     * Whether to render the screen overlays. Default true.
     */
    this.screenOverlays = true;
    /**
     * Suppress the rendering of info windows when layer features are clicked.
     */
    this.suppressInfoWindows = false;
    /**
     * The URL of the KML document to display.
     */
    this.url = null;
    /**
     * The z-index of the layer.
     */
    this.zIndex = null;
    /**
     * This event is fired when a feature in the layer is clicked.
     */
    this.layerClick = new EventEmitter();
    /**
     * This event is fired when the KML layers default viewport has changed.
     */
    this.defaultViewportChange = new EventEmitter();
    /**
     * This event is fired when the KML layer has finished loading.
     * At this point it is safe to read the status property to determine if the layer loaded
     * successfully.
     */
    this.statusChange = new EventEmitter();
  }
  ngOnInit() {
    if (this._addedToManager) {
      return;
    }
    this._manager.addKmlLayer(this);
    this._addedToManager = true;
    this._addEventListeners();
  }
  ngOnChanges(changes) {
    if (!this._addedToManager) {
      return;
    }
    this._updatePolygonOptions(changes);
  }
  _updatePolygonOptions(changes) {
    const options = Object.keys(changes).filter(k => AgmKmlLayer_1._kmlLayerOptions.indexOf(k) !== -1).reduce((obj, k) => {
      obj[k] = changes[k].currentValue;
      return obj;
    }, {});
    if (Object.keys(options).length > 0) {
      this._manager.setOptions(this, options);
    }
  }
  _addEventListeners() {
    const listeners = [{
      name: 'click',
      handler: ev => this.layerClick.emit(ev)
    }, {
      name: 'defaultviewport_changed',
      handler: () => this.defaultViewportChange.emit()
    }, {
      name: 'status_changed',
      handler: () => this.statusChange.emit()
    }];
    listeners.forEach(obj => {
      const os = this._manager.createEventObservable(obj.name, this).subscribe(obj.handler);
      this._subscriptions.push(os);
    });
  }
  /** @internal */
  id() {
    return this._id;
  }
  /** @internal */
  toString() {
    return `AgmKmlLayer-${this._id.toString()}`;
  }
  /** @internal */
  ngOnDestroy() {
    this._manager.deleteKmlLayer(this);
    // unsubscribe all registered observable subscriptions
    this._subscriptions.forEach(s => s.unsubscribe());
  }
};
AgmKmlLayer._kmlLayerOptions = ['clickable', 'preserveViewport', 'screenOverlays', 'suppressInfoWindows', 'url', 'zIndex'];
AgmKmlLayer.ctorParameters = () => [{
  type: KmlLayerManager
}];
__decorate([Input(), __metadata("design:type", Object)], AgmKmlLayer.prototype, "clickable", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmKmlLayer.prototype, "preserveViewport", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmKmlLayer.prototype, "screenOverlays", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmKmlLayer.prototype, "suppressInfoWindows", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmKmlLayer.prototype, "url", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmKmlLayer.prototype, "zIndex", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmKmlLayer.prototype, "layerClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmKmlLayer.prototype, "defaultViewportChange", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmKmlLayer.prototype, "statusChange", void 0);
AgmKmlLayer = AgmKmlLayer_1 = __decorate([Directive({
  selector: 'agm-kml-layer'
}), __metadata("design:paramtypes", [KmlLayerManager])], AgmKmlLayer);
function createMVCEventObservable(array) {
  const eventNames = ['insert_at', 'remove_at', 'set_at'];
  return fromEventPattern(handler => eventNames.map(evName => array.addListener(evName, (index, previous) => handler.apply(array, [{
    'newArr': array.getArray(),
    evName,
    index,
    previous
  }]))), (_handler, evListeners) => evListeners.forEach(evListener => evListener.remove()));
}
class MvcArrayMock {
  constructor() {
    this.vals = [];
    this.listeners = {
      'remove_at': [],
      'insert_at': [],
      'set_at': []
    };
  }
  clear() {
    for (let i = this.vals.length - 1; i >= 0; i--) {
      this.removeAt(i);
    }
  }
  getArray() {
    return [...this.vals];
  }
  getAt(i) {
    return this.vals[i];
  }
  getLength() {
    return this.vals.length;
  }
  insertAt(i, elem) {
    this.vals.splice(i, 0, elem);
    this.listeners.insert_at.map(listener => listener(i));
  }
  pop() {
    const deleted = this.vals.pop();
    this.listeners.remove_at.map(listener => listener(this.vals.length, deleted));
    return deleted;
  }
  push(elem) {
    this.vals.push(elem);
    this.listeners.insert_at.map(listener => listener(this.vals.length - 1));
    return this.vals.length;
  }
  removeAt(i) {
    const deleted = this.vals.splice(i, 1)[0];
    this.listeners.remove_at.map(listener => listener(i, deleted));
    return deleted;
  }
  setAt(i, elem) {
    const deleted = this.vals[i];
    this.vals[i] = elem;
    this.listeners.set_at.map(listener => listener(i, deleted));
  }
  forEach(callback) {
    this.vals.forEach(callback);
  }
  addListener(eventName, handler) {
    const listenerArr = this.listeners[eventName];
    listenerArr.push(handler);
    return {
      remove: () => {
        listenerArr.splice(listenerArr.indexOf(handler), 1);
      }
    };
  }
}
let PolygonManager = class PolygonManager {
  constructor(_mapsWrapper, _zone) {
    this._mapsWrapper = _mapsWrapper;
    this._zone = _zone;
    this._polygons = new Map();
  }
  addPolygon(path) {
    const polygonPromise = this._mapsWrapper.createPolygon({
      clickable: path.clickable,
      draggable: path.draggable,
      editable: path.editable,
      fillColor: path.fillColor,
      fillOpacity: path.fillOpacity,
      geodesic: path.geodesic,
      paths: path.paths,
      strokeColor: path.strokeColor,
      strokeOpacity: path.strokeOpacity,
      strokeWeight: path.strokeWeight,
      visible: path.visible,
      zIndex: path.zIndex
    });
    this._polygons.set(path, polygonPromise);
  }
  updatePolygon(polygon) {
    const m = this._polygons.get(polygon);
    if (m == null) {
      return Promise.resolve();
    }
    return m.then(l => this._zone.run(() => {
      l.setPaths(polygon.paths);
    }));
  }
  setPolygonOptions(path, options) {
    return this._polygons.get(path).then(l => {
      l.setOptions(options);
    });
  }
  deletePolygon(paths) {
    const m = this._polygons.get(paths);
    if (m == null) {
      return Promise.resolve();
    }
    return m.then(l => {
      return this._zone.run(() => {
        l.setMap(null);
        this._polygons.delete(paths);
      });
    });
  }
  getPath(polygon) {
    return this._polygons.get(polygon).then(polygon => polygon.getPath().getArray());
  }
  getPaths(polygon) {
    return this._polygons.get(polygon).then(polygon => polygon.getPaths().getArray().map(p => p.getArray()));
  }
  createEventObservable(eventName, path) {
    return new Observable(observer => {
      this._polygons.get(path).then(l => {
        l.addListener(eventName, e => this._zone.run(() => observer.next(e)));
      });
    });
  }
  createPathEventObservable(agmPolygon) {
    return __awaiter(this, void 0, void 0, function* () {
      const polygon = yield this._polygons.get(agmPolygon);
      const paths = polygon.getPaths();
      const pathsChanges$ = createMVCEventObservable(paths);
      return pathsChanges$.pipe(startWith({
        newArr: paths.getArray()
      }),
      // in order to subscribe to them all
      switchMap(parentMVEvent => merge(...
      // rest parameter
      parentMVEvent.newArr.map((chMVC, index) => createMVCEventObservable(chMVC).pipe(map(chMVCEvent => ({
        parentMVEvent,
        chMVCEvent,
        pathIndex: index
      }))))).pipe(startWith({
        parentMVEvent,
        chMVCEvent: null,
        pathIndex: null
      }))),
      // start the merged ob with an event signinifing change to parent
      skip(1),
      // skip the manually added event
      map(({
        parentMVEvent,
        chMVCEvent,
        pathIndex
      }) => {
        let retVal;
        if (!chMVCEvent) {
          retVal = {
            newArr: parentMVEvent.newArr.map(subArr => subArr.getArray().map(latLng => latLng.toJSON())),
            eventName: parentMVEvent.evName,
            index: parentMVEvent.index
          };
          if (parentMVEvent.previous) {
            retVal.previous = parentMVEvent.previous.getArray();
          }
        } else {
          retVal = {
            newArr: parentMVEvent.newArr.map(subArr => subArr.getArray().map(latLng => latLng.toJSON())),
            pathIndex,
            eventName: chMVCEvent.evName,
            index: chMVCEvent.index
          };
          if (chMVCEvent.previous) {
            retVal.previous = chMVCEvent.previous;
          }
        }
        return retVal;
      }));
    });
  }
};
PolygonManager.ctorParameters = () => [{
  type: GoogleMapsAPIWrapper
}, {
  type: NgZone
}];
PolygonManager = __decorate([Injectable(), __metadata("design:paramtypes", [GoogleMapsAPIWrapper, NgZone])], PolygonManager);
var PolylineManager_1;
let PolylineManager = PolylineManager_1 = class PolylineManager {
  constructor(_mapsWrapper, _zone) {
    this._mapsWrapper = _mapsWrapper;
    this._zone = _zone;
    this._polylines = new Map();
  }
  static _convertPoints(line) {
    const path = line._getPoints().map(point => {
      return {
        lat: point.latitude,
        lng: point.longitude
      };
    });
    return path;
  }
  static _convertPath(path) {
    const symbolPath = google.maps.SymbolPath[path];
    if (typeof symbolPath === 'number') {
      return symbolPath;
    } else {
      return path;
    }
  }
  static _convertIcons(line) {
    const icons = line._getIcons().map(agmIcon => ({
      fixedRotation: agmIcon.fixedRotation,
      offset: agmIcon.offset,
      repeat: agmIcon.repeat,
      icon: {
        anchor: new google.maps.Point(agmIcon.anchorX, agmIcon.anchorY),
        fillColor: agmIcon.fillColor,
        fillOpacity: agmIcon.fillOpacity,
        path: PolylineManager_1._convertPath(agmIcon.path),
        rotation: agmIcon.rotation,
        scale: agmIcon.scale,
        strokeColor: agmIcon.strokeColor,
        strokeOpacity: agmIcon.strokeOpacity,
        strokeWeight: agmIcon.strokeWeight
      }
    }));
    // prune undefineds;
    icons.forEach(icon => {
      Object.entries(icon).forEach(([key, val]) => {
        if (typeof val === 'undefined') {
          delete icon[key];
        }
      });
      if (typeof icon.icon.anchor.x === 'undefined' || typeof icon.icon.anchor.y === 'undefined') {
        delete icon.icon.anchor;
      }
    });
    return icons;
  }
  addPolyline(line) {
    const polylinePromise = this._mapsWrapper.getNativeMap().then(() => [PolylineManager_1._convertPoints(line), PolylineManager_1._convertIcons(line)]).then(([path, icons]) => this._mapsWrapper.createPolyline({
      clickable: line.clickable,
      draggable: line.draggable,
      editable: line.editable,
      geodesic: line.geodesic,
      strokeColor: line.strokeColor,
      strokeOpacity: line.strokeOpacity,
      strokeWeight: line.strokeWeight,
      visible: line.visible,
      zIndex: line.zIndex,
      path: path,
      icons: icons
    }));
    this._polylines.set(line, polylinePromise);
  }
  updatePolylinePoints(line) {
    const path = PolylineManager_1._convertPoints(line);
    const m = this._polylines.get(line);
    if (m == null) {
      return Promise.resolve();
    }
    return m.then(l => {
      return this._zone.run(() => {
        l.setPath(path);
      });
    });
  }
  updateIconSequences(line) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this._mapsWrapper.getNativeMap();
      const icons = PolylineManager_1._convertIcons(line);
      const m = this._polylines.get(line);
      if (m == null) {
        return;
      }
      return m.then(l => this._zone.run(() => l.setOptions({
        icons: icons
      })));
    });
  }
  setPolylineOptions(line, options) {
    return this._polylines.get(line).then(l => {
      l.setOptions(options);
    });
  }
  deletePolyline(line) {
    const m = this._polylines.get(line);
    if (m == null) {
      return Promise.resolve();
    }
    return m.then(l => {
      return this._zone.run(() => {
        l.setMap(null);
        this._polylines.delete(line);
      });
    });
  }
  getMVCPath(agmPolyline) {
    return __awaiter(this, void 0, void 0, function* () {
      const polyline = yield this._polylines.get(agmPolyline);
      return polyline.getPath();
    });
  }
  getPath(agmPolyline) {
    return __awaiter(this, void 0, void 0, function* () {
      return (yield this.getMVCPath(agmPolyline)).getArray();
    });
  }
  createEventObservable(eventName, line) {
    return new Observable(observer => {
      this._polylines.get(line).then(l => {
        l.addListener(eventName, e => this._zone.run(() => observer.next(e)));
      });
    });
  }
  createPathEventObservable(line) {
    return __awaiter(this, void 0, void 0, function* () {
      const mvcPath = yield this.getMVCPath(line);
      return createMVCEventObservable(mvcPath);
    });
  }
};
PolylineManager.ctorParameters = () => [{
  type: GoogleMapsAPIWrapper
}, {
  type: NgZone
}];
PolylineManager = PolylineManager_1 = __decorate([Injectable(), __metadata("design:paramtypes", [GoogleMapsAPIWrapper, NgZone])], PolylineManager);
let RectangleManager = class RectangleManager {
  constructor(_apiWrapper, _zone) {
    this._apiWrapper = _apiWrapper;
    this._zone = _zone;
    this._rectangles = new Map();
  }
  addRectangle(rectangle) {
    this._rectangles.set(rectangle, this._apiWrapper.createRectangle({
      bounds: {
        north: rectangle.north,
        east: rectangle.east,
        south: rectangle.south,
        west: rectangle.west
      },
      clickable: rectangle.clickable,
      draggable: rectangle.draggable,
      editable: rectangle.editable,
      fillColor: rectangle.fillColor,
      fillOpacity: rectangle.fillOpacity,
      strokeColor: rectangle.strokeColor,
      strokeOpacity: rectangle.strokeOpacity,
      strokePosition: rectangle.strokePosition,
      strokeWeight: rectangle.strokeWeight,
      visible: rectangle.visible,
      zIndex: rectangle.zIndex
    }));
  }
  /**
   * Removes the given rectangle from the map.
   */
  removeRectangle(rectangle) {
    return this._rectangles.get(rectangle).then(r => {
      r.setMap(null);
      this._rectangles.delete(rectangle);
    });
  }
  setOptions(rectangle, options) {
    return this._rectangles.get(rectangle).then(r => r.setOptions(options));
  }
  getBounds(rectangle) {
    return this._rectangles.get(rectangle).then(r => r.getBounds());
  }
  setBounds(rectangle) {
    return this._rectangles.get(rectangle).then(r => {
      return r.setBounds({
        north: rectangle.north,
        east: rectangle.east,
        south: rectangle.south,
        west: rectangle.west
      });
    });
  }
  setEditable(rectangle) {
    return this._rectangles.get(rectangle).then(r => {
      return r.setEditable(rectangle.editable);
    });
  }
  setDraggable(rectangle) {
    return this._rectangles.get(rectangle).then(r => {
      return r.setDraggable(rectangle.draggable);
    });
  }
  setVisible(rectangle) {
    return this._rectangles.get(rectangle).then(r => {
      return r.setVisible(rectangle.visible);
    });
  }
  createEventObservable(eventName, rectangle) {
    return Observable.create(observer => {
      let listener = null;
      this._rectangles.get(rectangle).then(r => {
        listener = r.addListener(eventName, e => this._zone.run(() => observer.next(e)));
      });
      return () => {
        if (listener !== null) {
          listener.remove();
        }
      };
    });
  }
};
RectangleManager.ctorParameters = () => [{
  type: GoogleMapsAPIWrapper
}, {
  type: NgZone
}];
RectangleManager = __decorate([Injectable(), __metadata("design:paramtypes", [GoogleMapsAPIWrapper, NgZone])], RectangleManager);
var AgmMap_1;
/**
 * AgmMap renders a Google Map.
 * **Important note**: To be able see a map in the browser, you have to define a height for the
 * element `agm-map`.
 *
 * ### Example
 * ```typescript
 * import { Component } from '@angular/core';
 *
 * @Component({
 *  selector: 'my-map-cmp',
 *  styles: [`
 *    agm-map {
 *      height: 300px;
 *    }
 * `],
 *  template: `
 *    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
 *    </agm-map>
 *  `
 * })
 * ```
 */
let AgmMap = AgmMap_1 = class AgmMap {
  constructor(_elem, _mapsWrapper, _platformId, _fitBoundsService, _zone) {
    this._elem = _elem;
    this._mapsWrapper = _mapsWrapper;
    this._platformId = _platformId;
    this._fitBoundsService = _fitBoundsService;
    this._zone = _zone;
    /**
     * The longitude that defines the center of the map.
     */
    this.longitude = 0;
    /**
     * The latitude that defines the center of the map.
     */
    this.latitude = 0;
    /**
     * The zoom level of the map. The default zoom level is 8.
     */
    this.zoom = 8;
    /**
     * Enables/disables if map is draggable.
     */
    // tslint:disable-next-line:no-input-rename
    this.draggable = true;
    /**
     * Enables/disables zoom and center on double click. Enabled by default.
     */
    this.disableDoubleClickZoom = false;
    /**
     * Enables/disables all default UI of the Google map. Please note: When the map is created, this
     * value cannot get updated.
     */
    this.disableDefaultUI = false;
    /**
     * If false, disables scrollwheel zooming on the map. The scrollwheel is enabled by default.
     */
    this.scrollwheel = true;
    /**
     * If false, prevents the map from being controlled by the keyboard. Keyboard shortcuts are
     * enabled by default.
     */
    this.keyboardShortcuts = true;
    /**
     * Styles to apply to each of the default map types. Note that for Satellite/Hybrid and Terrain
     * modes, these styles will only apply to labels and geometry.
     */
    this.styles = [];
    /**
     * When true and the latitude and/or longitude values changes, the Google Maps panTo method is
     * used to
     * center the map. See: https://developers.google.com/maps/documentation/javascript/reference#Map
     */
    this.usePanning = false;
    /**
     * Sets the viewport to contain the given bounds.
     * If this option to `true`, the bounds get automatically computed from all elements that use the {@link AgmFitBounds} directive.
     */
    this.fitBounds = false;
    /**
     * The initial enabled/disabled state of the Scale control. This is disabled by default.
     */
    this.scaleControl = false;
    /**
     * The initial enabled/disabled state of the Map type control.
     */
    this.mapTypeControl = false;
    /**
     * The initial enabled/disabled state of the Pan control.
     */
    this.panControl = false;
    /**
     * The initial enabled/disabled state of the Rotate control.
     */
    this.rotateControl = false;
    /**
     * The initial enabled/disabled state of the Fullscreen control.
     */
    this.fullscreenControl = false;
    /**
     * The map mapTypeId. Defaults to 'roadmap'.
     */
    this.mapTypeId = 'roadmap';
    /**
     * When false, map icons are not clickable. A map icon represents a point of interest,
     * also known as a POI. By default map icons are clickable.
     */
    this.clickableIcons = true;
    /**
     * A map icon represents a point of interest, also known as a POI.
     * When map icons are clickable by default, an info window is displayed.
     * When this property is set to false, the info window will not be shown but the click event
     * will still fire
     */
    this.showDefaultInfoWindow = true;
    /**
     * This setting controls how gestures on the map are handled.
     * Allowed values:
     * - 'cooperative' (Two-finger touch gestures pan and zoom the map. One-finger touch gestures are not handled by the map.)
     * - 'greedy'      (All touch gestures pan or zoom the map.)
     * - 'none'        (The map cannot be panned or zoomed by user gestures.)
     * - 'auto'        [default] (Gesture handling is either cooperative or greedy, depending on whether the page is scrollable or not.
     */
    this.gestureHandling = 'auto';
    /**
     * Controls the automatic switching behavior for the angle of incidence of
     * the map. The only allowed values are 0 and 45. The value 0 causes the map
     * to always use a 0° overhead view regardless of the zoom level and
     * viewport. The value 45 causes the tilt angle to automatically switch to
     * 45 whenever 45° imagery is available for the current zoom level and
     * viewport, and switch back to 0 whenever 45° imagery is not available
     * (this is the default behavior). 45° imagery is only available for
     * satellite and hybrid map types, within some locations, and at some zoom
     * levels. Note: getTilt returns the current tilt angle, not the value
     * specified by this option. Because getTilt and this option refer to
     * different things, do not bind() the tilt property; doing so may yield
     * unpredictable effects. (Default of AGM is 0 (disabled). Enable it with value 45.)
     */
    this.tilt = 0;
    this._observableSubscriptions = [];
    /**
     * This event emitter gets emitted when the user clicks on the map (but not when they click on a
     * marker or infoWindow).
     */
    this.mapClick = new EventEmitter();
    /**
     * This event emitter gets emitted when the user right-clicks on the map (but not when they click
     * on a marker or infoWindow).
     */
    this.mapRightClick = new EventEmitter();
    /**
     * This event emitter gets emitted when the user double-clicks on the map (but not when they click
     * on a marker or infoWindow).
     */
    this.mapDblClick = new EventEmitter();
    /**
     * This event emitter is fired when the map center changes.
     */
    this.centerChange = new EventEmitter();
    /**
     * This event is fired when the viewport bounds have changed.
     */
    this.boundsChange = new EventEmitter();
    /**
     * This event is fired when the mapTypeId property changes.
     */
    this.mapTypeIdChange = new EventEmitter();
    /**
     * This event is fired when the map becomes idle after panning or zooming.
     */
    this.idle = new EventEmitter();
    /**
     * This event is fired when the zoom level has changed.
     */
    this.zoomChange = new EventEmitter();
    /**
     * This event is fired when the google map is fully initialized.
     * You get the google.maps.Map instance as a result of this EventEmitter.
     */
    this.mapReady = new EventEmitter();
    /**
     * This event is fired when the visible tiles have finished loading.
     */
    this.tilesLoaded = new EventEmitter();
  }
  /** @internal */
  ngOnInit() {
    if (isPlatformServer(this._platformId)) {
      // The code is running on the server, do nothing
      return;
    }
    // todo: this should be solved with a new component and a viewChild decorator
    const container = this._elem.nativeElement.querySelector('.agm-map-container-inner');
    this._initMapInstance(container);
  }
  _initMapInstance(el) {
    this._mapsWrapper.createMap(el, {
      center: {
        lat: this.latitude || 0,
        lng: this.longitude || 0
      },
      zoom: this.zoom,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      controlSize: this.controlSize,
      disableDefaultUI: this.disableDefaultUI,
      disableDoubleClickZoom: this.disableDoubleClickZoom,
      scrollwheel: this.scrollwheel,
      backgroundColor: this.backgroundColor,
      draggable: this.draggable,
      draggableCursor: this.draggableCursor,
      draggingCursor: this.draggingCursor,
      keyboardShortcuts: this.keyboardShortcuts,
      styles: this.styles,
      zoomControl: this.zoomControl,
      zoomControlOptions: this.zoomControlOptions,
      streetViewControl: this.streetViewControl,
      streetViewControlOptions: this.streetViewControlOptions,
      scaleControl: this.scaleControl,
      scaleControlOptions: this.scaleControlOptions,
      mapTypeControl: this.mapTypeControl,
      mapTypeControlOptions: this.mapTypeControlOptions,
      panControl: this.panControl,
      panControlOptions: this.panControlOptions,
      rotateControl: this.rotateControl,
      rotateControlOptions: this.rotateControlOptions,
      fullscreenControl: this.fullscreenControl,
      fullscreenControlOptions: this.fullscreenControlOptions,
      mapTypeId: this.mapTypeId,
      clickableIcons: this.clickableIcons,
      gestureHandling: this.gestureHandling,
      tilt: this.tilt,
      restriction: this.restriction
    }).then(() => this._mapsWrapper.getNativeMap()).then(map => this.mapReady.emit(map));
    // register event listeners
    this._handleMapCenterChange();
    this._handleMapZoomChange();
    this._handleMapMouseEvents();
    this._handleBoundsChange();
    this._handleMapTypeIdChange();
    this._handleTilesLoadedEvent();
    this._handleIdleEvent();
  }
  /** @internal */
  ngOnDestroy() {
    // unsubscribe all registered observable subscriptions
    this._observableSubscriptions.forEach(s => s.unsubscribe());
    // remove all listeners from the map instance
    this._mapsWrapper.clearInstanceListeners();
    if (this._fitBoundsSubscription) {
      this._fitBoundsSubscription.unsubscribe();
    }
  }
  /* @internal */
  ngOnChanges(changes) {
    this._updateMapOptionsChanges(changes);
    this._updatePosition(changes);
  }
  _updateMapOptionsChanges(changes) {
    let options = {};
    let optionKeys = Object.keys(changes).filter(k => AgmMap_1._mapOptionsAttributes.indexOf(k) !== -1);
    optionKeys.forEach(k => {
      options[k] = changes[k].currentValue;
    });
    this._mapsWrapper.setMapOptions(options);
  }
  /**
   * Triggers a resize event on the google map instance.
   * When recenter is true, the of the google map gets called with the current lat/lng values or fitBounds value to recenter the map.
   * Returns a promise that gets resolved after the event was triggered.
   */
  triggerResize(recenter = true) {
    // Note: When we would trigger the resize event and show the map in the same turn (which is a
    // common case for triggering a resize event), then the resize event would not
    // work (to show the map), so we trigger the event in a timeout.
    return new Promise(resolve => {
      setTimeout(() => {
        return this._mapsWrapper.triggerMapEvent('resize').then(() => {
          if (recenter) {
            this.fitBounds != null ? this._fitBounds() : this._setCenter();
          }
          resolve();
        });
      });
    });
  }
  _updatePosition(changes) {
    if (changes['latitude'] == null && changes['longitude'] == null && !changes['fitBounds']) {
      // no position update needed
      return;
    }
    // we prefer fitBounds in changes
    if ('fitBounds' in changes) {
      this._fitBounds();
      return;
    }
    if (typeof this.latitude !== 'number' || typeof this.longitude !== 'number') {
      return;
    }
    this._setCenter();
  }
  _setCenter() {
    let newCenter = {
      lat: this.latitude,
      lng: this.longitude
    };
    if (this.usePanning) {
      this._mapsWrapper.panTo(newCenter);
    } else {
      this._mapsWrapper.setCenter(newCenter);
    }
  }
  _fitBounds() {
    switch (this.fitBounds) {
      case true:
        this._subscribeToFitBoundsUpdates();
        break;
      case false:
        if (this._fitBoundsSubscription) {
          this._fitBoundsSubscription.unsubscribe();
        }
        break;
      default:
        this._updateBounds(this.fitBounds, this.fitBoundsPadding);
    }
  }
  _subscribeToFitBoundsUpdates() {
    this._zone.runOutsideAngular(() => {
      this._fitBoundsSubscription = this._fitBoundsService.getBounds$().subscribe(b => {
        this._zone.run(() => this._updateBounds(b, this.fitBoundsPadding));
      });
    });
  }
  _updateBounds(bounds, padding) {
    if (!bounds) {
      return;
    }
    if (this._isLatLngBoundsLiteral(bounds) && typeof google !== 'undefined' && google && google.maps && google.maps.LatLngBounds) {
      const newBounds = new google.maps.LatLngBounds();
      newBounds.union(bounds);
      bounds = newBounds;
    }
    if (this.usePanning) {
      this._mapsWrapper.panToBounds(bounds, padding);
      return;
    }
    this._mapsWrapper.fitBounds(bounds, padding);
  }
  _isLatLngBoundsLiteral(bounds) {
    return bounds != null && bounds.extend === undefined;
  }
  _handleMapCenterChange() {
    const s = this._mapsWrapper.subscribeToMapEvent('center_changed').subscribe(() => {
      this._mapsWrapper.getCenter().then(center => {
        this.latitude = center.lat();
        this.longitude = center.lng();
        this.centerChange.emit({
          lat: this.latitude,
          lng: this.longitude
        });
      });
    });
    this._observableSubscriptions.push(s);
  }
  _handleBoundsChange() {
    const s = this._mapsWrapper.subscribeToMapEvent('bounds_changed').subscribe(() => {
      this._mapsWrapper.getBounds().then(bounds => {
        this.boundsChange.emit(bounds);
      });
    });
    this._observableSubscriptions.push(s);
  }
  _handleMapTypeIdChange() {
    const s = this._mapsWrapper.subscribeToMapEvent('maptypeid_changed').subscribe(() => {
      this._mapsWrapper.getMapTypeId().then(mapTypeId => {
        this.mapTypeIdChange.emit(mapTypeId);
      });
    });
    this._observableSubscriptions.push(s);
  }
  _handleMapZoomChange() {
    const s = this._mapsWrapper.subscribeToMapEvent('zoom_changed').subscribe(() => {
      this._mapsWrapper.getZoom().then(z => {
        this.zoom = z;
        this.zoomChange.emit(z);
      });
    });
    this._observableSubscriptions.push(s);
  }
  _handleIdleEvent() {
    const s = this._mapsWrapper.subscribeToMapEvent('idle').subscribe(() => {
      this.idle.emit(void 0);
    });
    this._observableSubscriptions.push(s);
  }
  _handleTilesLoadedEvent() {
    const s = this._mapsWrapper.subscribeToMapEvent('tilesloaded').subscribe(() => this.tilesLoaded.emit(void 0));
    this._observableSubscriptions.push(s);
  }
  _handleMapMouseEvents() {
    const events = [{
      name: 'click',
      emitter: this.mapClick
    }, {
      name: 'rightclick',
      emitter: this.mapRightClick
    }, {
      name: 'dblclick',
      emitter: this.mapDblClick
    }];
    events.forEach(e => {
      const s = this._mapsWrapper.subscribeToMapEvent(e.name).subscribe(event => {
        let value = {
          coords: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
          },
          placeId: event.placeId
        };
        // the placeId will be undefined in case the event was not an IconMouseEvent (google types)
        if (value.placeId && !this.showDefaultInfoWindow) {
          event.stop();
        }
        e.emitter.emit(value);
      });
      this._observableSubscriptions.push(s);
    });
  }
};
/**
 * Map option attributes that can change over time
 */
AgmMap._mapOptionsAttributes = ['disableDoubleClickZoom', 'scrollwheel', 'draggable', 'draggableCursor', 'draggingCursor', 'keyboardShortcuts', 'zoomControl', 'zoomControlOptions', 'styles', 'streetViewControl', 'streetViewControlOptions', 'zoom', 'mapTypeControl', 'mapTypeControlOptions', 'minZoom', 'maxZoom', 'panControl', 'panControlOptions', 'rotateControl', 'rotateControlOptions', 'fullscreenControl', 'fullscreenControlOptions', 'scaleControl', 'scaleControlOptions', 'mapTypeId', 'clickableIcons', 'gestureHandling', 'tilt', 'restriction'];
AgmMap.ctorParameters = () => [{
  type: ElementRef
}, {
  type: GoogleMapsAPIWrapper
}, {
  type: Object,
  decorators: [{
    type: Inject,
    args: [PLATFORM_ID]
  }]
}, {
  type: FitBoundsService
}, {
  type: NgZone
}];
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "longitude", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "latitude", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "zoom", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmMap.prototype, "minZoom", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmMap.prototype, "maxZoom", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmMap.prototype, "controlSize", void 0);
__decorate([Input('mapDraggable'), __metadata("design:type", Object)], AgmMap.prototype, "draggable", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "disableDoubleClickZoom", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "disableDefaultUI", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "scrollwheel", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmMap.prototype, "backgroundColor", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmMap.prototype, "draggableCursor", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmMap.prototype, "draggingCursor", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "keyboardShortcuts", void 0);
__decorate([Input(), __metadata("design:type", Boolean)], AgmMap.prototype, "zoomControl", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "zoomControlOptions", void 0);
__decorate([Input(), __metadata("design:type", Array)], AgmMap.prototype, "styles", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "usePanning", void 0);
__decorate([Input(), __metadata("design:type", Boolean)], AgmMap.prototype, "streetViewControl", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "streetViewControlOptions", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "fitBounds", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "fitBoundsPadding", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "scaleControl", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "scaleControlOptions", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "mapTypeControl", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "mapTypeControlOptions", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "panControl", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "panControlOptions", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "rotateControl", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "rotateControlOptions", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "fullscreenControl", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "fullscreenControlOptions", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmMap.prototype, "mapTypeId", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "clickableIcons", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "showDefaultInfoWindow", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmMap.prototype, "gestureHandling", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "tilt", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMap.prototype, "restriction", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "mapClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "mapRightClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "mapDblClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "centerChange", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "boundsChange", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "mapTypeIdChange", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "idle", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "zoomChange", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "mapReady", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMap.prototype, "tilesLoaded", void 0);
AgmMap = AgmMap_1 = __decorate([Component({
  selector: 'agm-map',
  providers: [CircleManager, DataLayerManager, DataLayerManager, FitBoundsService, GoogleMapsAPIWrapper, InfoWindowManager, KmlLayerManager, LayerManager, MarkerManager, PolygonManager, PolylineManager, RectangleManager],
  host: {
    // todo: deprecated - we will remove it with the next version
    '[class.sebm-google-map-container]': 'true'
  },
  template: `
              <div class='agm-map-container-inner sebm-google-map-container-inner'></div>
              <div class='agm-map-content'>
                <ng-content></ng-content>
              </div>
  `,
  styles: [`
    .agm-map-container-inner {
      width: inherit;
      height: inherit;
    }
    .agm-map-content {
      display:none;
    }
  `]
}), __param(2, Inject(PLATFORM_ID)), __metadata("design:paramtypes", [ElementRef, GoogleMapsAPIWrapper, Object, FitBoundsService, NgZone])], AgmMap);
var AgmMarker_1;
let markerId = 0;
/**
 * AgmMarker renders a map marker inside a {@link AgmMap}.
 *
 * ### Example
 * ```typescript
 * import { Component } from '@angular/core';
 *
 * @Component({
 *  selector: 'my-map-cmp',
 *  styles: [`
 *    .agm-map-container {
 *      height: 300px;
 *    }
 * `],
 *  template: `
 *    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
 *      <agm-marker [latitude]="lat" [longitude]="lng" [label]="'M'">
 *      </agm-marker>
 *    </agm-map>
 *  `
 * })
 * ```
 */
let AgmMarker = AgmMarker_1 = class AgmMarker {
  constructor(_markerManager) {
    this._markerManager = _markerManager;
    /**
     * If true, the marker can be dragged. Default value is false.
     */
    // tslint:disable-next-line:no-input-rename
    this.draggable = false;
    /**
     * If true, the marker is visible
     */
    this.visible = true;
    /**
     * Whether to automatically open the child info window when the marker is clicked.
     */
    this.openInfoWindow = true;
    /**
     * The marker's opacity between 0.0 and 1.0.
     */
    this.opacity = 1;
    /**
     * All markers are displayed on the map in order of their zIndex, with higher values displaying in
     * front of markers with lower values. By default, markers are displayed according to their
     * vertical position on screen, with lower markers appearing in front of markers further up the
     * screen.
     */
    this.zIndex = 1;
    /**
     * If true, the marker can be clicked. Default value is true.
     */
    // tslint:disable-next-line:no-input-rename
    this.clickable = true;
    /**
     * This event is fired when the marker's animation property changes.
     *
     * @memberof AgmMarker
     */
    this.animationChange = new EventEmitter();
    /**
     * This event emitter gets emitted when the user clicks on the marker.
     */
    this.markerClick = new EventEmitter();
    /**
     * This event emitter gets emitted when the user clicks twice on the marker.
     */
    this.markerDblClick = new EventEmitter();
    /**
     * This event is fired when the user rightclicks on the marker.
     */
    this.markerRightClick = new EventEmitter();
    /**
     * This event is fired when the user starts dragging the marker.
     */
    this.dragStart = new EventEmitter();
    /**
     * This event is repeatedly fired while the user drags the marker.
     */
    this.drag = new EventEmitter();
    /**
     * This event is fired when the user stops dragging the marker.
     */
    this.dragEnd = new EventEmitter();
    /**
     * This event is fired when the user mouses over the marker.
     */
    this.mouseOver = new EventEmitter();
    /**
     * This event is fired when the user mouses outside the marker.
     */
    this.mouseOut = new EventEmitter();
    /** @internal */
    this.infoWindow = new QueryList();
    this._markerAddedToManger = false;
    this._observableSubscriptions = [];
    this._fitBoundsDetails$ = new ReplaySubject(1);
    this._id = (markerId++).toString();
  }
  /* @internal */
  ngAfterContentInit() {
    this.handleInfoWindowUpdate();
    this.infoWindow.changes.subscribe(() => this.handleInfoWindowUpdate());
  }
  handleInfoWindowUpdate() {
    if (this.infoWindow.length > 1) {
      throw new Error('Expected no more than one info window.');
    }
    this.infoWindow.forEach(marker => {
      marker.hostMarker = this;
    });
  }
  /** @internal */
  ngOnChanges(changes) {
    if (typeof this.latitude === 'string') {
      this.latitude = Number(this.latitude);
    }
    if (typeof this.longitude === 'string') {
      this.longitude = Number(this.longitude);
    }
    if (typeof this.latitude !== 'number' || typeof this.longitude !== 'number') {
      return;
    }
    if (!this._markerAddedToManger) {
      this._markerManager.addMarker(this);
      this._updateFitBoundsDetails();
      this._markerAddedToManger = true;
      this._addEventListeners();
      return;
    }
    if (changes['latitude'] || changes['longitude']) {
      this._markerManager.updateMarkerPosition(this);
      this._updateFitBoundsDetails();
    }
    if (changes['title']) {
      this._markerManager.updateTitle(this);
    }
    if (changes['label']) {
      this._markerManager.updateLabel(this);
    }
    if (changes['draggable']) {
      this._markerManager.updateDraggable(this);
    }
    if (changes['iconUrl']) {
      this._markerManager.updateIcon(this);
    }
    if (changes['opacity']) {
      this._markerManager.updateOpacity(this);
    }
    if (changes['visible']) {
      this._markerManager.updateVisible(this);
    }
    if (changes['zIndex']) {
      this._markerManager.updateZIndex(this);
    }
    if (changes['clickable']) {
      this._markerManager.updateClickable(this);
    }
    if (changes['animation']) {
      this._markerManager.updateAnimation(this);
    }
  }
  /** @internal */
  getFitBoundsDetails$() {
    return this._fitBoundsDetails$.asObservable();
  }
  _updateFitBoundsDetails() {
    this._fitBoundsDetails$.next({
      latLng: {
        lat: this.latitude,
        lng: this.longitude
      }
    });
  }
  _addEventListeners() {
    const cs = this._markerManager.createEventObservable('click', this).subscribe(() => {
      if (this.openInfoWindow) {
        this.infoWindow.forEach(infoWindow => infoWindow.open());
      }
      this.markerClick.emit(this);
    });
    this._observableSubscriptions.push(cs);
    const dcs = this._markerManager.createEventObservable('dblclick', this).subscribe(() => {
      this.markerDblClick.emit(null);
    });
    this._observableSubscriptions.push(dcs);
    const rc = this._markerManager.createEventObservable('rightclick', this).subscribe(() => {
      this.markerRightClick.emit(null);
    });
    this._observableSubscriptions.push(rc);
    const ds = this._markerManager.createEventObservable('dragstart', this).subscribe(e => {
      this.dragStart.emit({
        coords: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        }
      });
    });
    this._observableSubscriptions.push(ds);
    const d = this._markerManager.createEventObservable('drag', this).subscribe(e => {
      this.drag.emit({
        coords: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        }
      });
    });
    this._observableSubscriptions.push(d);
    const de = this._markerManager.createEventObservable('dragend', this).subscribe(e => {
      this.dragEnd.emit({
        coords: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        }
      });
    });
    this._observableSubscriptions.push(de);
    const mover = this._markerManager.createEventObservable('mouseover', this).subscribe(e => {
      this.mouseOver.emit({
        coords: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        }
      });
    });
    this._observableSubscriptions.push(mover);
    const mout = this._markerManager.createEventObservable('mouseout', this).subscribe(e => {
      this.mouseOut.emit({
        coords: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        }
      });
    });
    this._observableSubscriptions.push(mout);
    const anChng = this._markerManager.createEventObservable('animation_changed', this).subscribe(() => {
      this.animationChange.emit(this.animation);
    });
    this._observableSubscriptions.push(anChng);
  }
  /** @internal */
  id() {
    return this._id;
  }
  /** @internal */
  toString() {
    return 'AgmMarker-' + this._id.toString();
  }
  /** @internal */
  ngOnDestroy() {
    this._markerManager.deleteMarker(this);
    // unsubscribe all registered observable subscriptions
    this._observableSubscriptions.forEach(s => s.unsubscribe());
  }
};
AgmMarker.ctorParameters = () => [{
  type: MarkerManager
}];
__decorate([Input(), __metadata("design:type", Number)], AgmMarker.prototype, "latitude", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmMarker.prototype, "longitude", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmMarker.prototype, "title", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMarker.prototype, "label", void 0);
__decorate([Input('markerDraggable'), __metadata("design:type", Object)], AgmMarker.prototype, "draggable", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmMarker.prototype, "iconUrl", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMarker.prototype, "visible", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMarker.prototype, "openInfoWindow", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMarker.prototype, "opacity", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmMarker.prototype, "zIndex", void 0);
__decorate([Input('markerClickable'), __metadata("design:type", Object)], AgmMarker.prototype, "clickable", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmMarker.prototype, "animation", void 0);
__decorate([Output(), __metadata("design:type", Object)], AgmMarker.prototype, "animationChange", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMarker.prototype, "markerClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMarker.prototype, "markerDblClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMarker.prototype, "markerRightClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMarker.prototype, "dragStart", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMarker.prototype, "drag", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMarker.prototype, "dragEnd", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMarker.prototype, "mouseOver", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmMarker.prototype, "mouseOut", void 0);
__decorate([ContentChildren(AgmInfoWindow), __metadata("design:type", QueryList)], AgmMarker.prototype, "infoWindow", void 0);
AgmMarker = AgmMarker_1 = __decorate([Directive({
  selector: 'agm-marker',
  providers: [{
    provide: FitBoundsAccessor,
    useExisting: forwardRef(() => AgmMarker_1)
  }],
  inputs: ['latitude', 'longitude', 'title', 'label', 'draggable: markerDraggable', 'iconUrl', 'openInfoWindow', 'opacity', 'visible', 'zIndex', 'animation'],
  outputs: ['markerClick', 'dragStart', 'drag', 'dragEnd', 'mouseOver', 'mouseOut']
}), __metadata("design:paramtypes", [MarkerManager])], AgmMarker);
var AgmPolygon_1;
/**
 * AgmPolygon renders a polygon on a {@link AgmMap}
 *
 * ### Example
 * ```typescript
 * import { Component } from '@angular/core';
 *
 * @Component({
 *  selector: 'my-map-cmp',
 *  styles: [`
 *    agm-map {
 *      height: 300px;
 *    }
 * `],
 *  template: `
 *    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
 *      <agm-polygon [paths]="paths">
 *      </agm-polygon>
 *    </agm-map>
 *  `
 * })
 * export class MyMapCmp {
 *   lat: number = 0;
 *   lng: number = 0;
 *   zoom: number = 10;
 *   paths: Array<LatLngLiteral> = [
 *     { lat: 0,  lng: 10 },
 *     { lat: 0,  lng: 20 },
 *     { lat: 10, lng: 20 },
 *     { lat: 10, lng: 10 },
 *     { lat: 0,  lng: 10 }
 *   ]
 *   // Nesting paths will create a hole where they overlap;
 *   nestedPaths: Array<Array<LatLngLiteral>> = [[
 *     { lat: 0,  lng: 10 },
 *     { lat: 0,  lng: 20 },
 *     { lat: 10, lng: 20 },
 *     { lat: 10, lng: 10 },
 *     { lat: 0,  lng: 10 }
 *   ], [
 *     { lat: 0, lng: 15 },
 *     { lat: 0, lng: 20 },
 *     { lat: 5, lng: 20 },
 *     { lat: 5, lng: 15 },
 *     { lat: 0, lng: 15 }
 *   ]]
 * }
 * ```
 */
let AgmPolygon = AgmPolygon_1 = class AgmPolygon {
  constructor(_polygonManager) {
    this._polygonManager = _polygonManager;
    /**
     * Indicates whether this Polygon handles mouse events. Defaults to true.
     */
    this.clickable = true;
    /**
     * If set to true, the user can drag this shape over the map. The geodesic
     * property defines the mode of dragging. Defaults to false.
     */
    // tslint:disable-next-line:no-input-rename
    this.draggable = false;
    /**
     * If set to true, the user can edit this shape by dragging the control
     * points shown at the vertices and on each segment. Defaults to false.
     */
    this.editable = false;
    /**
     * When true, edges of the polygon are interpreted as geodesic and will
     * follow the curvature of the Earth. When false, edges of the polygon are
     * rendered as straight lines in screen space. Note that the shape of a
     * geodesic polygon may appear to change when dragged, as the dimensions
     * are maintained relative to the surface of the earth. Defaults to false.
     */
    this.geodesic = false;
    /**
     * The ordered sequence of coordinates that designates a closed loop.
     * Unlike polylines, a polygon may consist of one or more paths.
     *  As a result, the paths property may specify one or more arrays of
     * LatLng coordinates. Paths are closed automatically; do not repeat the
     * first vertex of the path as the last vertex. Simple polygons may be
     * defined using a single array of LatLngs. More complex polygons may
     * specify an array of arrays. Any simple arrays are converted into Arrays.
     * Inserting or removing LatLngs from the Array will automatically update
     * the polygon on the map.
     */
    this.paths = [];
    /**
     * This event is fired when the DOM click event is fired on the Polygon.
     */
    this.polyClick = new EventEmitter();
    /**
     * This event is fired when the DOM dblclick event is fired on the Polygon.
     */
    this.polyDblClick = new EventEmitter();
    /**
     * This event is repeatedly fired while the user drags the polygon.
     */
    this.polyDrag = new EventEmitter();
    /**
     * This event is fired when the user stops dragging the polygon.
     */
    this.polyDragEnd = new EventEmitter();
    /**
     * This event is fired when the user starts dragging the polygon.
     */
    this.polyDragStart = new EventEmitter();
    /**
     * This event is fired when the DOM mousedown event is fired on the Polygon.
     */
    this.polyMouseDown = new EventEmitter();
    /**
     * This event is fired when the DOM mousemove event is fired on the Polygon.
     */
    this.polyMouseMove = new EventEmitter();
    /**
     * This event is fired on Polygon mouseout.
     */
    this.polyMouseOut = new EventEmitter();
    /**
     * This event is fired on Polygon mouseover.
     */
    this.polyMouseOver = new EventEmitter();
    /**
     * This event is fired whe the DOM mouseup event is fired on the Polygon
     */
    this.polyMouseUp = new EventEmitter();
    /**
     * This event is fired when the Polygon is right-clicked on.
     */
    this.polyRightClick = new EventEmitter();
    /**
     * This event is fired after Polygon first path changes.
     */
    this.polyPathsChange = new EventEmitter();
    this._polygonAddedToManager = false;
    this._subscriptions = [];
  }
  /** @internal */
  ngAfterContentInit() {
    if (!this._polygonAddedToManager) {
      this._init();
    }
  }
  ngOnChanges(changes) {
    if (!this._polygonAddedToManager) {
      this._init();
      return;
    }
    this._polygonManager.setPolygonOptions(this, this._updatePolygonOptions(changes));
  }
  _init() {
    this._polygonManager.addPolygon(this);
    this._polygonAddedToManager = true;
    this._addEventListeners();
  }
  _addEventListeners() {
    const handlers = [{
      name: 'click',
      handler: ev => this.polyClick.emit(ev)
    }, {
      name: 'dblclick',
      handler: ev => this.polyDblClick.emit(ev)
    }, {
      name: 'drag',
      handler: ev => this.polyDrag.emit(ev)
    }, {
      name: 'dragend',
      handler: ev => this.polyDragEnd.emit(ev)
    }, {
      name: 'dragstart',
      handler: ev => this.polyDragStart.emit(ev)
    }, {
      name: 'mousedown',
      handler: ev => this.polyMouseDown.emit(ev)
    }, {
      name: 'mousemove',
      handler: ev => this.polyMouseMove.emit(ev)
    }, {
      name: 'mouseout',
      handler: ev => this.polyMouseOut.emit(ev)
    }, {
      name: 'mouseover',
      handler: ev => this.polyMouseOver.emit(ev)
    }, {
      name: 'mouseup',
      handler: ev => this.polyMouseUp.emit(ev)
    }, {
      name: 'rightclick',
      handler: ev => this.polyRightClick.emit(ev)
    }];
    handlers.forEach(obj => {
      const os = this._polygonManager.createEventObservable(obj.name, this).subscribe(obj.handler);
      this._subscriptions.push(os);
    });
    this._polygonManager.createPathEventObservable(this).then(paths$ => {
      const os = paths$.subscribe(pathEvent => this.polyPathsChange.emit(pathEvent));
      this._subscriptions.push(os);
    });
  }
  _updatePolygonOptions(changes) {
    return Object.keys(changes).filter(k => AgmPolygon_1._polygonOptionsAttributes.indexOf(k) !== -1).reduce((obj, k) => {
      obj[k] = changes[k].currentValue;
      return obj;
    }, {});
  }
  /** @internal */
  id() {
    return this._id;
  }
  /** @internal */
  ngOnDestroy() {
    this._polygonManager.deletePolygon(this);
    // unsubscribe all registered observable subscriptions
    this._subscriptions.forEach(s => s.unsubscribe());
  }
  getPath() {
    return this._polygonManager.getPath(this);
  }
  getPaths() {
    return this._polygonManager.getPaths(this);
  }
};
AgmPolygon._polygonOptionsAttributes = ['clickable', 'draggable', 'editable', 'fillColor', 'fillOpacity', 'geodesic', 'icon', 'map', 'paths', 'strokeColor', 'strokeOpacity', 'strokeWeight', 'visible', 'zIndex', 'draggable', 'editable', 'visible'];
AgmPolygon.ctorParameters = () => [{
  type: PolygonManager
}];
__decorate([Input(), __metadata("design:type", Object)], AgmPolygon.prototype, "clickable", void 0);
__decorate([Input('polyDraggable'), __metadata("design:type", Object)], AgmPolygon.prototype, "draggable", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmPolygon.prototype, "editable", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmPolygon.prototype, "fillColor", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolygon.prototype, "fillOpacity", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmPolygon.prototype, "geodesic", void 0);
__decorate([Input(), __metadata("design:type", Array)], AgmPolygon.prototype, "paths", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmPolygon.prototype, "strokeColor", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolygon.prototype, "strokeOpacity", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolygon.prototype, "strokeWeight", void 0);
__decorate([Input(), __metadata("design:type", Boolean)], AgmPolygon.prototype, "visible", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolygon.prototype, "zIndex", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyDblClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyDrag", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyDragEnd", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyDragStart", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyMouseDown", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyMouseMove", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyMouseOut", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyMouseOver", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyMouseUp", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolygon.prototype, "polyRightClick", void 0);
__decorate([Output(), __metadata("design:type", Object)], AgmPolygon.prototype, "polyPathsChange", void 0);
AgmPolygon = AgmPolygon_1 = __decorate([Directive({
  selector: 'agm-polygon'
}), __metadata("design:paramtypes", [PolygonManager])], AgmPolygon);

/**
 * AgmPolylineIcon enables to add polyline sequences to add arrows, circle,
 * or custom icons either along the entire line, or in a specific part of it.
 * See https://developers.google.com/maps/documentation/javascript/shapes#polyline_customize
 *
 * ### Example
 * ```html
 *    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
 *      <agm-polyline>
 *          <agm-icon-sequence [fixedRotation]="true" [path]="'FORWARD_OPEN_ARROW'">
 *          </agm-icon-sequence>
 *      </agm-polyline>
 *    </agm-map>
 * ```
 *
 * @export
 * @class AgmPolylineIcon
 */
let AgmPolylineIcon = class AgmPolylineIcon {
  ngOnInit() {
    if (this.path == null) {
      throw new Error('Icon Sequence path is required');
    }
  }
};
__decorate([Input(), __metadata("design:type", Boolean)], AgmPolylineIcon.prototype, "fixedRotation", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmPolylineIcon.prototype, "offset", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmPolylineIcon.prototype, "repeat", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolylineIcon.prototype, "anchorX", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolylineIcon.prototype, "anchorY", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmPolylineIcon.prototype, "fillColor", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolylineIcon.prototype, "fillOpacity", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmPolylineIcon.prototype, "path", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolylineIcon.prototype, "rotation", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolylineIcon.prototype, "scale", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmPolylineIcon.prototype, "strokeColor", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolylineIcon.prototype, "strokeOpacity", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolylineIcon.prototype, "strokeWeight", void 0);
AgmPolylineIcon = __decorate([Directive({
  selector: 'agm-polyline agm-icon-sequence'
})], AgmPolylineIcon);
var AgmPolylinePoint_1;
/**
 * AgmPolylinePoint represents one element of a polyline within a  {@link
 * AgmPolyline}
 */
let AgmPolylinePoint = AgmPolylinePoint_1 = class AgmPolylinePoint {
  constructor() {
    /**
     * This event emitter gets emitted when the position of the point changed.
     */
    this.positionChanged = new EventEmitter();
  }
  ngOnChanges(changes) {
    if (changes['latitude'] || changes['longitude']) {
      const position = {
        lat: changes['latitude'] ? changes['latitude'].currentValue : this.latitude,
        lng: changes['longitude'] ? changes['longitude'].currentValue : this.longitude
      };
      this.positionChanged.emit(position);
    }
  }
  /** @internal */
  getFitBoundsDetails$() {
    return this.positionChanged.pipe(startWith({
      lat: this.latitude,
      lng: this.longitude
    }), map(position => ({
      latLng: position
    })));
  }
};
__decorate([Input(), __metadata("design:type", Number)], AgmPolylinePoint.prototype, "latitude", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolylinePoint.prototype, "longitude", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolylinePoint.prototype, "positionChanged", void 0);
AgmPolylinePoint = AgmPolylinePoint_1 = __decorate([Directive({
  selector: 'agm-polyline-point',
  providers: [{
    provide: FitBoundsAccessor,
    useExisting: forwardRef(() => AgmPolylinePoint_1)
  }]
}), __metadata("design:paramtypes", [])], AgmPolylinePoint);
var AgmPolyline_1;
let polylineId = 0;
/**
 * AgmPolyline renders a polyline on a {@link AgmMap}
 *
 * ### Example
 * ```typescript
 * import { Component } from '@angular/core';
 *
 * @Component({
 *  selector: 'my-map-cmp',
 *  styles: [`
 *    .agm-map-container {
 *      height: 300px;
 *    }
 * `],
 *  template: `
 *    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
 *      <agm-polyline>
 *          <agm-polyline-point [latitude]="latA" [longitude]="lngA">
 *          </agm-polyline-point>
 *          <agm-polyline-point [latitude]="latB" [longitude]="lngB">
 *          </agm-polyline-point>
 *      </agm-polyline>
 *    </agm-map>
 *  `
 * })
 * ```
 */
let AgmPolyline = AgmPolyline_1 = class AgmPolyline {
  constructor(_polylineManager) {
    this._polylineManager = _polylineManager;
    /**
     * Indicates whether this Polyline handles mouse events. Defaults to true.
     */
    this.clickable = true;
    /**
     * If set to true, the user can drag this shape over the map. The geodesic property defines the
     * mode of dragging. Defaults to false.
     */
    // tslint:disable-next-line:no-input-rename
    this.draggable = false;
    /**
     * If set to true, the user can edit this shape by dragging the control points shown at the
     * vertices and on each segment. Defaults to false.
     */
    this.editable = false;
    /**
     * When true, edges of the polygon are interpreted as geodesic and will follow the curvature of
     * the Earth. When false, edges of the polygon are rendered as straight lines in screen space.
     * Note that the shape of a geodesic polygon may appear to change when dragged, as the dimensions
     * are maintained relative to the surface of the earth. Defaults to false.
     */
    this.geodesic = false;
    /**
     * Whether this polyline is visible on the map. Defaults to true.
     */
    this.visible = true;
    /**
     * This event is fired when the DOM click event is fired on the Polyline.
     */
    this.lineClick = new EventEmitter();
    /**
     * This event is fired when the DOM dblclick event is fired on the Polyline.
     */
    this.lineDblClick = new EventEmitter();
    /**
     * This event is repeatedly fired while the user drags the polyline.
     */
    this.lineDrag = new EventEmitter();
    /**
     * This event is fired when the user stops dragging the polyline.
     */
    this.lineDragEnd = new EventEmitter();
    /**
     * This event is fired when the user starts dragging the polyline.
     */
    this.lineDragStart = new EventEmitter();
    /**
     * This event is fired when the DOM mousedown event is fired on the Polyline.
     */
    this.lineMouseDown = new EventEmitter();
    /**
     * This event is fired when the DOM mousemove event is fired on the Polyline.
     */
    this.lineMouseMove = new EventEmitter();
    /**
     * This event is fired on Polyline mouseout.
     */
    this.lineMouseOut = new EventEmitter();
    /**
     * This event is fired on Polyline mouseover.
     */
    this.lineMouseOver = new EventEmitter();
    /**
     * This event is fired whe the DOM mouseup event is fired on the Polyline
     */
    this.lineMouseUp = new EventEmitter();
    /**
     * This event is fired when the Polyline is right-clicked on.
     */
    this.lineRightClick = new EventEmitter();
    /**
     * This event is fired after Polyline's path changes.
     */
    this.polyPathChange = new EventEmitter();
    this._polylineAddedToManager = false;
    this._subscriptions = [];
    this._id = (polylineId++).toString();
  }
  /** @internal */
  ngAfterContentInit() {
    if (this.points.length) {
      this.points.forEach(point => {
        const s = point.positionChanged.subscribe(() => {
          this._polylineManager.updatePolylinePoints(this);
        });
        this._subscriptions.push(s);
      });
    }
    if (!this._polylineAddedToManager) {
      this._init();
    }
    const pointSub = this.points.changes.subscribe(() => this._polylineManager.updatePolylinePoints(this));
    this._subscriptions.push(pointSub);
    this._polylineManager.updatePolylinePoints(this);
    const iconSub = this.iconSequences.changes.subscribe(() => this._polylineManager.updateIconSequences(this));
    this._subscriptions.push(iconSub);
  }
  ngOnChanges(changes) {
    if (!this._polylineAddedToManager) {
      this._init();
      return;
    }
    let options = {};
    const optionKeys = Object.keys(changes).filter(k => AgmPolyline_1._polylineOptionsAttributes.indexOf(k) !== -1);
    optionKeys.forEach(k => options[k] = changes[k].currentValue);
    this._polylineManager.setPolylineOptions(this, options);
  }
  getPath() {
    return this._polylineManager.getPath(this);
  }
  _init() {
    this._polylineManager.addPolyline(this);
    this._polylineAddedToManager = true;
    this._addEventListeners();
  }
  _addEventListeners() {
    const handlers = [{
      name: 'click',
      handler: ev => this.lineClick.emit(ev)
    }, {
      name: 'dblclick',
      handler: ev => this.lineDblClick.emit(ev)
    }, {
      name: 'drag',
      handler: ev => this.lineDrag.emit(ev)
    }, {
      name: 'dragend',
      handler: ev => this.lineDragEnd.emit(ev)
    }, {
      name: 'dragstart',
      handler: ev => this.lineDragStart.emit(ev)
    }, {
      name: 'mousedown',
      handler: ev => this.lineMouseDown.emit(ev)
    }, {
      name: 'mousemove',
      handler: ev => this.lineMouseMove.emit(ev)
    }, {
      name: 'mouseout',
      handler: ev => this.lineMouseOut.emit(ev)
    }, {
      name: 'mouseover',
      handler: ev => this.lineMouseOver.emit(ev)
    }, {
      name: 'mouseup',
      handler: ev => this.lineMouseUp.emit(ev)
    }, {
      name: 'rightclick',
      handler: ev => this.lineRightClick.emit(ev)
    }];
    handlers.forEach(obj => {
      const os = this._polylineManager.createEventObservable(obj.name, this).subscribe(obj.handler);
      this._subscriptions.push(os);
    });
    this._polylineManager.createPathEventObservable(this).then(ob$ => {
      const os = ob$.subscribe(pathEvent => this.polyPathChange.emit(pathEvent));
      this._subscriptions.push(os);
    });
  }
  /** @internal */
  _getPoints() {
    if (this.points) {
      return this.points.toArray();
    }
    return [];
  }
  _getIcons() {
    if (this.iconSequences) {
      return this.iconSequences.toArray();
    }
    return [];
  }
  /** @internal */
  id() {
    return this._id;
  }
  /** @internal */
  ngOnDestroy() {
    this._polylineManager.deletePolyline(this);
    // unsubscribe all registered observable subscriptions
    this._subscriptions.forEach(s => s.unsubscribe());
  }
};
AgmPolyline._polylineOptionsAttributes = ['draggable', 'editable', 'visible', 'geodesic', 'strokeColor', 'strokeOpacity', 'strokeWeight', 'zIndex'];
AgmPolyline.ctorParameters = () => [{
  type: PolylineManager
}];
__decorate([Input(), __metadata("design:type", Object)], AgmPolyline.prototype, "clickable", void 0);
__decorate([Input('polylineDraggable'), __metadata("design:type", Object)], AgmPolyline.prototype, "draggable", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmPolyline.prototype, "editable", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmPolyline.prototype, "geodesic", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmPolyline.prototype, "strokeColor", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolyline.prototype, "strokeOpacity", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolyline.prototype, "strokeWeight", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmPolyline.prototype, "visible", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmPolyline.prototype, "zIndex", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineDblClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineDrag", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineDragEnd", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineDragStart", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineMouseDown", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineMouseMove", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineMouseOut", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineMouseOver", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineMouseUp", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmPolyline.prototype, "lineRightClick", void 0);
__decorate([Output(), __metadata("design:type", Object)], AgmPolyline.prototype, "polyPathChange", void 0);
__decorate([ContentChildren(AgmPolylinePoint), __metadata("design:type", QueryList)], AgmPolyline.prototype, "points", void 0);
__decorate([ContentChildren(AgmPolylineIcon), __metadata("design:type", QueryList)], AgmPolyline.prototype, "iconSequences", void 0);
AgmPolyline = AgmPolyline_1 = __decorate([Directive({
  selector: 'agm-polyline'
}), __metadata("design:paramtypes", [PolylineManager])], AgmPolyline);
var AgmRectangle_1;
let AgmRectangle = AgmRectangle_1 = class AgmRectangle {
  constructor(_manager) {
    this._manager = _manager;
    /**
     * Indicates whether this Rectangle handles mouse events. Defaults to true.
     */
    this.clickable = true;
    /**
     * If set to true, the user can drag this rectangle over the map. Defaults to false.
     */
    // tslint:disable-next-line:no-input-rename
    this.draggable = false;
    /**
     * If set to true, the user can edit this rectangle by dragging the control points shown at
     * the center and around the circumference of the rectangle. Defaults to false.
     */
    this.editable = false;
    /**
     * The stroke position. Defaults to CENTER.
     * This property is not supported on Internet Explorer 8 and earlier.
     */
    this.strokePosition = 'CENTER';
    /**
     * The stroke width in pixels.
     */
    this.strokeWeight = 0;
    /**
     * Whether this rectangle is visible on the map. Defaults to true.
     */
    this.visible = true;
    /**
     * This event is fired when the rectangle's is changed.
     */
    this.boundsChange = new EventEmitter();
    /**
     * This event emitter gets emitted when the user clicks on the rectangle.
     */
    this.rectangleClick = new EventEmitter();
    /**
     * This event emitter gets emitted when the user clicks on the rectangle.
     */
    this.rectangleDblClick = new EventEmitter();
    /**
     * This event is repeatedly fired while the user drags the rectangle.
     */
    this.drag = new EventEmitter();
    /**
     * This event is fired when the user stops dragging the rectangle.
     */
    this.dragEnd = new EventEmitter();
    /**
     * This event is fired when the user starts dragging the rectangle.
     */
    this.dragStart = new EventEmitter();
    /**
     * This event is fired when the DOM mousedown event is fired on the rectangle.
     */
    this.mouseDown = new EventEmitter();
    /**
     * This event is fired when the DOM mousemove event is fired on the rectangle.
     */
    this.mouseMove = new EventEmitter();
    /**
     * This event is fired on rectangle mouseout.
     */
    this.mouseOut = new EventEmitter();
    /**
     * This event is fired on rectangle mouseover.
     */
    this.mouseOver = new EventEmitter();
    /**
     * This event is fired when the DOM mouseup event is fired on the rectangle.
     */
    this.mouseUp = new EventEmitter();
    /**
     * This event is fired when the rectangle is right-clicked on.
     */
    this.rightClick = new EventEmitter();
    this._rectangleAddedToManager = false;
    this._eventSubscriptions = [];
  }
  /** @internal */
  ngOnInit() {
    this._manager.addRectangle(this);
    this._rectangleAddedToManager = true;
    this._registerEventListeners();
  }
  /** @internal */
  ngOnChanges(changes) {
    if (!this._rectangleAddedToManager) {
      return;
    }
    if (changes['north'] || changes['east'] || changes['south'] || changes['west']) {
      this._manager.setBounds(this);
    }
    if (changes['editable']) {
      this._manager.setEditable(this);
    }
    if (changes['draggable']) {
      this._manager.setDraggable(this);
    }
    if (changes['visible']) {
      this._manager.setVisible(this);
    }
    this._updateRectangleOptionsChanges(changes);
  }
  _updateRectangleOptionsChanges(changes) {
    let options = {};
    let optionKeys = Object.keys(changes).filter(k => AgmRectangle_1._mapOptions.indexOf(k) !== -1);
    optionKeys.forEach(k => {
      options[k] = changes[k].currentValue;
    });
    if (optionKeys.length > 0) {
      this._manager.setOptions(this, options);
    }
  }
  _registerEventListeners() {
    let events = new Map();
    events.set('bounds_changed', this.boundsChange);
    events.set('click', this.rectangleClick);
    events.set('dblclick', this.rectangleDblClick);
    events.set('drag', this.drag);
    events.set('dragend', this.dragEnd);
    events.set('dragStart', this.dragStart);
    events.set('mousedown', this.mouseDown);
    events.set('mousemove', this.mouseMove);
    events.set('mouseout', this.mouseOut);
    events.set('mouseover', this.mouseOver);
    events.set('mouseup', this.mouseUp);
    events.set('rightclick', this.rightClick);
    events.forEach((eventEmitter, eventName) => {
      this._eventSubscriptions.push(this._manager.createEventObservable(eventName, this).subscribe(value => {
        switch (eventName) {
          case 'bounds_changed':
            this._manager.getBounds(this).then(bounds => eventEmitter.emit({
              north: bounds.getNorthEast().lat(),
              east: bounds.getNorthEast().lng(),
              south: bounds.getSouthWest().lat(),
              west: bounds.getSouthWest().lng()
            }));
            break;
          default:
            eventEmitter.emit({
              coords: {
                lat: value.latLng.lat(),
                lng: value.latLng.lng()
              }
            });
        }
      }));
    });
  }
  /** @internal */
  ngOnDestroy() {
    this._eventSubscriptions.forEach(function (s) {
      s.unsubscribe();
    });
    this._eventSubscriptions = null;
    this._manager.removeRectangle(this);
  }
  /**
   * Gets the LatLngBounds of this Rectangle.
   */
  getBounds() {
    return this._manager.getBounds(this);
  }
};
AgmRectangle._mapOptions = ['fillColor', 'fillOpacity', 'strokeColor', 'strokeOpacity', 'strokePosition', 'strokeWeight', 'visible', 'zIndex', 'clickable'];
AgmRectangle.ctorParameters = () => [{
  type: RectangleManager
}];
__decorate([Input(), __metadata("design:type", Number)], AgmRectangle.prototype, "north", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmRectangle.prototype, "east", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmRectangle.prototype, "south", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmRectangle.prototype, "west", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmRectangle.prototype, "clickable", void 0);
__decorate([Input('rectangleDraggable'), __metadata("design:type", Object)], AgmRectangle.prototype, "draggable", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmRectangle.prototype, "editable", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmRectangle.prototype, "fillColor", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmRectangle.prototype, "fillOpacity", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmRectangle.prototype, "strokeColor", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmRectangle.prototype, "strokeOpacity", void 0);
__decorate([Input(), __metadata("design:type", String)], AgmRectangle.prototype, "strokePosition", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmRectangle.prototype, "strokeWeight", void 0);
__decorate([Input(), __metadata("design:type", Object)], AgmRectangle.prototype, "visible", void 0);
__decorate([Input(), __metadata("design:type", Number)], AgmRectangle.prototype, "zIndex", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "boundsChange", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "rectangleClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "rectangleDblClick", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "drag", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "dragEnd", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "dragStart", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "mouseDown", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "mouseMove", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "mouseOut", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "mouseOver", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "mouseUp", void 0);
__decorate([Output(), __metadata("design:type", EventEmitter)], AgmRectangle.prototype, "rightClick", void 0);
AgmRectangle = AgmRectangle_1 = __decorate([Directive({
  selector: 'agm-rectangle'
}), __metadata("design:paramtypes", [RectangleManager])], AgmRectangle);
let layerId$3 = 0;
/*
 * This directive adds a transit layer to a google map instance
 * <agm-transit-layer [visible]="true|false"> <agm-transit-layer>
 * */
let AgmTransitLayer = class AgmTransitLayer {
  constructor(_manager) {
    this._manager = _manager;
    this._addedToManager = false;
    this._id = (layerId$3++).toString();
    /**
     * Hide/show transit layer
     */
    this.visible = true;
  }
  ngOnInit() {
    if (this._addedToManager) {
      return;
    }
    this._manager.addTransitLayer(this, {
      visible: this.visible
    });
    this._addedToManager = true;
  }
  ngOnChanges(changes) {
    if (!this._addedToManager) {
      return;
    }
    if (changes['visible'] != null) {
      this._manager.toggleLayerVisibility(this, {
        visible: changes['visible'].currentValue
      });
    }
  }
  /** @internal */
  id() {
    return this._id;
  }
  /** @internal */
  toString() {
    return `AgmTransitLayer-${this._id.toString()}`;
  }
  /** @internal */
  ngOnDestroy() {
    this._manager.deleteLayer(this);
  }
};
AgmTransitLayer.ctorParameters = () => [{
  type: LayerManager
}];
__decorate([Input(), __metadata("design:type", Object)], AgmTransitLayer.prototype, "visible", void 0);
AgmTransitLayer = __decorate([Directive({
  selector: 'agm-transit-layer'
}), __metadata("design:paramtypes", [LayerManager])], AgmTransitLayer);
var google$1;
var SymbolPath;
(function (SymbolPath) {
  SymbolPath[SymbolPath["BACKWARD_CLOSED_ARROW"] = 3] = "BACKWARD_CLOSED_ARROW";
  SymbolPath[SymbolPath["BACKWARD_OPEN_ARROW"] = 4] = "BACKWARD_OPEN_ARROW";
  SymbolPath[SymbolPath["CIRCLE"] = 0] = "CIRCLE";
  SymbolPath[SymbolPath["FORWARD_CLOSED_ARROW"] = 1] = "FORWARD_CLOSED_ARROW";
  SymbolPath[SymbolPath["FORWARD_OPEN_ARROW"] = 2] = "FORWARD_OPEN_ARROW";
})(SymbolPath || (SymbolPath = {}));
/**
 * Identifiers used to specify the placement of controls on the map. Controls are
 * positioned relative to other controls in the same layout position. Controls that
 * are added first are positioned closer to the edge of the map.
 */
var ControlPosition;
(function (ControlPosition) {
  ControlPosition[ControlPosition["RIGHT_BOTTOM"] = 0] = "RIGHT_BOTTOM";
  ControlPosition[ControlPosition["TOP_LEFT"] = 1] = "TOP_LEFT";
  ControlPosition[ControlPosition["TOP_CENTER"] = 2] = "TOP_CENTER";
  ControlPosition[ControlPosition["TOP_RIGHT"] = 3] = "TOP_RIGHT";
  ControlPosition[ControlPosition["LEFT_CENTER"] = 4] = "LEFT_CENTER";
  ControlPosition[ControlPosition["LEFT_TOP"] = 5] = "LEFT_TOP";
  ControlPosition[ControlPosition["LEFT_BOTTOM"] = 6] = "LEFT_BOTTOM";
  ControlPosition[ControlPosition["RIGHT_TOP"] = 7] = "RIGHT_TOP";
  ControlPosition[ControlPosition["RIGHT_CENTER"] = 8] = "RIGHT_CENTER";
  ControlPosition[ControlPosition["BOTTOM_RIGHT"] = 9] = "BOTTOM_RIGHT";
  ControlPosition[ControlPosition["BOTTOM_LEFT"] = 10] = "BOTTOM_LEFT";
  ControlPosition[ControlPosition["BOTTOM_CENTER"] = 11] = "BOTTOM_CENTER";
})(ControlPosition || (ControlPosition = {}));
var MapTypeId;
(function (MapTypeId) {
  /** This map type displays a transparent layer of major streets on satellite images. */
  MapTypeId[MapTypeId["HYBRID"] = 0] = "HYBRID";
  /** This map type displays a normal street map. */
  MapTypeId[MapTypeId["ROADMAP"] = 1] = "ROADMAP";
  /** This map type displays satellite images. */
  MapTypeId[MapTypeId["SATELLITE"] = 2] = "SATELLITE";
  /** This map type displays maps with physical features such as terrain and vegetation. */
  MapTypeId[MapTypeId["TERRAIN"] = 3] = "TERRAIN";
})(MapTypeId || (MapTypeId = {}));
var MapTypeControlStyle;
(function (MapTypeControlStyle) {
  MapTypeControlStyle[MapTypeControlStyle["DEFAULT"] = 0] = "DEFAULT";
  MapTypeControlStyle[MapTypeControlStyle["DROPDOWN_MENU"] = 2] = "DROPDOWN_MENU";
  MapTypeControlStyle[MapTypeControlStyle["HORIZONTAL_BAR"] = 1] = "HORIZONTAL_BAR";
})(MapTypeControlStyle || (MapTypeControlStyle = {}));
var ScaleControlStyle;
(function (ScaleControlStyle) {
  ScaleControlStyle[ScaleControlStyle["DEFAULT"] = 0] = "DEFAULT";
})(ScaleControlStyle || (ScaleControlStyle = {}));
var ZoomControlStyle;
(function (ZoomControlStyle) {
  ZoomControlStyle[ZoomControlStyle["DEFAULT"] = 0] = "DEFAULT";
  ZoomControlStyle[ZoomControlStyle["LARGE"] = 1] = "LARGE";
  ZoomControlStyle[ZoomControlStyle["SMALL"] = 2] = "SMALL";
})(ZoomControlStyle || (ZoomControlStyle = {}));
var GeocoderLocationType;
(function (GeocoderLocationType) {
  GeocoderLocationType["APPROXIMATE"] = "APPROXIMATE";
  GeocoderLocationType["GEOMETRIC_CENTER"] = "GEOMETRIC_CENTER";
  GeocoderLocationType["RANGE_INTERPOLATED"] = "RANGE_INTERPOLATED";
  GeocoderLocationType["ROOFTOP"] = "ROOFTOP";
})(GeocoderLocationType || (GeocoderLocationType = {}));
var GeocoderStatus;
(function (GeocoderStatus) {
  GeocoderStatus["ERROR"] = "ERROR";
  GeocoderStatus["INVALID_REQUEST"] = "INVALID_REQUEST";
  GeocoderStatus["OK"] = "OK";
  GeocoderStatus["OVER_QUERY_LIMIT"] = "OVER_QUERY_LIMIT";
  GeocoderStatus["REQUEST_DENIED"] = "REQUEST_DENIED";
  GeocoderStatus["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
  GeocoderStatus["ZERO_RESULTS"] = "ZERO_RESULTS";
})(GeocoderStatus || (GeocoderStatus = {}));
let AgmGeocoder = class AgmGeocoder {
  constructor(loader) {
    const connectableGeocoder$ = new Observable(subscriber => {
      loader.load().then(() => subscriber.next());
    }).pipe(map(() => this._createGeocoder()), multicast(new ReplaySubject(1)));
    connectableGeocoder$.connect(); // ignore the subscription
    // since we will remain subscribed till application exits
    this.geocoder$ = connectableGeocoder$;
  }
  geocode(request) {
    return this.geocoder$.pipe(switchMap(geocoder => this._getGoogleResults(geocoder, request)));
  }
  _getGoogleResults(geocoder, request) {
    const geocodeObservable = bindCallback(geocoder.geocode);
    return geocodeObservable(request).pipe(switchMap(([results, status]) => {
      if (status === GeocoderStatus.OK) {
        return of(results);
      }
      return throwError(status);
    }));
  }
  _createGeocoder() {
    return new google.maps.Geocoder();
  }
};
AgmGeocoder.ctorParameters = () => [{
  type: MapsAPILoader
}];
AgmGeocoder.ngInjectableDef = ɵɵdefineInjectable({
  factory: function AgmGeocoder_Factory() {
    return new AgmGeocoder(ɵɵinject(MapsAPILoader));
  },
  token: AgmGeocoder,
  providedIn: "root"
});
AgmGeocoder = __decorate([Injectable({
  providedIn: 'root'
}), __metadata("design:paramtypes", [MapsAPILoader])], AgmGeocoder);
class WindowRef {
  getNativeWindow() {
    return window;
  }
}
class DocumentRef {
  getNativeDocument() {
    return document;
  }
}
const BROWSER_GLOBALS_PROVIDERS = [WindowRef, DocumentRef];
var GoogleMapsScriptProtocol;
(function (GoogleMapsScriptProtocol) {
  GoogleMapsScriptProtocol[GoogleMapsScriptProtocol["HTTP"] = 1] = "HTTP";
  GoogleMapsScriptProtocol[GoogleMapsScriptProtocol["HTTPS"] = 2] = "HTTPS";
  GoogleMapsScriptProtocol[GoogleMapsScriptProtocol["AUTO"] = 3] = "AUTO";
})(GoogleMapsScriptProtocol || (GoogleMapsScriptProtocol = {}));
/**
 * Token for the config of the LazyMapsAPILoader. Please provide an object of type {@link
 * LazyMapsAPILoaderConfig}.
 */
const LAZY_MAPS_API_CONFIG = new InjectionToken('angular-google-maps LAZY_MAPS_API_CONFIG');
let LazyMapsAPILoader = class LazyMapsAPILoader extends MapsAPILoader {
  constructor(config = null, w, d, localeId) {
    super();
    this.localeId = localeId;
    this._SCRIPT_ID = 'agmGoogleMapsApiScript';
    this.callbackName = `agmLazyMapsAPILoader`;
    this._config = config || {};
    this._windowRef = w;
    this._documentRef = d;
  }
  load() {
    const window = this._windowRef.getNativeWindow();
    if (window.google && window.google.maps) {
      // Google maps already loaded on the page.
      return Promise.resolve();
    }
    if (this._scriptLoadingPromise) {
      return this._scriptLoadingPromise;
    }
    // this can happen in HMR situations or Stackblitz.io editors.
    const scriptOnPage = this._documentRef.getNativeDocument().getElementById(this._SCRIPT_ID);
    if (scriptOnPage) {
      this._assignScriptLoadingPromise(scriptOnPage);
      return this._scriptLoadingPromise;
    }
    const script = this._documentRef.getNativeDocument().createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.id = this._SCRIPT_ID;
    script.src = this._getScriptSrc(this.callbackName);
    this._assignScriptLoadingPromise(script);
    this._documentRef.getNativeDocument().body.appendChild(script);
    return this._scriptLoadingPromise;
  }
  _assignScriptLoadingPromise(scriptElem) {
    this._scriptLoadingPromise = new Promise((resolve, reject) => {
      this._windowRef.getNativeWindow()[this.callbackName] = () => {
        resolve();
      };
      scriptElem.onerror = error => {
        reject(error);
      };
    });
  }
  _getScriptSrc(callbackName) {
    let protocolType = this._config && this._config.protocol || GoogleMapsScriptProtocol.HTTPS;
    let protocol;
    switch (protocolType) {
      case GoogleMapsScriptProtocol.AUTO:
        protocol = '';
        break;
      case GoogleMapsScriptProtocol.HTTP:
        protocol = 'http:';
        break;
      case GoogleMapsScriptProtocol.HTTPS:
        protocol = 'https:';
        break;
    }
    const hostAndPath = this._config.hostAndPath || 'maps.googleapis.com/maps/api/js';
    const queryParams = {
      v: this._config.apiVersion || 'quarterly',
      callback: callbackName,
      key: this._config.apiKey,
      client: this._config.clientId,
      channel: this._config.channel,
      libraries: this._config.libraries,
      region: this._config.region,
      language: this._config.language || this.localeId !== 'en-US' ? this.localeId : null
    };
    const params = Object.keys(queryParams).filter(k => queryParams[k] != null).filter(k => {
      // remove empty arrays
      return !Array.isArray(queryParams[k]) || Array.isArray(queryParams[k]) && queryParams[k].length > 0;
    }).map(k => {
      // join arrays as comma seperated strings
      let i = queryParams[k];
      if (Array.isArray(i)) {
        return {
          key: k,
          value: i.join(',')
        };
      }
      return {
        key: k,
        value: queryParams[k]
      };
    }).map(entry => {
      return `${entry.key}=${entry.value}`;
    }).join('&');
    return `${protocol}//${hostAndPath}?${params}`;
  }
};
LazyMapsAPILoader.ctorParameters = () => [{
  type: undefined,
  decorators: [{
    type: Optional
  }, {
    type: Inject,
    args: [LAZY_MAPS_API_CONFIG]
  }]
}, {
  type: WindowRef
}, {
  type: DocumentRef
}, {
  type: String,
  decorators: [{
    type: Inject,
    args: [LOCALE_ID]
  }]
}];
LazyMapsAPILoader = __decorate([Injectable(), __param(0, Optional()), __param(0, Inject(LAZY_MAPS_API_CONFIG)), __param(3, Inject(LOCALE_ID)), __metadata("design:paramtypes", [Object, WindowRef, DocumentRef, String])], LazyMapsAPILoader);

/**
 * When using the NoOpMapsAPILoader, the Google Maps API must be added to the page via a `<script>`
 * Tag.
 * It's important that the Google Maps API script gets loaded first on the page.
 */
class NoOpMapsAPILoader {
  load() {
    if (!window.google || !window.google.maps) {
      throw new Error('Google Maps API not loaded on page. Make sure window.google.maps is available!');
    }
    return Promise.resolve();
  }
}

// exported map types

var AgmCoreModule_1;
/**
 * @internal
 */
function coreDirectives() {
  return [AgmBicyclingLayer, AgmCircle, AgmDataLayer, AgmFitBounds, AgmInfoWindow, AgmKmlLayer, AgmMap, AgmMarker, AgmPolygon, AgmPolyline, AgmPolylineIcon, AgmPolylinePoint, AgmRectangle, AgmTransitLayer];
}
/**
 * The angular-google-maps core module. Contains all Directives/Services/Pipes
 * of the core module. Please use `AgmCoreModule.forRoot()` in your app module.
 */
let AgmCoreModule = AgmCoreModule_1 = class AgmCoreModule {
  /**
   * Please use this method when you register the module at the root level.
   */
  static forRoot(lazyMapsAPILoaderConfig) {
    return {
      ngModule: AgmCoreModule_1,
      providers: [...BROWSER_GLOBALS_PROVIDERS, {
        provide: MapsAPILoader,
        useClass: LazyMapsAPILoader
      }, {
        provide: LAZY_MAPS_API_CONFIG,
        useValue: lazyMapsAPILoaderConfig
      }]
    };
  }
};
AgmCoreModule = AgmCoreModule_1 = __decorate([NgModule({
  declarations: coreDirectives(),
  exports: coreDirectives()
})], AgmCoreModule);

// main modules

/**
 * Generated bundle index. Do not edit.
 */

export { AgmBicyclingLayer, AgmCircle, AgmCoreModule, AgmDataLayer, AgmFitBounds, AgmGeocoder, AgmInfoWindow, AgmKmlLayer, AgmMap, AgmMarker, AgmPolygon, AgmPolyline, AgmPolylineIcon, AgmPolylinePoint, AgmRectangle, AgmTransitLayer, CircleManager, ControlPosition, DataLayerManager, FitBoundsAccessor, GeocoderLocationType, GeocoderStatus, GoogleMapsAPIWrapper, GoogleMapsScriptProtocol, InfoWindowManager, KmlLayerManager, LAZY_MAPS_API_CONFIG, LayerManager, LazyMapsAPILoader, MapTypeId, MapsAPILoader, MarkerManager, NoOpMapsAPILoader, PolygonManager, PolylineManager, RectangleManager, ScaleControlStyle, ZoomControlStyle, FitBoundsService as ɵa, coreDirectives as ɵb, WindowRef as ɵc, DocumentRef as ɵd, BROWSER_GLOBALS_PROVIDERS as ɵe };
