import {NgModule} from '@angular/core';
import {TaskListComponent} from './components/task-list/task-list.component';
import {TaskStoreModule} from "./store/module";
import {TaskListItemComponent} from './components/task-list-item/task-list-item.component';
import {NULL_TASK, NullTask, Task} from "./models/task";
import {NULL_TASK_LIST, NullTaskList, TaskList} from "./models/task-list";
import {NULL_CONTACT, NullContact} from "core";
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateAdapter,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule
} from "@angular-material-components/datetime-picker";
import {MAT_COLOR_FORMATS, MatColorFormats, NgxMatColorPickerModule} from "@angular-material-components/color-picker";
import {NgxMatMomentAdapter, NgxMatMomentModule} from "@angular-material-components/moment-adapter";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "material";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from "shared";
import {MapsModule} from "maps";
import {AuthenticationModule} from "auth";
import {MediaModule} from "media";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {ContactModule} from "contact";
import {PresenceModule} from "presence";
import {LayoutModule} from "layout";
import {FilterModule} from "filter";
import {TaskDetailComponent} from './components/task-detail/task-detail.component';
import {TaskListDetailComponent} from "./components/tasklist-detail/tasklist-detail.component";
import {TaskListListComponent} from "./components/tasklist-list/tasklist-list.component";
import {SessionModule} from "session";
import {TaskContactListComponent} from "./components/task-contact-list/task-contact-list.component";
import {CallbackDirective} from "./components/directives/callback.directive";
import {InterestsModule} from "interests";
import {DragDropModule} from "@angular/cdk/drag-drop";

const DATETIME_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'LLL',
  },
  display: {
    dateInput: 'LLL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LLL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

//'rgb' | 'hex' | 'hex6' | 'hex3' | 'hex4' | 'hex8'
const CUSTOM_MAT_COLOR_FORMATS: MatColorFormats = {
  display: {
    colorInput: 'hex8'
  }
}

export function nullTaskListFactory(nullContact: NullContact): TaskList {
  return new NullTaskList(nullContact);
}

export function nullTaskFactory(nullContact: NullContact, nullTaskList: TaskList): Task {
  return new NullTask(nullContact, nullTaskList);
}

export const PROVIDERS = [
  { provide: NULL_TASK_LIST, useFactory: nullTaskListFactory, deps: [NULL_CONTACT]},
  { provide: NULL_TASK, useFactory: nullTaskFactory, deps: [NULL_CONTACT, NULL_TASK_LIST]},
  { provide: NGX_MAT_DATE_FORMATS, useValue: DATETIME_FORMATS },
  { provide: MAT_COLOR_FORMATS, useValue: CUSTOM_MAT_COLOR_FORMATS },
  { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter },
];

@NgModule({
  declarations: [
    TaskListComponent,
    TaskListItemComponent,
    TaskDetailComponent,
    TaskListListComponent,
    TaskListDetailComponent,
    TaskContactListComponent,
    CallbackDirective,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    MapsModule,
    AuthenticationModule,
    MediaModule,
    ScrollingModule,
    ContactModule,
    PresenceModule,
    LayoutModule,
    SessionModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    FilterModule,
    TaskStoreModule,
    InterestsModule,
    DragDropModule,
    NgxMatColorPickerModule
  ],
  exports: [
    TaskListComponent,
    TaskDetailComponent,
    TaskListListComponent,
    TaskListDetailComponent,
    TaskContactListComponent
  ],
  providers: PROVIDERS
})
export class TaskModule { }
