import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {
  asContact,
  contactDeleteAction,
  contactDeleteDoneAction,
  contactDeleteFailedAction,
  contactLoadPageAction,
  contactLoadPageDoneAction,
  contactLoadPageFailedAction,
  contactLoadRequestAction,
  contactSetTypedFiltersAction,
  contactUpdateAction,
  contactUpdateDoneAction,
  contactUpdateFailedAction,
  contactUpdateSearchTermAction,
  contactUplineLoadAction,
  contactUplineLoadDoneAction,
  contactUplineLoadFailedAction,
  groupAuthorsLoadAction,
  groupAuthorsLoadDoneAction,
  groupAuthorsLoadFailedAction
} from "./actions";
import {addUrlParameter, Contact, Logger, EMPTY_ARRAY} from "core";
import {selectContactListState, State} from "./state";
import {VIEWED_FILTER_PREFIX} from 'shared';

@Injectable()
export class ContactEffects {

  protected logger = new Logger('ContactEffects');

  contactLoadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactLoadRequestAction,contactSetTypedFiltersAction,contactUpdateSearchTermAction),
      debounceTime(100),
      withLatestFrom(this.store$.select(selectContactListState)),
      map(([action,state]) => {
        //console.debug('loadRequest$', 'action', action, 'state', state);
        return contactLoadPageAction({cacheId:state.currentCacheId,index:0,size:25})
      })));

  contactLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactLoadPageAction),
      withLatestFrom(this.store$.select(selectContactListState)),
      mergeMap(([action, state]) => {
        const filters = <string[]>[].concat(...Object.keys(state.filters).map(type=>state.filters[type]??EMPTY_ARRAY));
        if (filters?.length > 0) {
          //console.debug('loadPage$', 'action', action, 'state', state);
          var query   = encodeURI(state.term.trim().replace(/\s+/g, ','));
          var append  = addUrlParameter("filter",filters.join(','),true).add("query",query,true).toString();
          var path    = "/v1.0/contacts/segment/"+action.cacheId+"/"+action.index+"/"+action.size+append;
          return this.http.get(path).pipe(
            map(result => {
              //console.debug("loaded",result);
              if (action.index==0) {
                let viewed = filters.find(id => id.startsWith(VIEWED_FILTER_PREFIX));
                //console.log("VIEWED",viewed);
                if (!!viewed) {
                  this.store$.dispatch(contactUplineLoadAction({contactId:viewed.substring(VIEWED_FILTER_PREFIX.length)}));
                }
              }
              return contactLoadPageDoneAction({cacheId:action.cacheId, index:action.index, data:result['data']?.map(c=>asContact(c)), parent:asContact(result['parent']), total:result['size'],
                dispatch: (dispatchAction : Action) => {
                  //console.debug("dispatch loaded ["+action.index+"/"+action.size+"]");
                  this.store$.dispatch(dispatchAction);
                }})
            }),
            catchError((error) => {
              return of(contactLoadPageFailedAction({cacheId:action.cacheId,index:action.index,size:action.size,error}))
            })
          )
        } else {
          return of(contactLoadPageDoneAction({cacheId:action.cacheId,index:0,total:0,data:[],
            dispatch:(dispatchAction : Action) => this.store$.dispatch(dispatchAction)}));
        }
      })));

  contactUplineLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactUplineLoadAction),
      //withLatestFrom(this.store$.pipe(select(selectContactUplineState))),
      mergeMap(action => { //([action,state]) => {
        let path = "/v1.0/contacts/upline/"+action.contactId;
        return this.http.get(path).pipe(
          map((result:{data: Contact[]}) => {
            return contactUplineLoadDoneAction({contactId:action.contactId,upline:result.data?.map(c=>asContact(c))});
          }),
          catchError((error) => {
            console.error('ERROR', error);
            return of(contactUplineLoadFailedAction({contactId:action.contactId, error}));
          })
        )
      })));

  groupAuthorsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupAuthorsLoadAction),
      //withLatestFrom(this.store$.pipe(select(selectContactUplineState))),
      mergeMap(action => { //([action,state]) => {
        if (!!action.force) {
          //console.log("LOAD_AUTHORS");
          let path = "/v1.0/contacts/authors";
          return this.http.get(path).pipe(
            map((result:{data: Contact[]}) => {
              return groupAuthorsLoadDoneAction({groupId:action.groupId,authors:result.data?.map(c=>asContact(c))});
            }),
            catchError((error) => {
              console.error('ERROR', error);
              return of(groupAuthorsLoadFailedAction({groupId:action.groupId, error}));
            })
          )
        } else {
          return EMPTY;
        }
      })));

  contactUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactUpdateAction),
      mergeMap(action  => {
        let path = `/v1.0/contacts`;
        return this.http.post(path, action.contact).pipe(
          map((contact: Contact) => {
            //console.log("CONTACT_.UPDATED",contact);
            return contactUpdateDoneAction({correlationId:action.correlationId,contact});
          }),
          catchError((error) => {
            console.error('ERROR', error);
            return of(contactUpdateFailedAction({correlationId:action.correlationId,contact:action.previous, error}));
          })
        )
      })));

  contactDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactDeleteAction),
      mergeMap(action  => {
        let path = `/v1.0/contacts/delete/${action.contact.id}`;
        return this.http.post(path, {}).pipe(
          map((result: { done:boolean }) => {
            const contact = new Contact({...action.contact, timeDeleted:Date.now()});
            //console.log("CONTACT_.DELETED",contact);
            return contactDeleteDoneAction({correlationId:action.correlationId,contact});
          }),
          catchError((error) => {
            console.error('ERROR', error);
            return of(contactDeleteFailedAction({correlationId:action.correlationId,contact:action.contact, error}));
          })
        )
      })));

  constructor(private http: HttpClient, private actions$: Actions, private store$: Store<State>) {}
}
