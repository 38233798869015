import {EntityState, SearchableState1, SimpleFilterableState} from "core";
import {
  ChatParticipationsAddMessageType,
  ChatParticipationsChangeMessageType,
  ChatParticipationsRemoveMessageType,
  ChatTimelineMessage,
  ConversationData
} from "./models";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {isValidNumber} from "shared";

export const chatFeatureKey = 'chat';

/**
 * default size of chat conversation message chunks for loading
 * and persistance.
 */
export const CHAT_DEFAULT_CHUNK_SIZE = 100;
export const CHAT_MAX_CHUNK_SIZE = 120;
export const CHAT_DEFAULT_HOT_CHUNK_SIZE = 10;
export const CHAT_MAX_HOT_CHUNK_SIZE = 50;

/**
 * sorting by timeCreated of the draftMessage or if not available lastMessage.
 * newestVersion/oldestVersion ... timeModified||timeCreated of the messages
 */
export interface ConversationsState extends
  EntityState<ConversationData>,
  SearchableState1,
  SimpleFilterableState {
  filterId: string;
  currentConversationId?: string;
  newestMessageVersion: number; // the newest persistent timeline message updated/created time
}

/**
 * sorting by timeCreated of the messages.
 *            timeCreated is the time the message is scheduled for sending (user pressed send)
 * newestVersion/oldestVersion ... timeModified||timeCreated of the messages
 */
export interface ConversationState extends
  EntityState<ChatTimelineMessage>,
  SearchableState1,
  SimpleFilterableState {
  conversationData?: ConversationData; // same conversation objects as in ChatConversationSummariesState
  filterId?: string;
  newestIncomingMessageVersion: number; // the newest persistent timeline message updated/created time from other sender
  newestMessageVersion: number; // the newest persistent timeline message updated/created time
}

export interface Error {
  label?: string;
  params: {[key:string]: any };
}

export interface ChatState {
  conversationsState : ConversationsState;
  conversationStates : { [key:string]: ConversationState };
  conversationsQueue : ChatTimelineMessage[];
  // conversationId:time ... on sync all conversations,
  // to know which are new and which should be removed
  conversationsMarker: {[key:string]:number};
  participantId : string;
  online : boolean;
  error: Error;   // error message in current locale
}

export const initialConversationState : ConversationState = {
  entities: [],
  indices: {},
  filterId: undefined,
  filters: [],
  term: '',
  newestIncomingMessageVersion: 0,
  newestMessageVersion: 0,
};

export const initialChatState : ChatState = {
  conversationsState: {
    entities: [],
    indices: {},
    filterId: undefined,
    filters: [],
    term: '',
    newestMessageVersion: 0
  },
  conversationsQueue: [],
  conversationStates: {},
  conversationsMarker: {},
  online: false,
  participantId: undefined,
  error: {
    params: {}
  }
};

// do not export - leads to naming conflicts with other feature State interfaces
export interface State {
  readonly [chatFeatureKey] : ChatState;
}

export const selectChatState = createFeatureSelector<State,ChatState>(chatFeatureKey);

export const selectConversationsState = createSelector(
  selectChatState,
  state => state.conversationsState
);

export const selectOnlineState = createSelector(
  selectChatState,
  state => state.online
);

export const selectConversations = createSelector(
  selectConversationsState,
  state => state.entities
);

export const selectConversationsUnseen = createSelector(
  selectConversations,
  conversations => conversations?.map(conversation=>conversation.unseen??0).reduce((accumulator, currentValue) => accumulator + currentValue, 0) ?? 0
);

export const selectCurrentConversationId = createSelector(
  selectConversationsState,
  state => state.currentConversationId
);

export const selectConversationsQueue = createSelector(
  selectChatState,
  state => state.conversationsQueue
);

export const selectConversationsMarker = createSelector(
  selectChatState,
  state => state.conversationsMarker
);

export const selectConversationState = (conversationId: string) => createSelector(
  selectChatState,
  (state): ConversationState => state.conversationStates[conversationId]
);

export const selectConversation = (conversationId: string) => createSelector(
  selectChatState,
  (state): ConversationData => state.conversationStates[conversationId]?.conversationData
);

export const selectConversationReplyMessage = (conversationId: string) => createSelector(
  selectChatState,
  (state): ChatTimelineMessage =>
    !!conversationId ?
      state.conversationStates[conversationId]?.conversationData?.replyMessage : undefined
);

export const selectConversationDraftMessage = (conversationId: string) => createSelector(
  selectChatState,
  (state): ChatTimelineMessage =>
    !!conversationId ?
      state.conversationStates[conversationId]?.conversationData?.draftMessage : undefined
);

export const selectConversationMessage = (conversationId: string, messageId: string) => createSelector(
  selectChatState,
  (state): ChatTimelineMessage => {
    const conversationState:ConversationState = state.conversationStates[conversationId];
    const index = conversationState?.indices[messageId];
    return isValidNumber(index) ? conversationState.entities[index] : undefined;
  }
);

export const selectConversationTimeMuted = (conversationId: string) => createSelector(
  selectChatState,
  (state): number => state.conversationStates[conversationId]?.conversationData?.timeMuted
);

export const selectConversationMessages = (conversationId: string) => createSelector(
  selectChatState,
  (state): ChatTimelineMessage[] => state.conversationStates[conversationId]?.entities
);

export const mainConversationTypes:Set<string> = new Set(['direct','group','host']);
export const participantMessageTypes:Set<string> = new Set([ChatParticipationsAddMessageType,ChatParticipationsChangeMessageType,ChatParticipationsRemoveMessageType]);
export const transientMessageTypes:Set<string> = new Set([ChatParticipationsAddMessageType,ChatParticipationsChangeMessageType,ChatParticipationsRemoveMessageType]);
