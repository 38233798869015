@charset "UTF-8";

/* projects/tasks/src/lib/components/task-detail/task-detail.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: column;
}
presence {
  position: absolute;
  right: 0;
  bottom: 0;
}
.view,
.edit {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.view .participants,
.view .attachments,
.edit .participants,
.edit .attachments {
  display: flex;
  flex-direction: column;
}
.view .participants:not(:last-child),
.view .attachments:not(:last-child),
.edit .participants:not(:last-child),
.edit .attachments:not(:last-child) {
  margin-bottom: 24px;
}
.view .participants.attachments,
.view .attachments.attachments,
.edit .participants.attachments,
.edit .attachments.attachments {
  margin-bottom: 24px;
}
.view .participants.attachments mat-list-item:not(:last-of-type),
.view .attachments.attachments mat-list-item:not(:last-of-type),
.edit .participants.attachments mat-list-item:not(:last-of-type),
.edit .attachments.attachments mat-list-item:not(:last-of-type) {
  padding-bottom: 5px;
}
.view .participants .action,
.view .attachments .action,
.edit .participants .action,
.edit .attachments .action {
  align-self: flex-end;
  margin-right: 3px;
}
.view mat-expansion-panel,
.edit mat-expansion-panel {
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.54);
  background-color: transparent;
}
.view mat-expansion-panel mat-expansion-panel-header,
.edit mat-expansion-panel mat-expansion-panel-header {
  padding-left: 16px;
  padding-right: 16px;
}
.view mat-expansion-panel mat-expansion-panel-header mat-panel-title,
.edit mat-expansion-panel mat-expansion-panel-header mat-panel-title {
  flex: 0;
  color: rgba(0, 0, 0, 0.54);
}
.view mat-expansion-panel mat-expansion-panel-header mat-panel-description,
.edit mat-expansion-panel mat-expansion-panel-header mat-panel-description {
  align-items: center;
  flex-direction: row-reverse;
  margin-right: 0;
  color: rgba(0, 0, 0, 0.87);
}
.view mat-expansion-panel mat-expansion-panel-header ::ng-deep .mat-content-hide-toggle,
.edit mat-expansion-panel mat-expansion-panel-header ::ng-deep .mat-content-hide-toggle {
  margin: 0;
}
.view mat-expansion-panel ::ng-deep .mat-expansion-panel-body,
.edit mat-expansion-panel ::ng-deep .mat-expansion-panel-body {
  padding: 0;
}
.view mat-expansion-panel.empty > mat-expansion-panel-header,
.edit mat-expansion-panel.empty > mat-expansion-panel-header {
  padding-right: 0;
}
.view mat-expansion-panel mat-list,
.edit mat-expansion-panel mat-list {
  padding-top: 0;
}
.view mat-expansion-panel mat-list mat-list-item,
.edit mat-expansion-panel mat-list mat-list-item {
  height: auto;
  padding-left: 4px;
}
.view mat-expansion-panel mat-list mat-list-item.selected,
.edit mat-expansion-panel mat-list mat-list-item.selected {
  color: white;
  background-color: var(--color-primary-300);
}
.view mat-expansion-panel mat-list mat-list-item .participant,
.edit mat-expansion-panel mat-list mat-list-item .participant {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
  height: auto;
}
.view mat-expansion-panel mat-list mat-list-item .participant div.avatar,
.edit mat-expansion-panel mat-list mat-list-item .participant div.avatar {
  margin-right: 12px;
  position: relative;
}
.view mat-expansion-panel mat-list mat-list-item .participant img[matlistitemavatar],
.edit mat-expansion-panel mat-list mat-list-item .participant img[matlistitemavatar] {
  width: 40px !important;
  height: 40px !important;
}
.view mat-expansion-panel mat-list mat-list-item .participant .name,
.edit mat-expansion-panel mat-list mat-list-item .participant .name {
  flex: 1;
  word-break: break-word;
}
.view mat-expansion-panel mat-list mat-list-item .attachment,
.edit mat-expansion-panel mat-list mat-list-item .attachment {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: auto;
}
.view mat-expansion-panel mat-list mat-list-item .attachment media-card,
.edit mat-expansion-panel mat-list mat-list-item .attachment media-card {
  width: 110px;
  font-size: 0.5em;
}
.view mat-expansion-panel mat-list mat-list-item .attachment .attachment-details,
.edit mat-expansion-panel mat-list mat-list-item .attachment .attachment-details {
  flex: 1;
  position: relative;
  line-height: 1.2;
  font-size: 0.85em;
  color: #424242;
  padding: 5px 0 0 5px;
  margin-right: 5px;
  overflow: hidden;
  cursor: pointer;
}
.view mat-expansion-panel mat-list mat-list-item .attachment .attachment-details > div:first-child,
.edit mat-expansion-panel mat-list mat-list-item .attachment .attachment-details > div:first-child {
  white-space: nowrap;
  font-size: 85%;
  color: #757575;
  margin-bottom: 4px;
}
.view mat-expansion-panel mat-list mat-list-item .attachment .attachment-details > div:first-child > span,
.edit mat-expansion-panel mat-list mat-list-item .attachment .attachment-details > div:first-child > span {
  display: block;
}
.view mat-expansion-panel mat-list mat-list-item .attachment .attachment-details::after,
.edit mat-expansion-panel mat-list mat-list-item .attachment .attachment-details::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 100%;
  background:
    linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      white);
  pointer-events: none;
}
.view mat-expansion-panel mat-list mat-list-item .mat-mdc-icon-button[matlistitemmeta],
.edit mat-expansion-panel mat-list mat-list-item .mat-mdc-icon-button[matlistitemmeta] {
  margin-left: 8px;
  margin-right: 8px;
}
.view mat-expansion-panel mat-list mat-list-item mat-divider,
.edit mat-expansion-panel mat-list mat-list-item mat-divider {
  position: absolute;
  top: 0;
  bottom: unset;
}
.view mat-expansion-panel mat-list mat-list-item mat-divider:not(.first),
.edit mat-expansion-panel mat-list mat-list-item mat-divider:not(.first) {
  margin-left: 50px;
  width: calc(100% - 70px);
}
.view > button.mat-raised-button,
.edit > button.mat-raised-button {
  margin: 10px 0 0;
}
.view > button[matSuffix] mat-icon,
.edit > button[matSuffix] mat-icon {
  height: 24px;
}
.view .mat-mdc-form-field .mat-suffix-center,
.edit .mat-mdc-form-field .mat-suffix-center {
  bottom: 3px;
}
.view .mat-mdc-form-field.target ::ng-deep .mat-mdc-form-field-icon-prefix,
.edit .mat-mdc-form-field.target ::ng-deep .mat-mdc-form-field-icon-prefix {
  bottom: 0 !important;
  padding: 0 8px 0 0;
}
.view .mat-mdc-form-field.target ::ng-deep .mat-mdc-form-field-icon-prefix > span,
.edit .mat-mdc-form-field.target ::ng-deep .mat-mdc-form-field-icon-prefix > span {
  position: relative;
}
.view .actions > *,
.edit .actions > * {
  display: block;
  margin-bottom: 20px;
}
.view .actions > * mat-label,
.edit .actions > * mat-label {
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.54);
}
.view {
  padding: 16px 24px;
}
.view mat-divider {
  margin-left: -24px;
  margin-right: -24px;
}
.view .info,
.view .remark {
  font-size: 14px;
}
.view .target {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.view .target media-icon {
  flex: none;
}
.view .target.target-contact img {
  margin-right: 12px;
  vertical-align: middle;
}
.view .target.target-media > :first-child {
  margin-right: 14px;
  margin-left: 4px;
}
.view .properties {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13.5px;
}
.view .properties > .time {
  margin-bottom: 16px;
}
.view .properties > .time h1 {
  font-size: 13.5px;
  margin: 0 0 0 28px;
  line-height: 1em;
}
.view .properties > .time mat-icon {
  vertical-align: middle;
  margin-right: 6px;
}
.view .properties > .time span {
  font-weight: 500;
  vertical-align: bottom;
}
.view .properties > .time.overdue mat-icon {
  color: #f44336;
}
.view .properties > span {
  display: inline-block;
}
.view mat-checkbox,
.view tri-state-checkbox {
  margin-bottom: 16px;
}
.view button.mat-mini-fab {
  align-self: flex-end;
  margin-top: -10px;
}
.view .mat-mdc-form-field.mat-form-field-disabled ::ng-deep .mat-form-field-underline {
  height: 0;
}
.edit {
  padding: 10px;
}
.edit > button:last-of-type {
  margin-top: 30px !important;
}
.section:not(:last-child) {
  margin-bottom: 20px;
}
mat-form-field.hidden {
  visibility: hidden;
  height: 0;
}
mat-form-field.target ::ng-deep .mat-mdc-text-field-wrapper {
  overflow: visible;
}
mat-form-field.selector ::ng-deep .mat-mdc-form-field-infix:before {
  content: "\a0";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: all;
  cursor: pointer;
}
mat-form-field.selector ::ng-deep .mat-mdc-form-field-infix label,
mat-form-field.selector ::ng-deep .mat-mdc-form-field-infix input:disabled {
  pointer-events: none;
}
mat-form-field.selector.task-list mat-icon[matPrefix] {
  color: var(--color, inherit);
}
.header {
  display: block;
  margin: 0 0 10px;
  padding: 0;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.54);
}
a.action {
  font-size: 0.8rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
a.action:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: underline;
}
:host-context(.color-scheme-light) .view {
  background-color: #f5f5f5;
}
:host-context(.color-scheme-dark) .header {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) a.action {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) a.action:hover {
  color: rgba(174, 175, 176, 0.8);
}
:host-context(.color-scheme-dark) mat-divider {
  border-color: #AEAFB0;
}
:host-context(.color-scheme-dark) mat-expansion-panel {
  border-color: rgba(174, 175, 176, 0.8);
}
:host-context(.color-scheme-dark) mat-expansion-panel mat-expansion-panel-header mat-panel-title {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) mat-expansion-panel mat-expansion-panel-header ::ng-deep .mat-expansion-indicator:after {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) mat-expansion-panel mat-expansion-panel-header mat-panel-description {
  color: white;
}
:host-context(.color-scheme-dark) mat-expansion-panel mat-list mat-list-item div.participant,
:host-context(.color-scheme-dark) mat-expansion-panel mat-list mat-list-item div.attachment {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) mat-expansion-panel mat-list mat-list-item div.attachment .attachment-details::after {
  background:
    linear-gradient(
      to right,
      rgba(24, 24, 24, 0),
      #181818);
}
:host-context(.color-scheme-dark) mat-expansion-panel mat-list mat-list-item.selected {
  background-color: rgba(174, 175, 176, 0.2);
}
:host-context(.color-scheme-dark) .properties {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .target {
  border-color: rgba(174, 175, 176, 0.8);
}
:host-context(.color-scheme-dark) .actions > * mat-label {
  color: #AEAFB0;
}
/*# sourceMappingURL=task-detail.component-FOW6YDPD.css.map */
