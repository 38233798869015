import {Inject, Injectable} from "@angular/core";
import {PropertiesService} from "properties";
import {ENVIRONMENT} from "core";
import {interval, Observable, of} from "rxjs";
import {distinctUntilChanged, startWith, switchMap} from "rxjs/operators";
import {ProductAdapter} from "./product-adapter.service";

@Injectable({
  providedIn: 'root'
})
export class ProductSelector {

  protected static PRO = 'pro';
  protected static MAX = 'max';

  constructor(protected propertiesService: PropertiesService,
              protected productAdapter: ProductAdapter,
              @Inject(ENVIRONMENT) protected environment: any) {
    //console.debug('ProductSelector.ctor()');
  }

  select(path: string = this.environment.app, active = true): Observable<[string, string, number, number]> {
    path = path ? path+'.' : '';
    return this.propertiesService.user$.pipe(
      switchMap((user) => {
        return interval(5 * 60 * 1000).pipe( // scan every 5 min
          startWith(0),
          switchMap(() => {
            let productId, source;
            let expiryTime, cancelTime;
            expiryTime = cancelTime = 0;
            if (user.products) {
              const now = new Date().getTime();
              for (let pid in user.products) {
                const product = user.products[pid];
                const productPath = this.productAdapter.normalize(path); //product.source!='stripe' ? path : path.replace(/\./g, '_');
                if (!productPath || this.productAdapter.normalize(pid).startsWith(productPath) &&
                  product.expiry_time > expiryTime &&
                  (!active || product.expiry_time > now)) {
                  productId  = pid;
                  source = product.source;
                  expiryTime = product.expiry_time || 0;
                  cancelTime = product.cancel_time || 0;
                }
              }
            }
            return of([productId, source, expiryTime, cancelTime] as [string, string, number, number]);
          }),
          distinctUntilChanged((previous, current) =>
            previous.length==current.length &&
            previous.every((value) => current.includes(value))
          )
        )
      })
    );
  }

  selectPro(): Observable<[string, string, number, number]> {
    return this.select(`${this.environment.app}.${ProductSelector.PRO}`);
  }

  selectMax(): Observable<[string, string, number, number]> {
    return this.select(`${this.environment.app}.${ProductSelector.MAX}`);
  }
}
