import {ApplicationRef, Inject, Injectable} from '@angular/core';
import {IAPError, IAPProduct, InAppPurchase2} from "@ionic-native/in-app-purchase-2/ngx";
import {ENVIRONMENT, Logger, Platform} from "core";
import {HttpClient} from "@angular/common/http";
import {debounceTime, first, withLatestFrom} from "rxjs/operators";
import {Product, ProductCallback, ProductEvents} from "../models/product";
import {Tax} from "../models/tax";
import {PaymentServiceImpl} from "../service/payment-service.factory";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {ProductAdapter} from "../service/product-adapter.service";

/*
 * https://medium.com/@andrew.thielcole/in-app-purchases-with-ionic-3-af13b21f49f2
 * https://github.com/thielCole/ionic-iap2
 * https://github.com/Fovea/cordova-plugin-purchase-demo
 * https://scottbolinger.com/cordova-app-purchases-validating-subscriptions/
 * https://alexdisler.com/2016/02/29/in-app-purchases-ionic-cordova/
 *
 * https://github.com/Fovea/cordova-plugin-purchase-demo
 * https://billing.fovea.cc/
*/

/*
 *   https://github.com/j3k0/cordova-plugin-purchase/blob/master/doc/api.md#life-cycle
 *
 *   REGISTERED +--> INVALID
 *              |
 *              +--> VALID +--> REQUESTED +--> INITIATED +-+
 *                                                         |
 *                   ^      +------------------------------+
 *                   |      |
 *                   |      |             +--> DOWNLOADING +--> DOWNLOADED +
 *                   |      |             |                                |
 *                   |      +--> APPROVED +--------------------------------+--> FINISHED +--> OWNED
 *                   |                                                             |
 *                   +-------------------------------------------------------------+
*/


/*
 android error: Init failed - Failed to query inventory: IabResult: Error refreshing inventory (querying owned items). (response: 6777017:Error)
 solution: clear Google Play Services storage: Settings > Apps > Google Play Services > Storage > Clear Storage + Clear Cache
*/

/*
 * we should take product price and title from store - hard coding is not tolerated sometimes:
 * https://github.com/j3k0/cordova-plugin-purchase/blob/master/doc/api.md
 * "...This isn't an optional step as some despotic store owners (like Apple) require you to display information about a
 * product as retrieved from their server: no hard-coding of price and title allowed!
*/

enum ProductId {
  REALIZER_PRO_SUBSCRIPTION_MONTHLY  = 'realizer.pro.subscription.monthly',
  REALIZER_PRO_SUBSCRIPTION_BIANNUAL = 'realizer.pro.subscription.biannual',
  REALIZER_PRO_SUBSCRIPTION_ANNUAL   = 'realizer.pro.subscription.annual'
}

@Injectable({
  providedIn: 'root'
})
export class IapPaymentService implements PaymentServiceImpl {

  protected static productDefinitions: {[key in ProductId]: any } = {
    [ProductId.REALIZER_PRO_SUBSCRIPTION_MONTHLY]: {
      type: 'PAID_SUBSCRIPTION', //'NON_CONSUMABLE',
      googleProductId: 'realizer.pro.subscription.monthly', //'android.test.purchased',
      appleProductId:  'realizer.pro.subscription.monthly'
    },
    [ProductId.REALIZER_PRO_SUBSCRIPTION_BIANNUAL]: {
      type: 'PAID_SUBSCRIPTION', //'NON_CONSUMABLE',
      googleProductId: 'realizer.pro.subscription.biannual', //'android.test.canceled',
      appleProductId:  'realizer.pro.subscription.biannual'
    },
    [ProductId.REALIZER_PRO_SUBSCRIPTION_ANNUAL]: {
      type: 'PAID_SUBSCRIPTION', //'NON_CONSUMABLE',
      googleProductId: 'realizer.pro.subscription.yearly', //'android.test.canceled',
      appleProductId:  'realizer.pro.subscription.yearly'
    }
  };

  protected productIds$: Subject<string[]>;
  protected logger = new Logger('IapPaymentService');

  constructor(protected iap: InAppPurchase2,
              protected platform: Platform,
              protected httpClient: HttpClient,
              protected applicationRef: ApplicationRef,
              protected productAdapter: ProductAdapter,
              @Inject(ENVIRONMENT) protected readonly environment: any) {
      this.initializeIap();
  }

  protected initializeIap() {
    this.logger.info('Initialize InAppPurchase');
    this.iap.verbosity = this.iap.DEBUG;
    /*
     *  https://github.com/j3k0/cordova-plugin-purchase/blob/master/doc/api.md
     *  "Sometimes during development, the queue of pending transactions fills up on your devices.
     *  Before doing anything else you can set store.autoFinishTransactions to true to clean up the queue.
     *  Beware: this is not meant for production."
     */
    if (!this.environment.production) {
      this.iap.autoFinishTransactions = true;
    }
    /*
     *  The plugin will auto refresh the status of user's purchases every 24h.
     *  You can change this interval by setting store.autoRefreshIntervalMillis to another interval (before calling store.init()).
     *  (this isn't implemented on iOS since it isn't necessary). Set to 0 to disable auto-refreshing.
     */
    // this.iap.autoRefreshIntervalMillis = 6 * 60 * 60 * 1000; // every 6 hours

    /*
     *   A purchased product will contain transaction information that can be sent to a remote server for validation.
     *   This information is stored in the product.transaction field.
     *   It has the following format:
     *    type: "ios-appstore" or "android-playstore"
     *    store specific data:
     *      Apple: https://developer.apple.com/library/archive/releasenotes/General/ValidateAppStoreReceipt/Chapters/ReceiptFields.html#//apple_ref/doc/uid/TP40010573-CH106-SW1
     *      Google: https://developer.android.com/google/play/billing/billing_library_overview
     *
     *  third-party validation server: https://billing-dashboard.fovea.cc/
     */

    // this.iap.validator = 'https://reeceipt-validator.fovea.cc/v1/validate?appName=realizer&apiKey=f0d3d5b5-7974-4eea-99d4-5bde7c3503df';
    this.iap.validator = (product: IAPProduct, callback) => {
      this.logger.info('VALIDATOR', product);
      this.httpClient.post('/v1.0/payment/process', product).subscribe((result: any) => {
        if (result && result.success) {
          this.logger.info('Validation succeeded. Transaction', result.transaction);
          callback(true, product.transaction);
        } else {
          this.logger.info('Validation failed. Error', result.error);
          callback(false, {
            code: this.iap.PURCHASE_EXPIRED,
            error: {
              message: result.error
            }
          });
        }
      });
    };

    try {
      Object.keys(ProductId).forEach(productId => this.register(ProductId[productId]));
      this.registerHandlers();
      this.iap.ready((status: any) => {
        this.logger.info('Status', status);
        this.logger.info('Products', this.iap.products);
      });
      this.iap.refresh();
    } catch(e) {
      this.logger.error(e);
    }

    this.platform.resume$.pipe(
      withLatestFrom(this.applicationRef.isStable.pipe(first(isStable => isStable === true))),
      debounceTime(1000)
    ).subscribe(([resume, stable]) => {
      this.logger.debug('RESUME', resume, stable);
      if (stable) {
        // this.restorePurchases();
      }
    })
  }

  protected register(productId: string) {
    const productDefinition = IapPaymentService.productDefinitions[productId];
    const nativeProductId = this.nativeProductId(productDefinition);
    if (nativeProductId) {
      // The product id and type have to match products defined in Apple and Google developer consoles.
      this.iap.register({
        id:    nativeProductId,
        alias: nativeProductId,
        type:  this.iap[productDefinition.type]
      });
    }
  }

  protected nativeProductId(productId: ProductId | object): string {
    const productDefinition = typeof productId === 'string' ? IapPaymentService.productDefinitions[productId] : productId;
    if (productDefinition) {
      if (this.platform.is('ios')) {
        return productDefinition.appleProductId;
      } else if (this.platform.is('android')) {
        return productDefinition.googleProductId;
      }
    }
    return null;
  }

  protected registerHandlers() {
    // https://github.com/j3k0/cordova-plugin-purchase/blob/master/doc/api.md#queries
    const query = "product"; // listen for events on all products
    this.iap.when(query).loaded( (product: IAPProduct) => {
      this.logger.info('Loaded', product);
    });
    this.iap.when(query).updated( (product: IAPProduct) => {
      this.logger.info('Updated', product);
    });
    this.iap.when(query).approved( (product: IAPProduct) => {
      this.logger.info('Approved', product);
      // Once the transaction is approved, the product still isn't owned:
      // the store needs confirmation that the purchase was delivered before closing the transaction.
      // To confirm delivery, you'll use the product.finish() method.

      // To verfify a purchase you'll have to do three things:
      // 1. configure the validator.
      // 2. call product.verify() from the approved event, before finishing the transaction.
      // 3. finish the transaction when transaction is verified.
      product.verify();
    });
    this.iap.when(query).owned( (product: IAPProduct) => {
      this.logger.info('Owned', product);
    });
    this.iap.when(query).cancelled( (product: IAPProduct) => {
      this.logger.info('Cancelled', product);
    });
    this.iap.when(query).refunded( (product: IAPProduct) => {
      this.logger.info('Refunded', product);
    });
    this.iap.when(query).verified((product: IAPProduct) => {
      this.logger.info('Verified', product);
      product.finish();
    });
    this.iap.when(query).unverified((product: IAPProduct) => {
      this.logger.info('Unverified', product);
    });
    this.iap.when(query).expired((product: IAPProduct) => {
      this.logger.info('Expired', product);
    });
    const iapProductEvents = this.iap.when(query);
    iapProductEvents.downloading = (product: IAPProduct, progress: any, time_remaining: any) => {
      this.logger.info('Downloading', product, progress, time_remaining);
      return iapProductEvents;
    };
    this.iap.when(query).downloaded( (product: IAPProduct) => {
      this.logger.info('Downloaded', product);
    });
    this.iap.when(query).error( (error: IAPError) => {
      this.logger.error(error);
    });
    this.iap.error((error: any) => {
      this.logger.info('Error', error);
    });
  }

  /*** PUBLIC API ***/

  get source(): string {
    if (this.platform.is('ios')) {
      return 'apple';
    } else if (this.platform.is('android')) {
      return 'google';
    }
    return null;
  }


  get productIds(): Observable<string[]> {
    // return Object.keys(ProductId).map(productId => this.nativeProductId(ProductId[productId]));
    if (!this.productIds$) {
      this.productIds$ = new ReplaySubject<string[]>();
      this.productIds$.next(this.iap.products.map(productId => this.nativeProductId(productId)));
      this.productIds$.complete(); // last value will be replayed (BehaviorSubject will not provide its value on subscription when completed)
    }
    return this.productIds$.asObservable();
  }

  get(productId: string): Promise<Product> {
    return Promise.resolve(this.iap.get(productId) as unknown as Product);
  }

  when(productId: string): ProductEvents {
      return this.iap.when(productId) as unknown as ProductEvents;
  }

  off(callback: ProductCallback) {
    return this.iap.off(callback);
  }

  async order(productId: string): Promise<boolean> {
    const {then, error} =  await this.iap.order(productId, null);
    //TODO: how to use then and error functions? Check IAP spec...
    return Promise.resolve(true);
  }

  /*
  selectActive(path: string): Observable<[Product, number]> {
    throw new Error('NOT IMPLEMENTED');
  }
  */

  async getTax(): Promise<Tax> {
    throw new Error('NOT IMPLEMENTED');
  }

  async cancel(productId: string): Promise<void> {
    // Cordova iap plugin does not support this.
    // apple and google users can use the corresponding store to manage their app subscriptions
    // can this be automated? should we show "Cancel Subscription" link in ui for cordova apps?
    throw new Error('NOT SUPPORTED');
  }

  async refund(productId: string): Promise<void> {
    throw new Error('NOT SUPPORTED');
  }

  restorePurchases() {
    /* https://github.com/j3k0/cordova-plugin-purchase/blob/master/doc/api.md#refresh
     *
     * "This will initiate all the complex behind-the-scene work, to load product data from the servers and restore
     * whatever already have been purchased by the user."
     * ...
     * "To make the restore purchases work as expected, please make sure that the "approved" event listener
     * had be registered properly, and in the callback product.finish() should be called."
     */
    this.iap.refresh();
  }

  getInterval(productId: string): {value: number, name: string} {
    // const name  = productId?.slice(productId.lastIndexOf('.')+1)?.toLowerCase();
    // const value = productId == ProductId.REALIZER_PRO_SUBSCRIPTION_MONTHLY  ? 1  :
    //               productId == ProductId.REALIZER_PRO_SUBSCRIPTION_BIANNUAL ? 6  :
    //               productId == ProductId.REALIZER_PRO_SUBSCRIPTION_ANNUAL   ? 12 : 0;
    // return { value: value, name: name };
    return this.productAdapter.interval(productId);
  }

  getType(productId: string): string {
    // let parts = productId?.split('.');
    // return parts?.length > 1 ? parts[1] : undefined;
    return this.productAdapter.type(productId);
  }

  /*** PUBLIC API END ***/
}
