import {Contact, NULL_CONTACT, VersionedId} from "core";
import {Inject, InjectionToken} from "@angular/core";
import {Message} from "messaging";

export class TaskList {
  readonly id: string;
  readonly version : number;
  author: Partial<Contact>;
  title: string;
  info: string;
  type: string;
  tags: string[];
  color: string;
  properties: any;
  editorsTerm: string;
  viewersTerm: string;
  permission: any;
  timeCreated: number;
  timeUpdated: number;

  constructor(init?: Partial<TaskList>) {
    Object.assign(this, init);
  }

  get isNew(): boolean {
    return !this.id && !this.version;
  }

  get isDefault(): boolean {
    return this.id=='0';
  }

  get editable() {
    return this.permission?.edit;
  }
}

export class NullTaskList extends TaskList {

  constructor(@Inject(NULL_CONTACT)  public author: Contact) {
    super();
    this.title = '';
    this.info = '';
    this.timeCreated = this.timeUpdated = new Date().getTime();
    this.permission = {edit: true};
  }
}

export const NULL_TASK_LIST = new InjectionToken('NullTaskList');

export interface TaskListRelatedMessage extends Message {
}

export const TaskListSubscriptionMessageType : string = "task.list.subscription";
export interface TaskListSubscriptionMessage extends TaskListRelatedMessage {
  subscribed: VersionedId[];
}

export const TaskListMessageType : string = "taskList";
export interface TaskListMessage extends TaskListRelatedMessage {
  taskList: TaskList;
}

export enum FilterKeys {
  TASK_LIST_PREFIX  = 'taskList.',
  TASK_LIST_EXCLUDE_PREFIX = 'taskList.exclude.',
}
