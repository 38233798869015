<ng-container *ngLet="{
    health: mediaService.getMedia$(mediaIds['health']),
    sports: mediaService.getMedia$(mediaIds['sports']),
    weight: mediaService.getMedia$(mediaIds['weight'])
} as media">
<div class="chart"
     *ngIf="display"
     [class.autoWidth]="autoWidth"
     [class.iconOnly]="iconOnly"
     [ngClass]="{
      '1' : 'one',
      '2' : 'two',
      '3' : 'three'}[showDefault ? 3 : displayCount]">
  <ng-container *ngFor="let type of ['health', 'sports', 'weight']">
    <div class="info" *ngIf="vitalityTypes[type]>=2 || showDefault">
      <media-card [media]="media[type] | async" [indicator]="false" [elevated]="false"></media-card>
      <div *ngIf="!iconOnly || showDefault" translate>{{'survey.vitality.type.'+type}}</div>
    </div>
  </ng-container>
</div>
</ng-container>
