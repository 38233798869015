<mat-selection-list class="mat-accent"
                    [multiple]="multiSelection"
                    [class.dense]="dense"
                    [class.multiple]="multiSelection"
                    (selectionChange)="onSelectionChanged($event)">
  <cdk-virtual-scroll-viewport itemSize="48" orientation="vertical">
    <ng-container *cdkVirtualFor="let entity of entities$;
                                  let index  = index;
                                  let first  = first;
                                  let last   = last;
                                  let even   = even;
                                  let odd    = odd;
                                  trackBy: trackTaskList;">
      <mat-list-option #option
                       *ngIf="safeTaskList(entity); let taskList"
                       [checkboxPosition]="'before'"
                       [selected]="isSelected(taskList.id)"
                       [value]="taskList.id"
                       [style.borderColor]="taskList.color ? '#'+taskList.color : undefined">
        <div class="container">
          <div *ngIf="taskList.title as title" class="task-list">
            <span [innerHTML]="(taskList.id <=0 && title?.startsWith('tasks.taskList.')
            ? (title  | translate)
            : title) |  safeHtml"></span>
          </div>
          <div class="controls" (click)="$event.stopPropagation()">
            <ng-container *ngTemplateOutlet="controlsTemplate; context: { index: index, taskList: taskList }"></ng-container>
          </div>
        </div>
        <mat-divider *ngIf="!last"></mat-divider>
      </mat-list-option>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</mat-selection-list>

