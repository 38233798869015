// AoT requires an exported function for factories
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader} from "@ngx-translate/core";
import {InjectionToken} from "@angular/core";
import {SessionTokenService} from "session";

export const PURE_TRANSLATE_LOADER = new InjectionToken<TranslateLoader>('PURE_TRANSLATE_LOADER');

export function createPureTranslateLoader(httpClient: HttpClient, appId: number, sessionTokenService: SessionTokenService) {
    return createTranslateLoader(httpClient, appId, sessionTokenService, '?pure=true');
}

export function createTranslateLoader(httpClient: HttpClient, appId: number, sessionTokenService: SessionTokenService, timezone = '') {
  const prefix = sessionTokenService.rewrite(`/v1.0/i18n/${appId}/`); // /i18n/${appId}/ - works!
  return new TranslateHttpLoader(httpClient, prefix, `${timezone ? `?timezone=${timezone}` : ''}`);
}

export const MISSING_TRANSLATE_HANDLER = new InjectionToken<(key: string) => string>('MISSING_TRANSLATE_HANDLER');

export function createMissingTranslationHandler(handler = (key: string) => key): MissingTranslationHandler {
  return {
    handle: (params: MissingTranslationHandlerParams) => {
      const translateService = params.translateService;
      const parser = translateService.parser;
      if (translateService.currentLang!=translateService.defaultLang) {
        return parser.interpolate(
          parser.getValue(translateService.translations[translateService.defaultLang], params.key),
          params.interpolateParams
        ) || handler(params.key);
      } else  {
        return handler(params.key);
      }
    }
  }
}
