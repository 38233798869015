<div #container
     (click)="$event.stopPropagation()"
     *ngLet="messagingService.open$ | async as online"
     (onDomModified)="onDomModified()"
     [ngSwitch]="state$ | async"
     [style.--layoutColumns]="columns">
  <!--
      buttons on no call:
          call(phone)  video(video)  ...
      buttons on this conversation audio call:
          hangup(red hangup phone)  pause(pause)  video(video crossed)....
      buttons on this conversation video call:
          hangup(red hangup phone)  pause(pause)  video(video)....
      buttons on other conversation call:
          call(phone)  video(video)  ...
  -->
  <ng-container *ngSwitchCase="'incoming'">
    <div>
      <button tabindex="-1" mat-icon-button class="accept" (click)="pickUp(conferenceInfo.conferenceId)">
        <mat-icon>call</mat-icon>
      </button>
      <span *ngIf="conferenceInfo.conferenceType=='audio'" translate>actions.accept</span>
      <span *ngIf="conferenceInfo.conferenceType=='video'" translate>actions.audio</span>
    </div>
    <div>
      <button tabindex="-1" mat-icon-button [class.accept]="conferenceInfo.conferenceType=='video'" (click)="pickUp(conferenceInfo.conferenceId,true,true)">
        <mat-icon>videocam</mat-icon>
      </button>
      <span translate>actions.video</span>
    </div>
    <div *ngIf="ringing">
      <button tabindex="-1" mat-icon-button class="decline" (click)="hangup(conferenceInfo.conferenceId)">
        <mat-icon>call_end</mat-icon>
      </button>
      <span translate>actions.decline</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'connected'">
    <div>
      <button tabindex="-1" mat-icon-button class="decline" (click)="hangup(conferenceInfo.conferenceId)">
        <mat-icon>call_end</mat-icon>
      </button>
      <span translate>actions.hangup</span>
    </div>
    <!--
    <div>
      <ng-container *ngIf="!isPaused(conferenceInfo); else paused">
        <button mat-icon-button (click)="pause(conferenceInfo.conferenceId)">
          <mat-icon>volume_up</mat-icon>
        </button>
        <span translate>actions.audio</span>
      </ng-container>
      <ng-template #paused>
        <button mat-icon-button (click)="continue(conferenceInfo.conferenceId)">
          <mat-icon>volume_off</mat-icon>
        </button>
        <span translate>actions.audio</span>
      </ng-template>
    </div>
    -->
    <div>
      <button tabindex="-1" mat-icon-button (click)="updateAudioVideo(conferenceInfo.conferenceId,!(conferenceInfo.participant.audio && !conferenceInfo.participant.muted),conferenceInfo.participant.video)">
        <mat-icon *ngIf="!!conferenceInfo.participant.audio && !conferenceInfo.participant.muted">mic</mat-icon>
        <mat-icon *ngIf="!conferenceInfo.participant.audio || !!conferenceInfo.participant.muted">mic_off</mat-icon>
      </button>
      <span translate>actions.audio</span>
    </div>
    <div>
      <button tabindex="-1" mat-icon-button (click)="updateAudioVideo(conferenceInfo.conferenceId,conferenceInfo.participant.audio && !conferenceInfo.participant.muted,!conferenceInfo.participant.video)">
        <mat-icon *ngIf="conferenceInfo.participant.video">videocam</mat-icon>
        <mat-icon *ngIf="!conferenceInfo.participant.video">videocam_off</mat-icon>
      </button>
      <span translate>actions.video</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div>
      <button tabindex="-1" [disabled]="!online" mat-icon-button (click)="startConference(false)">
        <mat-icon>call</mat-icon>
      </button>
      <span translate>actions.call</span>
    </div>
    <div>
      <button tabindex="-1" [disabled]="!online" mat-icon-button (click)="startConference(true)">
        <mat-icon>videocam</mat-icon>
      </button>
      <span translate>actions.video</span>
    </div>
  </ng-container>
  <ng-content></ng-content>
</div>
