<h2 *ngIf="label(survey).label as label" [style]="displayRoot ? undefined : { width: 0, height: 0, opacity: 0 }">
  <span>{{ label | translate}}</span>
  <button #rootHelpButton *ngIf="(help(survey) | async); let help" mat-icon-button class="help" (click)="showHelp(help, $event)">
    <mat-icon>help</mat-icon>
  </button>
</h2>
<div class="result" *ngIf="!!survey.properties?.result?.type">
  <app-survey-result
    [survey]="survey"
    [selectedTags]="selectedTags"
    [badgeData]="badgeData"
    [displayTag]="surveyTag"
    [readOnly]="readOnly"
    [defaultInfo]="defaultInfo"
    (onReady)="ready=$event"
    (onDisplay)="display=$event"
    [log]="log"></app-survey-result>
</div>
<ng-container *ngLet="this.userId==this.contactId as own">
  <ng-container *ngIf="{
      visible: isSurveyVisible(),
      own: own,
      readOnly: readOnly,
      evaluated: !readOnly && own && isEvaluated(),
      ready: !readOnly && own && isEvaluatedSurvey() && !isEvaluated()
    } as context">
<!--    {{context | json}}-->
    <ng-container *ngIf="context.visible">
      <div class="info" *ngIf="context.own"><span [innerHTML]="'survey.'+survey.id+'.help' | translate | safeHtml"></span></div>
      <div class="restart" *ngIf="context.evaluated">
        <button mat-raised-button color="primary" (click)="restart($event)">
          <span translate>actions.restart</span>
        </button>
        <!--
        <span>OHH<personality-colors [data]="{ red:60, yellow: 10, green: 12, blue: 80 }" [style.display]="'inline-block'"></personality-colors></span>
        -->
      </div>
      <ng-container [ngSwitch]="!!(sections$ | async).length">
        <ng-container *ngSwitchCase="true">
          <ng-container *ngFor="let topic of topics$ | async; let index = index; trackBy: trackByTopic">
            <ng-container *ngIf="selectedTopicTags(topic) as selectedTags" [ngSwitch]="topic.type">
              <ng-container *ngSwitchCase="'survey'">
                <app-survey [survey]="topic" [selectedTags]="selectedTags" [contactId]="contactId"></app-survey>
              </ng-container>
              <ng-container *ngSwitchCase="'section'"
                            [ngTemplateOutlet]="sectionTopicTemplate"
                            [ngTemplateOutletContext]="{ topic, selectedTags }">
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <section>
            <ng-container *ngSwitchDefault
                          [ngTemplateOutlet]="topicTemplate"
                          [ngTemplateOutletContext]="{ topic: survey, selectedTags: selectedTopicTags(survey) }">
            </ng-container>
          </section>
        </ng-container>
      </ng-container>
      <div class="evaluate" *ngIf="context.ready">
        <button mat-raised-button color="primary" [disabled]="!ready" (click)="evaluate($event)">
          <span translate>actions.evaluate</span>
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #sectionTopicTemplate let-topic="topic" let-selectedTags="selectedTags">
  <section>
    <h3 [style.backgroundColor]="backgroundColor(topic)" [style.color]="color(topic)">
<!--      <span [innerHTML]="'survey.'+survey.id+'.'+label(topic).label | translate | safeHtml"></span>-->
      <span [innerHTML]="label(topic).label | translate | safeHtml"></span>
      <button *ngIf="(help(topic) | async); let help" mat-icon-button class="help" (click)="showHelp(help, $event)">
        <mat-icon>help</mat-icon>
      </button>
    </h3>
    <ng-container *ngTemplateOutlet="topicTemplate; context: { topic, selectedTags }">
    </ng-container>
  </section>
</ng-template>
<ng-template #topicTemplate let-topic="topic" let-selectedTags="selectedTags">
  <topic-selection-list #topicSelectionList
                        [topics$]="normalizeTopics(topic)"
                        [readOnly]="readOnly || isEvaluated()"
                        [topicDisplayInfo]="topicDisplayInfo"
                        [class]="topic.type"
                        [selectedTags]="selectedTags"
                        (selectionChanged)="onSelectionChanged(topic, $event)">
    <ng-template #actionsTemplate let-topic="topic">
      <ng-container *ngTemplateOutlet="actionsTemplateRef; context: { survey, topic, tags: selectedTags }"></ng-container>
    </ng-template>
  </topic-selection-list>
</ng-template>
