/* projects/chat/src/lib/components/conversation/chat-conversation.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: column;
  position: relative;
}
virtual-section-scroller {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.section-header {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: transparent;
  padding: 4px 2px;
}
.section-header > span {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 1px 8px;
  font-size: 70%;
  color: var(--color-primary);
}
:host-context(.color-scheme-light.pure) .section-header > span {
  background-color: white;
  border: 1px solid #ddd;
  color: gray;
}
:host-context(.color-scheme-dark) .section-header > span {
  background-color: #111;
  border: 1px solid #444;
  color: #E5E5E5;
}
button.mat-mdc-mini-fab {
  visibility: hidden;
  transform: scale(0.1);
  position: absolute;
  bottom: 16px;
  right: 12px;
  transition: all 200ms ease-in-out;
}
button.mat-mdc-mini-fab.show {
  visibility: visible;
  transform: scale(1);
}
:host-context(.color-scheme-light.pure) {
  background-color: #f5f5f5;
}
:host-context(.color-scheme-dark) button.mat-mdc-mini-fab {
  background-color: #383838;
  color: #e5e5e5;
}
/*# sourceMappingURL=chat-conversation.component-CDCZ6D7H.css.map */
