import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "material";
import {SharedModule} from "shared";
import {PresenceService} from "./presence.service";
import {MomentModule} from "ngx-moment";
import {LayoutModule} from "layout";
import {SessionModule} from "session";
import {PresenceIndicatorComponent} from "./components/presence/presence-indicator.component";
import {PresenceComponent} from "./components/presence/presence.component";
import {PresenceInfoComponent} from "./components/presence/presence-info.component";
import {PresenceMenuComponent} from "./components/presence/presence-menu.component";
import {PresenceStoreModule} from "./store/module";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MaterialModule,
    SharedModule,
    MomentModule,
    LayoutModule,
    SessionModule,
    PresenceStoreModule
  ],
  exports: [
    PresenceIndicatorComponent,
    PresenceComponent,
    PresenceInfoComponent,
    PresenceMenuComponent
  ],
  declarations: [
    PresenceIndicatorComponent,
    PresenceComponent,
    PresenceInfoComponent,
    PresenceMenuComponent
  ],
  providers: [
    PresenceService
  ]
})
export class PresenceModule {}
