<ng-container *ngIf="task && {
                     scheduledYmdt: yearMonthDayTime(task.timeScheduled),
                     deadlineYmdt: yearMonthDayTime(task.timeDeadline),
                     formatDMH: ('tasks.formatDMH' | translate),
                     overdue: (overdue$ | async),
                     year: (year$ | async)
               } as context">
  <div class="details" (click)="select.emit({index, task})">
    <div>
      <h3 class="title"><span [innerHTML]="task.title | translate: task.properties | safeHtml"></span></h3>
      <!-- <h4 class="info"><span *ngIf="task.diffAfter!=undefined">a:{{task.diffAfter}}</span>&nbsp;<span *ngIf="task.diffBefore!=undefined">b:{{task.diffBefore}}</span>&nbsp;<span *ngIf="task.ordered">ORDERED!</span>&nbsp;<span class="info">{{task.info}}</span></h4> -->
      <h4 class="info" *ngIf="!!task.remark || !!task.info">
        <span *ngIf="!!task.remark" [innerHTML]="task.remark | translate: task.properties | safeHtml"></span>
        <span *ngIf="!!task.info">{{task.info}}</span>
      </h4>
    </div>
    <div *ngIf="task.timeScheduled" class="time">
      <mat-icon>schedule</mat-icon>
      <span>{{task.timeScheduled | date: context.formatDMH}}</span>
    </div>
    <div *ngIf="task.timeDeadline" class="time" [ngClass]="context.overdue ? 'overdue' : undefined">
      <mat-icon>pending_actions</mat-icon>
      <span>{{task.timeDeadline | date: context.formatDMH}}</span>
    </div>
  </div>
  <ng-container *ngFor="let target of taskTargets">
    <div *ngIf="target.type as type"
         [ngSwitch]="type"
         class="target"
         [ngClass]="type"
         (click)="select.emit({index, task, target})">
      <ng-container *ngSwitchCase="'contact'" >
        <img matListItemAvatar [src]="photoUrl(target.value) | sessionLink" onload="this.style.opacity=1"/>
        <presence [contactId]="target.value.id"></presence>
      </ng-container>
      <ng-container *ngSwitchCase="'media'">
        <media-icon [media]="target.media" [playable]="true"></media-icon>
        <!--
        <ng-container [ngSwitch]="target.media.mediaType=='survey'">
          <ng-container *ngSwitchCase="true">
            <ng-container *ngIf="surveyResults[target.media.id] as result" [ngSwitch]="result[0]?.id">
              <personality-colors *ngSwitchCase="'personality'"
                                  [colors]="result[1]"
                                  [class]="result[2]">
              </personality-colors>
              <vitality-images *ngSwitchCase="'vitality'"
                                  [survey]="result[0]"
                                  [vitalityTypes]="result[1]"
                                  [class]="result[2]"
                                  [iconOnly]="true">
              </vitality-images>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div *ngIf="media$(target.media) | async as media" class="media-card-container">
              <media-card [media]="media"
                          [elevated]="false"
                          [indicator]="false"
                          [fullCover]="true"
                          [imageMaxWidth]="48">
              </media-card>
              <ng-container *ngLet="mediaService.getMediaPlayedBadgeInfo(media) as badge">
                <div *ngIf="!!badge && !mediaService.isFolderMedia(media)"
                     class="badge played {{badge.class}}"
                     [ngSwitch]="badge.icon">
                  <mat-icon *ngSwitchCase="'eye'">visibility</mat-icon>
                  <ng-container *ngSwitchDefault>
                    <mat-icon *ngIf="badge.icon">{{badge.icon}}</mat-icon>
                  </ng-container>
                  <span *ngIf="badge.label" translate>{{badge.label}}</span>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        -->
      </ng-container>
      <ng-container *ngSwitchCase="'message'">
        <mat-icon>message</mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'action'" >
        <mat-icon>link</mat-icon>
      </ng-container>
      <ng-container *ngSwitchDefault></ng-container>
    </div>
  </ng-container>
</ng-container>
<!--<ng-template #targetContainer></ng-template>-->
