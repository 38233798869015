import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter, HostBinding,
  Inject,
  Injector,
  INJECTOR,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {BasicContainerComponent} from "shared";
import {AttachmentComponent} from "./attachment.component";
import {Attachment} from "messaging";
import {Space} from "./space";
import {
  AttachmentComponentFactory,
  AttachmentComponentFactoryService
} from "../attachment.component.factory.service";
import {ChatTimelineMessage} from "../../../store/models";

@Component({
  selector: 'attachment-square-container',
  template: '<div #square></div>',
  styles: [`
    :host>div {
      width: 100%;
    }
    :host {
      display: none;
    }
    :host.visible {
      display: unset;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentSquareContainer extends BasicContainerComponent implements AttachmentComponent {
  @HostBinding('class.visible') visible = false;
  @ViewChild('square', {read: ViewContainerRef, static: true}) square: ViewContainerRef;

  protected _message: ChatTimelineMessage = undefined;
  protected _attachments: Attachment[] = [];

  factory: AttachmentComponentFactory = undefined;
  component: ComponentRef<AttachmentComponent> = undefined;

  constructor(public elementRef: ElementRef,
              public attachmentService: AttachmentComponentFactoryService,
              public resolver: ComponentFactoryResolver,
              @Inject(INJECTOR) public injector:Injector) {
    super();
  }

  @Output()
  get updated():EventEmitter<AttachmentComponent> {
    return undefined;
  }

  @Output()
  get deleted():EventEmitter<Attachment> {
    return undefined;
  }

  @Input()
  set message(message:ChatTimelineMessage) {
    this._message = message;
    if (!!this.component?.instance) {
      this.component.instance.message = message;
    }
  }
  get message():ChatTimelineMessage {
    return this._message;
  }

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    this._attachments = attachments ?? [];
    let foundFactory:AttachmentComponentFactory = undefined;
    let foundAttachment:Attachment = undefined;
    this.attachments.forEach((attachment,index) => {
      if (!foundFactory) {
        foundFactory = this.attachmentService.getSquareFactory(attachment.type, attachment);
        if (!!foundFactory) {
          foundAttachment = attachment;
        }
      }
    });
    if (!!this.factory && this.factory!=foundFactory) {
      this.square.remove(0);
      this.component = undefined;
      this.factory = undefined;
    }
    if (!!foundFactory && this.factory!=foundFactory) {
      this.factory   = foundFactory;
      this.component = foundFactory.createComponent(this.injector,this.resolver);
      this.square.insert(this.component.hostView,0);
    }
    if (!!this.component) {
      const attachments = this.component.instance.attachments;
      if (attachments?.length!=1 || attachments[0]!=foundAttachment) {
        this.component.instance.message     = this.message;
        this.component.instance.attachments = [foundAttachment];
      }
    }
    this.visible = !!this.component;
  }

  get attachments(): Attachment[] {
    return this._attachments;
  }

  get space():Space|undefined {
    return this.component?.instance?.space;
  }
}
