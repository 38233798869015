import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AgmMap, AgmMarker, MapsAPILoader} from "@agm/core";
import {ModifiedEvent} from "shared";
import {Logger} from "core";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

declare var google;

export type Marker = { [P in keyof AgmMarker]?: AgmMarker[P]  };

// interface Marker {
//   lat: number;
//   lng: number;
//   label?: string;
//   draggable?: boolean;
//   icon?: any;
// }

const mapOptions = {
  zoom: 17,
  mapTypeId: 'hybrid',  // HYBRID ROADMAP SATELLITE TERRAIN
  zoomOptions: {}
};

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  // providers: [
  //   MarkerManager,
  //   GoogleMapsAPIWrapper
  // ]
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MapComponent),
      multi: true
    }
  ]
})
export class MapComponent implements OnInit, ControlValueAccessor {

  @Input() latitude: number;
  @Input() longitude: number;
  @Input() disabled = false;
  @Input() exclusive = true;
  @Input() autoCenter = true;

  protected subscription: Subscription;
  markers$ = new BehaviorSubject<Marker[]>([]);
  @Input() set markers(markers: Observable<Marker[]> | Marker[]) {
    this.subscription?.unsubscribe();
    const markers$ = Array.isArray(markers) ? of(markers) : markers;
    this.subscription = markers$.subscribe((markers) => {
      this.markers$.next(markers);
      if (markers.length==1) {
        const marker = markers[0];
        (this.autoCenter || !this.latitude)  && (this.latitude  = markers[0].latitude);
        (this.autoCenter || !this.longitude) && (this.longitude = markers[0].longitude);
        // window.setTimeout(() => {
        //   const center = this.map.getCenter();
        //   if (center.lat()!=this.latitude || center.lng()!=this.longitude) {
        //     this.map.setCenter({ lat: this.latitude, lng: this.longitude})
        //   }
        // });
      }
    });
  };

  get markers(): Observable<Marker[]> | Marker[] {
    return this.markers$.getValue();
  };

  options = mapOptions;
  googleLinkElement: HTMLAnchorElement;

  // @ViewChild(AgmMap, { static: true }) map: AgmMap;
  protected map: AgmMap;
  protected onChangeFunction = (value: Marker[]) : void => {};
  protected logger = new Logger('MapComponent');

  constructor(protected mapsApiLoader: MapsAPILoader) {
    //console.log("MAP.ctor");
  }

  ngOnInit() {
    this.mapsApiLoader.load().then(() => {
      this.options.zoomOptions = {
        position: google.maps.ControlPosition.TOP_RIGHT,
        style: google.maps.ZoomControlStyle.SMALL
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onMapReady(map: AgmMap) {
    //console.log("MAP.onMapReady", map, map.mapReady, map.mapTypeId);
    this.map = map;
  }

  onDomModified(event: ModifiedEvent) {
    // ATTENTION: google link href is updated when the map is moved
    if (!this.googleLinkElement && event.element) {
      let anchors = event.element.getElementsByTagName('a');
      if (anchors.length) {
        for (let i = 0; i < anchors.length; i++) {
          const anchor = anchors[i];
          if (anchor.href.indexOf('maps.google.com/maps?') !== -1) {
            this.googleLinkElement = anchor;
            event.element = anchor;
            break;
          }
        }
      }
    }
    if (this.googleLinkElement) {
      // https://stackoverflow.com/questions/38241685/how-to-add-marker-to-google-logo-destination
      const urlSplitExpression = new RegExp(/[?&]/);
      let   urlParts   = this.googleLinkElement.href.split(urlSplitExpression);
      let   url        = urlParts[0];
      urlParts.splice(0,1);
      let   pointIndex = urlParts.findIndex(part => part.startsWith("q="));
      if (pointIndex>=0) {
        urlParts.splice(pointIndex,1);
      }
      urlParts.push(`q=${this.latitude},${this.longitude}`);
      url = url+'?'+urlParts.join('&');
      //console.log("url",url);
      // https://www.google.com/maps?q=48.20767,13.49531&z=17&t=h&hl=de-DE&gl=US&mapclient=apiv3
      const href = url;
      if (href!=this.googleLinkElement.href) {
        this.googleLinkElement.href = href;
      }
    }
    // this.logger.debug("onModified", event, this.googleLinkElement);
  }

  onMapClick(event: any) {
    this.logger.debug('onClick', event);
    if (!this.disabled && event?.coords) {
      const marker: Marker = { latitude: event.coords.lat, longitude: event.coords.lng };
      this.logger.debug('marker', marker);
      const markers = [...(this.exclusive ? [] : this.markers$.getValue()), marker];
      this.markers$.next(markers);
      this.onChange();
    }
  }

  writeValue(markers: Marker[]): void {
    this.markers = markers || [];
  }

  onChange(): void {
    this.onChangeFunction(this.markers$.getValue());
  }

  registerOnChange(fn: (markers: Marker[]) => void): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: () => void): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
