import {Injectable} from "@angular/core";
import {ActionsSubject, select, Store} from "@ngrx/store";
import * as fromChat from "./store/state";
import {transientMessageTypes} from "./store/state";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  firstValueFrom,
  groupBy,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  scan,
  Subject,
  Subscription,
  timer
} from "rxjs";
import {
  AudioRecordingData,
  CallStatusMessageType,
  ChatInfoMessageType,
  ChatMessageType,
  ChatNoteMessageType,
  ChatParticipationsAddMessageType,
  ChatParticipationsChangeMessageType,
  ChatParticipationsRemoveMessageType,
  ChatTimelineMessage,
  ConversationData,
  ConversationDefinition,
  ConversationFilter,
  ConversationHeader,
  ConversationMuteMessage,
  ConversationMuteMessageType,
  ConversationSegmentData,
  ConversationSegmentHeader,
  ConversationType,
  CreatedMessageType,
  CreateMessageType,
  DeliveryMessage,
  DeliveryMessageType,
  DeliveryParticipant,
  MessageTimeUpdated,
  PrivateMessageTypes,
  TypingMessage,
  TypingMessageType,
  VisibilityMessage,
  VisibilityMessageType,
} from "./store/models";
import {AuthenticationService} from "auth";
import {Attachment, MessageEnvelope, MessageHandlerRegistry, MessagingService, Participant} from "messaging";
import {filter, finalize, first, map, switchMap, tap, toArray} from "rxjs/operators";
import moment from "moment";
import {
  addQueueMessagesAction,
  attachmentPayloadRequestAction,
  channelOpenStateAction,
  conversationCreateOrUpdateAction,
  conversationMuteAction,
  conversationsLoadRequestAction,
  conversationSynchronizeSegmentAction,
  conversationUpdateFiltersAndTermAction,
  conversationUpdateSegmentAction,
  receiveChatTimelineMessageAction,
  receiveDeliveryMessageAction,
  removeQueueMessagesAction,
  sendChatTimelineMessageAction,
  setCurrentConversationIdAction,
  setDraftMessageAction,
  setParticipantAction,
  setReplyMessageAction,
  setTypingMessageAction,
  showErrorAction,
  triggerTimeViewedAction
} from "./store/actions";
import {
  AsyncStorage,
  AsyncStorageFactory,
  createIndexedArrayProxy,
  EMPTY_ARRAY,
  IndexedArrayHooks,
  loaded$,
  SimpleFilterService,
  VersionedId
} from "core";
import {ChatSounds} from "./chat.sounds";
import {isValidNumber, MAX_JAVA_INTEGER, PageVisibilityService} from "shared";
import {FilteredListView, StoreService} from "store";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {PropertiesService} from "properties";
import isEqual from "lodash/isEqual";
import {SynchronizationService} from "synchronization";
import {Task} from "tasks";
import {DEFAULT_SEGMENT_SIZE, ListViewState} from "synchronization/lib/store/state";

export abstract class FilteredList<X> extends Subscription {
  abstract get list$(): Observable<X[]>;
  abstract get sections$(): Observable<number[]>;
  abstract updateFilters(filters : string[]):void;
  abstract updateSearchTerm(term : string | undefined):void;
  abstract unfiltered():void;
}

export interface ConversationConnection {
  conversationId:string;
  subscribed:number;
  loaded: boolean;
  hooks:IndexedArrayHooks<ChatTimelineMessage>;
  synchronizationSubscription?:Subscription;
}

@Injectable(
  {providedIn: 'root'}
)
export class ChatService extends StoreService implements SimpleFilterService {

  protected _sounds:ChatSounds = new ChatSounds();

  public get sounds():ChatSounds {
    return this._sounds;
  }

  protected _currentConversationId$ = new BehaviorSubject<string>(undefined);

  protected messageHandlerRegistry = new MessageHandlerRegistry();
  protected typingState:{[key:string]:TypingMessage} = {};
  protected conversations:{[key:string]:ConversationConnection} = {};

  protected syncStore:AsyncStorage;
  protected sendStore:AsyncStorage;
  //protected syncStore:database.UseStore = database.createStore('sync', 'key_segments');
  //protected sendStore:database.UseStore = database.createStore('send', 'key_messages');
  protected sendQueue$ = new BehaviorSubject<ChatTimelineMessage[]>([]);
  protected sendQueueMessages = new Map<string,ChatTimelineMessage>();
  protected sendQueueSubscription:Subscription = undefined;
  protected sendDeliveryStatus$ = new Subject<{conversationId:string,timeViewed?:number,timeReceived?:number}>();
  protected sendTimeReceived = new Map<string,number>();

  filters$: Observable<string[]> = of([]);

  constructor(protected store$ : Store<fromChat.State>,
              protected action$: ActionsSubject,
              protected authenticationService: AuthenticationService,
              protected propertiesService: PropertiesService,
              protected synchronizationService: SynchronizationService,
              protected pageVisibilityService: PageVisibilityService,
              protected router: Router,
              protected http: HttpClient,
              protected storageFactory: AsyncStorageFactory,
              protected messagingService: MessagingService) {
    super(store$,action$);
    //console.trace('ChatService.ctor');
    this.syncStore = this.storageFactory.request('sync', 'key_segments');
    this.sendStore = this.storageFactory.request('send', 'key_messages');
    /**
     * update store with open state and if open, send all pending messages...
     */
    combineLatest([
      this.messagingService.open$,
      this.authenticationService.user$,
      this.propertiesService.groupId$
    ]).subscribe(([open,user,groupId]) => {
      //console.log("OPENSTATE",open);
      this.dispatch(channelOpenStateAction({open:open}))
        .then(()=>{
          if (!!user.id) {
            this.sendQueueSubscription?.unsubscribe();
            this.sendQueueSubscription = undefined;
            if (!!open) {
              this.sendQueueSubscription = this.sendQueue$.pipe().subscribe(queue => {
                this.sendQueueMessages.clear();
                if (queue.length>0 && this.messagingService.isOpen()) {
                  queue.forEach(message => {
                    this.sendQueueMessages.set(message.id,message);
                    this.store$.dispatch(sendChatTimelineMessageAction({
                      message:message,
                      callback:(done)=>{
                        if (!done) {  // could not be sent.... try again....
                          this.sendQueueMessages.delete(message.id);
                        }
                      }}));
                  });
                }
              });
              //let triggerTime = 0;
              this.sendQueueSubscription.add(this.sendDeliveryStatus$.asObservable()
                .pipe(
                  groupBy(event => event.conversationId),
                  mergeMap(group$ =>
                    group$.pipe(
                      //tap(status => triggerTime = Date.now()),
                      scan((acc, curr) => ({
                        conversationId: curr.conversationId,
                        timeViewed: isValidNumber(curr.timeViewed) ? Math.max(acc.timeViewed ?? -Infinity, curr.timeViewed) : acc.timeViewed,
                        timeReceived: isValidNumber(curr.timeReceived) ? Math.max(acc.timeReceived ?? -Infinity, curr.timeReceived) : acc.timeReceived
                      }), { conversationId:undefined, timeViewed: undefined, timeReceived: undefined }),
                      filter(event => event.conversationId?.length>0 && (event.timeViewed !== undefined || event.timeReceived !== undefined)), // Ensure we don't emit initial
                      debounceTime(200)
                    )
                  )
                ).subscribe(status => {
                  //console.log("xx:sendDeliveryMessage",status,"triggered",Date.now()-triggerTime);
                  this.messagingService.sendMessage(this.messagingService.initializeMessage(<DeliveryMessage>{
                    type: DeliveryMessageType,
                    from: <DeliveryParticipant>{ id:this.userId, timeViewed:status.timeViewed, timeReceived:status.timeReceived },
                    conversationId: status.conversationId
                  }));
                }));
              this.load();
            }
          }
        });
    });
    // every minute try to send pending messages again if connection is open...
    timer(60_000,60_000).subscribe(()=> {
      if (this.messagingService.isOpen() && this.sendQueue$.value.length>0) {
        this.sendQueue$.next(this.sendQueue$.value);
      }
    });
    this.store$.pipe(select(fromChat.selectConversationsQueue))
      .subscribe(messages => {
        this.sendQueue$.next(messages)
      });
    /**
     * handle time received...
     */
    const handleTimeReceived = (conversationId:string)=>{
      firstValueFrom(this.store$.select(fromChat.selectConversationState(conversationId))).then(state=>{
        if (state?.newestIncomingMessageVersion > (this.sendTimeReceived.get(conversationId)??0)) {
          this.sendTimeReceived.set(conversationId,state.newestIncomingMessageVersion);
          this.triggerTimeReceived(conversationId,state.newestIncomingMessageVersion);
        }
      })
    };
    this.action$.pipe(
      filter(dispatchedAction => dispatchedAction.type==conversationUpdateSegmentAction.type),
      map(dispatchedAction => (<ConversationDefinition>(<any>dispatchedAction).conversation).id)
    ).subscribe(handleTimeReceived);
    this.action$.pipe(
      filter(dispatchedAction => dispatchedAction.type==receiveChatTimelineMessageAction.type),
      map(dispatchedAction => (<ChatTimelineMessage>(<any>dispatchedAction).message).conversationId)
    ).subscribe(handleTimeReceived);
    /**
     * handle all messages
     */
    let handleChatTimelineMessages = (envelope:MessageEnvelope):void => {
      //console.log("handleChatTimelineMessages",envelope);
      let message = <ChatTimelineMessage>envelope.message;
      //if (message?.conversationId=='direct.1679741802076687.3-TFNESWQHQDTKFXQYNJSUBEM6E') {
      //  console.log("XY.handleChatTimelineMessages",message);
      //}
      //console.log("receiveMessage",message);
      let error   = (<any>envelope.headers).error;
      if (message?.draft) {
        //console.log("handleChatTimelineMessages.draft",message);
        this.setDraftMessage(message.conversationId,!!message.timeDeleted ? undefined : message,false);
      } else {
        //console.log("ELSE.IF",message?.from?.name,message);
        // console.log("handleChatTimelineMessages",message?.from?.name,message);
        const process = () => {
          if (!!error) {
            //console.log("DELIVERY.QUEUE.error",message,error);
            this.store$.dispatch(showErrorAction({errorLabel:error}));
          } else {
            //console.log("DELIVERY.QUEUE.dispatch",message);
            this.store$.dispatch(receiveChatTimelineMessageAction({
              message: message,
              headers: { conversation:(<any>envelope.headers).conversation },
              hooks: this.ensureSynchronizedConversationInfo(message.conversationId).hooks,
              dispatch: action => this.store$.dispatch(action)
            }));
          }
        };
        if (this.sendQueueMessages.has(message.id)) {
          //console.log("DELIVERY.QUEUE.remove",message);
          Promise.all([
            this.dispatch(removeQueueMessagesAction({messages:[message]})),
            this.sendStore.remove(message.id)
            //database.del(message.id,this.sendStore)
          ]).catch(error=>console.log("DELIVERY.QUEUE.delete.error",message,error))
            .finally(process);
        } else {
          process();
        }
      }
    };
    let handleTypingMessages = (envelope:MessageEnvelope):void => {
      //console.trace("handleTypingMessages",envelope);
      let typingMessage = <TypingMessage>envelope.message;
      if (!!typingMessage && !!typingMessage.from?.id && !!typingMessage.from.name && !!typingMessage.conversationId) {
        let contactId      = typingMessage.from.id;
        let userId         = this.authenticationService.user?.id;
        let conversationId = typingMessage.conversationId;
        let typingStateKey = conversationId+'#'+contactId;
        if (contactId!=userId) {
          //console.log("deleteTypingMessages",typingMessage);
          this.store$.dispatch(setTypingMessageAction({ typingMessage, conversationId }));
          // remove typing message after 4 seconds. if no new typing message came
          // during this time, the typing indicator/message is removed....
          if (typingMessage.typing) {
            this.typingState[typingStateKey] = typingMessage;
            window.setTimeout(()=>{
              //console.log("deleteTypingMessages",typingMessage);
              if (this.typingState[typingStateKey]===typingMessage) {
                typingMessage.typing = false;
                this.store$.dispatch(setTypingMessageAction({ typingMessage, conversationId }));
              }
            },4_000);
          } else {
            delete this.typingState[typingStateKey];
          }
        }
      }
    };
    let handleMuteMessages = (envelope:MessageEnvelope):void => {
      let message = <ConversationMuteMessage>envelope.message;
      this.store$.dispatch(conversationMuteAction({ conversationId: message.conversationId, timeMuted: message.timeMuted }));
    };
    let handleDeliveryMessages = (envelope:MessageEnvelope):void => {
      let message = <DeliveryMessage>envelope.message;
      if (!!message?.conversationId && (!!message.timeReceived || !!message.timeViewed)) {
        //console.log("xx:handleDeliveryMessage",message,message.from?.id,message.from?.name,"\nconversation",message.conversationId,"timeReceived",message.timeReceived,"timeViewed",message.timeViewed);
        this.store$.dispatch(receiveDeliveryMessageAction({ message }));
      }
    };
    // chat...
    this.messageHandlerRegistry.addMessageHandler(ConversationMuteMessageType,handleMuteMessages);
    this.messageHandlerRegistry.addMessageHandler(TypingMessageType,handleTypingMessages);
    this.messageHandlerRegistry.addMessageHandler(CreateMessageType,handleChatTimelineMessages);
    this.messageHandlerRegistry.addMessageHandler(CreatedMessageType,handleChatTimelineMessages);
    this.messageHandlerRegistry.addMessageHandler(ChatParticipationsAddMessageType,handleChatTimelineMessages);
    this.messageHandlerRegistry.addMessageHandler(ChatParticipationsRemoveMessageType,handleChatTimelineMessages);
    this.messageHandlerRegistry.addMessageHandler(ChatParticipationsChangeMessageType,handleChatTimelineMessages);
    this.messageHandlerRegistry.addMessageHandler(ChatMessageType,handleChatTimelineMessages);
    this.messageHandlerRegistry.addMessageHandler(ChatNoteMessageType,handleChatTimelineMessages);
    this.messageHandlerRegistry.addMessageHandler(ChatInfoMessageType,handleChatTimelineMessages);
    this.messageHandlerRegistry.addMessageHandler(CallStatusMessageType,handleChatTimelineMessages);
    this.messageHandlerRegistry.addMessageHandler(DeliveryMessageType,handleDeliveryMessages);
    this.messagingService
      .register(envelope => this.messageHandlerRegistry.hasMessageHandler(envelope.message?.type))
      .subscribe(envelope => {
        //if ('direct.1679741802076687.3-4BZWHXJZRADZ2HDXBPHNYRHW2'==envelope.message.conversationId) {
        //  console.log("XY.incoming1.envelope",envelope);
        //} else {
        //  console.log("XY.incoming2.envelope",envelope);
        //}
        //console.log("ENVELOPE.in",envelope);
        this.messageHandlerRegistry.getMessageHandler(envelope.message.type)(envelope);
      });
    /**
     * foreground/background, browser minimized, tab (not) visible, etc...
     */
    let isVisibile:boolean     = true;
    let isConversation:boolean = undefined;
    let conversationId:string  = undefined;
    let visibilityTrigger:number;
    const onPageVisible = (source:string,visible:boolean)=>{
      const chat = this.router.url?.startsWith('/chat/');
      if (isVisibile!=visible ||
          isConversation!=chat ||
          conversationId!=this.currentConversationId) {
        isVisibile     = visible;
        isConversation = chat;
        conversationId = this.currentConversationId;
        //console.log("visibility.source",source,"visible",visible,"chat",chat,"id",conversationId);
        if (isValidNumber(visibilityTrigger)) {
          window.clearTimeout(visibilityTrigger);
        }
        visibilityTrigger = window.setTimeout(()=>{
          this.messagingService.sendMessage(this.messagingService.initializeMessage(<VisibilityMessage>{
            type: VisibilityMessageType,
            from: <Participant>{ id:this.userId },
            visible: visible,
            conversationId: isConversation ? conversationId : undefined
          }));
        },300)
      }
    };
    document.addEventListener('resume',()=>onPageVisible('resume',true),false);
    document.addEventListener('pause',()=>onPageVisible('pause',false),false);
    this.pageVisibilityService.$onPageVisible.subscribe(()=>onPageVisible('pageVisible',true));
    this.pageVisibilityService.$onPageHidden.subscribe(()=>onPageVisible('pageHidden',false));
    this.pageVisibilityService.$onPageUnloaded.subscribe(()=>onPageVisible('pageUnloaded',false));
    this.store$.pipe(select(fromChat.selectCurrentConversationId))
      .subscribe(conversationId => {
        this._currentConversationId$.next(conversationId);
        onPageVisible('conversationId',isVisibile);
      });
    this.router.events
      .subscribe(e=>onPageVisible('route',isVisibile));
    /**
     * add queue messages stored in database...
     */
    //database.values(this.sendStore)
    this.sendStore.values()
      .then((items:ChatTimelineMessage[]) => {
        let sorted = (items||[]).sort((a,b)=> {
          let cmp = a.timeCreated - b.timeCreated;
          return cmp!=0 ? cmp : a.id.localeCompare(b.id);
        });
        //console.log("LOADED",items,"SORTED",sorted);
        this.store$.dispatch(addQueueMessagesAction({messages:sorted}))
      });
    /**
     * handle user change (logout/login)...
     */
    this.authenticationService.user$.subscribe(user => {
      firstValueFrom(this.store$.select(fromChat.selectChatState))
        .then(state=>{
          if (state.participantId!=user?.id) {
            this.dispatch(setParticipantAction({participantId:user?.id}))
              .then(()=>{
                //console.log("PARTICIPANT.old",state.participantId,"new",user?.id);
                this.sendTimeReceived.clear();
                this.typingState = {};
                this.conversations = {};
                this.sendQueue$.next([]);
                this._currentConversationId$.next(undefined);
                if (!user?.id || !!state.participantId) {
                  console.log("PARTICIPANT.clearDatabase");
                  this.syncStore.clear();
                  this.sendStore.clear();
                  //database.clear(this.syncStore);
                  //database.clear(this.sendStore);
                }
                if (!!user?.id) {
                  this.load();
                }
              });
          }
        });
    });
  }

  protected _lastLoad:number = 0;
  protected _loadedId:string = undefined;
  load() {
    //console.log("CHAT.LOAD",this.propertiesService.user?.id);
    //console.log("LOAD");
    let self  = this;
    firstValueFrom(self.store$.select(fromChat.selectOnlineState)
      .pipe(filter(open=>open)))
      .then(online => {
        const now = Date.now();
        const userId = this.propertiesService.user?.id;
        //console.log("CHAT.LOAD.TRIGGER",(this._lastLoad<now-1_000 || this._loadedId!=userId),userId,this.propertiesService.user);
        if (this._lastLoad<now-1_000 || this._loadedId!=userId) {
          this._loadedId = userId;
          this._lastLoad = now;
          firstValueFrom(self.store$.select(fromChat.selectConversationsState))
            .then(state => {
              //console.log("ChatService.load()");
              let filter:ConversationFilter = state.filters?.length>0 || state.term?.length>0 ? {
                id: state.filterId,
                filters: state.filters || [],
                term: state.term || ''
              } : undefined;
              //console.log("CHAT.LOAD.DISPATCH",userId,this.propertiesService.user);
              self.store$.dispatch(conversationsLoadRequestAction({filter}));
            });
        }
      })
  }

  triggerTimeViewed(conversationId:string,timeViewed:number) {
    //console.log("xx:triggerDeliveryMessage.timeViewed.1",timeViewed,conversationId);
    this.dispatch(triggerTimeViewedAction({conversationId,timeViewed,send:false}))
      .then(action=> {
        if (action.send) {
          //console.log("xx:triggerDeliveryMessage.timeViewed.2",timeViewed,conversationId);
          this.sendDeliveryStatus$.next({conversationId,timeViewed});
        }
      });
  }

  //protected triggerTimeReceivedInfos = new Map<string,{timer:number,timeReceived:number}>();
  triggerTimeReceived(conversationId:string,timeReceived:number) {
    //console.log("xx:triggerDeliveryMessage.timeReceived.1",timeReceived,conversationId);
    this.store$.select(fromChat.selectConversationState(conversationId))
      .pipe(first())
      .subscribe(state=> {
        if ((state.conversationData?.timeSelfReceived ?? 0) < timeReceived) {
          //console.log("xx:triggerDeliveryMessage.timeReceived.2",timeReceived,conversationId);
          this.sendDeliveryStatus$.next({conversationId,timeReceived});
        }
      });
  }

  getDirectContactId(conversationId:string): string {
    let parts = conversationId?.split('\.');  // split with dot...
    if (parts?.length==3) {
      let thisId = this.authenticationService.user?.id;
      return parts[1]==thisId ? parts[2] : parts[1];
    }
    return undefined;
  }

  getDirectConversationId(targetContactId:string,sourceContactId?:string): string {
    if (!!targetContactId) {
      sourceContactId = sourceContactId || this.userId;
      if (sourceContactId<targetContactId) {
        return `direct.${sourceContactId}.${targetContactId}`;
      } else {
        return `direct.${targetContactId}.${sourceContactId}`;
      }
    }
    return undefined;
  }

  getDirectConversationPartnerId(conversationId:string): string|undefined {
    if (conversationId?.startsWith('direct.')) {
      let parts = conversationId?.split('\.');  // split with dot...
      if (parts?.length==3) {
        let thisId = this.authenticationService.user?.id;
        if (parts[1]==thisId) {
          return parts[2];
        } else if (parts[2]==thisId) {
          return parts[1];
        }
      }
    }
    return undefined;
  }


  get userId(): string {
    return this.authenticationService.user?.id;
  }

  getConversations(): FilteredList<ConversationData> {
    let self = this;
    return new class extends FilteredList<ConversationData> {
      get list$(): Observable<ConversationData[]> {
        return self.store$.pipe(
          select(fromChat.selectConversations),
          debounceTime(200),
          map(conversations=>conversations.filter(conversation=>!conversation.temporary)))
      }
      get sections$(): Observable<number[]> {
        return of(<number[]>[]);
      }
      unfiltered(): void {
        self.store$.dispatch(conversationsLoadRequestAction({}));
      }
      updateFilters(filters: string[]): void {
        self.store$.pipe(
          select(fromChat.selectConversationsState),
          first()).subscribe(state => {
            if (!isEqual(state.filters, filters||[])) {
              self.store$.dispatch(conversationsLoadRequestAction({
                filter: {
                  filters:filters||[],
                  term:state.term
                }
              }));
            }
          });
      }
      updateSearchTerm(term: string | undefined): void {
        self.store$.pipe(
          select(fromChat.selectConversationsState),
          first()).subscribe(state => {
            if (!isEqual(state.term, term||'')) {
              self.store$.dispatch(conversationsLoadRequestAction({
                filter: {
                  filters:state.filters,
                  term:term||''
                }
              }));
            }
        });
      }
      unsubscribe(): void {
        super.unsubscribe();
      }
    };
  }

  /*
  adjustCurrentConversationId$(): Observable<string|undefined> {
    let conversationId = this.currentConversationId;
    return this.store$.pipe(select(fromChat.selectConversations)).pipe(
      map(list => <ConversationData[]>((<any>list).backingArray || list)),
      map(list => !list.length ? undefined : !conversationId ? list[0].id : list.find(entry=>entry.id==conversationId)?.id || list[0].id),
      switchMap(id => {
        return id==conversationId ? of(id) : this.setCurrentConversationId(id)
      })
    );
  }*/

  get currentConversationId$(): Observable<string|undefined> {
    return this._currentConversationId$;
  }

  get currentConversationId(): string | undefined {
    return this._currentConversationId$.value;
  }

  setCurrentConversationId(id: string | undefined):Promise<string> {
    //console.log("setCurrentConversationId(...)",id);
    //console.trace("set currentConversationId",id,"is",this._currentConversationId$.value);
    if (this._currentConversationId$.value != id) {
      //console.log("setCurrentConversationId(...):START",id);
      return new Promise((resolve,reject)=>{
        firstValueFrom(this.store$.select(fromChat.selectConversations))
          .then(conversations=>{
            if (conversations?.find(conversation=>conversation.id==id)) {
              //console.log("setCurrentConversationId(...):FOUND","conversations",conversations);
              this.dispatch(setCurrentConversationIdAction({ conversationId:id }))
                .then(action=>resolve(id))
                .catch(reject);
            } else {
              const partnerId = this.getDirectConversationPartnerId(id);
              if (!!partnerId) {
                //console.log("setCurrentConversationId(...):CREATE",id,"partnerId",partnerId,"conversations",conversations);
                this.createOrUpdateDirectConversation(partnerId)
                  .then(conversationId=>{
                    //console.log("setCurrentConversationId(...):DONE",id,"partnerId",partnerId,"conversations",conversations);
                    this.dispatch(setCurrentConversationIdAction({ conversationId:id }))
                      .then(action=>resolve(id))
                      .catch(reject);
                  })
                  .catch(reject);
              } else {
                // we cannot switch as it does not exist...
                //console.log("setCurrentConversationId(...):KEEP",this._currentConversationId$.value,"id",id,"conversations",conversations);
                resolve(this._currentConversationId$.value);
              }
            }
            if (conversations.length==0) {
              this.load();
            }
          })
          .catch(reject);
      });
    }
    return firstValueFrom(this._currentConversationId$);
  }

  setDraftMessage(conversationId:string,draftMessage:ChatTimelineMessage,publish=true): Promise<void> {
    //console.trace("setDraftMessage.conversation.",conversationId,draftMessage,"type",draftMessage?.type);
    return firstValueFrom(this.getDraftMessage$(conversationId))
      .then(prevMessage => {
        if (!!draftMessage) {
          draftMessage.persistent = false;
          draftMessage.draft = true;
          draftMessage.private = PrivateMessageTypes.has(draftMessage.type);
        }
        this.store$.dispatch(setDraftMessageAction({ conversationId, draftMessage }));
        //console.log("draftMessage",draftMessage,"publish",publish);
        let promise: Promise<MessageEnvelope>;
        if (publish && !!draftMessage) {
          //console.log("draftMessage.send.curr",draftMessage);
          promise = this.messagingService.sendMessage(draftMessage);
        } else if (publish && !!prevMessage) {
          //console.log("draftMessage.send.prev",{...prevMessage, persistent:false, draft:true, timeDeleted:Date.now() });
          promise = this.messagingService.sendMessage(<ChatTimelineMessage>{...prevMessage, persistent:false, draft:true, timeDeleted:Date.now() });
        }
        return promise ? promise.then(() => void 0) : Promise.resolve()
      });
  }

  getDraftMessage$(conversationId:string): Observable<ChatTimelineMessage> {
    return this.store$.select(fromChat.selectConversationDraftMessage(conversationId));
  }

  setReplyMessage(conversationId:string,replyMessage:ChatTimelineMessage) {
    //console.log("setReplyMessage.conversation.",conversationId,replyMessage);
    firstValueFrom(this.getConversationData$(conversationId))
      .then(conversationData => {
        this.dispatch(setReplyMessageAction({ conversationId, replyMessage }))
          .then(action => {
            if (!!conversationData.draftMessage) {
              this.messagingService.sendMessage(<ChatTimelineMessage>{...conversationData.draftMessage, persistent:false, draft:true, timeDeleted:Date.now() });
            }
          });
      });
  }

  getReplyMessage$(conversationId:string): Observable<ChatTimelineMessage> {
    return this.store$.select(fromChat.selectConversationReplyMessage(conversationId));
  }

  protected attachmentPayloadRequests:{[key:string]:Promise<AudioRecordingData>} = {};
  getAttachmentPayload$(sourceMessage:ChatTimelineMessage,sourceAttachment:Attachment,load:{data:boolean,wave:boolean}):Promise<AudioRecordingData> {
    const conversationId = sourceMessage.conversationId;
    const messageId = sourceMessage.id;
    const index = sourceMessage.attachments?.findIndex(a=>a===sourceAttachment);
    const key = `direct.${conversationId}@${messageId}`;
    //console.log("getAttachmentPayload$(...)",key,index);
    let result = this.attachmentPayloadRequests[key];
    if (!result) {
      result = this.attachmentPayloadRequests[key] = new Promise<{data?:string,wave?:string,duration?:number}>((resolve, reject) => {
        firstValueFrom(this.getTimelineMessage$(conversationId, messageId))
          .then(message=>{
            //console.log("getAttachmentPayload$(...)",key,"message",message,"index",index);
            if (message.attachments.length>index) {
              const attachment = message.attachments[index];
              if ((!load?.data || !!(<any>attachment).data || !!(<any>sourceAttachment).data) &&
                  (!load?.wave || !!(<any>attachment).wave || !!(<any>sourceAttachment).wave)) {
                //console.log("getAttachmentPayload$(...)",key,"cached");
                resolve({data:(<any>attachment).data??(<any>sourceAttachment).data,wave:(<any>attachment).wave??(<any>sourceAttachment).wave});
              } else {
                this.store$.dispatch(attachmentPayloadRequestAction({
                  conversationId,messageId,callback:(data:AudioRecordingData)=>{
                    //console.log("getAttachmentPayload$(...).loaded",key,"message",message,"index",index,"data",data);
                    if (!!data) {
                      (<any>attachment).data = (<any>sourceAttachment).data = data.data ?? (<any>attachment).data ?? (<any>sourceAttachment).data;
                      (<any>attachment).wave = (<any>sourceAttachment).wave = data.wave ?? (<any>attachment).wave ?? (<any>sourceAttachment).wave;
                      resolve(data);
                    } else {
                      reject("payload loading failed");
                    }
                  }}));
              }
            } else {
              reject('message not found');
            }
          })
          .catch(reject);
      });
      result.finally(()=>delete this.attachmentPayloadRequests[key]);
    }
    return result;
  }

  getTimelineMessage$(conversationId:string,messageId:string): Observable<ChatTimelineMessage> {
    return this.store$.pipe(select(fromChat.selectConversationMessage(conversationId,messageId)));
  }

  deleteMessage(message:ChatTimelineMessage) {
    if (!!message?.conversationId && !message.timeDeleted) {
      message.timeDeleted = Date.now();
      this.sendMessage(message);
    }
  }

  getConversationData$(conversationId:string): Observable<ConversationData> {
    return this.store$.select(fromChat.selectConversation(conversationId));
  }

  setConversationMuted(conversationId:string,muted:boolean) {
    //console.log("setConversationMuted",muted);
    this.messagingService.sendMessage(this.messagingService.initializeMessage(<ConversationMuteMessage>{
      type: ConversationMuteMessageType,
      conversationId,
      timeMuted: muted ? Date.now() : undefined
    }));
  }

  getConversationTimeMuted$(conversationId:string): Observable<number> {
    return !conversationId ? of(undefined) :
      this.store$.select(fromChat.selectConversationTimeMuted(conversationId));
  }

  createOrUpdateDirectConversation(participantId:string, temporary:boolean = false) : Promise<string> {
    console.log("createOrUpdateDirectConversation(...)",participantId,"temporary",temporary);
    return new Promise<string>((resolve,reject) => {
      this.store$.dispatch(conversationCreateOrUpdateAction({
        conversation: {
          id:   undefined,
          type: 'direct',
          name: undefined,
          term: undefined
        },
        participantIds: [participantId],
        temporary,  // only temporary direct conversation for e.g. notes, not shown in chat conversations list...
        onError: errorLabel => reject(errorLabel),
        onSuccess: conversation => {
          let info = this.ensureSynchronizedConversationInfo(conversation.id);
          resolve(conversation.id);
          return info.hooks;
        }
      }));
    });
  }

  createOrUpdateGroupConversation(conversationType:ConversationType,conversationName:string,conversationTerm:string,participantIds:string[],updatedIds?:string[],conversationId?:string) : Promise<string> {
    let userId = this.userId;
    participantIds = participantIds?.find(id=>id==userId) ? participantIds : [userId,...(participantIds ?? [])];
    //console.log("createOrUpdateGroupConversation",conversationType,conversationName,userId,participantIds);
    return new Promise<string>((resolve,reject) => {
      this.store$.dispatch(conversationCreateOrUpdateAction({
        conversation: {
          id:   conversationId,
          type: conversationType,
          name: conversationName ?? '?',
          term: conversationTerm
        },
        participantIds,updatedIds,
        onError: errorLabel => reject(errorLabel),
        onSuccess: conversation => {
          //console.log("ON_SUCCESS.0",conversation,conversation?.id);
          let info = this.ensureSynchronizedConversationInfo(conversation.id);
          //console.log("ON_SUCCESS.1",conversation,conversation.id);
          resolve(conversation.id);
          return info.hooks;
        }
      }));
    });
  }

  createOrUpdateResourceConversation(conversationType:ConversationType,resourceId:string,conversationId?:string) : Promise<string>  {
    return new Promise<string>((resolve,reject) => {
      this.store$.dispatch(conversationCreateOrUpdateAction({
        conversation: {
          id:   conversationId,
          type: conversationType,
          name: undefined,
          term: undefined
        },
        resourceId,
        onError: errorLabel => reject.call(errorLabel),
        onSuccess: conversation => {
          let info = this.ensureSynchronizedConversationInfo(conversation.id);
          resolve.call(conversation.id);
          return info.hooks;
        }
      }));
    });
  }

  getConversationMessages(conversationId:string,parentId?:string,predicate?:(ChatTimelineMessage)=>boolean): FilteredList<ChatTimelineMessage> {
    // console.log("getConversationMessages",conversationId);
    let self = this;
    let list$:BehaviorSubject<ChatTimelineMessage[]> = undefined;
    let sections$:BehaviorSubject<number[]> = undefined;
    let listSubscription:Subscription = undefined;
    let sectionSubscription:Subscription = undefined;
    let messagingService = this.messagingService;
    //const addEncryptionInfoMessage = (messages:ChatTimelineMessage[])=>{
    //  /*const encryptionInfoMessage = messagingService.initializeMessage(<ChatInfoMessage>{
    //    type: ChatInfoMessageType,
    //    conversationId: conversationId,
    //    tags: [ENCRYPTION_INFO_TAG,TRANSLATE_TAG],
    //    bodyText: 'chat.conversation.info.encryption',
    //  });
    //  encryptionInfoMessage.timeCreated = messages.length>0 ? messages[0].timeCreated : encryptionInfoMessage.timeCreated;
    //  return [encryptionInfoMessage,...messages];*/
    //  return messages;
    //};
    const filterTimelineMessages = (messages:ChatTimelineMessage[])=>{
      return !!predicate ? messages.filter(predicate) : messages;
    };
    return new class extends FilteredList<ChatTimelineMessage> {
      get list$(): Observable<ChatTimelineMessage[]> {
        // console.log("getConversationMessages.list$",conversationId);
        if (!list$) {
          list$ = new BehaviorSubject<ChatTimelineMessage[]>([]);
          listSubscription = self.synchronizedConversation(conversationId)
            .subscribe(messages => {
              if (!!parentId) {
                //console.log("ChatService.getConversationMessages.1\nmessages",messages,"parentId",parentId);
                const childrenIds = new Set<string>();
                childrenIds.add(parentId);
                loaded$<ChatTimelineMessage,string>(messages).pipe(
                  map(entry => entry.curr.entity),
                  filter(message => !!message?.parentId && childrenIds.has(message.parentId)),
                  tap(message => childrenIds.add(message.id)),
                  toArray()
                ).subscribe(children => {
                  list$.next(filterTimelineMessages(children))
                });
              } else {
                //console.log("ChatService.getConversationMessages.2\nmessages",messages);
                list$.next(filterTimelineMessages(messages));
              }
            });
            /*.subscribe(messages => {
              console.log("LIST.NEXT",messages?.length,"\n",messages);
              list$.next(messages);
            });*/
        }
        return list$;
      }
      get sections$(): Observable<number[]> {
        // console.log("getConversationMessages.sections$",conversationId);
        if (!sections$) {
          sections$ = new BehaviorSubject<number[]>([]);
          sectionSubscription = this.list$.pipe(
            /*tap(list=>{
              console.log("SECTIONS.LIST.NEXT",list);
            }),*/
            switchMap(entities =>
              loaded$<ChatTimelineMessage,string>(entities).pipe(
                map(entry => {
                  let entity = entry.curr.entity;
                  entry.curr.stored = moment(entity.timeCreated).format('YYYYMMDD');
                  //console.log("map>entry.prev",entry.prev?.stored,entry.prev,"curr",entry.curr?.stored,entry.curr);
                  return entry;
                }),
                filter(entry => !entry.prev || entry.prev.stored!=entry.curr.stored),
                //tap(entry => console.log("filtered>entry.prev",entry.prev?.stored,entry.prev,"curr",entry.curr?.stored,entry.curr)),
                map(entry => entry.curr.index),
                //tap(index => console.log("index",index)),
                toArray(),
                /*tap(list=>{
                  console.log("SECTIONS.DONE.NEXT",list);
                }),*/
                //tap(array => console.log("array",array)),
              ))).subscribe(sections => sections$.next(sections));
        }
        return sections$.pipe();
      }
      unfiltered(): void {
        self.store$.dispatch(conversationUpdateFiltersAndTermAction({conversationId:conversationId}));
      }
      updateFilters(filters: string[]): void {
        self.store$.pipe(
          select(fromChat.selectConversationState(conversationId)),
          first()).subscribe(state => {
          if (!isEqual(state?.filters??EMPTY_ARRAY, filters??EMPTY_ARRAY)) {
            self.store$.dispatch(conversationUpdateFiltersAndTermAction({
              conversationId,
              filter: {
                filters:filters||[],
                term:state.term
              }
            }));
          }
        });
      }
      updateSearchTerm(term: string | undefined): void {
        self.store$.pipe(
          select(fromChat.selectConversationState(conversationId)),
          first()).subscribe(state => {
          if (!isEqual(state?.term??'', term??'')) {
            self.store$.dispatch(conversationUpdateFiltersAndTermAction({
              conversationId,
              filter: {
                filters:state.filters,
                term:state.term||''
              }
            }));
          }
        });
      }
      unsubscribe(): void {
        super.unsubscribe();
        listSubscription?.unsubscribe();
        sectionSubscription?.unsubscribe();
      }
    };
  }

  /**
   * ensure the synchronization conversation info.
   * segmentation:
   *    cold segments:  of size 20-1000 (every 1000 messages, a new cold segment is created)
   *    hot segment:    of size 2-20 (when it grows >=20 messages, it is cutted back to 2,
   *                    the rest goes to cold segment)
   * @param conversationId
   */
  ensureSynchronizedConversationInfo(conversationId:string): ConversationConnection {
    let curr = this.conversations[conversationId];
    let info:ConversationConnection = curr || {
      conversationId,
      loaded: false,
      subscribed: 0, // no subscriptions... means it can be unloaded if necessary
      hooks: this.createSynchronizedConversationHooks(conversationId)
    }
    if (!curr) {
      this.conversations[conversationId] = info;
    }
    return info;
  }

  protected createSynchronizedConversationHooks(conversationId:string) : IndexedArrayHooks<ChatTimelineMessage> {
    return {
      getId:(info)=>{
        return info?.id;
      },
      isValue:(value)=>{
        //console.log("synchronizationHooks:isValue",value);
        return !!value;
      },
      getValue:(array,index,value)=>{
        //console.log("synchronizationHooks:getValue",array,"@",index,value);
        return value;
      },
      onSet:(array, index, value)=>{
        let prev = array[index];
        //console.log("synchronizationHooks:onSet",array,"set@",index,prev,value);
      },
      // also handles initialization! .... elements removed and added....
      onSpliced:(array, removedAtIndex, removed, addedAtIndex, added)=>{
        //console.log("synchronizationHooks:onSpliced",array,"removed@",removedAtIndex,removed,"added@",addedAtIndex,added);
      }
    };
  }

  protected synchronizeConversation(conversationId:string,connection:ConversationConnection):Subscription {
    //console.trace("synchronizeConversation(...)",conversationId);
    let lastTimer = undefined;
    let lastOpen  = true;
    return combineLatest([
      this.messagingService.open$,
      this.store$.select(fromChat.selectConversationState(conversationId)),
      timer(100, 600_000)
    ]).pipe(
      filter(([open,state,timer])=> !!state)
    ).subscribe(([open,state,timer]) => {
      if (!open) {
        lastOpen = false;
      } else {
        let sync = timer!=lastTimer ||
                   open!=lastOpen ||
                   !!state.conversationData?.segments?.find(segment => segment.synchronize);
        //console.log("synchronizeConversation.TIMER.0",timer,"sync",sync,"conversationId",conversationId,"state",state,"open",open);
        // loads all segments and syncs latest, or trigger verification of segments...
        if (sync) {
          //console.log("synchronizeConversation.TIMER.1",timer,"conversationId",conversationId,"data",state.conversationData,"segments",state.conversationData?.segments);
          this.store$.dispatch(conversationSynchronizeSegmentAction({
            conversation: {
              type: state.conversationData.type,
              id: state.conversationData.id,
              name: state.conversationData.name,
              term: state.conversationData.term,
              version: state.conversationData.version
            },
            hooks:connection.hooks,
            dispatch:action => this.store$.dispatch(action)
          }));
        }
        lastOpen  = true;
        lastTimer = timer;
      }
    })
  }

  protected synchronizedConversation(conversationId:string) : Observable<ChatTimelineMessage[]> {
    let loaded$ = new ReplaySubject<{loaded:boolean,reason?:any}>();
    let info = this.ensureSynchronizedConversationInfo(conversationId);
    //console.trace("synchronizedConversation",info);
    if (!info.loaded) {
      info.loaded = true;
      //console.log("synchronizedConversation",info);
      // load all segments of the whole conversation....
      //database.get(conversationId, this.syncStore)
      this.syncStore.get(conversationId)
        .then((header: ConversationSegmentHeader) => {
          // just basic verify if this is a valid header
          header?.segments?.forEach(segment=>segment.synchronize=false);
          let current = header?.segments?.length>0 ? header.segments[header.segments.length-1] : undefined;
          if (!!header?.conversation && !!current &&
                header.messages?.length == current.size) {
            this.store$.dispatch(conversationUpdateSegmentAction({
              conversation: header.conversation,
              current: {
                ...current,
                loaded: true,
                messages: header.messages
              },
              segments: header.segments,
              hooks:info.hooks,
              dispatch: action => this.store$.dispatch(action)
            }));
            /*
            database.getMany(header.segments.reduce((keys,segment)=>{
              if (!!segment.key) {
                keys.push(segment.key);
              }
              return keys;
            },<string[]>[]),this.syncStore)
             */
            this.syncStore.values(header.segments.reduce((keys,segment)=>{
              if (!!segment.key) {
                keys.push(segment.key);
              }
              return keys;
            },<string[]>[]))
              .then((segments: ConversationSegmentData[]) => {
                segments?.forEach(segment => {
                  this.store$.dispatch(conversationUpdateSegmentAction({
                    conversation: header.conversation,
                    current: segment,
                    segments: header.segments,
                    hooks:info.hooks,
                    dispatch: action => this.store$.dispatch(action)
                  }));
                });
                loaded$.next({loaded:true});
              })
              .catch(reason => {
                loaded$.next({loaded:false,reason});
              })
          } else {
            loaded$.next({loaded:true});
          }
        })
        .catch(reason => {
          loaded$.next({loaded:false,reason});
        })
    } else {
      loaded$.next({loaded:info.loaded});
    }
    let synchronizing = false;
    //let prev = undefined;
    return loaded$.pipe(
      switchMap(done => this.store$.pipe(
        select(fromChat.selectConversationMessages(conversationId)),
        map(entities => {
          entities = entities ?? [];
          if (entities?.length>0) {
            const backingIndices:{[key:string]:number} = {};
            const backingArray:ChatTimelineMessage[]   = [];
            const backingHooks:IndexedArrayHooks<ChatTimelineMessage> = (<any>entities).backingHooks ?? this.ensureSynchronizedConversationInfo(conversationId).hooks;
            (<ChatTimelineMessage[]>((<any>entities).backingArray ?? entities))
              .forEach(message=>{
                if (message.type==ChatMessageType || !transientMessageTypes.has(message.type)) {
                  backingIndices[message.id] = backingArray.length;
                  backingArray.push(message);
                }
              });
            return createIndexedArrayProxy(backingHooks,backingArray,backingIndices);
          }
          return entities
        }),
        //map(entities => entities ?? []),
        tap(entities => {
          //console.log("SCROLLER.entities",entities.length,"\n",entities,"\nsame",prev===entities);
          //prev = entities;
          // start synchronizatin whenever someone subscribes....
          if (!synchronizing) {
            synchronizing = true;
            info.subscribed++;
            //console.log("synchronizedConversation.connect",!!info.synchronizationSubscription,info);
            info.synchronizationSubscription = info.synchronizationSubscription ||
                this.synchronizeConversation(conversationId,info);
            //console.log("subscribed to",conversationId)
          }
        }),
        finalize(() => {
          // stop synchronizatin whenever last one unsubscribes....
          if (synchronizing) {
            synchronizing = false;
            info.subscribed = Math.max(info.subscribed-1,0);
            if (info.subscribed == 0 && !!info.synchronizationSubscription) {
              //console.log("synchronizedConversation.disconnect",conversationId,info);
              info.synchronizationSubscription.unsubscribe();
              info.synchronizationSubscription = undefined;
            }
            //console.log('UNSUBSCRIBED TO CONVERSATION',conversationId)
          }
        })
      ))
    );
  }

  /*
    send a ChatTimelineMessage to server:
    1) save the message locally
    2) put the message into sending queue (this queue is loaded at startup
       and all messages sent ar queued here)
    3) update store with this message to immediately show sending, even
       if the connection to the server is currently closed...
   */
  sendMessage(message: ChatTimelineMessage): Promise<void> {
    //console.log("sendMessage",message);
    return firstValueFrom(this.store$.select(fromChat.selectConversationState(message?.conversationId)))
      .then(conversationState => {
        if (!!conversationState?.conversationData) {
          //console.log("message",message,"persistent",message.persistent,"store",this.sendStore);
          message.pending = true; // outgoing message which is not yet acknowledged from server to be received...
          message.timeUpdated = message.timeUpdated>0 ? message.timeUpdated+1 : message.timeCreated;
          if (message.persistent) {
            //return database.set(message.id,message,this.sendStore)
            return this.sendStore.set(message.id,message)
              .then(()=> {
                //console.log("SEND.saved",message);
                this.dispatch(addQueueMessagesAction({messages:[message]}))
                  .then((action)=> {
                    //console.log("SEND.queued",message);
                    if (!!message.timeDeleted) {
                      this.sounds.messageOut().play();
                    } else {
                      this.sounds.messageOut().play();
                    }
                    let conversationData = conversationState.conversationData;
                    let conversationHeader:ConversationHeader = {
                      type: conversationData.type,
                      id: conversationData.id,
                      version: conversationData.version,
                      name: conversationData.name,
                      term: conversationData.term,
                      timeMuted: conversationData.timeMuted,
                      timeReceived: conversationData.timeReceived,
                      timeViewed: conversationData.timeViewed,
                      timeSelfReceived: Math.max(conversationData.timeSelfReceived??0, MessageTimeUpdated(message,0)),
                      timeSelfViewed: Math.max(conversationData.timeSelfViewed??0, MessageTimeUpdated(message,0))
                      //filterId: conversationState.filterId
                    };
                    this.store$.dispatch(receiveChatTimelineMessageAction({
                      message: message,
                      headers: {conversation: conversationHeader},
                      hooks: this.ensureSynchronizedConversationInfo(conversationData.id).hooks,
                      dispatch: action => this.store$.dispatch(action)
                    }));
                  });
              })
              .catch(error => {
                //console.log("SEND.error",error);
                this.store$.dispatch(showErrorAction({
                  errorLabel: "chat.error.cannotSaveMessage"
                }));
              });
          } else {
            return this.dispatch(addQueueMessagesAction({messages:[message]})).then(() => void 0);
          }
        }
        return Promise.resolve();
    });
  }

  updateFilter(filters: string[]): void {
  }

  createParticipantsListView$(conversationId:string): FilteredListView<Participant> {
    let currentSearchTerm = '';
    let currentSearchTerms:string[] = [];
    return this.synchronizationService.createFilteredListView<Participant>(
      'participant',
      // triggerLoad is called, if the filters or the search term changes
      (filters:{[type:string]:string[]},searchTerm:string)=>true,
      // preprocessEntities is called, after the entities are loaded from the server
      (entities:{entity:Task}[]):{entity:Task}[]=>entities,
      // serverFilterPrepare is called, before the filters are sent to the server
      (filters:{[type:string]:string[]}):{[type:string]:string[]}=>{ return {}},
      // serverSearchPrepare is called, before the search term is sent to the server
      (searchTerm:string):string=>'',
      // client side filter and search is only done, if these predicates are defined!
      // clientFilterPredicate
      (entity:VersionedId,filter:{[type:string]:string[]}):boolean=>true,
      (entity:VersionedId,searchTerm:string)=>{
        if (!!entity) {
          const participant = <Participant>entity;
          if (searchTerm!==currentSearchTerm) {
            currentSearchTerm = searchTerm ?? '';
            currentSearchTerms = currentSearchTerm.toLowerCase().split(/\s+/);
          }
          if (!participant.searchString) {
            participant.searchString = (participant.name?.length>0 ? participant.name.toLowerCase() : '');
          }
          return currentSearchTerms.every(term=>participant.searchString.includes(term));
        }
        return true;
      },
      // if undefined, the first fetch is DEFAULT_SEGMENT_SIZE (25) at the end of the list
      // which is the newest/most active part of the list....
      // everything else just gets fetched on access....
      // requestBootstrapSegment
      ()=>{ return { index: MAX_JAVA_INTEGER, size: DEFAULT_SEGMENT_SIZE }},
      // requestPrefetchInfo
      (state:ListViewState)=>{
        return {
          moreActiveSize: 0,
          morePassiveSize: 0, //state.passiveSize,
          prefetch: 'all'
        };
      });
  }

  getParticipants$(conversationId:string):Promise<Participant[]> {
    //const participants$ = new BehaviorSubject<Participant[]>([]);
    //this.store$.dispatch(participantsLoadAction({conversationId,participants$,loaded:false}));
    //return participants$;
    return new Promise<Participant[]>((resolve, reject) => {
      const path = "/v1.0/chat/participants/"+conversationId;
      this.http.get(path).subscribe((result:{data:Participant[]}) => {
          resolve(result.data ?? []);
        },error=>reject('getParticipants$.error:'+error));
    });
  }

  updateParticipant$(conversationId:string,participant:Participant):Promise<Participant> {
    return new Promise<Participant>((resolve, reject) => {
      const path = "/v1.0/chat/participant/update";
      this.http.post(path,{
        conversationId,participant
      }).subscribe((result:{data:Participant}) => {
        resolve(result.data);
      },error=>reject('updateParticipant$.error:'+error));
    });
  }

  // true: yes, this users message
  // false: other participants message
  // undefined: has no from
  isUserMessage(message:ChatTimelineMessage):boolean {
    const id = message?.from?.id;
    return id==undefined ? undefined : id==this.userId ? true : false;
  }

  isStatusMessage(message:ChatTimelineMessage):boolean {
    return message.type=='call' || this.isUserMessage(message)==undefined;
  }

  get unseen$() : Observable<number> {
    return this.store$.select(fromChat.selectConversationsUnseen);
  }

  /*
  protected _participantsMap = new Map<string,{subscribed:number,list$:Observable<Participant[]>}>();
  internalGetParticipants$(conversationId:string,potential:boolean):Observable<Participant[]> {
    const key = conversationId+potential;
    if (!this._participantsMap.has(key)) {
      const list$ = new BehaviorSubject<Participant[]>([]);
      this._participantsMap.set(key,{subscribed:0,list$});
    }
    const ref = this._participantsMap.get(key);
    ref.subscribed++;
    return ref.list$;
  }*/
}
