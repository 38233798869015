<virtual-scroller #list class="conversations" #scroller
                  [scrollToAdded]="scroller"
                  [startFromTop]="true"
                  [itemDefaultSize]="74"
                  [items$]="conversations$"
                  [itemsId$]="conversationsId$">
  <chat-conversation-item
    onDrop [dropForwarding]="{
      trigger:conversation.id!=chatService.currentConversationId,
      timeout:2000,
      callback:dropForwardCallback(conversation),
      target:conversation.id}"
    *virtualFor="let conversation of scroller.viewportItems; let index = index; trackBy: trackByConversation;"
    (click)="setCurrentConversationId(conversation.id)"
    [conversation]="conversation">
  </chat-conversation-item>
</virtual-scroller>
