import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from '@angular/core';
import {Contact, ENVIRONMENT, GroupMembership, Logger} from "core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {PropertiesService} from "properties";

export interface ContactListItemRenderingHints {
  noPartnerId?:boolean;
  noJoinDetails?:boolean;
  noNameLink?:boolean;
  noReferrer?:boolean;
}

@Component({
  selector: 'app-contact-list-item',
  templateUrl: './contact-list-item.component.html',
  styleUrls: ['./contact-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactListItemComponent implements OnInit {

  @Input() index: number;
  @Input() contact: Contact;
  @Input() renderingHints: ContactListItemRenderingHints = {};

  //prevIndex : number;
  //prevContact: Contact;

  logger: Logger = new Logger('ContactListItemComponent');

  constructor(protected propertiesService: PropertiesService,
              @Inject(ENVIRONMENT) protected environment: any) {
  }

  ngOnInit() {

  }

  get groupId$(): Observable<string> {
    return this.propertiesService.properties$.pipe(
      map(properties => properties.group.id)
    );
  }

  get membership(): Observable<GroupMembership> {
    return this.groupId$.pipe(map((groupId: string) => {
      if (this.contact.groups) {
        return this.contact.groups[groupId];
      }
    }))
  }

  //get full(): boolean {
  //  return this.mode === 'full';
  //}

  get photoUrl(): string {
    /*
    if (!this.prevContact && this.contact) {
      this.prevContact = this.contact;
      this.prevIndex   = this.index;
    }
    if (this.prevContact) {
      if (this.prevContact!=this.contact) {
        console.log("FATAL this.index:"+this.index+" prev.index:"+this.prevIndex,this.contact,this.prevContact);
      } else if (this.prevIndex!=this.index) {
        console.log("FATAL this.index:"+this.index+" prev.index:"+this.prevIndex,this.contact,this.prevContact);
      }
    }
    */
    // return this.contact.picture.data.url; // takes from facebook...many requests fail
    return `${this.environment.serverUrl}/v1.0/content/avatar/${this.contact.version || 0}/${this.contact.id}.jpg`;
    // return `${this.environment.production ? this.environment.serverUrl : 'https://1509039800.rsc.cdn77.org' +
    //        `/content/avatar/${this.contact.version || 0}/${this.contact.id}.jpg`}`;
  }
}
