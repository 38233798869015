import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {clipboardCopy, ENVIRONMENT, Logger, Platform, User} from "core";
import {TranslateService} from "@ngx-translate/core";
import {concat, from, Observable, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {AuthenticationService} from "auth";
import {DownloadService} from "shared";
import {PropertiesService} from "properties";

@Component({
  selector: 'app-install',
  templateUrl: './app-install.component.html',
  host: {
    '[class.fullscreen]': "fullscreen"
  },
  styleUrls: ['./app-install.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppInstallComponent implements OnInit, OnDestroy {

  googlePlayLink: string;
  appleStoreLink: string;
  info: string;
  imagesLoaded: boolean = false;
  download:boolean = false;

  @Input() code: string;
  @Input() link: string;
  @Input() user: User;
  @Input() fullscreen: boolean = false;

  @Output() onClose: EventEmitter<void> = new EventEmitter();
  @Output() onLink:  EventEmitter<any>  = new EventEmitter();
  // @Output() canInstall: EventEmitter<boolean> = new EventEmitter();

  private subscriptionBundle = new Subscription();

  private logger = new Logger('AppInstallComponent');

  constructor(public platform: Platform,
              private translateService: TranslateService,
              private changeDetector: ChangeDetectorRef,
              private sanitizer: DomSanitizer,
              private downloadService: DownloadService,
              private authenticationService: AuthenticationService,
              private propertiesService: PropertiesService,
               @Inject(ENVIRONMENT) private environment: any) {
    //console.debug('AppInstallComponent.ctor()');
  }

  onTapLink(event: MouseEvent): void {
    if (this.code) {
      clipboardCopy(this.code); // installed app will search for code in clipboard
      if (this.platform.is('ios')) {
        // server will keep the code in client session
        // later the installed app could check if there is an invitation code
        // in the clipboard for sessions having the same IP
        // this way we try to limit clipboard access on ios which could be annoying for the user because
        // Apple has added a new banner alert to iOS 14 that lets users know if an app is pasting
        // from the clipboard and therefore able to read the clipboard’s contents
        this.authenticationService.setInvitationCode(this.code);
      }
    }
    this.onLink.emit((event.target as HTMLAnchorElement).href);
    // event.preventDefault();
  }

  onTapClose(event: MouseEvent) {
    this.onClose.emit();
  }

  imageLoaded(event:Event) {
    window.setTimeout(() => {
      this.imagesLoaded = true;
      // markForCheck() call is required when the component is displayed inside bottom sheet.
      // In this case the change detection does no happen automatically.
      // When used inside a regular overlay it works also without the following line.
      this.changeDetector.markForCheck();
    },5000);
    if (event.target instanceof HTMLElement) {
      (event.target as HTMLElement).style.opacity = "1";
    }
  }

  ngOnInit() {
    const googlePlayLinkKey = 'app.links.googlePlay', appleStoreLinkKey = 'app.links.appleStore';
    this.subscriptionBundle.add(
      this.translateService
        .stream([ googlePlayLinkKey, appleStoreLinkKey ])
        .subscribe((result) => {
          this.googlePlayLink = result[googlePlayLinkKey];
          this.appleStoreLink = result[appleStoreLinkKey];
          // let ok:boolean =
          //   (this.googlePlayLink.length && this.androidOrDesktop) ||
          //   (this.appleStoreLink.length && this.iosOrDesktop);
          // //console.log("google.play",this.googlePlayLink,"apple.appstore",this.appleStoreLink,"ok",ok);
          // this.canInstall.emit(ok);
        })
    );

    if (this.user) {
      this.subscriptionBundle.add(
        concat(
          from(this.platform.ready()),
          // this.authenticationService.state$(),
        // ).subscribe((state: AuthenticationState) => {
          ).subscribe(() => {
          this.info = null;
          // const user = state.user;
          // if (!user || !user.id) {
          if (!this.user || !this.user.id) {
            if (!this.link && !this.platform.is('hybrid')) {
              this.info = 'installNoCodeInfo';
            } else if(this.link) {
              if (this.platform.is('desktop')) {
                this.info = 'installDesktopInfo';
              } else if (!this.platform.is('hybrid')) {
                if (this.platform.is('android')) {
                  this.info = 'installMobileAndroidInfo';
                } else if (this.platform.is('android')) {
                  this.info = 'installMobileIosInfo';
                }
              }
            }
            if (this.info) {
              this.info = `login.${this.info}`;
            }
          }
        })
      );
    }
  }

  get androidOrDesktop(): boolean {
    return this.platform.is('desktop') || this.android;
  }

  get android(): boolean {
    return !this.platform.is('hybrid') && this.platform.is('android');
  }

  get iosOrDesktop(): boolean {
    return this.platform.is('desktop') || (!this.platform.is('hybrid') && this.platform.is('ios'))
  }

  ngOnDestroy() {
    this.subscriptionBundle.unsubscribe();
  }

  get openInStore(): Observable<SafeHtml> {
    return this.translateService
      .stream('app.openInStore')
      .pipe(map(text => this.sanitizer.bypassSecurityTrustHtml(text)));
  }

  toDownload() {
    this.download = true;
  }

  onDownload(element: Element) {
    if (!element.classList.contains('downloading')) {
      const properties = this.propertiesService.properties;
      const version = properties.apps?.["android"]?.version
                   || properties.user.app?.storeVersion
                   || this.environment.version?.split('-')[0];
      const url = `${this.environment.serverUrl}/${this.environment.app}-${version}.apk`;
      this.downloadService.download(url, element).catch(error => this.logger.error("Failed to download app"));
    }
  }
}
