import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Attachment} from "messaging";
import {Space} from "../../core/space";
import {AttachmentComponent} from "../../core/attachment.component";
import {ChatTimelineMessage} from "../../../../store/models"

@Component({
  selector: 'audio-recording-attachment-square-viewer',
  template: `<mat-icon>graphic_eq</mat-icon>`,
  styles: [`
    mat-icon {
      padding: 2px;
      width: 36px;
      height: 36px;
      font-size: 36px;
      line-height: 36px;
      color: #ffffff40;
    }
    :host-context(.left)>mat-icon {
      color: #00000040;
    }
    :host-context(.color-scheme-light.pure)>mat-icon {
      color: #ffffff80;
    }
    :host-context(.color-scheme-dark)>mat-icon {
      color: #ffffff40;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioRecordingAttachmentSquareViewerComponent implements AttachmentComponent {
  protected _space: Space = {
    fixedWidth: false,
    fixedHeight: false,
    fixedRatio: true,
    ratio: 1
  };
  protected _attachments: Attachment[] = [];

  @Input()
  set message(message:ChatTimelineMessage) {
  }

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    this._attachments = attachments ?? [];
  }

  get attachments(): Attachment[] {
    return this._attachments;
  }

  get space(): Space {
    return this._space;
  }

  get updated(): EventEmitter<AttachmentComponent> {
    return undefined;
  }

  get deleted(): EventEmitter<Attachment> {
    return undefined;
  }
}
