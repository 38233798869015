import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, skip, take} from "rxjs";
import {distinctUntilChanged, takeUntil, tap} from "rxjs/operators";
import {BasicContainerComponent} from "shared";
import {ContactService, Selection} from 'contact';
import {Contact, Logger} from "core";

@Component({
  selector: 'task-contact-list',
  templateUrl: './task-contact-list.component.html',
  styleUrls: ['./task-contact-list.component.scss']
})
export class TaskContactListComponent extends BasicContainerComponent  {

  @Output() selectionChange = new EventEmitter<Contact[]>();

  @Input() entities$: Observable<Contact[]>;
  @Input() cacheId$: Observable<string>;
  @Input() set selected (selected: Contact[]) {
    this.logger.debug('selected', selected);
    this.selected$.next(selected);
  }

  get selected(): Contact[] {
    const selection = this.selection$.getValue();
    // Object.values() returns an array ordered the same way as provided by a for...in loop i.e.
    // string keys are traversed in ascending chronological order of property creation.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/values
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/for...in
    return Object.values(selection);
  }

  protected _multiSelection = false;
  @Input() set multiSelection(multiSelection: boolean) {
    multiSelection = multiSelection ?? false;
    if (multiSelection!=this._multiSelection) {
      this._multiSelection = multiSelection;
      this.selection$.next(undefined);
    }
  }

  get multiSelection(): boolean {
    return this._multiSelection;
  }

  selected$ = new BehaviorSubject<Contact[]>([]);
  selection$ = new BehaviorSubject<{[key: string]: Contact}>(undefined);

  // protected contactSubscriptions: {[key:string]:Subscription} = {};
  // protected subscribedContacts: Contact[];

  protected initialized = false;
  protected logger = new Logger('TaskContactListComponent');

  constructor(protected contactService: ContactService) {
    super();
    this.logger.debug('ctor');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cacheId$?.pipe(takeUntil(this.onDestroy$)).subscribe((cacheId) => {
      this.logger.debug("cacheId", cacheId);
    });
    const entitiesSubscription = this.entities$
      ?.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe((contacts) => {
        this.logger.debug("Contacts", contacts?.length);
        // this.subscribed(contacts);
      });
    // entitiesSubscription.add(() => this.subscribed(undefined));

    this.selected$.pipe(takeUntil(this.onDestroy$))
      .subscribe((selected) => {
        const selection = selected?.reduce((selection, contact) => {
          if (contact) {
            selection[contact.id] = contact;
          }
          return selection;
        }, {} as any);
        this.selection$.next(selection);
      });

    this.selection$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged((previous, current) => {
        const previousIds = previous ? Object.keys(previous) : [];
        const currentIds  = current  ? Object.keys(current)  : [];
        return previousIds == currentIds ||
          previousIds && currentIds &&
          previousIds.length == currentIds.length &&
          previousIds.every(f => currentIds.includes(f))
      }),
      skip(1), // initial selection is not emitted back to the container
    ).subscribe((selection) => {
      const selectedValues = selection ? Object.values(selection) : [];
      this.logger.debug('selectionChange.emit', selectedValues);
      this.selectionChange.emit(selectedValues);
    });
  }

  onSelectionChanged(event: Selection) {
    this.logger.debug('onSelectionChanged', {event});
    const contact = event?.contact;
    const id = contact?.id;
    if (id) {
      // keep selection value immutable i.e. use shallow clone
      // this will ensure distinctUntilChanged operator will not take the same object
      // for previous and current values as this will compromise the comparison
      let selection = {...this.selection$.getValue()};
      const selected = selection?.[id];
      if (selected) {
        delete selection[id];
      } else {
        if (selection && this.multiSelection) {
          selection[id] = contact;
        } else {
          selection = {[id]: contact};
        }
      }
      this.logger.debug('onSelectionChanged', {selection});
      this.selection$.next(selection);
    }
  }

  // subscribed(contacts: Contact[]): Contact[] {
  //   if (this.subscribedContacts!==contacts) {
  //     this.subscribedContacts = contacts;
  //     const subscriptions: {[key:string]: Subscription} = {};
  //     contacts?.forEach(contact => {
  //       if (!!contact?.id) {
  //         subscriptions[contact.id] =
  //           this.contactSubscriptions[contact.id] ??
  //           this.contactService.getContact$(contact.id,contact).subscribe();
  //       }
  //     });
  //     Object.keys(this.contactSubscriptions).forEach(contact=>{
  //       if (!subscriptions[contact]) {
  //         this.contactSubscriptions[contact].unsubscribe();
  //       }
  //     });
  //     this.contactSubscriptions = subscriptions;
  //   }
  //   return contacts;
  // }

  callback(contact: Contact, index: number, last: boolean) {
    return () => {
      index==0 && (this.initialized = false);
      if (contact?.id) {
        const select = this.selected?.findIndex(selectedContact => contact?.id == selectedContact.id) >= 0;
        const selected = this.selection$.getValue()?.[contact.id];
        if (select!=!!selected) {
          this.onSelectionChanged({contact, index})
        }
      }
      last && (this.initialized = true);
    }
  }
}
