<ng-container *ngIf="{ ymdt: yearMonthDayTime, year: (year | async) } as context">
  <ng-container *ngIf="context.ymdt">
    <div class="date" [class.dense]="!!index%2" [style.backgroundColor]="!!color ? '#'+color : undefined">
      <span class="day">{{context.ymdt.day}}</span>
      <span class="month">{{context.ymdt.month}}</span>
      <!--<div class="year" *ngIf="context.ymdt.year!=context.year">{{context.ymdt.year}}</div>-->
    </div>
  </ng-container>
  <div class="details">
    <div>
      <h3 class="title"><span>{{event.name}}</span></h3>
      <div class="location">{{event.info}}</div>
    </div>
    <div>
      <div class="time">
        <mat-icon>schedule</mat-icon>
        <span>{{context.ymdt.time}}</span>
      </div>
      <div *ngIf="event.seats.total.online; let seatsOnline">
        <mat-icon>connected_tv</mat-icon>
        <span>{{ (seatsOnline - event.seats.available.online) + ' / ' + seatsOnline }}</span>
      </div>
      <div *ngIf="event.seats.total.offline; let seatsOffline">
        <mat-icon>event_seat</mat-icon>
        <span>{{ (seatsOffline - event.seats.available.offline) + ' / ' + seatsOffline }}</span>
      </div>
<!--      <div>{{'SEATS: '+(event.seats | json)}}</div>-->
    </div>
  </div>
</ng-container>
