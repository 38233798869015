import {
  CachedState,
  createCacheId,
  createPageLoadingArray,
  createPageLoadingProxy,
  EntityState,
  IoState,
  Logger,
  SearchableState1,
  SelectableState,
  SimpleFilterableState,
  SubscribableState,
  VersionedId
} from "core";
import {createReducer, createSelector, on} from "@ngrx/store";
import {
  CalendarActionIds,
  calendarDeleteFailureAction,
  calendarDeleteSuccessAction,
  calendarLoadPageAction,
  calendarLoadPageSuccessAction,
  calendarLoadRequestAction,
  calendarSubscribeAction,
  calendarUnsubscribeAction,
  CalendarUpdateAction,
  calendarUpdateFailureAction,
  calendarUpdateFilterAction,
  calendarUpdateSearchTermAction,
  calendarUpdateSuccessAction
} from "./actions";
import {Calendar} from "../../models/calendar";
import isEqual from "lodash/isEqual";
import {selectCalendarState} from "../state";
import {isValidNumber} from "shared";

const logger = new Logger('Calendar.reducer');

export interface CalendarsState extends
  SearchableState1,
  SimpleFilterableState,
  IoState,
  CachedState,
  SelectableState,
  EntityState<Calendar>,
  SubscribableState<Calendar> {
}

export const initialCalendarsState: CalendarsState = {
  term: '',
  filters: [],
  entities: [],
  indices: {},
  subscriptions: {},
  currentCacheId: null,
  previousCacheId: null,
  selectedIndex: null,
  loading: false,
  error: null
};

export const calendarReducer = createReducer(
  initialCalendarsState,
  on(calendarLoadRequestAction,(state, {}) => ({ ...state, currentCacheId: createCacheId(), loading: true })),
  on(calendarUpdateFilterAction,(state, { filters })=>
    isEqual(state.filters, filters)
      ? state
      : {
        ...state,
        entities: createPageLoadingArray<Calendar>(0,25, (index: number, size: number): void => {}),
        indices: {},
        filters: filters,
        currentCacheId: createCacheId()
      }
  ),
  on(calendarUpdateSearchTermAction,(state,{term})=>
    isEqual(state.term, term)
      ? state
      : { ...state, term: term, currentCacheId: createCacheId() }
  ),
  on(calendarLoadPageSuccessAction,(state, { cacheId, index, data, total, dispatch })=> {
    let { entities, indices, previousCacheId } = { ...state };
    const ioState = { loading: false, error: null };
    if (cacheId == state.currentCacheId && data) {
      if (state.previousCacheId != state.currentCacheId) {
        entities = createPageLoadingArray<Calendar>(
          total,
          25,
          (index: number, size: number):void => {
            dispatch(calendarLoadPageAction({ cacheId, index, size }));
          });
        indices = {};
        previousCacheId = cacheId;
      } else {
        const backingArray: Calendar[] = (<any>entities).backingArray ?? [];
        const loadPageFunction: (pageIndex: number, pageSize: number) => void = (
          (<any>state.entities).loadPageFunction ||
          (() => ((pageIndex:number, pageSize:number): void => {}))
        )() ;
        entities = createPageLoadingProxy<Calendar>(backingArray,25, loadPageFunction)
      }
      entities['loaded'] = Math.max(entities['loaded'] || 0, index + data.length);
      for (let i=0, max=data.length; i<max; i++) {
        entities[index+i] = data[i];
        indices[data[i].id] = index+i;
      }
    }
    return { ...state, entities, indices, previousCacheId, ioState };
  }),
  {
    types: [CalendarActionIds.UPDATE],
    reducer: (state, action: CalendarUpdateAction) => {
      return calendarReducer(state, calendarUpdateSuccessAction({
        calendar: action.calendar, correlationId: action.correlationId
      }));
    }
  },
  // on(calendarUpdateAction,(state, { calendar, previous}) => {
  //   return calendarReducer(state, calendarUpdateSuccessAction({ calendar }));
  // }),
  on(calendarUpdateSuccessAction,(state, { calendar}) => {
    let index : number = state.indices[calendar.id];
    logger.debug(calendarUpdateSuccessAction.type, 'index', index);
    if (index !== undefined) {
      let backingArray : Calendar[] = (<any>state.entities).backingArray;
      let loadPageFunction : (pageIndex:number,pageSize:number) => void = (<any>state.entities).loadPageFunction();
      state = {
        ...state,
        entities: createPageLoadingProxy<Calendar>(backingArray,25, loadPageFunction),
        error: null,
        loading: false
      };
      state.entities[index] = calendar;
    }
    return state;
  }),
  on(calendarUpdateFailureAction,(state, { calendar, reason, correlationId }) => {
    // restore previous media.
    // we have optimistic media updates => new media is already in the store
    // if server reports an error have to revert the media to its original version
    return calendarReducer(
      { ...state, error: reason, loading: false },
      calendarUpdateSuccessAction({ calendar, correlationId })
    );
  }),
  on(calendarDeleteSuccessAction,(state, { calendar }) => {
    let index: number = state.indices[calendar.id];
    if (index !== undefined) {
      let backingArray: Calendar[] = (<any>state.entities).backingArray;
      let loadPageFunction: (index: number, size: number) => void = (<any>state.entities).loadPageFunction();
      // TODO: remove from store!!!
      // For now the store is just reloaded - refer to CalendarService.delete()
      state = {
        ...state,
        entities: createPageLoadingProxy<Calendar>(backingArray,25, loadPageFunction),
        error: null,
        selectedIndex: state.selectedIndex==index ? undefined : state.selectedIndex
      };
      state.entities[index] = calendar;
    }
    return state;
  }),
  on(calendarDeleteFailureAction,(state, { calendar, reason }) => {
    return { ...state, error: reason }
  }),
  on(calendarSubscribeAction,(state, action) => {
    let subscribed = state.subscriptions[action.id];
    if (!subscribed) {
      let subscriptions = { ...state.subscriptions };
      let index  = state.indices[action.id];
      let entity = action.calendar = isValidNumber(index) ? state.entities[index] : undefined;
      subscriptions[action.id] = {
        subscribed: 1,
        entity:entity
      };
      return {
        ...state,
        subscriptions
      }
    } else {
      action.calendar = subscribed.entity;
      subscribed.subscribed++;
    }
    return state;
  }),
  on(calendarUnsubscribeAction,(state, action) => {
    let subscribed = state.subscriptions[action.id];
    action.calendar = subscribed?.entity;
    if (subscribed?.subscribed>1) {
      subscribed.subscribed--;
    } else if (!!subscribed) {
      let subscriptions = { ...state.subscriptions };
      delete subscriptions[action.id];
      return {
        ...state,
        subscriptions
      }
    }
    return state;
  })
);

export const selectCalendarsState         = createSelector(selectCalendarState, state => state.calendars);
export const selectCalendarEntities       = createSelector(selectCalendarsState, state => state.entities);
export const selectCalendarEntitiesLength = createSelector(selectCalendarEntities, state => state.length);
export const selectCalendarFilters        = createSelector(selectCalendarsState, state => state.filters);
export const selectCalendarSearchTerm     = createSelector(selectCalendarsState, state => state.term);
export const selectCalendarCacheId        = createSelector(selectCalendarsState, state => state.currentCacheId);

export const selectCalendar = (calendarId: string) => createSelector(
  selectCalendarsState,
  (state: CalendarsState): Calendar => {
    let index = state.indices[calendarId];
    return index >= 0 && index < state.entities.length ? state.entities[index] : null;
  }
);

export const selectCalendarsSubscribed = createSelector(
  selectCalendarsState,
  state => Object
    .keys(state.subscriptions)
    .map(id=><VersionedId>{
      id:id,
      version:state.subscriptions[id]?.entity?.version??0
    })
);
