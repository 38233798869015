import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {delay, distinctUntilChanged, filter, first, map, switchMap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import * as fromShared from "shared";
import {FilterDefinition, FilterDefinitions, FilterSection, MenuSelection} from "shared";
import {EventService} from "calendar";
import {MediaService} from "media";
import {APP_ID, ENVIRONMENT, FilterTypes, RouteReusableStrategyHandler, Topic} from "core";
import {AuthenticationService} from "auth";
import {PropertiesService} from "properties";
import {SettingsService} from "./settings.service";
import {MetricsService} from "./metrics.service";
import {ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot, Routes, UrlTree,} from "@angular/router";
import {ContactService, ConversationHook} from "contact";
import uniq from "lodash/uniq";
import {ChatService} from "chat";
import {LayoutService, SidenavMode} from "layout";
import {ConferenceService} from "conference";
// import {loadRemoteModule} from '@angular-architects/module-federation';
import cloneDeep from 'lodash/cloneDeep';
import * as fromFilter from 'filter';
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import {BadgeService} from "common/lib/services/badge.service";
import {TaskService} from "tasks";


@Injectable({
  providedIn: 'root'
})
export class MenuService extends fromShared.MenuService {

  constructor(protected translateService: TranslateService,
              protected propertiesService: PropertiesService,
              protected authenticationService: AuthenticationService,
              protected mediaService: MediaService,
              protected chatService: ChatService,
              protected conferenceService: ConferenceService,
              protected eventService: EventService,
              protected taskService: TaskService,
              protected contactService: ContactService,
              protected settingsService: SettingsService,
              protected metricsService: MetricsService,
              protected layoutService: LayoutService,
              protected filterService: fromFilter.FilterService,
              protected router: Router,
              protected routeReusableStrategyHandler: RouteReusableStrategyHandler,
              protected badgeService: BadgeService,
              @Inject(APP_ID) protected appId: number,
              @Inject(ENVIRONMENT) protected environment: any) {
    super(filterService, router, appId);
    console.log('MenuService.ctor');
    /*
    (<any>this.router).oldNavigate = this.router.navigate;
    (<any>this.router).oldNavigateByUrl = this.router.navigateByUrl;

    //console.log("REPLACE",prevNavigate);
    //console.log("REPLACE",prevNavigateByUrl);

    this.router.navigate = (commands: any[], extras?: NavigationExtras): Promise<boolean> => {
      console.trace("NAVIGATE.navigate",commands);
      return (<any>this.router).oldNavigate(commands,extras);
    };

    this.router.navigateByUrl = (url: string | UrlTree, extras?: NavigationBehaviorOptions): Promise<boolean> => {
      console.trace("NAVIGATE.navigateByUrl",url);
      return (<any>this.router).oldNavigateByUrl(url,extras);
    };
     */

    let chatHook:ConversationHook = {
      canConnect$(contactId?: string): Promise<boolean> {
        return Promise.resolve(true);
      },
      connect: (contactId?:string):Promise<void> => {
        if (!!contactId) {
          return new Promise((resolve,reject)=>{
            this.chatService.createOrUpdateDirectConversation(contactId)
              .then(conversationId=>{
                if (this.menuSelection?.section=='chat') {
                  resolve();
                } else {
                  combineLatest([
                    this.layoutService.navigation.state$,
                    this.layoutService.details.state$
                  ]).pipe(
                    first(),
                    switchMap(([navigationState,detailsState])=>{
                      let navigationOk = navigationState.mode!=SidenavMode.OVER || (!navigationState.open && !navigationState.animation);
                      let detailsOk    = detailsState.mode!=SidenavMode.OVER || (!detailsState.open && !detailsState.animation);
                      if (navigationOk && detailsOk) {
                        return of(true);
                      } else {
                        if (!navigationOk) this.layoutService.navigation.close();
                        if (!detailsOk) this.layoutService.details.close();
                        return combineLatest([
                          this.layoutService.navigation.state$,
                          this.layoutService.details.state$
                        ]).pipe(
                          filter(([navigationState,detailsState])=>{
                            return (navigationOk || (!navigationState.open && !navigationState.animation)) &&
                              (detailsOk || (!detailsState.open && !detailsState.animation));
                          }),
                          map(state=>true),
                          first()
                        )
                      }
                    })).subscribe(done=> {
                      if (!!conversationId) {
                        this.chatService.setCurrentConversationId(conversationId)
                          .then(id=> {
                            //console.log("CREATED.conversationId.2",conversationId,"current",this.chatService.currentConversationId);
                            this.router.navigate([`chat/${conversationId}`], { replaceUrl: true})
                              .then(()=>resolve());
                          })
                          .catch(reject);
                      } else {
                        this.router.navigate(['chat'], { replaceUrl: true})
                          .then(()=>resolve())
                          .catch(reject);
                      }
                    });
                }
              })
              .catch(reject);
          });
        } else {
          // TODO: open hosts conversation....
          Promise.reject("not yet implemented");
        }
      },
      disconnect: (contactId?:string):Promise<void> => Promise.resolve(),
      state$: (contactId?:string):Observable<string> => {
        return chatService.getConversationData$(chatService.getDirectConversationId(contactId)).pipe(
          map(data=>data?.id)
        );
      }
    };
    let callHook:ConversationHook = {
      canConnect$(contactId?: string): Promise<boolean> {
        return Promise.resolve(true);
      },
      connect: (contactId?:string):Promise<void> => {
        return new Promise((resolve,reject)=>{
          // TODO: call hosts... no contactId
          this.conferenceService.getConversationConferenceInfo$(chatService.getDirectConversationId(contactId))
            .pipe(first())
            .subscribe(call => {
              if (!!call) {
                this.conferenceService.pickUpConference(call.conferenceId)
                  .then(()=>resolve())
                  .catch(reject);
              } else {
                this.chatService.createOrUpdateDirectConversation(contactId)
                  .then(conversationId=>{
                    this.conferenceService.startConference('audio',[contactId],conversationId)
                      .then(()=>resolve())
                      .catch(reject);
                  })
                  .catch(reject);
              }
            });
        });
      },
      disconnect: (contactId?:string):Promise<void> => {
        return new Promise((resolve,reject)=>{
          this.conferenceService.getConversationConferenceInfo$(chatService.getDirectConversationId(contactId))
            .pipe(first())
            .subscribe(call => {
              if (!!call) {
                this.conferenceService.hangUpConference(call.conferenceId)
                  .then(()=>resolve())
                  .catch(reject);
              } else {
                reject("no open connection");
              }
            });
        });
      },
      state$: (contactId?:string):Observable<string> => {
        return this.conferenceService.getConversationConferenceInfo$(chatService.getDirectConversationId(contactId))
          .pipe(
            map(call=>{
              let userId = this.chatService.userId;
              return !!call
                ? !!call.ringing.find(p=>p.id==userId)
                  ? 'in'
                  : !!call.paused.find(p=>p.id==userId)
                    ? 'paused'
                    : 'out'
                : undefined
            }));
      }
    };

    let surveyFilters$ = new BehaviorSubject<string[]>(undefined);
    combineLatest([
      this.propertiesService.properties$,
      this.mediaService.getCombinedFilters$((type,filters)=>type!=FilterTypes.NAVIGATION),
      this.eventService.getCombinedFilters$((type,filters)=>type!=FilterTypes.NAVIGATION),
      this.taskService.getCombinedFilters$((type,filters)=>type!=FilterTypes.NAVIGATION),
      this.contactService.getCombinedFilters$((type,filters)=>type!=FilterTypes.NAVIGATION),
      this.chatService.filters$,
      this.settingsService.filters$,
      this.metricsService.filters$,
      this.update$,
    ]).pipe(
      filter(([properties, mediaFilters, calendarFilters, taskFilters, contactFilters, chatFilters, settingsFilters, metricsFilters, update]) => {
        if (!properties.user.isAuthenticated) {
          this.menuSelectionNext(MenuSelection.EMPTY);
          this.filterSections$.next(<FilterSection[]>[]);
          return false;
        }
        if (isArray(mediaFilters) &&
          isArray(calendarFilters) &&
          isArray(taskFilters) &&
          isArray(contactFilters) &&
          isArray(chatFilters) &&
          isArray(settingsFilters) &&
          isArray(metricsFilters)) {
          return true;
        }
        return false;
      }),
      distinctUntilChanged((a,b)=>{
        /*console.log("distinctUntilChanged",a,b,isEqual(a[0].user,b[0].user),
          isEqual(a[1],b[1]),
          isEqual(a[2],b[2]),
          isEqual(a[3],b[3]),
          isEqual(a[4],b[4]),
          isEqual(a[5],b[5]));*/
        return isEqual(a[0].user,b[0].user) &&
          isEqual(a[1],b[1]) &&
          isEqual(a[2],b[2]) &&
          isEqual(a[3],b[3]) &&
          isEqual(a[4],b[4]) &&
          isEqual(a[5],b[5])
      }),
      map(([properties, mediaFilters, calendarFilters, taskFilters, contactFilters, chatFilters, settingsFilters, metricsFilters]) => {
        console.debug("updateMenu",mediaFilters,contactFilters,calendarFilters,taskFilters,chatFilters,settingsFilters,metricsFilters);
        //console.log("XY.mediaFilters",mediaFilters,"contactFilters",contactFilters,"settingsFilters",settingsFilters);
        const user = properties.user;
        const topics: Topic[] = user?.app?.topics || [];
        // NOTE: mfe1 is an artificial topic for testing purposes only!
        if (this.environment.mfe && !topics.find(topic => topic.id == 'mfe1')) {
          topics.push({
            id: 'mfe1',
            label: 'MFE1',
            abbreviation: undefined,
            icon: 'settings_applications',
            visible: true,
            remoteModule: {
              // For Loading
              remoteEntry: 'http://localhost:3000/remoteEntry.js',
              remoteName: 'mfe1',
              exposedModule: './Module',
              // For Routing
              displayName: 'MFE1',
              routePath: 'mfe1',
              ngModuleName: 'FlightsModule'
            }
          });
        }

        this.contactService.chatHook = !!topics.find(topic=>topic.id=='chat') ? chatHook : undefined;
        this.contactService.callHook = callHook; // TODO: maybe no right to call...

        // ensure /project/intro navigation can always be handled
        // because it is the fallback routing path - see MediaService.canActivateChild()
        let projectTopic: Topic = topics.find(topic => topic.id=='project');
        if (!projectTopic) {
          projectTopic = {
            id: "project",
            icon: "ondemand_video",
            defaults: ["project", "project.intro"],
            label: "Project",
            abbreviation: "Project",
            topics: []
          };
          topics.unshift(projectTopic);
        }
        if (projectTopic.topics?.every(topic => topic.id!='intro')) {
          projectTopic.topics.unshift({
            id: "intro",
            filter: "project.intro",
            label: "media.filters.project.intro"
          });
        }

        const filterDefinitionsOf : (sectionId: string) => FilterDefinitions = (sectionId) => {
          return this.filterSections.find(section => section.id==sectionId)?.filters;
        };

        // const currentAppVersionUpdateBadge$ = this.badges['settings#app']?.badge$;
        // let appVersionUpdateBadge$: Observable<Badge> = undefined;
        const surveyFilterDefinitons = filterDefinitionsOf('survey');
        if (!!surveyFilterDefinitons) {
          surveyFilterDefinitons.selected = surveyFilters$.value;
        }
        const filterDefinitions: (topic: Topic, index: number) => FilterDefinitions = (topic: Topic, index) => {
          let result = undefined;
          const filterTopics = !!topic.topics && topic.topics.length>0 ? topic.topics : undefined;
          if (filterTopics?.length) {
            let updateFilters: (filters: string[]) => void =
              topic.id=='settings' ? (filters:string[])=>this.settingsService.updateFilter(filters) :
              topic.id=='events' || topic.id=='mfe1' ? (filters:string[])=>this.eventService.setTypedFilters({[FilterTypes.NAVIGATION]:filters}) :
              topic.id=='tasks' ? (filters:string[])=>this.taskService.setTypedFilters({[FilterTypes.NAVIGATION]:filters}) :
              topic.id=='chat' ? (filters:string[])=>this.chatService.updateFilter(filters)    :
              topic.id=='team' ? (filters:string[])=>this.contactService.setTypedFilters({[FilterTypes.NAVIGATION]:filters}) :
              topic.id=='metrics' ? (filters:string[])=>this.metricsService.updateFilter(filters) :
              topic.id=='survey' ? (filters:string[])=>surveyFilters$.next(filters?.length>0 ? filters : undefined) :
              (filters:string[])=>this.mediaService.setTypedFilters({[FilterTypes.NAVIGATION]:filters});
            let filters =
              topic.id=='settings' ? settingsFilters :
              topic.id=='events'  ? calendarFilters :
              topic.id=='tasks'  ? taskFilters :
              topic.id=='chat'    ? chatFilters     :
              topic.id=='team'    ? contactFilters  :
              topic.id=='metrics' ? metricsFilters  :
              topic.id=='survey'  ? surveyFilters$.value : mediaFilters;
            const allFilters = filterTopics?.map((filterTopic, filterIndex) => {
              //console.log(index, topics.length, '/', filterIndex, filterTopics.length);
              // if (topic.id=='settings' && filterTopic.id=='app') {
              //   if (!currentAppVersionUpdateBadge$) {
              //     appVersionUpdateBadge$ = this.badgeService.appVersionBadge$;
              //   }
              // }
              return <FilterDefinition> {
                id: filterTopic.id,
                imageId: filterTopic.icon,
                label: filterTopic.label,
                filter: filterTopic.filter,
                visible: filterTopic.visible!=false,
                enabled: filterTopic.enabled!=false,
                editable: filterTopic.editable,
                preselected: filterTopic.preselected==true,
                tags: filterTopic.tags || []
              }
            });
            const selectedFilters = this.menuSelection.section==topic.id
              // will be good if the FilterSection.selected contains only the filters which it can handle,
              // but applying this now break the third level filters in media filter sidenav (they should be refactored accordingly).
              ? filters//.filter(filter => allFilters.find(filterDefinition => `${topic.id}.${filterDefinition.id}`==filter))
              : filterDefinitionsOf(topic.id)?.selected;
            // this.logger.debug('selectedFilters', topic.id, selectedFilters);
            result = {
              all: allFilters,
              default: topic.defaults || [],
              selected: selectedFilters,
              updateFilters: updateFilters
            }
          }
          // if (index == topics.length-1 &&
          //     !currentAppVersionUpdateBadge$ &&
          //     currentAppVersionUpdateBadge$!=appVersionUpdateBadge$) {
          //   this.getBadgeObservable$('settings#app'); // ensure registration in badges
          //   this.setBadgeObservable$('settings#app', appVersionUpdateBadge$);
          // }
          return result;
        };

        const filterSections: FilterSection[] = [];
        const routes = cloneDeep(this.router.config);
        const parentRoute = routes.find(route => route.path=='' && route.component)
          ?.children
          ?.find(child => child.path=='' && !child.redirectTo && !child.outlet)
        let resetConfig = false;
        //this.logger.debug('PARENT ROUTE', parentRoute);
        topics.forEach((topic, index) => {
          // angular-architects/module-federation-plugin is currently not compatible with angular 17
          // produces  compile time errors.
          const moduleFederationPluginAvailable = false;
          if (moduleFederationPluginAvailable && topic.remoteModule && !parentRoute?.children?.find(route => route.path == topic.id)) {
            const remoteRoute = {
              path: topic.id,
              data: { cacheId: `${topic.remoteModule.ngModuleName}_${topic.remoteModule.remoteName}`},
              // loadChildren: () => loadRemoteModule(topic.remoteModule).then(remoteModule => {
              //   this.logger.debug('Loaded Remote Module', remoteModule, topic.remoteModule);
              //   return remoteModule[topic.remoteModule.ngModuleName]
              // })
            }
            if (!parentRoute.children) {
              parentRoute.children = [remoteRoute];
            } else {
              parentRoute.children.push(remoteRoute);
            }
            resetConfig = true;
          }
          filterSections.push({
            id: topic.id,
            title: topic.label,
            abbreviation: topic.abbreviation,
            icon: topic.icon,
            visible: topic.visible!=false,
            enabled: topic.enabled!=false,
            editable: topic.editable,
            preselected: topic.preselected==true,
            routable: true,
            submenu: topic.id=='chat',
            filters: filterDefinitions(topic, index)
          });
        });
        [
          {
            id: 'invite',
            title: undefined,
            abbreviation: undefined,
            filters: {
              all: [
              //   <FilterDefinition>{
              //   id: 'invite.intro',
              //   imageId: undefined,
              //   label: 'invite.intro',
              //   filter: 'intro',
              //   visible: false,
              //   enabled: true,
              //   editable: false,
              //   preselected: false,
              //   tags: []
              // }
              ],
              default: [],
              filterService: mediaService
            },
            icon: 'undefined',
            visible: false,
            routable: true
          },
          {
            id: 'groups',
            title: undefined,
            abbreviation: undefined,
            icon: 'undefined',
            visible: false,
            routable: true
          },
          {
            id: 'qrconnect',
            title: undefined,
            abbreviation: undefined,
            icon: 'undefined',
            visible: false,
            routable: true
          },
          {
            id: 'qrlogin',
            title: undefined,
            abbreviation: undefined,
            icon: 'undefined',
            visible: false,
            routable: true
          },
          {
            id: 'channels',
            title: 'project.channels',
            abbreviation: undefined,
            icon: 'undefined',
            visible: false,
            routable: true
          },
          {
            id: 'rules',
            title: undefined,
            abbreviation: undefined,
            icon: 'undefined',
            visible: false,
            routable: true
          },
          {
            id: 'start',
            title: undefined,
            abbreviation: undefined,
            icon: 'undefined',
            visible: false,
            routable: true
          },
          {
            id: 'survey',
            title: undefined,
            abbreviation: undefined,
            icon: 'undefined',
            visible: false,
            routable: true
          },
        ].forEach(filterSection => filterSections.push(filterSection as unknown as FilterSection));

        // wire all...
        let filterSectionMap: {[key:string]: FilterSection} = {};
        let filterTagsMap: {[key:string]: string[]} = {};
        let filterSelectionMap: {[key:string]: string[]} = {};
        // keep selections if possible
        this.filterSections$.value?.forEach(filterSection=> {
          if (filterSection.filters?.selected?.length>0) {
            filterSelectionMap[filterSection.id] = filterSection.filters.selected;
            //console.log("Y.selected.M.SELECTED",filterSection.filters?.selected,filterSelectionMap);
          }
        });
        //console.log("Y.selected.MAP",filterSelectionMap,this.filterSections$.value,filterSections);
        filterSections.forEach(filterSection => {
          filterSection.badge$ = this.getBadgeObservable$(filterSection.id);
          //console.log("FILTER_UNSEEN$",(<BehaviorSubject<number>>filterSection.unseen$).value);
          filterSectionMap[filterSection.id] = filterSection;
          filterSection.filters = filterSection.filters || { all: <FilterDefinition[]>[], default:[], selected:[] };
          if (filterSection.filters) {
            filterSection.filters.idToDefinition = {};
            filterSection.filters.filterToDefinition = {};
            if (filterSection.filters.all) {
              filterSection.filters.all.forEach(filterDefinition => {
                if (filterDefinition.tags?.length) {
                  filterTagsMap[`${filterSection.id}.${filterDefinition.id}`] = filterDefinition.tags;
                }
                filterDefinition.section = filterSection;
                filterDefinition.filter  = filterDefinition.filter ?? filterDefinition.id;
                filterSection.filters.idToDefinition[filterDefinition.id] = filterDefinition;
                filterSection.filters.filterToDefinition[filterDefinition.filter] = filterDefinition;
              });
            }
            const selected = filterSelectionMap[filterSection.id];
            if (!!selected && selected.every(id=>id==filterSection.id || !!filterSection.filters.idToDefinition[id])) {
              filterSection.filters.selected = selected;
            }
          }
        });
        // update selection if selection is not valid any more!
        let menuSelection    = this.menuSelection;
        let filterSectionId  = menuSelection ? menuSelection.section : undefined;
        let filterId         = menuSelection && menuSelection.filters && menuSelection.filters.length ? menuSelection.filters[0] : undefined;
        //console.debug("menuSelection",menuSelection,"filterSectionId",filterSectionId,"filterId",filterId);
        if (filterSections.length>0) {
          let filterSection = filterSections.find(filterSection => filterSection.id==filterSectionId);
          if (filterSection) {
            if (filterSection.filters && filterSection.filters.all && filterSection.filters.all.length) {
              filterId = filterSection.filters.all.find(filter => filter.id==filterId && filter.visible && filter.enabled) ? filterId : filterSection.filters.all.map(filter => filter.visible && filter.enabled ? filter.id : undefined).find(filterId => !!filterId);
            } else {
              filterId = undefined;
            }
          } else {
            filterSectionId = filterSections[0].id;
            filterId        = filterSections[0].filters && filterSections[0].filters.all && filterSections[0].filters.all.length ? filterSections[0].filters.all.map(filter => filter.visible && filter.enabled ? filter.id : undefined).find(filterId => !!filterId) : undefined;
          }
        }
        this.filterSectionsMap$.next(filterSectionMap);
        this.filterTagsMap$.next(filterTagsMap);
        //console.log("FILTER SECTION MAP",filterSectionMap,"FILTERTAGS",filterTagsMap);
        //console.debug("created filterSections",filterSections);
        return [filterSections, routes, resetConfig];
      }),
      delay(0) // this.delayFirst()
    ).subscribe(([filterSections, routes, resetConfig]: [FilterSection[], Routes, boolean]) => {
      //console.log("Y.selected.1",filterSections,routes,resetConfig,this.router.url);
      //console.debug("showed filterSections",filterSections);
      this.filterSections$.next(filterSections);
      // NOTE: after visiting a route which specifies loadChildren function i.e. has lazy loaded target component
      // the following isEqual comparison starts to return false regardless of the fact that routes are deepClone of the config
      // to avoid this false negative result we use resetConfig flag to signal an actual change in the routes
      if (resetConfig /*!isEqual(this.router.config, routes)*/) {
        // const diff = (o1, o2) => {
        //   return transform(o1, (result, value, key) => {
        //     if (!isEqual(value, o2[key])) {
        //       result[key] = (isObject(value) && isObject(o2[key])) ? diff(value, o2[key]) : value;
        //     }
        //   });
        // };
        // window.setTimeout(() => {
          this.logger.debug('ROUTER -> RESET CONFIG', {
            routes,
            config: this.router.config,
            // diff: diff(routes, this.router.config )
          });
          this.router.resetConfig(routes);
          // this.routeReusableStrategyHandler.reset();
          this.select(this.router.url);
        // })
      } else {
        this.select(this.router.url);
      }
    });

    combineLatest([
      this.filterSections$,
      this.menuSelection$
    ]).pipe(
      filter(([sections,selection]) => this.propertiesService.user.isAuthenticated),
      map(([sections,selection]) => {
        // let translate = this.injector.get(TranslateService);
        // translate.get('APP_NAME').subscribe(x => console.debug(x));
        const breadcrumb: string[] = [];
        //console.log("GROUP_SELECTION",sections,selection);
        if (selection.section) {
          //breadcrumb.push(`module.${selection.section}`);
          const media   = selection.section==='project' || selection.section==='training';
          const section = media ? 'media' : selection.section;
          const filterSection = this.filterSections.find(fs => fs.id==selection.section);
          //console.log("SELECTION",filterSection);
          if (filterSection?.title) {
            breadcrumb.push(filterSection.title);
          } else {
            breadcrumb.push(`module.${selection.section}`);
          }
          for (let i = 0, max = selection.filters.length; i < max; i++) {
            let filter = selection.filters[i] || '';
            if (filterSection && filterSection.filters.idToDefinition[filter]) {
              breadcrumb.push(filterSection.filters.idToDefinition[filter].label);
            } else if (filter) {  // should never happen
              breadcrumb.push(`${section}.filters.${filter}`);
            }
          }
          //console.debug("MenuService.selection",breadcrumb);
        }
        // console.debug("SELECTION",selection,breadcrumb);
        return uniq(breadcrumb);
      }))
      .subscribe(breadcrumb => {
        //console.log("breadcrumb",breadcrumb);
        this.breadcrumb$.next(breadcrumb);
      });
    this.router.events
      .pipe(
        filter(event => this.propertiesService.user.isAuthenticated && event instanceof NavigationEnd),
        //tap(event => console.log("this.router.events",event)),
        map(event => (<NavigationEnd>event).urlAfterRedirects),
        //  distinct(),
        //tap(urlAfterRedirects => console.log("UNIQUE",urlAfterRedirects))
      ).subscribe(urlAfterRedirects => this.select(urlAfterRedirects));
    this.chatService.currentConversationId$
      //.pipe(distinct())
      .subscribe(currentConversationId => {
        const selection = MenuSelection.fromPath(this.router.url);
        //console.log("CONVERSATION",currentConversationId,selection);
        if (selection?.section=='chat') {
          this.router.navigate([currentConversationId ? `chat/${currentConversationId}` : 'chat'], { replaceUrl: true});
        }
      });
  }

  sectionUrl(filterSection: FilterSection): string {
    if (filterSection.id=='chat') {
      if (!!this.chatService.currentConversationId) {
        return `/${filterSection.id}/${this.chatService.currentConversationId}`;
      }
      //return '/chat';
    }
    return super.sectionUrl(filterSection);
  }

  canActivateChild(child: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): boolean | Observable<boolean | UrlTree> {
    //console.log("MAIN.canActivateChild.1",state.url,"length",this.filterSections$.value.length);
    // if (state.url=='/chat/default' &&
    //     this.filterSections$.value.length) {
    //   let chatSection = this.filterSections$.value.find(section=>section.id=='chat');
    //   //console.log("MAIN.canActivateChild.2",state.url,"chatSection",chatSection);
    //   if (chatSection) {
    //     let urlTree = this.router.parseUrl(this.sectionUrl(chatSection));
    //     //console.log("MAIN.canActivateChild.2",state.url,"urlTree",urlTree);
    //     return of(urlTree);
    //   }
    // }
    return super.canActivateChild(child,state);
  }

  select(path: string, force = false) : void {
    //console.trace("SELECT",path);
    const selection = MenuSelection.fromPath(path);
    if (selection?.section=='chat' &&
      selection.filters?.length>0 &&
      selection.filters[0]!='default') {
      this.chatService.setCurrentConversationId(selection.filters[0]);
    }
    super.select(path, force);
  }
}
