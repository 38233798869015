import {
  Injectable,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  NgModuleFactory,
  Optional,
  SkipSelf,
  Type,
} from '@angular/core';
import {APP_MODULE_LOADER, APP_MODULE_PATH, BootstrapComponent} from "./bootstrap.component";
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from "@angular/platform-browser";
import {MatIconModule} from '@angular/material/icon';
import {AppInstallComponent} from "./components/app-install/app-install.component";
import {AppInstallOverlayComponent} from "./components/app-install/app-install-overlay.component";
import {AppInstallBottomSheetComponent} from "./components/app-install/app-install-bottom-sheet.component";
import {OverlayModule} from "@angular/cdk/overlay";
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {APP_ID, CONFIG, Config, CoreModule, ENVIRONMENT, secondLevelDomain} from "core";
import {createMissingTranslationHandler, createTranslateLoader, MISSING_TRANSLATE_HANDLER,} from "translation";
import {HttpClient} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatDialogModule} from "@angular/material/dialog";
// import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import {PropertiesModule} from "properties";
import {MatButtonModule} from "@angular/material/button";
import {BootstrapRetryComponent} from "./components/bootstrap-retry/bootstrap-retry.component";
import {StoreModule} from "store";
import {initialContactListState, initialContactUplineState} from "contact";
import {initialMediaListState, initialMediaOnboardingListState, MediaModule} from "media";
import {initialSessionState} from "auth";
import {initialAttendeesState, initialCalendarEventsState, initialCalendarsState} from "calendar";
import {initialChatState} from "chat";
import {SessionModule, SessionTokenService} from "session";
import moment from "moment";
import Hammer from "hammerjs";
import {WelcomeComponent} from './components/welcome/welcome.component';
import {WelcomeOverlayComponent} from "./components/welcome/welcome-overlay.component";
import {RouterModule} from "@angular/router";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {ScreenOrientation} from "@ionic-native/screen-orientation/ngx";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {InAppBrowser} from "@ionic-native/in-app-browser/ngx";
import * as fromShared from "shared";
import {MenuService} from "common/lib/services/menu.service";

// Dead code elimination:
// Secondary lib entry points: https://medium.com/angular-in-depth/improve-spa-performance-by-splitting-your-angular-libraries-in-multiple-chunks-8c68103692d0
// Lodash: https://www.azavea.com/blog/2019/03/07/lessons-on-tree-shaking-lodash/
//         https://github.com/lodash/lodash/issues/3192

// Moment: https://github.com/moment/moment/issues/3376

// New way for lazy loading (without router):
// https://medium.com/@matt.denobrega/for-those-of-you-who-implemented-this-and-are-getting-warnings-about-ngmodulefactoryloader-being-ae20ce1bca20

// Optimize angular builds: https://github.com/angular/angular-cli/issues/9016

// ISSUE: Angular cli - not removing dependencies of unused library modules / components
// https://github.com/angular/angular-cli/issues/14052

// Tree-shakable components (and NgModule deprecation):
// https://indepth.dev/angular-revisited-tree-shakable-components-and-optional-ngmodules/

// Source Map Explorer
// https://github.com/danvk/source-map-explorer/blob/master/README.md#generating-source-maps

//Ivy:
// https://dev.to/eugeniolentini/angular-ivy-a-detailed-introduction-oj1
// +HOC: https://medium.com/angular-in-depth/all-you-need-to-know-about-ivy-the-new-angular-engine-9cde471f42cf
// https://github.com/angular/angular/blob/master/packages/compiler/design/separate_compilation.md
// https://medium.com/angular-in-depth/inside-ivy-exploring-the-new-angular-compiler-ebf85141cee1
// https://indepth.dev/ivy-engine-in-angular-first-in-depth-look-at-compilation-runtime-and-change-detection/

// ViewEngine:
// https://medium.com/@immanubhardwaj/renderer2-angular-view-engine-d872498be1e6

// Angular Libs:
// https://blog.mgechev.com/2017/01/21/distributing-an-angular-library-aot-ngc-types/

// AOT:
// https://stackoverflow.com/questions/38932193/angular-2-4-5-ahead-of-time-compilation-how-to

// Import (es2015) vs require (nodejs style) (dynamic require is not tree-shakable)
// https://insights.untapt.com/webpack-import-require-and-you-3fd7f5ea93c0

const DEFAULT_MISSING_TRANSLATE_HANDLER = (key: string): string => {
  // when the translation is missing and the current lang is different than the default lang
  // the translated value for the same key in the default lang is returned to the caller.
  // when the default lang also does not provide a value the passed in key itself is returned.
  // in some cases this is not desirable e.g. when there is a logic which is based on the presence of translated value(s).
  // the corresponding keys for such cases are defined here.
  const paths = ['interests.help.', 'survey.*.help.*', 'purchase.stripeError.',
                 'app.links.googlePlay', 'app.links.appleStore',
                 'media.defaultName.', 'contact.validation.',
                 'app.name'];
  return paths.find(path => path.endsWith('.') && key.startsWith(path) || key==path || key.match(path)) ? '' : key;
};

export const TIMEZONE = new InjectionToken<string>('timezone', {
  providedIn: 'root',
  factory: () => moment.tz.guess()
});

export function apiEndpointInitializer(config: any) {
  // Promise is needed only when working with APP_INITIALIZER token
  // however we need the ENVIRONMENT value initialized earlier.
  // This function is now being used as a direct factory of the ENVIRONMENT value
  // and therefore the Promise related code is not needed
  // return (): Promise<any> => {
    // if the serverUrl value specified in environment is an object/map we reduce it to one string value depending on the current app domain
    // This is a workaround for ios Safari which does not respect SameSite.NONE cookie property leading to cross domain session management issues
    // when the domain which serves the app is different than the server api endpoint
    const environment = config.environment;
    if (typeof environment?.serverUrl=='object') {
      const appIdentifier   = secondLevelDomain();
      environment.serverUrl = Object.keys(environment.serverUrl)?.includes(appIdentifier)
        ? environment.serverUrl[appIdentifier]
        :  Object.values(environment.serverUrl)[0];
    }
    return environment;
    // return Promise.resolve(environment)
  // }
}

// Hammerjs must be configured in bootstrap module see:
// https://github.com/angular/angular/issues/19874
// https://github.com/angular/components/issues/8207
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  events: ['swipe','pinch','tap'];
  overrides = <any>{
    'swipe': { direction: Hammer.DIRECTION_ALL, enabled: true },
    'pinch': { enabled: true },
    'tap': { enabled: true }
  };
  options: {
    enable:true,
    domEvents:true
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatBottomSheetModule,
    OverlayModule,
    RouterModule.forRoot([], {
      // preloadingStrategy: PreloadAllModules,
      enableTracing: true,
      // prevent automatic initial navigation.
      // perform it imperatively by calling Router.initialNavigation() after properties/bootstrap initialization is done!
      initialNavigation: 'disabled' //false,
    }),
    CoreModule.forRoot(),
    SessionModule,
    StoreModule.forRoot({
      app: {
        contact:  {
          contactList: initialContactListState,
          contactUpline: initialContactUplineState
        },
        media: {
          mediaList: initialMediaListState,
          mediaOnboarding: initialMediaOnboardingListState
        },
        sessions: initialSessionState,
        calendar: {
          calendars: initialCalendarsState,
          event: initialCalendarEventsState,
          attendees: initialAttendeesState
        },
        chat: initialChatState,
        upload: []
      },
      group: {
        contact:  {
          contactList: initialContactListState,
          contactUpline: initialContactUplineState
        },
        media: {
          mediaList: initialMediaListState,
          mediaOnboarding: initialMediaOnboardingListState
        },
        sessions: initialSessionState,
        calendars: {
          calendars: initialCalendarsState,
          event: initialCalendarEventsState,
          attendees: initialAttendeesState
        },
        // no chat reset for group switching....
        upload: []
      }
    }),
    PropertiesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, APP_ID, SessionTokenService, TIMEZONE]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: (createMissingTranslationHandler),
        deps: [MISSING_TRANSLATE_HANDLER]
      },
      useDefaultLang: false,
      isolate: true
    }),
    MediaModule  // used for media store initialization
    // ShakableModule,
    // Shakable2Module,
  ],
  declarations: [
    BootstrapComponent,
    BootstrapRetryComponent,
    AppInstallComponent,
    AppInstallOverlayComponent,
    AppInstallBottomSheetComponent,
    WelcomeOverlayComponent,
    WelcomeComponent
  ],
  providers: [
    // ENVIRONMENT initialization will happen too late when provided here and
    // some DI objects/services referencing it in their constructors could see the raw value
    // For this reason ENVIRONMENT is initialized eagerly in BootstrapModule.create()
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: apiEndpointInitializer,
    //   deps: [ENVIRONMENT, AppIdentifierProvider],
    //   multi: true
    // },
    // { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    // SplashScreen,
    ScreenOrientation,
    StatusBar,
    InAppBrowser,
    //Menu Service is used by GroupService which has  providedIn: 'root'
    {
      provide: fromShared.MenuService,
      useFactory: (service: MenuService) => service,
      deps: [MenuService]
    }
  ]/*,
  entryComponents: [
    BootstrapComponent,
    BootstrapRetryComponent,
    AppInstallOverlayComponent,
    AppInstallBottomSheetComponent,
    WelcomeOverlayComponent,
    WelcomeComponent
  ],
  */,
  exports: [RouterModule]
  // bootstrap: [BootstrapComponent]
})
export class BootstrapModule {

  constructor(@Optional() @SkipSelf() parentModule: BootstrapModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import BootstrapModule only once!`);
    }
  }

  static create(config: Config, appModulePath: string,
                appModuleLoader?: () => Promise<(NgModuleFactory<any> | Type<any>)>,
                missingTranslateHandler = DEFAULT_MISSING_TRANSLATE_HANDLER): ModuleWithProviders<BootstrapModule> {
    return {
      ngModule: BootstrapModule,
      providers: [
        { provide: CONFIG, useValue: config },
        { provide: APP_MODULE_PATH, useValue: appModulePath },
        { provide: APP_MODULE_LOADER, useValue: appModuleLoader },
        { provide: ENVIRONMENT, useFactory: apiEndpointInitializer, deps: [CONFIG] },
        { provide: MISSING_TRANSLATE_HANDLER, useValue: missingTranslateHandler }
      ]
    };
  }
}
