import { Component, OnInit } from '@angular/core';
import {CalendarView, CalendarViews, CalendarViewService} from "../../services/calendar.view.service";

@Component({
  selector: 'calendar-view-switch',
  templateUrl: './calendar-view-switch.component.html',
  styleUrls: ['./calendar-view-switch.component.scss']
})
export class CalendarViewSwitchComponent implements OnInit {

  CalendarViews = CalendarViews;

  constructor(public calendarViewService: CalendarViewService) {
  }

  ngOnInit(): void {
  }

  onViewChange(view: CalendarView) {
    if (this.calendarViewService.view != view) {
      this.calendarViewService.view = view;
    }
  }
}
