import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';

import {ChatConversationComponent} from './components/conversation/chat-conversation.component';
import {ChatConversationContainerComponent} from './components/conversation-container/chat-conversation-container.component';
import {ChatConversationListComponent} from './components/conversation-list/chat-conversation-list.component';
import {ChatConversationListContainerComponent} from './components/conversation-list-container/chat-conversation-list-container.component';
import {ChatConversationSearchContainerComponent} from './components/conversation-search-container/chat-conversation-search-container.component';
import {ChatMediaLibraryContainerComponent} from './components/media-library-container/chat-media-library-container.component';
import {ChatTimelineMessageComponent} from './components/message/chat-timeline-message/chat-timeline-message.component';
import {ChatNoteMessageComponent} from './components/message/chat-note-message/chat-note-message.component';
import {ChatMessageRowComponent} from './components/message/chat-message-row/chat-message-row.component';
import {ChatMessageComponent} from './components/message/chat-message/chat-message.component';
import {ChatMessageDetailsContainerComponent} from './components/message-details-container/chat-message-details-container.component';
import {ChatMessageEditorComponent} from './components/message-editor/chat-message-editor.component';
import {ChatMainContainerComponent} from './components/main-container/chat-main-container.component';
import {ChatRoutingModule} from "./chat-routing.module";
import {ChatConversationDetailsContainerComponent} from './components/conversation-details-container/chat-conversation-details-container.component';
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "material";
import {SharedModule} from "shared";
import {FormsModule} from "@angular/forms";
import {ChatStoreModule} from "./store/module";
import {ChatMessageTextEditorComponent} from './components/message-editor/chat-message-text-editor.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MomentModule} from "ngx-moment";
import {ChatConversationItemComponent} from "./components/conversation-list/chat-conversation-item.component";
import {LayoutModule} from "layout";
import {SessionModule} from "session";
import {ChatConversationCreateContainerComponent} from "./components/conversation-create-container/chat-conversation-create-container.component";
import {ContactModule} from "contact";
import {MatDividerModule} from '@angular/material/divider';
import {PresenceModule} from "presence";
import {
  ChatConversationBarContainerComponent,
  ConversationCallBarComponent
} from './components/conversation-bar-container/chat-conversation-bar-container.component';
import {ChatService} from "./chat.service";
import {MediaModule} from "media";
import {MediaAttachmentComposerComponent} from "./components/attachment/media/composer/media-attachment-composer.component";
import {MediaAttachmentCoverViewerComponent} from "./components/attachment/media/viewer/media-attachment-cover-viewer.component";
import {MediaAttachmentFileViewerComponent} from "./components/attachment/media/viewer/media-attachment-file-viewer.component";
import {AttachmentSectionContainer} from "./components/attachment/core/attachment.section.container";
import {AttachmentSquareContainer} from "./components/attachment/core/attachment.square.container";
import {AudioRecordingAttachmentViewerComponent} from "./components/attachment/recording/viewer/audio-recording-attachment-viewer.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {GenericAttachmentViewerComponent} from "./components/attachment/core/generic.attachment.viewer.component";
import {MediaAttachmentSquareViewerComponent} from "./components/attachment/media/square/media-attachment-square-viewer.component";
import {AudioRecordingAttachmentSquareViewerComponent} from "./components/attachment/recording/square/audio-recording-attachment-square-viewer.component";
import {MessageDeliveryStateComponent} from "./components/message-delivery-state/message-delivery-state.component";
import {
  ChatMessageTypeSelectorComponent
} from "./components/message-editor/chat-message-type-selector.component";
import {
  ChatMessageContainerComponent
} from "./components/message/chat-message-container/chat-message-container.component";
import {ChatInfoMessageComponent} from "./components/message/chat-info-message/chat-info-message.component";
import {CallInfoMessageComponent} from "./components/message/call-info-message/call-info-message.component";
import {ChatMessageCoreComponent} from "./components/message/chat-message-container/chat-message-core.component";

export const PROVIDERS =  [
  // ChatService
]

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MaterialModule,
    MatDialogModule,
    FormsModule,
    SharedModule,
    PresenceModule,
    ChatStoreModule,
    ChatRoutingModule,
    MomentModule,
    LayoutModule,
    SessionModule,
    ContactModule,
    MediaModule,
    MatDividerModule,
    DragDropModule
  ],
  exports: [
    ChatConversationComponent,
    ChatConversationContainerComponent,
    ChatConversationItemComponent,
    ChatConversationListComponent,
    ChatConversationListContainerComponent,
    ChatConversationCreateContainerComponent,
    ChatConversationSearchContainerComponent,
    ChatMediaLibraryContainerComponent,
    ChatMessageRowComponent,
    ChatMessageContainerComponent,
    ChatMessageCoreComponent,
    ChatMessageComponent,
    ChatNoteMessageComponent,
    ChatInfoMessageComponent,
    CallInfoMessageComponent,
    ChatTimelineMessageComponent,
    ChatMessageDetailsContainerComponent,
    ChatMessageEditorComponent,
    ChatMessageTypeSelectorComponent,
    ChatMainContainerComponent,
    ChatConversationDetailsContainerComponent,
    GenericAttachmentViewerComponent,
    MediaAttachmentComposerComponent,
    MediaAttachmentCoverViewerComponent,
    MediaAttachmentFileViewerComponent,
    AudioRecordingAttachmentViewerComponent,
    AudioRecordingAttachmentSquareViewerComponent,
    AttachmentSectionContainer,
    AttachmentSquareContainer,
    MediaAttachmentSquareViewerComponent,
    MessageDeliveryStateComponent
  ],
  declarations: [
    ChatConversationComponent,
    ChatConversationContainerComponent,
    ChatConversationItemComponent,
    ChatConversationListComponent,
    ChatConversationListContainerComponent,
    ChatConversationCreateContainerComponent,
    ChatConversationSearchContainerComponent,
    ChatMediaLibraryContainerComponent,
    ChatMessageRowComponent,
    ChatMessageContainerComponent,
    ChatMessageCoreComponent,
    ChatMessageComponent,
    ChatNoteMessageComponent,
    ChatInfoMessageComponent,
    CallInfoMessageComponent,
    ChatTimelineMessageComponent,
    ChatMessageDetailsContainerComponent,
    ChatMessageEditorComponent,
    ChatMessageTypeSelectorComponent,
    ChatMainContainerComponent,
    ChatConversationDetailsContainerComponent,
    ChatMessageTextEditorComponent,
    ChatConversationBarContainerComponent,
    ConversationCallBarComponent,
    GenericAttachmentViewerComponent,
    MediaAttachmentComposerComponent,
    MediaAttachmentCoverViewerComponent,
    MediaAttachmentFileViewerComponent,
    AudioRecordingAttachmentViewerComponent,
    AudioRecordingAttachmentSquareViewerComponent,
    AttachmentSectionContainer,
    AttachmentSquareContainer,
    MediaAttachmentSquareViewerComponent,
    MessageDeliveryStateComponent
  ],
  providers: PROVIDERS
})
export class ChatModule {
  /*
  static forRoot(config: ChatModuleConfig): ModuleWithProviders<ChatModule> {
    return {
      ngModule: ChatModule,
      providers: [
        config.conferenceInfoProvider
      ]
    };
  }
  */
}
