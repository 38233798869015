import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, signal} from '@angular/core';
import {GroupMembership, Logger, Platform, Topic, ymdToDate} from 'core';
import {FilterTagEvent} from "filter";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {toObservable} from "@angular/core/rxjs-interop";
import {firstValueFrom, Subscription, switchMap, tap} from "rxjs";
import isEqual from "lodash/isEqual";
import moment from 'moment';
import {ContactService} from "../../contact.service";
import { MenuService } from 'shared';
import {first, map, withLatestFrom} from "rxjs/operators";
import {takeRight} from "lodash";

@Component({
  selector: 'partner-detail',
  templateUrl: './partner-detail.component.html',
  styleUrls: ['./partner-detail.component.scss']
})
export class PartnerDetailComponent implements OnInit {

  @Input() contactId: string;

  @Input()  set groupMembership(groupMembership: GroupMembership) {
    this.current.set(groupMembership);
    this.current().startDate = ymdToDate(groupMembership.ymd_started);
  }

  @Input() groupMembershipTypeTopic: Topic;

  @Output() onGroupMembershipChange = new EventEmitter<GroupMembership>();
  groupMembershipStartDateFilter = (moment: moment.Moment): boolean => true; //moment?.toDate().getTime() <= new Date().getTime();

  protected current = signal<GroupMembership & { startDate?: Date }>(undefined);
  protected current$= toObservable(this.current);
  protected subscription = new Subscription();

  protected logger = new Logger('PartnerDetailComponent');

  constructor(protected contactService: ContactService,
              protected menuService: MenuService,
              protected changeDetector: ChangeDetectorRef,
              protected platform: Platform) {
  }

  ngOnInit() {
    if (!this.groupMembershipTypeTopic && this.contactId) {
      const groupMembershipTypesTopic$ = this.menuService.menuSelection$
        .pipe(
          first(),
          switchMap(selection => {
            //TODO: is this the correct way to get the group membership type??
            const path = 'team.members'//'partner'; // `${selection.section}.${selection.filters[0]}`;
            return this.contactService.loadContextFilters$(this.contactId);//, path);
          }),
          map((filters) => {
            const topic = filters.filters.find(filter => filter.id=='level');
            topic.topics = topic.topics.filter(topic => topic.tags.includes('partner'));
            return topic;
          })
        );
      this.subscription.add(groupMembershipTypesTopic$
        .subscribe((topic) => {
          this.groupMembershipTypeTopic = topic;
          this.changeDetector.detectChanges();
        })
      );
    }
    this.subscription.add(this.current$
      // .pipe(map(groupMembership => ({...groupMembership})))
      .subscribe((groupMembership) => {
        if (groupMembership) {
          groupMembership.manual_partner_id = groupMembership.manual_partner_id || undefined;
        }
        this.onGroupMembershipChange.emit(groupMembership);
      })
    );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onPartnerIdChange(event: Event) {
    const partnerId = (event.target as any).value;
    if (partnerId!=this.current().manual_partner_id) {
      this.current.set({ ...this.current(), manual_partner_id: partnerId })
    }
  }

  onPartnerStartDateChange(event: MatDatepickerInputEvent<any, any>) {
    const moment = event.value;
    const startDate = moment.toDate();
    const current = { ...this.current() };
    if (startDate!=current.startDate) {
      const ymd = startDate
        ? startDate.getFullYear() * 10000 + (startDate.getMonth()+1) * 100 + startDate.getDate()
        : undefined;
      current.ymd_started = ymd;
      this.current.set(current);
    }
  }

  onGroupMembershipTypeChange(event: FilterTagEvent) {
    const membershipTypeId = event.filter?.id;
    let currentTags = [ ...this.current().tags ]
    const select = !currentTags.includes(membershipTypeId);
    currentTags = currentTags.filter(tag => !tag.startsWith(this.groupMembershipTypeTopic.id));
    if (select) {
      currentTags.splice(0, 0, ...[membershipTypeId]);
    }
    if (!isEqual(currentTags, this.current().tags)) {
      this.current.set({...this.current(), tags: currentTags });
    }
  }

  /*
  @Input()  partnerId: string = '';
  @Output() partnerIdChange = new EventEmitter<string>();


  editMode: boolean;
  previousPartnerId: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (!this.partnerId) {
      this.edit();
    }
  }

  edit() {
    this.editMode = true;
    window.setTimeout(() => {
      let input = this.elementRef.nativeElement.querySelector('.edit > input');
      input.focus();
      input.select();
    });
  }

  cancel() {
    this.partnerId = this.previousPartnerId;
    this.editMode = false;
  }

  save() {
    this.editMode = false;
    this.partnerId = (this.partnerId || '').trim();
    this.previousPartnerId = this.partnerId;
    this.partnerIdChange.emit(this.partnerId);
  }

  @HostListener('keyup', ['$event'])
  onKeyup(event: KeyboardEvent) {
    if (this.editMode) {
      if (event.key === 'Enter') {
        this.save();
        event.preventDefault();
      } else if(event.key === 'Escape') {
        this.cancel();
        event.preventDefault();
      }
    }
  }
 */
}
