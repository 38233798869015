import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject, Input,
  OnInit,
  Output
} from '@angular/core';
import {Observable} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {map} from "rxjs/operators";
import {ENVIRONMENT, Platform} from "core";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent implements OnInit {

  protected welcome$: Observable<SafeHtml>;
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  constructor(public platform: Platform,
              private translateService: TranslateService,
              private changeDetector: ChangeDetectorRef,
              private sanitizer: DomSanitizer,
              @Inject(ENVIRONMENT) private environment: any) {
  }

  ngOnInit(): void {
    this.welcome$ = this.translateService
      .stream('app.welcome')
      .pipe(map(text => this.sanitizer.bypassSecurityTrustHtml(text)));
  }

  get welcome(): Observable<SafeHtml> {
    return this.welcome$
  }
}
