import {CalendarsState} from "./calendar/reducers";
import {CalendarEventsState} from "./event/reducers";
import {AttendeesState} from "./attendee/reducers";
import {createFeatureSelector} from "@ngrx/store";

export const calendarFeatureKey = 'calendar';

export interface State {
  readonly [calendarFeatureKey]: CalendarState;
}

export interface CalendarState {
  readonly calendars: CalendarsState;
  readonly events: CalendarEventsState;
  readonly attendees: AttendeesState;
}

export const selectCalendarState = createFeatureSelector<CalendarState>(calendarFeatureKey);
