<ng-container [ngSwitch]="survey?.id">
  <ng-container *ngSwitchCase="'personality'">
    <personality-result
      [survey]="survey"
      [selectedTags]="selectedTags"
      [badgeData]="badgeData"
      [readOnly]="readOnly"
      [displayTag]="displayTag"
      [defaultInfo]="defaultInfo"
      [missingResultTemplate]="missingResultTemplate"
      [log]="log"
      (onDisplay)="display=$event"
      (onReady)="ready=$event">
    </personality-result>
  </ng-container>
  <ng-container *ngSwitchCase="'vitality'">
    <vitality-result
      [survey]="survey"
      [selectedTags]="selectedTags"
      [badgeData]="badgeData"
      [readOnly]="readOnly"
      [displayTag]="displayTag"
      [defaultInfo]="defaultInfo"
      [missingResultTemplate]="missingResultTemplate"
      [log]="log"
      (onDisplay)="display=$event"
      (onReady)="ready=$event">
    </vitality-result>
  </ng-container>
</ng-container>
<ng-template #missingResultTemplate let-result='result'>
  <div class="missing-result accent">
    <mat-icon>warning</mat-icon>
    <span translate>survey.result.missing</span>
  </div>
</ng-template>
