/* projects/chat/src/lib/components/conversation-list-container/chat-conversation-list-container.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  min-height: 100%;
  width: 100%;
}
:host > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: var(--color-primary);
  transform: translate3d(0, 0, 0);
}
:host > div > div.conversations {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transform: scale(1);
  transition: transform 300ms ease;
}
:host span.title {
  overflow: hidden;
  text-overflow: ellipsis;
}
:host > div.createMode > div.conversations {
  transform: scale(0.96);
}
:host > div > div.create {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateY(100%);
  transition: transform 300ms ease;
}
:host > div.createMode > div.create {
  transform: translateY(0);
}
.create_menu_container {
  transition: height 300ms ease;
}
.create_menu_container > mat-nav-list {
  width: 100%;
  position: absolute;
  background-color: var(--color-primary);
  padding-top: 0;
}
.create_menu_container > mat-nav-list > mat-list-item {
  color: white;
}
.create_menu_container > mat-nav-list > mat-list-item mat-icon {
  vertical-align: middle;
}
.content {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.content > chat-conversation-list {
  flex: 1 1 auto;
  position: relative;
}
a {
  display: block;
  padding: 10px;
}
app-status-bar {
  z-index: 1;
  background-color: white;
}
app-status-bar presence-menu {
  flex: 1;
}
:host-context(.color-scheme-light.pure) > div {
  background-color: #ddd;
}
:host-context(.color-scheme-light.pure) app-search-bar {
  border-bottom-color: white;
}
:host-context(.color-scheme-light.pure) .create_menu_container {
  position: relative;
}
:host-context(.color-scheme-light.pure) .create_menu_container > mat-nav-list {
  background-color: white;
}
:host-context(.color-scheme-light.pure) .create_menu_container > mat-nav-list > mat-list-item {
  color: #333;
}
:host-context(.color-scheme-light.pure) .create_menu_container > mat-nav-list > mat-list-item mat-icon {
  color: #333;
}
:host-context(.color-scheme-light.pure) .create_menu_container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ddd;
}
:host-context(.color-scheme-dark) {
  background-color: #181818;
}
:host-context(.color-scheme-dark) > div {
  background-color: #181818;
}
:host-context(.color-scheme-dark) .content {
  background-color: #181818;
}
:host-context(.color-scheme-dark) app-status-bar {
  background-color: #282828;
}
:host-context(.color-scheme-dark) .create_menu_container > mat-nav-list {
  background-color: #282828;
}
:host-context(.color-scheme-dark) .create_menu_container > mat-nav-list > mat-list-item {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .create_menu_container > mat-nav-list > mat-list-item mat-icon {
  color: #AEAFB0;
}
/*# sourceMappingURL=chat-conversation-list-container.component-DCTTZCLM.css.map */
