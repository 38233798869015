/* projects/chat/src/lib/components/message-details-container/chat-message-details-container.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
:host > mat-toolbar {
  padding-left: 8px;
  padding-right: 4px;
  display: flex;
  flex-direction: row;
}
:host > mat-toolbar .from {
  flex: 1;
  overflow: hidden;
}
:host > mat-toolbar .from > div {
  text-overflow: ellipsis;
  overflow: hidden;
}
.source-date {
  color: white;
  font-size: 12.6px;
  line-height: 1em;
  font-weight: lighter;
}
slide-panel {
  flex: 1 1 auto;
}
slide-panel .panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.source-message {
  background-color: var(--color-primary);
  padding: 8px 8px 16px;
  color: white;
}
app-status-bar {
  display: flex;
  flex-direction: row;
  z-index: 1;
  padding-left: 0;
  padding-right: 0;
}
app-status-bar > div {
  display: flex;
  position: relative;
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
  flex: 1 1;
  justify-content: center;
  cursor: pointer;
}
app-status-bar > div > mat-icon {
  position: relative;
  left: -4px;
  width: 1em;
  height: 1em;
  font-size: unset;
  line-height: unset;
  color: gray;
}
app-status-bar > div.selected:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -5px;
  bottom: -5px;
  right: 0;
  background-color: #eee;
  border-top: 1px solid #ddd;
  z-index: -1;
}
app-status-bar > div.selected > mat-icon {
  color: var(--color-primary);
}
:host-context(.color-scheme-light.pure) mat-toolbar {
  border-bottom: none;
}
:host-context(.color-scheme-light.pure) .source-message {
  color: #333;
  background-color: white;
  border-bottom: 1px solid #ddd;
}
:host-context(.color-scheme-dark) .source-message {
  background-color: #282828;
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) app-status-bar {
  background-color: #282828;
}
:host-context(.color-scheme-dark) app-status-bar > div.selected:before {
  background-color: #444;
  border-color: #777;
}
:host-context(.color-scheme-dark) app-status-bar > div.selected > mat-icon {
  color: #E5E5E5;
}
/*# sourceMappingURL=chat-message-details-container.component-IT2QJVUX.css.map */
