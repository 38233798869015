/* projects/calendar/src/lib/components/calendar-list/calendar-list.component.scss */
:host {
  position: relative;
  display: flex;
  flex-direction: column;
}
:host[dense] mat-selection-list .mat-mdc-list-item {
  padding: 0;
}
:host[dense] mat-selection-list .mat-mdc-list-item ::ng-deep .mdc-list-item__start {
  margin: 0;
}
:host[dense] .controls {
  margin-right: 0 !important;
}
mat-selection-list {
  position: relative;
  flex: 1 1 auto;
  outline: none;
}
mat-selection-list .mat-mdc-list-option {
  height: auto;
  border-left: 5px solid transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
mat-selection-list:not(.multiple) .mat-mdc-list-item ::ng-deep .mdc-list-item__start .mdc-checkbox {
  display: none;
}
cdk-virtual-scroll-viewport {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.container {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: 48px;
}
.container .calendar {
  flex: 1;
  display: flex;
  align-items: center;
}
.container .controls {
  margin-right: 8px;
}
div.main-button-space {
  height: 76px;
}
/*# sourceMappingURL=calendar-list.component-VJE45MCE.css.map */
