import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ResizeService} from "shared";
import {MatInput} from "@angular/material/input";
import {MatFormField} from "@angular/material/form-field";

@Component({
  selector: 'chat-message-text-editor',
  templateUrl: './chat-message-text-editor.component.html',
  styleUrls: ['./chat-message-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageTextEditorComponent implements OnInit, AfterViewInit {

  @ViewChild('textfield', { read: MatFormField }) textfield: MatFormField;
  @ViewChild('textarea', { read: ElementRef }) textarea: ElementRef;
  @Output() focusEvent  = new EventEmitter<any>();
  @Output() blurEvent   = new EventEmitter<any>();
  @Output() sendEvent   = new EventEmitter<any>();
  @Output() changeEvent = new EventEmitter<string>();

  constructor(protected resizeService: ResizeService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  onKeydownEvent(event: KeyboardEvent): void {
    //console.debug("onKeydownEvent",event);
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendEvent.emit(event);
    }
    this.resizeService.triggerAsync();
  }

  protected timer:number = undefined;
  onChange(event: any) {
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(timer=>{
      this.resizeService.triggerAsync();
      this.changeEvent.emit(this.value);
    });
  }

  onFocus(event:any) : void {
    this.focusEvent.emit(event);
  }

  onBlur(event:any) : void {
    this.blurEvent.emit(event);
  }

  focus() : void {
    if (this.textarea) {
      this.textarea.nativeElement.focus();
    }
  }

  reset() : void {
    this.selection = {start:0,end:0};
    this.value     = null;
  }

  get selection() : { start:number, end:number } {
    if (this.textarea) {
      return {
        start:this.textarea.nativeElement.selectionStart,
        end:this.textarea.nativeElement.selectionEnd
      };
    }
    return { start:0, end:0 };
  }

  set selection(selection : { start:number, end:number }) {
    const value = this.value;
    if (value &&
      selection &&
      selection.start<=selection.end &&
      selection.start>=0 &&
      selection.end<=value.length) {
      this.textarea.nativeElement.setSelectionRange(selection.start,selection.end);
    }
  }

  get value() : string | null {
    if (this.textfield?._control) {
      return this.textfield?._control.value;
    }
    return null;
  }

  set value(value : string | null) {
    if (this.textfield?._control) {
      this.textfield._control.value = value || null;
      //this.textarea.nativeElement.value = value || null;
      this.resizeService.triggerAsync();
    }
  }
}
