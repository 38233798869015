import {Component, ComponentFactoryResolver, ElementRef, Inject, INJECTOR, Injector, Input} from '@angular/core';
import {EMPTY_ARRAY, ENVIRONMENT, User} from "core";
import {Attachment} from "messaging";
import {BasicContainerComponent} from "shared";
import {ChatTimelineMessage, RenderMode, RenderPosition, TRANSLATE_TAG} from "../../../store/models";
import {MessageSpace} from "../../conversation/chat-conversation.component";
import {AuthenticationService} from "auth";
import {AttachmentComponentFactoryService} from "../../attachment/attachment.component.factory.service";
import {AttachmentSection} from "../../attachment/core/attachment.section";
import {ChatService} from "../../../chat.service";
import {LayoutService} from "layout";
import {
  ChatMessageDetailsContainerComponent,
  ChatMessageDetailsContext
} from "../../message-details-container/chat-message-details-container.component";
import {BehaviorSubject, Observable} from "rxjs";

@Component({
  selector: 'chat-message-core',
  template: '',
  styles: [],
})
export class ChatMessageCoreComponent extends BasicContainerComponent {
  protected _message:ChatTimelineMessage;
  protected _index:number;
  protected _total:number;
  protected _user:User;
  protected _header:boolean;
  protected _parent:boolean;
  protected _space:MessageSpace;
  protected _mode:RenderMode = 'bubble';
  protected _defaultReplyMessage$ = new BehaviorSubject<ChatTimelineMessage>(undefined);

  public mine:boolean = false;
  public translate:boolean = false;

  protected _calcMinWidthPercent:(width:number)=>number = (width) => undefined;

  constructor(public authenticationService: AuthenticationService,
              public elementRef: ElementRef,
              public chatService: ChatService,
              public layoutService:LayoutService,
              public attachmentService: AttachmentComponentFactoryService,
              public resolver: ComponentFactoryResolver,
              @Inject(INJECTOR) public injector:Injector,
              @Inject(ENVIRONMENT) public environment: any) {
    super();
    this._user = this.authenticationService.user;
    //console.log("ChatMessageComponent.ctor",this.instanceId);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.updateSpace();
  }

  updateSections(attachmentSections:AttachmentSection[]) {
    this._calcMinWidthPercent = (width)=> {
      let percent:number = undefined;
      attachmentSections?.forEach(section=>{
        const component = section.component?.instance;
        const space = component?.space;
        const attachments = component?.attachments;
        if (!!space && attachments?.length>0) {
          //console.log("ATTACHMENTS",attachments,"\nSPACE",space);
          percent = percent ?? 50;
          if (attachments.length==1) {
            if (space.fixedRatio && space.width>=640) {
              percent = Math.max(percent,space.ratio>=1 ? 80 : 60);
            } else if (attachments[0].type=='audioRecording') {
              percent = 40;
            }
          } else if (attachments.length>1 && space.fixedRatio && space.width>=640) {
            percent = Math.max(percent,space.ratio>=1 ? 90 : 80);
          }
        }
      });
      return percent;
    };
    this.updateSpace();
  }

  updateSpace() {
    if (!!this.elementRef?.nativeElement) {
      let container:HTMLElement = this.elementRef?.nativeElement;
      while (!!container && !container.classList.contains('positioned-container')) {
        container = container.parentElement;
      }
      if (!!container && !!container.parentElement) {
        const width = container.parentElement.offsetWidth;
        const minPercent = this._calcMinWidthPercent(width);
        //console.log("CALCULATED.minPercent",minPercent,"of",width);
        container.style.minWidth = !!minPercent ? minPercent.toFixed(0)+'%' : null;
      }
    }
  }

  @Input()
  set message(message: ChatTimelineMessage) {
    this._message = message;
    this.translate = !!message?.tags?.includes(TRANSLATE_TAG);
    this.mine = this.chatService.isUserMessage(message);
  }

  get message():ChatTimelineMessage {
    return this._message;
  }

  @Input()
  set mode(mode: RenderMode) {
    this._mode = mode;
  }

  get mode():RenderMode {
    return this._mode;
  }

  @Input()
  set space(space: MessageSpace) {
    this._space = space;
    //console.log("SPACE.2",space);
  }

  get space():MessageSpace {
    return this._space;
  }

  get attachments():Attachment[] {
    //console.log("ChatMessageComponent",this.instanceId,"\ngetAttachments",this._message?.attachments,this._message);
    return this._message?.attachments || EMPTY_ARRAY;
  }

  get messageBody() {
    return (this._message?.bodyText || '').replace(/^\n+|\n+$/g,'').replace(/\n/g,'<br/>');
  }

  @Input()
  set index(index:number) {
    this._index = index;
  }

  get index():number {
    return this._index;
  }

  @Input()
  set total(total:number) {
    this._total = total;
  }

  get total():number {
    return this._total;
  }

  get position():RenderPosition {
    return 'center';
  }

  get user():User {
    return this._user;
  }

  @Input()
  set header(header:boolean) {
    this._header = header;
  }

  get header():boolean {
    return !!this._header;
  }

  @Input()
  set parent(parent:boolean) {
    this._parent = parent;
  }

  get parent():boolean {
    return !!this._parent;
  }

  openMessageReplyInfos(message:ChatTimelineMessage) {
    if (!!message?.conversationId) {
      this.layoutService.details.setContent('component', {
        type: ChatMessageDetailsContainerComponent,
        context: <ChatMessageDetailsContext>{
          message: message,
          view: 'replies'
        }
      });
      this.layoutService.details.ensure(true);
    }
  }

  replyMessage$(conversationId:string,messageId:string):Observable<ChatTimelineMessage> {
    if (!!conversationId && !!messageId) {
      return this.chatService.getTimelineMessage$(conversationId,messageId);
    }
    return this._defaultReplyMessage$;
  }
}
