import {createAction, props} from "@ngrx/store";
import {
  ConferenceHangUpMessage,
  ConferenceInfo,
  ConferenceInviteMessage,
  ConferenceMediaMessage,
  ConferencePauseMessage,
  ConferencePickUpMessage,
  ConferenceStateMessage,
  ConferenceSynchronizeStatesMessage
} from "./models";

export const showErrorAction = createAction(
  'conference.error',
  props<{
    readonly errorLabel: string         // error label if not ok
  }>()
);

/*
  CONFERENCE ACTIONS
 */

export const conferenceSynchronizeStatesAction = createAction(
  'conference.synchronize.states',
  props<{
    message?: ConferenceSynchronizeStatesMessage,
    synchronized: boolean}>()
);

export const conferenceInviteMessageSendAction = createAction(
  'conference.invite.message.send',
  props<{
    readonly message: ConferenceInviteMessage,
    conference?:ConferenceInfo}>()
);

export const conferenceStateMessageReceiveAction = createAction(
  'conference.state.message.receive',
  props<{
    readonly message: ConferenceStateMessage,
    readonly userId: string}>()
);

export const conferencePickUpMessageAction = createAction(
  'conference.pickup.message.send',
  props<{
    readonly message: ConferencePickUpMessage,
    readonly userId: string,
    readonly send: boolean,
    conference?:ConferenceInfo}>()
);

export const conferenceMediaMessageAction = createAction(
  'conference.media.message.send',
  props<{
    readonly message: ConferenceMediaMessage,
    readonly userId: string,
    readonly send: boolean,
    conference?:ConferenceInfo}>()
);

export const conferencePauseMessageAction = createAction(
  'conference.pause.message.send',
  props<{
    readonly message: ConferencePauseMessage,
    readonly userId: string,
    readonly send: boolean,
    conference?:ConferenceInfo}>()
);

export const conferenceHangUpMessageAction = createAction(
  'conference.hangup.message.send',
  props<{
    readonly message: ConferenceHangUpMessage,
    readonly userId: string,
    send: boolean,
    conference?:ConferenceInfo}>()
);

export const conferenceTouchAction = createAction(
  'conference.touch',
  props<{
    readonly conferenceId: string,
    readonly participantId?: string}>()
);

/*
  STATE
 */

export const channelOpenStateAction = createAction(
  'conference.channel.open.state',
  props<{
    readonly open: boolean,
    readonly connectionId: string,
  }>()
);

export const setErrorAction = createAction(
  'conference.error.set',
  props<{
    readonly label?: string,
    readonly params: {[key:string]:any}
  }>()
);
