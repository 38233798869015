import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  BasicContainerComponent,
  ResizeEvent,
  VirtualRenderEvent,
  VirtualScrollEvent,
  VirtualSectionScrollerComponent
} from "shared";
import {EMPTY_ARRAY} from "core";
import {Message} from "messaging";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ChatService, FilteredList} from "../../chat.service";
import {ChatTimelineMessage, MessageTimeUpdated} from "../../store/models";

export interface MessageSpace {
  id?:string,
  name?:string,
  before:boolean,
  after:boolean
}

export interface MessageViewport {
  offset:number;
  total:number;
  items:ChatTimelineMessage[];
  render:ChatTimelineMessage[];
  indices:Set<number>;
  spaces:MessageSpace[];    // space needed before, after or both or for name....
  trackBy:string[];
}

@Component({
  selector: 'chat-conversation',
  templateUrl: './chat-conversation.component.html',
  styleUrls: ['./chat-conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationComponent extends BasicContainerComponent implements OnChanges {
  @Input()
  public set conversationId(conversationId:string) {
    //console.log("ChatConversationComponent.conversationId",conversationId);
    this.conversationId$.next(conversationId);
  }
  public get conversationId():string {
    return this.conversationId$.value;
  }
  @Input()
  public set parentId(messageId:string) {
    this.parentId$.next(messageId);
  }
  public get parentId():string {
    return this.parentId$.value;
  }
  @Input()
  public set predicate(value:(ChatTimelineMessage)=>boolean) {
    this.filterPredicate = value;
  }
  public get predicate():(ChatTimelineMessage)=>boolean {
    return this.filterPredicate;
  }
  @Input() startFromTop: boolean = false;
  @Input() searchTerm: string;
  @Input() filters: string[];

  @Input() sectionHeaderTemplateRef: TemplateRef<any>;

  @ViewChild('sectionHeaderTemplate', { static: true })
  sectionHeaderTemplate: TemplateRef<any>;
  @ViewChild('scroller', {read: VirtualSectionScrollerComponent})
  scrollerComponent: VirtualSectionScrollerComponent;

  conversationId$ = new BehaviorSubject<string>(undefined);
  parentId$ = new BehaviorSubject<string>(undefined);
  timelineMessageSections$ = new BehaviorSubject<number[]>([]);
  timelineMessages$ = new BehaviorSubject([]);
  timeLastViewed:number = 0;
  timelineFilteredList:FilteredList<ChatTimelineMessage>;
  timelineMessageSubscription:Subscription;
  timelineMessageSectionSubscription:Subscription;
  viewport$ = new BehaviorSubject<MessageViewport>({ offset:0, total:0, items:[], render:[], indices:new Set(), spaces:[], trackBy:[]})
  showDownButton = false;
  filterPredicate:(ChatTimelineMessage)=>boolean = undefined;

  previousTrackBy:{[key:string]:string} = {};

  //_pageInfo: IPageInfo;

  constructor(
    public elementRef: ElementRef,
    public chatService: ChatService,
    public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.sectionHeaderTemplateRef = this.sectionHeaderTemplateRef ?? this.sectionHeaderTemplate;
    /*
    (<any>this.sectionHeaderTemplateRef).hugo = this.sectionHeaderTemplateRef.createEmbeddedView;
    this.sectionHeaderTemplateRef.createEmbeddedView = (context:any,p2?:any)=> {
      console.log("createEmbeddedView",context,p2);
      const view:EmbeddedViewRef<any> = (<any>this.sectionHeaderTemplateRef).hugo(context);
      view.detach();
      console.log("createEmbeddedView.done",view);
      return view;
    };*/
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("ngOnChanges()",changes);
    let searchTermChanges = changes['searchTerm'];
    if (searchTermChanges) {
      //console.log("ngOnChanges() searchTerm",searchTermChanges,this.searchTerm);
      this.timelineFilteredList?.updateSearchTerm(searchTermChanges.currentValue);
    }
    let filtersChanges = changes['filtersChanges'];
    if (filtersChanges) {
      //console.log("ngOnChanges() filters",filtersChanges,this.filters);
      this.timelineFilteredList?.updateFilters(filtersChanges.currentValue);
    }
    let conversionIdChange = changes['conversationId'];
    let parentIdChange = changes['parentId'];
    if (conversionIdChange || parentIdChange) {
      //console.log("ngOnChanges() conversationId",conversionIdChange,this.conversationId);
      this.timelineFilteredList?.unsubscribe();
      this.timelineFilteredList = !!this.conversationId
        ? this.chatService.getConversationMessages(this.conversationId,this.parentId,this.predicate)
        : undefined;
      this.timelineFilteredList?.updateSearchTerm(this.searchTerm);
      this.timelineFilteredList?.updateFilters(this.filters);
      this.timeLastViewed = 0;
      this.timelineMessageSubscription?.unsubscribe();
      this.timelineMessageSubscription = (this.timelineFilteredList?.list$ ?? of([]))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(list => {
          if (this.log) {
            //console.log("UPDATE.MESSAGES",list.length,"\n",list);
            console.log("x:CONVERSATION.list",this.conversationId,list,"\nsize",list.length);
            //list?.forEach((message,index)=>{
            //  console.log("x:list.message",index,"id",message.id,message.bodyText,message);
            //});
          }
          this.timelineMessages$.next(list)
        });
      this.timelineMessageSectionSubscription?.unsubscribe();
      this.timelineMessageSectionSubscription = (this.timelineFilteredList?.sections$ ?? of([]))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(list => {
          //console.log("UPDATE.SECTIONS");
          this.timelineMessageSections$.next(list)
        });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.timelineFilteredList?.unsubscribe();
  }

  onResize(event:ResizeEvent) {
    //if (this.scrollerComponent) {
    //  this.scrollerComponent.refresh();
    //}
  }

  onScrolled(event:VirtualScrollEvent) {
    const showDownButton = (event.scrollSize-event.scrollEndPosition)>50;
    if (showDownButton != this.showDownButton) {
      this.showDownButton = showDownButton;
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
    let messages:ChatTimelineMessage[] = this.timelineMessages$.value;
    let timeLastViewed:number = 0;
    //console.log("xx:scroll",event.visibleEndIndex<messages.length,event.visibleStartIndex,event.visibleEndIndex,messages);
    if (!!messages && event.visibleEndIndex<messages.length) {
      const userId = this.chatService.userId;
      for (let i=event.visibleEndIndex; i>=event.visibleStartIndex; i--) {
        const message = messages[i];
        // we do not trigger our own messages...
        // thus we never would trigger private messages, nor pending messages
        if (message.from?.id!=userId) {
          timeLastViewed = Math.max(timeLastViewed,MessageTimeUpdated(message,0));
        }
      }
    }
    //console.log("onScrolled",event,"showDownButton",showDownButton,"messages",messages,"visibleStart",event.visibleStartIndex,"visibleEnd",event.visibleEndIndex,"prevLastTimeViewed",this.timeLastViewed,"timeLastViewed",timeLastViewed,"trigger",(this.timeLastViewed<timeLastViewed));
    //console.log("xx:triggerDeliveryMessage.scroll",this.timeLastViewed<timeLastViewed,"old",this.timeLastViewed,"new",timeLastViewed,"diff",timeLastViewed-this.timeLastViewed);
    if (this.timeLastViewed<timeLastViewed) {
      this.timeLastViewed = timeLastViewed;
      //console.log("LAST_VIEWED",new Date(timeLastViewed));
      this.chatService.triggerTimeViewed(this.conversationId,timeLastViewed);
    }
    //console.log("onScroll",event,"start",event.scrollStartPosition,"end",event.scrollEndPosition,"max",event.scrollMaxPosition,"total",event.scrollSize,"show",showDownButton);
  }

  scrollToBottom(animationMilliseconds:number = 0) {
    const count = this.timelineMessages$.value?.length || 0;
    if (count>0) {
      //this.scrollerComponent.scrollToIndex(count-1, Alignment.end, 0);
      this.scrollerComponent.scrollToEnd(animationMilliseconds);
    }
  }

  onRendered(event:VirtualRenderEvent) {
    //console.log("onRendered index",event.renderedIndex,"count",event.renderedCount,"size",event.renderedSize);
  }

  trackMessage(index:number, message:Message) : string {
    return message.id;
  }

  hasDateHeader(index:number, message:Message) : boolean {
    //let range = this.scrollViewport.requestedRangeStream.value;
    //return range?.start==index || (index%10)==0;
    return false;
  }

  hasSectionHeader(message:Message,viewportOffset:number):boolean {
    return true;
  }

  public itemHeaderElementAccessor = (e:Element):Element => {
    return e?e.firstElementChild:undefined;
  }

  spaces(messages:ChatTimelineMessage[],messageBefore:Message,messageAfter:Message):MessageSpace[] {
    const length = messages?.length ?? 0;
    const userId = this.chatService.userId;
    const spaces = new Array<MessageSpace>(length);
    let   lastId = messageBefore?.from?.id;
    for (let i=0, last=length-1; i<=last; i++) {
      const nextId = i==last ? messageAfter?.from?.id : messages[i+1].from?.id
      const status = this.chatService.isStatusMessage(messages[i]);
      const from   = messages[i]?.from;
      const id     = from?.id;
      const name   = !id ? undefined : from?.name ?? '?';
      //const show = !!id && id!=userId && id!=lastId;
      const show   = !!id && ((id!=userId && id!=lastId) || status);
      const space  = spaces[i] = {
        id:show?id:undefined,
        name:show?name:undefined,
        before:lastId!=id,
        after:nextId!=id
      };
      //console.log("@",i,"message",messages[i],"space",space);
      //lastId = id;
      lastId = status ? undefined : id;
    }
    //console.log("SPACES",spaces);
    return spaces;
  }

  // protected _scrollToBottomTrigger:number;
  // onViewportUpdated(event:{current:Viewport,previous:Viewport}) {
  //   if (!!event.current && event.current.itemCount>1 &&
  //       event.current.scrollSize > event.current.viewportSize &&
  //       event.current.itemCount > (event.previous?.itemCount ?? 0)) {
  //     if (this._scrollToBottomTrigger) {
  //       window.clearTimeout(this._scrollToBottomTrigger);
  //     }
  //     this._scrollToBottomTrigger = window.setTimeout(()=>{
  //       this._scrollToBottomTrigger = undefined;
  //       this.scrollToBottom(200);
  //     },100);
  //     //this.scrollerComponent.scrollToIndex(event.current.itemCount-1,Alignment.end,200);
  //     /*console.log("UPDATED.VIEWPORT",event.current.itemCount,"was",event.previous?.itemCount,
  //       "\nvisibleEndIndex",event.current.visibleEndIndex,
  //       "\nbufferedEndIndex",event.current.bufferedEndIndex);
  //     */
  //   }
  // }

  onViewportChanged() {
    const items:ChatTimelineMessage[] = this.scrollerComponent.items ?? EMPTY_ARRAY;
    const render:ChatTimelineMessage[] = this.scrollerComponent.viewportItems ?? EMPTY_ARRAY;
    const offset:number = this.scrollerComponent.viewportOffset ?? 0;
    const total:number = this.scrollerComponent.items?.length ?? 0;
    let viewport = this.viewport$.value;
    if (viewport.render!==render || viewport.total!=total || viewport.offset!=offset) {
      viewport = { offset, total, render, items,
        indices: viewport.indices,
        spaces: this.spaces(render,
          offset<=0?undefined:items[offset-1],
          (offset+render.length)>=items.length?undefined:items[offset+render.length]),
        trackBy: new Array(items.length)
      };
      viewport.indices.clear();
      this.scrollerComponent.viewportSectionIndexes?.forEach(index=>viewport.indices.add(index));
      if (this.log) {
        console.log("x:CONVERSATION.viewport",this.conversationId,"\noffset",offset,"size",render.length);
      }
      viewport.render.forEach((viewportItem,viewportIndex)=>{
        const message   = viewportItem;
        const space     = viewport.spaces[viewportIndex];
        const isSection = viewport.indices.has(viewportIndex+offset);
        viewport.trackBy[viewportIndex] = `${message?.id}.${message?.timeUpdated}.${isSection}.${space?.id}:${space?.before}:${space?.after}`;
        if (this.log) {
          //const previousTrackBy = this.previousTrackBy[message.id];
          //const currentTrackBy  = viewport.trackBy[viewportIndex];
          //this.previousTrackBy[message.id] = currentTrackBy;
          //console.log("x:viewport.message",viewportIndex,offset+viewportIndex,!!previousTrackBy ? previousTrackBy==currentTrackBy : 'new',"\ntrackBy",currentTrackBy,"\nid",message.id,message.bodyText,message);
        }
      });
      //console.log("x:viewport",viewport);
      this.viewport$.next(viewport);
    }
  }

  trackBy = (index: number, message: ChatTimelineMessage) => {
    return this.viewport$.value?.trackBy[index];
  };
}
