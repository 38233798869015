/* projects/interests/src/lib/components/survey/result/result.component.scss */
.missing-result {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  vertical-align: middle;
  border-radius: 4px;
  padding: 2px 8px;
  font:
    400 15px/24px Roboto,
    "Helvetica Neue",
    sans-seri;
  color: black;
  background-color: #e0e0e0;
}
.missing-result.accent {
  background-color: var(--color-accent);
  color: var(--color-accentContrast);
}
.missing-result > mat-icon {
  flex: none;
  height: 32px;
  width: 32px;
  min-width: 32px;
  font-size: 32px;
  line-height: 32px;
  margin-right: 8px;
}
/*# sourceMappingURL=result.component-RLNPOK4A.css.map */
