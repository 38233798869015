<app-contact-list [entities$]="entities$"
                  [cacheId$]="cacheId$"
                  [subscribe]="false"
                  [avatarTemplateRef]="avatarTemplate"
                  [contactInfoTemplateRef]="contactInfoTemplate"
                  [controlsTemplateRef]="controlsTemplate"
                  [itemMinSize]="60"
                  [avatarSize]="50"
                  [dividerLeftMargin]="74"
                  (selectionChanged)="onSelectionChanged($event)">
</app-contact-list>
<ng-template #avatarTemplate
             let-contact="contact"
             let-src="src"
             let-width="width"
             let-height="height"
             let-index="index"
             let-total="total"
             let-last="last">
  <img matListItemAvatar
       [src]="src | sessionLink"
       [style.width]="width"
       [style.height]="height"
       onload="this.style.opacity=1"/>
  <presence [contactId]="contact?.id"></presence>
</ng-template>
<ng-template #contactInfoTemplate
             let-attendee="entity"
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last">
<!--  <presence-info *ngIf="contact.id as contactId" [contactId]="contactId"></presence-info>-->
  <div *ngIf="attendee.seats?.online; let seatsOnline" class="seats">
    <mat-icon>connected_tv</mat-icon>
    <span>{{seatsOnline}}</span>
  </div>
  <div *ngIf="attendee.seats?.offline; let seatsOffline" class="seats">
    <mat-icon>event_seat</mat-icon>
    <span>{{seatsOffline}}</span>
  </div>
</ng-template>
<ng-template #controlsTemplate
             let-contact="contact"
             let-index="index"
             let-total="total"
             let-last="last">
<!--  <mat-icon *ngIf="(selection$ | async)?.index==index">check_circle</mat-icon>-->
</ng-template>
