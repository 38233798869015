import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  INJECTOR,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {BasicContainerComponent} from "shared";
import {EMPTY_ARRAY} from "core";
import {AttachmentSection} from "./attachment.section";
import {AttachmentComponent} from "./attachment.component";
import {Attachment} from "messaging";
import {Space} from "./space";
import {
  AttachmentComponentFactory,
  AttachmentComponentFactoryService
} from "../attachment.component.factory.service";
import isEqual from "lodash/isEqual";
import {ChatTimelineMessage} from "../../../store/models";

@Component({
  selector: 'attachment-section-container',
  template: '<div #sections></div>',
  styles: [`
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentSectionContainer extends BasicContainerComponent implements AttachmentComponent {

  @ViewChild('sections', {read: ViewContainerRef, static: true}) sections: ViewContainerRef;

  @Output()
  public attachmentSections: AttachmentSection[] = [];

  protected silent = false;
  protected _message: ChatTimelineMessage = undefined;
  protected _attachments: Attachment[] = EMPTY_ARRAY;
  protected _temporaryAttachments: Attachment[] = EMPTY_ARRAY;
  protected _attachmentTypes: string = undefined;
  protected _accessor: (type:string,attachment?:Attachment)=>AttachmentComponentFactory = undefined;
  protected _updated$:EventEmitter<AttachmentComponent>;
  protected _deleted$:EventEmitter<Attachment>;

  constructor(public elementRef: ElementRef,
              public attachmentService: AttachmentComponentFactoryService,
              public resolver: ComponentFactoryResolver,
              @Inject(INJECTOR) public injector:Injector) {
    super();
  }

  @Output()
  get updated():EventEmitter<AttachmentComponent> {
    this._updated$ = this._updated$ ?? new EventEmitter();
    return this._updated$;
  }

  @Output()
  get deleted():EventEmitter<Attachment> {
    this._deleted$ = this._deleted$ ?? new EventEmitter();
    return this._deleted$;
  }

  @Input()
  set message(message:ChatTimelineMessage) {
    this._message = message;
    const tempAttachments = message?.attachments??EMPTY_ARRAY;
    //console.log("message",this.instanceId,message,"equals",this._temporaryAttachments===tempAttachments);
    if (this._temporaryAttachments===tempAttachments) {
      this.triggerAttachmentUpdate();
    }
  }
  get message():ChatTimelineMessage {
    return this._message;
  }

  @Input()
  set factoryAccessor(accessor:(type:string,attachment?:Attachment)=>AttachmentComponentFactory) {
    this._accessor = accessor;
    const tempAttachments = this.message?.attachments??EMPTY_ARRAY;
    //console.log("factoryAccessor",this.instanceId,!!accessor,"equals",this._temporaryAttachments===tempAttachments);
    if (this._temporaryAttachments===tempAttachments) {
      this.triggerAttachmentUpdate();
    }
  }
  get factoryAccessor():(type:string,attachment?:Attachment)=>AttachmentComponentFactory {
    return this._accessor ?? this.attachmentService.getViewerFactory;
  }

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    this._temporaryAttachments = attachments??EMPTY_ARRAY;
    const tempAttachments = this.message?.attachments??EMPTY_ARRAY;
    //console.log("attachments",this.instanceId,attachments,"equals",this._temporaryAttachments===tempAttachments);
    if (this._temporaryAttachments===tempAttachments) {
      this.triggerAttachmentUpdate();
    }
  }

  protected triggerAttachmentUpdate() {
    //const tempAttachments = this.message?.attachments??EMPTY_ARRAY;
    //console.log("triggerAttachmentUpdate",this.instanceId,this.message,"factory",!!this.factoryAccessor,"temp",tempAttachments===this._temporaryAttachments,"!equal",!_.isEqual(this._attachments, this._temporaryAttachments??EMPTY_ARRAY),this.attachments);
    if (!!this.message &&
        !!this.factoryAccessor &&
          this._temporaryAttachments===(this.message?.attachments??EMPTY_ARRAY) &&
        !isEqual(this._attachments, this._temporaryAttachments)) {
      this._attachments = this._temporaryAttachments;
      //console.log("triggerAttachmentUpdate",this.message,this.attachments);
      this.silent = true;
      if (this.log) console.log("ATTACHMENTS",this._attachments);
      const attachmentSections:AttachmentSection[] = [];
      const oldAttachmentSections = new Map<AttachmentComponentFactory,AttachmentSection>();
      const newAttachmentSections = new Map<AttachmentComponentFactory,AttachmentSection>();
      this.attachmentSections?.forEach(definition=>oldAttachmentSections.set(definition.factory,definition));
      this.attachments.forEach((attachment,index) => {
        const factory = this.factoryAccessor(attachment.type, attachment);
        if (!!factory) {
          if (!newAttachmentSections.has(factory)) {
            const sectionDefinition:AttachmentSection = {
              factory, attachments:[],
              component:oldAttachmentSections.get(factory)?.component
            };
            attachmentSections.push(sectionDefinition);
            newAttachmentSections.set(factory, sectionDefinition);
          }
          newAttachmentSections.get(factory).attachments.push(attachment);
        }
      });
      oldAttachmentSections?.forEach((sectionDefinition,factory)=>{
        if (!newAttachmentSections.has(factory)) {
          sectionDefinition.subscription?.unsubscribe();
          this.sections.remove(this.sections.indexOf(sectionDefinition.component.hostView));
          //sectionDefinition.component.destroy();
        }
      });
      attachmentSections.forEach((section,index)=>{
        if (!!section.component) {
          this.sections.move(section.component.hostView,index);
        } else {
          section.component = section.factory.createComponent(this.injector,this.resolver);
          const subscription1 = section.component.instance.updated?.subscribe(component=>this.onUpdated(component));
          const subscription2 = section.component.instance.deleted?.subscribe(attachment=>this.onDeleted(attachment));
          if (!!subscription1 && !!subscription2) {
            section.subscription = subscription1;
            section.subscription.add(subscription2);
          } else {
            section.subscription = subscription1 ?? subscription2;
          }
          this.sections.insert(section.component.hostView,index);
        }
        //console.log("ATTCHMENT.SECTION\n",section,"prev",section.component.instance.attachments,"curr",section.attachments);
        section.component.instance.message     = this.message;
        section.component.instance.attachments = section.attachments;
      });
      this.attachmentSections = attachmentSections;
      this.silent = false;
      this.updated.emit(this);
    }
  }

  get attachments(): Attachment[] {
    return this._attachments;
  }

  get space():Space|undefined {
    return undefined;
  }

  protected onUpdated(component:AttachmentComponent) {
    this._attachments = [];
    this.attachmentSections.forEach(section=>{
      const attachments = section.component?.instance?.attachments;
      if (attachments?.length>0) {
        this._attachments.push(...attachments);
      }
    });
    if (!this.silent) {
      this.updated.emit(this);
    }
  }

  protected onDeleted(attachment:Attachment) {
    if (!this.silent) {
      this.deleted.emit(attachment);
    }
  }
}
