/* projects/interests/src/lib/components/survey/result/personality/personality.result.component.scss */
:host {
  display: flex;
  justify-content: center;
}
:host > div.container {
  display: flex;
  flex-direction: column;
}
:host > div.container > div.chart {
  width: 260px;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1254901961);
  overflow: hidden;
  border-radius: 5%;
}
:host > div.container > div.chart > personality-colors {
  font-size: 260px;
}
:host > div.container > div.chart:before {
  content: "";
  display: inline-block;
  padding-top: 100%;
  width: 0;
}
:host > div.container > div.chart > div.info {
  position: absolute;
  left: 2%;
  top: 2%;
  bottom: 2%;
  right: 2%;
}
:host > div.container > div.chart > div.info:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(204, 204, 204, 0.2509803922);
}
:host > div.container > div.chart > div.info > div {
  position: absolute;
  width: 100%;
}
:host > div.container > div.chart > div.info > div > div {
  z-index: 10;
}
:host > div.container > div.chart > div.info.red {
  transform: rotate(-45deg);
}
:host > div.container > div.chart > div.info.red:before {
  transform: translateY(-98%);
}
:host > div.container > div.chart > div.info.red > div {
  top: 0;
  transform: translateY(-100%);
}
:host > div.container > div.chart > div.info.red > div > div {
  color: red;
  line-height: 1em;
  text-align: center;
}
:host > div.container > div.chart > div.info.red > div > div.label {
  font-size: 13px;
}
:host > div.container > div.chart > div.info.red > div > div.percent {
  font-size: 21px;
}
:host > div.container > div.chart > div.info.yellow {
  transform: rotate(45deg);
}
:host > div.container > div.chart > div.info.yellow:before {
  transform: translateY(-98%);
}
:host > div.container > div.chart > div.info.yellow > div {
  top: 0;
  transform: translateY(-100%);
}
:host > div.container > div.chart > div.info.yellow > div > div {
  color: #FEDD2C;
  line-height: 1em;
  text-align: center;
}
:host > div.container > div.chart > div.info.yellow > div > div.label {
  font-size: 13px;
}
:host > div.container > div.chart > div.info.yellow > div > div.percent {
  font-size: 21px;
}
:host > div.container > div.chart > div.info.green {
  transform: rotate(-45deg);
}
:host > div.container > div.chart > div.info.green:before {
  transform: translateY(98%);
}
:host > div.container > div.chart > div.info.green > div {
  bottom: 0;
  transform: translateY(100%);
}
:host > div.container > div.chart > div.info.green > div > div {
  color: #A5DB2E;
  line-height: 1em;
  text-align: center;
}
:host > div.container > div.chart > div.info.green > div > div.label {
  font-size: 13px;
}
:host > div.container > div.chart > div.info.green > div > div.percent {
  font-size: 21px;
}
:host > div.container > div.chart > div.info.blue {
  transform: rotate(45deg);
}
:host > div.container > div.chart > div.info.blue:before {
  transform: translateY(98%);
}
:host > div.container > div.chart > div.info.blue > div {
  bottom: 0;
  transform: translateY(100%);
}
:host > div.container > div.chart > div.info.blue > div > div {
  color: #0a84ff;
  line-height: 1em;
  text-align: center;
}
:host > div.container > div.chart > div.info.blue > div > div.label {
  font-size: 13px;
}
:host > div.container > div.chart > div.info.blue > div > div.percent {
  font-size: 21px;
}
:host > div.container > div.info {
  position: relative;
  margin-top: 8px;
  background-color: rgba(204, 204, 204, 0.2509803922);
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  overflow: hidden;
  max-width: 260px;
  border: 2px solid transparent;
  cursor: pointer;
}
:host > div.container > div.info:after {
  font-family: "Material Icons";
  content: "\e5c5";
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}
:host > div.container > div.info > div:first-child {
  font-size: 13.5px;
}
:host > div.container > div.info > div:first-child > span:first-child {
  padding-right: 6px;
}
:host > div.container > div.info > div:last-child {
  font-size: 13.5px;
}
:host > div.container > div.info > div:last-child::ng-deep > ul {
  padding-left: 16px;
}
:host > div.container > div.info.red {
  border-color: red;
}
:host > div.container > div.info.red > div:first-child > span:first-child {
  color: red;
}
:host > div.container > div.info.yellow {
  border-color: #FEDD2C;
}
:host > div.container > div.info.yellow > div:first-child > span:first-child {
  color: #FEDD2C;
}
:host > div.container > div.info.green {
  border-color: #A5DB2E;
}
:host > div.container > div.info.green > div:first-child > span:first-child {
  color: #A5DB2E;
}
:host > div.container > div.info.blue {
  border-color: #0a84ff;
}
:host > div.container > div.info.blue > div:first-child > span:first-child {
  color: #0a84ff;
}
/*# sourceMappingURL=personality.result.component-ZGS23VC5.css.map */
