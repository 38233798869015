/* projects/chat/src/lib/components/message/chat-note-message/chat-note-message.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: column;
  position: relative;
}
.bubble {
  border-radius: 2px;
  clip-path: polygon(0 0, calc(100% - 12px) 0, 100% 12px, 100% 100%, 0 100%);
}
.bubble.right > div.arrow::before {
  display: none;
}
.bubble.right {
  color: #444;
  background-color: #ffdd00;
}
.bubble::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 12px;
  height: 12px;
  border-left: 1px solid rgba(0, 0, 0, 0.1882352941);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1882352941);
  border-bottom-left-radius: 2px;
  background-color: #eecc00;
}
.header > div.reply {
  padding-bottom: 8px;
}
.reply .body {
  border-left: 2px solid var(--color-primary);
}
.right > .body > .content > span::ng-deep a {
  color: rgba(255, 255, 255, 0.8);
}
.right > .body > .content > span::ng-deep a:hover {
  color: white;
}
.bubble.right .reply .body {
  border-left-color: rgba(255, 255, 255, 0.5019607843);
}
:host-context(.color-scheme-light.pure) .bubble.right > .body > .content > span::ng-deep a {
  color: #777;
}
:host-context(.color-scheme-light.pure) .bubble.right > .body > .content > span::ng-deep a:hover {
  color: #444;
}
:host-context(.color-scheme-light.pure) .bubble.right {
  color: #444;
  background-color: #ffdd00;
}
:host-context(.color-scheme-dark) .bubble.right > .body > .content > span::ng-deep a {
  color: #777;
}
:host-context(.color-scheme-dark) .bubble.right > .body > .content > span::ng-deep a:hover {
  color: #444;
}
:host-context(.color-scheme-dark) .bubble.right {
  color: #444;
  background-color: #ffdd00;
}
/*# sourceMappingURL=chat-note-message.component-6B75SDNR.css.map */
