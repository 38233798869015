import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Contact, ENVIRONMENT, Logger} from 'core';
import {Product} from "../models/product";
import {PaymentMethod, StripePaymentService} from "./stripe-payment.service";
import {Subscription} from "rxjs";
import {PaymentServiceFactory} from "../service/payment-service.factory";
import {animate, style, transition, trigger} from "@angular/animations";
import {ResizeSensor} from 'css-element-queries'
import {map, scan, startWith} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import {default as _get} from "lodash/get";
import isEqual from "lodash/isEqual";
import reduce from "lodash/reduce";
import values from "lodash/map";
import mapValues from "lodash/mapValues";

/*
----------------------------------------------------------------------------------
Test cards
----------------------------------------------------------------------------------

https://stripe.com/docs/payments/payment-intents/quickstart#automatic-confirmation-flow

4000002760003184 - requires authentication
4242424242424242 - does not require authentication

https://stripe.com/docs/payments/payment-intents/quickstart#testing

NUMBER	          AUTHENTICATION	                        DESCRIPTION
4000002500003155	Required on setup or first transaction	This test card requires authentication for one-time payments. However, if you set up this card using the Setup Intents API and use the saved card for subsequent payments, no further authentication is needed.
4000002760003184	Required	                              This test card requires authentication on all transactions.
4000008260003178	Required	                              This test card requires authentication, but payments will be declined with an insufficient_funds failure code after successful authentication.
4000000000003055	Supported	                              This test card supports authentication via 3D Secure 2, but does not require it. Payments using this card do not require additional authentication in test mode unless your test mode Radar rules request authentication.

Simulate successful payment with Austrian credit card with 4000000400000008
https://stripe.com/docs/testing#international-cards
(use with Austrian VAT ID (e.g. ATU39687503) to opt-in for VAT breakdown in generated invoice)


----------------------------------------------------------------------------------
Test IBANs
----------------------------------------------------------------------------------

https://stripe.com/docs/sources/sepa-debit

DE89370400440532013000	The charge status transitions from pending to succeeded.
DE62370400440532013001	The charge status transitions from pending to failed.
DE35370400440532013002	The charge status transitions from pending to succeeded, but a dispute is immediately created.

AT611904300234573201	The charge status transitions from pending to succeeded.
AT861904300235473202	The charge status transitions from pending to failed.
AT591904300235473203	The charge status transitions from pending to succeeded, but a dispute is immediately created.
*/

const swissVatIdRegex = /^CHE(-|\s)?(?:\d{3}(?:.|s)){2}\d{3}\s(?:MWST|IVA|TVA)$/; // e.g. CHE-123.456.789 MWST

export function taxIdValidator(countryCodeAccessor: () => string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const taxId = control;
    const countryCode = countryCodeAccessor();
    if (countryCode=='CH' && taxId && taxId.value) {
      const match = taxId.value.match(swissVatIdRegex);
      if (!match) {
        return { invalidTaxId: 'invalidSwissTaxId' };
      }
    }
    return null;
  };
}

// export const taxIdValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
//   const taxId = control.get('taxId');
//   const address = control.get('address');
//   const countryCode = address && address.get('countryCode');
//   if (countryCode && countryCode.value=='CH' && taxId && taxId.value) {
//     const match = taxId.value.match(swissVatIdRegex);
//     if (!match) {
//       // taxId.setErrors({ invalidTaxId: 'invalidSwissTaxId' });
//       return { 'invalidTaxId': 'invalidSwissTaxId' };
//     }
//   }
//   return null;
// };

export class TaxIdErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid /*&& control.touched*/);
  }
}

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
  animations: [
    trigger('pageActivation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms ease-in', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('300ms ease-out', style({opacity: 0}))
      ])
    ]),
    trigger('fieldActivation', [
      transition(':enter', [
        style({maxHeight:0, overflow:'hidden', opacity: 0.5}),
        animate('300ms', style({maxHeight:'100px', opacity: 1}))
      ]),
      transition(':leave', [
        style({maxHeight:'100px', opacity: 1}),
        animate('300ms', style({maxHeight:0, opacity: 0}))
      ])
    ])
  ]
})
export class StripeComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input()  product: Product;
  @Input()  contact: Contact;
  @Output() paymentCompleted = new EventEmitter<any>();
  @ViewChild('stripeElementHost') stripeElementHost: ElementRef;
  @ViewChild('taxId') taxId: ElementRef;
  @ViewChildren('page') pages: QueryList<any>;
  @ContentChild(TemplateRef) controlsTemplate: TemplateRef<any>;

  stripeElement: stripe.elements.Element;
  stripeSource: stripe.Source;
  stripeElementError: string;
  stripeElementComplete = false;

  paymentError: any;
  taxIdError: boolean;
  taxIdErrorStateMatcher = new TaxIdErrorStateMatcher();

  paymentMethod: PaymentMethod;
  processing   = false;
  done         = false;

  form: FormGroup;
  countries: { code: string, name: string }[];
  // The component's size is dynamically adapted to the size of the hosted page.
  // In order to eliminate the resizing of the component once the first page is rendered
  // (which happens just a moment after the component itself is displayed and is annoying for the user eyes)
  // the component is initially hidden (because the first page displayed inside are not yet rendered and its dimension are unknown),
  // later once  the component is sized properly (after measuring the size of the current page) it is displayed by setting opacity = 1
  // (see ngAfterViewInit).
  opacity = 0;

  bankName: string; // for methods based on direct bank transfers e.g. SEPA

  protected sensors: ResizeSensor[] = [];
  protected stripeService: StripePaymentService;
  protected subscription: Subscription;

  protected logger = new Logger('StripeComponent');

  constructor(protected paymentServiceFactory: PaymentServiceFactory,
              protected renderer: Renderer2,
              protected formBuilder: FormBuilder,
              protected translateService: TranslateService,
              @Inject(ENVIRONMENT)
              protected readonly environment: any) {
  }

  ngOnInit() {
    this.subscription = new Subscription();
    this.stripeService = this.paymentServiceFactory.stripe();
    this.environment.production && this.onPaymentMethodSelected('creditCard'); // remove when sepa is ready!!
  }

  ngAfterViewInit(): void {
    const resetSensors = () => {
      this.sensors.forEach((sensor) => sensor.detach());
      this.sensors = [];
      this.pages.forEach((page, index) => {
        const pageElement = page.nativeElement;
        const sensor = new ResizeSensor(pageElement, (size) => {
          this.logger.debug('SIZE', size);
          if (size && size.height) {
            const parent = this.renderer.parentNode(pageElement);
            if (parent) {
              this.renderer.setStyle(parent, 'height', Math.max(0, size.height)+'px');
            }
          }
        });
        this.sensors.push(sensor);
      });
    };
    this.subscription.add(
      this.pages.changes
        .pipe(
          startWith(this.pages),
          scan((state: any, value: any) => {
            state.value = value;
            // initialize the payment form lazily once it is displayed for the first time.
            if (!state.initialized) {
              this.pages.find((page: ElementRef, index: number) => {
                const isPaymentForm = page.nativeElement.classList.contains('payment');
                if (isPaymentForm) {
                  this.initializePaymentForm(); // window.setTimeout(this.initializeForm.bind(this));
                  state.initialized = true;
                }
                return isPaymentForm;
              });
            }
            return state;
          }, {value: undefined, initialized: false}),
          map(state => state.value)
        )
        .subscribe((pages) => {
          console.debug('PAGES', pages);
          resetSensors();
        })
    );

    // show the component once it is properly sized (i.e. first page has been rendered)
    // timeout needed to avoid ExpressionChangedAfterItHasBeenCheckedError
    // (we are in change detection cycle currently)
    window.setTimeout(() => this.opacity = 1);
  }

  ngOnDestroy(): void {
    this.stripeElement && this.stripeService.releaseElement(this.stripeElement);
    this.sensors.forEach((sensor) => sensor.detach());
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.logger.debug('ngOnChanges', changes);
    const productId = changes.product.currentValue.id;
    const country = changes.contact.currentValue.country;
    if (productId && country) {
      this.stripeService.getTax(productId, country)
        .then()
        .catch((error: any) => { this.logger.error(error.description, error.messages); });
    }
  }

  onPaymentMethodSelected(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
    if (!this.form) {
      this.form = this.createForm(); // form group must be created before we show the payment form
      this.subscription.add(
        this.translateService.stream('countries')
          .subscribe(countries => {
            let language = this.translateService.currentLang;
            this.countries = values(mapValues(countries, (value, key) => {
              return { code: key, name: value };
            })).sort((a,b) => a.name.localeCompare(b.name,language));
          })
      );
    }
  }

  onPurchase(event: Event) {
    event.preventDefault();
    this.processing = true;
    this.stripeSource = null;
    new Promise<void>((resolve, reject) => {
      const taxId  = this.taxId.nativeElement.value;
      if (taxId) {
        this.stripeService.validateBusinessNumber(taxId)
          .then(() => resolve())
          .catch((error) => {
            this.taxIdError = true;
            this.form.get('taxId').setErrors({
              invalidTaxId: 'invalidTaxId'
            });
            window.setTimeout(() => console.log('ERRORS', this.form.errors), 1000);
            reject(error);
          })
      } else {
        resolve();
      }
    })
    .then(() =>
      //this.stripeService.purchase(this.product.id, this.card, this.taxId.nativeElement.value);
      this.stripeService.purchase(
        this.product.id,
        this.stripeElement,
        {
          ...this.form.value,
          // switched address arg type to object to circumvent an issue with reduce() overload resolution in lodash v4.17.15
          // address: reduce(this.form.value.address, (address: Address, value: any, key: string) => {
          address: reduce(this.form.value.address, (address: object, value: any, key: string) => {
            if (key=='countryCode') {
           // address.country_code = value;
              address['countryCode'] = value;
            } else {
              address[key] = value;
            }
            return address;
          }, {})
        },
        this.paymentMethod
      )
    )
    .then((result) =>  {
      const { source, response } = { ...result };
      console.debug('PURCHASE COMPLETED', source, response);
      this.done = !response.error;
      this.stripeSource = source;
      if (!this.done) {
        throw new Error(response.error);
      } else {
        this.paymentCompleted.emit();
      }
    })
    .catch((error) => {
      this.paymentError = error;
      // this.paymentError = error instanceof Error ? error.message
      // : typeof error == 'object'  // server reported error
      //   ? error.hasOwnProperty('code')      ? this.errorCodeKey(error.code)
      //     : error.hasOwnProperty('message') ? error.message
      //     : JSON.stringify(error)
      // : error;
      this.paymentCompleted.emit({ error: error });
    })
    .then(() => this.processing = false);
  }

  initializePaymentForm() {
    // this.subscription.add(
    //   fromEvent(
    //     this.taxId.nativeElement,
    //     'change'
    //   ).subscribe(change => {
    //     this.taxIdError = false;
    //     this.form.get('taxId').setErrors(null);
    //   })
    // );
    const promise = this.paymentMethod=='creditCard' ? this.stripeService.acquireCard()
                  : this.paymentMethod=='sepa' ? this.stripeService.acquireIban()
                  : null;
    if (promise) {
      promise.then((element) => {
        let subscription: Subscription;
        let error: any;
        element.mount(this.stripeElementHost.nativeElement);
        element.on('change', (event: any) => {
          console.debug('STRIPE ELEMENT CHANGE', event);
          this.stripeElementComplete = event.complete;
          if (!isEqual(error, event.error)) {
            error = event.error;
            if (subscription) {
              subscription.unsubscribe();
              this.subscription.remove(subscription);
            }
            if (event.error) {
              subscription = this.translateService
                .stream(`purchase.stripeError.${this.errorCodeKey(event.error.code)}`)
                .subscribe(value => {
                  this.stripeElementError = value || event.error.message;
                });
              this.subscription.add(subscription);
            } else {
              this.stripeElementError = null;
            }
          }
          // error type: validation_error
          // card-related stripe error codes: (see https://stripe.com/docs/error-codes):
          // {
          //     "invalid_number": "The card number is not a valid credit card number",
          //     "invalid_expiry_month": "The card’s expiration month is invalid",
          //     "invalid_expiry_year": "The card’s expiration year is invalid",
          //     "invalid_cvc": "The card’s security code is invalid",
          //     "incorrect_number": "The card number is incorrect",
          //     "incomplete_number": "The card number is incomplete",
          //     "incomplete_cvc": "The card’s security code is incomplete",
          //     "incomplete_expiry": "The card’s expiration date is incomplete",
          //     "expired_card": "The card has expired",
          //     "incorrect_cvc": "The card’s security code is incorrect",
          //     "incorrect_zip": "The card’s zip code failed validation",
          //     "invalid_expiry_year_past": "The card’s expiration year is in the past",
          //     "card_declined": "The card was declined",
          //     "missing": "There is no card on a customer that is being charged",
          //     "processing_error": "An error occurred while processing the card"
          // }
          this.bankName = event.bankName || undefined;  // Display bank name corresponding to IBAN, if available.
        });
        element.on('blur', (event: any) => {
          console.debug('STRIPE ELEMENT BLUR', event);
          if (!this.form.touched) {
            this.form.markAsTouched();
          }
        });
        this.stripeElement = element;
      });
    }
  }

  createForm(): FormGroup {
    const get = (property: string, contactPropery?: any) => {
      contactPropery = contactPropery || property.substring(Math.min(property.lastIndexOf('.')+1, property.length));
      return _get(this.contact, `company.${property}`, _get(this.contact, contactPropery, ''));
    };

    const formGroup: any = this.formBuilder.group({
      name      : [ get('name'),  Validators.required ],
      // the following code stopped working since switch to angular v9
      // Runtime error: cannot access formGroup before initialization
      // taxId     : [
      //   get('taxId'),
      //   taxIdValidator((): string => {
      //     const address = formGroup && formGroup.get('address');
      //     const countryCode = address && address.get('countryCode');
      //     return !!countryCode ? countryCode.value : null;
      //   })
      // ],
      email     : [ get('email'), Validators.nullValidator ],
      address   : this.formBuilder.group({
        zip         : [ get('address.zip'),         Validators.required ],
        community   : [ get('address.community'),   Validators.required ],
        address     : [ get('address.address'),     Validators.required ],
        countryCode : [ get('address.countryCode'), Validators.required ]
      })
    }/*,  { validators: taxIdValidator }*/ );
    formGroup.registerControl('taxId', new FormControl(
      get('taxId'),
      taxIdValidator((): string => {
        const address = formGroup && formGroup.get('address');
        const countryCode = address && address.get('countryCode');
        return !!countryCode ? countryCode.value : null;
      })
    ));
    this.subscription.add(
      formGroup.valueChanges.subscribe(() =>
        //TODO: Check if country has changed and only update validity in this case!
        window.setTimeout(() => formGroup.get('taxId').updateValueAndValidity())
      )
    );
    return formGroup;
  }

  validateTaxId() {
    const taxId  = this.taxId.nativeElement.value;
    if (taxId) {
      this.stripeService.validateBusinessNumber(taxId)
        .then((result) => this.logger.debug('valid', result))
        .catch((error) => this.logger.error('error', error));
    }
  }

  errorCodeKey(errorCode: string) {
    return errorCode.replace(/_([a-z])/g, match => match[1].toUpperCase());
    // return _.camelCase(errorCode);
  }
}
