import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import {BasicContainerComponent, MessageBoxComponent, TopicDisplayInfo} from "shared";
import {PropertiesService} from "properties";
import {EMPTY_ARRAY, getPreferredThemeMode, Logger, themeMode$, Topic} from "core";
import {EMPTY, Observable, ReplaySubject, Subscriber, Subscription, take, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import cloneDeep from "lodash/cloneDeep";

declare type Label = { label: string, ignore: boolean, path: 'root' | 'survey'};

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyComponent extends BasicContainerComponent {

  @Input() set survey(survey: Topic) {
    this._survey = survey;
    this.surveyTag = `${survey?.id}.survey`;
    this.surveyConsultationTag = `${survey?.id}.consultation.yes`;
    const sections$: Topic[] = survey.topics?.filter(topic => topic.type=='section');
    this.sections$.next(sections$ || []);
    this.topics$.next(survey?.topics || []);
  };

  @Input()
  set selectedTags(tags: string[]) {
    //console.log("SET.selectedTags",tags);
    this.logger.debug('SURVEY.ROOT.selectedTags', tags);
    this._selectedTags = tags ?? EMPTY_ARRAY;
  }

  @Input() defaultInfo = false;
  @Input() badgeData:string = undefined;

  protected _contactId:string = undefined;
  @Input()
  set contactId(contactId:string) {
    this._contactId = contactId;
  }
  get contactId():string {
    return this._contactId;
  }
  @Input() readOnly = false;
  @Input() displayRoot = true;

  surveyTag: string = undefined;

  get userId():string {
    return this.propertiesService?.user?.id;
  }

  protected _ready   = false;
  set ready(ready:boolean) {
    //console.log("SET.ready",ready);
    this._ready = ready;
  }
  get ready():boolean {
    return this._ready;
  }

  protected _display = false;
  set display(display:boolean) {
    //console.log("SET.display",display);
    this._display = display;
  }
  get display():boolean {
    return this._display;
  }

  protected _onHelp$: Observable<void> = EMPTY;
  protected helpSubscription: Subscription;
  @ViewChild('rootHelpButton') rootHelpButton: ElementRef;
  @Input()
  set onHelp$(onHelp$: Observable<void>) {
    if (onHelp$!=this._onHelp$) {
      this.helpSubscription?.unsubscribe();
      this._onHelp$ = onHelp$;
      this._onHelp$?.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        // this.rootHelpButton?.nativeElement.click();
        this.help(this.survey).pipe(take(1)).subscribe(help => {
          this.showHelp(help);
        })
      })
    }
  }

  @Output() selectionChanged = new EventEmitter<[Topic, string[]]>();
  @ContentChild(TemplateRef, { static: true }) actionsTemplateRef: TemplateRef<any>;

  topics$ = new ReplaySubject<Topic[]>(1);
  sections$ = new ReplaySubject<Topic[]>(1);

  trackByTopic: TrackByFunction<Topic> = (index: number, topic: Topic): string => {
    return topic.id;
  };

  topicDisplayInfo: (topic: Topic) => TopicDisplayInfo = (topic) => {
    // const isYesNo = topic.type=='yesno';
    return {
      selectable: !topic.topics?.length,
      expand: true
    }
  };

  protected surveyConsultationTag:string;
  protected _survey: Topic;
  protected _selectedTags: string[] = [];
  protected childTopicsMap: { [topicId: string]: {
                                topics$: Observable<Topic[]>,
                                subscribers?: Subscriber<Topic[]>[]
                            }} = {};
  protected static readonly yesNoTopics: Topic[] = [
    { id: 'yes', label: 'actions.yes' }, // $actions.yes
    { id: 'no',  label: 'actions.no' }   // $actions.no
  ];

  protected logger = new Logger('SurveyComponent').setSilent(true);

  constructor(protected propertiesService: PropertiesService,
              protected translateService: TranslateService,
              protected changeDetector: ChangeDetectorRef,
              protected dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.rootHelpButton?.nativeElement.click();
    themeMode$.pipe(takeUntil(this.onDestroy$)).subscribe(mode=>{
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
    //this.topics$.pipe(takeUntil(this.onDestroy$)).subscribe(topics => {
    //})
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    //console.log("USERID",this.userId,"CONTACTID",this.contactId);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    Object.values(this.childTopicsMap)
          .forEach(value =>
            value.subscribers.forEach(subscriber => subscriber?.unsubscribe())
          );
  }

  get survey(): Topic {
    return this._survey;
  }

  get selectedTags(): string[] {
    return this._selectedTags;
  }

  onSelectionChanged(topic: Topic, event: [Topic, string[]]) {
    const [eventTopic, eventTags] = [...event];
    const prefix = `${this.survey.id}.${this.survey!=topic ? `${topic.id}.` : ''}`;
    const selectedTags = eventTags?.map(tag => `${prefix}${tag}`);
    this.selectedTags = this.selectedTags.filter(tag => tag && !tag.startsWith(prefix)).concat(selectedTags)
    this.logger.debug('onSelectionChanged', { eventTopic, eventTags, selectedTags: this.selectedTags });
    this.selectionChanged.emit([this.survey, [...this.selectedTags]]);
  }

  selectedTopicTags(topic): string[] {
    // this.logger.debug('selectedTopicTags', topic.id, this.selectedTags);
    const prefix = `${this.survey.id}.${this.survey!=topic ? `${topic.id}.` : ''}`;
    const selectedTags = (this.selectedTags || [])
      .filter(tag => tag == topic.id || tag?.startsWith(prefix))
      .map(tag => tag.substring(prefix.length));
    this.logger.debug('selectedTopicTags', topic.id, selectedTags);
    return selectedTags;
  }

  normalizeTopics(topic: Topic): Observable<Topic[]> {
    let entry = this.childTopicsMap[topic.id];
    if (entry?.topics$) {
      return entry.topics$;
    } else {
      let topicsSubscriber: Subscriber<Topic[]>;
      const topics$ = new Observable<Topic[]>(subscriber => {
        entry.subscribers.push(subscriber);
        const surveyPath = topic!=this.survey ? `survey.${this.survey.id}` : '';
        const  normalize = (topic: Topic, parentPath: string): Topic => {
          const { label, ignore, path } = this.label(topic);
          const rootPath = path=='root' ? '' : path=='survey' ? surveyPath : parentPath;
          topic.label = `${rootPath ? `${rootPath}.` : ''}${label}`;
          parentPath = ignore ? parentPath : `${parentPath ? `${parentPath}.` : ''}${label.endsWith('.label') ? label.slice(0, -6) : label}`;
          if (topic.type=='yesno') {
            if (!topic.tags?.includes('single_select')) {
              !topic.tags && (topic.tags = []);
              topic.tags.push('single_select')
            }
            topic.topics = cloneDeep(SurveyComponent.yesNoTopics);
          }
          topic.topics?.forEach((childTopic, index) => {
            topic.topics[index] = normalize(childTopic, parentPath);
          })
          return topic;
        }
        const normalizedTopic = normalize(cloneDeep(topic),  surveyPath);
        subscriber.next(normalizedTopic.topics);
        return () => {
          entry.subscribers
            .splice(entry.subscribers.indexOf(subscriber), 1)
            .forEach(subscriber => subscriber?.unsubscribe()) // should be already unsubscribed but unsubscribe here just in case.
        }
      });
      entry?.subscribers.forEach(subscriber => subscriber?.unsubscribe());
      entry = { topics$, subscribers: []};
      this.childTopicsMap[topic.id] = entry;
      return entry.topics$;
    }
  }

  label(topic: Topic): Label {
    // ! (ignore)   - exclude this label from child label composition,
    // $ (absolute) - label with absolute path
    // . (relative) - label with path relative to the survey label path
    // const      raw = topic.label || topic.id || '';
    // const   ignore = raw.charAt(0)=='!';
    // const path = function() {
    //   const symbol = raw.charAt(ignore ? 1 : 0);
    //   return symbol=='.' ? 'survey' : symbol=='$' ? 'root' : undefined;
    // }();
    // const    label = raw.substring((+ignore)+(+!!path));
    // return { label, ignore, path };
    return { label: topic.label, ignore: true, path: 'root' };
  }

  help(topic: Topic): Observable<string> {
    return topic?.help ? this.translateService.stream(topic.help) : EMPTY;
  }

  backgroundColor(topic: Topic): string {
    //console.log("TOPIC.properties!",topic.properties,"mode",getCurrentThemeMode());
    return getPreferredThemeMode()=='light' ? topic.properties.lightBackgroundColor : topic.properties.darkBackgroundColor;
  }

  color(topic: Topic): string {
    //console.log("TOPIC.properties!",topic.properties,"mode",getCurrentThemeMode());
    return getPreferredThemeMode()=='light' ? topic.properties.lightColor : topic.properties.darkColor;
  }

  showHelp(help: string, event?: Event) {
    event?.stopPropagation();
    if (help) {
      this.dialog.open(MessageBoxComponent, { data: { message: help }});
    }
  }

  isEvaluatedSurvey():boolean {
    return !!this.survey.properties?.result?.type && !!this.surveyTag;
  }

  isEvaluated():boolean {
    return !!this.selectedTags.find(tag=>tag==this.surveyTag);
  }

  restart(event: UIEvent) {
    if (this.isEvaluatedSurvey()) {
      if (!!this.selectedTags.find(tag => tag==this.surveyTag)) {
        this.selectedTags = [];
        //console.log("SET.restart",[...this.selectedTags]);
        this.selectionChanged.emit([this.survey, this.selectedTags]);
      }
    }
  }

  evaluate(event: UIEvent) {
    if (this.isEvaluatedSurvey()) {
      if (!this.selectedTags.find(tag => tag==this.surveyTag)) {
        this.selectedTags = [...this.selectedTags, this.surveyTag];
        //console.log("SET.evaluate",[...this.selectedTags]);
        this.selectionChanged.emit([this.survey, [...this.selectedTags]]);
      }
    }
  }

  isSurveyVisible():boolean {
    if (!this.readOnly && this.userId==this.contactId) {
      return true;
    } else if (this.readOnly && this.selectedTags?.includes(this.surveyConsultationTag)) {
      return true;
    }
    //console.log("SELECTED",this.selectedTags);
    return false;
  }
}
