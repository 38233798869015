<tri-state-checkbox #domainCheckbox [disabled]="disabled">
  <span>{{domain?.label | translate}}</span>
</tri-state-checkbox>
<div *ngIf="!this.disabled">
  <mat-checkbox #leaderCheckbox
                *ngIf="domainCheckbox.value"
                [checked]="isLeader"
                (change)="onTapLeader($event)"
                class="leader"
                aria-label="Leader">
    <span translate>userRoles.leader</span>
  </mat-checkbox>
  <div *ngIf="current">
    <div *ngIf="current?.role; let role;">
      <div translate>domain.labels.role</div>
      <div><span class="role {{!!current.accepted ? role : 'unconfirmed'}}">{{(!current.accepted && role!='none' ? 'userRoles.unconfirmed' : 'userRoles.'+role) | translate}}</span></div>
    </div>
    <div *ngIf="!current?.role">
      <div translate>domain.labels.role</div>
      <div><span class="role none" translate>userRoles.none</span></div>
    </div>
    <div *ngIf="current.time; let time;">
      <div translate>domain.labels.since</div>
      <div>{{time | date}}</div>
    </div>
    <div *ngIf="current.contact_name; let name;">
      <div translate>structure.upline</div>
      <div>{{name}}</div>
    </div>
    <div *ngIf="current.admin_name; let name;">
      <div translate>domain.labels.by</div>
      <div>{{name}}</div>
    </div>
  </div>
  <div *ngIf="!current">
    <div>
      <div translate>domain.labels.role</div>
      <div><span class="role none" translate>userRoles.none</span></div>
    </div>
  </div>
</div>
