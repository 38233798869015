import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {CalendarEvent, CalendarEventType, CalendarEventTypes, NULL_CALENDAR_EVENT} from "../../models/event";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {
  BasicContainerComponent,
  contentAnimations,
  Country,
  enterLeaveAnimations,
  FormChangeDetector,
  Language,
  LanguageSelectorComponent,
  SlideEvent,
  SlidePanelContainerComponent,
  SlideState,
  TimezoneSelectorComponent,
  TimezoneService
} from "shared";
import {PropertiesService} from "properties";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import cloneDeep from "lodash/cloneDeep";
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  from, lastValueFrom,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription
} from "rxjs";
import {
  auditTime,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  scan,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from "rxjs/operators";
import {FilterTypes, Logger, Platform, REMOVE_ALL_OTHER_TYPES, Timezone, Timezones} from "core";
import {Calendar} from "../../models/calendar";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import values from "lodash/values";
import mapValues from "lodash/mapValues";
import {MapComponent, Marker} from "maps";
import isEqual from "lodash/isEqual";
import {NgxMatDateAdapter} from "@angular-material-components/datetime-picker";
import moment from "moment";
import {ErrorStateMatcher} from "@angular/material/core";
import {HttpClient} from "@angular/common/http";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {EXPANSION_PANEL_ANIMATION_TIMING, MatExpansionPanel} from "@angular/material/expansion";
import {GeocodeService} from "../../services/geocode.service";
import {LatLng} from "../event-map/event-map.component";
import {CalendarListComponent} from "../calendar-list/calendar-list.component";
import {ImageLink, Media, MediaAction, MediaService, MediaType, MediaViewerOverlayService} from "media";
import {UPLOAD_TYPE} from "upload";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Seats} from "../../models/seats";
import {Platform as AngularPlatform} from "@angular/cdk/platform";
import {NgxMatDatepickerInputEvent} from "@angular-material-components/datetime-picker/lib/datepicker-input-base";

// deriving timezone by location:
// https://stackoverflow.com/questions/16086962/how-to-get-a-time-zone-from-a-location-using-latitude-and-longitude-coordinates

// issue related to rendering of datetime picker
// https://github.com/h2qutc/angular-material-components/issues/269

export type State = {
  valid: boolean;
  dirty: boolean;
}

export type EventDetailPanel = 'main' | 'calendars' | 'languages' | 'timezones' | 'media';

class SeatsRange  {
  public static readonly DEFAULT = new SeatsRange();
  private readonly _min: number;
  private readonly _max: number;
  constructor(min = 0, max = 0) {
    this._min = min;
    this._max = max;
  }
  get min(): number { return this._min; }
  get max(): number { return this._max; }
  get closed(): boolean { return this.min == this.max; }
}

class SeatsRangeMap {
  public static readonly DEFAULT = new SeatsRangeMap(SeatsRange.DEFAULT, SeatsRange.DEFAULT);
  constructor(public online: SeatsRange, public offline: SeatsRange) {}
  get closed(): boolean {
    return this.online.closed && this.offline.closed;
  }
}

// for cross datetime form field validation we need to override the default mat-error behavior
// and displayed the error even when the control is not touched
class EventDatetimeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && control.invalid && (!control.hasError('required') || control.touched);
  }
}

const conditionalValidator = (predicate: () => boolean, validator: ValidatorFn, name?:string): ValidatorFn  => {
  const validatorFn: ValidatorFn = (formControl => {
    let result = null;
    if (formControl?.parent && predicate()) {
      result = validator(formControl);
    }
    console.debug('conditionalValidator', { formControl, result, name });
    return result;
  });
  return validatorFn;
};

const seatsValidator = (range = SeatsRange.DEFAULT): ValidatorFn => {
  const validators = [Validators.pattern("\\d*"), Validators.min(range.min), Validators.max(range.max)];
  const validatorFn: ValidatorFn = (formControl => {
    const result = validators.reduce((errors: ValidationErrors | null, validator, index) => {
      const error = validator(formControl);
      if (error) {
        if (!errors) {
          errors = error;
        } else {
          errors = {...errors , ...error};
        }
      }
      return errors;
    }, null);
    console.debug('seatsValidator', { formControl, result });
    return result;
  });
  return validatorFn;
};

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
  animations: [
    enterLeaveAnimations,
    contentAnimations,
    trigger('content', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms ease-in', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('300ms ease-out', style({opacity: 0}))
      ])
    ]),
   // matExpansionAnimations, //https://github.com/angular/components/blob/d29df3813eb340a6a30e155bf90ddb1d2befe4e0/src/material/expansion/expansion-animations.ts#L51
   trigger('indicatorRotate', [
    state('collapsed, void', style({transform: 'scale(1.5) rotate(0deg)'})),
    state('expanded', style({transform: 'scale(1.5) rotate(180deg)'})),
    transition('expanded <=> collapsed, void => collapsed',
      animate(EXPANSION_PANEL_ANIMATION_TIMING)),
  ]),
  trigger('timezone', [
      // animate new timezones
      // transition(':enter', [
      //   style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
      //   animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
      //     style({ transform: 'scale(1)', opacity: 1 }))  // final
      // ]),
      // animate timezone removal
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ]),
    ]),
    // animate initial display of timezone list
    // trigger('timezones', [
    //   transition(':enter', [
    //     query('@timezones', stagger(300, animateChild()), { optional: true })
    //   ]),
    // ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDetailComponent extends BasicContainerComponent {

  @ViewChild(SlidePanelContainerComponent)   slidePanel : SlidePanelContainerComponent;
  @ViewChild(CalendarListComponent)     calendarSelector: CalendarListComponent;
  @ViewChild(LanguageSelectorComponent) languageSelector: LanguageSelectorComponent;
  @ViewChild(TimezoneSelectorComponent) timezoneSelector: TimezoneSelectorComponent;
  @ViewChild(MapComponent) map: MapComponent;
  @ViewChild('timezonesPanel') timezonesPanel: MatExpansionPanel;
  @ContentChild(TemplateRef, { static: true }) controlsTemplate: TemplateRef<any>;
  @ViewChild('infoText', { read: ElementRef }) infoText: ElementRef;

  @Input()
  set editMode(editMode: boolean) {
    if (this.editMode$.getValue() != editMode) {
      this.formChangeSubscription?.unsubscribe();
      this.editMode$.next(editMode);
    }
  }

  get editMode(): boolean {
    return this.editMode$.getValue();
  }

  @Input()
  set calendars(calendars: Observable<Calendar[]>) {
    this.calendarsSubscription?.unsubscribe();
    this.calendarsSubscription = calendars
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((calendars) =>
        this.calendars$.next(calendars)
      )
  }

  get calendars(): Observable<Calendar[]> {
    return this.calendars$;
  }

  @Input() set languageLru(languageLru: Observable<string[]>) {
    this.logger.debug('languageLru', languageLru);
    this.languageLruSubscription?.unsubscribe();
    this.languageLruSubscription = languageLru
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(languageLru => {
        this.logger.debug('languageLru', languageLru);
        this.languageLru$.next(languageLru);
      })
  };

  @Input() set languageSearchTerm(languageSearchTerm: Observable<string>) {
    this.languageSearchTermSubscription?.unsubscribe();
    this.languageSearchTermSubscription = languageSearchTerm
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(term => {
          this.logger.debug('languageSearchTerm.term', term);
          this.onLanguageSearchTermChange(term);
      });
  };

  @Input() set timezoneLru(timezoneLru: Observable<string[]>) {
    this.logger.debug('timezoneLru', timezoneLru);
    this.timezoneLruSubscription?.unsubscribe();
    this.timezoneLruSubscription = timezoneLru
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(timezoneLru => {
        this.logger.debug('timezoneLru', timezoneLru);
        this.timezoneLru$.next(timezoneLru);
      })
  };

  @Input() set timezoneSearchTerm(timezoneSearchTerm: Observable<string>) {
    this.logger.debug('timezoneSearchTerm', timezoneSearchTerm);
    this.timezoneSearchTermSubscription?.unsubscribe();
    this.timezoneSearchTermSubscription = timezoneSearchTerm
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(term => {
        this.logger.debug('timezoneSearchTerm.term', term);
        this.onTimezoneSearchTermChange(term);
      })
  };

  @Input() canCreateCalendar = true;

  @Output() onStateChange = new ReplaySubject<State>(1); //EventEmitter<State>();
  @Output() onValueChange = new ReplaySubject<CalendarEvent>(1); //EventEmitter<State>();
  @Output() onPanelChange = new EventEmitter<EventDetailPanel>();
  @Output() onAttendeeSeatsChange = new EventEmitter<Seats>();
  @Output() onLanguageLruChange = new EventEmitter<string[]>();
  @Output() onTimezoneLruChange = new EventEmitter<string[]>();
  @Output('onCalendarCreate') onCalendarCreateRequest = new EventEmitter<(calendar: Calendar) => void>();

  infoExpanded = false;
  infoOverflows = false;
  eventForm: FormGroup;
  attendeeForm: FormGroup;
  initialized = false;
  eventInfoRows: { content: string, type: string }[][] = [];

  countries$: Observable<Country[]>;

  timezones$: Observable<Timezones>;
  localTimezone: string = moment.tz.guess();
  selectedTimezone = this.localTimezone;
  timezoneLru$ = new BehaviorSubject<string[]>(undefined);
  timezoneSearchTerm$ = new BehaviorSubject<string>(undefined);
  protected timezoneLruSubscription: Subscription;
  protected timezoneSearchTermSubscription: Subscription;

  languages$: Observable<Language[]>;
  languageLru$ = new BehaviorSubject<string[]>(undefined);
  languageSearchTerm$ = new BehaviorSubject<string>(undefined);
  protected languageLruSubscription: Subscription;
  protected languageSearchTermSubscription: Subscription;

  protected media$: Observable<Media[]>;

  state$ = new BehaviorSubject<State>({ valid: undefined, dirty: undefined });
  protected value$ = new BehaviorSubject<CalendarEvent>(undefined);

  protected _panel: EventDetailPanel = 'main';
  protected _selector: Exclude<EventDetailPanel, 'main'>;
  protected event$ = new BehaviorSubject<CalendarEvent>(undefined);
  protected editMode$ = new BehaviorSubject<boolean | undefined /* tolerate compiler strictNullChecks option */>(undefined);
  protected formChangeSubscription: Subscription;
  protected formChangeReset: Subject<void>;

  calendars$ = new BehaviorSubject<Calendar[]>([]);
  protected calendarsSubscription: Subscription;

  protected logger = new Logger('EventDetailComponent').setSilent(false);
  protected loggerValueChange = new Logger('EventDetailComponent').setSilent(true);

  timeFromMin        : moment.Moment;
  timeFromMax        : moment.Moment;
  timeToMin          : moment.Moment;
  timeToMax          : moment.Moment;
  timeBookingFromMin : moment.Moment;
  timeBookingFromMax : moment.Moment;
  timeBookingToMin   : moment.Moment;
  timeBookingToMax   : moment.Moment;
  dateTimeErrorStateMatcher = new EventDatetimeErrorStateMatcher();

  timeBookingFromFilter = (from: moment.Moment): boolean => {
    const created  = moment(this.event.timeCreated).startOf('day');
    const timeFrom  = this.eventForm.get('timeFrom').value;
    return from   >= created && (!timeFrom || from <= timeFrom);
  };

  timeBookingToFilter  = (to: moment.Moment): boolean => {
    const  bookingFrom = this.eventForm.get('timeBookingFrom').value;
    const  timeFrom    = this.eventForm.get('timeFrom').value;
    return (!bookingFrom || bookingFrom <= to) &&  (!timeFrom || to <= timeFrom);
  };

  showMap:boolean = false;

  eventSeatsRangeMap    = SeatsRangeMap.DEFAULT;
  attendeeSeatsRangeMap = SeatsRangeMap.DEFAULT;

  CalendarEventType = CalendarEventType;
  CalendarEventTypes = CalendarEventTypes;

  constructor(protected elementRef: ElementRef,
              public angularPlatform: AngularPlatform,
              protected changeDetector: ChangeDetectorRef,
              protected formBuilder: FormBuilder,
              protected formChangeDetector: FormChangeDetector,
              public propertiesService: PropertiesService,
              public translateService: TranslateService,
              public timezoneService: TimezoneService,
              protected geocodeService: GeocodeService,
              public mediaService: MediaService,
              protected mediaViewerOverlayService: MediaViewerOverlayService,
              protected httpClient: HttpClient,
              protected snackBar: MatSnackBar,
              public platform: Platform,
              @Inject(NULL_CALENDAR_EVENT)
              protected nullCalendarEvent: CalendarEvent,
              private dateAdapter: NgxMatDateAdapter<moment.Moment>) {
    super();
    // this.eventForm = this.createEventForm();
    // this.attendeeForm = this.createAttendeeForm();
  }

  ngOnInit(): void {
    const languages$ = this.translateService.stream('languages').pipe(shareReplay(1));
    this.languages$ = languages$.pipe(map(result => {
      const language = this.translateService.currentLang;
      return values(mapValues(result, (value, key) => { return {code: key, name: value } }))
        .sort((l1, l2) => l1.name.localeCompare(l2.name, language));
    }));

    this.countries$ = this.translateService
      .stream('countries')
      .pipe(
        map((result) => {
          const language = this.translateService.currentLang;
          return values(mapValues(result, (value, key) => { return {code: key, name: value } }))
            .sort((c1,c2) => c1.name.localeCompare(c2.name,language));
        })
      );

    this.timezones$ = combineLatest([
      this.timezoneService.zones(),
      this.timezoneSearchTerm$
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        map(([timezones, term]) => {
          if (!term) {
            return timezones;
          } else {
            return timezones.reduce((result, {countryCode, zones}) => {
              const countryZones = zones?.filter(zone => {
                if (!zone) {
                  return false;
                } else {
                  const searchString = (zone.name+'\x00'+zone.id).toLowerCase();
                  const match = searchString.indexOf(term.toLowerCase()) >= 0
                  // this.logger.debug('SEARCH', { searchString, zone, term, match });
                  return match;
                }
              });
              if (countryZones?.length) {
                result.push({countryCode: countryCode, zones: countryZones});
              }
              this.logger.debug('SEARCH RESULT', result);
              return result;
            }, [] as Timezones);
          }
        })
      );

    this.translateService.onLangChange
      .pipe(
        map((event: LangChangeEvent) => event.lang),
        startWith(this.translateService.currentLang),
        takeUntil(this.onDestroy$)
      )
      .subscribe(language => this.dateAdapter.setLocale(language));

    this.media$ = this.mediaService.entities$;

    this.state$
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(state => this.onStateChange.next(state));
    this.value$
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(change => {
        this.loggerValueChange.debug('VALUE CHANGE', change);
        this.onValueChange.next(change)
      });

    combineLatest([
      this.event$.pipe(filter(event => !!event)),
      this.editMode$
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap(([event, editMode], index) => {
        const triggerChangeDetection = (value: CalendarEvent | object) => {
          // NOTE: imperative/programmatic control value changes (e.g. changes to languageName control value)
          // which by design does not lead to emission on valueChanges observable of the angular reactive form

          // feeds form change detector with changes
          this.value$.next(value instanceof CalendarEvent
            ? value
            : new CalendarEvent({...this.value$.getValue(), ...value}));
        };
        if (editMode) {
          let eventForm = this.eventForm;
          if (!eventForm) {
            eventForm = this.eventForm = this.createEventForm();
          }

          let formChangeSubscription = this.formChangeSubscription;
          if (!formChangeSubscription) {
            formChangeSubscription = new Subscription();
            formChangeSubscription.add(eventForm.statusChanges.subscribe(() => {
              this.state$.next({...this.state$.getValue(), valid: eventForm.valid});
            }));

            // the following impl was used when change detector did simply listen to form.valueChanges
            // now it listens to emissions of value$ property which makes this code deprecated
            // const updateState = () => {
            //     const dirty = !isEqual(this.event, this.value); //this.state.getValue().dirty;
            //     if (eventForm.pristine && dirty) {
            //       // "Augment event form dirty state detection (which is based on tracking form valueChanges) with manually driven dirty/pristine marks
            //       // in order to support imperative/programatic control value changes (e.g. changes to languageName control value)
            //       // which by design does not lead to emission on valueChanges observable of the angular reactive form
            //       //
            //       // see https://github.com/angular/angular/blob/0075017b43a37d06780cc245c3326212d15fd6bd/packages/forms/src/model.ts#L284
            //       // https://github.com/angular/angular/blob/0075017b43a37d06780cc245c3326212d15fd6bd/packages/forms/src/model.ts#L284
            //       /**
            //        * A control is `pristine` if the user has not yet changed
            //        * the value in the UI.
            //        *
            //        * @returns True if the user has not yet changed the value in the UI; compare `dirty`.
            //        * Programmatic changes to a control's value do not mark it dirty.
            //        */
            //       eventForm.markAsDirty();
            //     } else if (eventForm.dirty && !dirty) {
            //       eventForm.markAsPristine();
            //     }
            // };


            formChangeSubscription.add(eventForm.valueChanges
              // .pipe(
              //     auditTime(0),
              //     scan((state: { event: CalendarEvent, previous?: CalendarEvent }, change: any) => {
              //       state.previous = cloneDeep(state.event);
              //       state.event = this.value;
              //       return state;
              //     }, {event: undefined}),
              //   filter(state => !isEqual(state.event, state.previous)),
              // )
              .subscribe(state => {
                this.logger.debug('onValueChange', state.event, state.previous);
                // triggerChangeDetection(state.event);
                triggerChangeDetection(this.value);
              })
            );

            // keep languageCode and languageName in sync
            formChangeSubscription.add(eventForm.get('languageCode').valueChanges
              .pipe(withLatestFrom(this.translateService.stream('languages')))
              .subscribe(([change, languages]) => {
                // updateLanguageName(languages)
                this.logger.debug('LANGUAGE CODE CHANGE', change);
                const code = eventForm.get('languageCode').value;
                const name = languages[code];
                if (eventForm.get('languageName').value != name) {
                  eventForm.patchValue({'languageName': name});
                  triggerChangeDetection({language: code});
                }
              })
            );

            // keep calendarId and calendarName in sync
            formChangeSubscription.add(eventForm.get('calendarId').valueChanges
              .pipe(withLatestFrom(this.calendars))
              .subscribe(([id, calendars]) => {
                this.logger.debug('CALENDAR ID CHANGE', id);
                const name = calendars?.find(calendar => calendar.id == id)?.name;
                if (eventForm.get('calendarName').value != name) {
                  eventForm.patchValue({'calendarName': name});
                  triggerChangeDetection({calendarId: id});
                }
              })
            );

            // event type does not have a corresponding form control element in the template
            // changes are reflected by direct calls to setValue() api =>
            // we have to take care for updates of form dirty/pristine state
            formChangeSubscription.add(eventForm.get('type').valueChanges
              .subscribe((type: CalendarEventType) => {
                this.logger.debug('TYPE CHANGE', type);
                const typeControls: { [key: string]: string[] } = {
                  [CalendarEventType.online]: ['joinLink'/*, 'seats.online'*/],
                  [CalendarEventType.offline]: ['location'/*, 'seats.offline'*/],
                };
                window.setTimeout(() => {
                  Object.entries(typeControls).forEach(([key, value]) => {
                    value?.forEach(controlName => {
                      const control = eventForm.get(controlName);
                      if (control) {
                        if (key == CalendarEventType.online  && CalendarEventType.isOnline(type) ||
                            key == CalendarEventType.offline && CalendarEventType.isOffline(type)) {
                          control.setValidators(Validators.required);
                        } else {
                          control.clearValidators();
                        }
                        control.updateValueAndValidity();
                      }
                    })
                  });
                  // eventForm.setErrors(null);
                  // eventForm.updateValueAndValidity();
                });
              })
            );

            const locationChange = eventForm.get('location').valueChanges.pipe(
              filter(location => {
                if (CalendarEventType.isOffline(eventForm.get('type').value)) {
                  const locationControl = eventForm.get('location');
                  return locationControl.dirty && location?.length > 2;
                }
                return false;
              }),
              debounceTime(300, asyncScheduler)
            );
            formChangeSubscription.add(locationChange.pipe(filter(location => eventForm.get('geocodingEnabled').value)).subscribe(location => {
              this.geocodeService.geocode(location)
                .pipe(takeUntil(locationChange))
                .subscribe( {
                  next: (marker: Marker) => {
                    eventForm.get('marker').setValue([marker]);
                  },
                  error: (error) => this.logger.error('Failed to geocode event location. Map event marker position will not be updated.')
                });
            }));

            const markerChange: Observable<LatLng> = eventForm.get('marker').valueChanges.pipe(
              // wait for other control value modifications to be reflected in form value
              // because we need also values of timezone, online, etc.
              auditTime(100),
              map(marker => (marker?.length ? { lat: marker[0].latitude, lng: marker[0].longitude } : undefined))
            );
            formChangeSubscription.add(markerChange
              .pipe(
                filter((location: LatLng) => {
                  if (CalendarEventType.isOffline(eventForm.get('type').value) && location) {
                    return !!(location.lat && location.lng);
                  }
                  return false;
                })
              )
              .subscribe((location: LatLng) => {
                if (eventForm.get('marker').dirty || !eventForm.get('timezone').value) {
                  if (eventForm.get('marker').dirty && eventForm.get('geocodingEnabled').value) {
                    this.geocodeService.reverseGeocode(location)
                      .pipe(takeUntil(locationChange))
                      .subscribe({
                        next: (location: string) => {
                          eventForm.get('location').setValue(location);
                        },
                        error: (error) => this.logger.error('Failed to reverse geocode event map marker. Event location will not be updated.')
                      });
                  }
                  this.geocodeService.timezone(location).pipe(
                    takeUntil(markerChange),
                    map((timezone: any) => timezone.timeZoneId || this.localTimezone),
                    catchError(error => {
                      this.logger.debug(`Failed to find timezone - assuming: ${this.localTimezone}`);
                      return this.localTimezone;
                    })
                  )
                  .subscribe(timezone => {
                    eventForm.get('timezone').setValue(timezone);
                    if (eventForm.get('marker').pristine) {
                      this.selectedTimezone = timezone;
                    }
                    const timezones = eventForm.get('timezones').value || [];
                    if (timezones.includes(timezone)) {
                      if (timezones[0] != timezone) {
                        timezones.sort((tz1, tz2) => tz1 == timezone ? -1 : tz2 == timezone ? 1 : 0);
                      }
                    } else {
                      timezones.unshift(timezone);
                    }
                    eventForm.get('timezones').setValue(timezones);
                  });
                }
              })
            );

            /*
            Needed only when multiplexing event joinLink and location to the same form input
            Introduction of hybrid events which could have both join link and location made this code obsolete
            eventForm.get('online').valueChanges
              .pipe(
                takeUntil(this.onDestroy$),
                scan((state: { online: boolean, location: string, joinLink: string }, online: boolean) => {
                    const location = eventForm.get('location').value;
                    if (state.online = online) {
                      state.location = location;
                    } else {
                      state.joinLink = location;
                    }
                    return state;
                  }, { online: this.event.online, location: this.event.location, joinLink: this.event.joinLink }
                )
              )
              .subscribe(state => {
                const location = eventForm.get('location');
                if (state.online) {
                  location.setValue(state.joinLink);
                } else {
                  location.setValue(state.location);
                }
                location.markAsUntouched();
                location.updateValueAndValidity();
              });
            */

            formChangeSubscription.add(eventForm.get('timeFrom').valueChanges
              .pipe(
                startWith(undefined as moment.Moment), // emitting first empty value to fill-in the buffer
                pairwise()
              )
              .subscribe(([prevTimeFrom, currTimeFrom]) => {
                const format = 'MMMM Do YYYY, h:mm:ss a';
                this.logger.debug({
                  previousTimeFrom: prevTimeFrom?.format(format),
                  currentTimeFrom: currTimeFrom?.format(format)
                });
                const timeToControl = eventForm.get('timeTo');
                let timeTo: moment.Moment = timeToControl.value;
                if (currTimeFrom) {
                  const delta = timeTo && prevTimeFrom ? timeTo.diff(prevTimeFrom) : moment.duration(1, 'hours');
                  timeTo = moment(currTimeFrom).add(delta);
                } else {
                  timeTo = undefined;
                }
                if (timeTo != timeToControl.value) {
                  timeToControl.setValue(timeTo, {onlySelf: true, emitEvent: false});
                  // do we need to take care for form pristine state here?
                }
              })
            );

            formChangeSubscription.add(this.timezoneLru$
              .pipe(
                skip(1), // listen only for changes - current timezones lru are set below see value.timezones
                map(timezones => [this.localTimezone, ...timezones.filter(timezone => timezone!=this.localTimezone)]),
              )
              .subscribe(timezones => {
                this.logger.debug('timezoneLru$', timezones);
                // the following line leads to eventForm.valueChanges emission
                // which in turn will trigger change detection cycle
                eventForm.get('timezones').setValue(timezones);
              })
           );
          } // formChangeSubscription initialization

          const marker: Marker = { latitude: event.latitude, longitude: event.longitude };
          const timeCreated = moment(this.timeCreated(event));
          const round: (date: number | moment.Moment, duration: number | moment.Duration) => moment.Moment =
            (date, duration) => moment(Math.ceil((+date) / (+duration)) * (+duration)).startOf('minutes');
          const timeFrom = event.timeFrom && moment(event.timeFrom) || round(moment().add(10, 'minutes'), moment.duration(10, 'minutes'));
          const value: any = {
            name: event.name,
            author: event.author.name,
            info: event.info,
            type: event.type,
            seats: Seats.create( // do not display zero seats to the user - leave the field empty
              Object.entries(event.seats.total.toJSON()).reduce((seats, [key, value]: [ string | number | symbol, any ]) => {
                seats[key] = value!=0 ? value : undefined;
                return seats;
              }, {})),
            location: event.location,
            joinLink: event.joinLink,
            bookingLink: event.bookingLink,
            marker: [marker],
            calendarId: event.calendar?.id || this.calendars$.getValue()?.find(calendar => true)?.id,
            timeCreated: timeCreated,
            timeFrom: timeFrom,
            timeTo: moment(event.timeTo ? event.timeTo : moment(timeFrom).add(1, 'hours')),
            timeBookingFrom: event.timeBookingFrom && moment(event.timeBookingFrom),
            timeBookingTo: event.timeBookingTo && moment(event.timeBookingTo),
            countryCodes: event.countryCodes ? [...event.countryCodes] : [],
            countryCodesExcluded: !!event.countryCodesExcluded,
            languageCode: event.language,
            // timezones: [this.localTimezone, ...event.timezones.filter(timezone => timezone!=this.localTimezone)],
            timezones: [this.localTimezone, ...this.timezoneLru$.getValue().filter(timezone => timezone!=this.localTimezone)],
            timezone: event.timezoneId,
            geocodingEnabled: event.properties?.geocodingEnabled!=false
          };
          // update of language name is triggered by a call to updateValueAndValidity()
          // of calendarId FormControl immediately after form value change
          // const promise: Promise<any> = value.languageCode
          //   ? this.translateService.get('languages')
          //     .toPromise()
          //     .then(languages => {
          //         value.languageName = Object.entries(languages)
          //           .find(([code, name]) => code.toLowerCase()==value.languageCode.toLowerCase() && name)
          //           ?.map(([code, name]) => name)[0];
          //         return value;
          //       }
          //     )
          //   : Promise.resolve(value);
          // promise.then(value => eventForm.reset(value));
          const mediaPromise: Promise<Media> = !event.media ? this.defaultMedia : Promise.resolve(event.media);
          const locationPromise: Promise<string> = value.location || !value.geocodingEnabled
            ? Promise.resolve(value.location)
            : lastValueFrom(this.geocodeService.reverseGeocode({lat: marker.latitude, lng: marker.longitude}));
          const promise = Promise
            .allSettled([mediaPromise, locationPromise])
            .then((results) =>
              results.reduce((value, result: PromiseSettledResult<any>, index) => {
                if (result.status=='fulfilled') {
                  switch(index) {
                    case 0: value.media = result.value; break;
                    case 1: value.location = result.value; break;
                  }
                }
                return value;
              }, value as { media: Media, location: string })
            )
            .then(value => {
              // wait for form value to be updated/reset and then force calendar and language name updates
              // valueChange subscribers of those two fields are registered in ngOnInit
              eventForm.valueChanges
                .pipe(
                  debounceTime(0),
                  take(1)
                ).subscribe(() => {
                eventForm.get('calendarId').updateValueAndValidity({ onlySelf: false, emitEvent: true });
                eventForm.get('languageCode').updateValueAndValidity();
              });

              const minSeats = new Seats(0, 0).add(event.seats.total).remove(event.seats.available);
              this.eventSeatsRangeMap = new SeatsRangeMap(
                new SeatsRange(minSeats.online, Number.MAX_SAFE_INTEGER),
                new SeatsRange(minSeats.offline, Number.MAX_SAFE_INTEGER)
              );
              this.logger.debug("Event seats range", this.eventSeatsRangeMap, event.seats);
              eventForm.get('seats.online').setValidators(seatsValidator(this.eventSeatsRangeMap.online));
              eventForm.get('seats.offline').setValidators(seatsValidator(this.eventSeatsRangeMap.offline));
              eventForm.reset(value);
              // event.time_form should not be before event.timeCreated (current date for new events)
              this.timeFromMin = this.timeToMin = this.timeBookingFromMin = this.timeBookingToMin = timeCreated;
              return value;
            })
            .then(value => {
              const detectorOptions = {
                trigger$: this.value$,
                normalizer: function(value: any) {
                  // media selection will assign the complete media object to the corresponding form control value although
                  // the event is delivered with its media property containing just some essential media attributes
                  // to avoid false detection of media change just the media id is compared.
                  const mediaId = value?.media?.id;
                  value.media = mediaId ? { id: mediaId } : value.media;
                  return Promise.resolve(value);
                }.bind(this),
                log: true
              };
              return [this.eventForm, this.value, formChangeSubscription, detectorOptions, index];
            });
          return from(promise);
        } else if (editMode == false) {
          if (!this.attendeeForm) {
            this.attendeeForm = this.createAttendeeForm();
          }
          let formChangeSubscription = this.formChangeSubscription;
          if (!formChangeSubscription) {
            formChangeSubscription = new Subscription();
            formChangeSubscription.add(this.attendeeForm.statusChanges.subscribe(() => {
              this.state$.next({...this.state$.getValue(), valid: this.attendeeForm.valid});
            }));
            formChangeSubscription.add(this.attendeeForm.valueChanges
              .pipe(scan((state: { seats: Seats, previous?: Seats }, change: any) => {
                  state.previous = state.seats;
                  state.seats = this.attendeeForm.value;
                  return state;
                }, {seats: undefined}),
                filter(state => !isEqual(state.seats, state.previous)),
              )
              .subscribe(state => {
                this.logger.debug('attendeeForm.onValueChange', state.seats, state.previous);
                triggerChangeDetection({ seats: state.seats } );
              })
            );
          }
          const maxSeats = event.seats.available.add(event.attendee?.seats);
          this.attendeeSeatsRangeMap = new SeatsRangeMap(new SeatsRange(0, maxSeats.online), new SeatsRange(0, maxSeats.offline));
          this.logger.debug("Attendee seats range", this.attendeeSeatsRangeMap, event.attendee);
          this.attendeeForm.get('online').setValidators(seatsValidator(this.attendeeSeatsRangeMap.online));
          this.attendeeForm.get('offline').setValidators(seatsValidator(this.attendeeSeatsRangeMap.offline));
          this.attendeeForm.reset(event.attendee?.seats);
          return of([this.attendeeForm, event, formChangeSubscription, {}, index]);
        }
      }))
      .subscribe(([form, value, formChangeSubscription, detectorOptions, index] : [FormGroup, CalendarEvent, Subscription, any, number]) => {
        this.value$.next(value);
        if (!this.formChangeSubscription) {
          this.formChangeReset = new Subject();
          const detector = this.formChangeDetector.detect(form, {...detectorOptions, resetter: this.formChangeReset});
          formChangeSubscription.add(
            detector.subscribe((pristine: boolean) => {
              this.logger.debug('PRISTINE', pristine);
              this.state$.next({ ...this.state$.getValue(), dirty: !pristine });
            })
          );
          formChangeSubscription.add(() => {
            this.formChangeSubscription = null;
            this.formChangeReset.complete();
            this.formChangeReset = null;
            this.logger.debug('formChangeSubscription -> unsubscribed!');
          });
          this.formChangeSubscription = formChangeSubscription;
        } else {
          this.logger.debug('RESET change detector', { value: form.getRawValue() });
          this.formChangeReset.next();
        }
        if (index==0) {
          window.setTimeout(() => {
            this.initialized = true;
            this.changeDetector.detectChanges();
          });
        }
      })
  }

  ngOnDestroy(): void {
    this.formChangeSubscription?.unsubscribe();
  }

  @Input()
  set event(event: CalendarEvent) {
    this.logger.debug('SET EVENT', event);
    event = new CalendarEvent({...this.nullCalendarEvent, ...cloneDeep(event)});
    if (!isEqual(this.event$.getValue(), event)) {
      this.event$.next(event);
    }
  }

  get event(): CalendarEvent {
    return this.event$.getValue();
  }

  protected get value(): CalendarEvent {
    this.logger.debug('VALUE!!!');
    const event = new CalendarEvent(this.event);
    event.name  = this.eventForm.get('name').value;
    event.info  = this.eventForm.get('info').value;
    const type  = this.eventForm.get('type').value;
    if (CalendarEventType.isOffline(type)) {
      event.location  = this.eventForm.get('location').value;
      const marker    = (this.eventForm.get('marker').value || [])[0] || {};
      event.latitude  = marker.latitude;
      event.longitude = marker.longitude;
    } else {
      event.location = null;
      event.latitude = event.longitude = null;
    }

    if (CalendarEventType.isOnline(type)) {
      event.joinLink = this.eventForm.get('joinLink').value?.trim();
    } else {
      event.joinLink = null;
    }
    
    event.bookingLink = this.eventForm.get('bookingLink').value?.trim();

    // let seats = this.eventForm.get('seats').value;
    event.seats.total = this.getEventSeats(this.eventForm.get('seats').value, type);

    // event.calendar = this.calendars$.getValue().find(c => c.id == this.eventForm.get('calendar').value);
    const calendarId = this.eventForm.get('calendarId').value;
    event.calendar   = calendarId==this.event.calendar?.id ? this.event.calendar : { id: this.eventForm.get('calendarId').value };

    const timeOf = (control: string) => {
      let   moment   = this.eventForm.get(control).value;
      const timezone = this.eventForm.get('timezone').value;
      if (moment && timezone!=this.selectedTimezone) {
        moment = moment.tz(this.localTimezone);
      }
      return moment?.valueOf();
    };

    event.timeCreated       = timeOf('timeCreated');      // this.eventForm.get().value?.valueOf();
    event.timeFrom          = timeOf('timeFrom');         // this.eventForm.get('timeFrom').value?.valueOf();
    event.timeTo            = timeOf('timeTo');           // this.eventForm.get('timeTo').value?.valueOf();
    event.timeBookingFrom   = timeOf('timeBookingFrom');  // this.eventForm.get('timeBookingFrom').value?.valueOf();
    event.timeBookingTo     = timeOf('timeBookingTo');    // this.eventForm.get('timeBookingTo').value?.valueOf();

    event.language  = this.eventForm.get('languageCode').value;
    event.countryCodes = this.eventForm.get('countryCodes').value;
    event.countryCodesExcluded = this.eventForm.get('countryCodesExcluded').value;
    // event.timezones = this.eventForm.get('timezones').value; //?.filter(timezone => timezone!=this.localTimezone);
    event.timezoneId = this.eventForm.get('timezone').value;
    event.editorsTerm = this.eventForm.get('editorsTerm').value;
    event.viewersTerm = this.eventForm.get('viewersTerm').value;
    event.attendeesTerm = this.eventForm.get('attendeesTerm').value;
    event.media = this.eventForm.get('media').value?.id ? this.eventForm.get('media').value : undefined;
    event.properties = event.properties ?? {};
    event.properties.geocodingEnabled = this.eventForm.get('geocodingEnabled').value;
    return event;
  }

  set panel(panel: EventDetailPanel) {
    this.logger.debug(`PANEL: ${this._panel} --> ${panel}`);
    if (this._panel != panel) {
      if (panel != 'main') {
        this._selector = panel;
      }
      this.onPanelChange.emit(this._panel = panel);
    }
  }

  get panel(): EventDetailPanel {
    return this._panel;
  }

  get selector(): Exclude<EventDetailPanel, 'main'> {
    return this._selector;
  }

  timeCreated(event: CalendarEvent) {
    return event?.timeCreated ? new Date(event.timeCreated) : new Date();
  }

  protected createEventForm(): FormGroup {
    const now = moment.now();
    const timeCreated = moment(now);
    const timeFrom = moment(timeCreated).add(1, 'hours').startOf('hour');
    const timeBookingFrom = moment(timeFrom);
    const eventForm: FormGroup = this.formBuilder.group({
      name            : [ '', Validators.required ],
      author          : [ '' ],
      info            : [ '' ],
      timeCreated     : [ { value: timeCreated, disabled: false }, Validators.required ],
      type            : [ 'offline' ],
      // When we use 2 separate matInput controls (e.g. location for online events and joinLink for offline)
      // there is a problem with 'required' directive attached to them:
      // the required validator errors are not removed when switching the online checkbox
      // we need this directive for proper styling of the elements and for attaching some extra aria-* properties
      // https://angular.io/guide/form-validation#validator-functions
      // "Notice that the required attribute is still present in the template. Although it's not necessary for validation, it should be retained to for accessibility purposes."
      location        : [ '' /*, conditionalValidator(() => CalendarEventType.isOffline(this.eventForm.get('type').value), Validators.required, 'location') */],
      joinLink        : [ '' /*, conditionalValidator(() => CalendarEventType.isOnline(this.eventForm.get('type').value), Validators.required, 'joinLink') */],
      bookingLink     : [''],
      marker          : [ [], Validators.nullValidator ],
      calendarName    : [ { value: '', disabled: true } ],
      calendarId      : [ '', Validators.required ],
      timeFrom        : [ moment(now).add(1, 'hours').startOf('hour') , Validators.required ],
      timeTo          : [ undefined, Validators.required ],
      timeBookingFrom : [ moment(now) ],
      timeBookingTo   : [ undefined ],
      seats: this.createSeatsForm(),
      // NOTE: values of disabled controls are not included in form value -> use rawValue instead,
      // such control will be always in state invalid
      languageName    : [ { value: '', disabled: true } ],
      languageCode    : [ '', Validators.required ],
      countryCodes    : [ [] ],
      countryCodesExcluded: [ false ],
      timezones       : [ [] ],
      timezone        : [ '' ],
      editorsTerm     : [],
      viewersTerm     : [],
      attendeesTerm   : [],
      media           : [],
      geocodingEnabled: true
    });
    return eventForm;
  }

  protected createAttendeeForm(): FormGroup {
    return this.createSeatsForm();
  }

  protected createSeatsForm(): FormGroup {
    return this.formBuilder.group({
      online : [undefined, [seatsValidator()]],
      offline: [undefined, [seatsValidator()]]
    });
  }

  protected getEventSeats(seats: Seats, type: CalendarEventType) {
    return Object.keys(seats).reduce((result, key) => {
      if ((key=='online'  && CalendarEventType.isOnline(type) ||
           key=='offline' && CalendarEventType.isOffline(type))) {
        result = result.add(Seats.create({[key]: seats[key]}));
      }
      return result;
    }, new Seats());
  }

  onCalendarCreate(event: MouseEvent) {
    this.onCalendarCreateRequest.emit((calendar: Calendar) => {
        if (calendar?.id) {
          this.logger.debug('onCalendarCreate.callback', {calendar});
          let calendars = this.calendars$.getValue();
          if (!calendars.find(c => c.id==calendar.id)) {
            calendars = [...calendars, calendar];
            this.calendars$.next(calendars);
          }
          this.eventForm.get('calendarId').setValue(calendar?.id)
        }
    });
  }

  onCalendarSelect() {
    this.panel = 'calendars';
  }

  onCalendarSelectorSelectionChange(selected: string[]) {
    this.logger.debug('onCalendarSelectorSelectionChange', selected);
    if (selected.length) {
      this.eventForm.get('calendarId').setValue(selected[0]);
    }
    this.panel = 'main';
  }

  onLanguageSelect() {
    this.panel = 'languages';
    // window.setTimeout(() => this.languageSelector.scrollToPosition(0));
  }

  onLanguageSearchTermChange(term: string) {
    this.languageSearchTerm$.next(term);
  }

  onLanguageSelectorSelectionChange(language: Language) {
    this.logger.debug('onLanguageSelectorSelectionChange', language);
    this.panel='main';
    this.eventForm.get('languageCode').setValue(language.code);
  }

  onLanguageSelectorLruChange(languages: string[]) {
    this.logger.debug('onLanguageSelectorLruChange', languages);
    this.onLanguageLruChange.emit(languages);
  }

  onTimezoneSelect(event: Event) {
    event.stopPropagation();
    this.panel = 'timezones';
    // window.setTimeout(() => this.timezoneSelector.scrollToPosition(0));
  }

  onTimezoneSelectorSelectionChange(timezone: Timezone) {
    this.logger.debug('onTimezoneSelectorSelectionChange', timezone);
    // called just to immediately update and open the timezones panel
    // the list will still be updated once the timezoneLru$ emits -
    // firing of lruChange event by the timezones selector triggers onLanguageLruChange emission
    // which is handled by the parent which updates the timezone lru list
    // on the server and in the local source -> Properties
    this.onTimezoneAdd(timezone.id);
    this.panel = 'main';
  }

  onTimezoneSelectorLruChange(timezones: string[]) {
    this.logger.debug('onTimezoneSelectorLruChange', timezones);
    this.onTimezoneLruChange.emit(timezones);
  }

  onTimezoneAdd(timezone: string) {
    const timezonesControl =  this.eventForm.get('timezones');
    let timezones: string[] = timezonesControl.value;
    if (!timezones.includes(timezone)) {
      timezones = [...timezones];
      timezones.splice(1, 0, timezone);
      timezonesControl.setValue(timezones);
      if (this.timezonesPanel.closed) {
        this.timezonesPanel.open();
      }
    }
  }

  onTimezoneRemove(timezone: string) {
    if (timezone!=this.localTimezone) {
      if (this.selectedTimezone == timezone) {
        this.onTimezoneChanged(this.selectedTimezone = this.localTimezone)
      }
      const timezonesControl = this.eventForm.get('timezones');
      this.logger.debug('onTimezoneRemove', { timezones: timezonesControl.value, timezone });
      // const timezones = timezonesControl.value;
      // timezonesControl.setValue(timezones.filter(tz => tz!=timezone));
      this.onTimezoneLruChange.emit(timezonesControl.value.filter(tz => tz!=timezone));
    }
  }

  onTimezoneChanged(timezone: string) {
    this.selectedTimezone = timezone;
    const oldFormValue = this.eventForm.getRawValue();
    const oldDates = (({timeCreated, timeFrom, timeTo, timeBookingFrom, timeBookingTo}) =>
      ({timeCreated, timeFrom, timeTo, timeBookingFrom, timeBookingTo}))(oldFormValue);
    const newDates: any = Object.entries(oldDates).reduce((accumulator, [key, value]: [ string | number | symbol, any ]) =>
      ({...accumulator, [key]: value ? moment(value).tz(timezone) : value} ), {} as any);
    // PROBLEM: changing the min/max date ranges OR value of NgxMatDatetimePicker control triggers eventForm.valueChange emission.
    // dirty change detector detects the change in NgxMatDatetimePicker control values and reports pristine = false
    // SOLUTION:  Configure the change detector api with form value transformer which is used
    // to retrieve the normalized form value with all datetime values relative to the event timezone
    // the event should have home timezone for all its datetime properties.
    this.timeFromMin = this.timeToMin = this.timeBookingFromMin = this.timeBookingToMin = newDates.timeCreated;
    // this.timeToMin = this.timeBookingFromMax = this.timeBookingToMax = newDates.timeFrom;
    const patch = Object.entries(newDates)
      .filter(([key, value]) => oldDates[key]!=value)
      .reduce((accumulator, [key, value]) => ({...accumulator, [key]: value}), {});
    this.eventForm.patchValue(patch);
    this.logger.debug('onTimezoneChanged', { oldDates, newDates, oldFormValue, 'newFormValue': this.eventForm.value });
  }

  onTimezoneSearchTermChange(term: string) {
    this.logger.debug('onTimezoneSearchTermChange', term);
    this.timezoneSearchTerm$.next(term);
  }

  onTimeFromChange(event: NgxMatDatepickerInputEvent<any>) {
    this.timeToMin = this.timeBookingFromMax = this.timeBookingToMax = event.value;
  }

  onTimeToChange(event: NgxMatDatepickerInputEvent<any>) {
  }

  onTimeBookingFromChange(event: MatDatepickerInputEvent<moment.Moment, moment.Moment | null>) {
    this.timeBookingToMin = event.value;
  }

  onTimeBookingToChange(event: MatDatepickerInputEvent<moment.Moment, moment.Moment | null>) {
  }

  onTypeSelect(type: CalendarEventType, previous: CalendarEventType = undefined) {
    if (type!=previous) {
      this.eventForm.get('type').setValue(type);
    }
  }

  onMediaPlay(media: Media) {
    this.mediaViewerOverlayService.openBottomSheet({ media:()=>media,
      // actionsTemplate: isProjectMedia ? this.mediaActions : null,
      onAction: (action: MediaAction) => {
        //this.logger.debug('MEDIA ACTION', action);
        if (action.id === 'contact' || action.id === 'info') {
          // this.onTapContact();
        }
      },
      onComplete: (media: Media) => {}
    });
  }

  onMediaSelect(media: Media) {
    this.mediaService.updateSearchTerm('');
    this.mediaService.setTypedFilters({[FilterTypes.NAVIGATION]: ['event.intro'/*, 'project.intro'*/]},REMOVE_ALL_OTHER_TYPES);
    this.panel = 'media';
  }

  onMediaSelectorSelectionChange(media: Media) {
    this.eventForm.get('media').setValue(media);
    this.panel = 'main';
  }

  onMediaRemove(media) {
    if (media!=this._defaultMedia) {
      this.defaultMedia.then(defaultMedia => {
        this.eventForm.get('media').setValue(defaultMedia);
      })
    }
  }

  onMediaUpload() {
    this.translateService
      .get('events.media.defaultName', { eventName: this.eventForm.get('name').value })
      .subscribe((mediaName) => {
        // this.logger.info('onUploadMedia', 'mediaName', mediaName);
        const language = this.eventForm.get('languageCode').value || this.translateService.currentLang;
        const media = { name: mediaName, tags: ['event.intro'], language: language };
        const options = { uploadTypes: [UPLOAD_TYPE.VIDEO, UPLOAD_TYPE.IMAGE], media: media };
        this.mediaService
          .uploadMedia(this.elementRef, options)
          .subscribe(mediaUploadRef => {
            const {uploadId, uploadRef, complete} = mediaUploadRef;
            complete.then(mediaUploads => {
              const error = mediaUploads.find(mediaUpload => mediaUpload.error || !mediaUpload.media);
              if (error) {
                this.translateService.get('events.media.uploadError').subscribe((translatedMessage) => {
                  this.snackBar.open(translatedMessage, this.translateService.instant('actions.close'), {
                    duration: 2000,
                    horizontalPosition: 'right',
                    // verticalPosition: 'bottom'
                  });
                });
              }
            });
          });
      });
  }

  onPanelSlide(event: SlideEvent) {
    this.logger.debug('onPanelSlide', event);
    // clear selector to remove the corresponding dom element when it has been fully slided out
    if (event.state==SlideState.END && event.slideIn=='main' && this._selector) {
      // schedule selector removal to run once the main thread stack is empty
      // this is required to avoid rendering issues when selecting a calendar in calendar selector (material selection list)
      // if we remove the selector immediately once the slide animation completes the view is switched from event details form
      // to an empty selector window (just the controls template i.e. cancel button is rendered).
      // most likely the reason for this is related to the input focus - i.e. focus is assigned to the material list component
      // and browser tries to ensure that this control is visible.
      window.setTimeout(() => {
        if (typeof this._selector['scrollToPosition'] === 'function') {
          this._selector['scrollToPosition'](0);
        } else {
          this._selector = undefined;
        }
      } );
    }
  }

  protected _defaultMedia: Promise<Media>;
  get defaultMedia(): Promise<Media> {
    if (!this._defaultMedia) {
      const image = new Image();
      const imageUrl = `/assets/media/cover/default.jpg`; //`/assets/images/logo-light.png`;
      image.src = imageUrl;
      this._defaultMedia = new Promise<Media>((resolve, reject) => {
        image.onload = () => {
          const link:ImageLink = {
            type: 'image',
            link: imageUrl,
            contentType: 'image/png',
            width: image.width,
            height: image.height,
            size:0  // unknown
          };
          resolve ({
            type  : 'media',
            links : [ link ],
            cover : link,
            ready : true,
            valid : true,
            mediaType: MediaType.image,
            properties: { cover: link },
            rootPath: ''
          } as Media);
        };
        image.onerror = (error) => reject(error);
      });
    }
    return this._defaultMedia;
  }

  onSeatsChange(control: AbstractControl, delta: number, range: SeatsRange, /*event?: Event*/) {
    // event?.stopPropagation(); // prevent focusing the input
    const value = Math.max(0, ((control.value as number) || 0) + delta);
    this.logger.debug("onSeatsChange", {delta, range, value});
    if (range.max >= value && value >= range.min) {
      control.setValue(value || null);
    }
  }

  onAttendeeSeatsUpdate() {
    const seats = this.getEventSeats(this.attendeeForm.value, this.event.type);
    this.logger.debug('onAttendeeSeatsUpdate');
    this.onAttendeeSeatsChange.emit(seats);
  }

  onChangeGeocoding(checked: boolean) {
    this.eventForm.get('geocodingEnabled').setValue(checked);
    if (checked) {
      const markerControl = this.eventForm.get('marker');
      const locationControl = this.eventForm.get('location');
      const marker = markerControl.value;
      if (marker?.length) {
        this.geocodeService
          .reverseGeocode({ lat: marker[0].latitude, lng: marker[0].longitude })
          .subscribe(location => locationControl.setValue(location));
      } else {
        const location = locationControl.value;
        this.geocodeService.geocode(location).subscribe(marker => markerControl.setValue([marker]));
      }
    }
  }

  sameDay(timeFrom: number, timeTo: number):boolean {
    return moment(timeFrom).isSame(timeTo,'day');
  }
}
