/* projects/tasks/src/lib/components/task-list/task-list.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: column;
  position: relative;
}
:host:not([dense]) .event .controls {
  margin-right: 4px;
}
virtual-section-scroller {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
div.actions.more {
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-header {
  position: relative;
  width: 100%;
  padding: 4px 2px;
  background-color: white;
}
.section-header > span {
  display: inline-block;
  padding: 1px 8px;
}
.indicator {
  height: 4px;
  background-color: greenyellow;
}
.indicator.saving {
  background-color: red;
}
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.container.dragging {
  transition: none;
  z-index: 1000;
}
.container.more .task {
  opacity: 0.5;
  animation: loading 2s infinite;
}
.container.more .task .label {
  padding: 6px;
}
.container.more .task mat-checkbox {
  color: orange;
}
.container .task {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 48px;
  margin: 5px 8px;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.container .task:hover {
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.container .task app-task-list-item {
  flex: 1;
  cursor: pointer;
  overflow: hidden;
}
.container .task .controls:first-of-type {
  padding: 8px;
}
.container .task .list-indicator {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}
:host-context(.color-scheme-dark) .section-header {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .task {
  background-color: #333;
}
@keyframes loading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
/*# sourceMappingURL=task-list.component-NNY7ZYCJ.css.map */
