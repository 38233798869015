import {Contact, NULL_CONTACT, VersionedId} from 'core';
import {Inject, InjectionToken} from "@angular/core";
import {HasTranslations} from "./translations";
import {Calendar, NULL_CALENDAR} from "./calendar";
import {Media} from "media";
import {Attendee} from "./attendee";
import {ProvisioningType, ProvisioningTypes} from "./provisioning-type";
import {Seats, SeatsRegistry} from "./seats";
import {Message} from "messaging";
import cloneDeep from "lodash/cloneDeep";

export const CalendarEventTypes = ProvisioningTypes;
export type CalendarEventType   = ProvisioningType;
export namespace CalendarEventType  {
  export const online  : CalendarEventType = ProvisioningTypes[0];
  export const offline : CalendarEventType = ProvisioningTypes[1];
  export const hybrid  : CalendarEventType = ProvisioningTypes[2];
  export function isOnline(type: CalendarEventType) : boolean  { return ProvisioningType.isOnline(type);  }
  export function isOffline(type: CalendarEventType): boolean  { return ProvisioningType.isOffline(type); }
  export function isHybrid(type: CalendarEventType) : boolean  { return ProvisioningType.isHybrid(type);  }
  export function types(): readonly CalendarEventType[] { return ProvisioningType.types(); }
}

// export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>
// export type CalendarEventPartial = AtLeast<CalendarEvent, 'type'>;

/*
 * HasReadonlyId is internal interface and should not be exported
 * Used just for CalendarEvent cloning purposes (see CalendarEvent.clone())
 */
interface HasReadonlyId { id: string; }
export class CalendarEvent extends HasTranslations implements HasReadonlyId {
  readonly id: string;
  readonly version : number;
  author: Partial<Contact>;
  name: string;
  info: string;
  timeCreated: number;
  timeUpdated: number;
  countryCodes: string[];
  countryCodesExcluded?: boolean;
  tags: string[];
  filters: {[key: string]: string};
  properties: any;
  timezoneId: string;
  timeFrom: number;
  timeTo: number;
  timeBookingFrom: number;
  timeBookingTo: number;
  calendar: Partial<Calendar>;
  attachmentIds: string[];
  seats: SeatsRegistry;
  location: string;
  joinLink: string;
  bookingLink: string;
  language: string;
  latitude: number;
  longitude: number;
  latLngHash: string;
  editorsTerm: string;
  viewersTerm: string;
  attendeesTerm: string;
  media: Media;

  attendee?: Attendee; // user-specific event attendee object if available
  permission?: {
    edit?: boolean;
    attend?: boolean;
  }
  //editable: boolean;

  constructor(init?: Partial<CalendarEvent>) {
    super();
    // const seats = new SeatsRegistry();
    // if (init?.seats) {
    //   seats.merge(init.seats);
    // }
    // Object.assign(this, {...init, seats: seats});
    Object.assign(this, init, { seats: SeatsRegistry.merge(init?.seats)});
    if (this.attendee && !(this.attendee instanceof Attendee)) {
      this.attendee = new Attendee(this.attendee);
    }
  }

  get isNew(): boolean {
    return !this.id;
  }

  /*
  get timezones(): string[] {
    return this.properties?.timezones || [];
  }

  set timezones(timezones: string[]) {
    set(this, 'properties.timezones', timezones);
  }
  */

  get type(): CalendarEventType {
    return this.seats.total.type;
  }

  get clone(): CalendarEvent {
    const clone = cloneDeep(this);
    (<HasReadonlyId>clone).id = undefined;
    if (!clone.properties) {
      clone.properties = {};
    }
    clone.properties.parentId = clone.id;
    return clone;
  }
}

export class NullCalendarEvent extends CalendarEvent {

  constructor(@Inject(NULL_CONTACT)  public author: Contact,
              @Inject(NULL_CALENDAR) public calendar: Calendar) {
    super();
    this.name = '';
    this.info = '';
    this.timeCreated = this.timeUpdated = new Date().getTime();
    this.language = this.author.countryCode;
    this.seats = new SeatsRegistry(Seats.create({offline: undefined}), Seats.create({offline: undefined}));
    this.permission = {
      edit: true
    }
    //this.editable = true;
  }

  getTranslations(): string[] {
    return [];
  }

  hasTranslations(): boolean {
    return false;
  }
}


export const NULL_CALENDAR_EVENT = new InjectionToken('NullCalendarEvent');


export interface CalendarEventRelatedMessage extends Message {
}

export const CalendarEventSubscriptionMessageType : string = "calendar.event.subscription";
export interface CalendarEventSubscriptionMessage extends CalendarEventRelatedMessage {
  subscribed: VersionedId[];
}

export const CalendarEventUpdateMessageType : string = "calendar.event.update";
export interface CalendarEventUpdateMessage extends CalendarEventRelatedMessage {
  event: CalendarEvent;
}

export enum FilterKeys {
  TIME_PREFIX = 'time.',
  LANGUAGE_PREFIX  = 'language.',
  CALENDAR_PREFIX  = 'calendar.',
  CALENDAR_EXCLUDE_PREFIX = 'calendar.exclude.',
  PERIOD_PREFIX = 'period.',
  PERIOD_BACKWARD_SUFFIX  = '.backward'
}
