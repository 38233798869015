<mat-toolbar color="primary">
  <mat-toolbar-row class="main">
    <button tabindex="-1" mat-icon-button (click)="close(false)"><mat-icon>close</mat-icon></button>
    <ng-container *ngIf="type()=='direct'">
      <span translate>chat.create.direct</span>
    </ng-container>
    <ng-container *ngIf="type()=='group'">
      <span translate>chat.create.group</span>
      <button tabindex="-1" mat-icon-button class="done" [disabled]="!save()" (click)="saveGroupChat()"><mat-icon>done</mat-icon></button>
    </ng-container>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="type()=='group'">
    <mat-icon class="field-icon">label_outline</mat-icon>
    <div class="field-container">
      <mat-form-field floatLabel="never">
        <mat-label translate>chat.group.name</mat-label>
        <input unmodified-input matInput
               [disabled]="disabled()"
               [(ngModel)]="groupName">
      </mat-form-field>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="type()=='group'">
    <mat-icon class="field-icon">filter_list</mat-icon>
    <div class="field-container" *ngLet="groupTermErrorStateMatcher() as matcher">
      <mat-form-field floatLabel="never">
        <mat-label translate>chat.group.term</mat-label>
        <input unmodified-input matInput
               [disabled]="disabled() || (selectedParticipantIds().length>0 && !(groupTerm()?.trim()?.length>0))"
               [(ngModel)]="groupTerm"
               [errorStateMatcher]="matcher">
        <mat-hint *ngIf="groupTerm()?.length>0">{{'chat.conversation.term.participantCount' | translate:{count:matcher?.data?.calculatedParticipants??0} }}</mat-hint>
        <mat-error *ngIf="matcher?.data?.errorLabel?.length>0">{{matcher.data.errorLabel | translate:matcher.data.errorParameters??{} }}</mat-error>
      </mat-form-field>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <mat-icon class="field-icon">search</mat-icon>
    <div class="field-container">
      <mat-form-field>
        <input unmodified-input matInput
               [disabled]="disabled() || groupTerm()?.length>0"
               [(ngModel)]="searchTerm">
      </mat-form-field>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="content">
  <app-contact-list [entities$]="contacts$" [cacheId$]="cacheId$"
    [disabled]="disabled() || groupTerm()?.trim().length>0"
    [avatarTemplateRef]="avatarTemplate"
    [contactInfoTemplateRef]="contactInfoTemplate"
    [controlsTemplateRef]="controlsTemplate"
    [itemMinSize]="60" [avatarSize]="50" [dividerLeftMargin]="74"
    (selectionChanged)="onSelect($event)">
    <ng-template #avatarTemplate
                 let-contact="contact"
                 let-src="src"
                 let-width="width"
                 let-height="height"
                 let-index="index"
                 let-total="total"
                 let-last="last">
      <img matListItemAvatar
           [src]="src"
           [style.width]="width"
           [style.height]="height"
           onload="this.style.opacity=1"/>
      <presence [contactId]="contact?.id"></presence>
    </ng-template>
    <ng-template #contactInfoTemplate
                 let-contact="contact"
                 let-index="index"
                 let-total="total"
                 let-last="last">
      <presence-info *ngIf="contact?.id" [contactId]="contact.id"></presence-info>
    </ng-template>
    <ng-template #controlsTemplate
                 let-contact="contact"
                 let-index="index"
                 let-total="total"
                 let-last="last">
      <mat-icon *ngIf="(selectedParticipants() || {})[contact.id]">check_circle</mat-icon>
    </ng-template>
  </app-contact-list>
</div>
<app-status-bar><span>{{(contacts$ | async).length | number}}</span></app-status-bar>

