import { Injectable } from '@angular/core';
import { Contact, Topic } from 'core';
import { ReplaySubject, Observable } from 'rxjs';
import {distinctUntilChanged} from "rxjs/operators";
import isEqual from "lodash/isEqual";

@Injectable({
  providedIn: 'root'
})
export class ContactDetailDataShareService {

  private contactSubject: ReplaySubject<Contact> = new ReplaySubject<Contact>(1);
  getContact$: Observable<Contact> = this.contactSubject
      .asObservable()
      .pipe(distinctUntilChanged((previous, current) => isEqual(previous, current)));

  setContact(contact: Contact): void {
    this.contactSubject.next(contact);
  }

  private contactFilterSubject: ReplaySubject<Topic[]> = new ReplaySubject<Topic[]>(1);
  getContactFilter$: Observable<Topic[]> = this.contactFilterSubject.asObservable();

  setContactFilter(filter: Topic[]): void {
    this.contactFilterSubject.next(filter);
  }

  private isBasicSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  isBasic$: Observable<boolean> = this.isBasicSubject.asObservable();

  setIsBasic(isBasic: boolean): void {
    this.isBasicSubject.next(isBasic);
  }

  private reachFilterSubject: ReplaySubject<Topic[]> = new ReplaySubject<Topic[]>(1);
  getReachFilter$: Observable<Topic[]> = this.reachFilterSubject.asObservable();

  setReachFilter(filter: Topic[]): void {
    this.reachFilterSubject.next(filter);
  }

}
