import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {clipboardCopy, ENVIRONMENT} from "core";
import {Attachment} from "messaging";
import {BasicContainerComponent, EmojiPicker, ManualMenuTrigger, PressEvent} from "shared";
import {ChatService} from "../../../chat.service";
import {MessageSpace} from "../../conversation/chat-conversation.component";
import {AuthenticationService} from "auth";
import {MediaViewerOverlayService} from "media";
import {ChatTimelineMessage, RenderPosition} from "../../../store/models";
import {
  ChatMessageDetailsContainerComponent,
  ChatMessageDetailsContext
} from "../../message-details-container/chat-message-details-container.component";
import {LayoutService} from "layout";
import {TranslateService} from "@ngx-translate/core";
import {delay, firstValueFrom, take, takeUntil} from "rxjs";

@Component({
  selector: 'chat-message-row',
  templateUrl: './chat-message-row.component.html',
  styleUrls: ['./chat-message-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageRowComponent extends BasicContainerComponent {
  @ViewChild('contextMenuTrigger') contextMenuTrigger: ManualMenuTrigger;
  @ViewChild('contextMenuTrigger', {read: ElementRef}) messageContainerElementRef: ElementRef;
  @ViewChild('messageTemplate', { static: true }) messageTemplate: TemplateRef<any>;
  @ViewChild('emojiHost',  { read: ViewContainerRef }) emojiHost:ViewContainerRef;

  // [position]="message.from?.id ? 'left' : 'right'"
  protected _message:ChatTimelineMessage;
  protected _total:number;
  protected _index:number;
  protected _header:boolean;
  protected _parent:boolean;
  protected _space:MessageSpace;

  public mine:boolean;
  public status:boolean;  // true if mine is undefined, true for centered messages
  public delete:boolean = false;

  public blockEdit = false;

  constructor(public chatService: ChatService,
              public authenticationService: AuthenticationService,
              public mediaViewerOverlayService: MediaViewerOverlayService,
              public layoutService:LayoutService,
              public translateService: TranslateService, // app specific translateService
              public elementRef: ElementRef,
              @Inject(ENVIRONMENT) private environment: any) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.contextMenuTrigger._openedBy = null;
  }

  onTapMessage(event:PressEvent) {
    console.log("onTapMessage()",this.contextMenuTrigger,event);
    if (event.auto) {
      let clonedElement:HTMLElement = undefined;
      this.delete = false;
      this.contextMenuTrigger.openMenu();
      this.contextMenuTrigger.menuClosed.asObservable().pipe(
        takeUntil(this.onDestroy$),
        take(1),
        delay(1000)
      ).subscribe(()=>{
        clonedElement?.parentElement?.removeChild(clonedElement)
      });
      let hostBoundingRect = this.messageContainerElementRef.nativeElement.getBoundingClientRect();
      clonedElement = this.messageContainerElementRef.nativeElement.cloneNode(true);
      clonedElement.style.position = 'fixed';
      clonedElement.style.width  = hostBoundingRect.width+'px';
      clonedElement.style.left   = hostBoundingRect.left+'px';
      clonedElement.style.top    = hostBoundingRect.top+'px';
      let menuRoot = this.emojiHost.element.nativeElement;
      console.log("menuRoot",menuRoot,menuRoot.parentElement);
      while (!!menuRoot.parentElement && !menuRoot.parentElement.classList.contains('cdk-overlay-container')) {
        console.log("menuRoot",menuRoot,menuRoot.parentElement);
        menuRoot = menuRoot.parentElement;
      }
      //console.log("menuRoot",menuRoot,menuRoot.parentElement);
      menuRoot.insertBefore(clonedElement,menuRoot.children[0]);
    }
  }

  @Input()
  set message(message: ChatTimelineMessage) {
    this._message  = message;
    this.mine      = this.chatService.isUserMessage(message);
    this.status    = this.chatService.isStatusMessage(message);
    this.blockEdit = message!=null && message.attachments?.length==1 && message.attachments[0].type=='audioRecording';
  }

  get message():ChatTimelineMessage {
    return this._message;
  }

  get attachments():Attachment[] {
    return this._message?.attachments || [];
  }

  get messageBody() {
    return (this._message?.bodyText || '').replace(/^\n+|\n+$/g,'').replace(/\n/g,'<br/>');
  }

  get hasBody() {
    return this._message?.bodyText?.length>0;
  }

  copyBody() {
    firstValueFrom(this.translateService.get(this.messageBody))
      .then(value => {
        clipboardCopy(value);
      });
  }

  @Input()
  set space(space: MessageSpace) {
    this._space = space;
    //console.log("SPACE.1",space);
  }

  get space():MessageSpace {
    return this._space;
  }

  @Input()
  set index(index:number) {
    this._index = index;
  }

  get index():number {
    return this._index;
  }

  @Input()
  set total(total:number) {
    this._total = total;
  }

  get total():number {
    return this._total;
  }

  get position():RenderPosition {
    return this.status ? 'center' : this.mine==true ? 'right' : this.mine==false ? 'left' : 'center';
  }

  @Input()
  set header(header:boolean) {
    this._header = header;
  }

  get header():boolean {
    return !!this._header;
  }

  @Input()
  set parent(parent:boolean) {
    this._parent = parent;
  }

  get parent():boolean {
    return !!this._parent;
  }

  /*
  get photo() {
    return this.position=='left'
      ? 'https://randomuser.me/api/portraits/men/18.jpg'
      : `${this.environment.serverUrl}/v1.0/content/avatar/${this.user.version || 0}/${this.user.id}.jpg`;
  }*/

  onTapDelete(event:Event) {
    //console.log("onTapDelete",{...event});
    if (!this.delete) {
      this.delete = true;
      event.stopPropagation();
    } else {
      this.chatService.deleteMessage(this.message);
    }
  }

  openMessageDeliveryInfo(message:ChatTimelineMessage) {
    if (!!message?.conversationId) {
      this.layoutService.details.setContent('component', {
        type: ChatMessageDetailsContainerComponent,
        context: <ChatMessageDetailsContext>{
          message: message,
          view: 'delivery'
        }
      });
      this.layoutService.details.ensure(true);
    }
  }

  openMessageReplyInfos(message:ChatTimelineMessage) {
    if (!!message?.conversationId) {
      this.layoutService.details.setContent('component', {
        type: ChatMessageDetailsContainerComponent,
        context: <ChatMessageDetailsContext>{
          message: message,
          view: 'replies'
        }
      });
      this.layoutService.details.ensure(true);
    }
  }

  selectEmoji(event:Event,s: string) {
    event.preventDefault();
    event.stopPropagation();
    console.log("EmojiPicker.selectEmoji",event,s);
    this.contextMenuTrigger.closeMenu();
  }

  openEmojiSelector(event:Event) {
    event.preventDefault();
    event.stopPropagation();
    this.emojiHost.element.nativeElement.parentElement.style.display = 'block';
    const emojoPickerRef = EmojiPicker.create(this.emojiHost);
    this.contextMenuTrigger.menuClosed.asObservable().pipe(
      takeUntil(this.onDestroy$),
      take(1),
      delay(1000)
    ).subscribe(()=>{
      this.emojiHost.element.nativeElement.parentElement.style.display = '';
      emojoPickerRef.destroy();
    });
    emojoPickerRef.instance.emojiClick.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(event => {
      console.log("EmojiPicker.emojiClick",event);
      this.contextMenuTrigger.closeMenu();
      //this.onEmojiSelect(event);
    });
  }
}
