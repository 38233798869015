<div [class]="position+' '+mode" *ngLet="{
    view: view$ | async,
    time: time$ | async
  } as context">
  <div class="body">
    <div class="content">
      <ng-container *ngIf="mode=='reply'">
        <span *ngIf="context.view=='finished'"
              [innerHTML]="'chat.conference.info.reply' | translate:{participants:message.participants.length} | safeHtml"></span>
        <span *ngIf="context.view=='running'"
              [innerHTML]="'chat.conference.info.running' | translate:{participants:message.participants.length} | safeHtml"></span>
        <span *ngIf="context.view=='canceled'" translate>chat.conference.info.canceled</span>
      </ng-container>
      <ng-container *ngIf="mode!='reply'">
        <ng-container *ngLet="(time$ | async) as time">
          <span *ngIf="context.view=='finished'"
                [innerHTML]="'chat.conference.info.finished' | translate:{
                    participants:message.participants.length, time
                } | safeHtml"></span>
          <span *ngIf="context.view=='running'"
                [innerHTML]="'chat.conference.info.running' | translate:{
                    participants:message.participants.length, time
                } | safeHtml"></span>
          <span *ngIf="context.view=='canceled'" translate>chat.conference.info.canceled</span>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
