import {PresenceState} from "./models";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SubscribableState, Presence} from "core";

export const presenceFeatureKey = 'presence';

export interface PresencesState extends SubscribableState<Presence> {
}

export const initialPresencesState : PresencesState = {
  subscriptions: {},
};

export const initialPresence : Presence = {
  state: PresenceState.invisible,
  timeSince: 0,
  timeActive: 0,
  contactId: undefined  // must be defined!!
}


// do not export - leads to naming conflicts with other feature State interfaces
export interface State {
  readonly [presenceFeatureKey] : PresencesState;
}

export const selectPresencesState = createFeatureSelector<State,PresencesState>(presenceFeatureKey);

export const selectPresenceIds = createSelector(
  selectPresencesState,
  state => Object.keys(state.subscriptions)
);

export const selectPresence = (id:string) => createSelector(
  selectPresencesState,
  (state):Presence => state.subscriptions[id]?.entity
);
