import {Action, createAction, props} from "@ngrx/store";
import {Contact} from "core";

export const contactLoadRequestAction = createAction(
    'contact.load.request'
);

export const contactSubscribeAction = createAction(
    'contact.subscribe',
    props<{
        id: string,
        contact?: Contact // provided by reducer
    }>()
);

export const contactUnsubscribeAction = createAction(
    'contact.unsubscribe',
    props<{
        id: string,
        contact?: Contact // provided by reducer
    }>()
);

export const contactUpdateAction = createAction(
    'contact.update',
    props<{
        correlationId?: string,
        contact: Contact,
        previous?: Contact
    }>()
);

export const contactUpdateDoneAction = createAction(
    'contact.update.done',
    props<{
        correlationId?: string,
        contact: Contact
    }>()
);

export const contactUpdateFailedAction = createAction(
    'contact.update.failed',
    props<{
        correlationId?: string,
        contact: Contact,
        error: any
    }>()
);

export const contactDeleteAction = createAction(
    'contact.delete',
    props<{
        correlationId?: string,
        contact: Contact
    }>()
);

export const contactDeleteDoneAction = createAction(
    'contact.delete.done',
    props<{
        correlationId?: string,
        contact: Contact
    }>()
);

export const contactDeleteFailedAction = createAction(
    'contact.delete.failed',
    props<{
        correlationId?: string,
        contact: Contact,
        error: any
    }>()
);

export const contactSelectAction = createAction(
    'contact.select',
    props<{
        selectedIndex: number
    }>()
);

export const contactSetTypedFiltersAction = createAction(
    'contact.set.typed.filters',
    props<{
        filters: {[type:string]:string[]},
        remove?:(type:string,filters:string[])=>boolean
    }>()
);

export const contactUpdateSearchTermAction = createAction(
    'contact.update.searchTerm',
    props<{
        term: string
    }>()
);

export const contactLoadPageAction = createAction(
    'contact.load.page',
    props<{
        cacheId : string,
        index : number,
        size : number
    }>()
);

export const contactLoadPageDoneAction = createAction(
    'contact.load.page.done',
    props<{
        cacheId : string,
        index : number,
        data : Contact[],
        total : number,
        parent? : Contact,
        dispatch : (action:Action)=>void
    }>()
);

export const contactLoadPageFailedAction = createAction(
    'contact.load.page.failed',
    props<{
        cacheId : string,
        index : number,
        size : number,
        error: any
    }>()
);


export const contactUplineLoadAction = createAction(
    'contact.upline.load',
    props<{
        contactId : string
    }>()
);

export const contactUplineLoadDoneAction = createAction(
    'contact.upline.load.done',
    props<{
        contactId : string
        upline : Contact[]
    }>()
);

export const contactUplineLoadFailedAction = createAction(
    'contact.upline.load.failed',
    props<{
        contactId : string
        error: any
    }>()
);

export const groupAuthorsLoadAction = createAction(
    'group.authors.load',
    props<{
        groupId : string,
        force?: boolean
    }>()
);

export const groupAuthorsLoadDoneAction = createAction(
    'group.authors.load.done',
    props<{
        groupId : string
        authors : Contact[]
    }>()
);

export const groupAuthorsLoadFailedAction = createAction(
    'group.authors.load.failed',
    props<{
        groupId : string
        error: any
    }>()
);

export function asContact(contact:any):Contact {
  return !contact ? undefined :
    (contact instanceof Contact
    ? contact
    : new Contact(contact));
}
