<div [class]="position+' '+mode">
  <ng-container *ngIf="!!parent && !!message?.parentId && (mode=='bubble' || mode=='header')">
    <ng-container *ngIf="chatService.getTimelineMessage$(message.conversationId,message.parentId) | async;
                         let replyMessage">
      <div class="reply" (click)="openMessageReplyInfos(replyMessage)">
        <chat-message-container
        [message]="replyMessage"
        [mode]="'reply'">
        </chat-message-container>
      </div>
    </ng-container>
  </ng-container>
  <attachment-section-container #attachmentSectionContainer *ngIf="mode!='reply' && attachments?.length>0"
    [factoryAccessor]="attachmentService.getViewerFactory.bind(attachmentService)"
    [message]="message"
    [attachments]="attachments"
    (updated)="updateSections(attachmentSectionContainer.attachmentSections)">
  </attachment-section-container>
  <div class="body">
    <div class="attachment" *ngIf="mode=='reply' && message.attachments?.length>0">
      <attachment-square-container [attachments]="message.attachments"></attachment-square-container>
    </div>
    <div class="content">
      <div class="from" *ngIf="(mode=='reply') && message.from?.name">{{message.from.name}}</div>
      <span *ngIf="!messageBody && mode=='reply'" translate>chat.conversation.attachment</span>
      <span class="text" [innerHTML]="messageBody | markup:{clean:mode=='reply'} | safeHtml"></span><span class="space"></span>
      <div *ngIf="mode=='bubble'||mode=='header'" class="time">{{message?.timeCreated | date:('chat.conversation.time' | translate)}}<message-delivery-state *ngIf="position=='right'" [log]="false" [message]="message" [conversationData]="chatService.getConversationData$(message.conversationId) | async"></message-delivery-state></div>
    </div>
  </div>
  <div class="arrow"></div>
</div>
