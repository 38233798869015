import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AccordionSectionComponent, BasicComponent} from 'shared';

@Component({ template: ''})
export abstract class ContactDetailSectionComponent extends BasicComponent implements AccordionSectionComponent {

  protected _context: any;

  @Input()
  set context(context: any) {
    this._context = context ?? {};
  }

  get context(): any {
    return this._context;
  }

  protected _isHidden: EventEmitter<boolean> = new EventEmitter();

  @Output()
  get isHidden() {
    return this._isHidden;
  }

  constructor() {
    super();
  }
}
