/* projects/contact/src/lib/components/contact-list/contact-list.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
  display: flex;
  flex-direction: column;
}
virtual-section-scroller {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.contact-item-container {
  position: relative;
}
.contact-item {
  font-size: 16px;
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 0 4px 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-item.disabled {
  opacity: 0.7;
}
.contact-item.hover {
  background: rgba(0, 0, 0, 0.04);
}
.contact-item > .mat-ripple {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.contact-item .mat-divider {
  margin: 0;
  margin-left: 84px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}
div.avatar {
  position: relative;
  line-height: 0;
}
div.avatar img {
  opacity: 0;
  width: 60px;
  height: 60px;
}
div.deleted {
  margin-left: 84px;
}
div.deleted.disabled {
  opacity: 0.7;
}
div.deleted h4.contact {
  color: gray;
  margin: 0;
}
.content {
  position: relative;
  flex: 1;
  padding: 8px 3px 8px 12px;
  min-height: 76px;
}
.content > .contact {
  padding: 8px 0 8px 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.content > .contact > div {
  width: 100%;
  overflow: hidden;
}
.content > .contact > div > h3 {
  display: block;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content > .contact > div > h3 > span {
  white-space: nowrap;
}
.content > .contact > div > h3 > a {
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
  white-space: nowrap;
}
.content > .contact > div > app-contact-join-info {
  display: block;
  overflow: hidden;
}
:host-context(.color-scheme-dark) .contact-item.hover {
  background: rgba(255, 255, 255, 0.1);
}
:host-context(.color-scheme-dark) .contact-item .mat-divider {
  border-top-color: #333;
}
.controls {
  margin-right: 8px;
  opacity: 1;
  transition: opacity 300ms ease;
  display: flex;
  align-items: center;
}
div.contact-item-container.dropFilesOver .controls {
  opacity: 0;
}
div.contact-item-container.dropFilesOver:before {
  font-family: "Material Icons";
  content: "\e2c6";
  color: var(--color-accent);
  font-size: 36px;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  animation: pulse-light 500ms infinite;
}
div.contact-item-container.dropFilesOver:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-width: 2px;
  border-style: solid;
  animation: pulse-light 500ms infinite;
}
:host-context(.color-scheme-dark) div.contact-item-container.dropFilesOver:before,
:host-context(.color-scheme-dark) div.contact-item-container.dropFilesOver:after {
  animation: pulse-dark 500ms infinite;
}
@keyframes pulse-light {
  0% {
    color: var(--color-accent-A200);
    border-color: rgba(208, 208, 208, 0.5019607843);
  }
  50% {
    color: var(--color-accent);
    border-color: #d0d0d0;
  }
  100% {
    color: var(--color-accent-A200);
    border-color: rgba(208, 208, 208, 0.5019607843);
  }
}
@keyframes pulse-dark {
  0% {
    color: var(--color-accent-A200);
    border-color: rgba(128, 128, 128, 0.5019607843);
  }
  50% {
    color: var(--color-accent);
    border-color: #808080;
  }
  100% {
    color: var(--color-accent-A200);
    border-color: rgba(128, 128, 128, 0.5019607843);
  }
}
/*# sourceMappingURL=contact-list.component-PROKCBEN.css.map */
