import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {Attachment} from "messaging";
import {Space} from "../../core/space";
import {AttachmentComponent} from "../../../attachment/core/attachment.component";
import {AudioRecordingAttachment, AudioRecordingAttachmentType, ChatTimelineMessage} from "../../../../store/models";
import {DomSanitizer} from "@angular/platform-browser";
import {MediaService, Playback} from "media";
import {MatButton} from "@angular/material/button";
import {ENVIRONMENT, Logger} from "core";
import {ChatService} from "../../../../chat.service";
import {Subscription} from "rxjs";
import {decodeUint8ArrayFromBase64, encodeUint8ArrayToBase64, isValidNumber} from "shared";

const DEFAULT_DATA_CALLBACK = (string)=>{};

@Component({
  selector: 'audio-recording-attachment-viewer',
  templateUrl: './audio-recording-attachment-viewer.component.html',
  styleUrls: ['./audio-recording-attachment-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioRecordingAttachmentViewerComponent implements AttachmentComponent, AfterViewInit, OnDestroy {
  @ViewChild('wave',   { static: false }) wave:   ElementRef;
  @ViewChild('audio',  { static: false }) audio:  ElementRef;
  @ViewChild('button', { static: false }) button: MatButton;
  @HostBinding('style.height') height: string;

  protected static instanceCounter = 0;

  protected _attachment:AudioRecordingAttachment = undefined;
  protected _attachments:Attachment[] = [];
  protected static _space:Space = {fixedWidth:false,fixedHeight:true,fixedRatio:false,height:30,width:200};
  protected _message:ChatTimelineMessage;
  protected ready:boolean = false;

  protected _audioData:string;
  protected _waveData:string;
  protected _waveArray:Uint8Array = undefined;
  protected _waveColor:string;
  protected _progressColor:string;

  public playback:Playback = undefined;
  public subscription:Subscription = undefined;
  public playing:boolean = false;
  public durationFormatted:string = undefined;
  public duration:number = undefined;
  public position:number = 0;

  @Input()
  set message(message:ChatTimelineMessage) {
    //this.logger.info("trigger.on.message",message!=this._message,message,"attachment",this._attachment);
    this._message = message;
    this.onAttachmentUpdate();  // trigger load of what we need
  }

  get message():ChatTimelineMessage {
    return this._message;
  }

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    this._attachments   = attachments;
    this._attachment    = <AudioRecordingAttachment>attachments?.find(attachment=>attachment.type==AudioRecordingAttachmentType);
    this._waveArray     = undefined;
    this._waveData      = undefined;
    this._audioData     = undefined;
    this._loadAudioData = this._loadWaveData = false;
    this.onAttachmentUpdate();  // trigger load of what we need
  }

  get attachments():Attachment[] {
    return this._attachments;
  }

  get audioAttachment(): AudioRecordingAttachment {
    return this._attachment;
  }

  get space():Space {
    return AudioRecordingAttachmentViewerComponent._space;
  }

  protected onAttachmentUpdate() {
    if (!!this.message && !!this.audioAttachment) {
      const audioAttachment = this.audioAttachment;
      const audioData       = audioAttachment.data?.length>0 ? audioAttachment.data : undefined;
      const waveData        = audioAttachment.wave?.length>0 ? audioAttachment.wave : undefined;
      const hasDuration     = isValidNumber(audioAttachment.duration);
      const hasAudioData    = audioData?.length>0;
      const hasWaveData     = waveData?.length>0;
      const loadAudioData   = !hasAudioData && (!hasDuration || !hasWaveData);
      const loadWaveData    = !hasWaveData && !hasAudioData && !loadAudioData;
      this.onUpdateDuration(audioAttachment.duration);
      this.onWaveDataLoaded(audioAttachment.wave);
      this.onAudioDataLoaded(audioAttachment.data);
      /*
      console.log("onAttachmentUpdate",this.instanceId,
        "\nduration",audioAttachment.duration,
        "\nloadAudioData",loadAudioData,audioAttachment.data?.substring(0,20),
        "\nloadWaveData",loadWaveData,audioAttachment.wave?.substring(0,20),
        "\nmessageId",this.message.id);*/
      this.triggerPayloadRequest(
        loadAudioData ? DEFAULT_DATA_CALLBACK : undefined,
        loadWaveData  ? DEFAULT_DATA_CALLBACK : undefined);
      if (hasAudioData) {
        this.mediaService.currentPlayback?.source$().then(source=>{
          if (source==audioAttachment.data) {
            //console.log("CURRENT",source?.substring(0,100));
            this.playback = this.attachPlayback(this.mediaService.requestCurrentPlayback());
          }
        });
      }
      if (!loadAudioData && !loadWaveData && hasWaveData && hasDuration) {
        this.drawWaveData(this._waveArray,this.position,this.duration);
      }
    }
  }

  protected _loadAudioData:boolean = false;
  protected _loadWaveData:boolean  = false;
  protected _loadAudioCallbacks:((string)=>void)[] = [];
  protected _loadWaveCallbacks:((string)=>void)[] = [];
  protected triggerPayloadRequest(loadAudioCallback:(string)=>void,loadWaveCallback:(string)=>void) {
    if (!!loadAudioCallback) {
      if (this._audioData?.length>0) {
        loadAudioCallback(this._audioData);
      } else {
        this._loadAudioCallbacks.push(loadAudioCallback);
      }
    }
    if (!!loadWaveCallback) {
      if (this._waveData?.length>0) {
        loadWaveCallback(this._waveData);
      } else {
        this._loadWaveCallbacks.push(loadWaveCallback);
      }
    }
    if (!!this._message && !!this._attachment &&
         (this._loadAudioCallbacks.length>0 ||
          this._loadWaveCallbacks.length>0)) {
      const loadAudioData = !this._loadAudioData && this._loadAudioCallbacks.length>0;
      const loadWaveData  = !this._loadWaveData  && this._loadWaveCallbacks.length>0;
      this._loadAudioData = !!this._loadAudioData || loadAudioData;
      this._loadWaveData  = !!this._loadWaveData  || loadWaveData;
      if (loadAudioData || loadWaveData) {
        const message = this._message;
        window.setTimeout(()=>{
          this.chatService.getAttachmentPayload$(
            this._message,
            this._attachment, {data:loadAudioData,wave:loadWaveData})
            .then(result=>{
              //console.log("LOADED.same",message.id==this._message?.id,this._attachment,"result",result);
              if (message.id==this._message?.id && !!this._attachment) {
                this._attachment.wave = result.wave ?? this._attachment.wave;
                this._attachment.data = result.data ?? this._attachment.data;
              }
            })
            .catch(error=>{
              console.log("ERROR.message",this._message,"attachment",this._attachment,"error",error);
            })
            .finally(()=>{
              //console.log("FINALLY",attachment);
              this.zone.run(()=>{
                //console.log("IN.ZONE",attachment);
                if (message.id==this._message?.id && !!this._attachment) {
                  //console.log("FINALLY",this._attachment,"loadAudioData",loadAudioData,"loadWaveData",loadWaveData);
                  if (loadWaveData && this._attachment.wave?.length>0) {
                    this.onWaveDataLoaded(this._attachment.wave);
                    this._loadWaveCallbacks.forEach(callback=>{try{callback(this._attachment.wave)}catch(error){console.log("ERROR",error)}});
                  }
                  if (loadAudioData && this._attachment.data?.length>0) {
                    this.onAudioDataLoaded(this._attachment.data);
                    this._loadAudioCallbacks.forEach(callback=>{try{callback(this._attachment.data)}catch(error){console.log("ERROR",error)}});
                  }
                }
                this._loadAudioCallbacks.length = 0;
                this._loadWaveCallbacks.length = 0;
              })
            });
        });
      }
    }
  }

  protected instanceId = AudioRecordingAttachmentViewerComponent.instanceCounter++;
  protected logger = new Logger('AudioRecordingAttachmentViewerComponent.'+(this.instanceId));

  constructor(
    protected mediaService: MediaService,
    protected chatService: ChatService,
    protected domSanitizer: DomSanitizer,
    protected zone: NgZone,
    protected changeDetectorRef: ChangeDetectorRef,
    @Inject(ENVIRONMENT) public environment: any) {
    //this.logger.info("ctor()");
  }

  ngOnDestroy() {
    this.playback?.release();
    this.subscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.ready = true;
    let left = false;
    try {
      const color = window.getComputedStyle( this.button._elementRef.nativeElement ,null).getPropertyValue('background-color');
      left = color.indexOf("(0")>0;
    } catch(error) {}
    this._waveColor     = left ? '#00000030' : '#ffffff4a';
    this._progressColor = left ? '#0000004a' : '#ffffffc0';
    //console.log("INIT.DRAW",this.instanceId,"ready",this.ready,"position",this.position,"duration",this.duration,"wave",this._waveArray?.length);
    this.drawWaveData(this._waveArray,this.position,this.duration);
  }

  onAudioDataLoaded(audioData:string) {
    //console.log('AUDIO_DATA',this.instanceId,"data",audioData);
    if (audioData?.length>0 && this._audioData!=audioData) {
      this._audioData = audioData;
      //console.log("onAudioDataLoaded",this.instanceId,this._audioData?.substring(0,100),"\nanalyze",(!(this._waveData?.length>0) || isNaN(this.duration)));
      if (!(this._waveData?.length>0) || !isValidNumber(this.duration)) {
        this.mediaService.getAudioAnalysis$(this._audioData).then(audioAnalysis => this.zone.run(()=>{
          //console.log("analyzed.duration",audioAnalysis.duration());
          this.onUpdateDuration(audioAnalysis.duration());
          this.onWaveDataLoaded(encodeUint8ArrayToBase64(audioAnalysis.wave(200)));
          //console.log("analyzed",this.instanceId,"duration",audioAnalysis.duration(),"wave",this._waveData?.length);
        }))
      }
    }
  }

  onWaveDataLoaded(waveData:string) {
    if (waveData?.length>0 && this._waveData!=waveData) {
      this._waveData  = waveData ?? this._waveData;
      this._waveArray = this._waveData?.length>0 ?
        decodeUint8ArrayFromBase64(this._waveData) : undefined;
      //console.log("onWaveDataLoaded",this._waveData,this._waveArray);
      this.drawWaveData(this._waveArray,this.position,this.duration);
    }
  }

  protected drawnData:Uint8Array;
  protected drawnPosition:number;
  protected drawnDuration:number;
  protected drawnWidth:number;
  protected drawingTimer:number;
  drawWaveData(data:Uint8Array, position:number, duration:number) {
    const canvas:HTMLCanvasElement = this.wave?.nativeElement;
    if (this.ready &&
      !!canvas?.getContext &&
        isValidNumber(position) && isValidNumber(duration) &&
        duration>0 && position>=0 && data?.length>0) {
      const width  = this.wave.nativeElement.clientWidth;
      const redraw = duration!=this.drawnDuration ||
                     data!=this.drawnData ||
                     width!=this.drawnWidth;
      const update = position!=this.drawnPosition;
      if (redraw || update) {
        const context      = canvas.getContext('2d');
        const updateFrom   = redraw ? 0 : Math.floor(width*Math.min(position,this.drawnPosition)/duration);
        const updateTo     = redraw ? width : Math.ceil(width*Math.max(position,this.drawnPosition)/duration);
        const played       = Math.ceil(width*position/duration);
        const height       = this.wave.nativeElement.clientHeight;
        const bars         = Math.ceil(width/4);
        const barValues    = data.length/bars;
        const barValuesRnd = Math.max(1,Math.floor(barValues));
        //console.log("=======\ndrawWaveData",redraw,"position",position,"drawn",this.drawnPosition,"from",updateFrom,"to",updateTo);
        this.drawnDuration = duration;
        this.drawnData     = data;
        this.drawnPosition = position;
        this.drawnWidth    = width;
        if (redraw) {
          context.canvas.width  = width;
          context.canvas.height = height;
          context.clearRect(0,0,width,height);
        }
        for (let bar=Math.floor(updateFrom/4), max=Math.ceil(updateTo/4), progress=Math.ceil(played/4); bar<max; bar++) {
          context.fillStyle = bar<progress ? this._progressColor : this._waveColor;
          //console.log("draw",this.instanceId,"bar",bar,"progress",progress,"max",max);
          let value = 0;
          for (let dataIndex=Math.floor(bar*barValues), maxIndex=Math.min(data.length,dataIndex+barValuesRnd); dataIndex<maxIndex; dataIndex++) {
            value = Math.max(value,data[dataIndex]);
          }
          const barHeight = Math.max(2,Math.floor(height*value/255));
          context.clearRect(bar*4,height-barHeight,2,barHeight);
          context.fillRect(bar*4,height-barHeight,2,barHeight);
        }
        //console.log("REDRAW",this.instanceId,"width",width,"height",height,"cancas.width",canvas.width,"canvas.height",canvas.height);
      }
      if (this.playing && !!this.playback && !this.drawingTimer) {
        const timeout = Math.max(100,Math.floor(this.duration*1000/width/2));
        this.drawingTimer = window.setTimeout(()=>{
          if (data==this.drawnData && duration==this.drawnDuration) {
            this.zone.run(()=>{
              this.drawingTimer = undefined;
              const position = this.playback.position();
              //console.log("timeout",timeout,"position",position);
              this.drawWaveData(data,position,duration);
            });
          }
        },timeout);
      }
    }
  }

  onUpdatePlaying(playing:boolean) {
    //console.log("onUpdatePlaying",playing,"was",this.playing);
    this.playing = playing ?? false;
    this.changeDetectorRef.markForCheck();
  }

  onUpdatePosition(position:number) {
    this.position = position ?? 0;
    this.changeDetectorRef.markForCheck();
    this.drawWaveData(this._waveArray,this.position,this.duration);
    //console.log("onUpdatePosition",position);
  }

  onUpdateDuration(duration:number) {
    //console.log("DURATION",duration);
    if (duration==Infinity) {
      //console.trace("onUpdateDuration",this.instanceId,"duration","Infinity");
    }
    if (this.duration!=duration && isValidNumber(duration) && duration!=Infinity) {
      this.duration = duration;
      duration = Math.ceil(duration);
      const minutes = Math.floor(duration/60);
      const seconds = duration % 60;
      //console.log("duration",duration,"minutes",minutes,"seconds",seconds);
      this.durationFormatted = minutes.toString()+':'+seconds.toString().padStart(2,'0');
      this.changeDetectorRef.markForCheck();
    }
  }

  onFinished() {
    this.onUpdatePlaying(false);
  }

  get deleted(): EventEmitter<Attachment> | undefined {
    return undefined;
  }

  get updated(): EventEmitter<AttachmentComponent> | undefined {
    return undefined;
  }

  onButtonClicked() {
    //console.log("onButtonClicked",this.instanceId,"hasData",this._audioData?.length,"hasWave",this._waveData?.length,"was",this.playing);
    const play = !this.playing;
    if (play) {
      const position = this.position;
      this.playback  = this.playback ?? this.createPlayback();
      const currentTime = this.playback.position();
      if (position<0 || position>=this.duration) {
        this.playback.position(0);
        //console.log("play.1.curr",currentTime,"pos",position,"next",this.playback.position());
      } else {
        this.playback.position(position);
        //console.log("play.2.curr",currentTime,"pos",position,"next",this.playback.position());
      }
      //this.playing = true;
      this.playback.play()
        .then(play=>{
          //console.log("onButtonClicked.PLAYING");
          this.playing = true;
          this.drawWaveData(this._waveArray,this.playback.position(),this.duration);
        })
        .catch(error=>{
          //console.log("onButtonClicked.ERROR",this.instanceId,"was",this.playing);
          //this.playing = false;
          this.playback.pause();
          this.changeDetectorRef.markForCheck();
        });
      this.changeDetectorRef.markForCheck();
    } else {
      //console.log("onButtonClicked.PAUSE");
      this.playback?.pause();
      //this.playing = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  createPlayback():Playback {
    //console.log("createPlayback",this.message,"data",this._audioData,"attachment",this._attachment);
    const playback = this.mediaService.playback(this._audioData?.length > 0 ? this._audioData :
      new Promise<string>((resolve, reject) => {
        this.triggerPayloadRequest((data) => {
          if (data?.length > 0) {
            resolve(data);
          } else {
            reject();
          }
        },undefined);
      }));
    return this.attachPlayback(playback);
  }

  attachPlayback(playback:Playback) {
    this.subscription?.unsubscribe();
    this.onUpdatePosition(playback.position());
    this.subscription = playback.position$().subscribe(position=>{
      if (isValidNumber(position) && position!=Infinity) {
        this.zone.run(()=>this.onUpdatePosition(position))
      }
    });
    this.subscription.add(playback.playing$().subscribe(playing=>this.zone.run(()=>{
      //console.log("attachPlayback",this.instanceId,"playing$",playing);
      if (!playing && this.duration<=playback.position()) {
        this.onFinished();
      } else {
        this.onUpdatePlaying(playing);
      }
    })));
    playback.duration$().then(duration=>
      this.zone.run(()=>this.onUpdateDuration(duration)));
    return playback;
  }

  onWaveClicked(event: MouseEvent) {
    if (isValidNumber(this.duration) &&
        isValidNumber(this.position) && !!this.wave.nativeElement) {
      const width    = this.wave.nativeElement.clientWidth;
      const offset   = Math.max(0,Math.min(width,event.offsetX));
      const position = this.duration*offset/width;
      //console.log("onWaveClicked",event,"position",position);
      if (!!this.playback) {
        this.playback.position(position);
      } else {
        this.onUpdatePosition(position);
      }
    }
  }

  onResize() {
    this.drawWaveData(this._waveArray,this.position,this.duration);
  }
}
