import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {BasicContainerComponent} from "shared";
import {themeMode$} from "core";
import {takeUntil} from "rxjs";
import isEqual from "lodash/isEqual";
import bezier from "bezier-easing";

export declare type PersonalityColor = 'red'|'blue'|'yellow'|'green';
export declare type PersonalityColors = {
  red   : number,
  yellow: number,
  green : number,
  blue  : number
}

export const PersonalityColorDefaults: PersonalityColors = {
  red   : 0,
  yellow: 0,
  green : 0,
  blue  : 0
}

@Component({
  selector: 'personality-colors',
  templateUrl: './personality.colors.component.html',
  styleUrls: ['./personality.colors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalityColorsComponent extends BasicContainerComponent {

  @Output() onUpdate = new EventEmitter<PersonalityColors>();
  @Output() onDisplay = new EventEmitter<boolean>();

  static pattern = /badge\.personality\.(\d+)\.(\d+)\.(\d+)\.(\d+)$/;

  @Input() fill:number = 100;
  @Input() easing = bezier(.48,.62,.55,.89);
  // https://cubic-bezier.com/#.59,.49,.55,.84
  //.59,.49,.55,.84);//.48,.62,.55,.89);//.48,.62,.57,.95);//.51,.51,.73,.74);//.17,.67,.61,.94);
  protected _colors: PersonalityColors = PersonalityColorDefaults;

  @Input()
  set colors(colors: PersonalityColors) {
    if (!!colors && !isEqual(colors,this._colors)) {
      this._colors = colors;
      this.display = colors.red>0 || colors.yellow>0 || colors.green>0 || colors.blue>0;
      this.onUpdate.emit(colors);
    }
  }
  get colors():PersonalityColors {
    return this._colors;
  }

  protected _badgeData:string = undefined;
  @Input()
  set badgeData(badgeData:string) {
    //("SURVEY.DATA.badge.set",badgeData);
    if (this._badgeData!=badgeData) {
      if (!!badgeData) {
        const params = badgeData.match(PersonalityColorsComponent.pattern);
        if (params?.length == 5) {
          this._badgeData = badgeData;
          this.colors = {
            red: parseInt(params[1]),
            yellow: parseInt(params[2]),
            green: parseInt(params[3]),
            blue: parseInt(params[4])
          };
        } else {
          this._badgeData = undefined;
        }
      } else {
        this._badgeData = undefined;
      }
    }
  }
  get badgeData():string {
    return this._badgeData;
  }

  protected _display:boolean = false;
  set display(display:boolean) {
    if (this._display!=display) {
      //console.log("SET.DISPLAY",display);
      this._display = display;
      this.onDisplay.emit(display);
    }
  }
  get display():boolean {
    return this._display;
  }

  constructor(protected changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    themeMode$.pipe(takeUntil(this.onDestroy$)).subscribe(mode=>{
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  value(value:number):number {
    const result = this.easing(value/100)*100;
    //console.log("VALUE",value,"RESULT",result);
    return result;
  }
}
