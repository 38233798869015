import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges, TemplateRef,
  ViewChild
} from '@angular/core';
import {APP_ID, Contact, ENVIRONMENT, GroupMembership, Logger, Platform, Topic} from 'core';
import {PropertiesService} from "properties";
import {map, shareReplay, takeUntil} from "rxjs/operators";
import {
  AccordionContainerComponent,
  AccordionSectionContext,
  AccordionSectionDefinition,
  AccordionSectionHeaderButtonDefinition,
  BasicContainerComponent, ContactPropertiesAccessor, ContactPropertiesService,
  enterLeaveAnimations
} from "shared";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import isEqual from "lodash/isEqual";
import {ContactDetailComponentFactoryService} from "contact/lib/components/contact-detail/contact-detail-component-factory.service";
import {ContactDetailDataShareService} from "contact/lib/components/contact-detail/contact-detail-data-share.service";

// export declare var google: any;
@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss'],
  animations: [enterLeaveAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactDetailComponent extends BasicContainerComponent implements AfterViewChecked, OnChanges {

  @Input() set contact(contact: Contact) {
    if (!isEqual(this.contact, contact)) {
      this._contact = contact;
      this.dataShareService.setContact(contact);
    }
  }

  get contact(): Contact {
    return this._contact;
  }
  protected _contact: Contact;

  @Output() onShowNotes = new EventEmitter();

  @ViewChild('header')    header:  ElementRef;
  @ViewChild('accordion') accordion: AccordionContainerComponent;

  @ContentChild('leftControlTemplate') leftControlTemplate: TemplateRef<any>;
  @ContentChild('rightControlTemplate') rightControlTemplate: TemplateRef<any>;

  static _largeAvatar = false;
  static _largeAvatarStyle: { width: string, height: string } = {
    width: '200px',
    height: '200px'
  };
  largeAvatar = ContactDetailComponent._largeAvatar;
  largeAvatarStyle: { width: string, height: string, clientWith?: number} = { ...ContactDetailComponent._largeAvatarStyle };

  sections: AccordionSectionDefinition[] = [];
  headerButtons: { [sectionId: string]: AccordionSectionHeaderButtonDefinition[] } = {};

  logger = new Logger('ContactDetailComponent');

  //contactPropertiesAccessor: ContactPropertiesAccessor = undefined;

  constructor(public platform: Platform,
              protected translateService: TranslateService,
              //public contactPropertiesService: ContactPropertiesService,
              public propertiesService: PropertiesService,
              private contactDetailComponentFactoryService: ContactDetailComponentFactoryService,
              private dataShareService: ContactDetailDataShareService,
              private changeDetector: ChangeDetectorRef,
              @Inject(APP_ID) public appId: number,
              @Inject(ENVIRONMENT) protected environment: any) {
    super();

    this.sections.push({
      id: 'info',
      type: 'info',
      headless: true,
      factoryService: this.contactDetailComponentFactoryService,
      context: {
        showNotes: this.showNotes.bind(this)
      } as AccordionSectionContext
    });

    this.sections.push({
      id: 'reach',
      type: 'reach',
      label: 'structure.reach',
      factoryService: this.contactDetailComponentFactoryService,
      context: {}
    });

    this.sections.push({
      id: 'filters',
      type: 'filters',
      label: 'media.filters.tags',
      factoryService: this.contactDetailComponentFactoryService,
      context: {}
    });

    const interests$ = new BehaviorSubject([]);
    const surveys$   = new BehaviorSubject([]);
    const interestTags$ = this.dataShareService.getContact$.pipe(
      map(contact => contact?.interestTags
        ?.filter(tag => tag?.startsWith('interest.'))
        .map(tag => tag.substr('interest.'.length))
      ),
      shareReplay(1)
    );
    this.propertiesService.interests$.pipe(
      takeUntil(this.onDestroy$),
      map((topics: Topic[]) => {
        const result = topics.reduce((result, topic) => {
            const index = topic.type=='survey' ? 1 : 0;
            result[index].push(topic);
            return result;
          }, <[Topic[], Topic[]]>[[], []]
        )
        return [(result[0]), (result[1])]
      })
    ).subscribe(([interests, surveys]) => {
      interests$.next(interests);
      surveys$.next(surveys);
    });

    this.sections.push({
      id: 'interests',
      type: 'interests',
      label: 'module.interests',
      factoryService: this.contactDetailComponentFactoryService,
      context: {
        interests$,
        selectedTags$: interestTags$
      } as AccordionSectionContext & { interests$: Observable<Topic[]>, selectedTags$: Observable<string[]> }
    });

    surveys$.pipe(takeUntil(this.onDestroy$)).subscribe(surveys => {
      const sections = this.sections.filter(section => section.type!='survey');
      const headerButtons = {};
      surveys.forEach((survey: Topic) => {
        const sectionId = `survey.${survey.id}`;
        const onHelp$ = new Subject<void>();
        sections.push({
          id: sectionId,
          type: 'survey',
          label: survey.label || 'survey',
          factoryService: this.contactDetailComponentFactoryService,
          context: {
            survey,
            selectedTags$: interestTags$.pipe(map(interestTags => interestTags?.filter(tag => tag.startsWith(survey.id)))),
            displayRoot: false,
            onHelp$
          } as AccordionSectionContext & { survey: Topic, selectedTags$: Observable<string[]> }
        });
        headerButtons[sectionId] =  [{
          icon: 'help',
          onClick: () => onHelp$.next(),
          disabled: () => false
        }];
      });
      this.headerButtons = headerButtons;
      this.sections = sections;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    //this.contactPropertiesAccessor?.unsubscribe();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.accordion) {
      Object.entries(this.headerButtons).forEach(([sectionId, button]) => {
        this.accordion.setHeaderButtons(sectionId, button);
        this.changeDetector.detectChanges();
      })
    }
  }

  ngAfterViewChecked() {
    this.setLargeAvatar(this.largeAvatar);
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentContact = changes.contact.currentValue;
    const previousContact = changes.contact.previousValue;
    if (!isEqual(currentContact, previousContact)) {
      /*
      this.contactPropertiesAccessor?.unsubscribe();
      this.contactPropertiesAccessor = undefined;
      if (!!currentContact) {
        this.contactPropertiesService.contactProperties$(currentContact.id,"test")
          .then(accessor=> {
            this.contactPropertiesAccessor?.unsubscribe();
            this.contactPropertiesAccessor = accessor;
            this.contactPropertiesAccessor.setProperty("p1",null);
            this.contactPropertiesAccessor.setProperty("p2",['A','B','C']);
            this.contactPropertiesAccessor.setProperty("p3","value");
            this.contactPropertiesAccessor.setProperty("p4",{ "k1" : "v1", "k2": { "k2k1": "v2v1"} });;
            this.contactPropertiesAccessor.setTag("tag1");
            this.contactPropertiesAccessor.setTag("tag2");
            this.contactPropertiesAccessor.setTag("tag3");
            this.contactPropertiesAccessor.save()
              .then(accessor=>{
                console.log("CONTACT.xy.current.save",this.contactPropertiesAccessor);
              })
              .catch(error=>console.log("CONTACT.xy.current.error",error));
            console.log("CONTACT.xy.current.update",this.contactPropertiesAccessor);
          });
        console.log("CONTACT.xy.current",currentContact,"previous",previousContact);
      }
       */
    }
  }

  get groupId$(): Observable<string> {
    return this.propertiesService.properties$.pipe(
      map(properties => properties.group.id)
    );
  }

  get membership(): Observable<GroupMembership> {
    return this.groupId$.pipe(map((groupId: string) => {
      if (this.contact.groups) {
        return this.contact.groups[groupId];
      }
    }))
  }

  expand(event:UIEvent) {
    this.accordion.setExpandedSection(0);
  }

  toggleAvatar() {
    this.setLargeAvatar(!this.largeAvatar);
  }

  setLargeAvatar(large: boolean) {
    if (!!this.header && !!this.header.nativeElement &&
      this.largeAvatarStyle.clientWith!=this.header.nativeElement.clientWidth) {
      this.largeAvatarStyle.clientWith = this.header.nativeElement.clientWidth;
      let cs      = window.getComputedStyle(this.header.nativeElement);
      let padding = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
      var border  = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
      let width   = this.header.nativeElement.clientWidth - padding - border;
      ContactDetailComponent._largeAvatarStyle.width = this.largeAvatarStyle.width = `${width}px`;
      ContactDetailComponent._largeAvatarStyle.height = this.largeAvatarStyle.height = `${width}px`;
    }
    if (this.largeAvatar!=large) {
      ContactDetailComponent._largeAvatar = this.largeAvatar = large;
    }
  }

  get photoUrl(): string {
    if (this.contact) {
      return `${this.environment.serverUrl}/v1.0/content/avatar/${this.contact.version || 0}/${this.contact.id}.jpg`;
    }
    return '';
  }

  showNotes() {
    this.onShowNotes.emit();
  }
}
