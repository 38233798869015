import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  Inject,
  input,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {BasicContainerComponent, timer} from "shared";
import {ConferenceService} from "../../conference.service";
import {ConferenceInfo, ConferenceParticipant} from "../../store/models";
import {ENVIRONMENT, Logger} from "core";
import isEqual from "lodash/isEqual";

@Component({
  selector: "conference-participant",
  templateUrl: "./conference-participant.component.html",
  styleUrls: ["./conference-participant.component.scss"],
  host: { 'class.mirror': "mirror()"},
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConferenceParticipantComponent extends BasicContainerComponent {
  focus = input<boolean>(false);
  mediaStream = input.required<MediaStream,MediaStream>({
    transform: (mediaStream: MediaStream) => {
      console.log("#C.mediaStream",this.instanceId,"mediaStream",mediaStream?.id,"tracks",mediaStream?.getTracks().length);
      return mediaStream;
    }
  });
  participant = input.required<ConferenceParticipant>();
  conferenceInfo = input.required<ConferenceInfo>();
  showName = input<boolean>(false);
  showMedia = input<boolean>(false);
  mirror = input<boolean>(false);
  fixedRatio = input<number>(undefined);
  fixedImageMax = input<number>(90);

  avatarUrl = computed<string>(()=> {
    const participant = this.participant();
    if (!!participant?.id) {
      return `${this.environment.serverUrl}/v1.0/content/avatar/${
        participant.version ?? 0
      }/${participant.id}.jpg`;
    }
    return undefined;
  });

  mediaStreamTrackIds = signal<string[]>([]);

  showMediaStream = computed<boolean>(()=>{
    if (this.showMedia()) {
      const conferenceInfo = this.conferenceInfo();
      const participant = this.participant();
      const mediaStream = this.mediaStream();
      const mediaStreamTrackIds = this.mediaStreamTrackIds();
      if (!!conferenceInfo && !!participant?.id && !!mediaStream && mediaStreamTrackIds.length>0) {
        const participantId = participant.id;
        const hasVideoTracks = mediaStream?.getVideoTracks()?.length > 0 &&
          (participantId == this.conferenceService.userId ||
            !!conferenceInfo.pickedUp.find((p) => p.id == this.conferenceService.userId) ||
            !!conferenceInfo.paused.find((p) => p.id == this.conferenceService.userId));
        console.log("#C.showMediaStream.computed",this.instanceId,"showMediaStream",this.showMedia(),"mediaStream",this.mediaStream()?.id,"tracks",this.mediaStream()?.getTracks().length,"participant",this.participant());
        return hasVideoTracks;
      }
    }
    console.log("#C.showMediaStream.computed",this.instanceId,"showMediaStream",false);
    return false;
  });

  @ViewChild("video") videoElementRef: ElementRef;
  @ViewChildren("video") videoElementRefs: QueryList<ElementRef<HTMLVideoElement>>;
  @ViewChild("avatar") avatarElementRef: ElementRef;
  @ViewChild("name") nameElementRef: ElementRef;

  protected logger = new Logger("ConferenceParticipantComponent");

  constructor(
    @Inject(ENVIRONMENT) protected environment: any,
    public conferenceService: ConferenceService
  ) {
    super();
    const updateMediaStreamsTimer = timer();
    const mediaTrackIds= new Set<string>();
    effect((onCleanup) => {
      const mediaStream = this.mediaStream();
      console.log("#C.mediaStream",this.instanceId,"mediaStream",mediaStream?.id,"tracks",mediaStream?.getTracks().length);
      const onUpdateTracks = ()=> {
        if (!!mediaStream) {
          console.log("#C.mediaStream.onUpdateTracks",this.instanceId,"mediaStream",mediaStream?.id,"tracks",mediaStream?.getTracks().length);
          let ids = new Set<string>(
            mediaStream.getTracks().map((track) => track.id)
          );
          console.log("#C.mediaStream.tracks",this.instanceId,ids);
          if (!isEqual(mediaTrackIds, ids)) {
            mediaTrackIds.clear();
            ids.forEach(id=>mediaTrackIds.add(id));
            updateMediaStreamsTimer.set(()=>{
              this.mediaStreamTrackIds.set(Array.from(mediaTrackIds));
            });  // handle in animation frame
          }
        }
      }
      mediaStream?.addEventListener('addtrack',onUpdateTracks);
      mediaStream?.addEventListener('removetrack',onUpdateTracks);
      onUpdateTracks();
      onCleanup(()=> {
        console.log("#C.mediaStream.onCleanup",this.instanceId,"mediaStream",mediaStream?.id,"tracks",mediaStream?.getTracks().length);
        mediaStream?.removeEventListener('addtrack',onUpdateTracks);
        mediaStream?.removeEventListener('removetrack',onUpdateTracks);
        updateMediaStreamsTimer.cancel();
      });
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  onResize(event) {
    //console.log("onResize",event);
    event.element.nativeElement;
    const offsetWidth = event.element.nativeElement.offsetWidth;
    const offsetHeight = event.element.nativeElement.offsetHeight;
    if (offsetWidth > 0 && offsetHeight > 0) {
      let minPx =
        Math.min(
          event.element.nativeElement.offsetWidth,
          event.element.nativeElement.offsetHeight
        ) + "px";
      let style = (<HTMLElement>event.element.nativeElement.firstElementChild)
        .style;
      style.width = minPx;
      style.height = minPx;
    }
  }
}
