import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PresenceService} from "../../presence.service";
import {BehaviorSubject, Subscription} from "rxjs";
import isEqual from "lodash/isEqual";
import {PresenceState} from "../../store/models";
import {Presence} from "core";
import {BasicComponent} from "shared";

@Component({
  selector: 'presence',
  template: `
    <presence-indicator [all]="all" [presence]="presence$ | async"></presence-indicator>
  `,
  styles: [`
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresenceComponent extends BasicComponent {

  @Input() contactId: string;
  @Input() state: PresenceState;
  @Input() all: boolean = false;
  presence$ = new BehaviorSubject<Presence>(undefined);
  subscription:Subscription;

  constructor(public presenceService: PresenceService) {
    super();
    //console.log("presence.COMPONENT.ctor",this.instanceId);
  }

  set presenceState(state:PresenceState) {
    this.presence$.next({
      state,
      contactId:undefined,
      timeActive:Number.MAX_SAFE_INTEGER,
      timeSince:Number.MAX_SAFE_INTEGER
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    //console.log("presence.COMPONENT.destroy",this.instanceId);
    this.subscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let change = changes['contactId'];
    //console.log("ngOnChanges."+this.constructor.name,change);
    if (!!change) {
      if (!change.currentValue) {
        this.subscription?.unsubscribe();
        this.subscription = undefined;
      } else if (change.currentValue != change.previousValue) {
        this.subscription?.unsubscribe();
        this.subscription = this.presenceService.getPresence$(change.currentValue)
          .subscribe(presence => {
            if (!isEqual(presence,this.presence$.value)) {
              this.presence$.next(presence);
            }
          });
      }
    }
  }
}
