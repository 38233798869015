import {ApplicationRef, Injectable, Injector} from "@angular/core";
import {ENVIRONMENT, Logger, Platform} from "core";
import {PropertiesService} from "properties";
import {StripePaymentService} from "../stripe/stripe-payment.service";
import {IapPaymentService} from "../native/iap-payment.service";
import {Product, ProductCallback, ProductEvents} from "../models/product";
import {Tax} from "../models/tax";
import {InAppPurchase2} from "@ionic-native/in-app-purchase-2/ngx";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {DOCUMENT} from "@angular/common";
import {Overlay} from "@angular/cdk/overlay";
import {Observable} from "rxjs";
import {ProductSelector} from "./product-selector.service";
import {ProductAdapter} from "./product-adapter.service";

export interface PaymentServiceImpl {
  source: string;
  productIds: Observable<string[]>; //string[];
  get: (productId: string)  => Promise<Product>; // TODO: consider to return Observable to react on server side initiated updates
  when: (productId: string) => ProductEvents;
  off: (callback: ProductCallback) => void;
  order: (productId: string) => Promise<boolean>;
  cancel: (productId: string) => Promise<void>;
  refund: (productId: string) => Promise<void>;
  getTax: (productId?: string, country?: string) => Promise<Tax>;
  restorePurchases: () => void; // used by native apps to pull purchases from the store (possibly on a new device ...)
  /*
    interval value is a dimensionless quantity.
    The ratio of different interval values is used to calculate savings.
    interval name is for presentational purposes (i.e. label identification)
   */
  getInterval: (productId: string) => {value: number, name: string};
  getType: (productId?: string) => string;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentServiceFactory {

  protected instances: {[key: string]: PaymentServiceImpl} = {};
  protected logger = new Logger('PaymentServiceFactory');

  constructor(protected platform: Platform,
              protected injector: Injector) {
    //console.debug('PaymentServiceFactory.ctor()');
  }

  get(type?: 'stripe' | 'iap'): PaymentServiceImpl {
    // always use stripe as iap is not yet ready.
    // For hybrid apps payment is disabled in the components but
    // they can still use the payment service to query products and display some data
    type = 'stripe';
    // type = type || (this.platform.is(`hybrid`) ? 'iap' : 'stripe');
    console.debug('PaymentServiceFactory.get()', type);
    let instance = this.instances[type];
    if (!instance) {
      instance = this.create(type);
      this.instances[type] = instance;
    }
    return instance;
  }

  stripe(): StripePaymentService {
    let instance = this.get('stripe');
    return instance as StripePaymentService;
  }

  iap(): IapPaymentService {
    let instance = this.get('iap');
    return instance as IapPaymentService;
  }

  protected create(type: string): PaymentServiceImpl {
    try {
      // return type=='iap' ? this.injector.get(IapPaymentService) :
      //        type=='stripe' ? this.injector.get(StripePaymentService) : null;

      // Module should not be configured with providers for IapPaymentService and StripePaymentService
      // as this will instruct the injector to eagerly create instances of both services (which is not desired).
      // We create them lazily here
      return type=='iap' ? this.createIapPaymentService() :
             type=='stripe' ? this.createStripePaymentService() : null;
    } catch(e) {
      this.logger.error(e);
    }
  }

  protected createIapPaymentService(): IapPaymentService {
    return new IapPaymentService(
      this.injector.get(InAppPurchase2),
      this.platform,
      this.injector.get(HttpClient),
      this.injector.get(ApplicationRef),
      this.injector.get(ProductAdapter),
      this.injector.get(ENVIRONMENT)
    );
  }

  protected createStripePaymentService(): StripePaymentService {
    return new StripePaymentService(
      this.injector.get(PropertiesService),
      this.injector.get(TranslateService),
      this.injector.get(HttpClient),
      this.injector.get(ProductSelector),
      this.injector.get(ProductAdapter),
      this.injector.get(Overlay),
      this.injector.get(DOCUMENT),
      this.injector.get(ENVIRONMENT),
      this.injector
    );
  }
}
