import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Logger, DomainAccess, Contact} from 'core';
import {BasicContainerComponent, TriStateCheckboxComponent} from "shared";
import {PropertiesService} from "properties";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {takeUntil} from "rxjs/operators";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-contact-domain-access',
  templateUrl: './contact-domain-access.component.html',
  styleUrls: ['./contact-domain-access.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactDomainAccessComponent),
      multi: true,
    }
  ]
})
export class ContactDomainAccessComponent extends BasicContainerComponent implements ControlValueAccessor, AfterViewInit, OnChanges {

  @Input()    value: DomainAccess;
  @Input()   domain: DomainAccess;
  @Input()  contact: Contact;
  @Input() disabled: boolean;
  @ViewChild('domainCheckbox') domainCheckbox: TriStateCheckboxComponent;
  @ViewChild('leaderCheckbox') leaderCheckbox: MatCheckbox;

  protected current: DomainAccess;
  protected user: Contact;
  locale: string;

  private onChange: (value: DomainAccess) => void;
  private onTouched: () => void;

  protected logger = new Logger('DomainAccessComponent');

  constructor(protected propertiesService: PropertiesService,
              protected translateService: TranslateService) {
    super();
    this.propertiesService.user$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => this.user = user);
    this.locale = this.translateService.currentLang;
    this.translateService.onLangChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event: LangChangeEvent) => {
        this.locale = event.lang;
        // Force display of creation date in new lang by removing the date element and then adding it again.
        // Template is instrumented with *ngIf which makes it possible to manipulate the DOM
        // by modifying the current.time property
        const time = this.current.time;
        this.current.time = null;
        window.setTimeout(() => this.current.time = time)
      });
  }

  ngAfterViewInit() {
    this.domainCheckbox.registerOnChange((value: boolean) => this.onTapDomain(value));
    this.domainCheckbox.registerOnTouched(() => this.onTouched());
  }

  ngOnChanges(changes: SimpleChanges) {
    this.update();
  }

  writeValue(value: DomainAccess) {
    this.logger.debug('writeValue', value, this.domain);
    //console.log('writeValue', value, this.domain);
    this.value = value;
    // clone to prevent change propagation to the form initial value
    // which will compromise the change detection machinery.
    this.current = value ? {...value} : value;
    this.update();
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  update() {
    //console.log("DOMAIN",this.domain,"CONTACT",this.contact,"CURRENT",this.current);
    if (this.domain && this.contact) {
      //use timeout as "disabled" is set after writeValue() is called
      window.setTimeout(() => {
        const selected = this.current && (this.disabled || this.current.contact_id==this.contact.id)
          ? this.current.role != 'none' && !this.current.declined // cannot manipulate this domain or domain is not inherited
          : null;                       // contact does not have domain access at all or it is inherited
        //console.log("selected",selected,"access",this.current);
        this.domainCheckbox.writeValue(selected);
      });
    }
  }

  onTapDomain(checked: boolean) {
    this.current = this.value ? {...this.value} : this.value;
    if (!checked && checked != false) {
      // explicit domain access should be removed and inheritance rules will control the domain access
      if (this.current && this.current.contact_id == this.contact.id) {
        this.current = null;    // domain was explicitly assigned before (not inherited) - remove it
      }
    } else {                    // domain access is either assigned or blocked (role=none)
      const role = !checked ? 'none' : this.current && this.current.role!='none' ? this.current.role : 'user';
      if (!this.current) {
        this.current = { domain_id: this.domain.domain_id, time: new Date().getTime() };
      }
      this.current.role         = role;
      this.current.admin_id     = this.user.id;
      this.current.admin_name   = this.user.name;
      this.current.contact_id   = this.contact.id;
      this.current.contact_name = this.contact.name;
    }
    this.triggerDomainAccessChange(this.current);
  }

  onTapLeader(event: MatCheckboxChange) {
    this.current.role = event.checked ? 'leader' : 'user';
    this.triggerDomainAccessChange(this.current);
  }

  get isLeader() {
    return this.current.role=='leader';
  }

  protected triggerDomainAccessChange(value: DomainAccess) {
    this.onChange(value);
  }
}
