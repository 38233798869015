import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {Logger} from "core";
import {MaterialService} from "material";
import {TranslateService} from "@ngx-translate/core";
import {DateAdapter} from "@angular/material/core";
import moment from "moment";
import {BasicContainerComponent, ResizeEvent, VirtualScrollerComponent, VirtualScrollEvent} from "shared";
import {NULL_TASK, Task, TaskTarget} from "../../models/task";
import {TaskService} from "tasks/lib/services/task.service";
import {More} from "store";
import {CdkDragDrop, CdkDragEnd, CdkDragMove, CdkDragRelease, CdkDragStart} from "@angular/cdk/drag-drop";
import {TaskListService} from "tasks/lib/services/task-list.service";

@Component({
  selector: 'app-task-list',
  templateUrl: 'task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskListComponent extends BasicContainerComponent {
  protected _entities$ = new BehaviorSubject<Task[]>([]);
  protected _sections$ = new BehaviorSubject<number[]>([]);
  protected _morePassive$ = new BehaviorSubject<More>(undefined);
  protected morePassiveSubscription: Subscription;
  protected entitiesSubscription: Subscription;
  protected sectionsSubscription: Subscription;
  protected _dragEndedHandle:number = undefined;

  @ViewChild('scroller') virtualList: VirtualScrollerComponent;

  lockDragDrop = signal<boolean>(false);

  @Input()
  set morePassive$(morePassive$: Observable<More>) {
    console.log("sync:morePassive$",morePassive$);
    this.morePassiveSubscription?.unsubscribe();
    this.morePassiveSubscription = morePassive$
      ?.pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        console.log("sync:morePassive",value);
        this._morePassive$.next(value);
      });
  }

  get morePassive$(): Observable<More> {
    return this._morePassive$;
  }

  @Input()
  set entities$(entities: Observable<Task[]>) {
    //this.logger.debug('entities$', entities);
    this.entitiesSubscription?.unsubscribe();
    this.entitiesSubscription = entities
      ?.pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        //this.logger.debug('STORE.entities.next', [...value['backingArray']]);
        this._entities$.next(value);
      });
  }

  get entities$(): Observable<Task[]> {
    return this._entities$;
  }

  @Input() cacheId$: Observable<string>;

  @Input()
  set sections$(sections: Observable<number[]>) {
    this.sectionsSubscription?.unsubscribe();
    this.sectionsSubscription = sections
      ?.pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        //this.logger.debug('SECTIONS', value);
        this._sections$.next(value);
      });
  }

  get sections$(): Observable<number[]> {
    return this._sections$;
  }

  @Output() selectionChanged = new EventEmitter<{ index: number; task: Task; target?: TaskTarget }>();
  @ContentChild('controlsLeftTemplate',  { static: true }) controlsLeftTemplate:  TemplateRef<any>;
  @ContentChild('controlsRightTemplate', { static: true }) controlsRightTemplate: TemplateRef<any>;
  @HostBinding('class.dense') dense = false;

  itemHeaderElementAccessor = (element: Element): Element => {
    return element ? element.firstElementChild : undefined;
  };

  public trackTask = (index: number, task: Task) => {
    return task?.id ?? '_';//this.safeTask(task);
  };

  protected logger = new Logger('TaskListComponent');

  constructor(protected taskService: TaskService,
              public taskListService: TaskListService,
              public materialService: MaterialService,
              protected translateService: TranslateService,
              protected dateAdapter: DateAdapter<moment.Moment>,
              protected changeDetector: ChangeDetectorRef,
              @Inject(NULL_TASK) protected nullTask: Task) {
    super();
    this.cacheId$ = this.taskService.cacheId$;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected _loadMoreTriggered:number = undefined;
  onScrolled(event: VirtualScrollEvent) {
    if ((event.scrollSize-event.endGap-event.scrollEndPosition) < 0) {
      /*const more = this.more$();
      if (more?.hasMore() && more.hasStarted() && !more.isLoading()) {
        if (!!this._loadMoreTriggered) {
          window.clearTimeout(this._loadMoreTriggered);
        }
        this._loadMoreTriggered = window.setTimeout(() => {
          more.loadMore();
        },DEFAULT_LOAD_MORE_DELAY);
      }*/
    }
  }

  onResize(event: ResizeEvent) {
    super.onResize(event);
    this.dense = event.currentSize.width < 400;
  }

  onTaskSelection(selection: {index: number, task: Task, target?: TaskTarget}) {
    // this.logger.debug('onTaskSelection', selection);
    if (!this._dragEndedHandle) {
      this.selectionChanged.emit(selection);
      //console.log("onDrag:onTaskSelection",selection);
    }
  }

  color(index: number) {
    // if we put the call to MaterialService.color() directly into the template
    // the options are no passed to the method for indexes 20 - 24.
    // it is currently unclear what is the reason for this issue
    // as a workaround the MaterialService.color() method call is done in ts.
    const options = { fallback: index + 10 };
    return this.materialService.color(index, options);
  }

  monthYear(time: number) {
    const date = new Date(time);
    if (window && window.Intl && window.Intl.DateTimeFormat) {
      const options: Intl.DateTimeFormatOptions = {
        // day: '2-digit',
        // weekday: 'short',
        month: 'long',
        year: 'numeric'
      };
      const locales = [this.translateService.currentLang, 'en-US'];
      const supportedLocales = Intl.DateTimeFormat.supportedLocalesOf(locales, options);
      if (supportedLocales.length) {
        return new Intl.DateTimeFormat(locales, options).format(date);
      }
    }
    const mnt = moment(date);
    const ymd = this.dateAdapter.format(mnt, 'll');
    const day = this.dateAdapter.format(mnt, 'D');
    return ymd.replace(new RegExp('[^\.]?' + day + '.?'), '')
  }

//  safeTask(task: Task): Task {
//    return task || this.nullTask;
//  }

  // subscribed(tasks: Task[], offset:number): Task[] {
  //   // TODO: all function calls in template are executed enourmous often!
  //   if (this.subscribedTasks!==tasks || this.subscribedOffset!==offset) {
  //     console.log("STORE.SUBSCRIBED",tasks,offset);
  //     this.subscribedTasks = tasks;
  //     this.subscribedOffset = offset;
  //     const subscriptions: {[key:string]: Subscription} = {};
  //     const taskListSubscriptions: {[key:string]: Subscription} = {};
  //     tasks?.forEach((task,index) => {
  //       if (!!task?.id) {
  //         /*
  //         const taskAfter = index>0 ? tasks[index-1] : undefined;
  //         const taskBefore = index<tasks.length-1 ? tasks[index+1] : undefined;
  //         const timeAfter= taskAfter?.calculateTimeOrder();
  //         const timeBefore = taskBefore?.calculateTimeOrder();
  //         const timeOrder = task.calculateTimeOrder();
  //         task.diffAfter  = timeAfter!=undefined ? Math.ceil((timeAfter - timeOrder)/1) : undefined;
  //         task.diffBefore = timeBefore!=undefined ? Math.ceil((timeOrder - timeBefore)/1) : undefined;
  //         task.ordered    = timeOrder == task.timeOrder;
  //         if (task.diffAfter<0 && task.diffBefore<0) {
  //           console.error("STORE.after+before",task.diffAfter,task.diffBefore,"after",taskAfter,"task",task,"before",taskBefore);
  //         } else if (task.diffAfter<0) {
  //           console.error("STORE.after",task.diffAfter,"after",taskAfter,"task",task);
  //         } else if (task.diffBefore<0) {
  //           console.error("STORE.before",task.diffBefore,"task",task,"before",taskBefore);
  //         }
  //          */
  //         subscriptions[task.id] =
  //           this.taskSubscriptions[task.id] ??
  //           this.taskService.getTask$(task.id,task).subscribe();
  //
  //         const taskListId = task.taskList?.id;
  //         if (taskListId) {
  //           taskListSubscriptions[taskListId] =
  //             this.taskListSubscriptions[taskListId] ??
  //             this.taskListService.getTaskList$(taskListId)
  //               .subscribe((taskList) => {
  //                 this.logger.debug('TASK LIST UPDATE', taskList);
  //                 const subscribedTaskLists = this.subscribedTaskLists();
  //                 subscribedTaskLists[task.id] = taskList;
  //                 asapScheduler.schedule(() => {
  //                   this.subscribedTaskLists.set(subscribedTaskLists);
  //                   this.changeDetector.detectChanges();
  //                 });
  //               });
  //         }
  //       }
  //     });
  //
  //     Object.keys(this.taskSubscriptions).forEach(taskId=>{
  //       if (!subscriptions[taskId]) {
  //         this.taskSubscriptions[taskId].unsubscribe();
  //       }
  //     });
  //     this.taskSubscriptions = subscriptions;
  //
  //     Object.keys(this.taskListSubscriptions).forEach(taskListId=>{
  //       if (!taskListSubscriptions[taskListId]) {
  //         this.taskListSubscriptions[taskListId].unsubscribe();
  //       }
  //     });
  //     this.taskListSubscriptions = taskListSubscriptions;
  //
  //     this.resetDrag();
  //   }
  //   return tasks;
  // }


  protected _dragElementSourceIndex:number = undefined;
  protected _dragElementTargetIndex:number = undefined;
  protected _dragElementList:HTMLElement[] = [];
  protected _dragElementRectList:DOMRect[] = [];
  protected _scrollTo = undefined;

  onDragStarted(event:CdkDragStart, index: number, offset: number, task:Task) {
    //console.log("onDragStarted", event, index, offset, task);
    this._scrollTo = this.virtualList.virtualScrollHandler.scrollTo;
    this.virtualList.virtualScrollHandler.scrollTo = (scrollPosition:number,time:number=0, scrollCompletedCallback:()=>void=undefined): any => {};
    const subscribedIndex = index-offset;
    const sourceList= <Task[]>this.virtualList?.viewportItems;
    this.resetDrag();
    this._dragElementSourceIndex = subscribedIndex;
    const containerElement = event.source.element.nativeElement;
    containerElement.parentElement.childNodes.forEach((element:HTMLElement)=>{
      if (element.nodeType === Node.ELEMENT_NODE) {
        this._dragElementList.push(element);
      }
    });
    //console.log("onDragStarted.1", event, index, offset, task, "subscribedTasks.length",this.subscribedTasks.length,"dragElementList.length",this._dragElementList.length,"task===this.subscribedTasks[index]",task===this.subscribedTasks[index],"tasks",task,this.subscribedTasks[index],this.subscribedTasks);
    if (sourceList.length==this._dragElementList.length &&
        task==sourceList[subscribedIndex]) {
      this._dragElementList.forEach((element: HTMLElement, elementIndex) => {
        this._dragElementRectList.push(element.getBoundingClientRect());
        if (subscribedIndex != elementIndex) {
          element.style.transform = 'translateY(0px)';
        }
      });
      containerElement.classList.add('dragging');
    }
      // event.event.preventDefault();
      // event.event.stopPropagation();
    /*} else {
      // event.event.preventDefault();
      // event.event.stopPropagation();
    }*/
    //console.log("onDragStarted.2", event, index, this._dragElementRectList, "dragElementList.length",this._dragElementList.length,containerElement);
  }

  onDragReleased(event:CdkDragRelease, index: number, offset: number, task:Task) {
    //console.log("onDragReleased", event, index, offset, task);
  }

  onDragMoved(event:CdkDragMove<any>, index: number, offset: number, task:Task) {
    const subscribedIndex = index-offset;
    if (this._dragElementSourceIndex == subscribedIndex) {
      const position    = event.pointerPosition.y;
      const sourceIndex = this._dragElementSourceIndex;
      const sourceList   = <Task[]>this.virtualList?.viewportItems;
      const targetIndex = this._dragElementRectList.findIndex((rect:DOMRect)=>{
        return rect.top <= position && position <= rect.bottom;
      });
      if (targetIndex >= 0 &&
          targetIndex != this._dragElementTargetIndex &&
         !sourceList[targetIndex].isCompleted()) {
        this._dragElementTargetIndex = targetIndex;
        for (let i = 0; i < this._dragElementList.length; i++) {
          const element = this._dragElementList[i];
          if (i != sourceIndex) {
            if (targetIndex < sourceIndex) {
              if (i < targetIndex || i > sourceIndex) {
                element.style.transform = 'translateY(0px)';
              } else {
                element.style.transform = 'translateY(' + this._dragElementRectList[sourceIndex].height + 'px)';
              }
            } else if (targetIndex > sourceIndex) {
              if (i > targetIndex || i < sourceIndex) {
                element.style.transform = 'translateY(0px)';
              } else {
                element.style.transform = 'translateY(' + (-this._dragElementRectList[sourceIndex].height) + 'px)';
              }
            } else {
              element.style.transform = 'translateY(0px)';
            }
          }
        }
      }
      // event.event.preventDefault();
      event.event.stopPropagation();
    } else {
      event.source.reset();
      // event.event.preventDefault();
      // event.event.stopPropagation();
    }
    //console.log("onDragMoved", event, index, offset, task, "targetIndex",this._dragElementTargetIndex);
  }

  onDragDropped(event:CdkDragDrop<any>, index: number, offset: number, task:Task) {
    //console.log("onDragDropped", event, index, offset, task);
  }

  onDragEnded(event:CdkDragEnd, index: number, offset: number, task:Task) {
    //console.log("onDragEnded", event, index, offset, task);
    const sourceIndex = index-offset;
    const sourceList   = <Task[]>this.virtualList?.viewportItems;
    if (this._dragElementSourceIndex == sourceIndex &&
        this._dragElementTargetIndex >= 0 &&
        this._dragElementTargetIndex < sourceList.length &&
       !sourceList[this._dragElementTargetIndex].isCompleted()) {
      //event.source.element.nativeElement.classList.remove('dragging');
      this.lockDragDrop.set(true);
      const sourceTask = sourceList[this._dragElementSourceIndex];
      const targetTask = sourceList[this._dragElementTargetIndex];
      const targetTime = targetTask.calculateTimeOrder();
      const targetIndex= this._dragElementTargetIndex;
      let timeOrder = (():number => {
        let taskBefore:Task = targetIndex>sourceIndex ? targetTask : undefined;
        let taskAfter:Task  = targetIndex<sourceIndex ? targetTask : undefined;
        for (let i=targetIndex-1; i>=0 && !taskBefore; i--) {
          if (!sourceList[i].isCompleted()) {
            taskBefore = sourceList[i];
          }
        }
        for (let i=targetIndex+1, max=sourceList.length; i<max && !taskAfter; i++) {
          if (!sourceList[i].isCompleted()) {
            taskAfter = sourceList[i];
          }
        }
        return !!taskBefore && !!taskAfter ? (taskAfter.calculateTimeOrder() + taskBefore.calculateTimeOrder())/2 :
               !!taskBefore ? taskBefore.calculateTimeOrder() - 500 :
               !!taskAfter  ? taskAfter.calculateTimeOrder() + 500 : 0;
      }).bind(this)();
      //console.log("onDragEnded.1.time",timeOrder,"source",sourceIndex,sourceTask,"target",targetIndex,targetTask,[...this.subscribedTasks]);
      if (timeOrder>0) {
        const updatedTask = sourceTask.updateTimeOrder(timeOrder);
        this.taskService.syncTask(updatedTask)
          .then((task:Task)=>{
            this.taskService.save(updatedTask,sourceTask)
              .then((task:Task)=>{
                //console.log("onDrag.done",task);
                this.taskService.syncTask(task)
              })
              .catch((error:Error)=>{
                //console.log("onDrag.error",error);
                this.taskService.syncTask(sourceTask)
              })
              .finally(()=>this.lockDragDrop.set(false));
          });
      } else {
        this.lockDragDrop.set(false);
      }
      this._dragEndedHandle = window.setTimeout(() => {
        this._dragEndedHandle = undefined;
      });
      event.source.reset();
      // event.event.preventDefault();
      // event.event.stopPropagation();
      this.resetDrag();
    } else {
      event.source.reset();
      // event.event.preventDefault();
      // event.event.stopPropagation();
      this.resetDrag();
    }
    this.virtualList.virtualScrollHandler.scrollTo = this._scrollTo;
  }

  protected resetDrag() {
    this._dragElementSourceIndex =
    this._dragElementTargetIndex = undefined;
    this._dragElementList?.forEach((element:HTMLElement)=>{
      element.style.transform = "";
      element.classList.remove('dragging');
    });
    this._dragElementList = [];
    this._dragElementRectList = [];
  }

  onPressHold(element: HTMLDivElement) {
    if (!element.style?.transform) {
      element.style.transform = 'scale(1.1)';
    }
  }

  onPressEnd(element: HTMLDivElement) {
    element.style.transform = '';
  }
}
