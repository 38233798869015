import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'chat-timeline-message',
  template: `
    <p>
      chat-timeline-message works!
    </p>
  `,
  styles: []
})
export class ChatTimelineMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
