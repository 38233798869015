import {createAction, props} from "@ngrx/store";
import {Presence} from "core";

/*
  PRESENCE ACTIONS
 */

export const presenceSubscribeAction = createAction(
  'presence.subscribe',
  props<{
    id: string,
    presence?: Presence // provided by reducer
  }>()
);

export const presenceUnsubscribeAction = createAction(
  'presence.unsubscribe',
  props<{
    id: string,
    presence?: Presence // provided by reducer
  }>()
);

export const presenceUpdateDoneAction = createAction(
  'presence.update.done',
  props<{
    presence: Presence
  }>()
);
