import {
  CachedState,
  Contact,
  EMPTY_ARRAY,
  EntityState,
  IoState,
  SearchableState1,
  SelectableState,
  SubscribableState,
  TypedFilterableState,
  VersionedId
} from "core";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export interface ContactListState extends
  SearchableState1,
  TypedFilterableState,
  IoState,
  CachedState,
  SelectableState,
  EntityState<Contact>,
  SubscribableState<Contact>{
  parent?:Contact
}

export const initialContactListState : ContactListState = {
  term: '',
  filters: {},
  entities: [],
  indices: {},
  subscriptions: {},
  currentCacheId: null,
  previousCacheId: null,
  selectedIndex: null,
  loading: false,
  error: null
};

export interface ContactUplineState {
  uplines: {[key: string]: Contact[]};
  loading: boolean;
}

export const initialContactUplineState : ContactUplineState = {
  uplines: {},
  loading: false,
};

export interface GroupAuthorsState {
  authors: {[key: string]: Contact[]};
  loading: boolean;
}

export const initialGroupAuthorsState : GroupAuthorsState = {
  authors: {},
  loading: false,
};

export interface ContactState {
  readonly contactList: ContactListState;
  readonly contactUpline: ContactUplineState;
  readonly groupAuthors: GroupAuthorsState;
}

export interface State {
  readonly contact: ContactState;
}

export const selectContactState = createFeatureSelector<State, ContactState>('contact');

export const selectContactListState = createSelector(
  selectContactState,
  (state):ContactListState => state.contactList
);

export const selectContactUplineState = createSelector(
  selectContactState,
  (state):ContactUplineState => state.contactUpline
);

export const selectGroupAuthorsState = createSelector(
  selectContactState,
  (state):GroupAuthorsState => state.groupAuthors
);

export const selectContactEntities = createSelector(
  selectContactListState,
  state => state.entities
);

export const selectContactCacheId = createSelector(
  selectContactListState,
  state => state.currentCacheId
);

export const selectContactEntitiesLength = createSelector(
  selectContactEntities,
  state => state.length
);

export const selectContactLoading = createSelector(
  selectContactListState,
  state => state.loading
);

export const selectSelectedContactIndex = createSelector(
  selectContactListState,
  state => state.selectedIndex
);

export const selectContactFilters = createSelector(
  selectContactListState,
  (state:ContactListState):{[key:string]:string[]} => state.filters
);

/*
export const selectCombinedContactFilters = createSelector(
  selectContactFilters,
  (filters):string[] => <string[]>[].concat(Object.keys(filters).map(type=>(filters[type]??[])))
);

export const selectTypedContactFilters = createSelector(
  selectContactFilters,
  (filters:{[key:string]:string[]},param:{type:string, exclude:boolean}):string[] => {
    const paramType = param?.type;
    const exclude = !!param?.exclude;
    if (!paramType || exclude) {
      return <string[]>[].concat(Object.keys(filters).map(type=>(type==paramType?EMPTY_ARRAY:(filters[type]??EMPTY_ARRAY))));
    }
    return <string[]>(filters[paramType]??EMPTY_ARRAY);
  }
);*/

export const selectContactSearchTerm = createSelector(
  selectContactListState,
  state => state.term
);

export const selectSelectedContact = createSelector(
  selectContactEntities,
  selectSelectedContactIndex,
  (entities, selectedIndex) => {
    return selectedIndex >=0 && selectedIndex < entities.length ? entities[selectedIndex] : null;
  }
);

export const selectContact = createSelector(
  selectContactListState,
  (state: ContactListState, contactId: string): Contact => {
    //console.log('xbug.selectContact: '+contactId);
    const index = state.indices[contactId];
    const contact = (index >= 0 && index < state.entities.length ? state.entities[index] : undefined) ?? state.parent?.id==contactId ? state.parent : undefined;
    if (!contact) {
      const subscribed = state.subscriptions[contactId]?.entity;
      //console.log('xbug.selectContact.subscribed: '+contactId,subscribed);
      if (subscribed?.timeUpdated) {
        return subscribed;
      }
    }
    return contact;
  }
);

export const selectContactUplines = createSelector(
  selectContactUplineState,
  state => state.uplines
);

export const selectContactUplineLoading = createSelector(
  selectContactUplineState,
  state => state.loading
);

export const selectContactUpline = createSelector(
  selectContactUplineState,
  (state: ContactUplineState, contactId: string) => state.uplines[contactId] ?? EMPTY_ARRAY
);

export const selectGroupAuthors = createSelector(
  selectGroupAuthorsState,
  (state: GroupAuthorsState, groupId: string) => state.authors[groupId] ?? EMPTY_ARRAY
);

export const selectSubscribed = createSelector(
  selectContactListState,
  state => Object
    .keys(state.subscriptions)
    .map(id=><VersionedId>{
      id:id,
      version:state.subscriptions[id]?.entity?.version??0
    })
);

// MOVED to shared -> menu
// export const VIEWED_FILTER_PREFIX = 'view:';

/*
export const selectContactUpline2 = (contactId: string) => createSelector(
  selectContactUplines,
  uplines => {
      console.debug('ContactId: '+contactId);
      console.debug('UPLINES: '+JSON.stringify(uplines));
      return uplines[contactId] || [];
  }
);
*/
