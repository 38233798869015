import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Calendar, NULL_CALENDAR, NullCalendar} from "../../models/calendar";
import {Logger} from "core";
import {MatListOption, MatSelectionListChange} from "@angular/material/list";
import {CdkVirtualScrollViewport, ScrollDispatcher} from "@angular/cdk/scrolling";
import {filter} from "rxjs/operators";
import {CalendarService} from "../../services/calendar.service";

@Component({
  selector: 'app-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() entities$: Observable<Calendar[]>;
  @Input() preselect: string[];
  @Input() invert = false;
  @Input() multiple = true;
  @Output() selectionChange = new EventEmitter<string[]>();

  @ViewChild(CdkVirtualScrollViewport, { static: true }) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @ViewChildren(MatListOption) options: QueryList<MatListOption>;
  @ContentChild(TemplateRef, { static: true }) controlsTemplate: TemplateRef<any>;

  protected selected: string[] = [];

  protected calendarSubscriptions: {[key:string]:Subscription} = {};
  protected subscribedCalendars: Calendar[];

  protected entitiesSubscription: Subscription;

  protected logger = new Logger('CalendarListComponent');

  trackCalendar = (index: number, calendar: Calendar) => {
    const  safeCalendar = this.safeCalendar(calendar);
    return safeCalendar.id;// + '.' + trackedCalendar.version;
  };

  constructor (protected calendarService: CalendarService,
               protected changeDetector: ChangeDetectorRef,
               protected scrollDispatcher: ScrollDispatcher,
               @Inject(NULL_CALENDAR) private nullCalendar: NullCalendar) {
  }

  ngOnInit(): void {
    this.entitiesSubscription = this.entities$.subscribe(calendars => this.subscribed(calendars));
    this.entitiesSubscription.add(() => this.subscribed(undefined));
    if (this.preselect?.length) {
      this.selected = [...this.preselect];
    }
  }

  ngOnDestroy(): void {
    this.entitiesSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.scrollDispatcher
      .scrolled()
      .pipe(
        filter(scrollable => this.cdkVirtualScrollViewPort === scrollable)
      )
      .subscribe(() => {
        let update = false;
        this.options.forEach(option => {
          const selected = this.isSelected(option.value);
          if (selected != option.selected) {
            option.selected = selected;
            update = true;
          }
        });
        if (update) {
          this.changeDetector.detectChanges();
        }
      });
  }

  isSelected(calendarId: string): boolean {
    return this.selected.includes(calendarId) == !this.invert;
  }

  onSelectionChange(event: MatSelectionListChange) {
    // https://material.angular.io/components/list/api#MatSelectionListChange
    console.log(event.source.selectedOptions);
    const calendarId = event.options?.[0]?.value;
    const index  = this.selected.indexOf(calendarId);
    const select = index < 0;
    if (select) {
      this.selected.push(calendarId);
    } else {
      this.selected.splice(index, 1);
    }
    this.selectionChange.emit(this.selected);
  }

  get value(): string[] {
    return this.selected;
  }

  safeCalendar(calendar: Calendar): Calendar {
    return calendar || this.nullCalendar;
  }

  subscribed(calendars: Calendar[]): Calendar[] {
    if (this.subscribedCalendars!==calendars) {
      this.subscribedCalendars = calendars;
      const subscriptions: {[key:string]: Subscription} = {};
      calendars?.forEach(calendar => {
        if (!!calendar?.id) {
          subscriptions[calendar.id] =
            this.calendarSubscriptions[calendar.id] ??
            this.calendarService.getCalendar$(calendar.id,calendar).subscribe();
        }
      });
      Object.keys(this.calendarSubscriptions).forEach(calendarId=>{
        if (!subscriptions[calendarId]) {
          this.calendarSubscriptions[calendarId].unsubscribe();
        }
      });
      this.calendarSubscriptions = subscriptions;
    }
    return calendars;
  }
}
