<!--<div>{{'DATE:' + (date | date)}}</div>-->
<ng-container [ngSwitch]="view">
  <div *ngSwitchCase="'month'">
    <div class="header">
      <div>
        <span>{{ date | calendarDate: 'monthViewTitle': locale }}</span>
      </div>
    </div>
    <mwl-calendar-month-view
      [style.--monthViewFontSize]="monthViewFontSize"
      [viewDate]="date"
      [events]="events"
      [locale]="locale"
      [refresh]="refresh"
      [cellTemplate]="customCellTemplate"
      (eventClicked)="onCalendarEventSelected($event)"
      (dayClicked)="onDayClicked($event.day.date)">
    </mwl-calendar-month-view>
  </div>
  <ng-container *ngSwitchCase="'week'">
    <mwl-calendar-week-view
      calendarTimeframeSelector
      [viewDate]="date"
      [daysInWeek]="daysInWeek"
      [events]="events"
      [locale]="locale"
      [hourSegmentTemplate]="weekViewHourSegmentTemplate"
      [refresh]="refresh"
      (eventClicked)="onCalendarEventSelected($event)"
      (dayHeaderClicked)="onDayClicked($event.day.date)">
    </mwl-calendar-week-view>
  </ng-container>
  <ng-container *ngSwitchCase="'day'">
    <mwl-calendar-week-day-view
      calendarTimeframeSelector
      [viewDate]="date"
      [daysInWeek]="1"
      [events]="events"
      [locale]="locale"
      [hourSegmentTemplate]="weekViewHourSegmentTemplate"
      [refresh]="refresh"
      (eventClicked)="onCalendarEventSelected($event)">
    </mwl-calendar-week-day-view>
  </ng-container>
</ng-container>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-space">
  </div>
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
    <span class="cal-day-number" (click)="daySelect.emit(day.date)">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
  </div>
</ng-template>

<ng-template
  #weekViewHourSegmentTemplate
  let-segment="segment"
  let-locale="locale"
  let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel">
  <ng-template #timeTemplate>
    <div class="cal-time" *ngIf="isTimeLabel">
      {{ segment.date | calendarDate:'weekViewHour': locale }}
    </div>
  </ng-template>
  <ng-container [ngSwitch]="platform.is('mobile')">
    <div #segmentElement
         *ngSwitchCase="true"
         class="cal-hour-segment"
         [style.height.px]="segmentHeight"
         [class.cal-hour-start]="segment.isStart"
         [class.cal-after-hour-start]="!segment.isStart"
         [ngClass]="segment.cssClass"
         (touchstart)="onSegmentTouchStart(segment, segmentElement, $event)">
        <!--
        NOTE: Usage of PressDirective leads to some unexpected event firing patterns (at least) on android app (desktop chrome works properly),
        Reacting directly on touchstart event and implementing the required delay logic works as intended
        (onPressStart)="onSegmentPressStart(segment, segmentElement)"
        (onPressHold)="onSegmentPressHold(segment, segmentElement)">
        -->
      <ng-container *ngTemplateOutlet="timeTemplate; context: {segment, locale}"></ng-container>
    </div>
    <div #segmentElement
         *ngSwitchCase="false"
         class="cal-hour-segment"
         [style.height.px]="segmentHeight"
         [class.cal-hour-start]="segment.isStart"
         [class.cal-after-hour-start]="!segment.isStart"
         [ngClass]="segment.cssClass"
         (mousedown)="onSegmentMouseDown(segment, segmentElement)">
      <ng-container *ngTemplateOutlet="timeTemplate; context: {segment, locale}"></ng-container>
    </div>
  </ng-container>
</ng-template>
