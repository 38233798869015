import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output} from '@angular/core';
import {ResizeService} from "shared";
import {Media, MediaService, MediaViewerOverlayService} from "media";
import {Attachment} from "messaging";
import {Space} from "../../core/space";
import {MediaAttachmentComponent} from "../core/media.attachment.component";

@Component({
  selector: 'media-attachment-square-viewer',
  template: `<media-card
      [media]="media"
      [selectable]="false"
      [rounded]="true"
      [elevated]="false"
      [indicator]="false"
      [defaultCover]="true"
      [forcedCoverHeight]="100">
    </media-card>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaAttachmentSquareViewerComponent extends MediaAttachmentComponent {
  protected _space:Space = undefined;

  media:Media = undefined;

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    super.attachments = attachments;
    this.media = this.mediaAttachments?.length>0 ? this.mediaAttachments[0].media : undefined;
    this._space = !!this.media ? {
      fixedWidth:false,
      fixedHeight:false,
      fixedRatio:true,
      ratio:1
    } : undefined;
  }
  get attachments():Attachment[] {
    return super.attachments;
  }

  get space():Space {
    return this._space;
  }

  constructor(
    protected mediaService: MediaService,
    protected resizeService: ResizeService,
    protected mediaViewerOverlayService: MediaViewerOverlayService,
    protected changeDetectorRef: ChangeDetectorRef) {
    super(mediaViewerOverlayService,changeDetectorRef);
  }
}
