<div class="header-container" [class.expanded]="accordion?.expandedSection==0" [class.large]="largeAvatar">
<!--  <ng-content class="header-control"></ng-content>-->
  <div *ngIf="leftControlTemplate" class="header-control">
    <ng-container *ngTemplateOutlet="leftControlTemplate"></ng-container>
  </div>
  <div #header
       *ngIf="contact"
       class="header mat-expansion-panel-header"
       [style.paddingLeft.px]="leftControlTemplate ? 40 : 20"
       (click)="expand($event)">
    <img matListItemAvatar [src]="photoUrl | sessionLink"
         onload="this.style.opacity=1"
         (click)="toggleAvatar()"
         [ngStyle]="largeAvatar ? largeAvatarStyle : {}" />
    <div class="name-container">
      <div class="name">{{contact.name}}</div>
      <member-id *ngIf="contact.memberId; let memberId"
                 [memberId]="memberId"
                 [actions]="contact.app?.actions"
                 [role]="contact.role=='admin'||contact.role=='leader'?'leader':contact.role=='user'?'member':'guest'">
      </member-id>
      <ng-container *ngIf="!contact.memberId">
        <app-partner-id [membership]="membership | async" [hasMenu]="appId==1"></app-partner-id>
      </ng-container>
    </div>
    <span class="mat-expansion-indicator"></span>
  </div>
  <div *ngIf="rightControlTemplate" class="header-control">
    <ng-container [ngTemplateOutlet]="rightControlTemplate"></ng-container>
  </div>
</div>
<accordion-container #accordion [sections]="sections"></accordion-container>
