import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ResizeService} from "shared";
import {MessageType} from "../../store/models";

@Component({
  selector: 'chat-message-type-selector',
  templateUrl: './chat-message-type-selector.component.html',
  styleUrls: ['./chat-message-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageTypeSelectorComponent implements OnInit, AfterViewInit {

  @Output() changeEvent = new EventEmitter<MessageType>();
  @Input() @Output() type:MessageType = 'chat';

  constructor(protected resizeService: ResizeService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  reset() : void {
    this.value = 'chat';
  }

  get value() : MessageType {
    return this.type;
  }

  set value(value : MessageType) {
    if (this.type!=value) {
      this.type = value;
      this.changeEvent.emit(value);
    }
  }
}
