<ng-container *ngIf="taskList" [ngSwitch]="editMode">
  <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="view"></ng-container>
  <ng-container *ngSwitchCase="true"  [ngTemplateOutlet]="edit"></ng-container>
</ng-container>

<ng-template #view>
  <div #scroller class="view">
    <mat-form-field *ngIf="taskList.title" class="no-disabled-color">
      <mat-label translate>tasks.taskList.title</mat-label>
      <!--
        ATTENTION: autofocus=true moves the focused element into view (at least on chrome)
        This is a problem when the element is in the dom but is not supposed to be visible e.g.
        when using app-slide-panel.
      -->
      <input type="text"
             unmodified-input
             matInput
             [value]="taskList.title | translate"
             [autoFocus]="false"
             disabled/>
    </mat-form-field>
    <mat-form-field *ngIf="taskList.info" class="no-disabled-color">
      <mat-label translate>tasks.taskList.info</mat-label>
      <textarea matInput
                unmodified-input
                [value]="taskList.info"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8"
                disabled>
      </textarea>
    </mat-form-field>
    <mat-form-field  class="no-disabled-color">
      <mat-label translate>tasks.taskList.dateCreated</mat-label>
      <input type="text"
             unmodified-input
             matInput
             [value]="timeCreated(taskList) | date"
             disabled/>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #edit>
  <form #scroller left [formGroup]="taskListForm" class="edit">
    <mat-form-field>
      <mat-label translate>tasks.taskList.title</mat-label>
      <input type="text"
             unmodified-input
             matInput
             formControlName="title"
             name="title"
             [autoFocus]="autoFocus"
             required/>
      <mat-error *ngIf="taskListForm.controls.title.invalid && taskListForm.controls.title.touched">
        <span translate>tasks.validation.titleRequired</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label translate>tasks.taskList.info</mat-label>
      <!--<mat-icon matPrefix>theaters</mat-icon>-->
      <textarea matInput
                unmodified-input
                formControlName="info"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8">
        </textarea>
    </mat-form-field>
    <!--
    <mat-form-field *ngFor="let term of ['editors', 'viewers']">
      <mat-label translate>{{'tasks.terms.'+term | translate}}</mat-label>
      <mat-icon matPrefix>{{term=='editors' ? 'edit' : 'preview' }}</mat-icon>
      <input type="text"
             unmodified-input
             matInput
             [name]="term+'Term'"
             [formControlName]="term+'Term'"
             [attr.aria-label]="term+' term'"/>
    </mat-form-field>
    -->
    <filter-tag-section *ngIf="displayedTypesTopic?.topics?.length > 0"
                        [filter]="displayedTypesTopic"
                        [selected]="taskListForm.value['type']"
                        (onFilter)="onTapTypeFilter($event)"
                        class="section">
    </filter-tag-section>
    <mat-form-field class="selector" (click)="openColorPicker(colorPicker)">
      <!--
      <div>{{'VALUE:'+taskListForm.controls.color.value}}</div>
      <div>{{'INVALID:'+taskListForm.controls.color.invalid}}</div>
      <div>{{'DIRTY:'+taskListForm.controls.color.dirty}}</div>
      <div>{{'TOUCHED:'+taskListForm.controls.color.touched}}</div>
      -->
      <div class="colorPrefix" [style.backgroundColor]="taskListForm.controls.color.value?.toHexString()" matPrefix></div>
      <input matInput [ngxMatColorPicker]="colorPicker"
                      formControlName="color"
                      disabled>
      <ngx-mat-color-toggle matSuffix [for]="colorPicker">
        <mat-icon ngxMatColorpickerToggleIcon>keyboard_arrow_down</mat-icon>
      </ngx-mat-color-toggle>
      <ngx-mat-color-picker #colorPicker
                            [touchUi]="platform.is('mobile')"
                            [color]="'primary'">
      </ngx-mat-color-picker>
      <mat-error *ngIf="taskListForm.controls.color.invalid && taskListForm.controls.color.touched">
        <span translate>tasks.validation.colorInvalid</span>
      </mat-error>
    </mat-form-field>
    <ng-container *ngTemplateOutlet="controlsTemplate; context: { taskList: current }"></ng-container>
  </form>
</ng-template>
