import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ConferenceService} from "../../conference.service";
import {LayoutService} from "layout";
import {BehaviorSubject, Subscription} from "rxjs";
import {BasicContainerComponent, OnDomModified} from "shared";
import {takeUntil} from "rxjs/operators";
import {MessagingService} from "messaging";
import {ConferenceInfo} from "../../store/models";
import {ConferencePanelOverlayService} from "../conference-panel/conference-panel-overlay.service";
import isEqual from "lodash/isEqual";
import {ConversationCallBarComponent} from "chat";
import {isInteger} from "lodash";

export type ConferenceState = 'idle'|'incoming'|'connected';

@Component({
  selector: 'conference-bar',
  templateUrl: './conference-bar.component.html',
  styleUrls: ['./conference-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConferenceBarComponent extends BasicContainerComponent implements ConversationCallBarComponent, OnDomModified {

  public buttons$ = new BehaviorSubject<number>(0);
  public state$ = new BehaviorSubject<ConferenceState>('idle');
  public conferenceInfo$ = new BehaviorSubject<ConferenceInfo>(undefined);
  public ringing  = false;
  public overtake = false;

  @Input()
  set conferenceInfo(conference:ConferenceInfo) {
    this.conferenceInfo$.next(conference);
    const connectionId  = this.conferenceService.connectionId;
    const participantId = this.conferenceService.userId;
    this.ringing  = !!conference?.ringing?.find(p=>p.id==participantId);
    this.overtake = !this.ringing && !!conference?.participant?.connectionId && conference.participant.connectionId!=connectionId;
  }

  get conferenceInfo():ConferenceInfo {
    return this.conferenceInfo$.value;
  }

  protected _contactIds:string[] = [];
  @Input()
  set contactIds(contactIds:string[]) {
    this._contactIds = contactIds || [];
  }
  get contactIds():string[] {
    return this._contactIds;
  }

  protected _conversationConferenceSubscription:Subscription;
  protected _conversationConferenceTimer:number;
  protected _conversationId:string = undefined;
  @Input()
  set conversationId(conversationId:string) {
    this._conversationId = conversationId;
    this._conversationConferenceSubscription?.unsubscribe();
    window.clearTimeout(this._conversationConferenceTimer);
    this._conversationConferenceTimer = window.setTimeout(()=>{
      if (!!this.conversationId) {
        this._conversationConferenceSubscription = this.conferenceService
          .getConversationConferenceInfo$(this.conversationId)
          .subscribe(info=> {
            this.conferenceInfo = info;
          });
      }
    });
  }
  get conversationId():string {
    return this._conversationId;
  }

  @ViewChild('container') container: ElementRef;
  @Input() columns:number = 4;
  @Input() maxColumns:number = undefined;
  @Input() minColumns:number = undefined;

  @Output() onPendingConversationCalls = new BehaviorSubject<string[]>([]);

  constructor(public conferenceService:ConferenceService,
              public conferencePanelOverlayService:ConferencePanelOverlayService,
              public messagingService:MessagingService,
              public layoutService:LayoutService,
              public changeDetectorRef: ChangeDetectorRef) {
    super();
    this.conferenceInfo$.pipe(takeUntil(this.onDestroy$))
      .subscribe(conferenceInfo => {
      let state = this.getConferenceState(conferenceInfo);
      if (this.state$.value!=state) {
        this.state$.next(state);
      }
      //this.changeDetectorRef.markForCheck();
    });
    this.conferenceService.getAllConferenceInfos$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(infos => {
        const conversationIds = infos.filter(info=>!!info.conversationId).map(info=>info.conversationId);
        //console.log("CONFERENCE_CONVERSATION_IDS",infos,conversationIds);
        if (!isEqual(conversationIds,this.onPendingConversationCalls.value)) {
          this.onPendingConversationCalls.next(conversationIds);
        }
      });
  }

  protected getConferenceState(conference:ConferenceInfo):ConferenceState {
    const userId = this.conferenceService.userId;
    const connectionId = this.conferenceService.connectionId;
    const state:ConferenceState = !!conference
      ? !!conference.ringing.find(p=>p.id==userId)
        ? 'incoming'
        : !!conference.pickedUp.find(p=>p.id==userId) ||
          !!conference.paused.find(p=>p.id==userId)
          ? !!conference.pickedUp.find(p=>p.id==userId && p.connectionId==connectionId) ||
            !!conference.paused.find(p=>p.id==userId && p.connectionId==connectionId)
            ? 'connected'
            : 'incoming'
          : 'idle'
      : 'idle';
    //console.log("STATE",state,"conference",conference);
    return state;
  }

  /**
   * CALL METHODS
   */

  hangup(conferenceId:string) {
    this.conferenceService.hangUpConference(conferenceId);
  }

  isPaused(conference:ConferenceInfo):boolean {
    let userId = this.conferenceService.userId;
    return !!userId && !!conference?.paused.find(p=>p.id==userId);
  }

  pause(conferenceId:string) {
    this.conferenceService.pauseActiveConference();
  }

  pickUp(conferenceId:string,audio:boolean=true,video:boolean=false) {
    //console.log("pickUp",conferenceId,"audio",audio,"video",video);
    this.conferenceService.pickUpConference(conferenceId,audio,video);
  }

  continue(conferenceId:string) {
    this.conferenceService.pickUpConference(conferenceId);
  }

  updateAudioVideo(conferenceId:string,audio:boolean,video:boolean) {
    this.conferenceService.updateMediaStream(conferenceId,audio,video);
  }

  startConference(video:boolean) {
    let directContactId = !!this.conversationId ? this.conferenceService.getDirectContactId(this.conversationId) : undefined;
    //console.log("CALL",directContactId,"conversationId",this.conversationId);
    this.conferenceService.startConference(video ? 'video' : 'audio',
      !!directContactId ? [directContactId] : this.contactIds, this.conversationId);
  }

  onDomModified(): void {
    const children = this.container?.nativeElement?.children?.length;
    if (isInteger(children)) {
      //console.log("CHILDREN",children);
      const hasMaxColumns = isInteger(this.maxColumns);
      const hasMinColumns = isInteger(this.minColumns);
      let columns = children;
      // if maxColumns or minColumns is set, then we need to calculate the columns
      if (hasMaxColumns ||
          hasMinColumns) {
        let max = Math.max(1, Math.min(children, hasMaxColumns ? this.maxColumns : children));
        let min = Math.max(1, Math.min(max, hasMinColumns ? Math.min(this.minColumns,max) : max));
        columns = min>=children ? min :
                  max>=children ? children : max;
        const rows = Math.ceil(children / columns);
        while (rows>1 && columns>min && (children%columns)>=rows) {
          columns--;
        }
      }
      if (columns!=this.columns) {
        this.columns = columns;
        this.changeDetectorRef.markForCheck();
      }
    }
  }
}
