<!-- <div class="indicator" [class.saving]="lockDragDrop()"></div> -->
<virtual-section-scroller #scroller [scrollToAdded]="scroller"
                          [items$]="entities$"
                          [itemsId$]="cacheId$"
                          [startFromTop]="true"
                          [itemHeaderElementAccessor]="itemHeaderElementAccessor"
                          [sectionIndexes$]="sections$"
                          [renderEndComponentTemplateRef]="moreTemplate"
                          [endGap]="100"
                          (onScrolled)="onScrolled($event)"
                          (onResize)="onResize($event)">
  <ng-container *virtualFor="let task of scroller.viewportItems
                                   offset scroller.viewportOffset
                                    total scroller.items?.length;
                                      let offset = offset;
                                      let index  = index;
                                      let total  = total;
                                      trackBy: trackTask;">
    <ng-container *ngIf="task ?? nullTask; let task">
      <div #element
           *ngIf="{ dragDisabled: task.hasFixedOrder() || lockDragDrop() } as context"
           class="container"
           cdkDrag
           cdkDragStartDelay="1000"
           cdkDragLockAxis="y"
           [cdkDragDisabled]="context.dragDisabled"
           (cdkDragStarted)="onDragStarted($event,index,offset,task)"
           (cdkDragReleased)="onDragReleased($event,index,offset,task)"
           (cdkDragMoved)="onDragMoved($event,index,offset,task)"
           (cdkDragDropped)="onDragDropped($event,index,offset,task)"
           (cdkDragEnded)="onDragEnded($event,index,offset,task)"
           [pressTriggerMillis]="800"
           [pressDisabled]="context.dragDisabled"
           (onPressHold)="onPressHold(element)"
           (onPressEnd)="onPressEnd(element)">
        <div class="task">
          <div class="controls">
            <ng-container *ngTemplateOutlet="controlsLeftTemplate; context: { index, task }"></ng-container>
          </div>
          <app-task-list-item [task]="task"
                              [index]="index"
                              [dense]="dense"
                              (select)="onTaskSelection($event)">
          </app-task-list-item>
          <div class="controls">
            <ng-container *ngTemplateOutlet="controlsRightTemplate; context: { index, task }"></ng-container>
          </div>
          <ng-container *ngLet="task.taskList?.color as color">
            <div class="list-indicator" [style.backgroundColor]="!!color ? '#'+color : undefined"></div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</virtual-section-scroller>

<ng-template #moreTemplate>
  <ng-container *ngLet="morePassive$ | async as more">
    <ng-container *ngIf="more.size > 0">
      <div class="actions more" *ngIf="!more.loading">
        <button mat-button (click)="more.load(100)"><span translate>actions.more</span></button>
      </div>
      <div class="container more" *ngIf="more.loading">
        <div class="task">
          <div class="controls"><mat-checkbox [disabled]="true" [checked]="true"></mat-checkbox></div>
          <div class="label" translate>app.loading</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
