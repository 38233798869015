/* projects/calendar/src/lib/components/event-map/event-map.component.scss */
:host {
  display: block;
  overflow: hidden;
}
div.mapContainer {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=event-map.component-L2WYJ7EQ.css.map */
