import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  EventEmitter,
  HostListener,
  input,
  model,
  Output,
  signal
} from '@angular/core';
import {BasicContainerComponent} from "shared";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationRoutingGuard} from "auth";
import {ChatService} from "../../chat.service";
import {BehaviorSubject, combineLatest, firstValueFrom, startWith, Subscription, takeUntil} from "rxjs";
import {Contact, FilterTypes, REMOVE_ALL_OTHER_TYPES} from "core";
import {ContactService, Selection, TermTestResult} from "contact";
import isEqual from "lodash/isEqual";
import {ErrorStateMatcher} from "@angular/material/core";
import {FormControl, FormGroupDirective, NgForm} from "@angular/forms";
import {ConversationData} from "chat";
import {toObservable} from "@angular/core/rxjs-interop";

interface InternalProperties {
  searchTerm:string;
  groupTerm:string;
  groupName:string;
}

class TermTestErrorStateMatcher implements ErrorStateMatcher {
  constructor(public data?:TermTestResult) {}
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.data?.errorLabel?.length>0;
  }
}

@Component({
  selector: 'chat-conversation-create-container',
  templateUrl: './chat-conversation-create-container.component.html',
  styleUrls: ['./chat-conversation-create-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationCreateContainerComponent extends BasicContainerComponent {

  disabled = input<boolean>(false);
  type = input<'direct'|'group'|undefined>(undefined);
  conversationId = input<string>(undefined);

  searchTerm = model<string>(undefined);

  groupParticipantIds = model<string[]>([]);
  groupTerm = model<string>(undefined);
  groupName = model<string>(undefined);

  groupTermErrorStateMatcher = signal<TermTestErrorStateMatcher>(new TermTestErrorStateMatcher());
  groupTermUpdating = signal<boolean>(false);
  selectedParticipants = signal<{[key: string]: boolean}>({});
  selectedParticipantIds = computed<string[]>(()=>Object.keys(this.selectedParticipants()));
  connected = signal<boolean>(false);
  conversationData = signal<ConversationData>(undefined);

  connected$ = toObservable(this.connected);
  conversationData$ = toObservable(this.conversationData);

  loading = signal<boolean>(false);
  saving = signal<boolean>(false);

  save = computed<boolean>(()=>{
    const conversationData = this.conversationData();
    const groupName = this.groupName()?.trim() ?? undefined;
    const groupTerm = this.groupTerm()?.trim() ?? undefined;
    const currentSelected = this.selectedParticipants();
    const currentSelectedIds = Object.keys(currentSelected);
    console.log("SAVE.name.1",conversationData?.name,"groupName",groupName,"term",conversationData?.term,"groupTerm",groupTerm,"initial","current",currentSelectedIds,"type",this.type(),"!this.groupTermUpdating()",!this.groupTermUpdating(),"!this.loading()",!this.loading(),"!this.saving()",!this.saving(),"currentSelectedIds.length>0",currentSelectedIds.length>0);
    if (this.type()=='group' &&
        groupName?.length>0 &&
       !this.groupTermUpdating() &&
       !this.loading() &&
       !this.saving() &&
       (groupTerm?.length>0 || currentSelectedIds.length>0)) {
      const groupParticipantIds = this.groupParticipantIds();
      const initialSelectedIds = new Set<string>(groupParticipantIds);
      const initialName = conversationData?.name ?? undefined;
      const initialTerm = conversationData?.term ?? undefined;
      if (initialName!=groupName ||
          initialTerm!=groupTerm ||
          currentSelectedIds.length!=initialSelectedIds.size ||
        !!currentSelectedIds.find(id=>!initialSelectedIds.has(id)) ||
        !!groupParticipantIds.find(id=>!currentSelected[id])) {
        /*console.log("SAVE.name.2",initialName,"groupName",groupName,"term",initialTerm,"groupTerm",groupTerm,"initial",groupParticipantIds,"current",currentSelectedIds,
          "cmp1",currentSelectedIds.find(id=>!initialSelectedIds.has(id)),
          "cmp2",groupParticipantIds.find(id=>!currentSelected[id]));*/
        console.log("SAVE.true");
        return true;
      }
    }
    console.log("SAVE.false");
    return false;
  });

  cacheId$ = new BehaviorSubject<string>(undefined);
  contacts$ = new BehaviorSubject<Contact[]>([]);

  @Output() closed = new EventEmitter<boolean>();

  protected conversationSubscription:Subscription = undefined;

  static instances = <ChatConversationCreateContainerComponent[]>[];

  constructor(public chatService:ChatService,
              public contactService:ContactService,
              public route: ActivatedRoute,
              public router: Router,
              public routingGuard : AuthenticationRoutingGuard,
              public changeDetectorRef: ChangeDetectorRef) {
    super();
    effect(() => {
      const groupParticipantIds = this.groupParticipantIds();
      if (this.log) console.log("CONVERSATION.CREATE.CONTAINER.groupParticipantIds",groupParticipantIds);
      const selected:{[key: string]: boolean} = {};
      groupParticipantIds?.forEach(id=>{
        selected[id]=true;
      });
      this.selectedParticipants.set(selected);
    }, { allowSignalWrites: true });
    effect(() => {
      const conversationId = this.conversationId();
      if (this.log) console.log("CONVERSATION.CREATE.CONTAINER.conversationId",conversationId);
      const conversationData = this.conversationData();
      if (!!conversationId && conversationId!=conversationData?.id) {
        this.conversationSubscription?.unsubscribe();
        this.conversationSubscription = this.chatService.getConversationData$(conversationId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(data => {
            if (data?.id == conversationId) {
              if (this.log) console.log("CONVERSATION.CREATE.CONTAINER.conversation", data);
              this.conversationData.set(data);
            }
          });
      } else if (!conversationId && !!conversationData) {
        this.conversationData.set(undefined);
      }
    }, { allowSignalWrites: true });
    effect(() => {
      const groupTerm = this.groupTerm()?.trim();
      if (groupTerm?.length>0) {
        if (this.selectedParticipantIds().length>0) {
          this.selectedParticipants.set({});
        }
        if (this.log) console.log("TEST_TERM.start",groupTerm)
        this.groupTermUpdating.set(true);
        this.contactService.testTerm$(groupTerm)
          .then((result)=>{
            if (this.log) console.log("TEST_TERM.result",result);
            this.groupTermErrorStateMatcher.set(new TermTestErrorStateMatcher(result));
          })
          .catch((error)=>{})
          .finally(()=>this.groupTermUpdating.set(false));
      } else {
        this.groupTermErrorStateMatcher.set(new TermTestErrorStateMatcher(undefined));
        const selectedIds:{[key: string]: boolean} = {};
        this.groupParticipantIds()?.forEach(id=>{
          selectedIds[id]=true;
        });
        this.selectedParticipants.set(selectedIds);
      }
    }, { allowSignalWrites: true });
    effect(() => {
      const searchTerm = this.searchTerm();
      if (!!this.connected()) {
        this.contactService.updateSearchTerm(searchTerm);
      }
    }, { allowSignalWrites: true });
    ChatConversationCreateContainerComponent.instances.push(this);
  }

  ngOnDestroy() {
    //console.log(this.instanceId,"CONVERSATION.CREATE.CONTAINER.destroy")
    const self = this;
    ChatConversationCreateContainerComponent.instances = ChatConversationCreateContainerComponent.instances.filter(i=>i!=self);
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
    let previousGroupId:string = undefined;
    let previousFilters:string[] = undefined;
    if (this.log) console.log(this.instanceId,"LIST.init");
    this.addSubscription(this.contactService.cacheId$.subscribe(cacheId=>this.cacheId$.next(cacheId)));
    combineLatest([
      this.contactService.entities$,
      this.contactService.filters$,
      this.contactService.cacheId$,
      this.connected$.pipe(startWith(this.connected())),
      this.conversationData$.pipe(startWith(this.conversationData())),
    ]).pipe(takeUntil(this.onDestroy$))
      .subscribe(([entities,filters,cacheId,connected,conversationData])=>{
        if (this.log) console.log(this.instanceId,"LIST.entities",entities?.length,"connected",connected,"conversationData",conversationData,"filters",filters,this.filters(conversationData?.groupId),"cacheId",cacheId,this.cacheId$.value);
        if (connected && !this.loading() &&
          !!conversationData &&
            conversationData.groupId!=previousGroupId) {
          if (this.log) console.log(this.instanceId,"LIST.reset","connected",connected,"!loading",!this.loading(),
            "!groupEqual",conversationData.groupId!=previousGroupId,conversationData.groupId,previousGroupId,
            "!cacheIdEqual",this.cacheId$.value!=cacheId,this.cacheId$.value,cacheId,"!filtersEqual",!isEqual(filters,previousFilters),filters,previousFilters);
          previousGroupId = conversationData.groupId;
          previousFilters = filters;
          this.loading.set(true);
          this.reset(false).then(result=>{
            this.loading.set(false);
            this.cacheId$.next(result.cacheId);
          });
        }
        this.contacts$.next((connected && this.cacheId$.value==cacheId) ? entities : []);
      });
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.close(false);
  }

  close(changed:boolean) {
    this.contactService.updateSearchTerm(undefined);
    this.closed.emit(changed);
  }

  onSelect(selection:Selection) {
    console.log("ON_SELECT",selection,"term",this.groupTerm(),"keys");
    //console.log("SELECT",selection,"type",this.type);
    const groupTerm = this.groupTerm()?.trim();
    if (!this.disabled() && !(groupTerm?.length>0)) {
      let id = selection?.contact?.id;
      if (id) {
        if (this.type()=='direct') {
          console.log("XYZ.direct",selection.contact.id);
          this.chatService.createOrUpdateDirectConversation(selection.contact.id)
            .then(conversationId=>{
              this.chatService.setCurrentConversationId(conversationId);
            })
            .catch(reason => console.log(reason))
            .finally(()=>{
              this.close(true);
            });
        } else {
          let selected = {...this.selectedParticipants()};
          if (selected[id]) {
            delete selected[id];
          } else {
            selected[id] = true;
          }
          this.selectedParticipants.set(selected);
          console.log("XYZ.group",selected);
        }
      }
    }
  }

  reset(clear:boolean):Promise<{filters:string[],cacheId:string}> {
    if (this.log) console.log(this.instanceId,"LIST.reset",clear);
    const filters = this.filters(this.conversationData()?.groupId);
    this.searchTerm.set(undefined);
    if (!!clear) {
      this.groupTerm.set(undefined);
      this.groupName.set(undefined);
      this.groupParticipantIds.set([]);
    }
    return new Promise<{filters:string[],cacheId:string}>((resolve, reject) => {
      Promise.all([
        this.contactService.setTypedFilters({[FilterTypes.NAVIGATION]:filters},REMOVE_ALL_OTHER_TYPES),
        this.contactService.updateSearchTerm(undefined)
      ]).finally(()=>{
        firstValueFrom(this.contactService.cacheId$).then(cacheId=>resolve({filters,cacheId}));
      });
    });
  }

  saveGroupChat() {
    const initialSelected = new Set<string>(this.groupParticipantIds());
    const currentSelected = new Set<string>(this.selectedParticipantIds());
    const participantIds = [];
    const updatedIds = [];
    initialSelected.forEach(id=>{
      if (!currentSelected.has(id)) {
        updatedIds.push(id);
      }
    });
    currentSelected.forEach(id=>{
      participantIds.push(id);
      if (!initialSelected.has(id)) {
        updatedIds.push(id);
      }
    });
    this.saving.set(true);
    this.chatService.createOrUpdateGroupConversation(this.type(),
      this.groupName(),
      this.groupTerm(),
      participantIds,
      updatedIds,
      this.conversationId())
      .then((conversationId)=>{
        //console.log("ON_OK_PRESSED",conversationId,participantIds,"updated",updatedIds);
        this.chatService.setCurrentConversationId(conversationId);
        this.closed.emit(true);
      })
      .finally(()=>this.saving.set(false));
  }

  connect() {
    if (this.log) console.log(this.instanceId,"LIST.attach.true")
    if (!this.connected()) {
      this.connected.set(true);
      const self = this;
      ChatConversationCreateContainerComponent.instances.forEach(i=>{ if(i!=self) { i.closed.emit(false); }});
    }
  }

  disconnect() {
    if (this.log) console.log(this.instanceId,"LIST.attach.false")
    if (!!this.connected()) {
      this.connected.set(false);
    }
  }

  filters(groupId:string):string[] {
    const filters = ['team.connected'];
    if (!!groupId) {
      filters.push(`group:${groupId}`);
    }
    return filters;
  }
}
