/* projects/contact/src/lib/components/contact-detail/contact-detail-filters/contact-detail-filters.component.scss */
:host {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px 24px;
}
button {
  float: right;
}
/*# sourceMappingURL=contact-detail-filters.component-L5LJZIIU.css.map */
