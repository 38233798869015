<ng-container *ngIf="display">
  <div class="chart">
    <div>
      <div class="red" [style.width.%]="fill/2" [style.height.%]="fill/2"><div [style.width.%]="value(colors.red)" [style.height.%]="value(colors.red)"></div></div>
      <div class="yellow" [style.width.%]="fill/2" [style.height.%]="fill/2"><div [style.width.%]="value(colors.yellow)" [style.height.%]="value(colors.yellow)"></div></div>
      <div class="green" [style.width.%]="fill/2" [style.height.%]="fill/2"><div [style.width.%]="value(colors.green)" [style.height.%]="value(colors.green)"></div></div>
      <div class="blue" [style.width.%]="fill/2" [style.height.%]="fill/2"><div [style.width.%]="value(colors.blue)" [style.height.%]="value(colors.blue)"></div></div>
    </div>
  </div>
</ng-container>
