import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import moment from 'moment';
import {CalendarView} from 'angular-calendar';
import {Logger} from "core";
import {CalendarViewService} from "../../services/calendar.view.service";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {map, startWith} from "rxjs/operators";
import {DateAdapter} from "@angular/material/core";
import {MatMenuTrigger} from "@angular/material/menu";
import {Subscription} from "rxjs";

@Component({
  selector: 'calendar-navigation',
  templateUrl: './calendar-navigation.component.html',
  styleUrls: ['./calendar-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarNavigationComponent implements OnInit, OnDestroy{

  @ViewChild('viewMenuTrigger') viewMenuTrigger: MatMenuTrigger;

  @Input() date = new Date();
  @Input() period = 'all';
  @Input() periodSelector = false;
  @Input() daysInWeek = 7;

  @Output() navigate = new EventEmitter<Partial<{ period: string, date: Date }>>();

  protected subscription = new Subscription();
  protected logger = new Logger('CalendarNavigationComponent');

  constructor(public calendarViewService: CalendarViewService,
              protected translateService: TranslateService,
              protected dateAdapter: DateAdapter<any>) {
  }

  ngOnInit() {
    this.subscription.add(
        // TODO: should be revised - dateAdapter locale should be managed externally
        this.translateService.onLangChange
            .pipe(
                map((event: LangChangeEvent) => event.lang),
                startWith(this.translateService.currentLang),
            )
            .subscribe(language => this.dateAdapter.setLocale(language))
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  containsToday(view: CalendarView): boolean {
    const now = Date.now();
    switch (view) {
      case CalendarView.Month:
        //console.log("containsToday",view,moment(this.viewDate).isSame(now,"month"));
        return moment(this.date).isSame(now,"month");
      case CalendarView.Week: {
        const start = moment(this.date).startOf('day');
        const end   = moment(start).add(Math.max(0,this.daysInWeek-1),"days").endOf('day');
        console.log("containsToday",view,start.toDate(),end.toDate(),moment(now).isBetween(start,end,undefined, '[]'));
        return moment(now).isBetween(start,end,undefined, '[]');
      }
      default:
        //console.log("containsToday",view,moment(this.viewDate).isSame(now,"day"));
        return moment(this.date).isSame(now,"day");
    }
  }

  today() {
    this.navigate.emit({ date: new Date() });
  }

  next(view: CalendarView) {
    const date =
      view==CalendarView.Month ? moment(this.date).startOf('month').add(1,'month').toDate() :
      view==CalendarView.Week ? moment(this.date).startOf('day').add(this.daysInWeek,'days').toDate() :
      moment(this.date).add(1,'day').toDate();
    this.navigate.emit({ date });
  }

  previous(view: CalendarView) {
    const date =
      view==CalendarView.Month ? moment(this.date).startOf('month').subtract(1,'month').toDate() :
      view==CalendarView.Week ? moment(this.date).startOf('day').subtract(this.daysInWeek,'days').toDate() :
      moment(this.date).subtract(1,'day').toDate();
    this.navigate.emit({ date });
  }

  onTapCalendar(event: MouseEvent) {
    this.logger.debug('onTapCalendar', event);
    const target: any = event.target;
    let parent = target?.parentNode;
    let stop = true;
    let classList;
    while ((classList = parent?.classList) && !classList.contains('mat-calendar-controls')) {
      if (classList.contains('mat-calendar')) {
        stop = false;
        break;
      }
      parent = parent.parentNode;
    }
    if (stop) {
      // prevent context menu from closing if a calendar control
      // (i.e. not an actual date) is clicked
      event.stopPropagation();
    }
  }

  /*
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key=='ArrowUp') {
      this.viewMenuTrigger.openMenu();
    } else {
      this.viewMenuTrigger.closeMenu();
      switch(event.key) {
        case  'ArrowLeft': this.previous(<any>this.calendarViewService.view); break;
        case 'ArrowRight': this.next(<any>this.calendarViewService.view); break;
      }
    }
  }*/
}
