import {Action, createAction, props} from "@ngrx/store";
import {CalendarEvent} from "../../models/event";

export enum CalendarEventActionIds {
  LOAD_REQUEST       = 'calendar.event.load.request',
  LOAD_PAGE          = 'calendar.event.load.page',
  LOAD_PAGE_SUCCESS  = 'calendar.event.load.page.success',
  LOAD_PAGE_FAILURE  = 'calendar.event.load.page.failure',
  UPDATE_SET_TYPED_FILTERS = "calendar.event.set.typed.filters",
  UPDATE_SEARCH_TERM = "calendar.event.update.searchTerm",
  CREATE             = "calendar.event.create",
  CREATE_SUCCESS     = "calendar.event.create.success",
  CREATE_FAILURE     = "calendar.event.create.failure",
  UPDATE             = "calendar.event.update",
  UPDATE_SUCCESS     = "calendar.event.update.success",
  UPDATE_FAILURE     = "calendar.event.update.failure",
  DELETE             = "calendar.event.delete",
  DELETE_SUCCESS     = "calendar.event.delete.success",
  DELETE_FAILURE     = "calendar.event.delete.failure",
  SUBSCRIBE          = "calendar.event.subscribe",
  UNSUBSCRIBE        = "calendar.event.unsubscribe"
}

export enum CalendarEventDraftActionIds {
  UPDATE = "calendar.event.draft.update",
}

export const calendarEventLoadRequestAction = createAction(CalendarEventActionIds.LOAD_REQUEST, props<{ calendarId?: string }>());
export const calendarEventLoadPageAction = createAction(
  CalendarEventActionIds.LOAD_PAGE,
  props<{
    calendarId ?: string,
    cacheId     : string,
    index       : number,
    size        : number
  }>()
);
export const calendarEventLoadPageSuccessAction = createAction(
  CalendarEventActionIds.LOAD_PAGE_SUCCESS,
  props<{
    calendarId ?: string,
    cacheId     : string,
    index       : number,
    data        : CalendarEvent[],
    total       : number,
    dispatch    : (action: Action) => void
  }>()
);
export const calendarEventLoadPageFailureAction = createAction(
  CalendarEventActionIds.LOAD_PAGE_FAILURE,
  props<{
    calendarId ?: string,
    cacheId     : string,
    index       : number,
    size        : number
  }>()
);
export const calendarEventSetTypedFiltersAction = createAction(CalendarEventActionIds.UPDATE_SET_TYPED_FILTERS, props<{
  filters: {[type:string]:string[]},
  remove?:(type:string,filters:string[])=>boolean
}>());
export const calendarEventUpdateSearchTermAction = createAction(CalendarEventActionIds.UPDATE_SEARCH_TERM, props<{ term: string }>());

export class CalendarEventUpdateAction implements Action {
  readonly type = CalendarEventActionIds.UPDATE;
  constructor(public event: CalendarEvent,
              public previous?: CalendarEvent,
              public correlationId?: string) {
    if (!this.correlationId) {
      this.correlationId = this.event.id;
    }
  }
}

// export const calendarUpdateAction = createAction(CalendarEventActionIds.UPDATE, props<{ event: CalendarEvent, previous: CalendarEvent, correlationId: string  }>());
export const calendarEventUpdateSuccessAction = createAction(CalendarEventActionIds.UPDATE_SUCCESS, props<{ event: CalendarEvent, correlationId?: string }>());
export const calendarEventUpdateFailureAction = createAction(CalendarEventActionIds.UPDATE_FAILURE, props<{ event: CalendarEvent, reason: any, correlationId: string }>());

export const calendarEventDeleteAction = createAction(CalendarEventActionIds.DELETE, props<{ event: CalendarEvent }>());
export const calendarEventDeleteSuccessAction = createAction(CalendarEventActionIds.DELETE_SUCCESS, props<{ event: CalendarEvent }>());
export const calendarEventDeleteFailureAction = createAction(CalendarEventActionIds.DELETE_FAILURE, props<{ event: CalendarEvent, reason: any }>());

export const calendarEventDraftUpdateAction = createAction(CalendarEventDraftActionIds.UPDATE, props<{ event: CalendarEvent }>());

export const calendarEventSubscribeAction = createAction(CalendarEventActionIds.SUBSCRIBE, props<{ id: string, event?: CalendarEvent }>());
export const calendarEventUnsubscribeAction = createAction(CalendarEventActionIds.UNSUBSCRIBE, props<{ id: string, event?: CalendarEvent }>());
