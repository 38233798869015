import {Injectable} from "@angular/core";
import {Logger} from "core";
import {BehaviorSubject, Observable} from "rxjs";

//export const CalendarViews = ['month', 'week', 'day', 'list', 'map'] as const;
export const CalendarViews = ['list', 'map'] as const;
export type CalendarView = typeof CalendarViews[number]; //'month'|'week'|'day'|'list'|'map';

@Injectable({
  providedIn: 'root'
})
export class CalendarViewService {

  //protected currentView$ = new BehaviorSubject<CalendarView>('month');
  protected currentView$ = new BehaviorSubject<CalendarView>('list');
  protected logger = new Logger('CalendarViewService');

  constructor() {
  }

  get view$(): Observable<CalendarView> {
    return this.currentView$;
  }

  get view(): CalendarView {
    return this.currentView$.value;
  }

  set view(view:CalendarView) {
    if (!!view && this.currentView$.value!=view) {
      this.currentView$.next(view);
    }
  }
}

