import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  INJECTOR,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";

export const CONVERSATION_CALL_BAR_COMPONENT_FACTORY = new InjectionToken<ConversationCallBarComponent>('conversationCallBarComponentFactory');
export function createConversationCallBarComponentFactory(componentFactoryResolver: ComponentFactoryResolver): ComponentFactory<ConversationCallBarComponent> {
  return componentFactoryResolver.resolveComponentFactory(ConversationCallBarComponent);
}

@Component({
  selector: 'chat-conversation-bar-container',
  templateUrl: 'chat-conversation-bar-container.component.html',
  styleUrls: ['chat-conversation-bar-container.component.scss']
})
export class ChatConversationBarContainerComponent implements OnDestroy {
  protected _conversationId:string = undefined;
  @Input()
  set conversationId(conversationId:string) {
    this._conversationId = conversationId;
    if (!!this.conversationCallBarComponent) {
      this.conversationCallBarComponent.conversationId = conversationId;
    }
  }
  get conversationId():string {
    return this._conversationId;
  }
  @Input() muted = false;
  @Output() onConversationMuted = new EventEmitter<boolean>();
  @Output() onPendingConversationCalls = new BehaviorSubject<string[]>([]);
  @Output() onConversationDetails = new EventEmitter<void>();

  @ViewChild('mute', {static: true}) mute: ElementRef<HTMLElement>;
  @ViewChild('media', {static: true}) media: ElementRef<HTMLElement>;
  @ViewChild('details', {static: true}) details: ElementRef<HTMLElement>;
  @ViewChild ('conversationCallBar', { read: ViewContainerRef, static: true }) conversationCallBarRef: ViewContainerRef;

  protected conversationCallBarComponent:ConversationCallBarComponent = undefined;
  protected conversationCallBarSubscription:Subscription = undefined;

  constructor(
    @Inject(CONVERSATION_CALL_BAR_COMPONENT_FACTORY) protected conversationCallBarComponentFactory: ComponentFactory<ConversationCallBarComponent>,
    @Inject(INJECTOR) protected injector) {}

  ngOnInit() {
    const componentRef: ComponentRef<ConversationCallBarComponent> = this.conversationCallBarRef.createComponent(
      this.conversationCallBarComponentFactory, 0, this.injector, [ [this.mute.nativeElement, this.media.nativeElement, this.details.nativeElement] ]
    );
    this.conversationCallBarComponent = componentRef.instance;
    this.conversationCallBarComponent.conversationId = this.conversationId;
    this.conversationCallBarSubscription = this.conversationCallBarComponent.onPendingConversationCalls.subscribe(conversationIds => {
      this.onPendingConversationCalls.next(conversationIds);
    });
  }

  ngOnDestroy() {
    this.conversationCallBarSubscription?.unsubscribe();
  }
}

@Component({
  selector: 'chat-conversation-call-bar',
  template: ''
})
export class ConversationCallBarComponent {
  @Input() conversationId: string;
  @Input() columns: number;
  @Input() minColumns: number;
  @Input() maxColumns: number;
  @Output() onPendingConversationCalls = new Observable<string[]>();
  constructor() {}
}
