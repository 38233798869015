import {AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, input, ViewChild} from '@angular/core';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ConferenceInfo, ConferenceParticipant} from "../../store/models";
import {getCurrentThemeMode, isThemeModePure} from "core";

export type ConferenceParticipantState = 'ringing'|'muted'|'audio';

@Component({
  selector: 'conference-participant-indicator',
  template: `
    <div
      [class.ringing]="state()=='ringing'"
      [class.muted]="state()=='muted'"
      [class.audio]="state()=='audio'">
      <mat-icon class="muted" color="accent">volume_off</mat-icon>
      <mat-icon class="audio">volume_up</mat-icon>
      <mat-spinner class="ringing" #spinner></mat-spinner>
    </div>
  `,
  styleUrls: ['./conference-participant-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConferenceParticipantIndicatorComponent implements AfterViewInit {

  participant = input.required<ConferenceParticipant>();
  conferenceInfo = input.required<ConferenceInfo>();

  state = computed<ConferenceParticipantState>(()=>{
    const participant = this.participant();
    const conferenceInfo = this.conferenceInfo();
    if (!!participant?.id && !!conferenceInfo) {
      return !!conferenceInfo.ringing.find(p=>p.id==participant.id)
        ? 'ringing'
        : !!participant.audio && !participant.muted ? 'audio' : 'muted';
    }
    return undefined;
  });

  @ViewChild('spinner') spinner: MatProgressSpinner;


  constructor(
    public elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.updateSpinner();
  }

  protected updateSpinner() {
    try {
      let spinnerElement = this.spinner._elementRef.nativeElement;
      let svgElement     = <HTMLElement>spinnerElement.firstElementChild;
      let circleElement  = <HTMLElement>svgElement.firstElementChild;
      let diameter = this.elementRef.nativeElement.offsetWidth+"px";
      spinnerElement.style.width = spinnerElement.style.height = diameter;
      svgElement.style.width = svgElement.style.height = diameter;
      this.updateCircleElement(circleElement);
    } catch(error) {
    }
  }

  protected updateCircleElement(circleElement:HTMLElement) {
    circleElement.style.stroke =
      getCurrentThemeMode()=='dark' || !isThemeModePure() ? "#FFFFFFB0" : "#333";
  }
}
