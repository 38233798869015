<!--<span style="background-color: rgba(255, 0, 0, 0.75); color: white; z-index: 100">{{'SECTIONS:'+(sections$ | async)}}</span> -->
<!--<virtual-section-scroller #scroller [sectionIndexes$]="sections$"-->
<virtual-section-scroller #scroller
                          [items$]="entities$"
                          [itemsId$]="cacheId$"
                          [startFromTop]="true"
                          [itemHeaderElementAccessor]="itemHeaderElementAccessor"
                          [sectionIndexes$]="sections$"
                          [endGap]="100"
                          (onScrolled)="onScrolled($event)"
                          (onResize)="onResize($event)">
  <ng-container *virtualFor="let event of subscribed(scroller.viewportItems)
                                   offset scroller.viewportOffset
                                    total scroller.items?.length;
                                      let index  = index;
                                      let total  = total;
                                      trackBy: trackEvent;">
    <div *ngIf="safeCalendarEvent(event); let event" class="container">
      <ng-container *ngIf="scroller.viewportSectionIndexes?.includes(index)">
        <ng-container *ngTemplateOutlet="sectionHeaderTemplate; context: { item:event, index: index }"></ng-container>
      </ng-container>
      <div class="event" *ngLet="subscribedCalendars()?.[event.id] as calendar">
        <app-event-list-item [event]="event"
                             [index]="index"
                             [dense]="dense"
                             [color]="calendar?.color"
                             (click)="select(index, event, $event)">
        </app-event-list-item>
        <div class="controls">
          <ng-container *ngTemplateOutlet="controlsTemplate; context: { index: index, event: event }"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #sectionHeaderTemplate let-event="item" let-index="index">
    <div *ngIf="event.timeFrom; let timeFrom" class="section-header">
      <span>
<!--    {timeFrom | date: 'MM-YYYY'}} -->
        {{ monthYear(timeFrom) }}
      </span>
    </div>
  </ng-template>
</virtual-section-scroller>

<!--<div *ngFor="let event of entities$ | async; let index = index; let last = last" (click)="select(index, event, $event)">-->
<!--  {{event.name}}-->
<!--</div>-->
