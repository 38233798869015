import {createAction, props} from "@ngrx/store";
import {EntityState, VersionedId} from "core";
import {ListViewState, PrefetchInfo, Segment} from "synchronization/lib/store/state";
import {ListViewFilter, LoadMoreState} from "store";
import {ListViewChangeEvent} from "synchronization";

export const synchronizationInitializationAction = createAction(
  'synchronization.initialization',
  props<{
    resynchronize?:boolean;  // just for test purpose, resynchronizes all listviews
  }>());

export const synchronizationInitializationResultAction = createAction(
  'synchronization.initialization.result',
  props<{
    listViews?: {
      viewId: string,
      entityType: string,
      filter?: ListViewFilter,
      state: ListViewState
    }[];
    subscriptions?: {
      entityType: string,
      subscribed: VersionedId[]
    }[];
    resynchronize?:boolean;  // just for test purpose, resynchronizes all listviews
  }>());


export const synchronizationShutdownAction = createAction(
  'synchronization.shutdown');

export const synchronizationAuthenticatedAction = createAction(
  'synchronization.authenticated',
  props<{
    authenticated: boolean
  }>());

export const synchronizationConnectedAction = createAction(
  'synchronization.connected',
  props<{
    connected: boolean
  }>());

export const entityFactoryRegistrationAction = createAction(
  'entity.factory.registration',
  props<{
    entityType: string;
    entityFactory:(VersionedId)=>VersionedId;
  }>()
);

export const entitySubscribeAction = createAction(
  'entity.subscribe',
  props<{
    entityType: string;
    entityId: string;
    entity?: VersionedId; // provided by reducer
  }>()
);

export const entityUnsubscribeAction = createAction(
  'entity.unsubscribe',
  props<{
    entityType: string;
    entityId: string;
    entity?: VersionedId; // provided by reducer
  }>()
);

export const entitySubscriptionsUpdatedAction = createAction(
  'entity.subscriptions.updated');

export const entityUpdateAction = createAction(
  'entity.update',
  props<{
    entityType: string;
    entity?: VersionedId; // provided by reducer
  }>()
);

export const entityDraftAction = createAction(
  'entity.draft',
  props<{
    entityType: string;
    entity?: VersionedId; // provided by reducer
    channelId: string;  // unique channel id
    send?: boolean; // if true, the draft is sent to the server
  }>()
);

/**
 * creates the listview, but does not load it from server!
 */
export const listViewCreateAction = createAction(
  'listView.create',
  props<{
    viewId: string;
    entityType: string;
    filters: {[type:string]:string[]};
    searchTerm: string;
    preprocessEntities?:(entities:{entity?:VersionedId}[])=>{entity?:VersionedId}[];
    // if not defined, all filters and the searchTerm are sent to server
    serverFilterPrepare?:(filters:{[type:string]:string[]})=>{[type:string]:string[]};
    serverSearchPrepare?:(term:string)=>string;
    // client side filter and search is only done, if these predicates are defined!
    clientFilterPredicate?:(entity:VersionedId,filter:{[type:string]:string[]})=>boolean;
    clientSearchPredicate?:(entity:VersionedId,term:string)=>boolean;
    // if undefined, the first fetch is DEFAULT_SEGMENT_SIZE (25) at the end of the list
    // which is the newest/most active part of the list....
    // everything else just gets fetched on access....
    requestBootstrapSegment?:()=>Segment,
    requestPrefetchInfo?:(state:ListViewState)=>PrefetchInfo;
  }>());

/**
 * triggers immediate load of the listview from server if the listview is not already loaded
 */
export const listViewTriggerLoadAction = createAction(
  'listView.trigger.load',
  props<{
    viewId: string;
    entityType: string;
    delay?: number;  // milliseconds to wait before loading, undefined or 0 means immediate
  }>());

/**
 * triggers reload of the listview from server if server searchTerm changed.
 * see: listViewCreateAction.serverFilterPrepare
 */
export const listViewSetTypedFiltersAction = createAction(
  'listView.set.filters',
  props<{
    viewId: string;
    entityType: string;
    filters: {[type:string]:string[]},
    remove?:(type:string,filters:string[])=>boolean,
    delay?: number;  // milliseconds to wait before loading, undefined or 0 means immediate
  }>());

/**
 * triggers reload of the listview from server clientSearchPredicate is not defined
 * and searchTerm changed.
 * see: listViewCreateAction.clientSearchPredicate
 */
export const listViewUpdateSearchTermAction = createAction(
  'listView.update.term',
  props<{
    viewId: string;
    entityType: string;
    searchTerm: string
    delay?: number;  // milliseconds to wait before loading, undefined or 0 means immediate
  }>());

export const listViewLoadMoreAction = createAction(
  'listView.load.more',
  props<{
    viewId: string;
    entityType: string;
    moreActiveLoad?: number;
    morePassiveLoad?: number;
  }>());

export const listViewUpdateAction = createAction(
  'listView.update',
  props<{
    viewId: string;
    viewState: ListViewState;
    entityType: string;
    entityState: EntityState<VersionedId>;
    moreActive: LoadMoreState;
    morePassive: LoadMoreState;
    filters: {[key:string]:string[]};
    searchTerm: string;
    synchronized: boolean;
    selectedIndex: number;
    reload?: boolean;
    error?: any;
    internal?: any;
  }>());

export const listViewDeleteAction = createAction(
  'listView.delete',
  props<{
    viewId: string;
    entityType: string;
  }>());

export const listViewEventAction = createAction(
  'listView.event',
  props<{
    viewId: string;			  // unique id for the subscription
    event: ListViewChangeEvent;	// the event (insert, update, delete)
  }>());

