import {Component, Inject, InjectionToken, OnInit,} from '@angular/core';
import {OverlayRef} from "@angular/cdk/overlay";
import {Logger} from "core";

export const STRIPE_OVERLAY_DATA = new InjectionToken<any>('STRIPE_OVERLAY_DATA');

export class StripeOverlayRef {
  constructor(private overlayRef: OverlayRef) { }
  close(): void {
    this.overlayRef.dispose();
  }
}

@Component({
  selector: 'app-stripe-overlay',
  template: `
    <app-stripe [product]="data?.product"
                [contact]="data?.contact"
                (paymentCompleted)="onPaymentCompleted($event)">
      <div class="result">
        <div>
          <mat-icon>check_circle</mat-icon>
          <span translate>purchase.success</span>
        </div>
        <button mat-raised-button color="primary" (click)="overlayRef.close()">
          <span translate>actions.continue</span>
        </button>
      </div>
      <ng-template #controlsTemplate>
          <button mat-icon-button (click)="onClose()">
              <mat-icon>cancel</mat-icon>
          </button>
      </ng-template>
    </app-stripe>
  `,
  styleUrls: ['./stripe-overlay.component.scss']
})
export class StripeOverlayComponent implements OnInit {

  logger = new Logger('StripeOverlayComponent');

  constructor(public overlayRef: StripeOverlayRef,
              @Inject(STRIPE_OVERLAY_DATA) public data: any) {}

  ngOnInit() {
  }

  onPaymentCompleted(result: any = true) {
    this.logger.debug('Stripe payment completed', result);
    if (this.data.onPaymentCompleted) {
      this.data.onPaymentCompleted(result);
    }
  }

  onClose() {
    this.onPaymentCompleted(false);
    this.overlayRef.close();
  }
}
