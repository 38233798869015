import {AfterViewInit, Component, EventEmitter, Inject, InjectionToken} from '@angular/core';
import {OverlayRef} from '@angular/cdk/overlay';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";

export const APP_INSTALL_OVERLAY_DATA = new InjectionToken<any>('APP_INSTALL_OVERLAY_DATA');

export class AppInstallOverlayRef {
  private _closed = false;
  private _onClose = new ReplaySubject<void>();
  public onClose: Observable<void> = this._onClose.asObservable();
  constructor(private overlayRef: OverlayRef) {}
  close(): void {
    if (!this._closed) { // workaround: AppInstallOverlayComponent close animation done callback invoked twice!
      this.overlayRef.dispose();
      this._onClose.next();
      this._onClose.complete();
      this._closed = true;
    }
  }
}

const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

/*(onLink)="close()"*/

@Component({
  selector: 'app-install-overlay',
  template: `
    <app-install
      [@animation]="animationState"
      (@animation.start)="onAnimationStart($event)"
      (@animation.done)="onAnimationDone($event)"
      [code]="data?.code"
      [link]="data?.link"
      [user]="data.user"
      [fullscreen]="true"
      (onClose)="close()"
      style="opacity: 0">
    </app-install>`,
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `],
  animations: [
    trigger('animation', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn',  style({ opacity: 1 })),
      // transition('* => fadeOut', animate(ANIMATION_TIMINGS))
      transition('* => *', animate(ANIMATION_TIMINGS))
    ])
  ]
})
export class AppInstallOverlayComponent implements AfterViewInit {

  animationState: 'fadeIn' | 'fadeOut' = 'fadeOut';
  constructor(public overlayRef: AppInstallOverlayRef,
              @Inject(APP_INSTALL_OVERLAY_DATA) public data: any) {
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.animationState = 'fadeIn';
    }, 0);
  }

  close() {
    this.animationState = 'fadeOut';
  }

  onAnimationStart(event: AnimationEvent) {
  }

  onAnimationDone(event: AnimationEvent) {
    if (this.animationState == 'fadeOut') {
      this.overlayRef.close();
    }
  }
}
