import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {APP_ID, Contact, ENVIRONMENT, GroupMembership, hasAddress, Logger, PhoneService, Platform} from 'core';
import {PropertiesService} from "properties";
import {map, take} from "rxjs/operators";
import {enterLeaveAnimations, MenuService, MessageBoxComponent} from "shared";
import {EmailComposer, EmailComposerOptions} from "@ionic-native/email-composer/ngx";
import {CallNumber} from "@ionic-native/call-number/ngx";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {Observable, takeUntil} from "rxjs";
import {ContactService} from "../../../contact.service";
import {PaymentService} from "payment";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import {ContactDetailDataShareService} from "../contact-detail-data-share.service";
import {ContactDetailSectionComponent} from "../contact-detail-section/contact-detail-section.component";

@Component({
  selector: 'contact-detail-info',
  templateUrl: './contact-detail-info.component.html',
  styleUrls: ['./contact-detail-info.component.scss'],
  animations: [enterLeaveAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactDetailInfoComponent extends ContactDetailSectionComponent {

  @Input()
  set contact(contact: Contact) {
    this.contact$.set(contact);
  }
  contact$ = signal<Contact>(undefined);

  @Output() onShowNotes = new EventEmitter();

  @ViewChild('header')  header:  ElementRef;
  @ViewChild('appLink') appLink: ElementRef;

  static _largeAvatar = false;
  static _largeAvatarStyle:{width:string,height:string} = {
    width: '200px',
    height: '200px'
  };
  showMap = false;
  largeAvatar = ContactDetailInfoComponent._largeAvatar;
  largeAvatarStyle:{width:string,height:string,clientWith?:number} = { ...ContactDetailInfoComponent._largeAvatarStyle };
  pro: boolean = false;
  location: { lat?: number, lng?: number } = {};

  logger = new Logger('ContactDetailInfoComponent');

  constructor(public platform: Platform,
              protected paymentService: PaymentService,
              protected phoneService: PhoneService,
              protected emailComposer: EmailComposer,
              protected callNumber: CallNumber,
              protected dialog: MatDialog,
              public    contactService: ContactService,
              protected translateService: TranslateService,
              protected menuService: MenuService,
              public propertiesService: PropertiesService,
              private dataShareService: ContactDetailDataShareService,
              @Inject(APP_ID) public appId: number,
              @Inject(ENVIRONMENT) protected environment: any) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.dataShareService.getContact$.pipe(takeUntil(this.onDestroy$)).subscribe(contact => this.contact$.set(contact));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewChecked() {
    this.setLargeAvatar(this.largeAvatar);
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentContact = changes.contact.currentValue;
    const previousContact = changes.contact.previousValue;
    if (!isEqual(currentContact, previousContact)) {
      this.location.lat = currentContact.latitude;
      this.location.lng = currentContact.longitude;
    }
  }

  get groupId$(): Observable<string> {
    return this.propertiesService.properties$.pipe(
      map(properties => properties.group.id)
    );
  }

  get membership(): Observable<GroupMembership> {
    return this.groupId$.pipe(map((groupId: string) => {
      if (this.contact.groups) {
        return this.contact.groups[groupId];
      }
    }))
  }

  toggleAvatar() {
    this.setLargeAvatar(!this.largeAvatar);
  }

  setLargeAvatar(large: boolean) {
    if (!!this.header && !!this.header.nativeElement &&
      this.largeAvatarStyle.clientWith!=this.header.nativeElement.clientWidth) {
      this.largeAvatarStyle.clientWith = this.header.nativeElement.clientWidth;
      let cs      = window.getComputedStyle(this.header.nativeElement);
      let padding = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
      var border  = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
      let width   = this.header.nativeElement.clientWidth - padding - border;
      ContactDetailInfoComponent._largeAvatarStyle.width = this.largeAvatarStyle.width = `${width}px`;
      ContactDetailInfoComponent._largeAvatarStyle.height = this.largeAvatarStyle.height = `${width}px`;
    }
    if (this.largeAvatar!=large) {
      ContactDetailInfoComponent._largeAvatar = this.largeAvatar = large;
    }
  }

  onTapEmail() {
    let email = this.contact && this.contact.email;
    if (email) {
      let message = {
        to: this.contact.email,
        subject: '',
        body: ''
      };
      this.sendEmailMessage(message);
    }
  }

  onTapPhone() {
    let phone = this.contact && this.contact.phone;
    if (phone) {
      if (this.platform.is('hybrid')) {
        // the promise returned by isCallSupported is rejected even if the device can make calls
        // which was proven by directly calling callNumber method
        // this behaviour is observed on android mi2 phone device

        // this.callNumber.isCallSupported()
        //   .then((supported) => {
        //     if (!!supported) {
        this.callNumber.callNumber(phone, true)
          //     } else {
          //       throw new Error();
          //     }
          //   })
          .catch((error) => {
            this.logger.error('Phone calls are not supported');
            this.translateService.get('errors.phoneCallsNotSupported')
              .pipe(take(1))
              .subscribe((message) => {
                this.dialog.open(MessageBoxComponent, { data: { message: message } });
              });
          })
      } else if (this.platform.is('mobile')) {
        const callingCode = get(this.propertiesService.properties, 'session.callingCode');
        phone = '+'+this.phoneService.convertToWhatsappNumber(phone, callingCode);
        let href = `tel:${phone}`;
        this.appLink.nativeElement.href = href;
        this.appLink.nativeElement.click();
      }
    }
  }

  onTapWhatsApp(event: Event) {
    event.preventDefault();
    let phone = this.contact && this.contact.whatsapp;
    if (phone) {
      const callingCode = get(this.propertiesService.properties, 'session.callingCode');
      phone = this.phoneService.convertToWhatsappNumber(phone, callingCode);
      let text = `Hi ${this.contact.firstName || this.contact.name || ''}`;
      let href = `https://wa.me/${phone}?text=${encodeURIComponent(text)}`;
      this.appLink.nativeElement.href = href;
      this.appLink.nativeElement.click();
      /*
      phone = phone.replace(/\s/g,'');
      if (phone.charAt(0) == '0') {
        phone = '+' + phone.substr(1, phone.length);
      }
      // TODO: find out how to open whatsapp without specifying a message to be sent
      // let href = `https://api.whatsapp.com/send?phone=${phone}&text=Hi ${this.contact.first_name || this.contact.name || ''}`;
      let href = `https://wa.me/${encodeURIComponent(phone)}`;
      this.whatsAppLink.nativeElement.href = href;
      this.whatsAppLink.nativeElement.click();
      */
    }
  }

  onTapMessenger(event: Event) {
    event.preventDefault();
    let id = this.contact && this.contact.messenger;
    if (id) {
      // https://www.sendmate.io/blog/find-my-messenger-code/
      // https://answers.unity.com/questions/1255913/openning-deep-link-to-a-facebook-messenger-on-andr.html
      let href = `https://m.me/${id}`;
      this.appLink.nativeElement.href = href;
      this.appLink.nativeElement.click();
    }
  }

  onTapWechat(event: Event) {
    event.preventDefault();
    let id = this.contact && this.contact.wechat;
    if (id) {
      // https://stackoverflow.com/questions/35425553/how-do-i-link-to-wechat-from-a-webpage
      let href = `weixin://dl/chat?${id}`;
      this.appLink.nativeElement.href = href;
      this.appLink.nativeElement.click();
    }
  }

  onTapTelegram(event: Event) {
    event.preventDefault();
    let id = this.contact && this.contact.telegram;
    if (id) {
      // https://pureoxygenlabs.com/deep-linking-to-telegram-usernames-and-channels/
      let href = this.platform.is('mobile')
        ? `tg://resolve?domain=${id}`
        : `https://t.me/${id}`;
      this.appLink.nativeElement.href = href;
      this.appLink.nativeElement.click();
    }
  }

  sendEmailMessage(message: any, fallback: boolean = false) {
    this.platform.ready()
      .then((readySource) => {
        if (!fallback && this.platform.is('hybrid')) {
          let options: EmailComposerOptions  = {...message, isHtml: true};
          return this.emailComposer.open(options)
            .then((result) => {
              console.debug('Email send result', result);
            })
            .catch((error) => {
              console.error('Failed to send email using native composer', error);
              this.sendEmailMessage(message, true);
            });
        } else {
          let anchor = document.createElement('a');
          let subject = encodeURIComponent(message.subject);
          let body = encodeURIComponent(message.body);
          anchor.href = `mailto:${message.to}?subject=${subject}&body=${body}`;
          anchor.target = '_top';
          document.body.appendChild(anchor);
          window.setTimeout(() => anchor.remove());
          anchor.click();
        }
      });
  }

  get photoUrl(): string {
    if (this.contact) {
      return `${this.environment.serverUrl}/v1.0/content/avatar/${this.contact.version || 0}/${this.contact.id}.jpg`;
    }
    return '';
  }

  get hasAddress(): boolean {
    return hasAddress(this.contact);
  }

  /*
  getBirthday(contact: Contact): string {
    if (contact.birthday || contact.birthdaywoy) {
      let value = contact.birthday || contact.birthdaywoy;
      try {
        if (value>0) {
          //var day   = value%100;
          //var month = ~~(value/100) % 100;
          var year = ~~(value/10000);
          let birthdayMoment = moment(value.toString());
          let birthdayString = this.dateAdapter.format(birthdayMoment,"LL");
          if (year<1900) {
            let yearString = this.dateAdapter.getYearName(birthdayMoment);
            birthdayString = birthdayString.replace(yearString,'');
            var regex = new RegExp("^[,. ]+|[,. ]+$", "g");
            return birthdayString.replace(regex,'');
          }
          return birthdayString;
        }
      } catch(e) {}
    }
    return '';
  }
  */

  showNotes(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.context?.showNotes?.();
  }
}
