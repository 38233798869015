<ng-container *ngLet="{
    createMode : createMode$ | async,
    createMenu : createMenu$ | async
  } as context">
  <div [class.createMode]="!!context.createMode" [class.createMenu]="!!context.createMenu">
    <div class="conversations">
      <app-search-bar color="primary"
                      [(ngModel)]="searchTerm"
                      [leftIcon]="'arrow_back'"
                      [rightIcon]="context.createMenu ? 'expand_less' : 'add'"
                      [searchButton]="true"
                      (onSearchTermChange)="updateSearchTerm($event)"
                      (onLeftClicked)="back()"
                      (onRightClicked)="newUserOrGroup()"
                      (onAttach)="ngOnAttach()"
                      (onDetach)="ngOnDetach()">
        <span class="title" translate>module.chat</span>
      </app-search-bar>
      <div #create_menu_container class="create_menu_container" (transitionend)="triggerMenuAnimationDone($event)">
        <mat-nav-list #create_menu>
          <mat-list-item (click)="createDirectConversation()">
            <mat-icon>add</mat-icon>
            <span translate>chat.create.direct</span>
          </mat-list-item>
          <mat-list-item (click)="createGroupConversation()">
            <mat-icon>add</mat-icon>
            <span translate>chat.create.group</span>
          </mat-list-item>
        </mat-nav-list>
      </div>
      <div class="content" (onResize)="onResize($event)">
        <chat-conversation-list [conversations]="conversations()"
                                (onSelectConversationId)="conversationIdSelect($event)"
                                #list></chat-conversation-list>
      </div>
      <app-status-bar><presence-menu></presence-menu><span>{{conversations().length | number}}</span></app-status-bar>
    </div>
    <div class="create" (transitionend)="triggerCreateContainerAnimationDone($event)">
      <chat-conversation-create-container #create
           [disabled]="!context.createMode"
           [type]="context.createMode"
           (closed)="closeCreateContainer()">
      </chat-conversation-create-container>
    </div>
  </div>
</ng-container>
