import {Action, ActionsSubject, Store} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {
  CorrelationIdGenerator,
  EntityService,
  LazyLoadingArray,
  Logger,
  SearchService,
  SimpleFilterService,
  Topic,
  VersionedId
} from "core";
import {MessagingService} from "messaging";
import {TranslateService} from "@ngx-translate/core";
import {TaskList,} from "../models/task-list";
import {map, tap} from "rxjs/operators";
import {PropertiesService} from "properties";
import {
  taskListDeleteAction,
  taskListDeleteFailureAction,
  taskListDeleteSuccessAction,
  TaskListUpdateAction,
  taskListUpdateFailureAction,
  taskListUpdateSuccessAction
} from "../store/task-list/actions";
import {FilteredListView, StoreService} from "store";
import {AuthenticationService} from "auth";
import {Task} from "tasks/lib/models/task";
import {SynchronizationService} from "synchronization";
import {MAX_JAVA_INTEGER} from "shared";
import {DEFAULT_SEGMENT_SIZE, ListViewState} from "synchronization/lib/store/state";

@Injectable({
  providedIn: 'root'
})
export class TaskListService extends StoreService implements SearchService, SimpleFilterService , EntityService<TaskList> {
  protected _entities$  : Observable<TaskList[]>;
  protected _size$ = new ReplaySubject<number>(1);
  protected _sections$= new BehaviorSubject<number[]>([]);

  protected taskListView:FilteredListView<TaskList>;

  protected logger = new Logger('TaskListService');

  constructor(protected store$: Store<any>,
              protected action$: ActionsSubject,
              protected authenticationService: AuthenticationService,
              protected messagingService: MessagingService,
              protected translateService : TranslateService,
              protected propertiesService: PropertiesService,
              protected synchronizationService: SynchronizationService,
              protected correlationIdGenerator: CorrelationIdGenerator) {
    super(store$, action$);
    console.log('TaskListService.ctor');
    this.taskListView = this.synchronizationService.createFilteredListView<TaskList>(
      'TaskList',
      (filters:{[type:string]:string[]},searchTerm:string)=>true,
      (entities:{entity:Task}[])=>entities,
      (filters:{[type:string]:string[]})=>{ return {}},
      (term:string)=>'',
      (entity:VersionedId,filters:{[type:string]:string[]})=>{
        return true;
      },
      (entity:VersionedId,term:string)=>true,
      ()=>{ return { index: MAX_JAVA_INTEGER, size: DEFAULT_SEGMENT_SIZE }},
      (state:ListViewState)=>{
        return {
          moreActiveSize: 0,
          morePassiveSize: state.passiveSize,
          prefetch: 'all'
        };
      });
    this.translateService.onLangChange.subscribe(() => {
      //this.loadRequest();
    });
  }

  get cacheId$(): Observable<string> {
    return this.taskListView.id$;
  }

  get entities$(): Observable<TaskList[]> {
    if (!this._entities$) {
      this._entities$ = this.taskListView.entities$.pipe(
        map(entities=><LazyLoadingArray<TaskList|undefined>>entities),
        tap(entities=> this._size$.next(entities.length))
      );
    }
    return this._entities$;
  }

  get sections$(): Observable<number[]> {
    return this._sections$;
  }

  get size$(): Observable<number> {
    return this._size$;
  }

  get filters$(): Observable<string[]>  {
    return this.taskListView.filters$;
  }

  get searchTerm$(): Observable<string> {
    return this.taskListView.searchTerm$;
  }

  updateFilter(filters: string[]): void {
    this.taskListView.setTypedFilters({'filter':filters});
  }

  updateSearchTerm(searchTerm: string): void {
    this.taskListView.setSearchTerm(searchTerm);
  }

  getTaskList$(id: string, defaultTaskList?: TaskList): Observable<TaskList> {
    return this.synchronizationService.getEntity$(id,'TaskList',defaultTaskList)
      .pipe(map(entity=><TaskList>entity));
  }

  getDefaultTaskList$(): Observable<TaskList> {
    return this.getTaskList$('0',undefined);
  }

  typesTopic$(): Observable<Topic> {
    return this.propertiesService.groupId$
      .pipe(
        map(groupId => this.propertiesService.group?.taskTypesTopic)
      );
  }

  canCreateTaskList$(): Observable<boolean> {
    return this.typesTopic$().pipe(map(topic => !!topic?.topics?.length));
  }

  save(taskList: TaskList, previous?: TaskList): Promise<TaskList> {
    if (taskList) {
      const correlationId = this.correlationIdGenerator.next();
      const promise = new Observable<any>(subscriber => {
        const reducer = `saveTaskList_${correlationId}`;
        // to improve type checking consider implementing TaskListUpdateAction as class
        // then it will be possible to probe for particular Action type with instanceof operator
        // and benefit from user-defined type guards support of typescript and the related type narrowing.
        this.store$.addReducer(reducer, (state, action: { taskList: TaskList, error?: any, correlationId?: string } & Action) => {
          if (action.type == taskListUpdateSuccessAction.type  ||
            action.type == taskListUpdateFailureAction.type) {
            console.debug(reducer, state, action);
            if (action.correlationId == correlationId) {
              if (action.type == taskListUpdateSuccessAction.type) {
                // this.loadRequest();
                subscriber.next(action.taskList);
              } else {
                subscriber.error(action.error);
              }
              subscriber.complete();
              this.store$.removeReducer(reducer);
            }
          }
          return state;
        });
      }).toPromise();
      this.store$.dispatch(new TaskListUpdateAction(taskList, previous, correlationId));
      return promise;
    } else {
      return Promise.reject('Invalid taskList');
    }
  }

  delete(taskList: TaskList): Promise<any> {
    let resolve: any, reject: any;
    const promise = new Promise((promiseResolve, promiseReject) => {
      resolve=promiseResolve; reject=promiseReject;
    });
    const reducer = `deleteTaskList_${taskList.id}`;
    this.store$.addReducer(reducer, (state, action: { taskList: TaskList, error?: any } & Action) => {
      if (action.type == taskListDeleteSuccessAction.type || action.type == taskListDeleteFailureAction.type) {
        console.debug(reducer, state, action);
        if (action.taskList?.id==taskList.id) {
          if (action.type == taskListDeleteSuccessAction.type) {
//            this.loadRequest();
            resolve();
          } else {
            reject();
          }
          this.store$.removeReducer(reducer);
        }
      }
      return state;
    });
    this.store$.dispatch(taskListDeleteAction({ taskList }));
    return promise;
  }
}

