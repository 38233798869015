/* projects/interests/src/lib/components/survey/result/vitality/vitality.images.component.scss */
:host > div.chart {
  display: flex;
  flex-direction: row;
}
:host > div.chart.iconOnly {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  width: 44px;
  height: 44px;
}
:host > div.chart.iconOnly > div.info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
:host > div.chart.iconOnly > div.info > media-card {
  display: inline-block;
  box-sizing: border-box;
  width: 44px;
  height: 44px;
}
:host > div.chart.iconOnly.two,
:host > div.chart.iconOnly.three {
  background-color: rgba(192, 192, 192, 0.35);
}
:host > div.chart.iconOnly.two > div.info > media-card,
:host > div.chart.iconOnly.three > div.info > media-card {
  transform: scale(1.3);
}
:host > div.chart.iconOnly.two.two > div.info:nth-of-type(1),
:host > div.chart.iconOnly.three.two > div.info:nth-of-type(1) {
  clip-path: inset(0 calc(50% + 1px) 0 0);
}
:host > div.chart.iconOnly.two.two > div.info:nth-of-type(2),
:host > div.chart.iconOnly.three.two > div.info:nth-of-type(2) {
  clip-path: inset(0 0 0 calc(50% + 1px));
}
:host > div.chart.iconOnly.two.three > div.info:nth-of-type(1),
:host > div.chart.iconOnly.three.three > div.info:nth-of-type(1) {
  clip-path: inset(0 calc(66% + 1px) 0 0);
}
:host > div.chart.iconOnly.two.three > div.info:nth-of-type(2),
:host > div.chart.iconOnly.three.three > div.info:nth-of-type(2) {
  clip-path: inset(0 calc(33% + 1px) 0 calc(33% + 1px));
}
:host > div.chart.iconOnly.two.three > div.info:nth-of-type(3),
:host > div.chart.iconOnly.three.three > div.info:nth-of-type(3) {
  clip-path: inset(0 0 0 calc(66% + 1px));
}
:host > div.chart:not(.iconOnly) > div.info {
  position: relative;
  display: inline-block;
  margin: 4px 4px 0 0;
  border-radius: 8px;
  padding: 2px;
  overflow: hidden;
  white-space: nowrap;
  border: 2px solid transparent;
  font-size: 13.5px;
  text-align: center;
  width: 86px;
}
:host > div.chart:not(.iconOnly) > div.info > media-card {
  display: inline-block;
  width: 50px;
}
:host > div.chart:not(.iconOnly) > div.info > div:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}
:host > div.chart:not(.iconOnly).autoWidth > div.info {
  width: unset;
  min-width: 86px;
}
:host-context(.color-scheme-dark) > div.chart.iconOnly.two,
:host-context(.color-scheme-dark) > div.chart.iconOnly.three {
  background-color: rgba(192, 192, 192, 0.1);
}
/*# sourceMappingURL=vitality.images.component-BZ32S7CL.css.map */
