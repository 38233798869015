/* projects/contact/src/lib/components/contact-local-list/contact-local-list.component.scss */
:host {
  display: block;
  height: 100%;
  overflow: auto;
}
.contact {
  height: auto;
  min-height: 48px;
}
.contact .picture {
  background-color: aquamarine;
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: #fff;
}
.contact .details {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 48px;
  padding: 0 6px;
  max-width: 90%;
}
.contact .details .name,
.contact .details .links {
  margin: 0 0 3px;
  line-height: normal;
}
.contact .details .links {
  display: inline;
  font-size: 0.8rem;
}
.contact .details .links > div {
  overflow: hidden;
  text-overflow: ellipsis;
}
/*# sourceMappingURL=contact-local-list.component-TLM4SSHE.css.map */
