/* projects/chat/src/lib/components/attachment/media/composer/media-attachment-composer.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  overflow-x: auto;
  overflow-y: unset;
  scrollbar-width: none;
  min-height: 80px;
  max-height: 80px;
  z-index: 2;
  position: relative;
}
:host::-webkit-scrollbar {
  display: none;
}
:host > div {
  position: relative;
  flex: 1;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 80px;
  max-height: 80px;
}
:host > div > * {
  position: relative;
  flex-shrink: 0;
  margin-right: 8px;
}
:host > div > *:first-child {
  margin-left: 8px;
}
:host > div > *:last-child {
  margin-right: 16px;
}
media-card {
  position: relative;
  font-size: 10px;
}
media-card div.drag {
  cursor: ew-resize;
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  background-color: gray;
  border-radius: 4px;
}
media-card div.drag mat-icon {
  position: absolute;
  left: 1px;
  top: 1px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  color: white;
}
media-card button.delete {
  position: absolute;
  background-color: #757575;
  width: 20px;
  height: 20px;
  line-height: 20px;
  top: -5px;
  right: -5px;
  padding: 0;
}
media-card button.delete mat-icon {
  position: absolute;
  left: 1px;
  top: 1px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  color: white;
}
media-card button.delete.confirm {
  top: -7px;
  right: -7px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: #f44336 !important;
}
media-card button.delete.confirm mat-icon {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  color: white;
}
media-card.editable {
  cursor: move;
}
.control {
  position: absolute;
}
.control.bottom {
  bottom: 2px;
}
.control.left {
  left: 2px;
}
.control.top {
  top: 2px;
}
.control.right {
  right: 2px;
}
.badge {
  padding: 0.2em 0.4em;
  font-size: 0.75em;
  line-height: 1em;
  border-radius: 2px;
  background-color: var(--color-primary);
  color: white;
}
:host-context(.color-scheme-dark) media-card button.delete {
  background-color: #757575;
}
:host-context(.color-scheme-dark) media-card button.delete mat-icon {
  color: white;
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drop-list-dragging media-card:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
/*# sourceMappingURL=media-attachment-composer.component-TL4XL4XX.css.map */
