import {BehaviorSubject, concat, concatMap, from, fromEvent, Observable, of,} from "rxjs";
import {EMPTY_ARRAY, Logger} from "core";
import {ConferenceInfo} from "./store/models";
import {ConferenceConnection} from "./conference.service";
import {map, shareReplay, switchMap, tap} from "rxjs/operators";
import {addTrack, removeTrack} from "conference/lib/util";

export class MediaStreamWrapper {
  protected _mediaStream$: BehaviorSubject<MediaStream>;
  protected _audioEnabled = true;
  protected _videoEnabled = true;

  protected logger = new Logger(this.constructor.name).setSilent(this.log);

  constructor(
    public log: boolean,
    public requestedAudio: boolean,
    public requestedVideo: boolean,
    previousMediaStream$?: BehaviorSubject<MediaStream>
  ) {
    this._mediaStream$ =
      previousMediaStream$ ||
      new BehaviorSubject<MediaStream>(new MediaStream());
  }
  public pushMediaStream() {
    this.mediaStream = this.mediaStream;
  }
  protected get mediaStream(): MediaStream {
    return this._mediaStream$.value;
  }
  protected set mediaStream(mediaStream: MediaStream) {
    this._mediaStream$.next(mediaStream);
    //console.trace("#C.mediaStream.set",mediaStream?.id,"tracks",mediaStream?.getTracks().length);
    if (!!mediaStream) {
      //this.logger.debug("set.mediaStream.audio",this.audioEnabled,"video",this.videoEnabled);
      this.audioEnabled = this.audioEnabled;
      this.videoEnabled = this.videoEnabled;
    } else {
      this.logger.debug("MEDIA STREAM EMPTY!!!");
    }
  }
  public get mediaStream$(): Observable<MediaStream> {
    return this._mediaStream$;
  }
  public get hasAudio(): boolean {
    return this.mediaStream?.getAudioTracks()?.length > 0 || false;
  }
  public get hasVideo(): boolean {
    return this.mediaStream?.getVideoTracks()?.length > 0 || false;
  }
  public get videoTrackIds(): string[] {
    return this.mediaStream?.getVideoTracks()?.map(track=>track.id) ?? EMPTY_ARRAY;
  }
  public get audioEnabled(): boolean {
    return this._audioEnabled;
  }
  public set audioEnabled(enabled: boolean) {
    this._audioEnabled = !!enabled;
    this.mediaStream?.getAudioTracks().forEach((track) => {
      if (track.enabled != this._audioEnabled) {
        this.logger.debug("set.audioEnabled", this._audioEnabled);
      }
      try {
        track.enabled = this._audioEnabled;
      } catch (error) {
        this.logger.error("CANNOT SET ENABLED TO", this._videoEnabled, error);
      }
    });
  }
  public get videoEnabled(): boolean {
    return this._videoEnabled;
  }
  public set videoEnabled(enabled: boolean) {
    this._videoEnabled = !!enabled;
    if (!this._videoEnabled) {
      this.stopVideo();
    }
    /*
    this.mediaStream?.getVideoTracks().forEach(track=>{
      if (track.enabled!=this._videoEnabled) {
        this.logger.info("set.videoEnabled",this._videoEnabled);
      }
      try {
        track.enabled=this._videoEnabled;
      } catch(error) {
         this.logger.error("CANNOT SET ENABLED TO",this._videoEnabled,error);
      }
    });
    */
  }
  public stopAudio() {
    // this.logger.debug("MEDIA.stop.audio");
    this.mediaStream?.getAudioTracks().forEach((track) => {
      track.stop();
      removeTrack(this.mediaStream,track);
    });
    this.requestedAudio = false;
  }
  public stopVideo() {
    //this.logger.debug("MEDIA.stop.video");
    this.mediaStream?.getVideoTracks().forEach((track) => {
      track.stop();
      removeTrack(this.mediaStream,track);
    });
    this.requestedVideo = false;
  }
  public stop() {
    this.mediaStream?.getTracks().forEach((track) => {
      track.stop();
      removeTrack(this.mediaStream,track);
    });
  }
  get hasMediaStream(): boolean {
    return !!this.mediaStream;
  }

  hasAudioDeviceId(deviceId: string): boolean {
    return (
      this.mediaStream
        .getAudioTracks()
        .findIndex((track) => track.getSettings().deviceId == deviceId) >= 0
    );
  }

  hasVideoDeviceId(deviceId: string): boolean {
    return (
      this.mediaStream
        .getVideoTracks()
        .findIndex((track) => track.getSettings().deviceId == deviceId) >= 0
    );
  }

  hasVideoFacingMode(facingMode: VideoFacingModeEnum): boolean {
    const result =
      this.mediaStream
        .getVideoTracks()
        .findIndex((track) => track.getSettings().facingMode == facingMode) >=
      0;
    this.logger.debug("hasVideoFacingMode", result);
    return result;
  }
}

export class InternalMediaStreamWrapper extends MediaStreamWrapper {
  constructor(
    public log: boolean,
    public requestedAudio: boolean,
    public requestedVideo: boolean,
    previousMediaStream$?: BehaviorSubject<MediaStream>
  ) {
    super(
      log,
      requestedAudio,
      requestedVideo,
      previousMediaStream$
    );
  }
  public get mediaStream(): MediaStream {
    return super.mediaStream;
  }
  public set mediaStream(mediaStream: MediaStream) {
    super.mediaStream = mediaStream;
  }
  public cleanup() {
    let tracks = this.mediaStream.getTracks();
    this.logger.debug("MEDIA.TRACK.COUNT", tracks.length);
    tracks.forEach((track) => {
      if (track.readyState == "ended") {
        // should never happen, but who knows?
        this.logger.debug("MEDIA.TRACK.ENDED", track);
        removeTrack(this.mediaStream,track);
      }
    });
  }
}

export class Conference {
  protected _connections: {
    [key: string]: BehaviorSubject<ConferenceConnection>;
  } = {};
  protected _mediaStreamWrapper$: BehaviorSubject<InternalMediaStreamWrapper>;
  protected _screenStreamWrapper$: BehaviorSubject<InternalMediaStreamWrapper>;
  protected _screenStreamPromise: Promise<boolean> = undefined;
  protected _mediaStreamMapping = new Map<string, MediaStream>();
  protected _active: boolean = false; // only one conference is active!
  protected _muted: boolean = false;
  protected _audio: boolean = false;
  protected _video: boolean = false;
  protected _screen: boolean = false;
  protected _mediaStreamUpdatePromise: Promise<void> = undefined;

  protected static _mediaDevices: Observable<MediaDeviceInfo[]>;
  protected _audioInputDeviceId: string;
  protected _videoInputDeviceId: string;
  protected _videoFacingMode: VideoFacingModeEnum = "user";
  protected _mediaStreamConstraints: MediaStreamConstraints;

  protected logger = new Logger("Conference").setSilent(!this.log);

  constructor(
    public participantId: string,
    public info: ConferenceInfo,
    public startAudio: boolean,
    public startVideo: boolean,
    public updatedMediaStream: (ConferenceConnections) => void,
    public log = false
  ) {
    this._screenStreamWrapper$ = new BehaviorSubject(new InternalMediaStreamWrapper(
      this.log,
      false,
      false
    ));
    this._mediaStreamWrapper$ = new BehaviorSubject(new InternalMediaStreamWrapper(
      this.log,
      false,
      false
    ));
    this.video = startVideo;
    this.audio = startAudio;
    this.muted =
      !startAudio ||
      (!!info?.muted &&
        !(
          info?.participant?.role == "admin" ||
          info?.participant?.role == "owner"
        ));
    this.triggerMediaStreamUpdate();
  }

  protected onMediaStreamUpdated() {
    //this.logger.debug("UPDATED MEDIA STREAM after TRIGGERED audio",this.audio,this.mediaStreamWrapper.hasAudio,"video",this.video,this.mediaStreamWrapper.hasVideo);
    this.mediaStreamWrapper.pushMediaStream();
    this.updatedMediaStream(this);
  }

  protected onScreenStreamUpdated() {
    //this.logger.debug("UPDATED SHARE STREAM after TRIGGERED share",this.video,this.mediaStreamWrapper.hasVideo);
    this.screenStreamWrapper.pushMediaStream();
    this.updatedMediaStream(this);
  }

  protected triggerMediaStreamUpdate(): Promise<void> {
    //this.logger.debug("TRIGGER MEDIA STREAM UPDATE audio",this.audio,this.mediaStreamWrapper.hasAudio,"video",this.video,this.mediaStreamWrapper.hasVideo);
    if (!this._mediaStreamUpdatePromise) {
      this._mediaStreamUpdatePromise = new Promise((resolve, reject) => {
        window.setTimeout(() => {
          this.updateMediaStream()
            .then(resolve)
            .catch(reject)
            .finally(() => (this._mediaStreamUpdatePromise = undefined));
        });
      });
    }
    return this._mediaStreamUpdatePromise;
  }

  protected async updateMediaStream() {
    const logInfo = {
      audio: this.audio,
      hasAudio: this.mediaStreamWrapper.hasAudio,
      audioDeviceId: this._audioInputDeviceId,
      hasAudioDeviceId: this.mediaStreamWrapper.hasAudioDeviceId(
        this._audioInputDeviceId
      ),
      video: this.video,
      hasVideo: this.mediaStreamWrapper.hasVideo,
      videoDeviceId: this._videoInputDeviceId,
      hasVideoDeviceId: this.mediaStreamWrapper.hasVideoDeviceId(
        this._videoInputDeviceId
      ),
      videoFacingMode: this._videoFacingMode,
      hasVideoFacingMode: this.mediaStreamWrapper.hasVideoFacingMode(
        this._videoFacingMode
      ),
    };
    this.logger.info("updateMediaStream", logInfo);
    const videoStreamConstraint = this._mediaStreamConstraints?.video;
    const requestAudioInputDevice = !!(
      this._audioInputDeviceId &&
      !this.mediaStreamWrapper.hasAudioDeviceId(this._audioInputDeviceId)
    );
    const requestVideoInputDevice = !!(
      (this._videoInputDeviceId &&
        !this.mediaStreamWrapper.hasVideoDeviceId(this._videoInputDeviceId)) ||
      this._videoFacingMode != this.videoFacingMode
    );
    const requestInputDevice =
      (this.audio && requestAudioInputDevice) ||
      (this.video && requestVideoInputDevice);
    if (
      this.mediaStreamWrapper.requestedAudio != this.audio ||
      this.mediaStreamWrapper.requestedVideo != this.video ||
      requestInputDevice
    ) {
      //this.logger.info('updateMediaStream', logInfo);
      this._mediaStreamWrapper$.next(
        new InternalMediaStreamWrapper(
          this.log,
          this.audio,
          this.video,
          <BehaviorSubject<MediaStream>>this._mediaStreamWrapper$.value.mediaStream$
        ));
      const mediaStreamWrapper = this._mediaStreamWrapper$.value;
      let updated = false;
      //this.logger.debug("MEDIA.1.audio",this.audio,"video",this.video,"muted",this.muted);
      if (mediaStreamWrapper.hasAudio && !this.audio) {
        mediaStreamWrapper.stopAudio();
        updated = true;
      }
      if (mediaStreamWrapper.hasVideo && !this.video) {
        mediaStreamWrapper.stopVideo();
        updated = true;
      }
      if (
        mediaStreamWrapper.hasAudio == this.audio &&
        mediaStreamWrapper.hasVideo == this.video &&
        !requestInputDevice
      ) {
        if (updated) {
          //this.logger.info('updateMediaStream -> STREAM ADAPTED', { audio: this.audio, video: this.video });

          this.onMediaStreamUpdated();
        }
      } else {
        const requestAudio =
          this.audio &&
          (!mediaStreamWrapper.hasAudio || requestAudioInputDevice);
        const requestVideo =
          this.video &&
          (!mediaStreamWrapper.hasVideo || requestVideoInputDevice);
        try {
          const mediaStreamConstraints: MediaStreamConstraints = {};
          if (requestAudio) {
            // ATTENTION: at least on Android Chrome browser the existing tracks should be stopped
            // before requesting a new media stream (i.e. before getUserMedia api call)
            // Attempt to request audio/video from another device while still running the previously obtained tracks produces an error!
            if (mediaStreamWrapper.hasAudio) {
              // this.logger.debug('AUDIO - STOP');
              mediaStreamWrapper.stopAudio();
            }
            const audioConstraints: MediaTrackConstraints = {
              echoCancellation: true,
              noiseSuppression: true,
            };
            if (this._audioInputDeviceId) {
              audioConstraints.deviceId = { exact: this._audioInputDeviceId };
            }
            mediaStreamConstraints.audio = audioConstraints;
          } else {
            mediaStreamConstraints.audio = false;
          }

          if (requestVideo) {
            if (mediaStreamWrapper.hasVideo) {
              // this.logger.debug('VIDEO - STOP');
              mediaStreamWrapper.stopVideo();
            }
            const videoConstraints: MediaTrackConstraints = {
              // https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings/facingMode
              // If needed, you can determine whether or not this constraint is supported
              // by checking the value of MediaTrackSupportedConstraints.facingMode
              // as returned by a call to MediaDevices.getSupportedConstraints().
              facingMode: this._videoFacingMode ?? "user", // user, environment, left, right
            };
            if (this._videoInputDeviceId) {
              videoConstraints.deviceId = { exact: this._videoInputDeviceId };
            }
            mediaStreamConstraints.video = videoConstraints;
          } else {
            mediaStreamConstraints.video = false;
          }

          // if neither audio nor video is requested getUserMedia will report an error!
          const streamPromise =
            mediaStreamConstraints.audio || mediaStreamConstraints.video
              ? navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
              : Promise.resolve(undefined);
          const stream = await streamPromise;
          this.logger.info("updateMediaStream", {
            same: this.mediaStreamWrapper === mediaStreamWrapper,
            tracks: stream.getTracks()?.length,
            hasAudio: this.mediaStreamWrapper.hasAudio,
            hasVideo: this.mediaStreamWrapper.hasVideo,
            mediaStreamConstraints,
          });
          if (this._mediaStreamWrapper$.value === mediaStreamWrapper) {
            stream?.getTracks().forEach((track) => {
              const mediaStream = mediaStreamWrapper.mediaStream;
              addTrack(mediaStream,track);
            });
            mediaStreamWrapper.audioEnabled = !this.muted;
            this._mediaStreamConstraints = mediaStreamConstraints;
            this.logger.info("updateMediaStream -> onMediaStreamUpdated", {
              stream,
            });
            this.onMediaStreamUpdated();
          } else {
            // stop requested streams if another request is pending... last will win...
            mediaStreamWrapper.mediaStream = stream;
            mediaStreamWrapper.stop();
            this.logger.info(
              "updateMediaStream -> STREAM STOPPED (new request triggered)"
            );
          }
        } catch (e) {
          this.logger.error("Failed updateMediaStream", e);
        }
      }
    }
  }

  public shareScreen(screen: boolean): Promise<boolean> {
    if (!!this._screen != !!screen) {
      //console.trace("shareScreen",!!screen,"was",!!this._screen,"promise",this._screenStreamPromise);
      this._screen = !!screen;
      if (!screen) {
        if (this._screenStreamWrapper$.value.requestedVideo) {
          this._screenStreamWrapper$.value.stop();
          this._screenStreamWrapper$.next(new InternalMediaStreamWrapper(
            this.log,
            false,
            false,
            <BehaviorSubject<MediaStream>>this._screenStreamWrapper$.value.mediaStream$
          ));
        }
        return Promise.resolve(false);
      } else if (!!this._screenStreamPromise) {
        return this._screenStreamPromise;
      } else {
        return (this._screenStreamPromise = new Promise<boolean>(
          async (resolve, reject) => {
            this._screenStreamWrapper$.next(
              new InternalMediaStreamWrapper(
                this.log,
                false,
                true,
                <BehaviorSubject<MediaStream>>this._screenStreamWrapper$.value.mediaStream$
              ));
            let screenStreamWrapper = this._screenStreamWrapper$.value;
            try {
              const stream: MediaStream = await (<any>(
                navigator.mediaDevices
              )).getDisplayMedia();
              if (this.screen) {
                //this.logger.info('updateScreenStream -> STREAM CREATED', stream.getTracks());
                if (stream.getTracks().length > 0) {
                  //this.logger.info('shareScreen ok');
                  stream
                    .getTracks()
                    .forEach((track) => {
                      const mediaStream = screenStreamWrapper.mediaStream;
                      addTrack(mediaStream,track);
                    });
                  this._screen = true;
                  this._screenStreamPromise = undefined;
                  this._screenStreamWrapper$.next(screenStreamWrapper);
                  this.onScreenStreamUpdated();
                  resolve(true);
                } else {
                  //this.logger.error('shareScreen has no tracks');
                  this._screen = false;
                  this._screenStreamPromise = undefined;
                  this._screenStreamWrapper$.next(new InternalMediaStreamWrapper(
                    this.log,
                    false,
                    false,
                    <BehaviorSubject<MediaStream>>this._screenStreamWrapper$.value.mediaStream$
                  ));
                  resolve(false);
                }
              } else {
                //screenStreamWrapper.mediaStream = stream;
                screenStreamWrapper.stop();
                resolve(false);
              }
            } catch (error) {
              //this.logger.error('shareScreen failed',error);
              this._screen = false;
              this._screenStreamPromise = undefined;
              this._screenStreamWrapper$.next(new InternalMediaStreamWrapper(
                this.log,
                false,
                false,
                <BehaviorSubject<MediaStream>>this._screenStreamWrapper$.value.mediaStream$
              ));
              reject(error);
            }
          }
        ));
      }
    }
    return this._screenStreamPromise || Promise.resolve(!!this._screen);
  }

  getConnection$(participantId: string): Observable<ConferenceConnection> {
    if (
      !!participantId &&
      ((this.info.server && participantId == this.participantId) ||
        (!this.info.server && participantId != this.participantId))
    ) {
      let connection$ = this._connections[participantId];
      if (!connection$) {
        this._connections[participantId] = connection$ =
          new BehaviorSubject<ConferenceConnection>(undefined);
      }
      return connection$;
    }
    console.log(
      "Conference.getConnection$(",
      participantId,
      ")",
      this.info,
      "ERROR!!"
    );
    return of(undefined);
  }

  setConnection(participantId: string, connection: ConferenceConnection) {
    let connectionSubject = <BehaviorSubject<ConferenceConnection>>(
      this.getConnection$(participantId)
    );
    if (
      (!!connectionSubject.value && !connection) ||
      (!connectionSubject.value && !!connection)
    ) {
      connectionSubject.next(connection);
    }
  }

  getConnection(participantId: string): ConferenceConnection {
    return (<BehaviorSubject<ConferenceConnection>>(
      this.getConnection$(participantId)
    ))?.value;
  }

  getConnectedParticipantIds(): string[] {
    return Object.keys(this._connections).filter(
      (id) => !!this._connections[id]?.value?.participant
    );
  }

  getMediaStreamWrapper$(
    participantId: string
  ): Observable<MediaStreamWrapper> {
    console.log("#C.getMediaStreamWrapper$("+participantId+")");
    return !participantId
      ? of(undefined)
      : participantId == this.participantId
      ? this.mediaStreamWrapper$.pipe(
          tap((wrapper) => console.log("#C.getMediaStreamWrapper$",wrapper))
        )
      : this.getConnection$(participantId).pipe(
          map((connection) => connection?.mediaStreamWrapper)
        );
  }

  getScreenStreamWrapper$(
    participantId: string
  ): Observable<MediaStreamWrapper> {
    console.log("#C.getScreenStreamWrapper$("+participantId+")");
    return !participantId
      ? of(undefined)
      : participantId == this.participantId
      ? this.screenStreamWrapper$.pipe(
          tap((wrapper) => console.log("#C.getScreenStreamWrapper$",wrapper))
        )
      : this.getConnection$(participantId).pipe(
          map((connection) => connection?.screenStreamWrapper)
        );
  }

  getMediaStream$(
    participantId: string,
    stripAudio = false
  ): Observable<MediaStream> {
    //this.logger.debug("STRIP.AUDIO.getMediaStream$",participantId,"stripAudio",stripAudio,"conference",this);
    return this.getMediaStreamWrapper$(participantId).pipe(
      //tap(wrapper=>this.logger.debug("getMediaStream$.1",!!wrapper,wrapper)),
      switchMap((wrapper) => wrapper?.mediaStream$.pipe()),
      //tap(mediaStream=>this.logger.debug("getMediaStream$.2",!!mediaStream))
      map((mediaStream) => {
        //this.logger.debug("TRACK.getMediaStream$",participantId,"stripAudio",stripAudio,"\nstream",mediaStream?.id,"tracks",mediaStream?.getTracks().length);
        if (stripAudio) {
          //this.logger.debug("UPDATED MEDIA audio",this.mediaStreamWrapper.hasAudio,"video",this.mediaStreamWrapper.hasVideo);
          let videoStream =
            this._mediaStreamMapping.get(participantId) ?? new MediaStream();
          this._mediaStreamMapping.set(participantId, videoStream);
          mediaStream?.getVideoTracks().forEach((track) => {
            let mapped = !!videoStream.getTrackById(track.id);
            //this.logger.debug("STRIP.AUDIO.track",track.id,"mapped",mapped);
            if (!mapped) {
              addTrack(videoStream,track);
              //this.logger.debug("STRIP.AUDIO.add",track.id);
            }
          });
          videoStream?.getVideoTracks().forEach((track) => {
            let mapped = !!mediaStream?.getTrackById(track.id);
            if (!mapped) {
              removeTrack(videoStream,track);
              //this.logger.debug("STRIP.AUDIO.remove",track.id);
            }
          });
          return !!mediaStream && videoStream.getVideoTracks()?.length > 0
            ? videoStream
            : undefined;
        } else {
          return mediaStream;
        }
      })
    );
  }

  getScreenStream$(participantId: string): Observable<MediaStream> {
    return this.getScreenStreamWrapper$(participantId).pipe(
      switchMap((wrapper) => wrapper?.mediaStream$.pipe())
    );
  }

  get mediaStreamWrapper(): MediaStreamWrapper {
    return this._mediaStreamWrapper$.value;
  }

  get mediaStreamWrapper$(): Observable<MediaStreamWrapper> {
    return this._mediaStreamWrapper$;
  }

  get hasMediaStream(): boolean {
    return this.mediaStreamWrapper?.hasMediaStream;
  }

  get screenStreamWrapper(): MediaStreamWrapper {
    return this._screenStreamWrapper$.value;
  }

  get screenStreamWrapper$(): Observable<MediaStreamWrapper> {
    return this._screenStreamWrapper$;
  }

  get hasScreenStream(): boolean {
    return this.screenStreamWrapper?.hasMediaStream;
  }

  get muted(): boolean {
    return this._muted;
  }

  set muted(muted: boolean) {
    this._muted = muted;
    if (this.hasMediaStream) {
      this.mediaStreamWrapper.audioEnabled = !muted;
    }
  }

  get video(): boolean {
    return this._video;
  }

  set video(video: boolean) {
    this._video = video;
    if (this.hasMediaStream) {
      this.mediaStreamWrapper.videoEnabled = video;
    }
    this.triggerMediaStreamUpdate();
  }

  get audio(): boolean {
    return this._audio;
  }

  set audio(audio: boolean) {
    this._audio = audio;
    if (this.hasMediaStream) {
      this.mediaStreamWrapper.audioEnabled = audio;
    }
    this.triggerMediaStreamUpdate();
  }

  get canShareScreen(): boolean {
    let participant = this.info?.participant;
    return (
      !!participant &&
      (participant.role == "owner" ||
        participant.role == "admin" ||
        !!participant.unmuted ||
        !this.info.muted) &&
      typeof (<any>navigator.mediaDevices).getDisplayMedia === "function"
    );
  }

  get screen(): boolean {
    return this._screen;
  }

  get active(): boolean {
    return this._active;
  }

  set active(active: boolean) {
    if (this._active != active) {
      this._active = active;
      if (this.hasMediaStream) {
        this.mediaStreamWrapper.audioEnabled = active && !this.muted;
        this.mediaStreamWrapper.videoEnabled = active && this.video;
      }
    }
  }

  stop() {
    this.mediaStreamWrapper?.stop();
    this.screenStreamWrapper?.stop();
    Object.keys(this._connections).forEach((key) => {
      let connection = this._connections[key]?.value;
      if (connection) {
        connection.peer?.close();
        connection.peer = undefined;
        connection.participant = undefined;
      }
    });
    this._connections = {};
  }

  getMediaDevices(
    kind?: "audioinput" | "audiooutput" | "videoinput"
  ): Observable<(MediaDeviceInfo & { default?: boolean })[]> {
    // ATTENTION: on chrome audioinput and audiooutput devices are swapped
    // The order is significant — the default capture devices will be listed first.
    if (!Conference._mediaDevices) {
      const mediaDevices = () =>
        navigator.mediaDevices?.enumerateDevices
          ? navigator.mediaDevices.enumerateDevices()
          : Promise.reject("mediaDevices.enumerateDevices() API not supported");
      Conference._mediaDevices = concat(
        from(mediaDevices()),
        fromEvent(navigator.mediaDevices, "devicechange").pipe(
          concatMap((event) => from(mediaDevices()))
        )
      ).pipe(
        map((mediaDevices) => {
          // Default device id should be handled specifically for Chrome and Opera:
          // see https://stackoverflow.com/questions/53304934/why-does-mediadevices-enumeratedevices-list-some-devices-twice-what-is-default
          return mediaDevices.reduce(
            (accumulator, device) => {
              const index =
                device.kind == "audioinput"
                  ? 0
                  : device.kind == "audiooutput"
                  ? 1
                  : 2;
              const defaultDeviceIndex = accumulator.defaultIndexes[index];
              if (defaultDeviceIndex == undefined) {
                device["default"] = true;
                accumulator.devices.push(device);
                accumulator.defaultIndexes[index] =
                  accumulator.devices.length - 1;
              } else {
                const currentDefault = accumulator.devices[defaultDeviceIndex];
                if (device.deviceId == "default") {
                  if (currentDefault.groupId == device.groupId) {
                    currentDefault.default = true;
                  } else {
                    accumulator.devices[defaultDeviceIndex] = device;
                  }
                } else if (
                  currentDefault.groupId == device.groupId &&
                  currentDefault.deviceId == "default"
                ) {
                  accumulator.devices[defaultDeviceIndex] = device;
                } else {
                  accumulator.devices.push(device);
                }
              }
              return accumulator;
            },
            {
              defaultIndexes: [],
              devices: [] as (MediaDeviceInfo & { default?: boolean })[],
            }
          ).devices;
        }),
        shareReplay(1)
      );
    }
    return Conference._mediaDevices.pipe(
      map((devices) => devices.filter((device) => !kind || device.kind == kind))
    );
  }

  setAudioInputDevice(deviceId: string): Promise<void> {
    if (!this.mediaStreamWrapper.hasAudioDeviceId(deviceId)) {
      this._audioInputDeviceId = deviceId;
      return this.triggerMediaStreamUpdate();
    }
    return Promise.resolve();
  }

  setVideoInputDevice(deviceId: string): Promise<void> {
    if (!this.mediaStreamWrapper.hasVideoDeviceId(deviceId)) {
      this._videoInputDeviceId = deviceId;
      return this.triggerMediaStreamUpdate();
    }
    return Promise.resolve();
  }

  setVideoFacingMode(facingMode: VideoFacingModeEnum): Promise<void> {
    if (facingMode != this.videoFacingMode) {
      this._videoFacingMode = facingMode;
      return this.triggerMediaStreamUpdate();
    }
    return Promise.resolve();
  }

  toggleVideoFacingMode(): Promise<void> {
    return this.setVideoFacingMode(
      this._videoFacingMode == "user" ? "environment" : "user"
    );
  }

  get videoFacingMode(): ConstrainDOMString {
    const videoStreamConstraint = this._mediaStreamConstraints?.video;
    return typeof videoStreamConstraint == "object"
      ? videoStreamConstraint.facingMode
      : undefined;
  }

  setAudioOutputDevice(
    element: HTMLMediaElement & { setSinkId?: (sinkId) => Promise<void> },
    deviceId: string
  ): Promise<void> {
    if (
      Conference.canChangeAudioOutputDevice &&
      typeof element.setSinkId !== "undefined"
    ) {
      return element
        .setSinkId(deviceId)
        .then(() =>
          this.logger.info(
            `Audio output device set: [ element: ${element}, deviceId: ${deviceId} ]`
          )
        )
        .catch((error) =>
          this.logger.error(
            `Failed to set audio output device: ${error.message}`
          )
        );
    } else {
      return Promise.reject("Audio output device selection is not supported");
    }
  }

  static get canChangeAudioOutputDevice(): boolean {
    // https://caniuse.com/mdn-api_htmlmediaelement_setsinkid
    // currently only supported by desktop chrome and opera
    return "sinkId" in HTMLMediaElement.prototype;
  }
}
