<!--<app-contact-detail [contact]="contact" *ngIf="contact" (onShowNotes)="showNotes()"></app-contact-detail>-->

<!-- <div #header class="header" [class.large]="largeAvatar">
  <img matListItemAvatar [src]="photoUrl | sessionLink"
       onload="this.style.opacity=1"
       (click)="toggleAvatar()"
       [ngStyle]="largeAvatar ? largeAvatarStyle : {}" />
  <div>
    <div class="name">{{contact.name  }}</div>
    <member-id *ngIf="contact.memberId; let memberId"
               [memberId]="memberId"
               [actions]="contact.app?.actions"
               [role]="contact.role=='admin'||contact.role=='leader'?'leader':contact.role=='user'?'member':'guest'">
    </member-id>
    <ng-container *ngIf="!contact.memberId">
      <app-partner-id [membership]="membership | async" [hasMenu]="appId==1"></app-partner-id>
    </ng-container>
  </div>
</div> -->
<ng-container *ngIf="contact$() as contact">
  <div class="address" *ngIf="hasAddress">
    <div *ngIf="contact.address">{{contact.address}}</div>
    <div>
      <span *ngIf="contact.countryCode">{{contact.countryCode | asEmojiFlag}}</span>
      <span *ngIf="contact.zip">{{contact.zip}}</span>
      <span *ngIf="contact.community">{{contact.community}}</span>
    </div>
  </div>
  <div class="location-map" *ngIf="hasAddress && !!location.lat && !!location.lng && showMap"
       [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '160px' }}">
    <div>
      <!--
      <agm-map #map
               (onDomModified)="onDomModified($event)"
               [subtreeDomMutations]="true"
               [zoomControl]="true"
               [zoomControlOptions]="location.zoomOptions"
               [zoom]="location.zoom"
               [mapTypeId]="location.mapTypeId"
               [latitude]="location.lat"
               [longitude]="location.lng">
        <agm-marker [latitude]="location.lat" [longitude]="location.lng">
        </agm-marker>
      </agm-map>
      -->
      <map [latitude]="location.lat"
           [longitude]="location.lng"
           [markers]="[{ latitude: location.lat, longitude: location.lng }]">
        <!--
        <map-marker [latitude]="location.lat" [longitude]="location.lng">
        </map-marker>
        -->
      </map>
    </div>
  </div>
  <div class="links">
    <div class="location-map-link" *ngIf="hasAddress && !!location.lat && !!location.lng && !showMap"
         [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '46px' }}"
         (click)="showMap=true">
      <mat-icon>location_on</mat-icon>
      <span translate>contact.satelliteView</span>
    </div>
    <div class="birthday" *ngIf="contact.birthday || contact.birthdaywoy; let birthday">
      <mat-icon>cake</mat-icon>
      <span>{{birthday.toString() | birthday }}</span>
      <!-- <span>{{getBirthday(contact)}}</span> -->
    </div>
    <a *ngIf="!!contactService.chatHook && !!contact?.chat && !!contact?.id && contact.id!=contactService.contactId"
       (click)="contactService.chatHook.connect(contact.id)" class="chat">
      <mat-icon>chat</mat-icon>
      <span translate>contact.actions.chat</span>
      <button class="mat-elevation-z" mat-mini-fab color="primary" (click)="showNotes($event)"><mat-icon>edit_note</mat-icon></button>
    </a>
    <ng-container *ngIf="!!contact?.chat && !!contact?.id && contact.id!=contactService.contactId && contactService.callHook?.state$(contact.id) as state$">
      <ng-container *ngLet="state$ | async as state">
        <a *ngIf="!state" class="phone"
           (click)="contactService.callHook.connect(contact.id)">
          <mat-icon>call</mat-icon>
          <span translate>contact.actions.call</span>
        </a>
        <a *ngIf="state=='in'" class="phone accept"
           (click)="contactService.callHook.connect(contact.id)">
          <mat-icon>call</mat-icon>
          <span translate>actions.accept</span>
        </a>
        <a *ngIf="!!state && state!='in'" class="phone hangUp"
           (click)="contactService.callHook.disconnect(contact.id)">
          <mat-icon>call_end</mat-icon>
          <span translate>actions.hangup</span>
        </a>
      </ng-container>
    </ng-container>
    <a *ngIf="contact?.phone?.length>5" class="phone"
       [class.disabled]="!platform.is('hybrid') && !platform.is('mobile')"
       (click)="onTapPhone()">
      <mat-icon>phone</mat-icon>
      <span [class.copy]="!platform.is('hybrid') && !platform.is('mobile')">{{contact.phone}}</span>
    </a>
    <a *ngIf="!!(contact.email)" class="email" (click)="onTapEmail()" >
      <mat-icon>email</mat-icon>
      <span *ngIf="contact.tags?.includes('system')" translate>contact.email</span>
      <span *ngIf="!contact.tags?.includes('system')">{{contact.email}}</span>
    </a>
    <a #appLink target="_blank" hidden></a>
    <!--
      icons from:
          whatsapp,phone,mail: mihail brought it, dont know the source
          wechat, messenger,telegram:
          https://www.flaticon.com/free-icon/whatsapp_733585#term=whatsapp&page=1&position=6
          https://worldvectorlogo.com/de/suche/whatsapp
    -->
    <!--
      mat-icon with svgIcon property is not rendered in (and only in) ios app.
      for this reason we are forced to use plain svg elements.
    -->
    <!--
    <a *ngIf="!!(contact.whatsapp)" class="whatsapp" (click)="onTapWhatsApp($event)">
      <mat-icon svgIcon="whatsapp"></mat-icon>
      <span translate>apps.whatsapp.name</span>
    </a>
    <a *ngIf="!!(contact.messenger)" class="messenger" (click)="onTapMessenger($event)">
      <mat-icon svgIcon="messenger"></mat-icon>
      <span translate>apps.messenger.name</span>
    </a>
    <a *ngIf="!!(contact.wechat)" class="wechat" (click)="onTapWechat($event)">
      <mat-icon svgIcon="wechat"></mat-icon>
      <span translate>apps.wechat.name</span>
    </a>
    <a *ngIf="!!(contact.telegram)" class="telegram" (click)="onTapTelegram($event)">
      <mat-icon svgIcon="telegram"></mat-icon>
      <span translate>apps.telegram.name</span>
    </a>
    -->
    <a *ngIf="!!(contact.whatsapp)" class="whatsapp" (click)="onTapWhatsApp($event)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19.077,4.928C17.191,3.041,14.683,2.001,12.011,2c-5.506,0-9.987,4.479-9.989,9.985 c-0.001,1.76,0.459,3.478,1.333,4.992L2,22l5.233-1.237c1.459,0.796,3.101,1.215,4.773,1.216h0.004 c5.505,0,9.986-4.48,9.989-9.985C22.001,9.325,20.963,6.816,19.077,4.928z M16.898,15.554c-0.208,0.583-1.227,1.145-1.685,1.186 c-0.458,0.042-0.887,0.207-2.995-0.624c-2.537-1-4.139-3.601-4.263-3.767c-0.125-0.167-1.019-1.353-1.019-2.581 S7.581,7.936,7.81,7.687c0.229-0.25,0.499-0.312,0.666-0.312c0.166,0,0.333,0,0.478,0.006c0.178,0.007,0.375,0.016,0.562,0.431 c0.222,0.494,0.707,1.728,0.769,1.853s0.104,0.271,0.021,0.437s-0.125,0.27-0.249,0.416c-0.125,0.146-0.262,0.325-0.374,0.437 c-0.125,0.124-0.255,0.26-0.11,0.509c0.146,0.25,0.646,1.067,1.388,1.728c0.954,0.85,1.757,1.113,2.007,1.239 c0.25,0.125,0.395,0.104,0.541-0.063c0.146-0.166,0.624-0.728,0.79-0.978s0.333-0.208,0.562-0.125s1.456,0.687,1.705,0.812 c0.25,0.125,0.416,0.187,0.478,0.291C17.106,14.471,17.106,14.971,16.898,15.554z"/>
      </svg>
      <span translate>apps.whatsapp.name</span>
    </a>
    <a *ngIf="!!(contact.messenger)" class="messenger" (click)="onTapMessenger($event)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="96 93 322 324">
        <path d="M257 93c-88.918 0-161 67.157-161 150 0 47.205 23.412 89.311 60 116.807V417l54.819-30.273C225.449 390.801 240.948 393 257 393c88.918 0 161-67.157 161-150S345.918 93 257 93zm16 202l-41-44-80 44 88-94 42 44 79-44-88 94z"/>
      </svg>
      <span translate>apps.messenger.name</span>
    </a>
    <a *ngIf="!!(contact.wechat)" class="wechat" (click)="onTapWechat($event)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
        <path d="M300 255c0 24.854-20.147 45-45 45H45c-24.854 0-45-20.146-45-45V45C0 20.147 20.147 0 45 0h210c24.853 0 45 20.147 45 45v210z"/><g fill="#FFF"><path d="M200.803 111.88c-24.213 1.265-45.268 8.605-62.362 25.188-17.271 16.754-25.155 37.284-23 62.734-9.464-1.172-18.084-2.462-26.753-3.192-2.994-.252-6.547.106-9.083 1.537-8.418 4.75-16.488 10.113-26.053 16.092 1.755-7.938 2.891-14.889 4.902-21.575 1.479-4.914.794-7.649-3.733-10.849-29.066-20.521-41.318-51.232-32.149-82.85 8.483-29.25 29.315-46.989 57.621-56.236 38.635-12.62 82.054.253 105.547 30.927 8.485 11.08 13.688 23.516 15.063 38.224zm-111.437-9.852c.223-5.783-4.788-10.993-10.74-11.167-6.094-.179-11.106 4.478-11.284 10.483-.18 6.086 4.475 10.963 10.613 11.119 6.085.154 11.186-4.509 11.411-10.435zm58.141-11.171c-5.974.11-11.022 5.198-10.916 11.004.109 6.018 5.061 10.726 11.204 10.652 6.159-.074 10.83-4.832 10.772-10.977-.051-6.032-4.981-10.79-11.06-10.679z"/><path d="M255.201 262.83c-7.667-3.414-14.7-8.536-22.188-9.318-7.459-.779-15.3 3.524-23.104 4.322-23.771 2.432-45.067-4.193-62.627-20.432-33.397-30.89-28.625-78.254 10.014-103.568 34.341-22.498 84.704-14.998 108.916 16.219 21.129 27.24 18.646 63.4-7.148 86.284-7.464 6.623-10.15 12.073-5.361 20.804.884 1.612.985 3.653 1.498 5.689zm-87.274-84.499c4.881.005 8.9-3.815 9.085-8.636.195-5.104-3.91-9.385-9.021-9.406-5.06-.023-9.299 4.318-9.123 9.346.166 4.804 4.213 8.69 9.059 8.696zm56.261-18.022c-4.736-.033-8.76 3.844-8.953 8.629-.205 5.117 3.772 9.319 8.836 9.332 4.898.016 8.768-3.688 8.946-8.562.19-5.129-3.789-9.364-8.829-9.399z"/></g>
      </svg>
      <span translate>apps.wechat.name</span>
    </a>
    <a *ngIf="!!(contact.telegram)" class="telegram" (click)="onTapTelegram($event)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 792 792">
        <path style="fill:#FFFFFF;" d="M579.8,240.3c2.9,2.5,4.2,5.8,4,9.8l-59.9,285.7c-2,9.8-7.9,14.7-17.7,14.7c-3.8,0-7.3-1.1-10.6-3.4
          l-87.5-64.1l-49,46c-2.8,2.3-5.7,3.4-8.7,3.4c-6.8,0-11.3-2.9-13.6-8.7L302.1,419l-86-26.8c-5.3-1.5-8.3-5.3-9-11.3
          c0-6,3-10.2,9-12.4c235.2-88,353.6-131.9,355.1-131.9C574,236.6,576.9,237.8,579.8,240.3z M516.1,295.9c0.1-0.6,0.2-1.1,0.2-1.3
          c0-2.3-1.6-3.4-4.9-3.4c-0.8,0-63.6,40-188.5,119.9l26.4,86.3l5.3-55C462.1,345.4,515.9,296.5,516.1,295.9z"/>
        <path id="background" d="M740.7,253.5c18.7,45.2,28.1,92.7,28.1,142.5s-9.4,97.3-28.1,142.5c-18.7,45.2-45.8,85.6-81.2,121
          s-75.8,62.5-121,81.2c-45.2,18.7-92.7,28.1-142.5,28.1s-97.3-9.4-142.5-28.1c-45.2-18.7-85.6-45.8-121-81.2s-62.5-75.8-81.2-121
          C32.5,493.3,23.2,445.8,23.2,396s9.4-97.3,28.1-142.5c18.7-45.2,45.8-85.6,81.2-121s75.8-62.5,121-81.2
          c45.2-18.7,92.7-28.1,142.5-28.1s97.3,9.4,142.5,28.1c45.2,18.7,85.6,45.8,121,81.2S722,208.3,740.7,253.5z M583.7,250.1
          c0.3-4-1.1-7.3-4-9.8c-2.9-2.5-5.7-3.8-8.5-3.8c-1.5,0-119.9,44-355.1,131.9c-6,2.3-9,6.4-9,12.4c0.8,6,3.8,9.8,9,11.3l86,26.8
          l34.7,104.8c2.3,5.8,6.8,8.7,13.6,8.7c3,0,5.9-1.1,8.7-3.4l49-46l87.5,64.1c3.3,2.3,6.8,3.4,10.6,3.4c9.8,0,15.7-4.9,17.7-14.7
          L583.7,250.1z"/>
        <path id="shadow" d="M516.3,294.6c0,0.3-0.1,0.7-0.2,1.3c-0.1,0.6-54,49.4-161.5,146.4l-5.3,55l-26.4-86.3
          c124.9-79.9,187.7-119.9,188.5-119.9C514.6,291.2,516.3,292.3,516.3,294.6z"/>
      </svg>
      <!-- THIS ICON IS NOT RENDERED PROPERLY IN IOS APP
      <svg id="svg2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <style>.st0{fill:url(#path2995-1-0_1_)}.st1{fill:#c8daea}.st2{fill:#a9c9dd}.st3{fill:url(#path2991_1_)}</style>
        <linearGradient id="path2995-1-0_1_" gradientUnits="userSpaceOnUse" x1="-683.305" y1="534.845" x2="-693.305" y2="511.512" gradientTransform="matrix(6 0 0 -6 4255 3247)">
          <stop offset="0" stop-color="#1e96c8"/>
          <stop offset="1" stop-color="#1e96c8"/>
        </linearGradient>
        <path id="path2995-1-0" class="st0" d="M240 120c0 66.3-53.7 120-120 120S0 186.3 0 120 53.7 0 120 0s120 53.7 120 120z"/><path id="path2993" class="st1" d="M98 175c-3.9 0-3.2-1.5-4.6-5.2L82 132.2 152.8 88l8.3 2.2-6.9 18.8L98 175z"/><path id="path2989" class="st2" d="M98 175c3 0 4.3-1.4 6-3 2.6-2.5 36-35 36-35l-20.5-5-19 12-2.5 30v1z"/><linearGradient id="path2991_1_" gradientUnits="userSpaceOnUse" x1="128.991" y1="118.245" x2="153.991" y2="78.245" gradientTransform="matrix(1 0 0 -1 0 242)"><stop offset="0" stop-color="#eff7fc"/><stop offset="1" stop-color="#fff"/></linearGradient><path id="path2991" class="st3" d="M100 144.4l48.4 35.7c5.5 3 9.5 1.5 10.9-5.1L179 82.2c2-8.1-3.1-11.7-8.4-9.3L55 117.5c-7.9 3.2-7.8 7.6-1.4 9.5l29.7 9.3L152 93c3.2-2 6.2-.9 3.8 1.3L100 144.4z"/>
      </svg>
      -->
      <span translate>apps.telegram.name</span>
    </a>
  </div>
</ng-container>
