import {Injectable} from "@angular/core";
import {Marker} from "maps";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Logger} from "core";
import {LatLng} from "../components/event-map/event-map.component";
import {Observable} from "rxjs";
import get from 'lodash/get';

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {

  protected logger = new Logger('GeocodingService');

  protected static GOOGLE_API_KEY = 'AIzaSyDGSn4b6teaUQHoOG1oy8j28RKfkBMdzgs'; // office@groupmanager.info project realizer
  protected static HERE_MAPS_API_KEY = '7RqoQjzjPwduA0TVzaeEv9O1UzNuMstOkJ1ELd-7Ugo';

  constructor(protected httpClient: HttpClient) {
  }

  public geocode(location: string): Observable<Marker> {
    return this.httpClient
      .get<Marker>(`/v1.0/calendar/geocode?address=${location}`)
      .pipe(
        map((marker: Marker) => {
          if (!marker) {
            throw new Error('Unexpected server response');
          }
          this.logger.info('geocode', location, marker);
          return marker;
        }),
        catchError( error => {
          this.logger.error('Failed to perform geocode request', error);
          throw error;
        })
      );
  }

  public reverseGeocode(location: LatLng, radius?: number): Observable<string> {
    const prox=`${location.lat},${location.lng},${radius || 100 }`;
    return this.httpClient
      .get<any>(`/v1.0/calendar/geocode/reverse?latitude=${location.lat}&longitude=${location.lng}`)
      // .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=42.77026,23.35924&key=${GeocodeService.GOOGLE_API_KEY}`) // google api reverse geolocation is paid
      // .get(`https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?apiKey=${GeocodeService.HERE_MAPS_API_KEY}&mode=retrieveAddresses&prox=${prox}`)
      .pipe(
        map((response: any) => {
          let address = undefined;
          if (response) {
            // direct call to here maps reversecode api
            const result = get(response, 'Response.View[0].Result[0]'); // results are sorted by distance asc
            if (result) {
              address = get(result, 'Location.Address.Label');
              this.logger.info('reverseGeocode - success', { location, response, address });
            } else if (response.address) {
              // received from app server
              address = response.address;
            } else {
              this.logger.warn('reverseGeocode - address not found', { location, radius });
            }
          } else {
            this.logger.warn('reverseGeocode - empty response');
          }
          return address;
        }),
        catchError( error => {
          this.logger.error('Failed to perform reverse geocode request', error);
          throw error;
        })
      );
  }

  public timezone(location: LatLng): Observable<any> {
    // https://developers.google.com/maps/documentation/timezone/overview
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat},${location.lng}&timestamp=${Math.floor(Date.now() / 1000)}&key=${GeocodeService.GOOGLE_API_KEY}`
    return this.httpClient
      .get<any>(url)
      // .get<any>(`/v1.0/calendar/timezone?location=${location.lat},${location.lng}`)
      .pipe(
        map((timezone: any) => {
          this.logger.debug({timezone});
          if (!timezone) {
            throw new Error('Server response is empty');
          }
          // {
          //   dstOffset: 0,
          //   rawOffset: 3600,
          //   status: "OK",
          //   timeZoneId: "Europe/Vienna",
          //   timeZoneName: "Central European Standard Time"
          // }
          return timezone;
        }),
        catchError(error => {
          this.logger.error(`Failed to retrieve timezone for location: ${location}`, error);
          throw error;
        })
      );
  }
}
