import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output} from '@angular/core';
import {ResizeService} from "shared";
import {ImageLink, Media, MediaService, MediaViewerOverlayService} from "media";
import {Attachment} from "messaging";
import {Distance} from "../../core/distance";
import {Space} from "../../core/space";
import {MediaAttachmentComponent} from "../core/media.attachment.component";

export interface Size {
  width:number;
  height:number;
}

export interface MediaTile {
  top?:Distance;
  left?:Distance;
  width?:Distance;
  height?:Distance;
  media:Media;
  link:Partial<ImageLink>;
  ratio?:number;   // or undefined for e.g. binary or audio
  visible:boolean;
}

@Component({
  selector: 'media-attachment-cover-viewer',
  templateUrl: './media-attachment-cover-viewer.component.html',
  styleUrls: ['./media-attachment-cover-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaAttachmentCoverViewerComponent extends MediaAttachmentComponent {
  public tiles:MediaTile[] = [];
  public more:number = undefined;
  public heightPct:number = 56.25; // height in pct
  protected _space:Space = undefined;

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    super.attachments = attachments;
    this.tiles = [];
    this.more  = undefined;
    this.attachments.forEach((attachment,index) => {
      if (!!(<any>attachment)?.media) {
        const media = <Media>(<any>attachment)?.media;
        if (media.links?.length>0) {
          const link = this.getImageLink(media) ?? { ...media.links[0],width:50,height:50};
          //console.log("MEDIA",media,"\nLINK",link);
          this.tiles.push({
            media, link,
            visible: false
          });
        }
      }
    });
    const result = this.layout(this.tiles);
    this.tiles = result.tiles;
    this.tiles.forEach(tile=>{
      this.toStyleDistance(tile.left);
      this.toStyleDistance(tile.top);
      this.toStyleDistance(tile.width);
      this.toStyleDistance(tile.height);
    });
    this.heightPct = result.width>0 && result.height>0 ? 100*result.height/result.width : undefined;
    this.more = result.more;
    this._space = {
      fixedWidth:false,
      fixedHeight:false,
      fixedRatio:true,
      ratio:result.width/result.height,
      width:result.width,
      height:result.height
    };
    //console.log("RESULT",result,"heightPct",this.heightPct,"more",this.more);
  }
  get attachments():Attachment[] {
    return super.attachments;
  }

  toStyleDistance(distance:Distance):Distance {
    distance.style = (distance.distance==0 ? '0' : distance.distance.toFixed(2)+(distance.realtive?'%':'px'));
    return distance;
  }

  get space():Space {
    return this._space ?? {fixedWidth:false,fixedRatio:false,fixedHeight:false};
  }

  constructor(
    protected mediaService: MediaService,
    protected resizeService: ResizeService,
    protected mediaViewerOverlayService: MediaViewerOverlayService,
    protected changeDetectorRef: ChangeDetectorRef) {
    super(mediaViewerOverlayService,changeDetectorRef);
  }

  layout(tiles: MediaTile[]):{
    width:number,
    height:number,
    more:number,
    tiles:MediaTile[]
  } {
    if (tiles?.length>0) {
      if (tiles.length==1) {
        const tile  = tiles[0];
        const ratio = tile.ratio = tile.link.width / tile.link.height;
        tile.left   = { distance:0, realtive:false };
        tile.top    = { distance:0, realtive:false };
        tile.width  = { distance:100, realtive:true };
        tile.height = { distance:100, realtive:true };
        return {
          width:tile.link.width,
          height:tile.link.height,
          more:undefined,
          tiles:tiles
        }
      } else {
        const result:MediaTile[] = [];
        const primaryIndex = Math.max(0,tiles.findIndex(tile=>(tile.link.width+tile.link.height)>=200));
        result.push(tiles[primaryIndex]);
        tiles.splice(primaryIndex,1);
        const tile  = result[0];
        const ratio = tile.ratio = tile.link.width / tile.link.height;
        const right = tiles.length==1
          ? ratio<=1 || (tiles[0].link.width / tiles[0].link.height)<=1
          : ratio<=1;
        tiles.forEach(tile=>{
          if (tile.link.width>50 && tile.link.height>50 && result.length<4) {
            result.push(tile);
          }
        });
        tiles.forEach(tile=>{
          if (tile.link.width<=50 || tile.link.height<=50 && result.length<4) {
            result.push(tile);
          }
        });
        const extraTiles = Math.min(result.length-1,3);
        let   extraWidth = right ? 100 : 0;
        let  extraHeight = right ? 0 : 100;
        for (let i=1, max=1+extraTiles; i<max; i++) {
          const tile = result[i];
          tile.ratio = tile.link.width / tile.link.height;
          if (right) {
            extraHeight += 100/tile.ratio;
          } else {
            extraWidth  += 100*tile.ratio;
          }
        }
        //console.log("EXTRA.1.width",extraWidth,"height",extraHeight);
        let width  = tile.link.width;
        let height = tile.link.height;
        if (right) {
          extraWidth  = extraWidth*height/extraHeight;
          extraHeight = height;
        } else {
          extraHeight = extraHeight*width/extraWidth;
          extraWidth  = width;
        }
        //console.log("PRIMARY.1.width",width,"height",height,"extraWidth",extraWidth,"extraHeight",extraHeight,"link",{...tile.link},"tile",{...tile})
        width        = right ? width+extraWidth : width;
        height       = right ? height : height+extraHeight;
        tile.left    = {distance:0,realtive:false};
        tile.top     = {distance:0,realtive:false};
        tile.width   = {distance:(100*(tile.link.width/width)),realtive:true};
        tile.height  = {distance:(100*(tile.link.height/height)),realtive:true};
        //console.log("PRIMARY.2.width",width,"height",height,"extraWidth",extraWidth,"extraHeight",extraHeight,"link",{...tile.link},"tile",{...tile})
        let next     = 0;
        const inset  = right ? tile.width.distance : tile.height.distance;
        const extra1 = 100-inset;
        const extra2 = right ? extraHeight*extra1/extraWidth : extraWidth*extra1/extraHeight;
        for (let i=1, max=1+extraTiles; i<max; i++) {
          const tile  = result[i];
          tile.left   = { distance: right ? inset : next, realtive:true };
          tile.top    = { distance: right ? next : inset, realtive:true };
          tile.width  = { distance: right ? extra1 : 100*(extra1*tile.ratio)/extra2, realtive:true };
          tile.height = { distance: right ? 100*(extra1/tile.ratio)/extra2 : extra1, realtive:true };
          next += right ? tile.height.distance : tile.width.distance;
        }
        return {
          width:width,height:height,
          more:tiles.length>3 ? tiles.length-3 : undefined,
          tiles:result
        }
      }
    }
    return {
      width:undefined,
      height:undefined,
      more:undefined,
      tiles:[]
    }
  }

  getImageLink(media:Media):ImageLink {
    if (media.cover?.width>0 && media.cover.height>0) {
      return media.cover;
    } else if (media.mediaType=='video' || media.mediaType=='image') {
      return <ImageLink>media.links?.find(link=>link.width>0 && link.height>0);
    }
    return undefined;
  }

  onMore(event:UIEvent) {
    event.stopPropagation();
    event.preventDefault();
    const tileMedia = {};
    this.tiles?.forEach(tile=>tileMedia[tile.media.id]=tile.media);
    const attachment = this.mediaAttachments?.find(attachment=>tileMedia[attachment.media?.id]==undefined);
    if (!!attachment) {
      this.onPlayMedia(attachment.media);
    }
  }

  trackBy = (index: number, mediaTile: MediaTile) => {
    return mediaTile?.media?.id + mediaTile?.media?.version;
  };
}
