<div [class]="position+' '+mode">
  <attachment-section-container #attachmentSectionContainer *ngIf="attachments?.length>0"
    [factoryAccessor]="attachmentService.getViewerFactory.bind(attachmentService)"
    [message]="message"
    [attachments]="attachments"
    (updated)="updateSections(attachmentSectionContainer.attachmentSections)">
  </attachment-section-container>
  <div class="body">
    <div class="attachment" *ngIf="mode=='reply' && message.attachments?.length>0">
      <attachment-square-container [attachments]="message.attachments"></attachment-square-container>
    </div>
    <div class="content">
      <span *ngIf="!messageBody && mode=='reply'" translate>chat.conversation.attachment</span>
      <span *ngIf="!!translate" class="text" [innerHTML]="messageBody | translate | markup:{clean:mode=='reply'} | safeHtml"></span>
      <span *ngIf="!translate" class="text" [innerHTML]="messageBody | markup:{clean:mode=='reply'} | safeHtml"></span>
    </div>
  </div>
</div>
