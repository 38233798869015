<div *ngIf="initialized" [ngSwitch]="editMode">
  <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="view"></ng-container>
  <ng-container *ngSwitchCase="true"  [ngTemplateOutlet]="edit"></ng-container>
</div>
<!--{{'STATE'+(onStateChange | async | json)}}-->
<ng-template #view>
  <div @content class="view container">
    <ng-container *ngTemplateOutlet="mediaCardTemplate; context: { media: event.media, edit: false }"></ng-container>
    <div class="event-name" *ngIf="event.name">
      {{mediaService.replaceName(event.name)}}
    </div>

    <more-text *ngIf="event.info?.length" [text]="event.info" [align]="'center'"></more-text>

    <ng-container *ngLet="{formatDayDMY: 'events.formatDayDMY' | translate, formatDMY: 'events.formatDMY' | translate } as context">
      <div class="date-time" [ngSwitch]="sameDay(event.timeFrom,event.timeTo)">
        <div *ngSwitchCase="true" class="same-day">
          <div class="date">{{ event.timeFrom | date: context.formatDayDMY}}</div>
          <div class="time">
            <div>{{ event.timeFrom | date: 'LT'}}</div>
            <div class="separator">-</div>
            <div>{{ event.timeTo | date: 'LT'}}</div>
          </div>
        </div>
        <div *ngSwitchCase="false" class="days">
          <div>
            <div class="day">{{ event.timeFrom | date: 'dddd'}}</div>
            <div class="date">{{ event.timeFrom | date: context.formatDMY}}</div>
            <div class="time">
              <div>{{ event.timeFrom | date: 'LT'}}</div>
            </div>
          </div>
          <div class="separator">-</div>
          <div>
            <div class="day">{{ event.timeTo | date: 'dddd'}}</div>
            <div class="date">{{ event.timeTo | date: context.formatDMY}}</div>
            <div class="time">
              <div>{{ event.timeTo | date: 'LT'}}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="CalendarEventType.isOnline(event.type) && event.joinLink as joinLink" class="section joinLink">
      <div class="label" translate>events.joinLink</div>
      <a href="{{ joinLink }}" target="_blank">{{ joinLink }}</a>
    </div>

    <div *ngIf="CalendarEventType.isOffline(event.type)" class="location">
      <div class="label" translate>events.location</div>
      <div *ngIf="event.location as location">{{location}}</div>
      <div *ngIf="event.latitude && event.longitude">
        <a *ngIf="!showMap" (click)="showMap=true" translate>events.showInMap</a>
        <div *ngIf="showMap"
             [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '160px' }}"
             class="map-container">
          <map [markers]="[{ latitude: event.latitude, longitude: event.longitude }]" [disabled]="true"></map>
        </div>
      </div>
    </div>

    <div *ngIf="event.bookingLink as bookingLink" class="section joinLink">
      <div class="label" translate>events.bookingLink</div>
      <a href="{{ bookingLink }}" target="_blank">{{ bookingLink }}</a>
    </div>

    <form [formGroup]="attendeeForm" *ngIf="!attendeeSeatsRangeMap.closed" class="booking">
      <span class="label" translate>actions.attend</span>
      <ng-container *ngTemplateOutlet="seatsBookingTemplate; context: { eventType  : event.type,
                                                                             seats : event.seats,
                                                                            parent : attendeeForm,
                                                                             range : attendeeSeatsRangeMap,
                                                                        labelPrefix:'' }">
      </ng-container>
      <ng-container *ngIf="state$ | async; let state">
        <div class="accept-booking" *ngIf="state.dirty && state.valid">
          <span translate>events.confirmBooking</span>
          <button mat-mini-fab
                  type="button"
                  (click)="onAttendeeSeatsUpdate()"
                  aria-label="update attendee seats">
            <mat-icon>done</mat-icon>
          </button>
        </div>
      </ng-container>
    </form>

    <div class="label" translate>events.language</div>
    <ng-container *ngIf="event.language as languageCode">
      <div class="language">{{(('languages' | translate)||{})[languageCode]}}</div>
    </ng-container>

    <div class="label" translate>events.calendar.label</div>
    <ng-container *ngIf="event.calendar?.id as calendarId;">
      <ng-container *ngIf="((calendars$ | async) | filter: { id: calendarId }) as calendars">
        <div class="calendar">{{calendars.length ? calendars[0].name : undefined}}</div>
      </ng-container>
    </ng-container>


    <!--
    <mat-form-field *ngIf="event.calendar?.id as calendarId;" class="no-disabled-color">
      <ng-container *ngIf="((calendars$ | async) | filter: { id: calendarId }) as calendars">
        <input type="text"
               unmodified-input
               matInput
               [value]="calendars.length ? calendars[0].name : undefined"
               [placeholder]="'events.calendar.label' | translate"
               disabled/>
      </ng-container>
    </mat-form-field>
    <mat-form-field *ngIf="event.language as languageCode" class="no-disabled-color">
      <input type="text"
             unmodified-input
             matInput
             [value]="(('languages' | translate)||{})[languageCode]"
             [placeholder]="'events.language' | translate"
             disabled/>
    </mat-form-field>
    <mat-form-field *ngIf="event.name" class="no-disabled-color">
      <input type="text"
             unmodified-input
             matInput
             [value]="event.name"
             [placeholder]="'events.name' | translate"
             disabled/>
    </mat-form-field>
    <mat-form-field *ngIf="event.info" class="no-disabled-color">
      <textarea matInput
                unmodified-input
                [value]="event.info"
                [placeholder]="'events.info' | translate"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8"
                disabled>
      </textarea>
    </mat-form-field>
    -->

    <!--
    <mat-form-field class="no-disabled-color">
      <input type="text"
             unmodified-input
             matInput
             [value]="timeCreated(event) | date"
             [placeholder]="'events.dateCreated' | translate"
             disabled/>
    </mat-form-field>
    -->
    <!--
    <div class="type-section">
      <div class="type">
        <span class="mat-form-field-label" translate>events.type</span>
        <button type="button" mat-stroked-button [color]="'primary'" disabled disableRipple>
        <!-
          we have to use translate pipe instead of directive due an untranslated text being displayed in certain cases e.g.
          1. Click a on map cluster element containing events of different types
          2. Select an event from the list
          3. Back
          4. Select an event with different type
          5. Event details form shows untranslated event type (workaround -> using pipe)
          <span translate>{{'events.types.'+event.type}}</span>
        ->
          <span>{{('events.types.'+event.type) | translate}}</span>
        </button>
      </div>
      <mat-form-field *ngIf="CalendarEventType.isOnline(event.type)" class="joinLink" >
        <mat-icon matPrefix>link</mat-icon>
        <input type="text"
               unmodified-input
               matInput
               [value]="event.joinLink"
               [placeholder]="'events.joinLink' | translate"
               [attr.aria-label]="'join link'"
               disabled/>
      </mat-form-field>
      <div *ngIf="CalendarEventType.isOffline(event.type)" class="location">
        <mat-form-field>
          <mat-icon matPrefix>location_on</mat-icon>
          <input type="text"
                 unmodified-input
                 matInput
                 [value]="event.location || ''"
                 [placeholder]="'events.location' | translate"
                 [attr.aria-label]="'location'"
                 disabled/>
        </mat-form-field>
        <div *ngIf="event.latitude && event.longitude"
             [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '160px' }}"
             class="map-container">
          <map [markers]="[{ latitude: event.latitude, longitude: event.longitude }]" [disabled]="true"></map>
        </div>
      </div>
      <mat-form-field *ngIf="CalendarEventType.isOnline(event.type)">
        <mat-icon matPrefix>connected_tv</mat-icon>
        <input type="number"
               unmodified-input
               matInput
               [value]="event.seats?.online?.toString()"
               [placeholder]="'events.maxOnlineAttendees' | translate"
               disabled/>
      </mat-form-field>
      <mat-form-field *ngIf="CalendarEventType.isOffline(event.type)">
        <mat-icon matPrefix>event_seats</mat-icon>
        <input type="number"
               unmodified-input
               matInput
               [value]="event.seats?.offline?.toString()"
               [placeholder]="'events.maxOfflineAttendees' | translate"
               disabled/>
      </mat-form-field>
    </div>
     -->

    <!--
    <mat-form-field class="no-disabled-color">
      <input type="text"
             unmodified-input
             matInput
             [value]="event.timeFrom | date: 'LLL'"
             [placeholder]="'events.timeFrom' | translate"
             disabled/>
    </mat-form-field>
    <mat-form-field class="no-disabled-color">
      <input type="text"
             unmodified-input
             matInput
             [value]="event.timeTo | date: 'LLL'"
             [placeholder]="'events.timeTo' | translate"
             disabled/>
    </mat-form-field>
    -->

    <!--
    <form [formGroup]="attendeeForm" *ngIf="!attendeeSeatsRangeMap.closed">
      <span class="mat-form-field-label" translate>events.attendee.booking</span>
      <ng-container *ngTemplateOutlet="seatsBookingTemplate; context: { eventType: event.type,
                                                                 parent: attendeeForm,
                                                                 range: attendeeSeatsRangeMap,
                                                                 labelPrefix:'' }">
      </ng-container>
      <ng-container *ngIf="state$ | async; let state">
        <button *ngIf="state.dirty && state.valid"
                mat-mini-fab
                type="button"
                (click)="onAttendeeSeatsUpdate()"
                aria-label="update attendee seats">
          <mat-icon>done</mat-icon>
        </button>
      </ng-container>
    </form>
    -->
    <!--
    <mat-form-field class="no-disabled-color">
      <input type="text"
             unmodified-input
             matInput
             [value]="event.timeBookingFrom | date"
             [placeholder]="'events.timeBookingFrom' | translate"
             disabled/>
    </mat-form-field>
    <mat-form-field class="no-disabled-color">
      <input type="text"
             unmodified-input
             matInput
             [value]="event.timeBookingTo | date"
             [placeholder]="'events.timeBookingTo' | translate"
             disabled/>
    </mat-form-field>
    -->
    <ng-container *ngTemplateOutlet="controlsTemplate; context: { event: onValueChange | async }"></ng-container>
  </div>
</ng-template>

<ng-template #edit>
  <slide-panel [id]="panel" (onSlide)="onPanelSlide($event)" @content class="edit container">
    <!-- panels are scroll containers i.e. they have overflow-y: auto -->
    <div *slidePanelLeft="'main'" class="panel event">
      <form [formGroup]="eventForm">
      <!--div>{{'------FORM VALUE------'}}</div>
      {{eventForm.value | json}}
      <div>{{'------FORM ERRORS------'}}</div>
      {{'ERRORS:'+(eventForm.errors | json)}}
      {{'VALID:'+eventForm.valid}}
      {{'DIRTY:'+eventForm.dirty}}
      <div>{{'------LOCATION ERRORS------'}}</div>
      {{eventForm.controls.location.errors | json}}
      <div>{{'------JOIN LINK ERRORS------'}}</div>
      {{eventForm.controls.joinLink.errors | json}}
      <div>{{'------TIME FROM ERRORS------'}}</div>
      {{eventForm.controls.timeFrom.errors | json}}
      <div>{{'---------------------'}}</div-->
      <ng-container *ngTemplateOutlet="mediaCardTemplate; context: { media: eventForm.controls.media.value, edit: true }"></ng-container>
      <mat-form-field (click)="onCalendarSelect()" class="calendar selector">
        <mat-label translate>events.calendar.label</mat-label>
        <mat-icon matPrefix>date_range</mat-icon>
        <!--select *ngIf="calendars"
                matNativeControl
                formControlName="calendar"
                [placeholder]="'events.calendar.label' | translate"
                required
                aria-label="calendar">
          <ng-container *ngFor="let calendar of calendars | async">
            <option *ngIf="calendar" [value]="calendar.id">{{calendar.name}}</option>
          </ng-container>
        </select-->
        <input matInput
               type="text"
               unmodified-input
               required
               formControlName="calendarName"
               aria-label="calendar"/>
        <mat-icon matSuffix>arrow_right</mat-icon>
        <mat-hint *ngIf="canCreateCalendar" align="end">
          <a class="action" (click)="onCalendarCreate($event); $event.stopPropagation()" translate>events.actions.createCalendar</a>
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="hidden">
        <input matInput type="text" formControlName="calendarId"/>
      </mat-form-field>
      <mat-form-field (click)="onLanguageSelect()" class="language selector">
        <mat-label translate>events.filters.language</mat-label>
        <mat-icon matPrefix>language</mat-icon>
        <input matInput
               type="text"
               unmodified-input
               required
               formControlName="languageName"
               aria-label="language">
        <mat-icon matSuffix>arrow_right</mat-icon>
        <!--
        <mat-icon matPrefix>language</mat-icon>
        <select #languageSelect
                matNativeControl
                formControlName="language"
                aria-label="language"
                [placeholder]="'event.filters.language' | translate">
          <ng-container *ngIf="selectedLanguage">
            <option *ngFor="let language of [selectedLanguage]" [value]="language.code">
              {{language.name}}
            </option>
          </ng-container>
        </select>
        -->
      </mat-form-field>
      <mat-form-field class="hidden">
        <input matInput type="text" formControlName="languageCode"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label translate>events.name</mat-label>
        <input type="text"
               unmodified-input
               matInput
               formControlName="name"
               name="name"
               required
               aria-label="name"/>
        <mat-error *ngIf="eventForm.controls.name.invalid && eventForm.controls.name.touched">
          <span translate>events.validation.nameRequired</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label translate>events.info</mat-label>
        <textarea matInput
                  unmodified-input
                  formControlName="info"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="8"
                  aria-label="info">
          </textarea>
      </mat-form-field>

      <!--mat-checkbox #type
                    formControlName="type"
                    class="type"
                    aria-label="type"
                    (change)="$event.checked && eventForm.controls.seats.setValue(undefined)">
        <span translate>events.type</span>
      </mat-checkbox>

      <ng-container *ngIf="{ online: online.checked }; let state">
        <ng-container *ngIf="{ online: state.online,
                               type: state.online  ? 'joinLink'  : 'location',
                               icon: state.online  ? 'link'      : 'location_on',
                               label: state.online ? 'join link' : 'location'
                              }; let context">
        <mat-form-field>
          <mat-icon matPrefix>{{context.icon}}</mat-icon>
          <input type="text"
                 unmodified-input
                 matInput
                 formControlName="location"
                 name="location"
                 [placeholder]="'events.'+context.type | translate"
                 required
                 [attr.aria-label]="context.label"/>
          <mat-error *ngIf="eventForm.controls.location.invalid && eventForm.controls.location.touched">
            <span translate>{{'events.validation.'+context.type+'Required'}}</span>
          </mat-error>
        </mat-form-field>
        <div *ngIf="!context.online &&
                    event.latitude &&
                    event.longitude"
                    [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '160px' }}">
          <div class="map-container">
            <map formControlName="marker"></map>
          </div>
        </div>
        </ng-container>
      </ng-container-->

      <div *ngIf="{type: eventForm.controls.type.value} as context" class="type-section">
        <div class="type">
          <div class="mat-form-field-label" translate>events.type</div>
          <button *ngFor="let type of CalendarEventTypes"
                  type="button"
                  mat-stroked-button
                  [color]="context.type==type ? 'primary' : undefined"
                  (click)="onTypeSelect(type, context.type)">
            <span translate>{{'events.types.'+type}}</span>
          </button>
        </div>
        <adjustable-container [trigger]="context.type">
          <mat-form-field *ngIf="CalendarEventType.isOnline(context.type)" class="joinLink" >
            <mat-label translate>events.joinLink</mat-label>
            <mat-icon matPrefix>link</mat-icon>
            <input type="text"
                   unmodified-input
                   matInput
                   name="joinLink"
                   formControlName="joinLink"
                   required
                   [attr.aria-label]="'join link'"/>
            <mat-error *ngIf="eventForm.controls.joinLink.invalid && eventForm.controls.joinLink.touched">
              <span translate>{{'events.validation.joinLinkRequired'}}</span>
            </mat-error>
          </mat-form-field>
          <div *ngIf="CalendarEventType.isOffline(context.type)" class="location">
            <mat-form-field>
              <mat-label translate>events.location</mat-label>
              <mat-icon matPrefix>location_on</mat-icon>
              <input type="text"
                     unmodified-input
                     matInput
                     name="location"
                     formControlName="location"
                     required
                     [attr.aria-label]="'location'"/>
              <mat-error *ngIf="eventForm.controls.location.invalid && eventForm.controls.location.touched">
                <span translate>{{'events.validation.locationRequired'}}</span>
              </mat-error>
            </mat-form-field>
            <!--div class="connector">
              <mat-slide-toggle formControlName="geocodingEnabled" aria-label="geocoding enabled">
                Enable geocoding
              </mat-slide-toggle>
            </div-->
            <mat-button-toggle #toggle
                               [checked]="eventForm.controls.geocodingEnabled.value"
                               (change)="onChangeGeocoding($event.source.checked)"
                               class="connector"
                               [class.open]="!eventForm.controls.geocodingEnabled.value"
                               aria-label="enable geocoding">
              <mat-icon>{{ 'lock' + (toggle.checked ? '_outline' : '_open')}}</mat-icon>
            </mat-button-toggle>
<!--          <div *ngIf="event.latitude && event.longitude" [@enterLeaveHeightAnimation]="{ value:':enter, :leave', params : { height: '160px' }}">-->
            <div *ngIf="event.latitude && event.longitude" class="map-container">
              <map formControlName="marker"></map>
            </div>
<!--          </div>-->
          </div>
          <mat-form-field class="bookingLink" >
            <mat-label translate>events.bookingLink</mat-label>
            <mat-icon matPrefix>link</mat-icon>
            <input type="text"
                   unmodified-input
                   matInput
                   name="bookingLink"
                   formControlName="bookingLink"
                   [attr.aria-label]="'booking link'"/>
            <!--mat-error *ngIf="eventForm.controls.bookingLink.invalid && eventForm.controls.bookingLink.touched">
              <span translate>{{'events.validation.bookingLinkRequired'}}</span>
            </mat-error-->
          </mat-form-field>
          <ng-container *ngTemplateOutlet="seatsTemplate; context: { eventType: context.type,
                                                                     parent: eventForm.controls.seats,
                                                                     range: eventSeatsRangeMap,
                                                                     labelPrefix: 'total.'}"
                        formGroupName="seats">
          </ng-container>
        </adjustable-container>
      </div>

      <mat-form-field>
        <mat-label translate>events.timeFrom</mat-label>
        <input matInput
               formControlName="timeFrom"
               required
               [min]="timeFromMin"
               [max]="timeFromMax"
               [ngxMatDatetimePicker]="timeFromPicker"
               [errorStateMatcher]="dateTimeErrorStateMatcher"
               (dateChange)="onTimeFromChange($event)"
               aria-label="time from"/>
        <mat-datepicker-toggle matSuffix [for]="timeFromPicker">
          <mat-icon matDatepickerToggleIcon>today</mat-icon>
        </mat-datepicker-toggle>
        <ngx-mat-datetime-picker #timeFromPicker
                                 [showSpinners]="true"
                                 [showSeconds]="false"
                                 [stepHour]="1"
                                 [stepMinute]="1"
                                 [touchUi]="platform.is('mobile')"
                                 [color]="'primary'"
                                 [enableMeridian]="false"
                                 [disableMinute]="false"
                                 [hideTime]="false">
          <ngx-mat-datepicker-actions>
            <button mat-button ngxMatDatepickerCancel><span translate>actions.cancel</span></button>
            <button mat-raised-button color="primary" ngxMatDatepickerApply><span translate>actions.ok</span></button>
          </ngx-mat-datepicker-actions>
        </ngx-mat-datetime-picker>
        <mat-error *ngIf="eventForm.controls.timeFrom.invalid && eventForm.controls.timeFrom.touched">
          <span translate>events.validation.timeFromRequired</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label translate>events.timeTo</mat-label>
        <input matInput
               formControlName="timeTo"
               required
               [min]="timeToMin"
               [max]="timeToMax"
               [ngxMatDatetimePicker]="timeToPicker"
               [errorStateMatcher]="dateTimeErrorStateMatcher"
               (dateChange)="onTimeToChange($event)"
               aria-label="time to"/>
        <mat-datepicker-toggle matSuffix [for]="timeToPicker">
          <mat-icon matDatepickerToggleIcon>event</mat-icon>
        </mat-datepicker-toggle>
        <ngx-mat-datetime-picker #timeToPicker
                                 [showSpinners]="true"
                                 [showSeconds]="false"
                                 [stepHour]="1"
                                 [stepMinute]="1"
                                 [touchUi]="platform.is('mobile')"
                                 [color]="'primary'"
                                 [enableMeridian]="false"
                                 [disableMinute]="false"
                                 [hideTime]="false">
          <ngx-mat-datepicker-actions>
            <button mat-button ngxMatDatepickerCancel><span translate>actions.cancel</span></button>
            <button mat-raised-button color="primary" ngxMatDatepickerApply><span translate>actions.ok</span></button>
          </ngx-mat-datepicker-actions>
        </ngx-mat-datetime-picker>
<!--        <ng-container *ngIf="eventForm.controls.timeTo.touched">-->
          <mat-error *ngIf="eventForm.controls.timeTo.hasError('required') && eventForm.controls.timeTo.touched">
            <span translate>events.validation.timeToRequired</span>
          </mat-error>
          <mat-error *ngIf="eventForm.controls.timeTo.hasError('matDatetimePickerMin')">
            <span translate>events.validation.timeToMin</span>
          </mat-error>
<!--        </ng-container>-->
      </mat-form-field>
      <div class="timezones">
        <mat-expansion-panel #timezonesPanel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span translate>events.timezone</span>
            </mat-panel-title>
            <mat-panel-description class="timezone">
              <span class="selection-indicator"></span>
              <span>{{selectedTimezone}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!--mat-list @timezones-->
          <mat-list>
            <ng-container *ngFor="let timezone of eventForm.controls.timezones.value; let index = index; let first = first">
              <ng-container *ngIf="{ selected: timezone==selectedTimezone } as context">
                <mat-list-item @timezone [class.selected]="context.selected" (click)="onTimezoneChanged(timezone)">
                  <div class="timezone">
                    <span *ngIf="context.selected" class="selection-indicator"></span>
                    <span class="name" translate><span>{{timezone}}</span></span>
                    <button *ngIf="timezone!=localTimezone && timezone!=eventForm.controls.timezone.value"
                            mat-icon-button
                            (click)="$event.stopPropagation(); onTimezoneRemove(timezone)"
                            class="timezone-remove">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </div>
                  <mat-divider *ngIf="first"></mat-divider>
                </mat-list-item>
              </ng-container>
            </ng-container>
          </mat-list>
        </mat-expansion-panel>
        <a class="action timezone-select" (click)="onTimezoneSelect($event)" translate>events.actions.addTimezone</a>
      </div>
      <div class="more">
        <mat-expansion-panel #morePanel class="mat-elevation-z0" [hideToggle]="true">
          <mat-expansion-panel-header matRipple [matRippleDisabled]="true">
            <mat-panel-title>
              <span [@indicatorRotate]="morePanel.expanded ? 'expanded' : 'collapsed'" class="mat-expansion-indicator"></span>
              <span [innerHTML]="('events.details.'+(morePanel.expanded ? 'less' : 'more')) | translate | safeHtml"></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label translate>events.timeBookingFrom</mat-label>
            <!--      <mat-icon matPrefix>today</mat-icon>-->
            <input #timeBookingFrom
                   matInput
                   formControlName="timeBookingFrom"
                   readonly
                   [min]="timeBookingFromMin"
                   [max]="timeBookingFromMax"
                   [matDatepicker]="timeBookingFromPicker"
                   [matDatepickerFilter]="timeBookingFromFilter"
                   [errorStateMatcher]="dateTimeErrorStateMatcher"
                   (dateChange)="onTimeBookingFromChange($event)"
                   aria-label="date booking from"/>
            <!--      <button *ngIf="timeBookingFrom.value" mat-icon-button matSuffix aria-label="clear" (click)="timeBookingFromPicker.select(undefined)">-->
            <!--        <mat-icon>close</mat-icon>-->
            <!--      </button>-->
            <mat-datepicker-toggle matSuffix [for]="timeBookingFromPicker">
              <mat-icon matDatepickerToggleIcon>event_available</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #timeBookingFromPicker></mat-datepicker>
            <mat-error *ngIf="eventForm.controls.timeBookingFrom.hasError('required') && eventForm.controls.timeBookingFrom.touched">
              <span translate>events.validation.timeBookingFromRequired</span>
            </mat-error>
            <mat-error *ngIf="eventForm.controls.timeBookingFrom.hasError('matDatepickerMax')">
              <span translate>events.validation.timeBookingFromMax</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label translate>events.timeBookingTo</mat-label>
            <!--      <mat-icon matPrefix>event</mat-icon>-->
            <input #timeBookingTo
                   matInput
                   formControlName="timeBookingTo"
                   readonly
                   [min]="timeBookingToMin"
                   [max]="timeBookingToMax"
                   [matDatepicker]="timeBookingToPicker"
                   [matDatepickerFilter]="timeBookingToFilter"
                   [errorStateMatcher]="dateTimeErrorStateMatcher"
                   (dateChange)="onTimeBookingToChange($event)"
                   aria-label="date booking to"/>
            <!--      <button *ngIf="timeBookingTo.value" mat-icon-button matSuffix aria-label="clear" (click)="timeBookingTo.select(undefined)">-->
            <!--        <mat-icon>close</mat-icon>-->
            <!--      </button>-->
            <mat-datepicker-toggle matSuffix [for]="timeBookingToPicker">
              <mat-icon matDatepickerToggleIcon>event_busy</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #timeBookingToPicker></mat-datepicker>
            <mat-error *ngIf="eventForm.controls.timeBookingTo.hasError('required') && eventForm.controls.timeBookingTo.touched">
              <span translate>events.validation.timeBookingToRequired</span>
            </mat-error>
            <mat-error *ngIf="eventForm.controls.timeBookingTo.hasError('matDatepickerMin')">
              <span translate>events.validation.timeBookingToMin</span>
            </mat-error>
            <mat-error *ngIf="eventForm.controls.timeBookingTo.hasError('matDatepickerMax')">
              <span translate>events.validation.timeBookingToMax</span>
            </mat-error>
          </mat-form-field>
          <div class="section countries">
            <h4 class="header" matSubheader disabled translate>events.countries</h4>
            <mat-checkbox formControlName="countryCodesExcluded" aria-label="excluded countries">
              <span translate>actions.filter.exclude</span>
            </mat-checkbox>
            <app-country-select formControlName="countryCodes" [countries]="countries$"></app-country-select>
          </div>
          <ng-container *ngFor="let term of ['editors', 'viewers', 'attendees']">
            <mat-form-field *ngIf="{ label: 'events.terms.'+term | translate } as context">
                <mat-icon matPrefix>{{term=='editors' ? 'edit' : term=='viewers' ? 'preview' : 'person_add'}}</mat-icon>
                <mat-label>{{context.label}}</mat-label>
                <input type="text"
                       unmodified-input
                       matInput
                       [name]="term+'Term'"
                       [formControlName]="term+'Term'"
                       [attr.aria-label]="term+' term'"/>
            </mat-form-field>
          </ng-container>
        </mat-expansion-panel>
      </div>
      <ng-container *ngTemplateOutlet="controlsTemplate; context: { event: event }"></ng-container>
    </form>
    </div>
    <div *slidePanelRightLazy="'*'" class="panel selectors">
      <ng-container [ngSwitch]="selector">
        <app-calendar-list *ngSwitchCase="'calendars'"
                           [entities$]="calendars$"
                           [multiple]="false"
                           (selectionChange)="onCalendarSelectorSelectionChange($event)"
                           class="selector">
          <ng-template #controlsTemplate></ng-template>
        </app-calendar-list>
        <app-language-selector *ngSwitchCase="'languages'"
                               [languages$]="languages$"
                               [lru$]="languageLru$"
                               [searchTerm$]="languageSearchTerm$"
                               [currentLanguage]="translateService.currentLang"
                               (selectionChange)="onLanguageSelectorSelectionChange($event)"
                               (lruChange)="onLanguageSelectorLruChange($event)"
                               class="selector">
        </app-language-selector>
        <timezone-selector *ngSwitchCase="'timezones'"
                           [timezones$]="timezones$"
                           [lru$]="timezoneLru$"
                           [currentTimezone]="localTimezone"
                           (selectionChange)="onTimezoneSelectorSelectionChange($event)"
                           (lruChange)="onTimezoneSelectorLruChange($event)"
                           class="selector">
        </timezone-selector>
        <ng-container *ngSwitchCase="'media'">
          <media-selection-list [entities$]="mediaService.entities$"
                                [user$]="propertiesService.user$"
                                (selected)="onMediaSelectorSelectionChange($event)"
                                (play)="onMediaPlay($event)"
                                class="selector">
          </media-selection-list>
          <button mat-raised-button
                  color="primary"
                  type="button"
                  (click)="onMediaUpload()">
            <span translate>actions.upload</span>
          </button>
        </ng-container>
      </ng-container>
      <button mat-raised-button
              color="primary"
              type="button"
              (click)="panel='main'">
        <span translate>actions.cancel</span>
      </button>
    </div>
  </slide-panel>
</ng-template>

<ng-template #seatsTemplate let-eventType="eventType" let-parent="parent" let-range="range" let-labelPrefix="labelPrefix">
  <ng-template #seatsInputTemplate let-type="type" let-icon="icon">
    <ng-container *ngIf="{ label: ('events.attendee.seats.'+labelPrefix+type | translate),
                           control: parent.controls[type] } as context">
      <!--
      {{'HAS ERROR: '+context.control.hasError('min')}}
      {{'ERRORS: '+context.control.errors}}
      {{'TOUCHED: '+context.control.touched}}
      -->
      <mat-form-field class="seats">
        <mat-icon matPrefix>{{icon}}</mat-icon>
        <!--
          https://www.javaer101.com/en/article/37160598.html
          We have to use formControl instead formControlName attribute because the template interferes
          with form control elements hierarchy processing and leads to the following error:
          formControlName must be used with a parent formGroup directive.
          You'll want to add a formGroup directive and pass it an existing FormGroup instance (you can create one in your class)
        -->
        <number-input #input
                      [formControl]="context.control"
                      [placeholder]=""
                      [attr.aria-label]="context.label">
        </number-input>
        <div matSuffix>
          <ng-template #seatsButtonTemplate let-range="range" let-delta="delta" let-icon="icon">
            <!-- stop click event propagation prevents the input to be focused after each button click -->
<!--            <button mat-icon-button-->
<!--                    (click)="$event.stopPropagation()"-->
<!--                    (onPressStart)="onSeatsChange(context.control, delta)"-->
<!--                    (onPressHold)="onSeatsChange(context.control, delta)">-->
<!--              <mat-icon>{{icon}}</mat-icon>-->
<!--            </button>-->
            <ng-container *ngIf="{value: !!context.control.value && (range.min > context.control.value + delta || context.control.value + delta > range.max) ||
                                          !context.control.value && delta < 0
                                  } as disabled">
              <mat-icon matRipple
                        [matRippleDisabled]="disabled.value"
                        (click)="$event.stopPropagation()"
                        (onPressing)="onSeatsChange(context.control, delta, range)"
                        [class.disabled]="disabled.value">
                {{icon}}
              </mat-icon>
            </ng-container>
          </ng-template>
          <ng-container *ngTemplateOutlet="seatsButtonTemplate; context: {range: range[type], delta: 1, icon: 'add'}"></ng-container>
          <ng-container *ngTemplateOutlet="seatsButtonTemplate; context: {range: range[type], delta: -1, icon: 'remove'}"></ng-container>
          <mat-divider [vertical]="true"></mat-divider>
        </div>
        <mat-label>{{context.label}}</mat-label>
        <mat-error *ngIf="context.control.hasError('min')">
          <span translate>{{'events.attendee.seats.'+labelPrefix+'validation.seatsMin'}}</span>
        </mat-error>
        <mat-error *ngIf="context.control.hasError('max')">
          <span translate>{{'events.attendee.seats.'+labelPrefix+'validation.seatsMax'}}</span>
        </mat-error>
        <!-- should not happen -->
        <!--mat-error *ngIf="context.control.hasError('pattern')">
          <span translate>events.validation.seatsDigit</span>
        </mat-error-->
      </mat-form-field>
    </ng-container>
  </ng-template>
  <ng-container *ngIf="CalendarEventType.isOnline(eventType)"
                [ngTemplateOutlet]="seatsInputTemplate"
                [ngTemplateOutletContext]="{type: 'online', icon: 'connected_tv'}">
  </ng-container>
  <ng-container *ngIf="CalendarEventType.isOffline(eventType)"
                [ngTemplateOutlet]="seatsInputTemplate"
                [ngTemplateOutletContext]="{type: 'offline', icon: 'event_seat'}">
  </ng-container>
</ng-template>

<ng-template #seatsBookingTemplate let-eventType="eventType" let-seats="seats" let-parent="parent" let-range="range" let-labelPrefix="labelPrefix">
  <ng-template #seatsInputTemplate let-type="type" let-seats="seats" let-icon="icon">
    <ng-container *ngIf="{ label: ('events.attendee.seats.'+labelPrefix+type | translate),
                           control: parent.controls[type] } as context">
      <!--
      {{'HAS ERROR: '+context.control.hasError('min')}}
      {{'ERRORS: '+context.control.errors}}
      {{'TOUCHED: '+context.control.touched}}
      -->
      <div class="booking-control">
        <button *ngIf="true"
                mat-raised-button
                color="primary"
                type="button"
                (click)="onSeatsChange(context.control, 1, range[type])">
          <mat-icon>{{icon}}</mat-icon>
          <span>{{context.label}}</span>
        </button>
        <div class="seats">
          <ng-template #seatsButtonTemplate let-range="range" let-delta="delta" let-icon="icon">
            <!-- stop click event propagation prevents the input to be focused after each button click -->
            <!--            <button mat-icon-button-->
            <!--                    (click)="$event.stopPropagation()"-->
            <!--                    (onPressStart)="onSeatsChange(context.control, delta)"-->
            <!--                    (onPressHold)="onSeatsChange(context.control, delta)">-->
            <!--              <mat-icon>{{icon}}</mat-icon>-->
            <!--            </button>-->
            <ng-container *ngIf="{value: !!context.control.value && (range.min > context.control.value + delta || context.control.value + delta > range.max) ||
                                              !context.control.value && delta < 0
                                      } as disabled">
              <mat-icon matRipple
                        [matRippleDisabled]="disabled.value"
                        (click)="$event.stopPropagation()"
                        (onPressing)="onSeatsChange(context.control, delta, range)"
                        [class.disabled]="disabled.value">
                {{icon}}
              </mat-icon>
            </ng-container>
          </ng-template>
          <ng-container *ngTemplateOutlet="seatsButtonTemplate; context: {range: range[type], delta: 1, icon: 'add'}"></ng-container>
          <div class="value">{{context.control.value ?? 0}}</div>
          <ng-container *ngTemplateOutlet="seatsButtonTemplate; context: {range: range[type], delta: -1, icon: 'remove'}"></ng-container>
        </div>
      </div>
      <mat-error *ngIf="context.control.hasError('min')">
        <span translate>{{'events.attendee.seats.'+labelPrefix+'validation.seatsMin'}}</span>
      </mat-error>
      <mat-error *ngIf="context.control.hasError('max')">
        <span translate>{{'events.attendee.seats.'+labelPrefix+'validation.seatsMax'}}</span>
      </mat-error>
        <!-- should not happen -->
        <!--mat-error *ngIf="context.control.hasError('pattern')">
          <span translate>events.validation.seatsDigit</span>
        </mat-error-->
      <div class="label">
        {{ 'events.attendee.seats.count' | translate:{ booked: seats.total - seats.available, total: seats.total } }}
      </div>
    </ng-container>
  </ng-template>
  <ng-container *ngIf="CalendarEventType.isOnline(eventType)"
                [ngTemplateOutlet]="seatsInputTemplate"
                [ngTemplateOutletContext]="{type: 'online',
                                            seats: { total: seats.total.online, available: seats.available.online },
                                            icon: 'connected_tv'}">
  </ng-container>
  <ng-container *ngIf="CalendarEventType.isOffline(eventType)"
                [ngTemplateOutlet]="seatsInputTemplate"
                [ngTemplateOutletContext]="{type: 'offline',
                                            seats: { total: seats.total.offline, available: seats.available.offline },
                                            icon: 'event_seat'}">
  </ng-container>
</ng-template>

<ng-template #mediaCardTemplate let-media="media" let-edit="edit">
  <media-card *ngIf="media"
              [media]="media"
              [elevated]="false"
              [selectable]="false"
              (play)="onMediaPlay(media)">
    <div *ngIf="edit" mediaCardElement [position]="['bottom', 'right']">
      <button *ngIf="media!=(defaultMedia | async)" mat-icon-button
              (click)="onMediaRemove(media)"
              class="delete mat-accent">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button
              (click)="onMediaSelect(media)"
              class="edit mat-primary">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </media-card>
</ng-template>
