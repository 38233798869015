<div class="reach" *ngIf="reachFilters?.length>0">
    <app-contact-reach
    [contact]="contact"
    [circles]="propertiesService.group?.reachCircles ?? 5"
    [filters]="reachFilters"
    [valuesArray]="reachValues$ | async"
    (onFilter)="onReachFilter($event)"></app-contact-reach>
</div>
<ng-container *ngIf="propertiesService.user?.isVerifiedMember && !!contact?.id">
  <div *ngLet="getPresence$(contact.id,contact?.app?.presence) | async as presence">
    <div *ngIf="presence?.timeActive>0" class="last-access">
      <h4 translate>session.lastAccess</h4>
      <div class="app" *ngIf="!!presence?.timeDevice">
        <div [class.week]="isActive(presence.timeDevice,7)" [class.days]="isActive(presence.timeDevice,2)" [class.warning]="(presence?.devices??0)>3">
          <mat-icon>phone_android</mat-icon>
          <div class="warning" *ngIf="(presence?.devices??0)>3">{{presence.devices}}</div>
          <div class="push" *ngIf="presence.pushDevices>0">{{presence.pushDevices}}</div>
        </div>
        <span>{{time(presence.timeDevice) | async}}</span>
      </div>
      <div class="app" *ngIf="!!presence?.timeBrowser">
        <div [class.week]="isActive(presence.timeBrowser,7)" [class.days]="isActive(presence.timeBrowser,2)"  [class.warning]="((presence?.sessions??0)-(presence?.devices??0))>=6">
          <mat-icon>tv</mat-icon>
          <div class="warning" *ngIf="((presence?.sessions??0)-(presence?.devices??0))>=6">{{(presence.sessions-(presence?.devices??0))}}</div>
        </div>
        <span>{{time(presence.timeBrowser) | async}}</span>
      </div>
    </div>
  </div>
</ng-container>
