import {Injectable} from "@angular/core";
import {CorrelationIdGenerator, EntityService, SimpleFilterService, Logger, SearchService, VersionedId} from "core";

import {BehaviorSubject, Observable, ReplaySubject, Subscription} from "rxjs";
import {Action, ActionsSubject, select, Store} from "@ngrx/store";
import {MessageEnvelope, MessageHandlerRegistry, MessagingService} from "messaging";
import {PropertiesService} from "properties";
import {TranslateService} from "@ngx-translate/core";
import {
  selectAttendee,
  selectAttendeeCacheId,
  selectAttendeeEntities,
  selectAttendeeEntitiesLength,
  selectAttendeeFilters,
  selectAttendeeSearchTerm,
  selectAttendeesSubscribed
} from "../store/attendee/reducers";
import {
  attendeeLoadRequestAction,
  attendeeSubscribeAction,
  attendeeUnsubscribeAction,
  AttendeeUpdateAction,
  attendeeUpdateFailureAction,
  attendeeUpdateFilterAction,
  attendeeUpdateSearchTermAction,
  attendeeUpdateSuccessAction
} from "../store/attendee/actions";
import {debounceTime, distinctUntilChanged, finalize, map, tap} from "rxjs/operators";
import moment from "moment";
import isEqual from "lodash/isEqual";
import {
  Attendee,
  AttendeeSubscriptionMessage,
  AttendeeSubscriptionMessageType,
  AttendeeUpdateMessage,
  AttendeeUpdateMessageType
} from "../models/attendee";
import {StoreService} from "store";

@Injectable({
  providedIn: 'root'
})
export class AttendeeService extends StoreService implements SearchService, SimpleFilterService , EntityService<Attendee> {

  protected entities: Observable<Attendee[]>;
  protected size: Observable<number>;
  protected sections: Observable<number[]>;
  protected searchTerm: Observable<string>;
  protected filters: Observable<string[]>;

  protected eventId: string;

  protected messageHandlerRegistry = new MessageHandlerRegistry();
  protected attendeeSubscription: Subscription;

  protected logger = new Logger('AttendeeService').setSilent(true);

  constructor(protected store$: Store<any>,
              protected action$: ActionsSubject,
              protected messagingService: MessagingService,
              protected propertiesService: PropertiesService,
              protected translateService: TranslateService,
              protected correlationIdGenerator: CorrelationIdGenerator) {
    super(store$, action$);
    this.messagingService.open$.pipe().subscribe(open => {
      this.attendeeSubscription?.unsubscribe();
      this.attendeeSubscription = undefined;
      if (open) {
        this.attendeeSubscription = this.store$.select(selectAttendeesSubscribed)
          .pipe(
            debounceTime(30),
            distinctUntilChanged((keys1:VersionedId[],keys2:VersionedId[])=>isEqual(keys1,keys2))
          )
          .subscribe(versionedIds => {
            this.messagingService.sendMessage(this.messagingService.initializeMessage(<AttendeeSubscriptionMessage>{
              type: AttendeeSubscriptionMessageType,
              subscribed: versionedIds ?? []
            }));
          });
      }
    });
    let handleAttendeeUpdateMessage = (envelope: MessageEnvelope):void => {
      let message = <AttendeeUpdateMessage>envelope.message;
      this.store$.dispatch(attendeeUpdateSuccessAction({ attendee: message.attendee }));
    };
    this.messageHandlerRegistry.addMessageHandler(AttendeeUpdateMessageType,handleAttendeeUpdateMessage);
    this.messagingService
      .register(envelope => this.messageHandlerRegistry.hasMessageHandler(envelope.message?.type))
      .subscribe(envelope => {
        this.messageHandlerRegistry.getMessageHandler(envelope.message.type)(envelope);
      });
    this.translateService.onLangChange.subscribe(() => {
      this.eventId && this.loadRequest(this.eventId);
    });
  }

  loadRequest(eventId: string): void {
    this.logger.debug('loadRequest');
    this.eventId = eventId;
    this.store$.dispatch(attendeeLoadRequestAction({eventId}));
  }

  get cacheId$() : Observable<string> {
    return this.store$.select(selectAttendeeCacheId);
  }

  get entities$(): Observable<Attendee[]> {
    if (!this.entities) {
      const entities$ = new ReplaySubject<Attendee[]>(1);
      this.store$
        .pipe(
          select(selectAttendeeEntities),
          // tap(entities => this.logger.debug('ENTITIES', entities))
        )
        .subscribe(entities => entities$.next(entities));
      this.entities = entities$;
    }
    return this.entities;
  }

  get sections$(): Observable<number[]> {
    if (!this.sections) {
      const sections$ = new BehaviorSubject<number[]>([]);
      this.entities$
        .pipe(
          map(events =>
            // NOTE: events is proxy to the backing array!
            // It could return null when accessing events at certain indexes
            // (and trigger loading of the corresponding segment from the server)
            // section indexes are calculated based solely on the currently loaded entities
            ((<any>events).backingArray || [])
              .map((event, index) => ({index: index, date: moment(event?.timeFrom)}))
              .filter((dateIndex, index, list) => {
                // this.logger.debug('SECTION', dateIndex, dateIndex.date.month(), index>0 && list[index-1].date.month());
                return index == 0 || dateIndex.date.month() != list[index - 1].date.month();
              })
              .map((dateIndex, index) => dateIndex.index)
          ),
          distinctUntilChanged((previous, current) => {
            return previous == current ||
              previous && current &&
              previous.length == current.length &&
              previous.every(f => current.includes(f))
          })
        ).subscribe(sections => sections$.next(sections));
      this.sections = sections$;
    }
    return this.sections;
  }

  get size$(): Observable<number> {
    if (!this.size) {
      const size$ = new ReplaySubject<number>(1);
      this.store$
        .pipe(
          select(selectAttendeeEntitiesLength),
          tap(size => this.logger.debug('SIZE', size))
        )
        .subscribe(length => size$.next(length));
      this.size = size$;
    }
    return this.size;
  }

  get filters$(): Observable<string[]> {
    if (!this.filters) {
      const filters$ = new ReplaySubject<string[]>(1);
      this.store$
        .pipe(
          select(selectAttendeeFilters),
          tap(filters => this.logger.debug('FILTERS', filters))
        )
        .subscribe(filters => filters$.next(filters));
      this.filters = filters$;
    }
    return this.filters;
  }

  get searchTerm$(): Observable<string> {
    if (!this.searchTerm) {
      const searchTerm$ = new ReplaySubject<string>();
      this.store$
        .pipe(
          select(selectAttendeeSearchTerm),
          distinctUntilChanged((a, b) => isEqual(a, b)),
          tap(searchTerm => this.logger.debug('SEARCH TERM', searchTerm))
        )
        .subscribe(searchTerm => searchTerm$.next(searchTerm));
      this.searchTerm = searchTerm$;
    }
    return this.searchTerm;
  }

  updateFilter(filters: string[]): void {
    //this.logger.debug('updateFilter', filters);
    this.store$.dispatch(attendeeUpdateFilterAction({filters: filters}));
  }

  updateSearchTerm(term: string): void {
    this.store$.dispatch(attendeeUpdateSearchTermAction({term: term}));
  }

  getAttendee$(id: string, defaultAttendee?: Attendee): Observable<Attendee> {
    let subscribed = false;
    let ensureSubscription = (attendee:Attendee) => {
      if (!subscribed) {
        subscribed = true;
        this.dispatch(attendeeSubscribeAction({ id }));
      }
    }
    return this.store$.select(selectAttendee(id)).pipe(
      distinctUntilChanged((attendee1: Attendee, attendee2: Attendee)=>isEqual(attendee1, attendee2)),
      finalize(() => {
        if (subscribed) {
          subscribed = false;
          window.setTimeout(()=>
              this.store$.dispatch(attendeeUnsubscribeAction({ id })),
            1000
          );
        }
      }),
      tap(attendee => ensureSubscription(attendee)),
      map(attendee => attendee ?? defaultAttendee)
    );
  }

  save(attendee: Attendee, previous?: Attendee): Promise<Attendee> {
    if (attendee) {
      const correlationId = this.correlationIdGenerator.next();
      const promise = new Observable<any>(subscriber => {
        const reducer = `saveAttendee_${correlationId}`;
        this.store$.addReducer(reducer, (state, action: { attendee: Attendee, error?: any, correlationId?: string } & Action) => {
          if (action.type == attendeeUpdateSuccessAction.type  ||
            action.type == attendeeUpdateFailureAction.type) {
            console.debug(reducer, state, action);
            if (action.correlationId == correlationId) {
              if (action.type == attendeeUpdateSuccessAction.type) {
                // this.loadRequest();
                subscriber.next(new Attendee(action.attendee));
              } else {
                subscriber.error(action.error);
              }
              subscriber.complete();
              this.store$.removeReducer(reducer);
            }
          }
          return state;
        });
      }).toPromise();
      this.logger.debug({event, previous});
      this.store$.dispatch(new AttendeeUpdateAction(attendee, previous, correlationId));
      return promise;
    } else {
      return Promise.reject('Invalid event');
    }
  }
}
