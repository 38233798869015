import {Action, createAction, props} from "@ngrx/store";
import {Task} from "../../models/task";

export const DEFAULT_SEGMENT_SIZE = 25;

export enum TaskActionIds {
  CREATE             = "task.create",
  UPDATE             = "task.update",
  UPDATE_SUCCESS     = "task.update.success",
  UPDATE_FAILURE     = "task.update.failure",
  DELETE             = "task.delete",
  DELETE_SUCCESS     = "task.delete.success",
  DELETE_FAILURE     = "task.delete.failure"
}

export enum TaskDraftActionIds {
  UPDATE = "task.draft.update",
}

export class TaskUpdateAction implements Action {
  readonly type = TaskActionIds.UPDATE;
  constructor(public task: Task,
              public previous?: Task,
              public correlationId?: string) {
    if (!this.correlationId) {
      this.correlationId = this.task.id;
    }
  }
}

export const taskUpdateSuccessAction = createAction(
  TaskActionIds.UPDATE_SUCCESS,
  props<{
    readonly task: Task,
    readonly correlationId?: string
  }>());
export const taskUpdateFailureAction = createAction(TaskActionIds.UPDATE_FAILURE, props<{ task: Task, reason: any, correlationId: string }>());

export const taskDeleteAction = createAction(TaskActionIds.DELETE, props<{ task: Task }>());
export const taskDeleteSuccessAction = createAction(TaskActionIds.DELETE_SUCCESS, props<{ task: Task }>());
export const taskDeleteFailureAction = createAction(TaskActionIds.DELETE_FAILURE, props<{ task: Task, reason: any }>());
