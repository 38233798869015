export class HasTranslations {

  translations?: string[];

  hasTranslations(): boolean {
    return this.translations?.length > 0;
  }

  getTranslations(): string[] {
    return this.translations;
  }
}
