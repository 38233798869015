<mat-card appearance="outlined" class="card" [style.opacity]="opacity">
  <mat-card-header>
<!--    <mat-icon role="img" aria-hidden="true">motorcycle</mat-icon>-->
    <span class="title">{{('app.name' | translate)+' '+product.title}}</span>
    <span class="title">{{product.description}}</span>
  </mat-card-header>
  <mat-card-content [ngSwitch]="!!paymentMethod">
    <div #page *ngSwitchCase="false" [@pageActivation] class="page payment-method">
      <span translate>purchase.selectMethod</span>
      <div>
        <button *ngFor="let paymentMethod of ['creditCard', 'sepa']" mat-raised-button color="primary" (click)="onPaymentMethodSelected(paymentMethod)">
          <ng-container [ngSwitch]="paymentMethod=='sepa'">
            <!-- <mat-icon *ngSwitchCase="false">credit_card</mat-icon>  -->
            <!-- icon source: https://www.onlinewebfonts.com/icon/204842 -->
            <svg *ngSwitchCase="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
              <g><path d="M892.1,170.6h-504c-56.8,0-76.4,22.6-87.1,48.1h-54.4c-38.2,0-148.1,88.4-236.6,163.8v43.3C82.3,364,218.5,250.8,247.8,250.8h40.1h6.3c-3.1,22.9-1.3,269.3-1.3,269.3c-8.7,3.4-38.9,24-38.9,24l0.1,0.3c-3.2,2.9-5.2,7.3-5.2,12c0,9.1,7.3,16.3,16.3,16.3c4.6,0,8.7-1.9,11.6-4.9c15.3-9.1,66-38.6,89.8-46.5c119.1-47.2,203.2-105.6,237.2-68.4c0,0,25.2,31-7.8,88.6c-34.7,39.8-112,41.6-175.1,111c-55.4,62.1-141.1,113.3-183,113.3H10v32h231.4c24.8,0,80.8-24.4,87.5-30.6c0,0,11.1,32,45.6,39.1c34.5,7,67.3-9.4,77.4-15.6c0,0,67.7,87.3,143.4,0c-1.2,1.4,99.5,79.7,136.1-116.2h170.5c57.2,0,88.2-32.4,88.2-95.8V283.4C990.1,210,969.4,170.6,892.1,170.6z M424.3,768.9c-32,12.3-62.7,8.3-66.3-19.2c0,0,52.6-36.9,86.7-74.6H456C456.1,675.1,456.4,756.7,424.3,768.9z M377.4,204h516c76-4.7,63.3,73.3,63.3,73.3H325.4C325.8,274.9,314.3,204,377.4,204z M555.1,784.5c-37.7,24.4-55.5,9.1-82.7-21.2c0,0,16.6-37.3,16.3-88.1h94.6C583.3,675.2,592.6,760.1,555.1,784.5z M662.5,763.3c-24.5,23.7-44.3,4.2-53-3.2c0,0,12.8-48.6,6.6-85.2h82.2C698.4,674.9,697.8,722.2,662.5,763.3z M957.1,576.9c0,43.2-14.4,66.1-48.5,66.1H482.2c38.6-32.9,129.2-45.6,148.2-96.7c0,0,51.5-104.1-35-135.3c-68.7-12.3-97.7,26.2-270.1,93V352.8h631.7V576.9L957.1,576.9z"/></g>
            </svg>
            <!-- icon source: https://vip-svn.wordpress.com/plugins/laterpay/built_assets/img/logo-sepa.svg -->
            <svg *ngSwitchCase="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 27.5 76 20.9">
              <path d="M17.4 39.8c.1.4.1 1 .1 1.9 0 1.7-.1 2.9-.4 3.7-.6 1.5-1.9 2.4-3.9 2.7-1.2.2-2.5.3-4 .3-3 0-5-.2-6.1-.6-1.3-.3-2.2-1.1-2.7-2.5-.3-.7-.4-1.9-.4-3.6h5.2v.3c0 .9.2 1.5.7 1.8.6.2 1 .3 1.3.3h1.9c1.1 0 1.7 0 1.8-.1.5-.1.8-.4 1-.9 0-.1 0-.2.1-.4 0-.2.1-.4.1-.6 0-.9-.3-1.4-1-1.6-.1 0-1.3-.1-3.6-.3-1.7-.2-2.8-.3-3.4-.4-1.7-.3-2.8-.9-3.4-1.9-.5-1-.7-2.3-.7-3.9 0-1.2.1-2.2.4-3.1.3-.7.7-1.3 1.3-1.8.8-.5 1.9-.8 3.3-1 .8-.1 2.1-.1 3.9-.1 1.9 0 3.4.1 4.6.3 2.3.6 3.4 2.3 3.4 5.2v1h-5.2c0-.8-.1-1.4-.3-1.8-.3-.4-1.2-.6-2.7-.6-1.2 0-2.1.1-2.7.3-.4.3-.6.8-.6 1.6 0 .9.2 1.4.6 1.5.4.2.7.3 1 .3l1 .1 4.3.3c1.7.1 3 .5 3.9 1.3.6.4 1 1.2 1.2 2.3zm20.5-6.6v.1l-.1-.1c-1.5-1.8-3.4-2.7-5.6-2.7-2.8 0-4.9 1.2-6.2 3.6h11.6l-.1.1-1.3 3H24.8V38.9h10.4l-.2.1-1.3 3v.1H26c1.6 2.1 3.6 3.1 6.1 3.1 2.2 0 4-.8 5.5-2.5l.1-.1v4h-.1c-1.7 1.2-3.7 1.8-5.9 1.8-2.1 0-4-.6-5.8-1.7-1.9-1.2-3.1-2.7-3.9-4.5h-3.7V42l1.3-3 .1-.1h1.5c-.1-.2-.1-.5-.1-.9 0-.3 0-.5.1-.7h-3.1v-.1l1.5-3H22c.7-1.8 1.9-3.3 3.7-4.6 1.8-1.2 3.8-1.8 5.9-1.8 2.8 0 5.2.9 7.4 2.8v.1l-1.1 2.5zm14.8 9.7c-.9 0-1.9.1-3.1.1h-4.3v5.3h-5.5V27.6h10.4c1.2 0 2.3.1 3.3.3 1.7.6 2.8 1.7 3.4 3.4.3.7.4 2 .4 3.9 0 2.2-.1 3.7-.4 4.6-.8 1.8-2.2 2.8-4.2 3.1zm-1.1-6.8c0-.3.1-.7.1-1 0-.7-.1-1.3-.3-1.9-.1-.5-.5-.8-1.3-1h-4.8v5.9h3.6c1.2 0 1.9 0 2.1-.1.3-.3.5-.7.6-1.2-.1-.1-.1-.3 0-.7zm17.7 8.7h-7.6l-.9 3.6H55l6.2-20.8h8.3L76 48.4h-5.6l-1.1-3.6zm-1.2-4l-2.5-9.1-2.7 9.1h5.2z"/>
            </svg>
          </ng-container>
          <span>{{('purchase.method.'+paymentMethod+'.title') | translate}}</span>
        </button>
      </div>
    </div>
    <ng-container *ngSwitchCase="true">
      <div #page *ngIf="!done" [@pageActivation] class="page payment">
        <form [formGroup]="form" novalidate>
          <div>
            <div #stripeElementHost><!-- Mount point for stripe element --></div>
            <mat-error *ngIf="stripeElementError">
              <div>{{ stripeElementError }}</div>
            </mat-error>
          </div>
          <mat-form-field *ngIf="bankName" [@fieldActivation]>
            <input type="text" matInput unmodified-input [value]="bankName" [placeholder]="'purchase.bank' | translate" disabled/>
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput unmodified-input formControlName="name" required [placeholder]="'purchase.customer' | translate"/>
            <mat-error>
              <span translate>purchase.validation.customerRequired</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field [ngStyle]="form.controls.taxId.errors?.invalidTaxId=='invalidSwissTaxId' ? {'padding-bottom': '10px'} : {}">
            <input #taxId
                   unmodified-input
                   type="text"
                   name="taxId"
                   matInput
                   formControlName="taxId"
                   [errorStateMatcher]="taxIdErrorStateMatcher"
                   [placeholder]="'purchase.taxId' | translate"/>
            <a *ngIf="contact.id=='1679741802076687' && taxId.value"
               style="color: darkgrey;
                      font-size: 0.8em;
                      font-style: italic;
                      cursor: pointer;
                      position: absolute;
                      top: 30px;
                      right: 0;"
               (click)="validateTaxId()">
               Validate
            </a>
            <mat-error *ngIf="form.controls.taxId.errors?.invalidTaxId; let error;">
              <div>{{ 'purchase.validation.'+error | translate}}</div>
            </mat-error>
          </mat-form-field>
          <div formGroupName="address">
            <div class="location">
              <mat-form-field>
                <mat-icon matPrefix>location_on</mat-icon>
                <input type="text" unmodified-input matInput formControlName="zip" required [placeholder]="'contact.zip' | translate"/>
                <mat-error>
                  <span translate>contact.validation.zipRequired</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input type="text" unmodified-input matInput formControlName="community" required [placeholder]="'contact.community' | translate"/>
                <mat-error>
                  <span translate>contact.validation.communityRequired</span>
                </mat-error>
              </mat-form-field>
            </div>
            <mat-form-field class="inset">
              <input type="text" unmodified-input matInput formControlName="address" required [placeholder]="'contact.address' | translate"/>
              <mat-error>
                <span translate>contact.validation.addressRequired</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-icon matPrefix>language</mat-icon>
              <select matNativeControl formControlName="countryCode" required [placeholder]="'contact.country' | translate">
                <option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option>
              </select>
              <mat-error>
                <span translate>contact.validation.countryRequired</span>
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field>
            <mat-icon matPrefix>mail</mat-icon>
            <input type="email"
                   unmodified-input
                   matInput
                   formControlName="email"
                   [placeholder]="'contact.email' | translate"/>
            <mat-error>
              <span translate>contact.validation.emailRequired</span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="!processing && paymentError">
            <div>{{ paymentError.code && ('purchase.stripeError.'+errorCodeKey(paymentError.code) | translate) || paymentError.message ||  paymentError}}</div>
          </mat-error>
        </form>
        <button mat-raised-button
                color="primary"
                (click)="onPurchase($event)"
                [disabled]="processing || taxIdError || !stripeElementComplete || form.invalid">
          <span translate>Purchase for {{ product.price }} {{ product.currency }}</span>
        </button>
        <div *ngIf="processing" class="processing" translate>purchase.processing</div>
        <div *ngIf="paymentMethod=='sepa'" class="sepa-mandate" translate>purchase.method.sepa.mandate.text</div>
      </div>
      <div #page *ngIf="done" [@pageActivation] class="page payment-completed">
        <div><ng-content></ng-content></div>
        <div *ngIf="stripeSource?.sepa_debit && stripeSource.sepa_debit['mandate_url']; let mandateUrl;">
          <mat-icon>card_membership</mat-icon>
          <!-- e.g. https://stripe.com/sources/sepa_mandate?source=src_1Fj4kmGu8xa9LGJMrnNgRK16&client_secret=src_client_secret_GFZuCfjV0yV73he0shbrwNPm-->
          <a [href]="mandateUrl" target='_blank'>{{'purchase.method.sepa.mandate.preview' | translate}}</a>
        </div>
      </div>
    </ng-container>
  </mat-card-content>
  <ng-container *ngTemplateOutlet="controlsTemplate"></ng-container>
</mat-card>
