import {NgModule} from '@angular/core';
import {InterestsComponent} from './components/interests/interests.component';
import {SurveyComponent} from './components/survey/survey.component';
import {CommonModule} from "@angular/common";
import {MaterialModule} from "material";
import {SharedModule} from "shared";
import {TranslateModule} from "@ngx-translate/core";
import {SurveyResultComponent} from "./components/survey/result/result.component";
import {SurveyPersonalityResultComponent} from "./components/survey/result/personality/personality.result.component";
import {PersonalityColorsComponent} from "./components/survey/result/personality/personality.colors.component";
import {SurveyVitalityResultComponent} from "./components/survey/result/vitality/vitality.result.component";
import {MediaModule} from "media";
import {VitalityImagesComponent} from "./components/survey/result/vitality/vitality.images.component";

@NgModule({
  declarations: [
    InterestsComponent,
    SurveyComponent,
    SurveyResultComponent,
    SurveyPersonalityResultComponent,
    PersonalityColorsComponent,
    SurveyVitalityResultComponent,
    VitalityImagesComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    TranslateModule,
    MediaModule
  ],
  exports: [
    InterestsComponent,
    SurveyComponent,
    SurveyResultComponent,
    SurveyPersonalityResultComponent,
    PersonalityColorsComponent,
    SurveyVitalityResultComponent,
    VitalityImagesComponent
  ]
})
export class InterestsModule { }
