import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {of} from "rxjs";
import {catchError, map, mergeMap} from "rxjs/operators";
import {Logger} from "core";
import {HttpClient} from "@angular/common/http";

import {
  TaskListActionIds,
  taskListDeleteAction,
  taskListDeleteFailureAction,
  taskListDeleteSuccessAction,
  TaskListUpdateAction,
  taskListUpdateFailureAction,
  taskListUpdateSuccessAction
} from "./actions";
import {TaskList} from "../../models/task-list";

@Injectable()
export class TaskListEffects {

  // ATTENTION using createEffect api AND @Effect() decorator causes effect to be invoked twice - removing this decorator fixes the problem!
  // However this decorator is still required when the effect is created directly using the "old style" approach - in this case it also works properly.
  // https://github.com/ngrx/platform/issues/1054#issuecomment-538807675

  // @Effect()
  taskListUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TaskListUpdateAction>(TaskListActionIds.UPDATE),
      mergeMap(action  => {
        const path = `/v1.0/task/list`;
        // return of({...action.taskList}).pipe(
        return this.http.post(path, action.taskList).pipe(
          map((taskList: TaskList) => {
            const props = { taskList: taskList, correlationId: action.correlationId };
            return taskListUpdateSuccessAction(props);
          }),
          catchError((error) => {
            this.logger.error('ERROR', error);
            return of(taskListUpdateFailureAction({
              taskList: action.previous,
              reason: error,
              correlationId: action.correlationId
            }));
          })
        )
      })
    )
  );

  // @Effect()
  taskListDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taskListDeleteAction),
      mergeMap(action  => {
        const path = `/v1.0/task/list/${action.taskList.id}`;
        return this.http.delete(path).pipe(
          map((taskList: TaskList) => {
            return taskListDeleteSuccessAction({ taskList: taskList || action.taskList } );
          }),
          catchError((error) => {
            this.logger.error('ERROR', error);
            return of(taskListDeleteFailureAction({ taskList: action.taskList, reason: error }));
          })
        )
      })
    )
  );

  protected logger = new Logger('TaskListEffects');

  constructor(private http: HttpClient,
              private actions$: Actions) {
  }
}
