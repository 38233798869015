<!--
<agm-map #map
         (onDomModified)="onDomModified($event)"
         [subtreeDomMutations]="true"
         [zoomControl]="true"
         [zoomControlOptions]="location.zoomOptions"
         [zoom]="location.zoom"
         [mapTypeId]="location.mapTypeId"
         [latitude]="location.lat"
         [longitude]="location.lng">
  <ng-content></ng-content>
</agm-map>
-->
<agm-map #map
         [zoomControl]="true"
         [zoomControlOptions]="options.zoomOptions"
         [zoom]="options.zoom"
         [mapTypeId]="options.mapTypeId"
         [latitude]="latitude"
         [longitude]="longitude"
         (mapReady)="onMapReady($event)"
         (onDomModified)="onDomModified($event)"
         (mapClick)="onMapClick($event)">
  <agm-marker *ngFor="let marker of markers$ | async"
              [latitude]="marker.latitude"
              [longitude]="marker.longitude">
  </agm-marker>
</agm-map>
