<template #conversationCallBar></template>
<div #mute  class="cb-control">
  <button tabindex="-1" mat-icon-button (click)="onConversationMuted.emit(!muted)">
    <mat-icon>{{ muted ? 'notifications_off' : 'notifications'}}</mat-icon>
  </button>
  <span>{{('actions.' + (muted ? 'muted'  : 'unmuted')) | translate}}</span>
</div>
<div #media class="cb-control">
  <button tabindex="-1" mat-icon-button>
    <mat-icon>attach_file</mat-icon>
  </button>
  <span translate>actions.media</span>
</div>
<div #details class="cb-control">
  <button tabindex="-1" mat-icon-button (click)="onConversationDetails.emit()">
    <mat-icon>settings</mat-icon>
  </button>
  <span translate>actions.details</span>
</div>
