import {Inject, Injectable} from "@angular/core";
import {PropertiesService} from "properties";
import {MessageEnvelope, MessageHandlerRegistry, MessagingService} from "messaging";
import {EMPTY_ARRAY, Logger, NULL_USER, updateFilters, User, VersionedId} from "core";
import {
  EmptyListViewState,
  EmptyLoadMoreState,
  EntityDraftMessage,
  EntityDraftMessageType,
  EntityUpdateMessage,
  EntityUpdateMessageType,
} from "./store/models";
import {BehaviorSubject, filter, firstValueFrom, Observable, Subject, takeUntil, tap} from "rxjs";
import {AuthenticationService} from "auth";
import {ActionsSubject, Store} from "@ngrx/store";
import {FilteredListView, LoadMoreState, More, StoreService} from "store";
import {
  entityDraftAction,
  entityFactoryRegistrationAction,
  entitySubscribeAction,
  entityUnsubscribeAction,
  entityUpdateAction,
  listViewCreateAction,
  listViewLoadMoreAction,
  listViewSetTypedFiltersAction,
  listViewTriggerLoadAction,
  listViewUpdateSearchTermAction,
  synchronizationInitializationAction
} from "synchronization/lib/store/actions";
import {distinctUntilChanged, finalize, map} from "rxjs/operators";
import {
  DEFAULT_SEGMENT_SIZE,
  ListViewState,
  PrefetchInfo,
  Segment,
  selectEntity,
  selectEntityDraft,
  selectEntityView,
  selectState
} from "./store/state";
import isEqual from "lodash/isEqual";

@Injectable({
  providedIn: 'root'
})
export class SynchronizationService extends StoreService {

  protected logger = new Logger('SynchronizationService').setSilent(true);
  protected initialized$ = new BehaviorSubject(false);
  protected messageHandlerRegistry = new MessageHandlerRegistry();

  constructor(protected store$: Store<any>,
              protected action$: ActionsSubject,
              protected propertiesService: PropertiesService,
              protected authenticationService: AuthenticationService,
              protected messagingService: MessagingService,
              @Inject(NULL_USER) protected nullUser: User) {
    super(store$, action$);
    console.log('SynchronizationService.ctor');
    // let handleListViewReleaseMessage = (envelope:MessageEnvelope):void => {
    //   let message = <ListViewReleaseMessage>envelope.message;
    //   this.listViews.get(message.viewId)?.handleReleaseMessage(message);
    // };
    // let handleListViewSegmentMessage = (envelope:MessageEnvelope):void => {
    //   let message = <ListViewSegmentMessage>envelope.message;
    //   this.listViews.get(message.viewId)?.handleSegmentMessage(message);
    // };
    let handleEntityUpdateMessage = (envelope:MessageEnvelope):void => {
      let message = <EntityUpdateMessage>envelope.message;
      this.store$.dispatch(entityUpdateAction({
        entityType: message.entityType,
        entity: message.entity }));
    };
    let handleEntityDraftMessage = (envelope:MessageEnvelope):void => {
      let message = <EntityDraftMessage>envelope.message;
      this.store$.dispatch(entityDraftAction({
        entityType: message.entityType,
        entity: message.entity,
        channelId: message.channelId,
        send: false }));
    };
    /*
    let handleListViewEventMessage = (envelope:MessageEnvelope):void => {
      let message = <ListViewEventMessage>envelope.message;
      this.store$.dispatch(listViewEventAction({
        viewId: message.viewId,
        event: message.event }));
    };*/
    // we do not need to register for messages which are just reply messages!
    //this.messageHandlerRegistry.addMessageHandler(ListViewEventMessageType,handleListViewEventMessage);
    this.messageHandlerRegistry.addMessageHandler(EntityUpdateMessageType,handleEntityUpdateMessage);
    this.messageHandlerRegistry.addMessageHandler(EntityDraftMessageType,handleEntityDraftMessage);

    this.messagingService
      .register(envelope => this.messageHandlerRegistry.hasMessageHandler(envelope.message?.type))
      .subscribe(envelope => {
        this.messageHandlerRegistry.getMessageHandler(envelope.message.type)(envelope);
      });
  }

  resynchronize() {
    this.store$.dispatch(synchronizationInitializationAction({resynchronize:true}));
  }

  protected async waitOnInitialized() {
    return await firstValueFrom(this.initialized$.pipe(filter(initialized => !!initialized)));
  }

  public setEntityFactory(entityType:string,entityFactory:(VersionedId)=>VersionedId) {
    this.store$.dispatch(entityFactoryRegistrationAction({ entityType, entityFactory }));
  }

  public getEntity$(entityId:string, entityType:string, defaultEntity?:VersionedId):Observable<VersionedId> {
    //console.trace("xy.getEntity$",entityId,entityType,defaultEntity);
    //this.logger.debug("mediaService.getMedia$",id,defaultMedia);
    let subscribed = false;
    //console.log("sync:getEntity$.subscribe.1",entityId,entityType);
    let ensureSubscription = (entity:VersionedId) => {
      //console.log("xy.getEntity$.subscribed",subscribed,entity);
      if (!subscribed) {
        subscribed = true;
        //this.logger.debug("media.service.getMedia$.subscribe",id);
        //console.log("sync:getEntity$.subscribe",entityId,entityType,entity);
        this.store$.dispatch(entitySubscribeAction({ entityId, entityType, entity: entity ?? defaultEntity }));
      }
    }
    return this.store$.select(selectEntity(entityType,entityId)).pipe(
      //tap(entity =>  console.log("sync:getEntity$.tap1",entityId,entity)),
      //tap(entity =>  console.log("xy.getEntity$.tap1",entityId,entity)),
      distinctUntilChanged((entity1:VersionedId,entity2:VersionedId)=>isEqual(entity1,entity2)),
      finalize(() => {
        if (subscribed) {
          subscribed = false;
          this.store$.dispatch(entityUnsubscribeAction({ entityType, entityId }));
          //window.setTimeout(()=> {
            //console.log("sync:getEntity$.unsubscribe",entityId,entityType);
            //this.store$.dispatch(entityUnsubscribeAction({ entityType, entityId }));
          //},1000);
        }
      }),
      tap(entity => ensureSubscription(entity)),
      map(entity => {
        return (<any>entity)?.timeDeleted ? undefined :
          entity ?? defaultEntity;
      })
    );
  }

  public getEntityDraft$(entityType:string,channelId:string):Observable<VersionedId> {
    return this.store$.select(selectEntityDraft(entityType,channelId));
  }

  public setEntityDraft<T extends VersionedId>(entityType:string,channelId:string,entity:T):Promise<VersionedId> {
    return this.dispatch(entityDraftAction({ entityType, entity, channelId, send: true }))
      .then(r=>entity);
  }

  protected createListView(
    entityType:string,
    filters: {[type:string]:string[]},
    searchTerm: string,
    preprocessEntities?:(entities:{entity?:VersionedId}[])=>{entity?:VersionedId}[],
    serverFilterPrepare?:(filters:{[type:string]:string[]})=>{[type:string]:string[]},
    serverSearchPrepare?:(term:string)=>string,
    // client side filter and search is only done, if these predicates are defined!
    clientFilterPredicate?:(entity:VersionedId,filter:{[type:string]:string[]})=>boolean,
    clientSearchPredicate?:(entity:VersionedId,term:string)=>boolean,
    // if undefined, the first fetch is DEFAULT_SEGMENT_SIZE (25) at the end of the list
    // which is the newest/most active part of the list....
    // everything else just gets fetched on access....
    requestBootstrapSegment?:()=>Segment,
    requestPrefetchInfo?:(state:ListViewState)=>PrefetchInfo
  ):Promise<string> {
    //console.log("sync:createListView.dispatch");
    return this.dispatch(listViewCreateAction({
      viewId: this.messagingService.createMessageId(),
      entityType,
      filters,
      searchTerm,
      preprocessEntities,
      serverFilterPrepare,
      serverSearchPrepare,
      clientFilterPredicate,
      clientSearchPredicate,
      requestBootstrapSegment,
      requestPrefetchInfo
    })).then(action => action.viewId);
  }

  public createFilteredListView<T extends VersionedId>(
    entityType:string,
    // triggerLoad is called, if the filters or the search term changes
    triggerLoad?:(filters:{[type:string]:string[]},searchTerm:string)=>boolean,
    // preprocessEntities is called, after the entities are loaded from the server
    preprocessEntities?:(entities:{entity?:VersionedId}[])=>{entity?:VersionedId}[],
    // serverFilterPrepare is called, before the filters are sent to the server
    serverFilterPrepare?:(filters:{[type:string]:string[]})=>{[type:string]:string[]},
    // serverSearchPrepare is called, before the search term is sent to the server
    serverSearchPrepare?:(searchTerm:string)=>string,
    // client side filter and search is only done, if these predicates are defined!
    // clientFilterPredicate
    clientFilterPredicate?:(entity:VersionedId,filter:{[type:string]:string[]})=>boolean,
    // clientSearchPredicate
    clientSearchPredicate?:(entity:VersionedId,term:string)=>boolean,
    // if undefined, the first fetch is DEFAULT_SEGMENT_SIZE (25) at the end of the list
    // which is the newest/most active part of the list....
    // everything else just gets fetched on access....
    requestBootstrapSegment?:()=>Segment,
    requestPrefetchInfo?:(state:ListViewState)=>PrefetchInfo
  ): FilteredListView<T> {
    const self = this;
    let unsubscribed$:Subject<void>;
    const state$ = new BehaviorSubject<ListViewState>(EmptyListViewState);
    const entities$ = new BehaviorSubject<T[]>([]);
    const sections$ = new BehaviorSubject<number[]>([]);
    const store$ = this.store$;
    const viewId$ = new BehaviorSubject<string|undefined>(undefined);
    const filters$ = new BehaviorSubject<{[key:string]:string[]}>({});
    const searchTerm$ = new BehaviorSubject<string|undefined>(undefined);
    const moreActiveState$ = new BehaviorSubject<LoadMoreState>(EmptyLoadMoreState);
    const morePassiveState$ = new BehaviorSubject<LoadMoreState>(EmptyLoadMoreState);
    let moreActive$:Observable<More> = undefined;
    let morePassive$:Observable<More> = undefined;
    const filteredList= new class extends FilteredListView<T> {
      get id$(): Observable<string> {
        return viewId$.asObservable();
      }
      get entities$(): Observable<T[]> {
        return entities$.asObservable();
      }
      get sections$(): Observable<number[]> {
        return sections$.asObservable();
      }
      get filters(): string[] {
        return <string[]>[].concat(...Object.keys(filters$.value).map(type=>filters$.value[type]??EMPTY_ARRAY));
      }
      get filters$(): Observable<string[]> {
        return filters$.asObservable().pipe(
          map(filters=>[].concat(...Object.keys(filters).map(type=>filters[type]??EMPTY_ARRAY)))
        );
      }
      get typedFilters():{[key:string]:string[]} {
        return filters$.value;
      }
      get typedFilters$():Observable<{[key:string]:string[]}> {
        return filters$.asObservable();
      }
      get searchTerm(): string | undefined {
        return searchTerm$.value;
      }
      get searchTerm$(): Observable<string | undefined> {
        return searchTerm$.asObservable();
      }
      setSearchTerm(searchTerm:string|undefined,delay?:number):Promise<boolean> {
        if (!isEqual(searchTerm$.value,searchTerm)) {
          if (!!viewId$.value) {
            return self.dispatch(listViewUpdateSearchTermAction({
              viewId: viewId$.value,
              entityType,
              searchTerm: searchTerm??'',
              delay
            })).then(r=>true).catch(e=>false);
          } else {
            searchTerm$.next(searchTerm??'');
          }
        }
        return Promise.resolve(true);
      }
      setTypedFilters(typedFilters:{[key:string]:string[]},remove?:(type:string,filters:string[])=>boolean,delay?:number): Promise<boolean> {
        const updatedFilters = updateFilters(filters$.value,typedFilters,remove);
        //console.log("sync:set.filter",typedFilters,updatedFilters,!isEqual(filters$.value,updatedFilters));
        if (!isEqual(filters$.value,updatedFilters)) {
          if (!!viewId$.value) {
            return self.dispatch(listViewSetTypedFiltersAction({
              viewId: viewId$.value,
              entityType,
              filters:typedFilters,
              remove,
              delay
            })).then(r=>true).catch(e=>false);
          } else {
            filters$.next(updatedFilters);
          }
        }
        return Promise.resolve(true);
      }
      get state(): ListViewState {
        return state$.value;
      }
      protected getMore$(moreState$:BehaviorSubject<LoadMoreState>, moreActiveGetter:(number)=>number, morePassiveGetter:(number)=>number):Observable<More> {
        return moreState$.asObservable().pipe(
          map(more=>{
            console.log("sync:getMore$",more);
            return <More>{
              size: more.size,
              loading: more.load>0,
              load: (size?: number):Promise<number> => {
                const state = moreState$.value;
                size = Math.min(more.size,size ?? DEFAULT_SEGMENT_SIZE);
                if (size>0) {
                  return self.dispatch(listViewLoadMoreAction({
                    viewId: viewId$.value,
                    entityType,
                    moreActiveLoad: moreActiveGetter(size),
                    morePassiveLoad: morePassiveGetter(size)
                  })).then(r=>{
                    return firstValueFrom(moreState$.pipe(
                      filter(more=>more!==state),
                      map(more=>more.size??0),
                      filter(size=>size<state.size)
                    )).then(size=>state.size-size);
                  })
                }
                return Promise.resolve(0);
              }
            }
          }));
      }
      get moreActive$(): Observable<More> {
        return moreActive$ ?? (moreActive$ = this.getMore$(moreActiveState$,size=>size,size=>0));
      }
      get morePassive$(): Observable<More> {
        return morePassive$ ?? (morePassive$ = this.getMore$(morePassiveState$,size=>0,size=>size));
      }
      load(): Promise<FilteredListView<T>> {
        return firstValueFrom(viewId$.pipe(filter(viewId=>!!viewId)))
          .then(viewId=>{
            return self.dispatch(listViewTriggerLoadAction({
              viewId: viewId$.value,
              entityType
            })).then(r=>this);
          });
      }
      unsubscribe() {
        unsubscribed$?.next();
        unsubscribed$?.complete();
        super.unsubscribe();
      }
    }
    let createView = false;
    let reloadView = false;
    filteredList.add(this.store$.select(selectState)
      .subscribe(state=>{
        if (!createView && state.initialized && state.authenticated && !viewId$.value && state.entityStates?.[entityType]?.listViews?.[viewId$.value]===undefined) {
          //console.log("sync:selectState",!createView,state.initialized,state.authenticated,!viewId$.value,state.entityStates?.[entityType]?.listViews?.[viewId$.value]===undefined);
          createView = true;
          unsubscribed$?.next();
          unsubscribed$?.complete();
          unsubscribed$ = new Subject<void>();
          this.createListView(
            entityType,
            filters$.value,
            searchTerm$.value,
            preprocessEntities,
            serverFilterPrepare,
            serverSearchPrepare,
            clientFilterPredicate,
            clientSearchPredicate,
            requestBootstrapSegment,
            requestPrefetchInfo
          ).then(viewId => {
            viewId$.next(viewId);
            createView = false;
            reloadView = true;
            store$.select(selectEntityView(entityType,viewId)).pipe(
              takeUntil(unsubscribed$),
            ).subscribe(listView=>{
              console.log("sync:listView.update."+listView.viewId,listView,"entities",entities$.value!==listView?.entities,"state",{...listView?.state},state$.value!==listView?.state,"morePassive",{...morePassiveState$.value},{...listView?.morePassive},morePassiveState$.value!==listView?.morePassive,"filters",filters$.value);
              if (state$.value!==listView?.state) {
                state$.next(listView?.state);
              }
              if (entities$.value!==listView?.entities) {
                entities$.next(<T[]>listView?.entities);
              }
              if (!isEqual(moreActiveState$.value,listView?.moreActive)) {
                moreActiveState$.next(listView?.moreActive ?? EmptyLoadMoreState);
              }
              console.log("sync:morePassiveState",{...morePassiveState$.value},{...listView?.morePassive},morePassiveState$.value!==listView?.morePassive);
              if (!isEqual(morePassiveState$.value,listView?.morePassive)) {
                morePassiveState$.next(listView?.morePassive ?? EmptyLoadMoreState);
              }
              if (reloadView && (!triggerLoad || triggerLoad(listView.filters,listView.searchTerm))) {
                reloadView = false;
                store$.dispatch(listViewTriggerLoadAction({
                  viewId: viewId$.value,
                  entityType
                }));
              }
            });
          });
        } else if (!state.authenticated) {
          //console.log("sync:selectState",!createView,state.initialized,state.authenticated,!viewId$.value,state.entityStates?.[entityType]?.listViews?.[viewId$.value]===undefined);
          reloadView = false;
          viewId$.next(undefined);
          state$.next(EmptyListViewState);
          entities$.next(EMPTY_ARRAY);
          sections$.next(EMPTY_ARRAY);
        }
        const listView = state.entityStates?.[entityType]?.listViews?.[viewId$.value];
        if (!!listView) {
          if (!isEqual(searchTerm$.value,listView.searchTerm)) {
            //console.log("sync:UPDATE.searchTerm",searchTerm$.value,listView.searchTerm);
            searchTerm$.next(listView.searchTerm);
          }
          if (!isEqual(filters$.value,listView.filters)) {
            //console.log("sync:UPDATE.filters",filters$.value,listView.filters);
            filters$.next(listView.filters);
          }
        }
      }));
    //console.log("sync:createFilteredListView",entityType);
    return filteredList;
  }
}
