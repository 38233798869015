<ng-container *ngIf="result() as result" >
  <ng-container [ngSwitch]="result?.display">
    <ng-container *ngSwitchCase="true">
      <div *ngIf="result.colors as colors" class="container">
        <div class="chart">
          <personality-colors
            [colors]="colors"
            [badgeData]="badgeData"
            [log]="log"
            [fill]="76">
          </personality-colors>
          <div class="info red"><div><div class="percent">{{colors.red}}</div><div class="label" translate>survey.personality.legend.red.label</div></div></div>
          <div class="info yellow"><div><div class="percent">{{colors.yellow}}</div><div class="label"translate>survey.personality.legend.yellow.label</div></div></div>
          <div class="info green"><div><div class="label" translate>survey.personality.legend.green.label</div><div class="percent">{{colors.green}}</div></div></div>
          <div class="info blue"><div><div class="label" translate>survey.personality.legend.blue.label</div><div class="percent">{{colors.blue}}</div></div></div>
        </div>
        <div class="info red" [class.opened]="opened=='red'" (click)="toggle('red')">
          <div><span translate>survey.personality.legend.red.label</span><span [innerHTML]="'survey.personality.legend.points' | translate: { points:colors.red} | safeHtml"></span></div>
          <div *ngIf="opened=='red'" [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '350px' }}" [innerHTML]="'survey.personality.legend.red.info' | translate | safeHtml"></div>
        </div>
        <div class="info yellow" [class.opened]="opened=='yellow'" (click)="toggle('yellow')">
          <div><span translate>survey.personality.legend.yellow.label</span><span [innerHTML]="'survey.personality.legend.points' | translate: { points:colors.yellow} | safeHtml"></span></div>
          <div *ngIf="opened=='yellow'" [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '350px' }}" [innerHTML]="'survey.personality.legend.yellow.info' | translate | safeHtml"></div>
        </div>
        <div class="info green" [class.opened]="opened=='green'" (click)="toggle('green')">
          <div><span translate>survey.personality.legend.green.label</span><span [innerHTML]="'survey.personality.legend.points' | translate: { points:colors.green} | safeHtml"></span></div>
          <div *ngIf="opened=='green'" [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '350px' }}" [innerHTML]="'survey.personality.legend.green.info' | translate | safeHtml"></div>
        </div>
        <div class="info blue" [class.opened]="opened=='blue'" (click)="toggle('blue')">
          <div><span translate>survey.personality.legend.blue.label</span><span [innerHTML]="'survey.personality.legend.points' | translate: { points:colors.blue} | safeHtml"></span></div>
          <div *ngIf="opened=='blue'" [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '350px' }}" [innerHTML]="'survey.personality.legend.blue.info' | translate | safeHtml"></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="missingResultTemplate"
                    [ngTemplateOutlet]="missingResultTemplate">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
