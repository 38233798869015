/* projects/calendar/src/lib/components/event-list-item/event-list-item.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
  background-color: transparent;
  min-height: 70px;
}
mat-icon {
  color: gray;
}
.date {
  background-color: #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 3px;
}
.date > span {
  font-size: 22px;
  line-height: 1em;
}
.date > span.day {
  font-size: 36px;
}
.date > span.month {
  font-weight: bold;
  text-transform: uppercase;
}
.date.dense {
  font-size: 20px;
}
.date.dense.day {
  font-size: 36px;
}
.details {
  margin-left: 78px;
  padding: 4px 0 4px 6px;
  overflow: hidden;
}
.details > div:first-child .title {
  display: block;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9em !important;
}
.details > div:first-child .title > span {
  white-space: nowrap;
}
.details > div:first-child .location {
  color: #9e9e9e;
  font-size: 0.8em;
}
.details > div:nth-child(2) {
  margin-left: -8px;
}
.details > div:nth-child(2) > * {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 8px;
}
.details > div:nth-child(2) mat-icon {
  vertical-align: middle;
}
.details > div:nth-child(2) mat-icon + span {
  margin-left: 6px;
  font-size: 14px;
}
:host-context(.color-scheme-dark) mat-icon {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .date {
  background-color: #555;
  color: #E5E5E5;
}
/*# sourceMappingURL=event-list-item.component-NF3BCAER.css.map */
