import {
  ChangeDetectionStrategy,
  Compiler,
  Component,
  ComponentRef,
  createNgModule,
  ElementRef,
  HostBinding,
  Inject,
  InjectFlags,
  InjectionToken,
  Injector,
  NgModuleRef,
  Renderer2,
  StaticProvider,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Overlay, OverlayConfig} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {
  APP_INSTALL_OVERLAY_DATA,
  AppInstallOverlayComponent,
  AppInstallOverlayRef
} from "./components/app-install/app-install-overlay.component";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {BootstrapRetryComponent} from "./components/bootstrap-retry/bootstrap-retry.component";
import {filter, take, tap, concat, concatWith, startWith, shareReplay} from "rxjs/operators";
import {Properties, PropertiesService} from "properties";
import {BehaviorSubject, from, lastValueFrom, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {
  AppModule,
  ENVIRONMENT,
  I18nService,
  LOCAL_STORAGE,
  Logger,
  Platform,
  Registration,
  setPreferredThemeMode,
  setThemeModePure,
  SyncStorage,
  ThemeColorNames,
  ThemeColors,
  ThemeMode
} from "core";
// import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import {Location} from "@angular/common";
import tinycolor from "tinycolor2";
import {Router, ROUTES} from "@angular/router";
import cloneDeep from "lodash/cloneDeep";
import {
  ImageLink,
  Media,
  MediaAction,
  MediaService,
  MediaType,
  MediaViewerOverlayRef,
  MediaViewerOverlayService
} from "media";

export const APP_MODULE_PATH = new InjectionToken('APP_MODULE_PATH');
export const APP_MODULE_LOADER = new InjectionToken('APP_MODULE_LOADER');

@Component({
  selector: 'app-bootstrap',
  template: `
    <ng-container #root></ng-container>
    <!-- border: 0 because ios draws 2px border by default -->
    <iframe #iframe style="visibility: hidden; width:0; height: 0; border: 0;"></iframe>
  `,
  styleUrls: ['bootstrap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BootstrapComponent {

  @ViewChild('root', { read: ViewContainerRef }) viewRef!: ViewContainerRef;
  @ViewChild('iframe') iframe: ElementRef<HTMLIFrameElement>;

  private translateRegistration: Registration;
  private subscriptionBundle = new Subscription();
  private error = false;

  private logger = new Logger('BootstrapComponent');

  constructor(private overlay: Overlay,
              private injector: Injector,
              private element: ElementRef,
              private renderer: Renderer2,
              private platform: Platform,
              private dialog: MatDialog,
              // private splashScreen: SplashScreen,
              private propertiesService: PropertiesService,
              private translateService: TranslateService,
              private i18nService: I18nService,
              protected location: Location,
              protected router: Router,
              protected mediaService: MediaService,
              protected mediaViewerOverlayService: MediaViewerOverlayService,
              // private loader: NgModuleFactoryLoader,
              protected compiler: Compiler,
              @Inject(LOCAL_STORAGE) private storage: SyncStorage,
              @Inject(ENVIRONMENT) private environment: any,
              @Inject(APP_MODULE_PATH) private appModulePath: string,
              // @Inject(APP_MODULE_LOADER) private appModuleLoader: () => Promise<(NgModuleFactory<any> | Type<any>)>) {
              @Inject(APP_MODULE_LOADER) private appModuleLoader: () => Promise<(Type<AppModule>)>) {
    this.propertiesService.properties$.pipe(filter(proerties=>!!proerties)).subscribe(properties=>this.initializeColorScheme(properties));
  }

  ngOnInit() {
    this.platform.ready()
      .then((readySource) => {
        //this.renderer.removeChild(this.element.nativeElement, this.primary.nativeElement, false);
        return readySource;
      })
      .then(readySource => this.bootstrap())
      .then((ngModuleRef) => {
        const createAppComponent = () => {
          const injector = ngModuleRef.injector;
          const flatten: <T>(arr: T[][]) => T[] = (arr) => Array.prototype.concat.apply([], arr)
          // const lazyRoutes = moduleRef.instance.getRoutes();

          // Flags needed because:
          // When loading a module that doesn't provide `RouterModule.forChild()` preloader
          // will get stuck in an infinite loop. The child module's Injector will look to
          // its parent `Injector` when it doesn't find any ROUTES so it will return routes
          // for it's parent module instead.
          // see RouterConfigLoader.loadChildren()
          // https://github.com/angular/angular/blob/main/packages/router/src/router_config_loader.ts
          const lazyRoutes  = flatten(injector.get(ROUTES, [], InjectFlags.Self | InjectFlags.Optional));
          if (lazyRoutes?.length) {
            const routes = cloneDeep(this.router.config);
            // const parentRoute = routes.find(route => route.path=='' && route.component)
            //   ?.children
            //   ?.find(child => child.path=='' && !child.redirectTo && !child.outlet);
            // if (!parentRoute?.children) {
            //   parentRoute.children = [];
            // }
            // lazyRoutes.forEach(route => {
            //   parentRoute.children.push(route);
            // })
            routes.splice(routes.length, 0, ...lazyRoutes);
            this.router.resetConfig(routes);
          }
          const componentType = ngModuleRef.instance.getComponent();
          const componentRef = this.viewRef.createComponent(componentType, { environmentInjector: ngModuleRef });
          const changeDetector = (componentRef.instance as any).changeDetector; // BootstrapComponent has ChangeDetectionStrategy.OnPush
          changeDetector.markForCheck();
          changeDetector.detectChanges();
          this.logger.debug('CREATED APP COMPONENT!');
        }
        const onboardingMedia = this.propertiesService?.group?.onboarding?.media;
        if (this.platform.is('mobile') &&     // mobile?
          !this.platform.is('hybrid') &&      // browser?
          !this.propertiesService.group?.entry?.page &&    // entry page?
          !((<any>window.navigator).standalone === true ||
            window.matchMedia('(display-mode: standalone)').matches) && // installed webapp?
          !onboardingMedia) {   // onboarding media is present - do not show the app install dialog here but delegate responsibility to display this prompt to onboarding media itself
          // // If "app://" is registered the app will launch immediately and the timer won't fire.
          // // Iframe makes possible to catch the error when the app is not installed
          // this.iframe.nativeElement.src = `${this.environment.app}://`;
          // window.setTimeout(() => {
          //   const hidden = document.hidden ?? document['webkitHidden'];
          //   if (!hidden) {
          // returned observable completes after first emit (according to docs) - no need to unsubscribe
          return this.translateService
            .get(['app.links.googlePlay', 'app.links.appleStore'])
            .subscribe(result => {
              if (Object.values(result).filter(value => !!value).length > 0) {
                this.openOverlay().onClose.subscribe(() => {
                  createAppComponent();
                })
              }
            });
          //   }
          // }, 500);
        } else {
          createAppComponent();
        }
      });
  }

  ngOnDestroy() {
    if (this.translateRegistration!=null) {
      this.translateRegistration.deregister();
    }
    this.subscriptionBundle.unsubscribe();
  }

  protected bootstrap(attempt = 0, bootstrapRetryDialogRef: MatDialogRef<BootstrapRetryComponent> = null): Promise<NgModuleRef<AppModule>> {
    return new Promise((resolve, reject) => {
        this.propertiesService
          .reload()
          //.then((properties) => this.initializeColorScheme(properties))
          .then((properties) => this.initializeTranslation(properties),
            (error) => { throw new Error(`FAILED TO LOAD PROPERTIES! Error: ${error}`); })
          .then((module) => this.appModuleLoader(),
            (error) => { throw new Error(`FAILED TO LOAD APP MODULE! Error: ${error}`); })
          // .then((moduleFactoryOrType: (NgModuleFactory<any> | Type<any>))=> {
          .then((moduleFactoryOrType: Type<AppModule>)=> {
            // if (moduleFactoryOrType instanceof NgModuleFactory) {
            //   // For AOT
            //   return moduleFactoryOrType;
            // } else {
            // For JIT
            return createNgModule(moduleFactoryOrType, this.injector);
            // return this.compiler.compileModuleAsync(moduleFactoryOrType);
            // }
          })
          // .then((moduleFactory: NgModuleFactory<any>) => {
          //     const entryComponent = (<any>moduleFactory.moduleType).entry;
          //     const moduleRef = moduleFactory.create(this.injector);
          //     const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(entryComponent);
          //     if (componentFactory) {
          //       // const componentRef = this.viewRef.createComponent(componentFactory);
          //       // componentRef.changeDetectorRef.detectChanges(); // BootstrapComponent has ChangeDetectionStrategy.OnPush
          //       // this.logger.debug('CREATED APP COMPONENT!');
          //       return componentFactory;
          //     } else {
          //       throw new Error ('Cannot resolve factory for component '+entryComponent);
          //     }
          // })
          // .then((factory) => {
          //     if (bootstrapRetryDialogRef!=null) {
          //         bootstrapRetryDialogRef.close();
          //     }
          //     this.error = false;
          //     resolve(factory);
          // })
          .then((moduleRef) => {
            if (bootstrapRetryDialogRef!=null) {
              bootstrapRetryDialogRef.close();
            }
            this.error = false;
            resolve(moduleRef);
          })
          .catch(error => {
            this.logger.debug('BOOTSTRAP ERROR: '+error);
            this.error = true;
            if (attempt==0 && this.platform.is('hybrid')) {
              const working = new BehaviorSubject(false);
              const dialogConfig = new MatDialogConfig();
              dialogConfig.hasBackdrop = false;
              dialogConfig.data = { working: working.asObservable() };
              const dialogRef = this.dialog.open(BootstrapRetryComponent, dialogConfig);
              const subscription = dialogRef.componentInstance.onRetry.subscribe(() => {
                working.next(true);
                this.bootstrap(++attempt, dialogRef)
                  .then((factory) => resolve(factory))
                  .catch(error => working.next(false));
              });
              dialogRef.afterOpened().subscribe(() => /*this.splashScreen.hide()*/ (navigator as any).splashscreen.hide());
              dialogRef.afterClosed().subscribe((result) => {
                //this.logger.info(`Bootstrap Retry dialog closed: ${result}`);
                subscription.unsubscribe();
                if (result === 'cancel' && navigator['app']) {
                  navigator['app'].exitApp();
                }
              });
            } else {
              reject(error);
            }
          });
      }
    );
  }

  protected initializeColorScheme(properties: Properties): Promise<Properties> {
    //console.log("DOCUMENT initializeColorScheme",properties?.group?.colorScheme,properties?.group?.appColors);
    let mode:ThemeMode = properties.group.colorScheme;
    let groupColors:ThemeColors = properties.group.colors;
    //console.log("DOCUMENT GROUP COLORS ",groupColors);
    if (mode) {
      let previous = localStorage.getItem('default-theme-mode');
      if (!previous || previous!=mode) {
        localStorage.setItem('default-theme-mode',mode);
        setPreferredThemeMode(properties.group.colorScheme);
      }
    }
    const appColors = this.environment.colors = {
      pure:true,
      primary: "#007DC8",
      primaryContrast: "#FFFFFF",
      accent: "#FAA519",
      accentContrast: "#FFFFFF"
    };
    const pure = this.environment.colors.pure = groupColors?.pure ?? true;
    //console.log("DOCUMENT APP COLORS ",appColors,groupColors,"pure",pure);
    setThemeModePure(pure);
    //console.log("DOCUMENT APP COLORS ",appColors,groupColors,"pure",pure);
    ThemeColorNames.forEach(colorName=>{
      const color = tinycolor(groupColors?.[colorName] ?? appColors?.[colorName]);
      document.documentElement.style.setProperty('--color-'+colorName+'-50', color.clone().lighten(65).toHexString());
      document.documentElement.style.setProperty('--color-'+colorName+'-100', color.clone().lighten(40).toHexString());
      document.documentElement.style.setProperty('--color-'+colorName+'-200', color.clone().lighten(20).toHexString());
      document.documentElement.style.setProperty('--color-'+colorName+'-300', color.clone().lighten(10).toHexString());
      document.documentElement.style.setProperty('--color-'+colorName+'-400', color.clone().lighten(5).toHexString());
      document.documentElement.style.setProperty('--color-'+colorName, color.toHexString());
      document.documentElement.style.setProperty('--color-'+colorName+'-500', color.toHexString());
      document.documentElement.style.setProperty('--color-'+colorName+'-600', color.clone().darken(5).toHexString());
      document.documentElement.style.setProperty('--color-'+colorName+'-700', color.clone().darken(10).toHexString());
      document.documentElement.style.setProperty('--color-'+colorName+'-800', color.clone().darken(20).toHexString());
      document.documentElement.style.setProperty('--color-'+colorName+'-900', color.clone().darken(40).toHexString());

      document.documentElement.style.setProperty('--color-'+colorName+'-A100', color.toHexString()+'4C');
      document.documentElement.style.setProperty('--color-'+colorName+'-alpha-03', color.toHexString()+'4C');

      document.documentElement.style.setProperty('--color-'+colorName+'-A200', color.toHexString()+'80');
      document.documentElement.style.setProperty('--color-'+colorName+'-alpha-05', color.toHexString()+'80');

      document.documentElement.style.setProperty('--color-'+colorName+'-A400', color.toHexString()+'B3');
      document.documentElement.style.setProperty('--color-'+colorName+'-alpha-07', color.toHexString()+'B3');

      document.documentElement.style.setProperty('--color-'+colorName+'-A700', color.toHexString()+'E6');
      document.documentElement.style.setProperty('--color-'+colorName+'-alpha-09', color.toHexString()+'E6');
    });
    this.environment.primaryColor = groupColors?.['primary'] ?? appColors?.['primary'];
    this.environment.accentColor = groupColors?.['accent'] ?? appColors?.['accent'];
    //console.log("this.environment.primaryColor",this.environment.primaryColor);
    //    100       200       300       400       500       600       700       800        900
    //['#ffcdd2','#ef9a9a','#e57373','#ef5350','#f44336','#e53935','#d32f2f','#c62828','#b71c1c'].forEach((color,index)=>{
    //  const hsva = this.colorService.stringToHsva(color);
    //  console.log("DOCUMENT COLOR "+(index*100+100)+' '+color,"hsva",hsva,"cmyk",this.colorService.rgbaToCmyk(this.colorService.hsvaToRgba(hsva)));
    //});
    return Promise.resolve(properties);
  }

  protected initializeTranslation(properties: Properties): Promise<void> {
    let supportedLanguages = properties?.languages?.length ? properties.languages : this.environment.supportedLanguages;
    let storedLanguage  = this.i18nService.storedLanguage;
    const defaultLanguage = properties => (properties.user.isAuthenticated ? storedLanguage : undefined) ||
      properties.group.language ||
      (storedLanguage && properties.languages?.contains(storedLanguage) ? storedLanguage : undefined) ||
      properties.languages[0]   ||
      this.environment.defaultLanguage;
//    console.log("initializeTranslation authenticated",properties.user.isAuthenticated,"stored",this.i18nService.storedLanguage,"lang",defaultLanguage(properties));
    this.i18nService.init(defaultLanguage(properties), supportedLanguages);
    this.translateRegistration = this.i18nService.register(this.translateService);
    const propertiesSubscription = this.propertiesService.properties$.subscribe(properties => {
      //console.log("INIT.TRANSLATION.bootstrap",properties);
      if (properties.languages?.length &&
        supportedLanguages?.length) {
        if (properties.languages.length!=supportedLanguages.length ||
          !properties.languages.every(language => supportedLanguages.includes(language))) {
          supportedLanguages = properties.languages;
          this.i18nService.init(this.i18nService.language,supportedLanguages);
          if (!properties.languages.includes(this.i18nService.language)) {
            // this.i18nService.setLanguage(properties.languages[0]);
            this.i18nService.setLanguage(defaultLanguage(properties));
          }
        }
      }
    });
    this.subscriptionBundle.add(propertiesSubscription);
    return new Promise((resolve, reject) => {
      const backoff = (attempt: number) => {
        const minBackoff = 500;   //1000
        const maxBackoff = 30000; //5000
        const exponent = 2;
        const factor = 0.5;
        let backoff = minBackoff * Math.pow(exponent, attempt);
        if (factor) {
          const rand =  Math.random();
          const deviation = Math.floor(rand * factor * backoff);
          backoff = (Math.floor(rand * 10) & 1) == 0  ? backoff - deviation : backoff + deviation;
        }
        // console.debug('BACKOFF', backoff);
        return Math.min(backoff, maxBackoff);
      };
      const setLanguage = (attempt = 0) => {
        const trigger = attempt == 0 ? this.i18nService.setLanguage(defaultLanguage(properties)) : this.i18nService.reloadLanguage();
        trigger
          .then(() => resolve())
          .catch((error) => {
            this.logger.warn(`Failed to load translation labels attempt ${attempt}`);
            if (attempt++ < 10) {
              return new Promise<void>((resolve) => {
                window.setTimeout(() => resolve(), backoff(attempt));
              }).then(() => setLanguage(attempt))
            } else {
              this.logger.error(`Failed to load translation labels. No more attempts will be made.`);
              reject(error);
            }
          })
      };
      setLanguage();
    });
  }

  protected openOverlay(): AppInstallOverlayRef {
    console.log('OPEN OVERLAY');
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = new OverlayConfig({
      // hasBackdrop: true,
      // backdropClass: '',
      panelClass: ['app-install', 'overlay'],
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
    const overlayRef =  this.overlay.create(overlayConfig);
    const dialogRef = new AppInstallOverlayRef(overlayRef);

    // const injectionTokens = new WeakMap();
    // injectionTokens.set(AppInstallOverlayRef, dialogRef);
    const providers: StaticProvider[] = [];
    providers.push({ provide: AppInstallOverlayRef, useValue: dialogRef })

    const data: any = { user: this.propertiesService.user };
    const parts: string[] = (location.pathname || '').split('/').filter(part => !!part);
    const invitationCode =
      parts.length==3 && parts[0]=='login' && parts[1]=='guest' ? parts[2] :
        parts.length==2 && parts[0]=='join' ? parts[1] : undefined;
    if (invitationCode) {
      data.code = invitationCode;
      data.link = invitationCode ? `${this.environment.serverUrl}${location.pathname}` : undefined;
    }

    providers.push({ provide: APP_INSTALL_OVERLAY_DATA, useValue: data });
    const injector = Injector.create({providers, parent: this.injector });
    // injectionTokens.set(APP_INSTALL_OVERLAY_DATA, data);
    // const injector = new PortalInjector(this.injector, injectionTokens);

    const containerPortal = new ComponentPortal(AppInstallOverlayComponent, null, injector);
    const componentRef: ComponentRef<AppInstallOverlayComponent> = overlayRef.attach(containerPortal);
    /*
     * Session cloning reloads properties after performing server-side login with authenticationToken
     * We assume properties reload is as signal to close this overlay which could have been displayed.
     */

    const subscription = this.propertiesService.properties$
      .subscribe((properties) => {
        if (properties.user.isAuthenticated) {
          dialogRef.close();
        }
      });

    // on ios dialogRef.close(); is called before execution reaches componentRef.onDestroy()
    // producing Error: View has already been destroyed
    // componentRef.onDestroy(() => subscription?.unsubscribe());

    // onClose observable is sourced by ReplaySubject i.e. will emit its current value on every subscription call
    // (even after the dialog has already been closed)
    dialogRef.onClose
      .pipe(take(1))
      .subscribe(() => subscription?.unsubscribe());
    return dialogRef;
  }

  @HostBinding('style')
  get style() {
    return this.error ?
      { backgroundColor: this.environment.primaryColor }
      : null;
  }
}
