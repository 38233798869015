import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Contact, ENVIRONMENT, Logger, Platform, ymdToDate} from 'core';
import {Contact as NativeContact, Contacts, IContactAddress} from "@ionic-native/contacts";
import unionWith from 'lodash/unionWith';
import isEqual from 'lodash/isEqual';
import {TranslateService} from "@ngx-translate/core";
import omitBy from "lodash/omitBy";
import set from "lodash/set";
import {AndroidPermissionResponse, AndroidPermissions} from "@ionic-native/android-permissions/ngx";
import {SessionTokenService} from "session";

interface ContactAddressBookAddDialogComponentData {
  contact: Contact;
}

class ContactError {
  constructor(public message: string, public inner?: Error) {}
}

@Component({
  selector: 'lib-contact-address-book-add-dialog',
  template: `
    <h1 mat-dialog-title align="center" translate>Add Contact</h1>
    <div mat-dialog-content class="content">
      <mat-selection-list>
        <mat-list-option *ngFor="let option of options; let index = index;" (click)="onSelectOption(option)">
          <span translate>contact.actions.{{option}}</span>
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close cdkFocusInitial><span translate>actions.cancel</span></button>
    </div>
  `,
  styles: [`
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    mat-selection-list ::ng-deep .mat-pseudo-checkbox {
      display: none !important;
    }
  `]
})
export class ContactAddressBookAddDialogComponent implements OnInit {

  options = ['create', 'merge'];
  protected contact: Contact;
  protected nativeContact: NativeContact;

  protected logger = new Logger('ContactAddressBookAddDialogComponent');

  constructor(
    protected contacts: Contacts,
    protected translateService: TranslateService,
    protected sessionTokenService: SessionTokenService,
    protected renderer: Renderer2,
    protected platform: Platform,
    protected androidPermissions: AndroidPermissions,
    @Inject(ENVIRONMENT)  protected environment: any,
    public dialogRef: MatDialogRef<ContactAddressBookAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContactAddressBookAddDialogComponentData) {
    this.contact = data?.contact;
  }

  ngOnInit(): void {
  }

  onSelectOption(option: string) {
    if (this.contact) {
      let promise: Promise<any>;
      switch (option) {
        case 'create' : promise = this.onCreate().catch((error) => new ContactError('onCreate', error)); break;
        case 'merge'  : promise = this.onMerge().catch((error) => new ContactError('onMerge', error)); break;
      }
      if (promise) {
        promise.then(
          result => this.dialogRef.close(true),
          // error => {
          //   this.logger.error(error);
          //   this.dialogRef.close(false)
          // }
        ).catch((error) => {
          this.logger.error(error);
          this.dialogRef.close(false);
        });
      }
    }
  }

  protected onCreate(): Promise<any> {
    this.logger.debug('Contact Add');
    return this.getNativeContact().then((contact) => contact.save())
  }

  protected onMerge(): Promise<any> {
    this.logger.debug('Contact Merge');
    // const filter = this.contact.email || this.contact.phone || this.contact.name;
    // const desiredFields: ContactFieldType[] = ['*'];
    // const options: IContactFindOptions = new ContactFindOptions(filter, true, desiredFields);
    // const searchFields: ContactFieldType[] = ['emails', 'phoneNumbers', 'name'];
    // this.contacts.find(searchFields, options)
    return this.contacts.pickContact()
      .then((pickedContact: NativeContact) => {
        this.logger.debug('Picked Contact', pickedContact);
        if (pickedContact) {
          // const mergedContact = pickedContact.clone();
          const mergedContact = this.cloneContact(pickedContact);
          this.logger.debug('Cloned Contact', mergedContact);
          return pickedContact.remove() // remove and save because direct update crashes the app - seems bug in the polugin!!
            .then(() => this.getNativeContact())
            .then(sourceContact => {
              this.logger.debug('Source Contact', sourceContact);
              const merge = (property) => {
                if (sourceContact[property] instanceof Array && sourceContact[property]?.length > 0) {
                  const predicate = (value, key) => key!='value';
                  if (property=='photos') {
                    // for now we just replace photos until we find a way to reliably compare them.
                    mergedContact[property] = sourceContact['photos'];
                  } else {
                    mergedContact[property] = unionWith(
                      mergedContact[property],
                      sourceContact[property],
                      (merged: object, source: object) => isEqual(omitBy(merged, predicate), omitBy(source, predicate))
                    );
                  }
                } else if (sourceContact[property] instanceof Object) {
                  Object.keys(sourceContact[property]).forEach((key) => {
                    if (sourceContact[property][key]) {
                      set(mergedContact, `${property}.${key}`, sourceContact[property][key]);
                    }
                  });
                } else if (sourceContact[property]) {
                  mergedContact[property] = sourceContact[property];
                }
              };
              merge('name');
              merge('emails');
              merge('phoneNumbers');
              merge('addresses');
              // merge('birthday');
              merge('photos');
            this.logger.debug('Merged Contact', mergedContact);
            const save = (): Promise<any> => {
              return mergedContact
                .save()
                .then((contact) => {
                  this.logger.debug('Merged Contact saved', contact);
                  return contact;
                });
            };
            return this.platform.ready().then(() => {
              if (this.platform.is('android')) {
                const checkReadPermission = this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_CONTACTS);
                const checkWritePermission = this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_CONTACTS);
                return Promise.all([checkReadPermission, checkWritePermission])
                       .then((result: AndroidPermissionResponse[]) => {
                          const hasReadPermission  = result[0].hasPermission;
                          const hasWritePermission = result[1].hasPermission;
                          const permissions = [];
                          if (!hasReadPermission) {
                            permissions.push(this.androidPermissions.PERMISSION.READ_CONTACTS);
                          }
                          if (!hasWritePermission) {
                            permissions.push(this.androidPermissions.PERMISSION.WRITE_CONTACTS);
                          }
                          if (permissions.length) {
                            return this.androidPermissions.requestPermissions(permissions).then(result => {
                              if (result.hasPermissions) {
                                return save();
                              } else {
                                throw new Error('CONTACT READ/WRITE NOT ALLOWED!')
                              }
                            });
                          } else {
                            return save();
                          }
                       });
              } else {
                return save();
              }
            });
          });
        } else {
          return Promise.reject('Invalid target contact');
        }
      })
      .catch(error => {
        this.logger.error('Failed to merge contact', error);
        throw error;
      }
    );
  }

  protected getNativeContact(): Promise<NativeContact> {
    if (!this.nativeContact) {
      const contact: NativeContact = this.contacts.create();
      contact.displayName = this.contact.name;
      contact.nickname = this.contact.name;
      contact.name = {
        // formatted: this.contact.name,
        givenName: this.contact.firstName,
        familyName: this.contact.lastName
      };
      contact.emails = this.contact.email ? [{ value: this.contact.email }] : [];
      contact.phoneNumbers = this.contact.phone ? [{ value: this.contact.phone }] : [];
      // birthday expected by plugin is string but ionic-native typedefs declare it as Date
      // which leads to runtime exception which is catched and reported by the plugin
      // contact.birthday = this.contact.birthday ? ymdToDate(this.contact.birthday) : undefined;
      const logger = this.logger;
      return new Promise<NativeContact>((resolve, reject) => {
        if (this.contact.address || this.contact.zip || this.contact.countryCode) {
          const address: IContactAddress = {
            pref: false,
            type: 'home',
            // formatted: this.contact.address,
            streetAddress: this.contact.address || '',
            // locality: '',
            // region: '',
            postalCode: this.contact.zip || '',
          };
          contact.addresses = [address];
          if (this.contact.countryCode) {
            this.translateService
              .get(`countries.${this.contact.countryCode.toUpperCase()}`)
              .toPromise()
              .then(country => {
                address.country = country || this.contact.countryCode;
                this.logger.debug('getNativeContact() country', country);
                resolve(contact);
              });
              return;
          }
        }
        resolve(contact);
      })
      .then(contact => {
        return new Promise<NativeContact>((resolve, reject) => {
          let canvas: HTMLCanvasElement = this.renderer.createElement('canvas');
          const context = canvas.getContext('2d');
          const image = new Image();
          // image.crossOrigin = 'Anonymous';
          image.crossOrigin = 'use-credentials'; // enough for android. for ios we need to rewrite the src see below
          image.onload = function() {
            canvas.height = image.height;
            canvas.width = image.width;
            context.drawImage(image,0,0);
            const dataURL = canvas.toDataURL('image/png');
            logger.debug('PHOTO BASE64', dataURL);
            contact.photos = [{
              "type": "base64",
              "value": dataURL
            }];
            canvas = null;
            resolve(contact);
          };
          image.onerror = (error) => reject(error);
          const src = `${this.environment.serverUrl}/v1.0/content/avatar/${this.contact.version || 0}/${this.contact.id}.jpg`;
          image.src = this.sessionTokenService.rewrite(src);
        })
      })
      .then(contact => {
        this.nativeContact = contact;
        return contact;
      })
      .catch(error => {
        this.logger.error(error);
        throw error;
      });
    }
    return Promise.resolve(this.nativeContact);
  }

  /**
   * mb: Adapted from cordova-js source utils.clone()
   * https://github.com/apache/cordova-js/blob/master/src/common/utils.js
   *
   * Does a deep clone of the object.
   */
  protected clone(obj: any, ret?: any) {

    // ========= HELPER FUNCTIONS ===============

    const typeName = function (val) {
      return Object.prototype.toString.call(val).slice(8, -1);
    };

    /**
     * Returns an indication of whether the argument is an array or not
     */
    const isArray = Array.isArray || function (a) { return typeName(a) === 'Array'; };

    /**
     * Returns an indication of whether the argument is a Date or not
     */
    const isDate = function (d) {
      return (d instanceof Date);
    };

    // ========================

    if (!obj || typeof obj === 'function' || isDate(obj) || typeof obj !== 'object') {
      return obj;
    }

    var retVal, i;

    if (isArray(obj)) {
      retVal = [];
      for (i = 0; i < obj.length; ++i) {
        retVal.push(this.clone(obj[i]));
      }
      return retVal;
    }

    retVal = ret || {};
    for (i in obj) {
      this.logger.debug('clone', i);
      // added the following guard condition to avoid cloning
      // of "_objectInstance" Contact property introduced by ionic-native
      // if (i != '_objectInstance') {
        // 'unknown' type may be returned in custom protocol activation case on
        // Windows Phone 8.1 causing "No such interface supported" exception on
        // cloning (https://issues.apache.org/jira/browse/CB-11522)
        // eslint-disable-next-line valid-typeof
        if ((!(i in retVal) || retVal[i] !== obj[i]) && typeof obj[i] !== 'undefined' /*&& typeof obj[i] !== 'unknown'*/) {
          this.logger.debug('clone', i, obj[i]);
          retVal[i] = this.clone(obj[i]);
        }
      // }
    }
    return retVal;
  };

  /*
   * mb: Adapted from cordova-plugin-contacts Contact.js
   *
   * The Contact.clone() method is coded properly in the plugin itself.
   * However the ionic-native wrapper provides its own but wrong implementation if this api.
   * Namely it returns 'undefined' if the passed in object has an id property.
   * What is the motivation behind this behavior and why it does not forward the call the the plugin itself is unclear.
   * In order to overcome this problem we have replicated with the code from the plugin here.
   */
  cloneContact(contact: NativeContact) {
    // important: we need to create NativeContact not just object literal (which is the default used by clone() method)
    // because the ionic-native Contact object constructor defines getters and setters
    // for each field in the corresponding plugin Contact object e.g:
    // Object.defineProperty(Contact.prototype, "name", {
    //         get: function () { return instancePropertyGet(this, "name"); },
    //         set: function (value) { instancePropertySet(this, "name", value); },
    //         enumerable: true,
    //         configurable: true
    //     });
    // Thus the object state is stored in an internal private variable named _instanceObject
    // which seems to be a general approach taken by @ionic-native lib with regard to various plugins.
    const clonedContact = this.clone(contact, new NativeContact());
    this.logger.debug('cloneContact', clonedContact);
    clonedContact.id = null;
    clonedContact.rawId = null;

    function nullIds(arr) {
      if (arr) {
        for (var i = 0; i < arr.length; ++i) {
          arr[i].id = null;
        }
      }
    }

    // Loop through and clear out any id's in phones, emails, etc.
    nullIds(clonedContact.phoneNumbers);
    nullIds(clonedContact.emails);
    nullIds(clonedContact.addresses);
    nullIds(clonedContact.ims);
    nullIds(clonedContact.organizations);
    nullIds(clonedContact.categories);
    nullIds(clonedContact.photos);
    nullIds(clonedContact.urls);
    return clonedContact;
  }
}
