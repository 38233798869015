import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  INJECTOR,
  Injector
} from '@angular/core';
import {ENVIRONMENT} from "core";
import {RenderPosition} from "../../../store/models";
import {AuthenticationService} from "auth";
import {AttachmentComponentFactoryService} from "../../attachment/attachment.component.factory.service";
import {ChatService} from "../../../chat.service";
import {LayoutService} from "layout";
import {ChatMessageCoreComponent} from "../chat-message-container/chat-message-core.component";

@Component({
  selector: 'chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent extends ChatMessageCoreComponent {

  constructor(public authenticationService: AuthenticationService,
              public elementRef: ElementRef,
              public chatService: ChatService,
              public layoutService:LayoutService,
              public attachmentService: AttachmentComponentFactoryService,
              public resolver: ComponentFactoryResolver,
              @Inject(INJECTOR) public injector:Injector,
              @Inject(ENVIRONMENT) public environment: any) {
    super(authenticationService, elementRef, chatService, layoutService, attachmentService, resolver, injector, environment);
  }

  get position():RenderPosition {
    return this.mine==true ? 'right' : this.mine==false ? 'left' : 'center';
  }
}
