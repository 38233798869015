import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output, TemplateRef,
  Type, ViewChild,
  ɵComponentDef,
  ɵComponentType,
  ɵRenderFlags,
  ɵɵdefineComponent,
  ɵɵelement,
  ɵɵproperty
} from '@angular/core';
import {BasicContainerComponent} from "shared";
import {Logger, Topic} from "core";
import {PersonalityColorsComponent} from "./personality/personality.colors.component";

// HOC tutorial:
// https://www.angulararchitects.io/en/aktuelles/architecture-with-angular-ivy-part-2-higher-order-and-dynamic-components/
// https://dev.to/this-is-angular/component-features-with-angular-ivy-213h

export function surveyResult(types: Type<any>[]) {
  @Component({template: '', standalone: true})
  class SurveyResult {
    static ɵcmp: ɵComponentDef<any>; //<SurveyResult>
    static ɵfac: () => SurveyResult;
    survey: Topic;
    selectedTags: string[];
    logger = new Logger('SurveyResult');
    constructor() {
    }
    ngOnInit() {
      this.logger.debug('ngOnInit');
    }
  }
  SurveyResult.ɵfac = () => new SurveyResult(); // ɵɵdirectiveInject(...)
  SurveyResult.ɵcmp = ɵɵdefineComponent({
    vars: 2,
    decls: 2,
    // consts: [[3, "survey"]],
    changeDetection: ChangeDetectionStrategy.Default,
    selectors: [['survey-result']],
    dependencies: [PersonalityColorsComponent], //[SurveyPersonalityResultComponent, SurveyVitalityResultComponent],
    template: (rf, ctx) => {
      if (rf & ɵRenderFlags.Create) {
        types.forEach((type, index) => {
          // At runtime this will be a ɵComponentType<any> with a static ngComponentDef property
          const ngComponent = type as ɵComponentType<any>;
          const def = ngComponent.ɵcmp as ɵComponentDef<any>;
          const elementName = def.selectors[0][0] as string;
          ɵɵelement(index, elementName);
        });
      }
      if (rf & ɵRenderFlags.Update) {

        ɵɵproperty('survey', ctx.survey);
        ɵɵproperty('selectedTags', ctx.selectedTags);
        ɵɵproperty('displayTag', 'personality.survey');
      }
    },
    type: SurveyResult,
  });
  return SurveyResult;
}


@Component({
  selector: 'app-survey-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyResultComponent extends BasicContainerComponent {

  @Input() survey: Topic;
  @Input() selectedTags: string[] = [];
  @Input() readOnly = false;
  @Input() displayTag:string = undefined; // which tag "seals" the survey
  @Input() badgeData:string = undefined;
  @Input() defaultInfo = false;

  @ViewChild('defaultResultTemplate') defaultResultTemplate: TemplateRef<any>;

  protected _ready:boolean = false;
  set ready(ready:boolean) {
    if (this._ready!=ready) {
      console.log("SET.READY",ready);
      this._ready = ready;
      this.onReady.emit(ready);
    }
  }
  get ready():boolean {
    return this._ready;
  }

  protected _display:boolean = false;
  set display(display:boolean) {
    if (this._display!=display) {
      console.log("SET.DISPLAY",display);
      this._display = display;
      this.onDisplay.emit(display);
    }
  }
  get display():boolean {
    return this._display;
  }

  @Output() onReady = new EventEmitter<boolean>();
  @Output() onDisplay = new EventEmitter<boolean>();

  constructor() {
    super();
  }
}
