<virtual-section-scroller #scroller [scrollToAdded]="scroller"
  [log]="log"
  [items$]="timelineMessages$"
  [itemsId$]="conversationId$"
  [sectionIndexes$]="timelineMessageSections$"
  [itemHeaderElementAccessor]="itemHeaderElementAccessor"
  [startFromTop]="startFromTop"
  [itemDefaultSize]="200"
  [bufferSize]="600"
  [endGap]="8"
  (onViewportChanged)="onViewportChanged()"
  (onScrolled)="onScrolled($event)"
  (onResize)="onResize($event)">
  <!--
(onViewportUpdated)="onViewportUpdated($event)"-->
  <!--
                          [sectionHeaderElementAccessor]="sectionHeaderElementAccessor"
  (onRendered)="onRendered($event)"
  (onScrolled)="onScrolled($event)"
  -->
  <ng-container *ngLet="viewport$ | async as viewport">
    <ng-container *virtualFor="let message of viewport.render offset viewport.offset total viewport.total log false; let index=index; let total=total; let offset=offset; trackBy:trackBy">
      <div>
        <ng-container *ngIf="viewport.indices.has(index)">
          <ng-container *ngTemplateOutlet="sectionHeaderTemplateRef; context: { item: message, index: index }"></ng-container>
        </ng-container>
        <chat-message-row [message]="message" [index]="index" [total]="total" [space]="viewport.spaces[index-offset]"
                          [parent]="!parentId || parentId!=message.parentId">
        </chat-message-row>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #sectionHeaderTemplate let-message="item" let-index="index">
    <div class="section-header">
      <span *ngIf="!!message?.timeCreated">{{message?.timeCreated | date}}</span>
    </div>
  </ng-template>
</virtual-section-scroller>

<!-- <button mat-fab color="accent" (click)=""> -->
<button mat-mini-fab color="basic" [class.show]="showDownButton" (click)="scrollToBottom()">
  <mat-icon aria-label="down">keyboard_arrow_down</mat-icon>
</button>

