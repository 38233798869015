<!--{{'VALUE:'+(taskForm?.getRawValue() | json)}}-->
<ng-container [ngSwitch]="editMode">
  <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="view"></ng-container>
  <ng-container *ngSwitchCase="true"  [ngTemplateOutlet]="edit"></ng-container>
</ng-container>

<ng-template #view>
  <div #scroller *ngIf="task" class="view">
    <div class="title section">{{task.title | translate: task.properties}}</div>
    <ng-container *ngIf="task.remark as remark">
      <mat-divider class="section"></mat-divider>
      <more-text [text]="remark | translate: task.properties"
                 class="remark section">
      </more-text>
    </ng-container>
    <ng-container *ngIf="task.info as info">
      <mat-divider class="section"></mat-divider>
      <more-text [text]="info | translate: task.properties"
                 class="info section">
      </more-text>
    </ng-container>
    <div *ngIf="task.targets?.length" class="targets-container section">
      <div *ngIf="task?.getTarget('media') as target"
           (click)="targetDisplay.emit(target)"
           class="target target-media">
        <!--
             TODO: displaying of media with respect to its type should be wrapped in a reusable piece of code
             Identical code exists in the template of TaskListItemComponent as well
        -->
        <!--        <mat-label translate>tasks.target</mat-label>-->
        <!--
        <ng-container [ngSwitch]="target?.media.mediaType=='survey'">
          <ng-container *ngSwitchCase="true">
            <ng-container *ngIf="surveyResult as result" [ngSwitch]="result[0]?.id">
              <personality-colors *ngSwitchCase="'personality'"
                                  [colors]="result[1]"
                                  [class]="result[2]">
              </personality-colors>
              <vitality-images *ngSwitchCase="'vitality'"
                               [survey]="result[0]"
                               [vitalityTypes]="result[1]"
                               [class]="result[2]"
                               [iconOnly]="true">
              </vitality-images>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div *ngIf="media$(target.media) | async as media" class="media-card-container">
              <media-card [media]="media"
                          [elevated]="false"
                          [indicator]="false"
                          [fullCover]="true"
                          [imageMaxWidth]="48">
              </media-card>
              <ng-container *ngLet="mediaService.getMediaPlayedBadgeInfo(media) as badge">
                <div *ngIf="!!badge && !mediaService.isFolderMedia(media)"
                     class="badge played {{badge.class}}"
                     [ngSwitch]="badge.icon">
                  <mat-icon *ngSwitchCase="'eye'">visibility</mat-icon>
                  <ng-container *ngSwitchDefault>
                    <mat-icon *ngIf="badge.icon">{{badge.icon}}</mat-icon>
                  </ng-container>
                  <span *ngIf="badge.label" translate>{{badge.label}}</span>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        -->
        <media-icon [media]="target.media" [playable]="true"></media-icon>
        <span [innerHTML]="(target.media?.name ?? target.media?.id) | translate | safeHtml"></span>
      </div>
      <div *ngIf="task?.getTarget('contact') as target"
           (click)="targetDisplay.emit(target)"
           class="target target-contact ">
        <!--        <mat-label translate>tasks.target</mat-label>-->
<!--        <div>-->
          <img matListItemAvatar
               [src]="getAvatarUrl(target.contact) | sessionLink"
               [style.width]="'40px'"
               [style.height]="'40px'"
               onload="this.style.opacity=1"/>
          <span>{{target.contact?.name}}</span>
<!--        </div>-->
      </div>
      <div *ngIf="task?.getTarget('action') as target"
           (click)="targetDisplay.emit(target)"
           class="target target-action">
       <mat-icon>link</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div class="actions">
      <ng-container *ngIf="taskForm.controls.groupMembership.value as groupMembership">
        <partner-detail *ngIf="task?.getTarget('contact')?.contact?.id as contactId"
                        [groupMembership]="groupMembership"
                        [contactId]="contactId"
                        (onGroupMembershipChange)="taskForm.controls.groupMembership.setValue($event)">
        </partner-detail>
      </ng-container>

      <ng-container *ngIf="taskForm.controls.mediaReview.value">
        <div>
          <mat-label translate>media.actions.review</mat-label>
          <media-review [value]="taskForm.controls.mediaReview.value"
                        (onMediaReviewChange)="taskForm.controls.mediaReview.setValue($event)">
          </media-review>
        </div>
      </ng-container>
    </div>
    <div class="properties section" *ngIf="task && {
                     formatDMH: ('tasks.formatDMH' | translate)
               } as context">
      <div *ngIf="task?.timeScheduled as timeScheduled" class="time">
        <h1 translate>tasks.dateScheduled</h1>
        <mat-icon>schedule</mat-icon>
        <span>{{timeScheduled | date: context.formatDMH}}</span>
      </div>
      <div *ngIf="task?.timeDeadline as timeDeadline" class="time" [ngClass]="overdue ? 'overdue' : undefined">
        <h1 translate>tasks.dateDeadline</h1>
        <mat-icon>pending_actions</mat-icon>
        <span>{{timeDeadline | date: context.formatDMH}}</span>
      </div>
      <span *ngIf="propertiesService.user.isAdmin && task.id as id"
            [innerHTML]="'tasks.property' | translate:{
            name: 'tasks.id' | translate,
            value: id
          } | safeHtml"
            style="user-select:text">
      </span>
      <span *ngIf="task.taskList?.title as taskList"
            [innerHTML]="'tasks.property' | translate:{
            name: 'tasks.taskList.label' | translate,
            value: ((task.taskList.id <= 0 && taskList.startsWith('tasks.taskList.') ? (taskList | translate) : taskList) +
                    (taskTypeLabel ? ' (' + (taskTypeLabel | translate) + ')' : ''))
          } | safeHtml">
      </span>
      <span *ngIf="task?.timeCreated as timeCreated"
            [innerHTML]="'tasks.property' | translate:{
            name: 'tasks.dateCreated' | translate,
            value: timeCreated | date: 'LLL'
          } | safeHtml">
      </span>
    </div>

    <ng-container *ngIf="taskForm.value['type']!='private'"
                  [ngTemplateOutlet]="participantsList"
                  [ngTemplateOutletContext]="{
                    participants: taskForm.controls.participants.value,
                    edit: task.isEditable('participants')
                  }">
    </ng-container>


    <ng-container [ngTemplateOutlet]="attachmentsList"
                  [ngTemplateOutletContext]="{
                    attachments: taskForm.controls.attachments.value,
                    edit: task.isEditable('attachments')
                  }">
    </ng-container>

    <!--
       ATTENTION: autofocus=true moves the focused element into view (at least on chrome)
       This is a problem when the element is in the dom but is not supposed to be visible e.g.
       when using app-slide-panel.
     -->
    <!--
    <mat-form-field *ngIf="task.title" class="no-disabled-color">
      <mat-label translate>tasks.title</mat-label>

      <input type="text"
             unmodified-input
             matInput
             [value]="task.title"
             [autoFocus]="false"
             disabled/>
    </mat-form-field>
    <mat-form-field *ngIf="task.info" class="no-disabled-color">
      <mat-label translate>tasks.info</mat-label>
      <textarea matInput
                unmodified-input
                [value]="task.info"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8"
                disabled>
      </textarea>
    </mat-form-field>
    <mat-form-field *ngIf="date(task.timeScheduled) as dateScheduled" class="no-disabled-color">
      <mat-label translate>tasks.dateScheduled</mat-label>
      <input type="text"
             unmodified-input
             matInput
             [value]="dateScheduled | date: 'LLL'"
             disabled/>
    </mat-form-field>
    <mat-form-field *ngIf="date(task.timeDeadline) as timeDeadline" class="no-disabled-color">
      <mat-label translate>tasks.dateDeadline</mat-label>
      <input type="text"
             unmodified-input
             matInput
             [value]="timeDeadline | date: 'LLL'"
             disabled/>
    </mat-form-field>
    <mat-form-field *ngIf="task.taskList.title" class="no-disabled-color">
      <mat-label translate>tasks.taskList.label</mat-label>
      <input type="text"
             unmodified-input
             matInput
             [value]="task.taskList.title | translate"
             disabled/>
    </mat-form-field>
    <mat-form-field *ngIf="task.target?.contact as target" class="no-disabled-color target" (click)="targetDisplay.emit(target)">
      <mat-label translate>tasks.target</mat-label>
      <span matPrefix>
          <ng-container *ngTemplateOutlet="avatarTemplate; context: {contact: target}"></ng-container>
        </span>
      <input type="text"
             unmodified-input
             matInput
             [value]="target.name"
             disabled/>
    </mat-form-field>
    <ng-container *ngIf="taskForm.value['type']!='private' &&
                         taskForm.controls.participants.value?.length"
                  [ngTemplateOutlet]="participantsList"
                  [ngTemplateOutletContext]="{ participants: task.participants, edit: false }">
    </ng-container>
    <mat-form-field *ngIf="task.type" class="no-disabled-color">
      <mat-label translate>tasks.type.label</mat-label>
      <input type="text"
             unmodified-input
             matInput
             [value]="task.type"
             disabled/>
    </mat-form-field>
    -->

    <ng-container *ngTemplateOutlet="controlsTemplate; context: { task: current }"></ng-container>
  </div>
</ng-template>

<ng-template #edit>
  <form #scroller left [formGroup]="taskForm" class="edit">
    <mat-form-field>
      <mat-label translate>tasks.title</mat-label>
      <input type="text"
             unmodified-input
             matInput
             formControlName="title"
             name="title"
             [autoFocus]="autoFocus"
             required/>
      <mat-error *ngIf="taskForm.controls.title.invalid && taskForm.controls.title.touched">
        <span translate>tasks.validation.titleRequired</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label translate>tasks.info</mat-label>
      <!--<mat-icon matPrefix>theaters</mat-icon>-->
      <textarea matInput
                unmodified-input
                formControlName="info"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8">
      </textarea>
    </mat-form-field>
    <mat-form-field class="timeScheduled selector" (click)="onTapDatePicker('timeScheduled', timeScheduled, timeScheduledPicker, $event)">
      <mat-label translate>tasks.dateScheduled</mat-label>
      <mat-icon matPrefix>event</mat-icon>
      <input #timeScheduled
             matInput
             formControlName="timeScheduled"
             disabled
             [min]="timeScheduledMin"
             [max]="timeScheduledMax"
             [ngxMatDatetimePicker]="timeScheduledPicker"
             [errorStateMatcher]="dateTimeErrorStateMatcher"
             (dateChange)="onTimeScheduledChange($event)"
             aria-label="date scheduled"/>
      <button *ngIf="timeScheduled.value"
              mat-icon-button
              matSuffix
              tabindex="-1"
              aria-label="clear">
        <mat-icon>close</mat-icon>
      </button>
      <mat-datepicker-toggle matSuffix [for]="timeScheduledPicker">
        <mat-icon matDatepickerToggleIcon>today</mat-icon>
      </mat-datepicker-toggle>
      <ngx-mat-datetime-picker #timeScheduledPicker
                               [showSpinners]="true"
                               [showSeconds]="false"
                               [stepHour]="1"
                               [stepMinute]="1"
                               [touchUi]="platform.is('mobile')"
                               [color]="'primary'"
                               [enableMeridian]="false"
                               [disableMinute]="false"
                               [hideTime]="false">
        <ngx-mat-datepicker-actions>
          <button mat-button ngxMatDatepickerCancel><span translate>actions.cancel</span></button>
          <button mat-raised-button color="primary" ngxMatDatepickerApply><span translate>actions.ok</span></button>
        </ngx-mat-datepicker-actions>
      </ngx-mat-datetime-picker>
      <mat-error *ngIf="taskForm.controls.timeScheduled.hasError('matDatetimePickerMin')">
        <span translate>tasks.validation.dateScheduledMin</span>
      </mat-error>
      <mat-error *ngIf="taskForm.controls.timeScheduled.hasError('matDatetimePickerMax')">
        <span translate>tasks.validation.dateScheduledMax</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="timeDeadline selector"  (click)="onTapDatePicker('timeDeadline', timeDeadline, timeDeadlinePicker, $event)">
      <mat-label translate>tasks.dateDeadline</mat-label>
      <mat-icon matPrefix>event</mat-icon>
      <input #timeDeadline
             matInput
             formControlName="timeDeadline"
             disabled
             [min]="timeDeadlineMin"
             [max]="timeDeadlineMax"
             [ngxMatDatetimePicker]="timeDeadlinePicker"
             [errorStateMatcher]="dateTimeErrorStateMatcher"
             (dateChange)="onTimeDeadlineChange($event)"
             aria-label="date deadline"/>
      <button tabindex="-1"
              *ngIf="timeDeadline.value"
              mat-icon-button
              matSuffix
              aria-label="clear">
        <mat-icon>close</mat-icon>
      </button>
      <mat-datepicker-toggle matSuffix [for]="timeDeadlinePicker">
        <mat-icon matDatepickerToggleIcon>today</mat-icon>
      </mat-datepicker-toggle>
      <ngx-mat-datetime-picker #timeDeadlinePicker >
        <ngx-mat-datepicker-actions>
          <button mat-button ngxMatDatepickerCancel><span translate>actions.cancel</span></button>
          <button mat-raised-button color="primary" ngxMatDatepickerApply><span translate>actions.ok</span></button>
        </ngx-mat-datepicker-actions>
      </ngx-mat-datetime-picker>
      <mat-error *ngIf="taskForm.controls.timeDeadline.hasError('matDatetimePickerMin')">
        <span translate>tasks.validation.dateDeadlineMin</span>
      </mat-error>
      <mat-error *ngIf="taskForm.controls.timeDeadline.hasError('matDatetimePickerMax')">
        <span translate>tasks.validation.dateDeadlineMax</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="{
                      taskList: taskForm.controls.taskList.getRawValue(),
                      disabled: !task.isEditable('taskList')
                    } as context"
                    formGroupName="taskList"
                    (click)="!context.disabled && onTaskListSelect($event)"
                    class="task-list selector">
      <mat-label translate>tasks.taskList.label</mat-label>
      <mat-icon matPrefix [style.--color]="taskListColor()">list</mat-icon>
      <!--input #taskList
             type="text"
             matInput
             unmodified-input
             formControlName="title"
             name="task list"/-->

      <input #taskList
             type="text"
             matInput
             unmodified-input
             disabled
             [value]="context.taskList?.id <= 0 &&
                      context.taskList?.title?.startsWith('tasks.taskList.')
                   ? (context.taskList.title  | translate)
                   :  context.taskList.title"
             name="task list"/>
      <button *ngIf="context.taskList?.id != '0' &&
                    !context.disabled"
              mat-icon-button
              matSuffix
              class="mat-suffix-center"
              aria-label="clear"
              tabindex="-1"
              (click)="onTaskListClear($event)">
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon *ngIf="!context.disabled" matSuffix>arrow_right</mat-icon>
      <mat-hint *ngIf="!context.disabled" align="end">
        <a class="action" (click)="onTaskListCreate($event); $event.stopPropagation()" translate>tasks.actions.createTaskList</a>
      </mat-hint>
    </mat-form-field>
    <mat-form-field *ngIf="{ disabled: !task.isEditable('target') } as context"
                    formGroupName="target"
                    (click)="!context.disabled && onTaskTargetSelect($event)"
                    class="target selector">
      <mat-label translate>tasks.target</mat-label>
<!--        <mat-icon matPrefix>person</mat-icon>-->
      <span matPrefix>
        <ng-container *ngTemplateOutlet="avatarTemplate; context: { contact: taskForm.controls.target?.value }"></ng-container>
      </span>
      <input type="text"
             matInput
             unmodified-input
             formControlName="name"
             disabled
             name="author"/>
      <mat-icon matSuffix *ngIf="!context.disabled">arrow_right</mat-icon>
    </mat-form-field>

    <ng-container [ngTemplateOutlet]="attachmentsList" [ngTemplateOutletContext]="{
                    attachments: taskForm.controls.attachments.value,
                    edit: task.isEditable('attachments')
                  }">
    </ng-container>

    <ng-container *ngIf="taskForm.value['type']!='private'"
                  [ngTemplateOutlet]="participantsList"
                  [ngTemplateOutletContext]="{
                    participants: taskForm.controls.participants.value,
                    edit: task.isEditable('participants')
                  }">
    </ng-container>

    <!--
    <mat-form-field *ngFor="let term of ['editors', 'viewers']">
      <mat-label translate>{{'tasks.terms.'+term | translate}}</mat-label>
      <mat-icon matPrefix>{{term=='editors' ? 'edit' : 'preview' }}</mat-icon>
      <input type="text"
             unmodified-input
             matInput
             [name]="term+'Term'"
             [formControlName]="term+'Term'"
             [attr.aria-label]="term+' term'"/>
    </mat-form-field>
    -->
    <!--
    <filter-tag-section [filter]="typesTopic"
                        [selected]="taskForm.value['type']"
                        (onFilter)="onTapTypeFilter($event)"
                        class="section">
    </filter-tag-section>
    -->
    <ng-container *ngTemplateOutlet="controlsTemplate; context: { task: current }"></ng-container>
  </form>
</ng-template>

<ng-template #avatarTemplate let-contact="contact">
  <div class="avatar">
    <img matListItemAvatar [src]="getAvatarUrl(contact) | sessionLink" onload="this.style.opacity=1"/>
    <presence [contactId]="contact?.id"></presence>
  </div>
</ng-template>

<ng-template #participantsList let-participants="participants" let-edit="edit">
  <ng-container *ngIf="edit || participants.length">
    <div class="participants" *ngIf="{ empty: !participants?.length } as context">
      <mat-expansion-panel #participantsPanel
                           [expanded]="!context.empty"
                           [hideToggle]="context.empty"
                           [disabled]="context.empty"
                           [class.empty]="context.empty"
                           class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span translate>tasks.participants</span>
          </mat-panel-title>
          <mat-panel-description *ngIf="edit && context.empty">
            <button tabindex="-1" mat-icon-button
                    (click)="$event.stopPropagation(); onParticipantSelect($event)"
                    class="participant-select">
              <mat-icon>add</mat-icon>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list dense>
          <ng-container *ngFor="let participant of participants; let index = index; let first = first">
            <mat-list-item>
              <div class="participant" matListItemTitle (click)="!edit && participantDisplay.emit(participant)">
                <ng-container *ngTemplateOutlet="avatarTemplate; context: {contact: participant}"></ng-container>
                <!--
                <img matListItemAvatar [src]="getAvatarUrl(participant)" onload="this.style.opacity=1"/>
                <presence [contactId]="participant?.id"></presence>
                -->
                <span class="name" translate>{{participant.name}}</span>
              </div>
              <button tabindex="-1" *ngIf="edit"
                      mat-icon-button
                      mat-mdc-icon-micro-button
                      matListItemMeta
                      (click)="$event.stopPropagation(); onParticipantRemove(participant)"
                      class="participant-remove">
                <mat-icon>clear</mat-icon>
              </button>
              <mat-divider [class.first]="first"></mat-divider>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </mat-expansion-panel>
      <a *ngIf="edit && !context.empty"
         (click)="onParticipantSelect($event)"
         class="action participant-select" translate>tasks.actions.addParticipants</a>
    </div>
  </ng-container>
</ng-template>

<ng-template #attachmentsList let-attachments="attachments" let-edit="edit">
  <ng-container *ngIf="edit || attachments.length">
    <div class="attachments" *ngIf="{ empty: !attachments?.length } as context">
      <mat-expansion-panel #attachmentsPanel
                           [expanded]="!context.empty"
                           [hideToggle]="context.empty"
                           [disabled]="context.empty"
                           [class.empty]="context.empty"
                           class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span translate>tasks.attachments</span>
          </mat-panel-title>
          <mat-panel-description *ngIf="edit && context.empty">
            <button tabindex="-1" mat-icon-button
                    (click)="$event.stopPropagation(); onAttachmentAdd($event)"
                    class="attachment-select">
              <mat-icon>add</mat-icon>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list dense>
          <ng-container *ngFor="let attachment of attachments; let index = index; let first = first">
            <mat-list-item>
              <div class="attachment" matListItemTitle (click)="!edit && attachmentDisplay.emit(attachment)">
                <ng-container *ngTemplateOutlet="attachmentTemplate; context: {attachment}"></ng-container>
              </div>
              <button tabindex="-1" *ngIf="edit"
                      mat-icon-button
                      mat-mdc-icon-micro-button
                      matListItemMeta
                      (click)="$event.stopPropagation(); onAttachmentRemove(attachment)"
                      class="attachment-remove">
                <mat-icon>clear</mat-icon>
              </button>
              <!--
              <mat-divider [class.first]="first"></mat-divider>
              -->
            </mat-list-item>
          </ng-container>
        </mat-list>
      </mat-expansion-panel>
      <a *ngIf="edit && !context.empty"
         (click)="onAttachmentAdd($event)"
         class="action attachment-select" translate>tasks.actions.addAttachment</a>
    </div>
  </ng-container>
</ng-template>

<ng-template #attachmentTemplate let-attachment="attachment">
  <media-card [media]="attachment"
              [showDuration]="true"
              [elevated]="false"
              (play)="attachmentDisplay.emit(attachment)"></media-card>
  <div class="attachment-details">
    <div>
      <span>{{attachment.timeProduced | date:'ll'}}</span>
      <span *ngIf="attachment.author?.name || attachment.published?.contact?.name; let author">{{author}}</span>
    </div>

  </div>
</ng-template>
