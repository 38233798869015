<div cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropArrange($event)">
  <ng-container *ngFor="let attachment of mediaAttachments; trackBy:trackBy">
    <ng-container *ngIf="getMedia$(attachment) | async; let media">
      <ng-container *ngLet="getDimension(media) as dimension">
        <media-card #card cdkDrag cdkDragLockAxis="x"
                    [media]="media"
                    [rounded]="true"
                    [elevated]="false"
                    [selectable]="true"
                    [indicator]="false"
                    [style.width]="dimension.width+4+'px'"
                    [defaultCoverHeight]="dimension.pct"
                    [defaultCover]="true"
                    (play)="onPlayMedia(media)">
          <div cdkDragHandle *ngIf="attachments?.length>1"
               class="drag">
            <mat-icon>drag_indicator</mat-icon>
          </div>
          <button mat-icon-button [class.confirm]="attachment===confirmAttachment"
                  class="delete mat-primary"
                  (clickOutside)="clickedOutside(attachment)"
                  (click)="delete(attachment)">
            <mat-icon>close</mat-icon>
          </button>
          <ng-container *ngIf="card.mediaDuration(media); let duration;">
            <span class="badge control bottom right">{{duration}}</span>
          </ng-container>
        </media-card>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
