import {Inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EMPTY, from} from "rxjs";
import {Store} from "@ngrx/store";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import {APP_ID, ENVIRONMENT, noAction} from "core";
import {HttpClient} from "@angular/common/http";
import {
  conferenceHangUpMessageAction,
  conferenceInviteMessageSendAction,
  conferenceMediaMessageAction,
  conferencePauseMessageAction,
  conferencePickUpMessageAction,
  conferenceSynchronizeStatesAction,
} from "./actions";
import {State} from "./state";
import {MessagingService} from "messaging";

@Injectable()
export class ConferenceEffects {
  synchronizeConferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conferenceSynchronizeStatesAction),
      //debounceTime(100),
      mergeMap((action) => {
        //console.log("ACTION",action);
        //console.log("SEND SYNCHRONIZE MESSAGE",action.message,action);
        return from(
          this.messagingService.sendMessage(action.message, true)
        ).pipe(
          map((result) => {
            action.synchronized = !!result.message;
            return noAction;
          }),
          catchError(() => EMPTY)
        );
      })
    )
  );

  inviteMessags$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(conferenceInviteMessageSendAction),
        tap((action) => {
          if (!!action.conference) {
            console.log("SEND INVITE MESSAGE", action.message, action);
            this.messagingService.sendMessage(action.message);
          }
        })
      ),
    { dispatch: false }
  );

  conferenceMessags$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          conferencePickUpMessageAction,
          conferenceMediaMessageAction,
          conferencePauseMessageAction,
          conferenceHangUpMessageAction
        ),
        tap((action) => {
          //console.log("effects.conferenceMessags$",action,"send",action.send && !!action.conference,action);
          if (action.send && !!action.conference) {
            //console.log("SEND CONFERENCE MESSAGE",action.message,action,action.type);
            this.messagingService.sendMessage(action.message);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private store$: Store<State>,
    private messagingService: MessagingService,
    @Inject(APP_ID) private appId: number,
    @Inject(ENVIRONMENT) private environment: any
  ) {}
}
