/* projects/chat/src/lib/components/conversation-details-container/chat-conversation-details-container.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary);
}
:host > div,
:host > div > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
}
:host > div > div.conversation {
  display: flex;
  flex-direction: column;
  transform: scale(1);
  transition: transform 300ms ease;
}
:host > div > div.conversation > mat-toolbar {
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
}
:host > div > div.conversation > mat-toolbar > div {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
:host > div > div.conversation > app-contact-list {
  flex: 1 1 auto;
  background-color: white;
}
:host > div.editMode > div.conversation {
  transform: scale(0.96);
}
:host > div > div.edit {
  transform: translateY(100%);
  transition: transform 300ms ease;
}
:host > div.editMode > div.edit {
  transform: translateY(0);
}
presence {
  position: absolute;
  right: 0;
  bottom: 0;
}
presence-info {
  display: block;
  font-size: 70%;
  line-height: 1em;
  font-weight: lighter;
}
div.role {
  position: relative;
  font-size: 70%;
  line-height: 1em;
  background-color: var(--color-primary);
  border-radius: 4px;
  padding: 4px;
  color: white;
}
div.role.blocked {
  background-color: var(--color-accent);
}
div.role.edit {
  padding-left: 2px;
}
div.role.edit:before {
  font-family: "Material Icons";
  content: "arrow_drop_down";
  vertical-align: top;
}
div.divider {
  border-top: 1px solid gray;
}
button.mat-mdc-menu-item.selected,
button.mat-menu-item.selected:hover:not([disabled]) {
  background-color: var(--color-primary);
  background-color: var(--color-accent);
  color: white;
}
button.mat-mdc-menu-item.selected mat-icon,
button.mat-menu-item.selected:hover:not([disabled]) mat-icon {
  color: white;
}
app-status-bar {
  z-index: 1;
  justify-content: flex-end;
  background-color: white;
}
:host-context(.color-scheme-light.pure) {
  background-color: #ddd;
}
:host-context(.color-scheme-dark) {
  background-color: #181818;
}
:host-context(.color-scheme-dark) div.divider {
  border-color: #333;
}
:host-context(.color-scheme-dark) > div {
  background-color: #181818;
}
:host-context(.color-scheme-dark) app-status-bar {
  background-color: #282828;
}
:host-context(.color-scheme-dark) > div > div.conversation > app-contact-list {
  background-color: #181818;
}
/*# sourceMappingURL=chat-conversation-details-container.component-VJKDHU35.css.map */
