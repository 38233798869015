import {Inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {combineLatest, EMPTY, from, Observable, of} from "rxjs";
import {Action, Store} from "@ngrx/store";
import {catchError, filter, first, map, mergeMap, switchMap, tap} from "rxjs/operators";
import {APP_ID, ENVIRONMENT} from "core";
import {HttpClient} from "@angular/common/http";
import {
  attachmentPayloadRequestAction,
  attachmentPayloadResultAction,
  conversationCreateOrUpdateAction,
  conversationsLoadRequestAction,
  conversationsLoadResultAction,
  conversationSynchronizeSegmentAction,
  conversationUpdateSegmentAction,
  receiveChatTimelineMessageAction,
  sendChatTimelineMessageAction,
  setErrorAction,
  testAction,
} from "./actions";
import {selectChatState, State} from "./state";
import {MessagingService} from "messaging";
import {
  AudioRecordingAttachment,
  AudioRecordingAttachmentType, AudioRecordingData,
  ConversationCreateOrUpdateMessage,
  ConversationCreateOrUpdateMessageType,
  ConversationCreateOrUpdateResultMessage,
  ConversationHeader,
  ConversationsLoadMessage,
  ConversationsLoadMessageType,
  ConversationsLoadResultMessage,
  ConversationSynchronizeMessage,
  ConversationSynchronizeMessageType,
  ConversationSynchronizeResultMessage,
  CreatedMessage,
  CreateMessageType,
} from "./models";

@Injectable()
export class ChatEffects {

  conversationsLoadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationsLoadRequestAction),
      switchMap(action =>
        combineLatest([
          of(action),
          this.store$.select(selectChatState).pipe(
            filter(state => !!state.online),
            first()
          )
        ])),
      mergeMap(([action,state]) => {
        //console.log("ACTION",action,"STATE",state);
        // cache information to let the server know what must be synced...
        //let cachedHash = 0;
        let newestMessageVersion = 0;
        state.conversationsState.entities.forEach(conversation => {
          //cachedHash += CRC16.fromString(conversation.id);
          newestMessageVersion = Math.max(newestMessageVersion,conversation.lastMessage?.timeUpdated || conversation.lastMessage?.timeCreated || 0);
        });
        //console.log("CHAT.LOAD.ConversationsLoadMessage");
        return from(this.messagingService.sendMessage(this.messagingService.initializeMessage(<ConversationsLoadMessage>{
          type: ConversationsLoadMessageType,
          filter: action.filter,
          newestMessageVersion: newestMessageVersion
        }),true)).pipe(
          map((result) => {
            //console.log("message",action,result);
            let message = <ConversationsLoadResultMessage>result.message;
            //console.log("CHAT.LOAD.ConversationsLoadResultMessage",message);
            return conversationsLoadResultAction({
                filter: message.filter,
                hash: message.hash,
                size: message.size,
                newestVersion: message.newestVersion,
                oldestVersion: message.oldestVersion,
                newestMessageVersion: message.newestMessageVersion
            })}),
          catchError(() => EMPTY)
        )})));

  conversationSynchronizeSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationSynchronizeSegmentAction),
      mergeMap((action) => {
        //console.log("effect.conversationUpdateSegmentAction->LOAD.0",action);
        //console.log("effect.conversationSynchronizeSegmentAction",action);
        return from(this.messagingService.sendMessage(this.messagingService.initializeMessage(<ConversationSynchronizeMessage>{
          type: ConversationSynchronizeMessageType,
          conversationId: action.conversation.id,
          conversation: action.conversation,
          current: action.current,
          segments: action.segments
        }),true)).pipe(
          map(result => {
            //console.log("message",result);
            let message:ConversationSynchronizeResultMessage = <ConversationSynchronizeResultMessage>result.message;
            let headers = <{conversation:ConversationHeader}>result.headers;
            //console.log("effect.conversationUpdateSegmentAction.result.0",result,"message",message,"headers",headers);
            //console.log("effect.conversationUpdateSegmentAction.result",result,"message",message,"headers",headers);
            return conversationUpdateSegmentAction({
              conversation: message.conversation,
              current: message.current,
              segments: message.segments,
              timeReceived: message.timeReceived,
              timeViewed: message.timeViewed,
              timeSelfReceived: message.timeSelfReceived,
              timeSelfViewed: message.timeSelfViewed,
              hooks: action.hooks,
              dispatch: action.dispatch
            })
          }),
          catchError(() => EMPTY)
        )})
    ));

  attachmentPayloadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentPayloadRequestAction),
      mergeMap(action => {
        let path = "/v1.0/chat/attachment";
        return this.http.post(path,{
          conversationId:action.conversationId,
          messageId:action.messageId
        }).pipe(
          map((result:AudioRecordingData) => {
            action.callback(result);
            return attachmentPayloadResultAction({
              conversationId:action.conversationId,
              messageId:action.messageId,
              data:result.data,
              wave:result.wave
            });
          }),
          catchError((error) => {
            action.callback(undefined);
            console.error('ERROR', error);
            return EMPTY;
          })
        )
      })));

  sendChatTimelineMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendChatTimelineMessageAction),
      mergeMap(action => {
        let   message = action.message;
        const errorHandler = (error):Observable<Action>=>{
          action.callback(false);
          console.error('sendChatTimelineMessage$.ERROR', error);
          return EMPTY;
        };
        if (message.attachments?.length==1 &&
            message.attachments[0].type==AudioRecordingAttachmentType &&
            !!(<AudioRecordingAttachment>message.attachments[0]).data) {
          message = {...message};
          message.attachments = [...message.attachments];
          message.attachments[0] = {...message.attachments[0]};
          const recordingAttachment = <AudioRecordingAttachment>message.attachments[0];
          const sourceData = recordingAttachment.data;
          recordingAttachment.data = undefined;
          if (!!sourceData) {
            let path = "/v1.0/chat/attachment";
            return this.http.post(path,{
              conversationId:message.conversationId,
              messageId:message.id,
              data:sourceData
            }).pipe(
              mergeMap((result:{data:string}) => {  // converted audio
                if (!!result?.data) {
                  return from(this.messagingService.sendMessage(message)).pipe(
                    map(envelope=>{
                      action.callback(true);
                      return attachmentPayloadResultAction({
                        conversationId: message.conversationId,
                        messageId: message.id,
                        data: result.data
                      })
                    }),
                    catchError(errorHandler)
                  );
                } else {
                  return errorHandler("conversion failed");
                }
              }),
              catchError(errorHandler))
          }
        }
        return from(this.messagingService.sendMessage(message)).pipe(
          mergeMap(envelope=>{
            action.callback(true);
            return EMPTY;
          }),
          catchError(errorHandler));
      })));

  /*
  conversationSynchronizeSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationUpdateSegmentAction),
      mergeMap((action) => {
        const segment = action.segments?.find(segment=>!!segment.synchronize);
        if (!!segment) {
          return of(conversationSynchronizeSegmentAction({
            conversation: action.conversation,
            hooks: action.hooks,
            dispatch: action => this.store$.dispatch(action)
          }));
        } else {
          //console.log("effect.conversationUpdateSegmentAction->EMPTY",action);
          return EMPTY
        }
      })
    ));
  */
  /*
  conversationSynchronizeSegmentsX$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationUpdateSegmentAction),
      mergeMap((action) => {
        const segment = action.segments?.find(segment=>!!segment.synchronize);
        if (!!segment) {
          //console.log("effect.conversationUpdateSegmentAction->LOAD",action);
          //console.log("effect.conversationSynchronizeSegmentAction",action);
          return from(this.ms.sendMessage(this.ms.initializeMessage(<ConversationSynchronizeMessage>{
            type: ConversationSynchronizeMessageType,
            conversationId: action.conversation.id,
            conversation: action.conversation,
            current: segment,
            segments: action.segments
          }),true)).pipe(
            map(result => {
              //console.log("message",result);
              let message:ConversationSynchronizeResultMessage = <ConversationSynchronizeResultMessage>result.message;
              let headers = <{conversation:ConversationHeader}>result.headers;
              //console.log("effect.conversationUpdateSegmentAction.result",result,"message",message,"headers",headers);
              return conversationUpdateSegmentAction({
                conversation: message.conversation,
                current: message.current,
                segments: message.segments,
                timeReceived: message.timeReceived,
                timeViewed: message.timeViewed,
                hooks: action.hooks,
                dispatch: action.dispatch
              })
            }),
            catchError(() => EMPTY))
        } else {
          //console.log("effect.conversationUpdateSegmentAction->EMPTY",action);
          return EMPTY
        }
      })
    ));*/

  conversationCreateAndUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationCreateOrUpdateAction),
      //debounceTime(100),
      mergeMap((action) => {
        //console.log("ACTION",action);
        return from(this.messagingService.sendMessage(this.messagingService.initializeMessage(<ConversationCreateOrUpdateMessage>{
          type: ConversationCreateOrUpdateMessageType,
          conversationId:   action.conversation.id,
          conversationType: action.conversation.type,
          conversationName: action.conversation.name,
          conversationTerm: action.conversation.term,
          resourceId: action.resourceId,
          participantIds: action.participantIds,
          updatedIds: action.updatedIds
        }),true)).pipe(
          map(result => {
            //console.log("message",result);
            let message = <ConversationCreateOrUpdateResultMessage>result.message;
            let headers = <{conversation:ConversationHeader}>result.headers;
            //console.log("ON_SUCCESS\nmessage",message,"\nheaders",headers,"\naction",action);
            if (!!message?.errorLabel) {
              action.onError(message.errorLabel);
              return setErrorAction({label:message.errorLabel,params:{}})
            } else {
              let hooks = action.onSuccess(headers.conversation);
              return receiveChatTimelineMessageAction({
                headers,
                message: <CreatedMessage>{
                  type: CreateMessageType,
                  id: message.id,
                  timeCreated: Date.now(),
                  conversationId: message.conversationId,
                  persistent: false
                },
                temporary: action.temporary,
                hooks,
                dispatch: action => this.store$.dispatch(action)
              })
            }
          }),
          catchError(() => EMPTY)
        )})
    ));

  /*
  participantsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(participantsLoadAction),
      //withLatestFrom(this.store$.pipe(select(selectContactUplineState))),
      mergeMap(action => { //([action,state]) => {
        if (!!action.loaded) {
          return EMPTY;
        } else {
          let path = "/v1.0/chat/participants/"+action.conversationId;
          return this.http.get(path).pipe(
            map((result:{data:Participant[]}) => {
              action.participants$.next(result.data ?? []);
              action.loaded = true;
              return action;
            }),
            catchError((error) => {
              console.error('participantsLoad$.error',action,error);
              return EMPTY;
            })
          )
        }
      })));*/

  test$ = createEffect(() =>
    this.actions$.pipe(
      ofType(testAction),
      tap(action => {
        action.effect = true;
        console.log("ACTION.EFFECT",action);
      })
    ),
    { dispatch: false });
  /*
  conversationsLoadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationsLoadRequestAction),
      debounceTime(100),
      switchMap(action =>
        combineLatest([
          of(action),
          this.store$.pipe(
            select(selectChatState),
            filter(state => state.online),
          )
        ])),
      mergeMap(([action,state]) => {
        console.log("ACTION",action,"STATE",state);
        return from(this.ms.sendMessage(this.ms.initializeMessage(<LoadConversationsMessage>{
          type: LoadConversationsMessageType,
          filterId: action.filterId,
          filters: action.filters,
          term: action.term,
          size: state.conversationListState.entities.length,
          newestVersion: state.conversationListState.newestVersion,
          oldestVersion: state.conversationListState.oldestVersion
        }),true)).pipe(
          map(<LoadConversationsMessageResult>(result) => conversationsLoadRequestResultAction({
              filterId: result.filterId,
              filters: result.filters,
              term: result.term,
              size: result.size,
              newestVersion: result.newestVersion,
              oldestVersion: result.oldestVersion,
            }),
          catchError(() => EMPTY)
      ))})));


  conversationLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationLoadPageAction),
      debounceTime(100),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(
              select(state => state.chat),
              filter(state => state.online)
            )
          ))),
      mergeMap(([action,state]) =>
        from(this.ms.sendMessage(this.ms.initializeMessage(<LoadConversationPageMessage>{
          type: LoadConversationsMessageType,
          filterId: action.filterId,
          filters: action.filters,
          term:   action.term,
          lastMessages: state.conversationListState.entities.filter(entity=>!!entity.lastMessage?.id).map(entity => <{}>{
            id: entity.lastMessage.id,
            index: entity.lastMessage.index
          })
        }))).pipe(
          / *
          map(done => conversationsUpdateFilterAndTermsAction({
              filterId: listState.filterId,
              filters: listState.filters,
              term: listState.term
            }),* /
          catchError(() => EMPTY)
        ))));

  conversationsUpdateFiltersAndTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationsUpdateFiltersAndTermAction),
      debounceTime(100),
      withLatestFrom(
        this.store$.pipe(select(state => state.chat.conversationListState)),
        this.ms.onOpen()
      ),
      mergeMap(([action,listState,open]) =>
        from(this.ms.sendMessage(this.ms.initializeMessage(<UpdateConversationsFiltersAndTermMessage>{
          type: UpdateConversationsFiltersAndTermMessageType,
          filterId: listState.filterId,
          filters: listState.filters,
          term: listState.term
        }))).pipe(
          map(done => conversationsUpdateFiltersAndTermDoneAction({})),
          catchError(() => EMPTY)))
    ));

  conversationLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationLoadPageAction),
      debounceTime(100),
      withLatestFrom(
        this.store$.pipe(select(state => state.chat.conversationStates)),
        this.ms.onOpen()
      ),
      mergeMap(([action,states,open]) =>
        from(this.ms.sendMessage(this.ms.initializeMessage(<LoadConversationPageMessage>{
          type: LoadConversationsMessageType,
          lastMessages: listState.entities.filter(entity=>!!entity.lastMessage).map((entity:ChatConversationInfo)=><{id:string,index:number}>{id:entity.lastMessage.id,index:entity.lastMessage.index})
        }))).pipe(
          map(done => conversationsUpdateFilterAndTermsAction({
              filterId: listState.filterId,
              filters: listState.filters,
              term: listState.term
            }),
            catchError(() => EMPTY))))
    ));

  conversationsUpdateFiltersAndTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationsUpdateFiltersAndTermAction),
      debounceTime(100),
      withLatestFrom(
        this.store$.pipe(select(state => state.chat.conversationListState)),
        this.ms.onOpen()
      ),
      mergeMap(([action,listState,open]) =>
        from(this.ms.sendMessage(this.ms.initializeMessage(<UpdateConversationsFiltersAndTermMessage>{
          type: UpdateConversationsFilterAndTermMessageType,
          filterId: listState.filterId,
          filters: listState.filters,
          term: listState.term
        }))).pipe(
          map(done => conversationsUpdateFiltersAndTermDoneAction({})),
          catchError(() => EMPTY)))
    ));

  // @Effect()
  currentConversationLoadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationLoadRequestAction, conversationUpdateFilterAction, conversationUpdateSearchTermAction),
      debounceTime(300),
      withLatestFrom(this.store$.pipe(select(state => state.chat.conversation))),
      map(([action,state]) => conversationLoadPageAction({conversationId:action.conversationId,cacheId:state.currentCacheId,index:0,size:25}))
    ));

  // @Effect()
  conversationsLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationsLoadPageAction),
      withLatestFrom(this.store$.pipe(select(state => state.chat.conversations))),
      mergeMap(([action,state]) => {
        var query   = encodeURI(state.term.trim().replace(/\s+/g, ','));
        var append  = addUrlParameter("filter",state.filters.join(','),true).add("query",query,true).toString();
        //console.debug("append:"+append);
        var path    = "/v1.0/messaging/conversations/segment/"+action.cacheId+"/"+action.index+"/"+action.size+append;
        console.debug("path:'"+path+"'");
        return this.http.get(path).pipe(
          map(result => {
            //console.info("loaded...");
            return conversationsLoadPageSuccessAction({cacheId:action.cacheId,index:action.index,data:result['data'],total:result['size'],dispatch:(dispatchAction : Action) => {
              console.debug("dispatch loaded ["+action.index+"/"+action.size+"]");
              this.store$.dispatch(dispatchAction);
            }})
          }),
          catchError(() => {
            return of(conversationsLoadPageFailureAction({cacheId:action.cacheId,index:action.index,size:action.size}))
          })
        )
      }),
    ));

  // @Effect()
  currentConversationLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationLoadPageAction),
      withLatestFrom(this.store$.pipe(select(state => state.chat.conversation))),
      mergeMap(([action,state]) => {
        var query   = encodeURI(state.term.trim().replace(/\s+/g, ','));
        var append  = addUrlParameter("filter",state.filters.join(','),true).add("query",query,true).toString();
        //console.debug("append:"+append);
        var path    = "/v1.0/messaging/conversation/segment/"+action.cacheId+"/"+action.conversationId+"/"+action.index+"/"+action.size+append;
        console.debug("path:'"+path+"'");
        return this.http.get(path).pipe(
          map(result => {
            //console.debug("loaded...");
            return conversationLoadPageSuccessAction({conversationId:action.conversationId,cacheId:action.cacheId,index:action.index,data:result['data'],total:result['size'],dispatch:(dispatchAction : Action) => {
              console.debug("dispatch loaded ["+action.index+"/"+action.size+"]");
              this.store$.dispatch(dispatchAction);
            }})
          }),
          catchError(() => {
            return of(conversationLoadPageFailureAction({conversationId:action.conversationId,cacheId:action.cacheId,index:action.index,size:action.size}))
          })
        )
      }),
    ));
*/
  constructor(private http: HttpClient,
              private actions$: Actions,
              private store$: Store<State>,
              private messagingService: MessagingService,
              @Inject(APP_ID) private appId: number,
              @Inject(ENVIRONMENT) private environment: any) {
  }
}
