import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, skip, Subscription, take} from "rxjs";
import {NULL_TASK_LIST, NullTaskList, TaskList} from "../../models/task-list";
import {Logger} from "core";
import {MatListOption, MatSelectionListChange} from "@angular/material/list";
import {CdkVirtualScrollViewport, ScrollDispatcher} from "@angular/cdk/scrolling";
import {distinctUntilChanged, filter, takeUntil} from "rxjs/operators";
import {TaskListService} from "../../services/task-list.service";
import {BasicContainerComponent} from "shared";

@Component({
  selector: 'app-tasklist-list',
  templateUrl: './tasklist-list.component.html',
  styleUrls: ['./tasklist-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskListListComponent extends BasicContainerComponent {

  @Input() entities$: Observable<TaskList[]>;
  @Input() set selected (selected: string[]) {
    this.logger.debug('selectedIds$.next', selected);
    this.selectedIds$.next(selected);
  }
  @Input() invert = false;
  @HostBinding('class.dense')
  @Input() dense = false;

  protected _multiSelection = false;
  @Input() set multiSelection(multiSelection: boolean) {
    multiSelection = multiSelection ?? false;
    if (multiSelection!=this._multiSelection) {
      this._multiSelection = multiSelection;
      this.selection$.next(undefined);
    }
  }

  get multiSelection(): boolean {
    return this._multiSelection;
  }

  @Output() selectionChange = new EventEmitter<TaskList[]>();

  @ViewChild(CdkVirtualScrollViewport, { static: true }) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @ViewChildren(MatListOption) options: QueryList<MatListOption>;
  @ContentChild(TemplateRef, { static: true }) controlsTemplate: TemplateRef<any>;

  protected taskListSubscriptions: {[key:string]: Subscription} = {};
  protected subscribedTaskLists: TaskList[];
  protected entitiesSubscription: Subscription;
  protected initialized = false;

  selectedIds$ = new BehaviorSubject<string[]>([]);
  selection$ = new BehaviorSubject<{[key: string]: TaskList}>(undefined);

  protected logger = new Logger('TaskListListComponent');

  trackTaskList = (index: number, taskList: TaskList) => {
    const  safeTaskList = this.safeTaskList(taskList);
    return safeTaskList.id;// + '.' + trackedTaskList.version;
  };

  constructor (protected taskListService: TaskListService,
               protected changeDetector: ChangeDetectorRef,
               protected scrollDispatcher: ScrollDispatcher,
               @Inject(NULL_TASK_LIST) private nullTaskList: NullTaskList) {
    super();
    this.logger.debug('ctor');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.entitiesSubscription = this.entities$.subscribe(taskLists => taskLists);

    combineLatest([this.entities$, this.selectedIds$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([entities, selectedIds]) => {
        const selection = entities?.reduce((selection, entity) => {
          if (selectedIds?.findIndex(id => id==entity?.id)>=0) {
            selection[entity.id] = entity;
          }
          return selection;
        }, {})
        this.logger.debug('selection$.next', selection);
        this.selection$.next(selection);
      })

    this.selection$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged((previous, current) => {
        const previousIds = previous ? Object.keys(previous) : [];
        const currentIds  = current  ? Object.keys(current)  : [];
        return previousIds == currentIds ||
          previousIds && currentIds &&
          previousIds.length == currentIds.length &&
          previousIds.every(f => currentIds.includes(f))
      }),
      skip(1), // initial selection is not emitted back to the container
    ).subscribe((selection) => {
      const selectedValues = selection ? Object.values(selection) : [];
      this.logger.debug('selectionChange', selectedValues);
      this.selectionChange.emit(selectedValues);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.entitiesSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.scrollDispatcher
      .scrolled()
      .pipe(
        filter(scrollable => this.cdkVirtualScrollViewPort === scrollable)
      )
      .subscribe(() => {
        let update = false;
        this.options.forEach(option => {
          const selected = this.isSelected(option.value);
          if (selected != option.selected) {
            option.selected = selected;
            update = true;
          }
        });
        if (update) {
          this.changeDetector.detectChanges();
        }
      });
  }

  isSelected(taskListId: string): boolean {
    return !!this.selection$.getValue()?.[taskListId] == !this.invert;
  }

  onSelectionChanged(event: MatSelectionListChange) {
    // https://material.angular.io/components/list/api#MatSelectionListChange
    // this.logger.debug(event.source.selectedOptions);
    const taskListId = event.options?.[0]?.value;
    if (taskListId) {
      // keep selection value immutable i.e. use shallow clone
      // this will ensure distinctUntilChanged operator will not take the same object
      // for previous and current values as this will compromise the comparison
      let selection = {...this.selection$.getValue()};
      const selected = selection?.[taskListId];
      if (selected) {
        delete selection[taskListId];
        this.selection$.next(selection);
      } else {
        this.entities$.pipe(take(1), takeUntil(this.onDestroy$))
          .subscribe((entities) => {
            const taskList = entities?.find(taskList => taskList?.id==taskListId);
            if (selection && this.multiSelection) {
              selection[taskListId] = taskList;
            } else {
              selection = {[taskListId]: taskList};
            }
            this.selection$.next(selection);
        })
      }
    }
    // const index  = this.selected.indexOf(taskListId);
    // const select = index < 0;
    // if (select) {
    //   this.selected.push(taskListId);
    // } else {
    //   this.selected.splice(index, 1);
    // }
    // this.selectionChange.emit(this.selected);
    // }
  }

  get value(): string[] {
    return this.selected;
  }

  safeTaskList(taskList: TaskList): TaskList {
    return taskList || this.nullTaskList;
  }

  subscribed(taskLists: TaskList[]): TaskList[] {
    if (this.subscribedTaskLists!==taskLists) {
      this.subscribedTaskLists = taskLists;
      const subscriptions: {[key:string]: Subscription} = {};
      taskLists?.forEach(taskList => {
        if (!!taskList?.id) {
          subscriptions[taskList.id] =
            this.taskListSubscriptions[taskList.id] ??
            this.taskListService.getTaskList$(taskList.id,taskList).subscribe();
        }
      });
      Object.keys(this.taskListSubscriptions).forEach(taskListId=>{
        if (!subscriptions[taskListId]) {
          this.taskListSubscriptions[taskListId].unsubscribe();
        }
      });
      this.taskListSubscriptions = subscriptions;
    }
    return taskLists;
  }
}
