import {Inject, Injectable, InjectionToken, Optional} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {of, SchedulerLike} from "rxjs";
import {Action, select, Store} from "@ngrx/store";
import {catchError, debounceTime, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {addUrlParameter, APP_ID, ENVIRONMENT, Logger} from "core";
import {HttpClient} from "@angular/common/http";

import {
  CalendarActionIds,
  calendarDeleteAction,
  calendarDeleteFailureAction,
  calendarDeleteSuccessAction,
  calendarLoadPageAction,
  calendarLoadPageFailureAction,
  calendarLoadPageSuccessAction,
  calendarLoadRequestAction,
  CalendarUpdateAction,
  calendarUpdateFailureAction,
  calendarUpdateFilterAction,
  calendarUpdateSearchTermAction,
  calendarUpdateSuccessAction
} from "./actions";
import {Calendar} from "../../models/calendar";
import {CalendarService} from "../../services/calendar.service";
import {PropertiesService} from "properties";

export const EVENT_LOAD_DEBOUNCE = new InjectionToken<number>('Event Load Debounce');
export const EVENT_LOAD_SHEDULER = new InjectionToken<SchedulerLike>(
  'Event Load Scheduler'
);

@Injectable()
export class CalendarEffects {

  // ATTENTION using createEffect api AND @Effect() decorator causes effect to be invoked twice - removing this decorator fixes the problem!
  // However this decorator is still required when the effect is created directly using the "old style" approach - in this case it also works properly.
  // https://github.com/ngrx/platform/issues/1054#issuecomment-538807675

  // @Effect()
  calendarLoadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarLoadRequestAction, calendarUpdateFilterAction, calendarUpdateSearchTermAction),
      debounceTime(300),
      withLatestFrom(this.store$.pipe(select(state => state.calendar.calendars))),
      map(([action, state]) => calendarLoadPageAction({cacheId: state.currentCacheId, index:0,size:25}))
    )
  );

  // @Effect()
  calendarLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarLoadPageAction),
      withLatestFrom(this.store$.pipe(select(state => state.calendar.calendars))),
      mergeMap(([action, state]) => {
        const query   = encodeURI(state.term.trim().replace(/\s+/g, ','));
        const append  = addUrlParameter("filter",state.filters.join(','),true).add("query",query,true).toString();
        const path    = `/v1.0/calendar/segment/${action.cacheId}/${action.index}/${action.size+append}`;
        // this.logger.debug(`calendarLoadPage. path:${path}`);
        // return of({data: this.calendars, size: this.calendars.length}).pipe( //TODO: test
        return this.http.get(path).pipe(
          map(result => {
            //this.logger.info("loaded...");
            return calendarLoadPageSuccessAction({
              cacheId :action.cacheId,
              index   :action.index,
              data    :result['data'],
              total   :result['size'],
              dispatch:(dispatchAction : Action) => {
                // this.logger.debug("dispatch loaded ["+action.index+"/"+action.size+"]");
                this.store$.dispatch(dispatchAction);
              }});
          }),
          catchError(() => {
            return of(calendarLoadPageFailureAction({
              cacheId:action.cacheId,
              index:action.index,
              size:action.size
            }));
          })
        )
      })
    )
  );

  // @Effect()
  calendarUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CalendarUpdateAction>(CalendarActionIds.UPDATE),
      withLatestFrom(this.store$.pipe(select(state => state.calendar.calendars))),
      mergeMap(([action, state])  => {
        const path = `/v1.0/calendar`;
        // return of({...action.calendar}).pipe( //TODO: test
        return this.http.post(path, action.calendar).pipe(
          map((calendar: Calendar) => {
            const props = { calendar: calendar, correlationId: action.correlationId };
            return calendarUpdateSuccessAction(props);
          }),
          catchError((error) => {
            this.logger.error('ERROR', error);
            return of(calendarUpdateFailureAction({
              calendar: action.previous,
              reason: error,
              correlationId: action.correlationId
            }));
          })
        )
      })
    )
  );

  // @Effect()
  calendarDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarDeleteAction),
      withLatestFrom(this.store$.pipe(select(state => state.calendar.calendars))),
      mergeMap(([action, state])  => {
        const path = `/v1.0/calendar/${action.calendar.id}`;
        return this.http.delete(path).pipe(
          map((calendar: Calendar) => {
            return calendarDeleteSuccessAction({ calendar: calendar || action.calendar } );
          }),
          catchError((error) => {
            this.logger.error('ERROR', error);
            return of(calendarDeleteFailureAction({ calendar: action.calendar, reason: error }));
          })
        )
      })
    )
  );

  // protected calendars: Calendar[]   = []; // dummy calendars for testing
  protected logger = new Logger('CalendarEffects');

  constructor(private http: HttpClient,
              private actions$: Actions,
              private store$: Store<any>,
              private propertiesService: PropertiesService,
              private calendarService: CalendarService,
              @Inject(APP_ID) private appId: number,
              @Inject(ENVIRONMENT) private environment: any,
              @Optional()
              @Inject(EVENT_LOAD_DEBOUNCE)
              private debounce: number,
              // scheduler injected for mock out purposes to assist testing
              @Optional()
              @Inject(EVENT_LOAD_SHEDULER)
              private scheduler: SchedulerLike) {
    /*
    const calendars = [];
    for (let index = 1; index<=100; index++) {
      const calendar = new Calendar({
        id: `${index}`,
        author: this.propertiesService.user,
        name: `Calendar ${index}`,
        info: `Calendar Info ${index}`,
        time_created: time,
        time_updated: time,
        countryCodes: [this.propertiesService.user.country_code],
        countryCodesExcluded: false,
        tags: [],
        filters: {},
        properties: undefined,
        language: this.propertiesService.user.country_code,
      });
      calendars.push(calendar);
    }
    this.calendars = calendars.sort((c1, c2) => c1.author.id - c2.author.id);
    */
  }
}
