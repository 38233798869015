import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {ChatEffects} from "./effects";
import {reducer} from "./reducers";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('chat', reducer),
    EffectsModule.forFeature([ChatEffects])
  ],
  providers: [ChatEffects]
})
export class ChatStoreModule {}
