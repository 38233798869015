import {createReducer, on} from "@ngrx/store";
import {presenceSubscribeAction, presenceUnsubscribeAction, presenceUpdateDoneAction,} from "./actions";
import {initialPresence, initialPresencesState} from "./state";

export const reducer = createReducer(
  initialPresencesState,
  on(presenceUpdateDoneAction,(state, action) => {
    //console.log("presenceUpdatedAction",state,action);
    let subscribed = state.subscriptions[action.presence?.contactId];
    if (!!subscribed) {
      let subscriptions = { ...state.subscriptions };
      subscriptions[action.presence.contactId] = { ...subscribed, entity:action.presence }
      return {
        ...state,
        subscriptions
      }
    }
    return state;
  }),
  on(presenceSubscribeAction,(state, action) => {
    //console.log("presenceSubscribeAction",state,action,{...state.subscriptions});
    let subscribed = state.subscriptions[action.id];
    if (!subscribed) {
      let subscriptions = { ...state.subscriptions };
      let entity = action.presence = {
        ...initialPresence,
        contactId: action.id
      };
      subscriptions[action.id] = {
        subscribed: 1,
        entity
      };
      return {
        ...state,
        subscriptions
      }
    } else {
      action.presence = subscribed.entity;
      subscribed.subscribed++;
    }
    return state;
  }),
  on(presenceUnsubscribeAction,(state, action) => {
    //console.log("presenceUnsubscribeAction",state,action,{...state.subscriptions});
    let subscribed = state.subscriptions[action.id];
    action.presence = subscribed?.entity;
    if (subscribed?.subscribed>1) {
      subscribed.subscribed--;
    } else if (!!subscribed) {
      let subscriptions = { ...state.subscriptions };
      delete subscriptions[action.id];
      return {
        ...state,
        subscriptions
      }
    }
    return state;
  }),
);

