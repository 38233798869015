import {ChangeDetectionStrategy, Component, ElementRef, ViewChild} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {ChatConversationContainerComponent} from "../conversation-container/chat-conversation-container.component";
import {ChatConversationListContainerComponent} from "../conversation-list-container/chat-conversation-list-container.component";
import {ChatConversationDetailsContainerComponent} from "../conversation-details-container/chat-conversation-details-container.component";
import {takeUntil, tap} from "rxjs/operators";
import {BasicContainerComponent, ResizeService} from "shared";

@Component({
  selector: 'chat-main-container',
  templateUrl: './chat-main-container.component.html',
  styleUrls: ['./chat-main-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMainContainerComponent extends BasicContainerComponent {

  @ViewChild('leftSideNav', {read: ElementRef})
  conversationListSidenavElement: ElementRef;
  @ViewChild('conversation')
  conversation: ChatConversationContainerComponent;
  @ViewChild('conversationList')
  conversationList: ChatConversationListContainerComponent;
  @ViewChild('conversationDetails')
  conversationDetails: ChatConversationDetailsContainerComponent;
  @ViewChild('conversationListSidenav')
  conversationListSidenav: MatSidenav;
  @ViewChild('conversationDetailsSidenav')
  conversationDetailsSidenav: MatSidenav;

  constructor(protected elementRef: ElementRef, protected resizeService : ResizeService) {
    super();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    //this.register(this.conversation.subscribeEvent(ChatConversationContainerComponent.EVENT_TOGGLE_CONVERSATION_LIST,(event : string) => this.onToggleConversationList()));
    //this.register(this.conversation.subscribeEvent(ChatConversationContainerComponent.EVENT_TOGGLE_CONVERSATION_DETAILS,(event : string) => this.onToggleConversationDetails()));
    //this.register(this.conversationDetails.subscribeEvent(ChatConversationDetailsContainerComponent.EVENT_CLOSE_CONVERSATION_DETAILS,(event : string) => this.onCloseConversationDetails()));
    this.conversationListSidenav.openedChange.pipe(takeUntil(this.onDestroy$), tap(visible => {
      this.onConversationListVisibilityChange(visible);
    }));
    /*
    window.setTimeout(()=> {
      this.conversation.leftSidenavButtonVisible = !this.conversationListSidenav.opened;
    },0);
    */
  }

  onConversationListVisibilityChange(visible : boolean) {
    console.debug("EVENT.onConversationListVisibilityChange "+visible);
  }

  onToggleConversationList() {
    this.conversationListSidenav.toggle();
  }

  onToggleConversationDetails() {
    this.conversationDetailsSidenav.toggle();
  }

  onCloseConversationDetails() {
    this.conversationDetailsSidenav.close();
  }

  isConversationListOpened() : boolean {
    if (this.resizeService.windowSize.width>=800) {
      return true;
    }
    return false;
  }

  getConversationListMode() : string {
    let conversationListSidenavOpened    = this.conversationListSidenav.opened;
    let conversationDetailsSidenavOpened = this.conversationDetailsSidenav.opened;
    if (this.resizeService.windowSize.width>=600) {
      return "side";
    }
    return "over";
  }

  getConversationDetailsMode() : string {
    let conversationListSidenavOpened    = this.conversationListSidenav.opened;
    let conversationDetailsSidenavOpened = this.conversationDetailsSidenav.opened;
    if (this.resizeService.windowSize.width>=600) {
      return "side";
    }
    return "over";
  }
}
