import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {ConferenceEffects} from "./effects";
import {reducer} from "./reducers";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('conference', reducer),
    EffectsModule.forFeature([ConferenceEffects])
  ],
  providers: [ConferenceEffects]
})
export class ConferenceStoreModule {}
