import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BasicContainerComponent} from "shared";
import {CallStatusMessage, ChatTimelineMessage, RenderMode} from "../../../store/models";
import {MessageSpace} from "../../conversation/chat-conversation.component";

@Component({
  selector: 'chat-message-container',
  templateUrl: './chat-message-container.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageContainerComponent extends BasicContainerComponent {
  protected _message:ChatTimelineMessage;
  protected _index:number;
  protected _total:number;
  protected _header:boolean;
  protected _parent:boolean;
  protected _space:MessageSpace;
  protected _mode:RenderMode = 'bubble';

  constructor() {
    super();
  }

  @Input()
  set message(message: ChatTimelineMessage) {
    this._message = message;
  }

  get message():ChatTimelineMessage|CallStatusMessage {
    return this._message;
  }

  get callStatusMessage():CallStatusMessage {
    return <CallStatusMessage>this._message;
  }

  @Input()
  set mode(mode: RenderMode) {
    this._mode = mode;
  }

  get mode():RenderMode {
    return this._mode;
  }

  @Input()
  set space(space: MessageSpace) {
    this._space = space;
  }

  get space():MessageSpace {
    return this._space;
  }

  @Input()
  set index(index:number) {
    this._index = index;
  }

  get index():number {
    return this._index;
  }

  @Input()
  set total(total:number) {
    this._total = total;
  }

  get total():number {
    return this._total;
  }

  @Input()
  set header(header:boolean) {
    this._header = header;
  }

  get header():boolean {
    return !!this._header;
  }

  @Input()
  set parent(parent:boolean) {
    this._parent = parent;
  }

  get parent():boolean {
    return !!this._parent;
  }
}
