<ng-container *ngLet="role(current()) as currentRole">
  <div *ngLet="editMode() as editMode" [class.editMode]="editMode"
       (onAttach)="ngOnAttach()"
       (onDetach)="ngOnDetach()">
    <div class="conversation">
      <mat-toolbar color="primary">
        <div translate>chat.details</div>
        <button tabindex="-1" *ngIf="isGroupConversation() && currentRole>=ADMIN" mat-icon-button (click)="openEditContainer()">
          <mat-icon>settings</mat-icon>
        </button>
        <button tabindex="-1" mat-icon-button (click)="layoutService.details.toggle()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar>
      <app-contact-list [entities$]="contacts$" [cacheId$]="cacheId$"
                        [avatarTemplateRef]="avatarTemplate"
                        [contactInfoTemplateRef]="contactInfoTemplate"
                        [controlsTemplateRef]="controlsTemplate"
                        [itemMinSize]="60" [avatarSize]="50" [dividerLeftMargin]="74"
                        (selectionChanged)="onSelect($event)">
        <ng-template #avatarTemplate
                     let-contact="contact"
                     let-src="src"
                     let-width="width"
                     let-height="height"
                     let-index="index"
                     let-total="total"
                     let-last="last">
          <img matListItemAvatar
               [src]="src"
               [style.width]="width"
               [style.height]="height"
               onload="this.style.opacity=1"/>
          <presence [contactId]="contact?.id"></presence>
        </ng-template>
        <ng-template #contactInfoTemplate
                     let-contact="contact"
                     let-index="index"
                     let-total="total"
                     let-last="last">
          <presence-info *ngIf="contact?.id" [contactId]="contact.id"></presence-info>
        </ng-template>
        <ng-template #controlsTemplate
                     let-contact="contact"
                     let-index="index"
                     let-total="total"
                     let-last="last">
          <ng-container *ngIf="isGroupConversation()">
            <ng-container *ngLet="role(contact) as role">
              <ng-container *ngLet="(currentRole>=ADMIN && currentRole>=role) as edit">
                <div [class.edit]="edit" [class.blocked]="role==BLOCKED" class="role" [ngSwitch]="role"
                     (click)="edit && openRoleMenu($event,contact,role)">
                  <span *ngSwitchCase="BLOCKED" translate>chat.role.blocked</span>
                  <span *ngSwitchCase="OWNER" translate>chat.role.owner</span>
                  <span *ngSwitchCase="ADMIN" translate>chat.role.admin</span>
                  <span *ngSwitchCase="MEMBER" translate>chat.role.member</span>
                  <span *ngSwitchDefault translate>chat.role.guest</span>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </app-contact-list>
      <app-status-bar><span>{{(contacts$ | async)?.length | number}}</span></app-status-bar>
      <div style="visibility: hidden; position: fixed"
           [style.left]="roleMenuContext.x"
           [style.top]="roleMenuContext.y"
           [matMenuTriggerFor]="roleMenu"></div>
      <mat-menu #roleMenu="matMenu" class="role-context-menu"
                overlapTrigger="false">
        <button mat-menu-item *ngIf="currentRole==OWNER" class="owner"
                [class.selected]="roleMenuContext.selectedRole==OWNER && roleMenuContext.selectedRole!=roleMenuContext.previousRole"
                (click)="select($event,OWNER)">
          <mat-icon *ngIf="roleMenuContext.selectedRole==OWNER">radio_button_checked</mat-icon>
          <mat-icon *ngIf="roleMenuContext.selectedRole!=OWNER">radio_button_unchecked</mat-icon>
          <span translate>chat.role.owner</span>
        </button>
        <button mat-menu-item *ngIf="currentRole>=ADMIN" class="admin"
                [class.selected]="roleMenuContext.selectedRole==ADMIN && roleMenuContext.selectedRole!=roleMenuContext.previousRole"
                (click)="select($event,ADMIN)">
          <mat-icon *ngIf="roleMenuContext.selectedRole==ADMIN">radio_button_checked</mat-icon>
          <mat-icon *ngIf="roleMenuContext.selectedRole!=ADMIN">radio_button_unchecked</mat-icon>
          <span translate>chat.role.admin</span>
        </button>
        <button mat-menu-item class="member"
                [class.selected]="roleMenuContext.selectedRole==MEMBER && roleMenuContext.selectedRole!=roleMenuContext.previousRole"
                (click)="select($event,MEMBER)">
          <mat-icon *ngIf="roleMenuContext.selectedRole==MEMBER">radio_button_checked</mat-icon>
          <mat-icon *ngIf="roleMenuContext.selectedRole!=MEMBER">radio_button_unchecked</mat-icon>
          <span translate>chat.role.member</span>
        </button>
        <button mat-menu-item class="guest"
                [class.selected]="roleMenuContext.selectedRole==GUEST && roleMenuContext.selectedRole!=roleMenuContext.previousRole"
                (click)="select($event,GUEST)">
          <mat-icon *ngIf="roleMenuContext.selectedRole==GUEST">radio_button_checked</mat-icon>
          <mat-icon *ngIf="roleMenuContext.selectedRole!=GUEST">radio_button_unchecked</mat-icon>
          <span translate>chat.role.guest</span>
        </button>
        <div class="divider"></div>
        <button mat-menu-item class="blocked"
                [class.selected]="roleMenuContext.selectedRole==BLOCKED && roleMenuContext.selectedRole!=roleMenuContext.previousRole"
                (click)="select($event,BLOCKED)">
          <mat-icon *ngIf="roleMenuContext.selectedRole==BLOCKED">radio_button_checked</mat-icon>
          <mat-icon *ngIf="roleMenuContext.selectedRole!=BLOCKED">radio_button_unchecked</mat-icon>
          <span translate>chat.role.blocked</span>
        </button>
      </mat-menu>
    </div>
    <div class="edit" (transitionend)="triggerEditContainerAnimationDone($event)">
      <chat-conversation-create-container #edit *ngLet="conversation() as conversation"
          [log]="log"
          [disabled]="!editMode"
          [type]="editMode"
          [conversationId]="conversation?.id"
          [groupName]="conversation?.name"
          [groupTerm]="conversation?.term"
          [groupParticipantIds]="contactsIds()"
          (closed)="closeEditContainer($event)">
      </chat-conversation-create-container>
    </div>
  </div>
</ng-container>
