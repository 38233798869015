/*
  SYNCHRONIZATION RELATED
*/
import {Message} from "messaging";
import {ListViewFilter, LoadMoreState} from "store";
import {VersionedId} from "core";
import {ListViewState} from "synchronization/lib/store/state";

export interface SynchronizationRelatedMessage extends Message {
}

export const EmptyListViewState: ListViewState = { totalSize: 0, passiveSize: 0, activeSize: 0, version: 0 };
export const EmptyLoadMoreState: LoadMoreState = { load:0, size:0, time:0 };

export const SynchronizationInitializationMessageType = "synchronization.initialization";
export interface SynchronizationInitializationMessage extends SynchronizationRelatedMessage {
  listViews?: {
    viewId:string,
    entityType: string,
    filter?: ListViewFilter,
    state: ListViewState
  }[];
  subscriptions?: {
    entityType: string,
    subscribed: VersionedId[]
  }[];
}

export const EntitySubscriptionMessageType = "entity.subscription";
export interface EntitySubscriptionMessage extends SynchronizationRelatedMessage {
  entityType: string;		// type of the entities to subscribe
  subscribed: VersionedId[];	// list of subscribed entities
}

export const EntityUpdateMessageType = "entity.update";
export interface EntityUpdateMessage extends SynchronizationRelatedMessage {
  entityType: string;		  // type of the entity
  entity: VersionedId;	// the entity itself
}

export const EntityDraftMessageType = "entity.draft";
export interface EntityDraftMessage extends SynchronizationRelatedMessage {
  entityType: string;		  // type of the entity
  entity: VersionedId;	  // the entity itself
  channelId: string;		  // unique channel id
}


export const ListViewSegmentRequestMessageType = "listView.segment.request";
export interface ListViewSegmentRequestMessage extends SynchronizationRelatedMessage {
  viewId: string;			  // unique id for the subscription
  viewVersion: number;	// highest version of all elements
  filter?: ListViewFilter;  // in case of complete client side filtering & searching, it is undefined
  segment: ListViewSegmentRequest|ListViewSegmentEntities<VersionedId>;  // includes the type info
}

export const ListViewSegmentMessageType = "listView.segment";
export interface ListViewSegmentMessage extends SynchronizationRelatedMessage {
  viewId: string;			  // unique id for the subscription
  viewVersion: number;	// SyncView version
  totalSize: number;		// total view size
  activeSize: number;		// e.g. the open tasks in tasks
  errorCode?:           // error code if any
    'unsupported'|      // FATAL: invalid entity type, or no segment specified, stop syncing
    'invalidSegment'|   // invalid segment or out of range (only first request is exception)
    'invalidVersion';   // invalid version, we missed event messages, sync again with the newest version
  segment: ListViewSegmentEntities;
}

export const ListViewEventMessageType = "listView.event";
export interface ListViewEventMessage extends SynchronizationRelatedMessage {
  event: ListViewChangeEvent;	// the event (insert, update, delete)
}


export const ListViewReleaseMessageType = "listView.release";
export interface ListViewReleaseMessage extends SynchronizationRelatedMessage {
  viewId: string;			// unique id for the subscription
}

export interface ListViewChangeEvent {
  eventType: 'insert'|'update'|'delete';
  viewId: string;			  // unique id for the subscription
  viewState: ListViewState;
  entityType: string;		  // type of the entity
  entity: VersionedId;	  // the entity itself
  insertIndex?: number;		// index of entity to be inserted (old state for insert, new state for update)
  deleteIndex?: number;		// index of entity to be removed (old state)
}

export interface ListViewSegmentRequest {
  entityType: string;	// e.g. "Contact"
  entities?: VersionedId[]; // if passed, it overrides the index/size....
  index: number;			// index of segment. <0 ... >0
  size: number;			  // size of the requested segment
}

export interface ListViewSegmentEntities<T extends VersionedId = VersionedId> {
  entityType: string;	// e.g. "Contact"
  entities: T[];      // can be entities or just VersionId's
  index: number;			// index of segment. <0 ... >0
  version: number;		// newest version of all entities in segment
  checksum: number;		// checksum of all VersionIds in segment
}
