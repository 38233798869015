import {NgModule} from '@angular/core';
import {Calendar as NativeCalendar} from "@ionic-native/calendar/ngx";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "material";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from "shared";
import {MapsModule} from "maps";
import {AuthenticationModule} from "auth"
import {ScrollingModule} from "@angular/cdk/scrolling";
import {EventMapComponent} from './components/event-map/event-map.component';
import {EventListComponent} from './components/event-list/event-list.component';
import {EventListItemComponent} from './components/event-list-item/event-list-item.component';
import {EventDetailComponent} from './components/event-detail/event-detail.component';
import {CalendarListComponent} from './components/calendar-list/calendar-list.component';
import {ContactModule} from "contact";
import {NULL_CONTACT, NullContact} from "core";
import {CalendarDetailComponent} from './components/calendar-detail/calendar-detail.component';
import {CalendarStoreModule} from "./store/module";
import {CalendarEvent, NULL_CALENDAR_EVENT, NullCalendarEvent} from "./models/event";
import {Calendar, NULL_CALENDAR, NullCalendar} from "./models/calendar";
import {LayoutModule} from "layout";
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateAdapter,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule
} from "@angular-material-components/datetime-picker";
import {NgxMatMomentAdapter, NgxMatMomentModule} from "@angular-material-components/moment-adapter";
import {EVENT_LOAD_DEBOUNCE, EVENT_LOAD_SHEDULER} from "./store/calendar/effects";
import {asyncScheduler} from "rxjs";
import {MediaModule} from "media";
import {AttendeeListComponent} from './components/attendee-list/attendee-list.component';
import {PresenceModule} from "presence";
import * as fromAngularCalendar from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import moment from 'moment';
import {CalendarNativeComponent} from "./components/calendar-native/calendar-native.component";
import {CalendarComponent, CalendarTimeframeSelector} from './components/calendar/calendar.component';
import {CalendarWeekDayViewComponent} from "./components/week-day-view/calendar-week-day-view.component";
import {CalendarNavigationComponent} from "./components/calendar-navigation/calendar-navigation.component";
import { CalendarViewSwitchComponent } from './components/calendar-view-switch/calendar-view-switch.component';
import {FilterModule} from "filter";
import {SessionModule} from "session";
import {MAT_COLOR_FORMATS, MatColorFormats, NgxMatColorPickerModule} from "@angular-material-components/color-picker";

const DATETIME_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'LLL',
  },
  display: {
    dateInput: 'LLL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LLL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

//'rgb' | 'hex' | 'hex6' | 'hex3' | 'hex4' | 'hex8'
const CUSTOM_MAT_COLOR_FORMATS: MatColorFormats = {
  display: {
    colorInput: 'hex8'
  }
}

export function nullCalendarFactory(nullContact: NullContact): Calendar {
  return new NullCalendar(nullContact);
}

export function nullCalendarEventFactory(nullContact: NullContact, nullCalendar): CalendarEvent {
  return new NullCalendarEvent(nullContact, nullCalendar);
}

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

export const PROVIDERS = [
  NativeCalendar,
  { provide: NULL_CALENDAR, useFactory: nullCalendarFactory, deps: [NULL_CONTACT]},
  { provide: NULL_CALENDAR_EVENT, useFactory: nullCalendarEventFactory, deps: [NULL_CONTACT, NULL_CALENDAR]},
  { provide: NGX_MAT_DATE_FORMATS, useValue: DATETIME_FORMATS },
  { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter },
  { provide: MAT_COLOR_FORMATS, useValue: CUSTOM_MAT_COLOR_FORMATS },
  { provide: EVENT_LOAD_SHEDULER, useValue: asyncScheduler },
  { provide: EVENT_LOAD_DEBOUNCE, useValue: 30 },
  { provide: fromAngularCalendar.MOMENT, useValue: moment }
];

@NgModule({
  declarations: [
    EventMapComponent,
    // AgmManipulator,
    EventListComponent,
    EventListItemComponent,
    EventDetailComponent,
    CalendarListComponent,
    CalendarDetailComponent,
    AttendeeListComponent,
    CalendarComponent,
    CalendarNativeComponent,
    CalendarComponent,
    CalendarWeekDayViewComponent,
    CalendarNavigationComponent,
    CalendarViewSwitchComponent,
    CalendarTimeframeSelector
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    MapsModule,
    AuthenticationModule,
    SessionModule,
    MediaModule,
    CalendarStoreModule,
    ScrollingModule,
    ContactModule,
    PresenceModule,
    LayoutModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    fromAngularCalendar.CalendarModule.forRoot(
      {
        provide: fromAngularCalendar.DateAdapter,
        useFactory: momentAdapterFactory,
      },
      {
        dateFormatter: {
          provide: fromAngularCalendar.CalendarDateFormatter,
          useClass: fromAngularCalendar.CalendarMomentDateFormatter,
        }
      }
    ),
    FilterModule,
    // TimezonePickerModule.forRoot()
    NgxMatColorPickerModule
  ],
  providers: PROVIDERS,
  exports: [
    CalendarComponent,
    EventMapComponent,
    EventListComponent,
    EventDetailComponent,
    CalendarListComponent,
    CalendarDetailComponent,
    CalendarNavigationComponent,
    CalendarViewSwitchComponent,
    AttendeeListComponent
  ]
})
export class CalendarModule { }
