import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  Inject,
  signal,
  ViewChild
} from '@angular/core';
import {BasicContainerComponent, enterLeaveAnimations, ResizeEvent, timer} from "shared";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ConferenceInfo, ConferenceParticipant} from "../../store/models";
import {Observable} from "rxjs";
import {map, shareReplay, takeUntil} from "rxjs/operators";
import {Conference} from "../../conference";
import {ConferenceService} from "../../conference.service";
import {Contact, EMPTY_ARRAY, ENVIRONMENT, FilterTypes, Logger, Platform, REMOVE_ALL_OTHER_TYPES} from "core";
import {MatButton} from "@angular/material/button";
import {ContactService, Selection} from "contact";
import {ConferenceParticipantComponent} from "../conference-participant/conference-participant.component";

export type Arrange = 'full'|'shared'|'side'|'grid';

export interface GridLayout {
  columns: number;
  columnsPercent?: string;
  rows: number;
  rowsPercent?: string;
}

@Component({
  selector: 'conference-panel',
  templateUrl: './conference-panel.component.html',
  styleUrls: ['./conference-panel.component.scss'],
  animations: [ enterLeaveAnimations ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConferencePanelComponent extends BasicContainerComponent {

  conferenceInfo = signal<ConferenceInfo>(undefined);
  conferenceId = computed<string>(()=>this.conferenceInfo()?.conferenceId);
  conference = signal<Conference>(undefined);
  allParticipants = signal<ConferenceParticipant[]>(EMPTY_ARRAY);
  unfocusParticipants = signal<ConferenceParticipant[]>(EMPTY_ARRAY);
  focusParticipant = signal<ConferenceParticipant>(undefined);
  conversationName = computed<string>(()=>{
    const participants = this.allParticipants() ?? EMPTY_ARRAY;
    const conferenceInfo = this.conferenceInfo();
    return participants.length==2 && !!conferenceInfo?.conversationName &&
    participants.find(p=>p.name==conferenceInfo.conversationName) ? undefined :
      conferenceInfo?.conversationName;
  });
  controls = signal<boolean>(true);
  controlsContentSize = signal<{width?:number,height?:number,set?:boolean}>({});
  arrange = signal<Arrange>('side');
  more = signal<boolean>(false);
  screenShared = signal<boolean>(false);
  gridLayout = signal<GridLayout>(undefined);
  addParticipant = signal<boolean>(false);
  videoInputChange = signal<boolean>(false);
  audioOutputChange = signal<boolean>(false);

  mediaDevices = signal<MediaDeviceInfo[]>([]);
  audioInputDevices = signal<MediaDeviceInfo[]>([]);
  audioOutputDevices = signal<MediaDeviceInfo[]>([]);
  videoInputDevices = signal<MediaDeviceInfo[]>([]);

  protected controlsContentElement:ElementRef = undefined;
  protected allParticipantIds = new Set<string>();

  contacts$:Observable<Contact[]>;
  cacheId$: Observable<string>;

  protected previousScreenIds = new Set<string>();

  @ViewChild('conferenceParticipant') conferenceParticipant: ConferenceParticipantComponent;
  @ViewChild('controls_content', {read: ElementRef}) controlsContent: ElementRef;

  protected logger = new Logger('ConferencePanelComponent');

  constructor(public bottomSheetRef: MatBottomSheetRef<ConferencePanelComponent>,
              // ConferencePanelOverlayConfig data is injected here to pass params...
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              @Inject(ENVIRONMENT) protected environment: any,
              public contactService: ContactService,
              public conferenceService: ConferenceService,
              public changeDetectorRef: ChangeDetectorRef,
              public platform: Platform) {
    super();
    //console.log("WEBRTC:ctor:ConferencePanelComponent");
    this.conferenceService.getAllConferenceInfos$().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(conferenceInfos => {
      this.updateConferenceInfos(conferenceInfos ?? []);
      //console.log("WEBRTC:ConferencePanelComponent.subscribed",conferenceInfos);
    });

    let addParticipant = this.addParticipant()
    const updateControls = ()=> {
      const controls = this.controls();
      if (controls) {
        if (addParticipant!=this.addParticipant()) {
          this.controlsContentSize.set({
            ...this.controlsContentSize(), set:true
          });
        }
      } else {
        this.addParticipant.set(false);
        this.controlsContentSize.set({});
      }
      addParticipant = this.addParticipant();
    }
    const updateControlsTimer = timer();
    effect((onCleanup) => {
      this.controls();
      this.addParticipant();
      updateControlsTimer.set(updateControls);  // handle in animation frame
      onCleanup(updateControlsTimer.cancel);
    });

    this.conferenceService.getMediaDevices()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(mediaDevices => {
        this.mediaDevices.set(mediaDevices);
        const [audioInputs, audioOutputs, videoInputs] =
          mediaDevices.reduce(
            (accumulator, device) => {
              const index = device.kind == 'audioinput' ? 0 : device.kind == 'audiooutput' ? 1 : 2;
              accumulator[index].push(device);
              return accumulator;
            },
            [ [], [], [] ]
          );
        this.logger.info('MEDIA DEVICES', {audioInputs, audioOutputs, videoInputs});
        this.audioInputDevices.set(audioInputs);
        this.audioOutputDevices.set(audioOutputs);
        this.videoInputDevices.set(videoInputs);
      });
  }

  updateConferenceInfos(conferenceInfos:ConferenceInfo[]) {
    let conferenceInfo = conferenceInfos.length>0 ? (conferenceInfos.find(c=>{
      return !!this.conferenceService.getConference(c.conferenceId)?.active;
    }) ?? conferenceInfos[0]) : undefined;
    if (!!conferenceInfo) {
      this.onAttachContent(this.controlsContentElement);
      this.updateConferenceInfo(conferenceInfo,'update');
    } else {
      this.close();
    }
  }

  /*
  for (let i=0; i<24; i++) {
    participants.push({
      id: "new"+i,
      type: "conference",
      video: false,
      audio: true,
      name: "Schlumpf "+i,
      conferenceType: 'audio',
      timeAdded: Date.now(),
      timeSince: Date.now(),
      connectionId: "cid"+i,
      version: 0
    });
  }*/
  updateConferenceInfo(conferenceInfo:ConferenceInfo,source?:string) {
    const conferenceId = conferenceInfo?.conferenceId;
    const conference = this.conferenceService.getConference(conferenceId);
    const userId       = this.conferenceService.userId;
    // we clone all participants to avoid changing the original data...
    const sourceParticipants = [...conferenceInfo.pickedUp, ...conferenceInfo.paused, ...conferenceInfo.ringing];
    const orderedParticipants = sourceParticipants
      .reduce((result,participant,index)=> {
        participant = <ConferenceParticipant>{...participant,uid:participant.id+':'+participant.screen};
        if (participant.id==userId) {
          result.unshift(participant);
        } else {
          result.push(participant);
        }
        return result;
      }, [] as ConferenceParticipant[]);
    const participants = orderedParticipants
      .reduce((result,participant,index)=> {
        if (!!participant.video && !!participant.screen) {
          result.push(<ConferenceParticipant>{...participant,video:false});
          result.push(<ConferenceParticipant>{...participant,screen:false});
        } else {
          result.push(participant);
        }
        return result;
      }, [] as ConferenceParticipant[]);
    const screenIndexes = participants.reduce((result,p,index)=>{
      if (p.screen) {
        result.push(index);
      }
      return result;
    },[] as number[]);
    const firstNewScreenIndex = screenIndexes.find(index=>!this.previousScreenIds.has(participants[index].id));
    this.previousScreenIds.clear();
    screenIndexes.forEach(index=>this.previousScreenIds.add(participants[index].id));
    const prevFocusParticipant = this.focusParticipant();
    const currFocusParticipant =
      (firstNewScreenIndex>=0 ? participants[firstNewScreenIndex] : undefined) ??
      (!!prevFocusParticipant?.id ?
        participants.reduce((result,participant,index)=>{
          if (participant.id==prevFocusParticipant.id) {
            if (!result) {
              return participant;
            } else if ((participant.screen && prevFocusParticipant.screen) ||
                       (participant.video && prevFocusParticipant.video)) {
              return participant;
            }
          }
          return result;
        }, undefined) : undefined) ??
      participants.find(participant=>participant.id!==userId);
    const unfocusParticipants = participants
      .reduce((result,participant,index)=> {
        if (participant!==currFocusParticipant) {
          if (participant.id==currFocusParticipant.id) {
            result.unshift(participant);
          } else {
            result.push(participant);
          }
        }
        return result;
      }, [] as ConferenceParticipant[]);
    const sortedParticipants = [currFocusParticipant,...unfocusParticipants];
    console.log("#C.updateConferenceInfo."+source,firstNewScreenIndex,"\nsource:",sourceParticipants,"\nunfocused:",unfocusParticipants,"\nfocused",currFocusParticipant);
    //console.log("PARTICIPANTS.4",[...participants],"focusIndex",focusIndex,"focusParticipant",focusParticipant);
    this.conferenceInfo.set(conferenceInfo);
    //console.log("UPDATE_CONFERENCE_INFO.3",conferenceInfo,"user",{...user},"screen",user?.screen,"more",this.more(),"participants",[...participants],"participant",{...conferenceInfo.participant},"screenIndex",screenIndex,"screenId",screenId,"focusId",focusId);
    if (!!conferenceInfo.participant?.screen && !this.more()) {
      this.more.set(true);
    }
    //console.log("WEBRTC.participant",,"participants",sortedParticipants);
    this.allParticipants.set(sortedParticipants);
    this.unfocusParticipants.set(unfocusParticipants);
    this.focusParticipant.set(currFocusParticipant);
    this.conference.set(conference);
    this.allParticipantIds.clear();
    sortedParticipants.forEach(p=>this.allParticipantIds.add(p.id));
    //console.log("PARTICIPANTS.5",[...participants],"unfocusParticipants",this.unfocusParticipants$.value);
  }

  onResize(event:ResizeEvent): void {
    super.onResize(event);
  }

  onTap(event?:UIEvent) {
    this.controls.set(!this.controls());
    if (!this.controls() && this.more()==true && !this.conference()?.info?.participant?.screen) {
      this.more.set(false);
    }
  }

  setFocusParticipant(participant:ConferenceParticipant,event?:UIEvent) {
    if (!!participant && participant!==this.focusParticipant() && !!this.conference()?.info) {
      console.log("#C.setFocusParticipant",participant,"previous",this.focusParticipant());
      this.focusParticipant.set(participant);
      this.updateConferenceInfo(this.conference()?.info,'focus');
      event?.stopPropagation();
    }
  }

  cancelAddParticipant() {
    this.addParticipant.set(false);
  }

  startAddParticipant() {
    if (!this.contacts$) {
      this.cacheId$  = this.contactService.cacheId$.pipe(takeUntil(this.onDestroy$));
      this.contacts$ = this.contactService.entities$.pipe(
        takeUntil(this.onDestroy$),
        shareReplay(1),
        map(contacts=>contacts.filter(c=>!this.allParticipantIds.has(c.id)))
      );
    }
    this.contactService.setTypedFilters({[FilterTypes.NAVIGATION]: ['team.connected']},REMOVE_ALL_OTHER_TYPES);
    this.contactService.updateSearchTerm( undefined);
    this.addParticipant.set(true);
  }

  cancelVideoInputChange() {
    this.videoInputChange.set(false);
  }

  startVideoInputChange() {
    this.videoInputChange.set(true);
  }

  cancelAudioOutputChange() {
    this.audioOutputChange.set(false);
  }

  startAudioOutputChange() {
    this.audioOutputChange.set(true);
  }

  toggleVideoFacingMode(conference: Conference) {
    conference?.toggleVideoFacingMode();
  }

  canShareScreen(conferenceId:string) {
    return this.conferenceService.canShareScreen(conferenceId);
  }

  shareScreen(conferenceId:string,button:MatButton) {
    button.disabled = true;
    this.screenShared.set(!this.screenShared());
    this.conferenceService.shareScreen(conferenceId,this.screenShared())
      .then(screen=> {
        this.screenShared.set(screen);
      })
      .catch(error=> {
        this.screenShared.set(false);
      })
      .finally(()=>{
        button.disabled = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  showMore(show = true) {
    this.onAttachContent(this.controlsContentElement);
    this.more.set(show);
  }

  arrangeView() {
    switch(this.arrange()) {
      case "full":
        this.arrange.set('side');
        break;
      case "shared":
        this.arrange.set('side');
        break;
      case "side":
        this.gridLayout.set(undefined);
        this.arrange.set('grid');
        break;
      case "grid":
      default:
        this.arrange.set('full');
        break;
    }
  }

  close(event?:UIEvent) {
    //console.trace("CLOSE",event);
    this.bottomSheetRef.dismiss();
    event?.preventDefault();
  }

  trackByParticipant(index:number,participant:ConferenceParticipant):string {
    return participant.uid;
  }

  onGridResize(event:ResizeEvent) {
    let element    = event.element.nativeElement;
    let width      = element?.offsetWidth;
    let height     = element?.offsetHeight;
    let count      = this.allParticipants()?.length??0;
    let maxColumns = Math.max(1,Math.floor(width/200));
    let maxRows    = Math.max(1,Math.floor(height/150));
    let layout:GridLayout = {
      columns: 1,
      rows: 1
    }
    if ((maxColumns+maxRows)>=2) {
      let max = maxColumns*maxRows;
      count = Math.min(count,max);
      if (count==2) {
        if (maxColumns>maxRows) {
          layout.columns = 2;
        } else {
          layout.rows = 2;
        }
      } else if (count<max-(max%2)) {
        while (count>(layout.columns*layout.rows)) {
          if (maxColumns/layout.columns >= maxRows/layout.rows) {
            layout.columns++;
          } else {
            layout.rows++;
          }
        }
      } else {
        layout.columns = maxColumns;
        layout.rows    = maxRows;
      }
    }
    if (!this.gridLayout() ||
      this.gridLayout().columns!=layout.columns ||
      this.gridLayout().rows!=layout.rows) {
      layout.columnsPercent = (100/layout.columns)+'%';
      layout.rowsPercent = (100/layout.rows)+'%';
      this.gridLayout.set(layout);
    }
    console.log("onGridResize",event,width,height,"count",count,"maxColumns",maxColumns,"maxRows",maxRows,"layout",layout);
  }

  onDone(event:any) {
    this.controlsContentSize.set({
      ...this.controlsContentSize(), set:false
    });
  }

  onAttachContent(elementRef: ElementRef) {
    if (!!elementRef) {
      this.controlsContentElement = elementRef;
      const updateSize = (set:boolean)=>{
        this.controlsContentSize.set({
          width:elementRef.nativeElement?.offsetWidth,
          height:elementRef.nativeElement?.offsetHeight,
          set
        });
      };
      const first = !(this.controlsContentSize()?.width>0);
      if (first) {
        window.setTimeout(()=>updateSize(false));
      } else {
        this.controlsContentSize.set({
          ...this.controlsContentSize(), set:true
        });
        window.setTimeout(()=>updateSize(true),100);
      }
    }
  }

  onAddParticipant(selection:Selection) {
    this.addParticipant.set(false);
    this.conferenceService.inviteToConference(this.conferenceId(),selection.contact.id);
  }

  onVideoInputChange(conference: Conference, deviceId: string) {
    conference?.setVideoInputDevice(deviceId);
  }

  onAudioOutputChange(conference: Conference, deviceId: string) {
    conference.setAudioOutputDevice(this.conferenceService.conferenceAudio, deviceId);
  }
}
