import {Action, Contact, NULL_CONTACT, VersionedId} from "core";
import {Inject, InjectionToken} from "@angular/core";
import {NULL_TASK_LIST, TaskList} from "./task-list";
import {Message} from "messaging";
import cloneDeep from "lodash/cloneDeep";
import {ChatTimelineMessage} from "chat";
import {SearchFilter} from "store";
import {Media} from "media";

type TaskTargetValue = Partial<Contact & { actions: Action[] }> |
                       Partial<Media> |
                       ChatTimelineMessage & { actions: Action[] } |
                       Action;
export class TaskTarget {
  constructor(private readonly _type: string,
              private readonly _value: TaskTargetValue) {
      if (this._value) {
        this._value = this._type == 'contact'
          ? new Contact(this._value as Partial<Contact>)
          : this._value;
      }
  }
  isContact(value = this.value): value is Contact {
    return this._value instanceof Contact;
  }
  isMedia(value = this.value): value is Media {
    return this._type == 'media'
  }
  isMessage() {
    return this._type == 'message';
  }
  isAction(value = this.value): value is Action {
    return this._type == 'action';
  }
  get contact(): Contact {
    switch(this.type) {
      case 'contact': return this.value as Contact;
      case 'message': return new Contact((this.value as ChatTimelineMessage).from);
      default: return undefined;
    }
  }
  get media(): Media {
    if (this.type == 'media') {
      return this.value as Media;
    }
    return undefined;
  }
  get message(): ChatTimelineMessage {
    if (this.type == 'message') {
      return this.value as ChatTimelineMessage;
    }
    return undefined;
  }
  get action(): Action {
    if (this.type == 'action') {
      return this.value as Action;
    }
    return undefined;
  }
  get type(): string {
    return this._type;
  }
  get value(): TaskTargetValue {
    return this._value;
  }
  get actions(): Action[] {
    return this.isAction() ? [this.value] : (this.value as any).actions;
  }
  toJSON() {
    return {[this._type]: this._value};
  }
}

interface HasReadonlyId { id: string; }
export class Task implements HasReadonlyId {
  readonly id: string;
  readonly parentId: string;
  readonly version : number;
  author: Partial<Contact>;
  source: {
    id: string;
    version: string;
    name: string;
  }
  title: string;
  info: string;
  remark?: string;
  type: string;
  taskList: Partial<TaskList>;
  participants: Partial<Contact>[];
  attachments: Partial<Media>[];
  tags: string[];
  properties: any; // { completed: {contact: Partial<Contact>, time: number}, target: {[type: string]: Partial<Contact> | Partial<Media> | Partial<ChatTimelineMessage>}}
  editorsTerm: string;
  viewersTerm: string;
  permission: any;
  timeCreated: number;
  timeUpdated: number;
  timeDeleted: number;
  timeScheduled: number;
  timeDeadline: number;
  timeOrder: number;
  timeNotification: number;
  sortString: string;
  searchString?: string;

  //diffAfter: number;
  //diffBefore: number;
  //ordered: boolean;

  constructor(init?: Partial<Task>) {
    Object.assign(this, init);
  }

  get isNew(): boolean {
    return !this.id;
  }

  get editable(): boolean {
    return this.permission?.edit;
  }

  isEditable(path?: string) {
    const keys = path?.split('.') || [];
    let permissionEditable = this.permission.properties;
    if (typeof permissionEditable === 'object') {
      for (let key of keys) {
        permissionEditable = permissionEditable[key];
        if (permissionEditable === undefined) {
          break;
        }
      }
    }
    return typeof permissionEditable === 'boolean'
        ? permissionEditable
        : this.editable;
  }

  isCompleted(): boolean {
    return !!this.properties?.completed?.time;
  }

  protected _hasFixedOrder: boolean;
  hasFixedOrder(): boolean {
    if (this._hasFixedOrder == undefined) {
      if (!this.isCompleted()) {
        const timeOrder = this.calculateTimeOrder();
        //console.log('xbug:timeOrder', timeOrder,'this.timeScheduled', this.timeScheduled, 'this.timeDeadline', this.timeDeadline, 'fixed', timeOrder == this.timeScheduled || timeOrder == this.timeDeadline);
        this._hasFixedOrder =
          timeOrder == this.timeScheduled ||
          timeOrder == this.timeDeadline;
      } else {
        this._hasFixedOrder = true;
      }
    }
    return this._hasFixedOrder;
  }

  calculateTimeOrder():number {
    const timeCreated = this.timeCreated ?? 0;
    const timeOrder = this.timeOrder ?? timeCreated;
    const timeScheduled = this.timeScheduled ?? timeOrder;
    const timeDeadline = this.timeDeadline ?? Number.MAX_SAFE_INTEGER;
    //console.log('xbug:timeCreated', timeCreated,'timeOrder', timeOrder, 'timeScheduled', timeScheduled, 'timeDeadline', timeDeadline);
    return Math.min(timeScheduled, timeDeadline);
  }

  get timeCompleted(): number {
    return this.properties?.completed?.time;
  }

  set timeCompleted(time) {
  }

  complete(contact: Contact): Task {
    const complete = !!contact;
    if (complete!=this.isCompleted()) {
      const completedTask = new Task(this);
      const completed = complete ? { time: new Date().getTime(), contact } : undefined;
      if (completed && !completedTask.properties?.completed) {
        !completedTask.properties && (completedTask.properties = {});
        completedTask.properties.completed = completed;
      } else if (!completed && completedTask.properties) {
        delete completedTask.properties.completed;
      }
      return completedTask;
    }
    return this;
  }

  getTarget(type: string): TaskTarget {
    const value = this.properties?.target?.[type];
    return value ? new TaskTarget(type, value) : undefined;
  }

  get targets(): TaskTarget[] {
    const target = this.properties?.target;
    return target ? Object.entries(target)?.reduce((result, [type, value]) => {
      const target = new TaskTarget(type, value);
      result.push(target);
      return result;
    }, []) : undefined;
  }

  set target(target: TaskTarget) {
    if (target?.type) {
      if (this.properties?.target) {
        if (target.value) {
          this.properties.target[target.type] = target.value;
        } else {
          delete this.properties.target?.[target.type];
        }
      } else if (target.value) {
        !this.properties && (this.properties = {})
        this.properties.target = {[target.type]: target.value};
      }
    }
  }

  get clone(): Task {
    const clone = cloneDeep(this);
    (<HasReadonlyId>clone).id = undefined;
    if (!clone.properties) {
      clone.properties = {};
    }
    clone.properties.parentId = clone.id;
    return clone;
  }

  updateTimeOrder(timeOrder: number): Task {
    // not completed and sort time not scheduled or deadline...
    if (!this.hasFixedOrder()) {
      timeOrder = Math.round(timeOrder);
      const updatedTask = new Task(this);
      updatedTask.timeOrder  = timeOrder;
      const timeCreated   = this.timeCreated ?? 0;   // 0 should never happen!
      const timeScheduled = this.timeScheduled ?? timeOrder;
      const timeDeadline  = this.timeDeadline ?? Number.MAX_SAFE_INTEGER;
      let time= Math.min(timeScheduled,timeDeadline);
      let hexTime = time.toString(16)
      let sortString = 'O' + hexTime.length.toString(16) + hexTime;
      if (time!=timeScheduled) {
        hexTime = (time = timeScheduled).toString(16);
        sortString += hexTime.length.toString(16) + hexTime;
      }
      if (time!=timeOrder) {
        hexTime = (time = timeOrder).toString(16);
        sortString += hexTime.length.toString(16) + hexTime;
      }
      if (time!=timeCreated) {
        hexTime = timeCreated.toString(16);
        sortString += hexTime.length.toString(16) + hexTime;
      }
      // we do not add checksum...
      updatedTask.sortString = sortString;
      return updatedTask;
    }
    return this;
  }
}

export class NullTask extends Task {

  constructor(@Inject(NULL_CONTACT)  public author: Contact,
              @Inject(NULL_TASK_LIST) public taskList: TaskList) {
    super();
    this.title = '';
    this.info = '';
    this.timeCreated = this.timeUpdated = new Date().getTime();
    this.permission = {edit: true};
  }
}

export const NULL_TASK = new InjectionToken('NullTask');

export interface TaskRelatedMessage extends Message {
}

export const TaskSubscriptionMessageType = "task.subscription";
export interface TaskSubscriptionMessage extends TaskRelatedMessage {
  subscribed: VersionedId[];
}

export const TaskMessageType = "task";
export interface TaskMessage extends TaskRelatedMessage {
  task: Task;
}

export const TaskViewSubscriptionMessageType = "task.view.subscription";
export interface TaskViewSubscriptionMessage extends TaskRelatedMessage {
  timeStart: number;
  segmentSize: number;
  filter?: Partial<{
    id: string;
    filters: string[];
    term: string;
  }>;
  segment?: Task[];
}



export const TaskViewSubscriptionResultMessageType = "task.view.subscription.result";
export interface TaskViewSubscriptionResultMessage extends TaskViewSubscriptionMessage {
  totalSize: number;
  segmentIndex;
}

export interface TaskViewSegmentAbstractMessage extends TaskRelatedMessage {
  filter?: SearchFilter;
  segmentIndex: number;
}

export const TaskViewSegmentRequestMessageType = "task.view.segment.request";
export interface TaskViewSegmentRequestMessage extends TaskViewSegmentAbstractMessage {
  segmentSize: number;
}

export const TaskViewSegmentMessageType = "task.view.segment";
export interface TaskViewSegmentMessage extends TaskViewSegmentAbstractMessage {
  totalSize: number;
  openSize: number;
  segment?: {
    version: number;
    checksum: number;
    data: Task[];
  } | {
    version: number;
    checksum: number;
    size: number;
    first: VersionedId;
    last: VersionedId;
  };
  idBefore?: string;
  idAfter?: string;
}

export const TaskViewSegmentVerificationMessageType = "task.view.segment.verification";
export interface TaskViewSegmentVerificationMessage extends TaskViewSegmentAbstractMessage {
  totalSize: number;
  openSize: number;
  segment?: {
    version: number;
    checksum: number;
    size: number;
    first: VersionedId;
    last: VersionedId;
  };
}

