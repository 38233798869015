/* projects/calendar/src/lib/components/calendar-view-switch/calendar-view-switch.component.scss */
:host {
  display: flex;
  flex-direction: row;
}
:host > div {
  margin: 2px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}
:host > div:not(.current) {
  background-color: white;
  border: 1px solid var(--color-primary);
}
:host > div.current {
  background-color: var(--color-primary);
  color: var(--color-primaryContrast);
}
:host > div.current mat-icon {
  color: var(--color-primaryContrast);
}
:host > div:nth-child(1) mat-icon,
:host > div:nth-child(2) mat-icon {
  transform: scale(1.11);
}
:host > div mat-icon {
  margin: 0;
}
:host-context(.color-scheme-dark) > div:not(.current) {
  background-color: #333;
}
/*# sourceMappingURL=calendar-view-switch.component-H5ZR4Y3G.css.map */
