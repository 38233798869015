import {Inject, Injectable} from '@angular/core';
import {ENVIRONMENT, Logger} from "core";
import {PropertiesService} from "properties";
import {catchError, combineLatest, from, Observable, of, Subject, throwError} from "rxjs";
import {map, scan, switchMap} from "rxjs/operators";
import {Product} from "../models/product";
import {Tax} from "../models/tax";
import {Saving} from "../models/saving";
import {PaymentServiceImpl} from "./payment-service.factory";
import {ProductSelector} from "./product-selector.service";

// Injector is configured to use factory method to create PaymentService instances (see module definition)
// Having the class annotated with @Injectable at the same time produces a warning
// when compiling for production with AoT enabled

// @Injectable({
//  providedIn: 'root'
// })
export class PaymentService {

  protected logger = new Logger('PaymentService');

  constructor(protected impl: PaymentServiceImpl,
              protected propertiesService: PropertiesService,
              protected productSelector: ProductSelector,
              @Inject(ENVIRONMENT) protected environment: any) {
    //console.debug('PaymentService.ctor()');
  }

  async purchase(productId: string): Promise<void> {
    const product = await this.impl.get(productId);
    if (product) {
      try {
        let result = await this.impl.order(productId);
        if (result) { // payment success -> reload properties to reflect the change
          this.propertiesService.reload();
          this.logger.info(`Purchase > DONE productId=${productId}`);
        }
      } catch (error) {
        this.logger.error(`Purchase.ERROR > productId=${productId}.`, error);
        throw error;
      }
    } else {
      throw new Error(`Purchase.ERROR > Product not found ${productId}`);
    }
  }

  select(productId: string, source?: string): Observable<Product> {
    productId = this.impl.source=='stripe' && (!source || this.impl.source!=source)
      ? productId.replace(/\./g, '_')
      : productId;
    return from(this.impl.get(productId))
      .pipe(switchMap((product) => {
        if (product) {
          return new Observable<Product>(subscriber => {
            const next = (product: Product) => {
              try {
                subscriber.next(product);
              } catch (e) {
                this.logger.error('Select Error', e);
              }
            };
            subscriber.next(product);
            this.impl.when(productId).updated(next);
            return () => {
              this.impl.off(next);
              subscriber.complete();
            };
          });
        } else {
          return throwError(new Error(`SELECT.ERROR > Product not found ${productId}`));
        }
      }));
  }

  selectProduct(path: string): Observable<[Product, number, number]> {
    return this.productSelector.select(path).pipe(
      switchMap(([productId, source, expiryTime, cancelTime]) => {
        if (productId) {
          /*
           * If we directly return the observable taken from this.select(productId).pipe(...)
           * the switchMap operator stops working properly after the first emission of productSelector -
           * i.e. subsequent emissions of productSelector will NOT ultimately cause an emission of the returned observable.
           * (although it should emit the current Product and its expiryTime values immediately upon subscription)
           *
           * Is this a problem with switchMap operator?
           * Or the observable returned by PaymentService.select() is somehow not compatible with it?
           *
           * Current workaround is to use an extra subject which basically repeats the output of PaymentService.select().
           */
          const subject = new Subject<[Product, number, number]>();
          this.select(productId).pipe(map((product) => {
            return [product, expiryTime, cancelTime] as [Product, number, number];
          })).subscribe((value) => {
            // console.debug('PaymentService.select', value);
            subject.next(value);
          });
          return subject.asObservable();
        } else {
          return of([null as Product, 0, 0] as [Product, number, number]) ;
        }
      })
    );
  }

  get productIds(): Observable<string[]> {
    return this.impl.productIds.pipe(catchError(error => []));
  }

  get isPro$(): Observable<boolean> {
    return combineLatest([
      this.productSelector.selectPro(),
      this.propertiesService.properties$
    ]).pipe(switchMap(([product , properties]) => {
      const [productId, source, expiryTime, cancelTime] = product;
      const user  = properties.user;
      const group = properties.group?.id;
      return of(!!productId || user.groups?.[group]?.tags?.includes('pro'));
    }));
  }

  get isMax$(): Observable<boolean> {
    return combineLatest([
      this.productSelector.selectMax(),
      this.propertiesService.properties$
    ]).pipe(switchMap(([product , properties]) => {
      const [productId, source, expiryTime, cancelTime] = product;
      const user  = properties.user;
      const group = properties.group?.id;
      return of(!!productId || user.groups?.[group]?.tags?.includes('max'));
    }));
  }

  get isBasic$(): Observable<boolean> {
    return combineLatest([this.isPro$, this.isMax$]).pipe(map(([isPro, isMax]) => !(isPro || isMax)));
  }

  get productType(): Observable<string> {
    return this.productSelector.select(`${this.environment.app}`, false).pipe(
      scan((state: { productId: string, expiryTime: number, type: string }, product: [string, string, number, number] ) => {
          const [productId, source, expiryTime, cancelTime] = product;
          if (productId) {
            const type = this.impl.getType(productId);
            const  now = new Date().getTime();
            if (!state) {
              state = { productId: productId, expiryTime: expiryTime, type: type }
            } else {
              if (state.productId == productId) {
                if (state.expiryTime != expiryTime) {
                  state.expiryTime = expiryTime;
                }
              } else {
                if (!(type=='pro' && state.type=='max') &&
                     (expiryTime > now || expiryTime > state.expiryTime)) {
                  state = { productId: productId, expiryTime: expiryTime, type: type }
                }
              }
            }
          }
          // console.debug('STATE', state);
          return state;
        }, undefined
      ),
      map(state => {
        return state?.expiryTime > new Date().getTime() ? state.type : undefined;
      })
    );
  }

  getTax(): Observable<Tax> {
    return this.propertiesService.user$.pipe(
      switchMap(user => {
        return from(this.impl.getTax(user.countryCode));
      })
    );
  }

  async cancel(productId: string): Promise<void> {
    return await this.impl.cancel(productId);
  }

  async refund(productId: string): Promise<void> {
    return await this.impl.refund(productId);
  }

  restorePurchases() {
    return this.impl.restorePurchases();
  }

  getSaving(targetProduct: Product, refProduct: Product): Saving {
    if (refProduct.id!=targetProduct.id) {
      const refInterval = this.impl.getInterval(refProduct.id);
      const targetInterval = this.impl.getInterval(targetProduct.id);
      if (refInterval && refInterval.value &&
          targetInterval && targetInterval.value) {
        try {
          const refNormalizedPrice = Number(refProduct.price) / refInterval.value;
          const targetNormalizedPrice = Number(targetProduct.price) / targetInterval.value;
          const percentage = targetNormalizedPrice ? (100 - (targetNormalizedPrice * 100) / refNormalizedPrice) : 0;
          return { price: targetNormalizedPrice, percentage: percentage };
        } catch (e) {
          this.logger.error(`Failed to calculate save percentage for refProductId=${refProduct.id} targetProductId=${targetProduct.id}`, e);
        }
      }
    }
    return { price: 0, percentage: 0 };
  }

  getInterval(productId: string): {value: number, name: string} {
    return this.impl.getInterval(productId);
  }
}
