/* projects/contact/src/lib/components/contact-join-info/contact-join-info.component.scss */
:host {
  display: block;
}
h4.join-info {
  margin: 0 0 3px;
  line-height: normal;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.join-info a {
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
}
.join-info > span.label {
  color: gray;
}
.join-info > span.label.mid {
  color: orange;
}
.join-info > span.label.short {
  color: red;
}
/*# sourceMappingURL=contact-join-info.component-565BSGSA.css.map */
