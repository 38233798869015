/* projects/chat/src/lib/components/main-container/chat-main-container.component.scss */
:host {
  width: 100%;
  height: 100vh;
  background-color: orange;
}
mat-sidenav,
mat-sidenav.md-locked-open-add-active,
mat-sidenav.md-closed.md-locked-open-add-active,
mat-sidenav.md-locked-open {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  border-right: 1px solid lightgray;
}
mat-sidenav.mat-drawer-over,
mat-sidenav.md-locked-open-add-active.mat-drawer-over,
mat-sidenav.md-closed.md-locked-open-add-active.mat-drawer-over,
mat-sidenav.md-locked-open.mat-drawer-over {
  border-right-width: 0;
}
/*# sourceMappingURL=chat-main-container.component-2EEW2VVP.css.map */
