/* projects/launcher/src/lib/components/app-install/app-install.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
:host > div {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 0 12px 0;
}
:host > div .qr-code {
  margin-right: 20px;
}
:host > div .links {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
:host > div .links > div {
  position: relative;
}
:host > div .links > div > div > a.badge {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
}
:host > div .links > div > div > a.badge.google {
  width: 155px;
  height: 60px;
}
:host > div .links > div > div > a.badge.google > img {
  width: 155px;
  height: 60px;
  opacity: 0;
}
:host > div .links > div > div > a.badge.apple {
  width: 135px;
  height: 40px;
  margin: 8px;
}
:host > div .links > div > div > a.badge.apple > img {
  width: 135px;
  height: 40px;
  opacity: 0;
}
:host > div .links > div.space {
  margin-bottom: 10px;
}
:host > div .links > div:not(.alternative):after {
  font-family: "Material Icons";
  content: "\eac9";
  color: var(--color-accent);
  font-size: 24px;
  line-height: 0;
  display: block;
  position: absolute;
  top: 30px;
  left: -20px;
  animation: pulse 1000ms infinite;
}
:host > div .links > div.alternative {
  text-align: center;
}
:host > div .links > div.alternative > div > span {
  color: #777;
  padding: 2px 8px;
  cursor: pointer;
  text-decoration: underline;
}
:host > div .links > div.alternative > div.install > span {
  border-radius: 4px;
  text-decoration: none;
  cursor: default;
}
:host > div .links > div.alternative > div.install:not(.downloading) > span {
  cursor: pointer;
  background-color: var(--color-primary);
  color: var(--color-primaryContrast);
}
:host h4 {
  max-width: 80%;
  text-align: center;
}
:host .info {
  max-width: 300px;
}
:host button.close {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms linear;
}
:host button.close mat-icon {
  color: #aaa;
}
:host.fullscreen button.close {
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  right: constant(safe-area-inset-right);
  right: env(safe-area-inset-right);
}
:host-context(.color-scheme-dark) {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .step mat-icon {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) button.close mat-icon {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) > div .links > div > div.alternative > div > span {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) > div .links > div > div.alternative > div.install > span {
  color: var(--color-primaryContrast);
}
@keyframes pulse {
  0% {
    left: -24px;
  }
  50% {
    left: -16px;
  }
  100% {
    left: -24px;
  }
}
/*# sourceMappingURL=app-install.component-B25RXJUP.css.map */
