import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  model,
  signal,
  ViewChild
} from '@angular/core';

import {Alignment, BasicContainerComponent, ResizeEvent} from "shared";
import {isValidNumber, RoutePathService} from "core";
import {ActivatedRoute, Router} from "@angular/router";
import {ChatService, FilteredList} from "../../chat.service";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {
  ChatConversationCreateContainerComponent
} from "../conversation-create-container/chat-conversation-create-container.component";
import {ConversationData} from "../../../lib/store/models";
import {LayoutService, SidenavMode} from "layout";
import {ChatConversationListComponent} from "../conversation-list/chat-conversation-list.component";

@Component({
  selector: 'chat-conversation-list-container',
  templateUrl: './chat-conversation-list-container.component.html',
  styleUrls: ['./chat-conversation-list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationListContainerComponent extends BasicContainerComponent {

  searchTerm = model<string>(undefined);

  @ViewChild('create_menu_container') createMenuContainerElement: ElementRef;
  @ViewChild('create_menu', { read: ElementRef }) createMenuElement: ElementRef;
  @ViewChild('create') createContainer: ChatConversationCreateContainerComponent;
  @ViewChild('list') list: ChatConversationListComponent;

  conversations = signal<ConversationData[]>([]);
  conversationsFilteredList = signal<FilteredList<ConversationData>>(undefined);

  protected previousUrl : string;
  protected height: number = undefined;

  protected previousConversationId: string;
  protected previousConversationIndex: number;

  createMode$:BehaviorSubject<'direct'|'group'|undefined> = new BehaviorSubject(undefined);
  createMenu$ = new BehaviorSubject(false);

  constructor(public chatService:ChatService,
              public layoutService: LayoutService,
              public route: ActivatedRoute,
              public router: Router,
              public routePathService : RoutePathService,
              public changeDetectorRef: ChangeDetectorRef) {
    super();
    this.conversationsFilteredList.set(this.chatService.getConversations());
    this.conversationsFilteredList().list$.pipe(takeUntil(this.onDestroy$))
      .subscribe(conversations=>{
        this.conversations.set(conversations);
      });
    this.createMode$.pipe(
      takeUntil(this.onDestroy$),
      filter(create=>!!create)
    ).subscribe(create => {
      this.createContainer.reset(true);
      this.createMenu$.next(false);
    });
    this.createMenu$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(open => {
      //console.log("OPEN",open,"height",this.height);
      if (this.createMenuContainerElement?.nativeElement) {
        if (!this.height) {
          // calculation here! in ngAfterViewInit() it was too early. normally it worked, but
          // clicking on a message notification opened directly the chat and the hight at this
          // monat was 0. now we calc only at use!
          const style  = getComputedStyle(this.createMenuElement.nativeElement,null);
          const margin = (parseInt(style?.marginBottom) || 0) + (parseInt(style?.marginTop) || 0);
          this.height  = this.createMenuElement.nativeElement.clientHeight + margin;
        }
        //console.log("HEIGHT.later",this.createMenuElement.nativeElement.clientHeight,"calc",this.height);
        this.createMenuContainerElement.nativeElement.style.height = (open ? this.height : 0) + 'px';
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.conversationsFilteredList().unsubscribe();
  }

  protected onInitialized$ = new BehaviorSubject<boolean>(false);
  protected onAttached$ = new BehaviorSubject<boolean>(false);

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.createMenuContainerElement.nativeElement.style.height = "0px";
    this.onInitialized$.next(true);
  }

  protected updateCurrentConversationId() {
    const currentConversationId = this.chatService.currentConversationId;
    const currentConversationIndex = !!currentConversationId ? this.conversations().findIndex(conversation=>conversation.id==currentConversationId) : undefined;
    //console.log("UPDATE.currentConversationId",currentConversationId,"currentConversationIndex",currentConversationIndex);
    const valid = !!currentConversationId &&
      isValidNumber(currentConversationIndex) &&
      currentConversationIndex>=0;
    if (valid) {
      this.previousConversationId = currentConversationId;
      this.previousConversationIndex = currentConversationIndex;
      //console.log("VALID+TRIGGER",trigger,"valid",this.list.virtualList.isValidIndex(this.previousConversationIndex),"visible",this.list.virtualList.isVisibleIndex(this.previousConversationIndex));
      /*if (trigger && this.list.virtualList.viewportSize>0 &&
          this.list.virtualList.isValidIndex(this.previousConversationIndex) &&
         !this.list.virtualList.isVisibleIndex(this.previousConversationIndex)) {
        trigger = false;
        this.list.virtualList.scrollToIndex(this.previousConversationIndex,Alignment.inside,0);
      }*/
    }
  }

  updateSearchTerm(term : string) : void {
    //console.log("SEARCH FOR "+term);
    this.conversationsFilteredList().updateSearchTerm(term);
    //this.taskActions.updateSearchTerm(term || '');
  }

  newUserOrGroup() : void {
    this.createMenu$.next(!this.createMenu$.value);
  }

  ngOnAttach() {
    super.ngOnAttach();
    this.previousUrl = this.routePathService.getPreviousUrl();
    this.chatService.load();
    this.onAttached$.next(true);
    //console.log("ChatConversationListContainerComponent.ngOnAttach.from",this.previousUrl);
  }

  ngOnDetach() {
    super.ngOnDetach();
    this.onAttached$.next(false);
  }

  back() : void {
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl);
    } else {
      this.router.navigateByUrl(this.routePathService.getDefaultUrl(true));
    }
  }

  createDirectConversation() {
    this.createMode$.next('direct');
  }

  createGroupConversation() {
    this.createMode$.next('group');
  }

  triggerMenuAnimationDone(event:TransitionEvent) {
    if (event.propertyName=='height') {
      if (this.createMenu$.value) {
        //console.log("ATTACH");
        this.createContainer.connect();
        this.createContainer.reset(true);
      } else if (!this.createMode$.value) {
        //console.log("DETACH");
        this.createContainer.disconnect();
      }
    }
  }

  triggerCreateContainerAnimationDone(event:TransitionEvent) {
    if (!this.createMode$.value && event.propertyName=='top') {
      //console.log("DETACH");
      this.createContainer.disconnect();
    }
  }

  closeCreateContainer() {
    this.createMode$.next(undefined);
    this.createContainer.disconnect();
  }

  setCurrentConversationId(conversationId: string) {
    this.chatService.setCurrentConversationId(conversationId);
  }

  conversationIdSelect(conversationId: string) {
    firstValueFrom(this.layoutService.navigation.state$)
      .then(state=> {
        if (!!state.open && state.mode==SidenavMode.OVER) {
          this.layoutService.navigation.close();
        }
      });
  }

  onResize(event:ResizeEvent): void {
    // we trigger the router outlet attach through onResize...
    if (event.currentSize.height>0 &&
        event.currentSize.width>0) {
      window.setTimeout(()=>{
        this.ngOnRouterOutletAttach(true);
      },200);
    }
  }

  protected triggeredOnRouterOutletAttach = false;
  ngOnRouterOutletAttach(internal=false) {
    //console.log("ngOnRouterOutletAttach.triggered",this.list?.virtualList?.attached,this.list?.virtualList,"vsize",this.list?.virtualList?.viewportSize);
    this.triggeredOnRouterOutletAttach = internal ? this.triggeredOnRouterOutletAttach : true;
    if (!this.onInitialized$.value) {
      firstValueFrom(this.onInitialized$.pipe(takeUntil(this.onDestroy$),filter(value=>!!value)))
        .then(()=>this.ngOnRouterOutletAttach(true));
    } else if (!this.list.virtualList.attached) {
      firstValueFrom(this.list.virtualList.onAttached.pipe(takeUntil(this.onDestroy$), filter(value => !!value)))
        .then(() => this.ngOnRouterOutletAttach(true));
    } else if (this.list.virtualList.viewportSize>0 &&
               this.triggeredOnRouterOutletAttach) {
      this.triggeredOnRouterOutletAttach = false;
      this.updateCurrentConversationId();
      //console.log("ngOnRouterOutletAttach.attached",this.list.virtualList.attached,this.onAttached$.value,"vsize",this.list.virtualList.viewportSize,"index",this.previousConversationIndex);
      if (this.list.virtualList.attached &&
        this.list.virtualList.viewportSize>0 &&
        this.list.virtualList.isValidIndex(this.previousConversationIndex) &&
        !this.list.virtualList.isVisibleIndex(this.previousConversationIndex)) {
        this.list.virtualList.scrollToIndex(this.previousConversationIndex,Alignment.inside,0);
      }
    }
  }
}
