import {ComponentFactoryResolver, Injector, NgModule} from '@angular/core';
import {AgmCoreModule, GoogleMapsAPIWrapper, LazyMapsAPILoader, MapsAPILoader} from "@agm/core";
import {MapComponent} from './components/map-component/map.component';
import {MapMarkerComponent} from './components/map-marker-component/map-marker.component';
import {SharedModule} from "shared";
import {CommonModule} from "@angular/common";
// import { AgmMarkerClustererModule } from '@agm/markerclusterer';

const MapsCoreModule = AgmCoreModule.forRoot({
  //apiKey: 'AIzaSyA_4GKIcDhb_kvoAm9KnYNYg3MoHcRlPY8'
  //apiKey: 'AIzaSyDGSn4b6teaUQHoOG1oy8j28RKfkBMdzgs', //'AIzaSyCcr1rwCsd9_rfagJnaXXEiAK5BG5FrTEU' // defined in office@groupmanager.info google developer account project realizer
  apiKey: 'AIzaSyCDGjv4Yfi3XeEbLXODr1pMFhpoGhAEsnY', // mbratoev@gmail.com project realizer
  // see: https://developers.google.com/maps/documentation/javascript/libraries?hl=de
  //      https://angular-maps.com/api-docs/agm-core/interfaces/lazymapsapiloaderconfigliteral#libraries
  libraries: ['visualization','places', 'drawing', 'geometry']
});

export const COMPONENTS = [
  MapComponent,
  MapMarkerComponent
];


export const PROVIDERS = [
  MapsCoreModule.providers,
  GoogleMapsAPIWrapper
];

@NgModule({
  imports: [
    CommonModule,
    // AgmCoreModule.forRoot({
    //   //apiKey: 'AIzaSyA_4GKIcDhb_kvoAm9KnYNYg3MoHcRlPY8'
    //   //apiKey: 'AIzaSyDGSn4b6teaUQHoOG1oy8j28RKfkBMdzgs', //'AIzaSyCcr1rwCsd9_rfagJnaXXEiAK5BG5FrTEU' // defined in office@groupmanager.info google developer account project realizer
    //   apiKey: 'AIzaSyCDGjv4Yfi3XeEbLXODr1pMFhpoGhAEsnY', // mbratoev@gmail.com project realizer
    //   // see: https://developers.google.com/maps/documentation/javascript/libraries?hl=de
    //   //      https://angular-maps.com/api-docs/agm-core/interfaces/lazymapsapiloaderconfigliteral#libraries
    //   libraries: ['visualization','places', 'drawing', 'geometry']
    // }),
    MapsCoreModule,
    // AgmMarkerClustererModule
    SharedModule, // for 'Modified' directive
  ],
  declarations: [ COMPONENTS ],
  providers   : [ PROVIDERS ],
  exports     : [ AgmCoreModule /*, AgmMarkerClustererModule*/, COMPONENTS ],
})
export class MapsModule {}
