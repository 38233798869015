import {createReducer, on} from "@ngrx/store";
import {
  channelOpenStateAction,
  conferenceHangUpMessageAction,
  conferenceInviteMessageSendAction,
  conferenceMediaMessageAction,
  conferencePauseMessageAction,
  conferencePickUpMessageAction,
  conferenceStateMessageReceiveAction,
  conferenceSynchronizeStatesAction,
  conferenceTouchAction,
} from "./actions";
import {ConferencesState, initialConferencesState} from "./state";
import {ConferenceInfo, ConferenceParticipant, ConferenceVersion} from "./models";
import {logAction} from "shared";
import isEqual from "lodash/isEqual";
import {LogMessage, LogMessageType} from "core";
import {MessagingService} from "messaging";

export const reducer = createReducer(
  initialConferencesState,
  // ALL CONVERSATIONS
  on(channelOpenStateAction,(state, action) => {
    return logConferenceAction("channelOpenStateAction",false,state,action,()=>{
      return {
        ...state,
        online:action.open,
        connectionId:action.connectionId
      }
    });
  }),
  on(conferenceSynchronizeStatesAction,(state, action) => {
    return logConferenceAction("conferenceSynchronizeStatesAction",false,state,action,()=>{
      if (action.message.from?.id) {
        action.message.conferences = Object.keys(state.allConferences)
          .map(conferenceId=><ConferenceVersion>{conferenceId: conferenceId,version:state.allConferences[conferenceId]?.version});
      }
      return state;
    });
  }),
  on(conferenceStateMessageReceiveAction,(state, action) => {
    return logConferenceAction("conferenceStateMessageReceiveAction",true,state,action,()=>{
      if (!!action?.message?.conferenceId) {
        let current:ConferenceInfo = {
          timeCreated: action.message.timeCreated,
          conferenceType: action.message.conferenceType,
          conferenceId: action.message.conferenceId,
          version: action.message.version,
          conversationId: action.message.conversationId,
          conversationName: action.message.conversationName,
          muted: !!action.message.muted||false,
          server: !!action.message.server,
          ringing: action.message.ringing||[],
          pickedUp: action.message.pickedUp||[],
          paused: action.message.paused||[],
          participant: undefined,
          active: false
        };
        let ringing  = !!(current.participant = current.ringing.find(p=>p.id==action.userId));
        let pickedUp = !ringing && !!(current.participant = current.pickedUp.find(p=>p.id==action.userId && p.connectionId==state.connectionId));
        let paused   = !ringing && !pickedUp && !!(current.participant = current.paused.find(p=>p.id==action.userId && p.connectionId==state.connectionId));
        current.active = pickedUp;
        let previous = state.allConferences[current.conferenceId];
        state = {
          ...state,
          allConferences: {...state.allConferences}
        };
        state.allConferences[current.conferenceId] = current;
        if (!!current.active) {
          state.activeConference = current;
        } else if (state.activeConference===previous) {
          state.activeConference = undefined;
        }
        //console.log("REDUCER.conferenceStateMessageReceiveAction",current);
      }
      return state;
    });
  }),
  on(conferenceInviteMessageSendAction,(state, action) => {
    return logConferenceAction("conferenceInviteMessageSendAction",true,state,action,()=>{
      if (!state.activeConference && !!action.message.conferenceId) {
        let from = {...action.message.from};
        state = {
          ...state,
          activeConference: {
            timeCreated: action.message.timeCreated,
            conferenceType: action.message.conferenceType,
            conferenceId: action.message.conferenceId,
            version: 0,
            conversationId: action.message.conversationId,
            ringing: action.message.invited.map(p => {
              //p.name = state.avatarNames[p.id]?.name;
              return p;
            }),
            pickedUp: [from],
            paused: [],
            participant: from,
            server: !!action.message.server,
            active: true
          },
          allConferences: {
            ...state.allConferences,
          }
        };
        state.allConferences[state.activeConference.conferenceId] = action.conference = state.activeConference;
      } else if (!!state.activeConference &&
        action.message.conferenceId==state.activeConference.conferenceId) {
        action.conference   = state.activeConference;
        action.message.from = state.activeConference.participant;
        action.message.invited.forEach(p=>{
          p.type = 'conference';
          p.conferenceType = action.conference.conferenceType;
          p.timeSince = Date.now();
        });
      }
      return state;
    });
  }),
  on(conferencePickUpMessageAction,(state, action) => {
    return logConferenceAction("conferencePickUpMessageAction",true,state,action,()=>{
      if (!!action?.message?.conferenceId && action.message.from?.id) {
        let previous = state.allConferences[action.message.conferenceId];
        let participantId = action.message.from.id;
        if (!!previous) {
          action.message.conversationId = previous.conversationId;
          state = {
            ...state,
            allConferences: {...state.allConferences}
          };
          let current:ConferenceInfo = {
            ...previous,
            paused: previous.paused.filter(p=>p.id!=participantId),
            pickedUp: previous.pickedUp.filter(p=>p.id!=participantId),
            ringing: previous.ringing.filter(p=>p.id!=participantId)
          };
          current.pickedUp.push(action.message.from);
          if (action.userId==participantId) {
            if (action.message.from.connectionId==state.connectionId) {
              current.participant = action.message.from;
              current.active = true;
            } else {
              current.participant = undefined;
              current.active = false;
            }
          }
          state.allConferences[current.conferenceId] = action.conference = current;
          if (!!current.active) {
            state.activeConference = current;
          } else if (state.activeConference===previous) {
            state.activeConference = undefined;
          }
          //console.log("REDUCER.conferencePickUpMessageAction",current);
        }
      }
      return state;
    });
  }),
  on(conferenceMediaMessageAction,(state, action) => {
    return logConferenceAction("conferenceMediaMessageAction",true,state,action,()=>{
      if (!!action?.message?.conferenceId && action.message.from?.id) {
        let previous = state.allConferences[action.message.conferenceId];
        let participantId = action.message.from.id;
        if (!!previous) {
          let previousRingingIndex  = previous.ringing.findIndex(p=>p.id==participantId);
          let previousPickedUpIndex = previousRingingIndex<0 ? previous.pickedUp.findIndex(p=>p.id==participantId) : -1;
          let previousPausedIndex   = previousRingingIndex<0 && previousPickedUpIndex<0 ? previous.paused.findIndex(p=>p.id==participantId) : -1;
          let previousParticipant   = previousRingingIndex>=0  ? previous.ringing[previousRingingIndex] :
                                      previousPickedUpIndex>=0 ? previous.pickedUp[previousPickedUpIndex] :
                                      previousPausedIndex>=0   ? previous.paused[previousPausedIndex] : undefined;
          if (!!previousParticipant && (previousParticipant.muted!=(!!action.message.from.muted) ||
                                        previousParticipant.audio!=(!!action.message.from.audio) ||
                                        previousParticipant.video!=(!!action.message.from.video) ||
                                        previousParticipant.screen!=(!!action.message.from.screen) ||
                                       !isEqual(previousParticipant.tracks,action.message.from.tracks))) {
            //console.log("conferenceMediaMessageAction.UPDATE",previousParticipant,action.message.from,"ringing",previousRingingIndex,"pickedUp",previousPickedUpIndex,"paused",previousPausedIndex);
            state = {
              ...state,
              allConferences: {...state.allConferences}
            };
            let updated:ConferenceParticipant = undefined;
            let update = (array:ConferenceParticipant[],index:number)=>{
              if (index>=0) {
                array = [...array];
                array[index] = updated = {...array[index]};
                updated.muted       = !!action.message.from.muted;
                updated.audio       = !!action.message.from.audio;
                updated.video       = !!action.message.from.video;
                updated.screen      = !!action.message.from.screen;
                updated.tracks      = !!action.message.from.tracks ? {...action.message.from.tracks} : undefined;
              }
              return array;
            };
            let current:ConferenceInfo = {
              ...previous,
              paused: update(previous.paused,previousRingingIndex),
              pickedUp: update(previous.pickedUp,previousPickedUpIndex),
              ringing: update(previous.ringing,previousPausedIndex)
            };
            if (current.participant?.id==updated?.id) {
              current.participant = updated;
            }
            state.allConferences[current.conferenceId] = action.conference = current;
            if (!!current.active) {
              state.activeConference = current;
            } else if (state.activeConference===previous) {
              state.activeConference = undefined;
            }
          } else {
            action.conference = previous;
          }
          action.message.conversationId = previous.conversationId;
        }
      }
      return state;
    });
  }),
  on(conferenceTouchAction,(state, action) => {
    return logConferenceAction("conferenceTouchAction",false,state,action,()=>{
      if (!!action?.conferenceId) {
        let previous = state.allConferences[action.conferenceId];
        if (!!previous) {
          state = {
            ...state,
            allConferences:{...state.allConferences}
          };
          let current:ConferenceInfo = {
            ...previous
          };
          state.allConferences[current.conferenceId] = current;
          if (!!current.active) {
            state.activeConference = current;
          } else if (state.activeConference===previous) {
            state.activeConference = undefined;
          }
        }
      }
      return state;
    });
  }),
  on(conferencePauseMessageAction,(state, action) => {
    return logConferenceAction("conferencePauseMessageAction",true,state,action,()=>{
      if (!!action?.message?.conferenceId && action.message.from?.id) {
        let previous = state.allConferences[action.message.conferenceId];
        let participantId = action.message.from.id;
        if (!!previous) {
          action.message.conversationId = previous.conversationId;
          state = {
            ...state,
            allConferences: {...state.allConferences}
          };
          let current:ConferenceInfo = {
            ...previous,
            paused: previous.paused.filter(p=>p.id!=participantId),
            pickedUp: previous.pickedUp.filter(p=>p.id!=participantId),
            ringing: previous.ringing.filter(p=>p.id!=participantId)
          };
          current.paused.push(action.message.from);
          if (action.userId==participantId) {
            current.participant = action.message.from;
            current.active = false;
          }
          state.allConferences[current.conferenceId] = action.conference = current;
          if ((action.userId==participantId && action.message.from.connectionId==state.connectionId) &&
               state.activeConference===previous) {
            state.activeConference = undefined;
          }
          //console.log("REDUCER.conferencePauseMessageAction",current);
        }
      }
      return state;
    });
  }),
  on(conferenceHangUpMessageAction,(state, action) => {
    return logConferenceAction("conferenceHangUpMessageAction",true,state,action,()=>{
      if (!!action?.message?.conferenceId && action.message.from?.id) {
        let previous = state.allConferences[action.message.conferenceId];
        let participantId = action.message.from.id;
        if (!!previous) {
          action.send = !!previous.ringing.find(p=>p.id==participantId) ||
                        !!previous.pickedUp.find(p=>p.id==participantId && p.connectionId==state.connectionId) ||
                        !!previous.paused.find(p=>p.id==participantId && p.connectionId==state.connectionId);
          action.message.conversationId = previous.conversationId;
          //console.log("hangup",previous,"send",action.send,"connectionId",state.connectionId);
          state = {
            ...state,
            allConferences: {...state.allConferences}
          };
          if (participantId==action.userId) {
            delete state.allConferences[previous.conferenceId];
            if (state.activeConference===previous) {
              state.activeConference = undefined;
            }
            action.conference = previous;
          } else {
            let current:ConferenceInfo = {
              ...previous,
              paused: previous.paused.filter(p=>p.id!=participantId),
              pickedUp: previous.pickedUp.filter(p=>p.id!=participantId),
              ringing: previous.ringing.filter(p=>p.id!=participantId)
            };
            action.conference = current;
            if ((current.paused.length+current.pickedUp.length+current.ringing.length)<=1) {
              delete state.allConferences[previous.conferenceId];
              current.active = false;
            } else {
              state.allConferences[current.conferenceId] = current;
            }
            if (!!current.active) {
              state.activeConference = current;
            } else if (state.activeConference===previous) {
              state.activeConference = undefined;
            }
          }
        }
      }
      return state;
    });
  })
);

export function logConferenceAction<A>(name:string,log:boolean,state:ConferencesState,action:A,reducer:()=>ConferencesState):ConferencesState {
  const result = logAction(name, false, state, action, reducer);
  if (log) {
    const messagingService:MessagingService = (<any>window).messagingService;
    const user = (<any>window).user;
    if (!!messagingService) {
      let object:any = {
        system: 'conference',
        action: name,
        online: result.online,
        connectionId: state.connectionId,
        participant: {
          id: user.id,
          name: user.name
        }
      };
      if (result!==state) {
        object.before = state.allConferences;
      }
      object.after = result.allConferences;
      messagingService.sendMessage(messagingService.initializeMessage(<LogMessage>{
        type: LogMessageType,
        object
      }));
    }
  }
  return result;
}
