import {Component} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, takeUntil} from 'rxjs';
import {ContactDetailSectionComponent} from '../contact-detail-section/contact-detail-section.component';
import {Contact, Topic} from "core";
import {ContactDetailDataShareService} from "../contact-detail-data-share.service";

@Component({
  selector: 'contact-detail-survey',
  templateUrl: './contact-detail-survey.component.html',
  styleUrls: ['./contact-detail-survey.component.scss']
})
export class ContactDetailSurveyComponent extends ContactDetailSectionComponent {

  contact$: Observable<Contact>;
  badgeData$ = new BehaviorSubject<string>(undefined);
  survey: Topic;
  selectedTags: string[] = [];
  onHelp$: Observable<void>;
  displayRoot = true;

  protected _contact: Contact = undefined;

  constructor(
    private dataShareService: ContactDetailDataShareService) {
    super();
    this.contact$ = this.dataShareService.getContact$;
    this.contact$?.pipe(takeUntil(this.onDestroy$)).subscribe(contact=>this.contact=contact);
  }

  set contact(contact:Contact) {
    this._contact = contact;
    this.updateSurvey(this.survey);
  }

  get contact():Contact {
    return this._contact;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.survey = this.context?.survey;
    if (this.survey) {
      this.displayRoot = this.context.displayRoot;
      this.onHelp$ = this.context.onHelp$;
      if (this.context.selectedTags$) {
        this.context.selectedTags$
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(selectedTags => {
            /*if (this.contact.name=='Tobias Merkinger') {
              console.log("SURVEY.TOBIAS",this.contact,"survey",this.survey,"selectedTags",selectedTags);
              const prefix = `${this.survey.id}.`;
              const except = `${this.survey.id}.survey`;
              const tags   = selectedTags.filter(tag=>!tag.startsWith(prefix) || tag==except);
              console.log("SURVEY.TOBIAS",this.contact,"survey",this.survey,"selectedTags",selectedTags,"tags",tags);
              selectedTags = tags;
            }*/
            this.selectedTags = selectedTags;
          });
      }
    } else {
      this.onHelp$ = EMPTY;
      this.selectedTags = [];
      this.displayRoot = true;
    }
    this.updateSurvey(this.survey);
    this.isHidden.emit(!this.survey);
  }

  protected updateSurvey(survey:Topic) {
    if (!!survey && !!this.contact) {
      const surveyBadgePrefix = `badge.${survey.id}.`;
      let badgeData = this.contact?.badgeData?.find(data=>data.startsWith(surveyBadgePrefix));
      /*if (this.contact.name=='Tobias Merkinger') {
        badgeData = survey.id == 'vitality'
          ? `${surveyBadgePrefix}5.4.0`
          : `${surveyBadgePrefix}100.30.20.10`;
      }*/
      if (this.badgeData$.value!=badgeData) {
        this.badgeData$.next(badgeData);
      }
    }
  }
}
