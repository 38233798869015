import {ConferenceInfo} from "./models";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const conferenceFeatureKey = 'conference';

export interface Error {
  label?: string;
  params: {[key:string]: any };
}

export interface ConferencesState {
  online : boolean;
  connectionId: string;
  activeConference?: ConferenceInfo;
  allConferences: { [key:string]: ConferenceInfo }; // -1: active, 0...n: pending, undefined
  error: Error;   // error message in current locale
}

export const initialConferencesState : ConferencesState = {
  online: false,
  connectionId: undefined,
  allConferences: {},
  error: {
    params: {}
  }
};

// do not export - leads to naming conflicts with other feature State interfaces
export interface State {
  readonly [conferenceFeatureKey] : ConferencesState;
}

export const selectChatState = createFeatureSelector<State,ConferencesState>(conferenceFeatureKey);

export const selectOnlineState = createSelector(
  selectChatState,
  state => state.online
);

export const selectAvatarName = (id: string) => createSelector(
  selectChatState,
  // TODO: avatarNames is not in ConferencesState
  (state: ConferencesState):string => (state as any).avatarNames[id]?.name || ''
);

export const selectAllConferences = createSelector(
  selectChatState,
  state => state.allConferences
);

export const selectAllConferencesArray = createSelector(
  selectAllConferences,
  conferences => Object.keys(conferences).map(conferenceId=>conferences[conferenceId]).sort(
    (a,b) =>
      a.active ? -1 : b.active ? 1 :
        a.timeCreated<b.timeCreated ? -1 : b.timeCreated<a.timeCreated ? 1 :
          a.conferenceId.localeCompare(b.conferenceId))
);

export const selectConference = (conferenceId: string) => createSelector(
  selectAllConferences,
  (state): ConferenceInfo => state[conferenceId]
);

export const selectActiveConference = createSelector(
  selectChatState,
  state => state.activeConference
);
