import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PresenceService} from "../../presence.service";
import {PresenceComponent} from "./presence.component";
import {PresenceState} from "../../store/models";
import {Presence} from "core";
import {BehaviorSubject, Observable, of, Subscription, timer} from "rxjs";
import {map, switchMap} from "rxjs/operators";

@Component({
  selector: 'presence-info',
  template: `
    <ng-container *ngIf="(info$ | async) as info">
      <span>{{info.label | translate:info}}</span>
    </ng-container>
  `,
  styles: [`
    :host {
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresenceInfoComponent extends PresenceComponent {

  info$ = new BehaviorSubject<{label:string,units?:number}>(undefined);
  presenceSubscription:Subscription;

  constructor(public presenceService: PresenceService) {
    super(presenceService);
    this.presenceSubscription = this.presence$.pipe(
      switchMap(presence => this.presenceToInfo$(presence))
    ).subscribe(info => {
      this.info$.next(info);
    });
  }

  ngOnDestroy() {
    this.presenceSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  presenceToInfo$(presence:Presence):Observable<{label:string,units?:number}> {
    if (!!presence?.state && presence.timeActive>0) {
      if (presence.state==PresenceState.loggedIn) {
        let sinceMinutes = ():number => Math.floor((Date.now()-presence.timeActive)/60000);
        return timer(0,5_000).pipe(
          map(timer => {
            let minutes = sinceMinutes();
            return minutes<2  ? { label: 'chat.presence.online.now' } :
              minutes<60 ? { label: 'chat.presence.online.past.minutes', units:minutes } :
              (minutes/60)<2 ? { label: 'chat.presence.online.past.hour' } :
              (minutes/60)<24 ? { label: 'chat.presence.online.past.hours', units:Math.floor(minutes/60) } :
              (minutes/60)<48 ? { label: 'chat.presence.online.past.day' } :
              (minutes/(60*24))<14 ? { label: 'chat.presence.online.past.days', units:Math.floor(minutes/(60*24)) } :
              (minutes/(60*24))<60 ? { label: 'chat.presence.online.past.weeks', units:Math.floor(minutes/(60*24*7)) } :
              (minutes/(60*24*30))<24 ? { label: 'chat.presence.online.past.months', units:Math.floor(minutes/(60*24*30)) } :
              { label: 'chat.presence.online.past.years', units:Math.floor(minutes/(60*24*365)) };
          })
        );
      } else if (presence.state==PresenceState.unavailable) {
        return of({label:'chat.presence.unavailable'});
      } else if (presence.state==PresenceState.absent) {
        return of({label:'chat.presence.absent'});
      }
    }
    return of(undefined);
  }
}
