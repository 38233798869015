<div (onDrop)="onDrop($event)" [dropForwardingTarget]="conversationId">
  <ng-container *ngLet="{
    hasFeatures:hasFeatures$ | async,
    forcedFeatures:forcedFeatures$ | async,
    muted:muted$ | async
  } as context">
    <app-search-bar color="primary" #bar
                    [class.hasFeatures]="context.forcedFeatures || context.hasFeatures"
                    [(ngModel)]="searchTerm"
                    [searchButton]="false"
                    (onSearchTermChange)="updateSearchTerm($event)"
                    (onLeftClicked)="layoutService.navigation.toggle()"
                    (onRightClicked)="openDetails()"
                    [leftButton]="false"
                    [rightButtonTemplateRef]="rightButtonTemplate"
                    [extensionTemplateRef]="extensionTemplate">
      <div class="title-container" (click)="hasFeatures$.next(context.forcedFeatures || !context.hasFeatures)">
        <div class="title-and-status" [class.centered]="context.hasFeatures" *ngLet="chatService.getDirectContactId(conversationId) as contactId">
          <span>{{(conversationData$ | async)?.name | translate}}</span>
          <presence-info [contactId]="contactId"></presence-info>
        </div>
      </div>
      <ng-template #rightButtonTemplate>
        <mat-icon class="toggle" [class.close]="hasFeatures$ | async" (click)="hasFeatures$.next(context.forcedFeatures || !context.hasFeatures)">expand_more</mat-icon>
        <!--
        <button tabindex="-1" *ngIf="bar.rightIcon" mat-icon-button (click)="bar.emitRightClicked()">
          <mat-icon>{{bar.rightIcon}}</mat-icon>
        </button>
        -->
      </ng-template>
      <ng-template #extensionTemplate>
        <div class="features-container"
             (transitionend)="transitionDone($event)">
          <div #features class="features" (click)="hasFeatures$.next(context.forcedFeatures || !context.hasFeatures)">
            <chat-conversation-bar-container [conversationId]="conversationId"
                                             [muted]="context.muted"
                                             (onPendingConversationCalls)="onPendingConversationCalls($event)"
                                             (onConversationMuted)="switchMuted($event)"
                                             (onConversationDetails)="openDetails()"
                                             (click)="$event.stopPropagation()">
            </chat-conversation-bar-container>
          </div>
        </div>
      </ng-template>
    </app-search-bar>
  </ng-container>
  <div class="content">
    <chat-conversation [conversationId]="conversationId" [searchTerm]="searchTerm" #chatConversationComponent></chat-conversation>
  </div>
  <ng-container *ngIf="!readonly()">
    <chat-message-editor [conversationId]="conversationId" #chatMessageEditor></chat-message-editor>
    <div class="drop-container dropFilesOver">
      <div>
        <mat-icon>file_upload</mat-icon>
      </div>
    </div>
  </ng-container>
  <ng-container *ngLet="(conversationData$ | async)?.canSend===false as readonly">
  </ng-container>
</div>
