@charset "UTF-8";

/* projects/calendar/src/lib/components/event-detail/event-detail.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
}
:host > div:first-child {
  position: relative;
  flex: 1;
}
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.container button.mat-mdc-raised-button {
  margin: 10px 0 0;
}
.container button[matSuffix] mat-icon {
  height: 24px;
}
.container .type-section > .type {
  margin-bottom: 6px;
}
.container .type-section mat-form-field {
  width: 100%;
}
.container .type-section button.mat-mdc-outlined-button.mat-primary {
  background-color: var(--color-primary);
  color: white;
}
.container .type-section .location {
  display: flex;
  flex-direction: column;
}
.container .type-section .location mat-button-toggle.connector {
  align-self: center;
  position: relative;
  overflow: visible;
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
  border-color: #bdbdbd;
}
.container .type-section .location mat-button-toggle.connector:before,
.container .type-section .location mat-button-toggle.connector:after {
  content: "";
  display: block;
  position: absolute;
  left: 14px;
  height: 16px;
  border-left: 1px solid #bdbdbd;
}
.container .type-section .location mat-button-toggle.connector:before {
  top: -16px;
}
.container .type-section .location mat-button-toggle.connector:after {
  bottom: -16px;
}
.container .type-section .location mat-button-toggle.connector mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  opacity: 0.6;
}
.container .type-section .location mat-button-toggle.connector.open {
  background-color: #f44336;
  color: white;
}
.container .type-section .location mat-button-toggle.connector.open mat-icon {
  opacity: unset;
}
.container .type-section .location mat-button-toggle.connector ::ng-deep .mat-button-toggle-button > .mat-button-toggle-label-content {
  line-height: 18px;
  padding: 0;
}
.container media-card {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ddd;
  margin-bottom: 8px;
}
.container media-card button.mat-mdc-icon-button {
  margin-right: 5px;
  background-color: rgba(255, 255, 255, 0.7);
}
.container media-card button.mat-mdc-icon-button.delete {
  color: var(--color-accent);
}
.container media-card button.mat-mdc-icon-button.edit {
  color: var(--color-primary);
}
.container form.booking {
  margin-bottom: 12px;
}
.container .booking-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
}
.container .booking-control button {
  margin: 0 8px 0 0;
  flex: 1;
}
.container .booking-control button ::ng-deep mat-icon {
  margin-right: 4px;
}
.container .booking-control .seats {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container .booking-control .seats > div.value {
  display: inline-block;
  padding: 0 4px;
  box-sizing: content-box;
  min-width: 1em;
  text-align: center;
}
.container .booking-control .seats mat-icon {
  padding: 4px;
  width: 27px;
  height: 27px;
  font-size: 18px;
  color: #757575;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #eee;
}
.container .booking-control .seats mat-icon.press {
  background-color: var(--color-accent-300);
  color: var(--color-accentContrast);
}
.container .booking-control .seats mat-icon.disabled,
.container .booking-control .seats mat-icon.disabled.press {
  background-color: #eee;
  color: #bbb;
  cursor: default;
}
.container .booking-control .seats mat-icon ~ mat-divider {
  background-color: transparent;
  border-color: transparent;
}
.container .booking-control .seats mat-divider {
  display: block;
  position: absolute;
  left: 50%;
  top: 2px;
  bottom: 2px;
  z-index: 1;
  background-color: #bdbdbd;
}
.container .mat-form-field-label {
  position: relative;
  display: block;
  top: 0;
  font-size: 14px;
  margin-bottom: 10px;
}
.container.view {
  overflow-y: auto;
}
.container.view mat-checkbox {
  margin-bottom: 16px;
}
.container.view > div:first-child {
  padding-top: 16px;
}
.container.view > div.event-name {
  overflow-wrap: break-word;
  padding-bottom: 16px;
}
.container.view > more-text {
  font-size: 14px;
  padding-bottom: 16px;
}
.container.view > .location,
.container.view > .calendar,
.container.view > .language {
  padding-bottom: 16px;
}
.container.view > .joinLink a {
  margin-right: 8px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}
.container.view > .joinLink a:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.75);
}
.container.view > .location a {
  font-style: italic;
  margin-right: 8px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13.5px;
}
.container.view > .location a:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.75);
}
.container.view .label {
  font-size: 13px;
  opacity: 0.6;
}
.container.view > div.date-time {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.container.view > div.date-time div.same-day {
  display: flex;
  flex-direction: column;
}
.container.view > div.date-time div.same-day > div.date {
  display: flex;
  font-size: 14px;
  justify-content: center;
}
.container.view > div.date-time div.same-day > div.time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 140%;
}
.container.view > div.date-time div.same-day > div.time > div.separator {
  font-weight: normal;
  padding: 0 8px;
}
.container.view > div.date-time div.days {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container.view > div.date-time div.days > div {
  display: flex;
  flex-direction: column;
}
.container.view > div.date-time div.days > div > div.day {
  display: flex;
  justify-content: center;
  font-size: 14px;
}
.container.view > div.date-time div.days > div > div.date {
  display: flex;
  justify-content: center;
  font-size: 14px;
}
.container.view > div.date-time div.days > div > div.time {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.container.view > div.date-time div.days > div > div.time > div.separator {
  font-weight: normal;
  padding: 0 8px;
}
.container.view > div.date-time div.days > div.separator {
  font-weight: normal;
  padding: 0 8px;
}
.container.view div.accept-booking {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}
.container.view div.accept-booking > span {
  font-size: 13px;
  opacity: 0.6;
}
.container.view div.accept-booking button {
  margin-left: 16px;
}
.container.edit .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.container.edit .panel.selectors .selector {
  flex: 1;
}
.container.edit mat-form-field.calendar {
  margin-bottom: 10px;
  position: relative;
}
.container.edit .type-section {
  margin-bottom: 16px;
}
.container.edit .type-section > .type button:not(:last-of-type) {
  margin-right: 8px;
}
.container.edit .timezones {
  display: flex;
  flex-direction: column;
}
.container.edit .timezones mat-expansion-panel {
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.54);
  background-color: transparent;
}
.container.edit .timezones mat-expansion-panel mat-expansion-panel-header {
  font-size: 16px;
  padding-left: 16px;
  padding-right: 10px;
}
.container.edit .timezones mat-expansion-panel mat-expansion-panel-header mat-panel-title {
  flex: 0;
  color: rgba(0, 0, 0, 0.54);
}
.container.edit .timezones mat-expansion-panel mat-expansion-panel-header mat-panel-description {
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
}
.container.edit .timezones mat-expansion-panel ::ng-deep .mat-expansion-panel-body {
  padding: 0;
}
.container.edit .timezones mat-expansion-panel mat-list {
  padding: 0;
}
.container.edit .timezones mat-expansion-panel mat-list mat-list-item {
  padding: 0;
  height: auto;
  cursor: pointer;
}
.container.edit .timezones mat-expansion-panel mat-list mat-list-item.selected {
  background-color: var(--color-primary-300);
}
.container.edit .timezones mat-expansion-panel mat-list mat-list-item.selected .timezone {
  color: white;
}
.container.edit .timezones mat-expansion-panel mat-list mat-list-item .timezone {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
  height: auto;
  padding: 16px;
  font-size: 16px;
}
.container.edit .timezones mat-expansion-panel mat-list mat-list-item .timezone .name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container.edit .timezones mat-expansion-panel mat-list mat-list-item .timezone .mat-mdc-icon-button {
  align-self: flex-end;
  width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0;
}
.container.edit .timezones mat-expansion-panel mat-list mat-list-item .timezone .mat-mdc-icon-button .mat-icon {
  line-height: 18px;
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.container.edit .timezones mat-expansion-panel mat-list mat-list-item mat-divider {
  top: 0;
}
.container.edit .timezones mat-expansion-panel .timezone > .selection-indicator {
  position: relative;
  height: 1em;
}
.container.edit .timezones mat-expansion-panel .timezone > .selection-indicator:before {
  display: inline;
  content: "\2022";
  position: absolute;
  left: -11px;
  top: 0;
  font-size: 1.4em;
  line-height: 1rem;
}
.container.edit .timezones .action.timezone-select {
  align-self: flex-end;
  margin-right: 3px;
}
.container.edit .more {
  display: flex;
  flex-direction: column;
}
.container.edit .more mat-expansion-panel {
  background: transparent;
  flex-shrink: 0;
}
.container.edit .more mat-expansion-panel mat-expansion-panel-header {
  padding: 0;
  background: transparent !important;
}
.container.edit .more mat-expansion-panel mat-expansion-panel-header mat-panel-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container.edit .more mat-expansion-panel ::ng-deep .mat-expansion-panel-body {
  padding: 0;
  display: flex;
  flex-direction: column;
}
form {
  display: flex;
  flex-direction: column;
}
.map-container {
  margin: 8px 0 16px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 160px;
}
.map-container > map {
  height: 100%;
}
.countries {
  display: flex;
  flex-direction: column;
}
.countries > mat-checkbox:first-of-type {
  margin-bottom: 10px;
}
.section:not(:last-child) {
  margin-bottom: 20px;
}
mat-form-field.hidden {
  visibility: hidden;
  height: 0;
}
mat-form-field.target ::ng-deep .mat-mdc-text-field-wrapper {
  overflow: visible;
}
mat-form-field.selector ::ng-deep .mat-mdc-form-field-infix:before {
  content: "\a0";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: all;
  cursor: pointer;
}
mat-form-field.selector ::ng-deep .mat-mdc-form-field-infix label,
mat-form-field.selector ::ng-deep .mat-mdc-form-field-infix input:disabled {
  pointer-events: none;
}
.header {
  display: block;
  margin: 0 0 10px;
  padding: 0;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.54);
}
a.action {
  font-size: 12px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
a.action:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: underline;
}
:host-context(.color-scheme-dark) .header {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) a.action {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) a.action:hover {
  color: rgba(174, 175, 176, 0.8);
}
:host-context(.color-scheme-dark) .container.edit .timezones mat-expansion-panel {
  border-color: rgba(174, 175, 176, 0.8);
}
:host-context(.color-scheme-dark) .container.edit .timezones mat-expansion-panel mat-expansion-panel-header mat-panel-title {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .container.edit .timezones mat-expansion-panel mat-expansion-panel-header ::ng-deep .mat-expansion-indicator:after {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .container.edit .timezones mat-expansion-panel mat-expansion-panel-header mat-panel-description {
  color: white;
}
:host-context(.color-scheme-dark) .container.edit .timezones mat-expansion-panel mat-list mat-list-item div.timezone {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .container.edit .timezones mat-expansion-panel mat-list mat-list-item.selected {
  background-color: rgba(174, 175, 176, 0.2);
}
:host-context(.color-scheme-dark) .container.view > .joinLink a {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .container.view > .joinLink a:hover {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .container.view > .location a {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .container.view > .location a:hover {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .more mat-expansion-panel mat-expansion-panel-header mat-panel-title {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .more mat-expansion-panel mat-expansion-panel-header mat-panel-title .mat-expansion-indicator:after {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .booking-control .seats {
  border-color: #bdbdbd;
}
:host-context(.color-scheme-dark) .booking-control .seats mat-icon {
  background-color: #777;
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .booking-control .seats mat-icon.press {
  color: var(--color-primary);
}
:host-context(.color-scheme-dark) .booking-control .seats mat-icon.disabled {
  color: #777;
  background-color: #444;
}
/*# sourceMappingURL=event-detail.component-GBDOZPTV.css.map */
