@charset "UTF-8";

/* projects/tasks/src/lib/components/tasklist-detail/tasklist-detail.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: column;
}
.view,
.edit {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.view button.mat-raised-button,
.edit button.mat-raised-button {
  margin: 10px 0 0;
}
.view button[matSuffix] mat-icon,
.edit button[matSuffix] mat-icon {
  height: 24px;
}
.view.edit button:last-of-type,
.edit.edit button:last-of-type {
  margin-top: 30px !important;
}
.view mat-checkbox,
.view tri-state-checkbox {
  margin-bottom: 16px;
}
.view button.mat-mini-fab {
  align-self: flex-end;
  margin-top: -10px;
}
.section:not(:last-child) {
  margin-bottom: 20px;
}
mat-form-field.selector ::ng-deep .mat-mdc-form-field-infix:before {
  content: "\a0";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: all;
  cursor: pointer;
}
mat-form-field.selector ::ng-deep .mat-mdc-form-field-infix input {
  caret-color: transparent;
}
mat-form-field.hidden {
  visibility: hidden;
  height: 0;
}
mat-form-field .colorPrefix {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
}
.header {
  display: block;
  margin: 0 0 10px;
  padding: 0;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.54);
}
:host-context(.color-scheme-dark) .header {
  color: #AEAFB0;
}
/*# sourceMappingURL=tasklist-detail.component-QTUYIH6O.css.map */
