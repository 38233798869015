import {createReducer, on} from "@ngrx/store";
import {
  addQueueMessagesAction,
  attachmentPayloadResultAction,
  channelOpenStateAction,
  conversationFilterAction,
  conversationMuteAction,
  conversationsLoadRequestAction,
  conversationsLoadResultAction,
  conversationSynchronizeSegmentAction,
  conversationUpdateSegmentAction,
  receiveChatTimelineMessageAction,
  receiveDeliveryMessageAction,
  removeQueueMessagesAction,
  setCurrentConversationIdAction,
  setDraftMessageAction,
  setFilteredMessageAction,
  setParticipantAction,
  setReplyMessageAction,
  setTypingMessageAction,
  testAction,
  triggerTimeViewedAction
} from "./actions";
import {
  CHAT_DEFAULT_CHUNK_SIZE,
  CHAT_MAX_CHUNK_SIZE,
  ChatState,
  ConversationState,
  initialChatState,
  initialConversationState,
  mainConversationTypes
} from "./state";
import {createFilterId, createIndexedArrayProxy, IndexedArrayHooks} from "core";
import {
  AudioRecordingAttachment,
  AudioRecordingAttachmentType,
  ChatParticipationsChangeMessageType,
  ChatParticipationsMessage,
  ChatParticipationsRemoveMessageType,
  ChatTimelineMessage,
  ChatTimelineMessageInfo,
  ConversationData,
  ConversationHeader,
  ConversationSegment,
  CreateMessageType,
  MessageTimeUpdated
} from "./models";
import sortedIndexBy from "lodash/sortedIndexBy";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import findLast from "lodash/findLast";
import {isValidNumber, logAction} from "shared";

export function createCoreConversationHooks() : IndexedArrayHooks<ConversationData> {
  return {
    getId:(info) => info?.id,
    isValue:(value) => !!value,
    getValue:(array,index,value) => value,
    onSet:(array, index, value) => {},
    onSpliced:(array, removedAtIndex, removed, addedAtIndex, added) => {}
  };
}

export function readableSegments(segments:ConversationSegment[]):{newest:string,oldest:string,size:number,checksum:number}[] {
  const result:{newest:string,oldest:string,size:number,checksum:number}[] = [];
  segments?.forEach(segment=>{
    result.push({
      newest: new Date(segment.newestMessageInfo.timeCreated).toLocaleString(),
      oldest: new Date(segment.oldestMessageInfo.timeCreated).toLocaleString(),
      size: segment.size,
      checksum: segment.checksum
    })
  });
  return result;
}

export function readableMessages(messages:ChatTimelineMessage[]):{date:string,id:string}[] {
  const result:{date:string,id:string}[] = [];
  messages?.forEach(message=>{
    result.push({
      date:new Date(message.timeCreated).toLocaleString(),
      id: message.id
    })
  });
  return result;
}

export function prepareMessageInfo(info:Partial<ChatTimelineMessageInfo>,message:ChatTimelineMessage):ChatTimelineMessageInfo {
  info.id          = message.id;
  info.timeCreated = message.timeCreated;
  info.timeUpdated = message.timeUpdated;
  return <ChatTimelineMessageInfo>info;
}

export function prepareSegments(conversationState:ConversationState,
    messages:ChatTimelineMessage[],indices:{[key:string]:number},
    segments:ConversationSegment[],updatedIndex:number,updatedSize:number) : ConversationSegment[] {
  const totalSize = messages?.length ?? 0;
  if (segments?.length>0 && totalSize>0) {
    let segmentsUpdated = false;
    const log = false && conversationState?.conversationData?.id == "direct.1-Q8RUGPMZZNEZM2VZ7SHWYRLYL.1679741802076687";
    updatedIndex = updatedIndex==undefined ? messages.length : updatedIndex;
    updatedSize  = Math.min(Math.max(0,updatedSize),messages.length-updatedIndex);
    if (log) console.log("prepareSegments.start",conversationState.conversationData?.id,"\nmessages",messages?.length,readableMessages(messages),"\nsegments",[...segments],readableSegments(segments),"\nupdatedIndex",updatedIndex,"updatedSize",updatedSize);
    const sealSegment = (segment:Partial<ConversationSegment>,newestIndex:number,oldestIndex:number):ConversationSegment => {
      segment.newestMessageInfo = prepareMessageInfo(segment.newestMessageInfo??{},messages[newestIndex]);
      segment.oldestMessageInfo = prepareMessageInfo(segment.oldestMessageInfo??{},messages[oldestIndex]);
      segment.size     = newestIndex-oldestIndex+1;
      segment.version  = 0;
      segment.checksum = 0;
      for (let i=oldestIndex; i<=newestIndex; i++) {
        let message  =  messages[i];
        let version  =  message.timeUpdated || message.timeCreated;
        let checksum = (message.timeCreated & 0x0ffff);
        if (message.timeUpdated) {
          checksum += ((message.timeUpdated & 0x0ffff)<<3);
        }
        segment.version   = Math.max(segment.version,version);
        segment.checksum += checksum;
      }
      if (log) console.log("prepareSegments.sealed",segment);
      return <ConversationSegment>segment;
    }
    // verify all loaded segments and adjust them.
    // only server provides the segments - the client only adapts them on new messages or
    // segments loaded!
    const verifySegmentsDown = (segmentIndex:number,remainingMessages:number):void => {
      let segment = segments[segmentIndex];
      if (segment.loaded) {   // we only handle loaded segments!!!
        let updated     = false;
        let oldestIndex = indices[segment.oldestMessageInfo?.id];
        let newestIndex = indices[segment.newestMessageInfo?.id];
        if (log) console.log("prepareSegments.verify",segmentIndex,"oldestIndex",oldestIndex,"newestIndex",newestIndex,"remaining",remainingMessages);
        if (!isValidNumber(newestIndex) || newestIndex<(remainingMessages-1)) {
          updated = true;
          newestIndex = remainingMessages-1;
          if (log) console.log("prepareSegments.newestIndex.segment",segmentIndex);
        }
        if (!isValidNumber(oldestIndex) || oldestIndex>newestIndex) {
          updated = true;
          oldestIndex = newestIndex;
          const oldestTime = segment.oldestMessageInfo.timeCreated;
          if (oldestTime>messages[newestIndex].timeCreated) {
            const copy = [...segments].map(s=><any>{...s});
            segments.splice(segmentIndex,1);
            if (log) console.log("prepareSegments.delete.segment",segmentIndex,"\n s1",copy,"\n s2",segments);
            verifySegmentsDown(segmentIndex-1, remainingMessages);
            return;
          } else {
            for (let i=newestIndex; i>=0 && messages[i].timeCreated>=oldestTime; i--) {
              oldestIndex = i;
            }
            if (log) console.log("prepareSegments.oldestIndex.segment",segmentIndex);
          }
        }
        if (log) console.log("prepareSegments.condition.segment",segmentIndex,"updated",updated,
          "diff",(segment.size != (newestIndex-oldestIndex+1)),"updated",updatedIndex,(updatedIndex + updatedSize),(updatedIndex <= newestIndex &&
            (updatedIndex + updatedSize) > oldestIndex),"oldestIndex",oldestIndex,"newestIndex",newestIndex);
        if (updated || segment.size != (newestIndex-oldestIndex+1) ||
           (updatedIndex <= newestIndex &&
           (updatedIndex + updatedSize) > oldestIndex)) {
          if (!segmentsUpdated) {
            segmentsUpdated = true;
            segments = [...segments];
          }
          segments[segmentIndex] = segment = {...segment};
          sealSegment(segment, newestIndex, oldestIndex);
        }
        if (segmentIndex > 0) {
          verifySegmentsDown(segmentIndex-1, oldestIndex);
        }
      } else {
        if (log) console.log("prepareSegments.unchecked.segment",segmentIndex);
      }
    }
    verifySegmentsDown(segments.length-1,totalSize);
    const segment  = segments[segments.length-1];
    if (segment.loaded &&
        segment.size>CHAT_MAX_CHUNK_SIZE) {
      const hotSize  = segment.size-CHAT_DEFAULT_CHUNK_SIZE;
      const coldSize = segment.size-hotSize;
      if (log) console.log("prepareSegments.split.hot",hotSize,"cold",coldSize);
      segments.push(sealSegment({loaded:true},totalSize-1,totalSize-hotSize))
      sealSegment(segment,totalSize-1-hotSize,totalSize-hotSize-coldSize);
    }
    if (log) console.log("prepareSegments.done\nsegments",[...segments],readableSegments(segments));
  }
  return segments;
}

export function updateAttachmentPayload(newMessage:ChatTimelineMessage,oldMessage:ChatTimelineMessage):ChatTimelineMessage {
  if (oldMessage?.attachments?.length==1 &&
      oldMessage.attachments[0].type==AudioRecordingAttachmentType &&
      newMessage?.attachments?.length==1 &&
      newMessage.attachments[0].type==AudioRecordingAttachmentType) {
    const oldAttachment = <AudioRecordingAttachment>oldMessage.attachments[0];
    const newAttachment = <AudioRecordingAttachment>newMessage.attachments[0];
    if (!!oldAttachment.data && !newAttachment.data) {
      newAttachment.data = oldAttachment.data;
    }
  }
  return newMessage;
}

export function needsAttachmentPayload(message:ChatTimelineMessage):boolean {
  return message?.attachments?.length==1 &&
         message.attachments[0].type==AudioRecordingAttachmentType &&
        !(<AudioRecordingAttachment>message.attachments[0]).data;
}

export function updateMainConversation(state:ChatState, conversationId:string, updateConversation:(conversation:ConversationData)=>ConversationData) : ChatState {
  let index = state.conversationsState?.indices[conversationId];
  if (isValidNumber(index) && (<any>state.conversationsState.entities).backingArray) {
    let sourceConversation  = state.conversationsState?.entities[index];
    let updatedConversation = updateConversation(sourceConversation);
    if (sourceConversation!==updatedConversation) {
      let backingArray   : ConversationData[]  = (<any>state.conversationsState.entities).backingArray;
      let backingIndices : {[key:string]: number} = (<any>state.conversationsState.entities).backingIndices;
      let backingHooks   : IndexedArrayHooks<ConversationData> = (<any>state.conversationsState.entities).backingHooks;
      state = {
        ...state,
        conversationsState: {
          ...state.conversationsState
        },
        conversationStates: {
          ...state.conversationStates
        }
      }
      //console.log("STATE.CONVERSATIONQUEUE.1",state.conversationsQueue);
      updatedConversation.timeSorted = Math.max(updatedConversation.draftMessage?.timeCreated||0, updatedConversation.lastMessage?.timeCreated||0);
      let insert = sortedIndexBy(backingArray,updatedConversation,c => -c.timeSorted);
      //console.log("CONVERSION",updatedConversation,"timeSorted",updatedConversation.timeSorted,"index",index,"insert",insert);
      //console.log("CONVERSION.PREPARE",[...backingArray],{...backingIndices});
      if (index!=insert) {
        backingArray.splice(index,1);
        insert = insert>index ? insert-1 : insert;
        backingArray.splice(insert, 0, updatedConversation);
        for (let i=Math.min(index,insert),max=Math.max(index,insert); i<=max; i++) {
          backingIndices[backingArray[i].id] = i;
        }
      } else {
        backingArray[index] = updatedConversation;
      }
      //console.log("CONVERSION.UPDATED",backingArray,backingIndices);
      state.conversationsState.entities  = createIndexedArrayProxy(backingHooks,backingArray,backingIndices);
      state.conversationsState.indices   = (<any>state.conversationsState.entities).backingIndicesProxy();
      state.conversationStates[conversationId] = {...state.conversationStates[conversationId]};
      state.conversationStates[conversationId].conversationData = updatedConversation;
    }
  }
  return state;
}

export function updateConversationDataLastMessage(conversationData:ConversationData,conversationsFilterId:string,message:ChatTimelineMessage,messageFilterId:string,force:boolean,cloneOnChange:boolean=false): ConversationData {
  if (message && !message.timeDeleted && !message.silent) {
    message.timeUpdated = MessageTimeUpdated(message);
    let isConversationFiltered = !!conversationsFilterId;
    let isMessageFiltered      = isConversationFiltered && conversationsFilterId==messageFilterId;
    let lastMessage =
      conversationData.lastMessage===message
        ? message
        : !conversationData.lastMessage || force
          ? message
          : !conversationData.lastMessage?.persistent || !!conversationData.lastMessage?.private
            ? message.persistent && !message.private
              ? message
              : conversationData.lastMessage?.timeCreated<=message.timeCreated
                ? message
                : conversationData.lastMessage
            : !message.persistent || message.private
              ? conversationData.lastMessage
              : conversationData.lastMessage.timeCreated<=message.timeCreated
                ? message
                : conversationData.lastMessage;
    //if (conversationData?.id=='direct.1679741802076687.3-4BZWHXJZRADZ2HDXBPHNYRHW2') {
    //  console.log("STORE.XY.reducer.updateConversationDataLastMessage.lastMessage",lastMessage,conversationData.lastMessage);
    //}
    /*
    let lastSharedMessage = !lastMessage?.private ? lastMessage :
      conversationData.lastSharedMessage===message
        ? message
        :
        !conversationData.lastSharedMessage
          ? message
          : !conversationData.lastMessage.persistent
            ? message.persistent
              ? message
              : conversationData.lastMessage.timeCreated<=message.timeCreated
                ? message
                : conversationData.lastMessage
            : !message.persistent
              ? conversationData.lastMessage
              : conversationData.lastMessage.timeCreated<=message.timeCreated
                ? message
                : conversationData.lastMessage;*/
    let filteredMessage =
      !isConversationFiltered
        ? undefined
        : !!conversationData.filteredMessage
        ? !isMessageFiltered
          ? conversationData.filteredMessage
          : conversationData.filteredMessage.timeCreated<=message.timeCreated
            ? message
            : conversationData.filteredMessage
        : !isMessageFiltered
          ? undefined
          : message;
    let timeSorted = Math.max(
      conversationData.draftMessage?.timeCreated||0,
      isConversationFiltered ?
        filteredMessage?.timeCreated||lastMessage?.timeCreated||0 :
        lastMessage?.timeCreated||0);
    if (conversationData.lastMessage===lastMessage &&
        conversationData.filteredMessage===filteredMessage &&
        conversationData.timeSorted==timeSorted) {
      //if (conversationData.id=='direct.1679741802076687.3-4BZWHXJZRADZ2HDXBPHNYRHW2') {
      //  console.log("XY.lastMessage.unchanged",lastMessage,conversationData);
      //}
      return conversationData;
    } else if (cloneOnChange) {
      //if (conversationData.id=='direct.1679741802076687.3-4BZWHXJZRADZ2HDXBPHNYRHW2') {
      //  console.log("XY.lastMessage.cloned",lastMessage,conversationData);
      //}
      return { ...conversationData, lastMessage, filteredMessage, timeSorted };
    } else {
      conversationData.lastMessage = lastMessage;
      conversationData.filteredMessage = filteredMessage;
      conversationData.timeSorted = timeSorted;
      //if (conversationData.id=='direct.1679741802076687.3-4BZWHXJZRADZ2HDXBPHNYRHW2') {
      //  console.log("XY.lastMessage.returned",lastMessage,conversationData);
      //}
    }
  }
  return conversationData;
}

export function ensureCoreConversation(state:ChatState,conversationHeader:ConversationHeader) : ChatState {
  //console.log("prepareConversation.state",state,"message",message,"conversationHeader",conversationHeader);
  let originalConversationState = state.conversationStates[conversationHeader.id]
  let {filterId,...conversationDefinition} = conversationHeader;
  let conversationState = { ...(originalConversationState ?? initialConversationState) };
  //console.log("prepareConversation.conversation",conversation,"existed",(conversation===conversationState.conversation));
  let result:ChatState = {
    ...state,
    conversationsState: { ...state.conversationsState},
    conversationStates: { ...state.conversationStates}
  };
  //console.log("prepareConversation.conversationState",conversationState,"existed",!!originalConversationState,"was",originalConversationState);
  let conversationData:ConversationData = (conversationState.conversationData ?? {
    ...conversationDefinition,
    segments: []
  });
  //console.log("STATE.CONVERSATIONQUEUE.2",state.conversationsQueue);
  result.conversationStates[conversationHeader.id] = conversationState;
//  if (conversationData?.id=='direct.1679741802076687.3-TFNESWQHQDTKFXQYNJSUBEM6E') {
//    console.trace("XY.reducer.ensureCoreConversation.data",conversationData.timeReceived,conversationData.timeViewed,conversationData,"header",conversationHeader.timeReceived,conversationHeader.timeViewed,conversationHeader);
//  }
  conversationState.conversationData = {
    ...conversationData,
    ...conversationHeader
  };
  //console.log("prepareConversation.conversation.lastMessage",conversation);
  return result;
}

export function prepareConversation(state:ChatState,message:ChatTimelineMessage,conversationHeader:ConversationHeader,temporary:boolean = false) : ChatState {
  //console.log("prepareConversation.state",state,"message",message,"conversationHeader",conversationHeader);
  let {filterId,...conversationDefinition} = conversationHeader;
  //console.log("prepareConversation.conversation",conversation,"existed",(conversation===conversationState.conversation));
  let result:ChatState  = ensureCoreConversation(state,conversationHeader);
  let conversationState = result.conversationStates[conversationHeader.id];
  let conversationData  = conversationState.conversationData =
      updateConversationDataLastMessage({...conversationState.conversationData},state.conversationsState.filterId,message,filterId,conversationHeader?.latest==true);
  conversationData.groupId = conversationHeader?.groupId ?? conversationData.groupId; // 2022.08.25 kht: we now pass groupId
  conversationData.canSend = conversationHeader?.canSend ?? conversationData.canSend;
  conversationData.canReact = conversationHeader?.canReact ?? conversationData.canReact;
  conversationData.temporary = temporary;
  if (conversationHeader.timeReceived>0) {
//    if (conversationData?.id=='direct.1679741802076687.3-TFNESWQHQDTKFXQYNJSUBEM6E') {
//      console.log("XY.reducer.prepareConversation.timeReceived",conversationData.timeReceived,"header",conversationHeader.timeReceived,conversationHeader);
//    }
    conversationData.timeReceived = Math.max(conversationData.timeReceived??0,conversationHeader.timeReceived);
  }
  if (conversationHeader.timeViewed>0) {
//    if (conversationData?.id=='direct.1679741802076687.3-TFNESWQHQDTKFXQYNJSUBEM6E') {
//      console.log("XY.reducer.prepareConversation.timeViewed",conversationData.timeViewed,"header",conversationHeader.timeViewed,conversationHeader);
//    }
    //if (conversationHeader.id=='host.lifechanger.3-CN4LZQRKDBMYQMH7KZZQGZMVC') {
    //  console.log("updateTimeViewed.header",conversationHeader);
    //}
    conversationData.timeViewed = Math.max(conversationData.timeViewed??0,conversationHeader.timeViewed);
  }
  if (conversationHeader.timeSelfReceived>0) {
    conversationData.timeSelfReceived = Math.max(conversationData.timeSelfReceived??0,conversationHeader.timeSelfReceived);
  }
  if (conversationHeader.timeSelfViewed>0) {
    conversationData.timeSelfViewed = Math.max(conversationData.timeSelfViewed??0,conversationHeader.timeSelfViewed);
  }
  if (conversationHeader.unseen>=0) {
    //console.log("UNSEEN.before",conversationData.unseen,"after",conversationHeader.unseen);
    conversationData.unseen = conversationHeader.unseen;
  }
  // is this conversation type listet in conversationsState.entities? ...
  if (mainConversationTypes.has(conversationHeader.type)) {
    //console.log("prepareConversation.conversation.lastMessage",conversation);
    let conversationsState = result.conversationsState;
    let conversationsHooks : IndexedArrayHooks<ConversationData> = (<any>conversationsState.entities)?.backingHooks || createCoreConversationHooks();
    let backingArray       : ConversationData[]     = (<any>conversationsState.entities)?.backingArray   ?? [...conversationsState.entities];
    //console.log("conversationsState.entities",conversationsState.entities,"backingArray",backingArray);
    let backingIndices     : {[key:string]: number} = (<any>conversationsState.entities)?.backingIndices ?? {...conversationsState.indices};
    //console.log("prepareConversation.backingArray",[...backingArray],"backingIndices",{...backingIndices});
    if (!message?.timeDeleted) {
      conversationsState.newestMessageVersion = Math.max(result.conversationsState.newestMessageVersion,MessageTimeUpdated(message,0));
    }
    let index  = conversationsState.indices[conversationDefinition.id];
    let insert = sortedIndexBy(backingArray,conversationData,c => -c.timeSorted);
    //console.log("prepareConversation.index",index,"insert",insert,"message",message,"conversationData",{...conversationData});
    if (isValidNumber(index)) {  // already there, so we have to move it if it does not fit...
      //console.log("prepareConversation.validIndex",index,"same",(index==insert),"insert",insert,"timeSorted",conversation.timeSorted,"backingArray",[...backingArray]);
      if (index!=insert) {
        backingArray.splice(index,1);
        insert = insert>index ? insert-1 : insert;
        backingArray.splice(insert, 0, conversationData);
        for (let i=Math.min(index,insert),max=Math.max(index,insert); i<=max; i++) {
          backingIndices[backingArray[i].id] = i;
        }
      } else {
        backingArray[index] = conversationData;
      }
    } else {
      //console.log("prepareConversation.invalidIndex",index,"insert",insert,"timeSorted",conversation.timeSorted,"backingArray",[...backingArray]);
      backingArray.splice(insert, 0, conversationData);
      for (let i=insert, max=backingArray.length; i<max; i++) {
        backingIndices[backingArray[i].id] = i;
      }
    }
    backingIndices[conversationData.id] = insert;
    //console.log("prepareConversation.result",conversation,"backingArray",[...backingArray],"backingIndices",{...backingIndices});
    conversationsState.entities = createIndexedArrayProxy(conversationsHooks,backingArray,backingIndices);
    conversationsState.indices  = (<any>conversationsState.entities).backingIndicesProxy();
    conversationsState.currentConversationId =
      conversationsState.currentConversationId || conversationData.id;
  }
  return result;
}

export function countUnseen(messages:ChatTimelineMessage[],participantId:string,timeViewed:number,defaultUnseen:number) {
  if (messages?.length>0 && isValidNumber(timeViewed)) {
    let unseen = 0;
    for (let index=messages?.length-1; index>=0; index--) {
      const message = messages[index];
      if (message.timeCreated<timeViewed) {
        break;
      } else if (!message.silent &&                 // do not count silent messages like participant added...
                  message.persistent &&             // only persistent messages...
                !!message.from?.id &&               // must have a sender id ... no "status infos"...
                  message.from.id!=participantId) { // no own messages...
        unseen++;
        console.log("UNSEEN",message);
      }
    }
    return unseen;
  }
  return defaultUnseen;
}

export function removeConversation(stateClone: ChatState, conversationId: string):ChatState {
  const index = stateClone.conversationsState.indices[conversationId];
  if (isValidNumber(index)) {
    stateClone.conversationsState = {...stateClone.conversationsState};
    let backingHooks    : IndexedArrayHooks<ConversationData>  = (<any>stateClone.conversationsState.entities)?.backingHooks;
    let backingArray    : ConversationData[]  = (<any>stateClone.conversationsState.entities)?.backingArray;
    let backingIndices  : {[key:string]: number} = (<any>stateClone.conversationsState.entities)?.backingIndices;
    backingArray.splice(index,1);
    delete backingIndices[conversationId];
    Object.keys(backingIndices).forEach(conversationId=>{
      if (backingIndices[conversationId]>index) {
        backingIndices[conversationId]--;
      }
    });
    if (stateClone.conversationsState.currentConversationId==conversationId) {
      stateClone.conversationsState.currentConversationId =
        backingArray.length==0 ? undefined :
        backingArray[index>0 ? index-1 : index].id;
    }
    stateClone.conversationsState.entities = createIndexedArrayProxy(backingHooks,backingArray,backingIndices);
    stateClone.conversationsState.indices  = (<any>stateClone.conversationsState.entities).backingIndicesProxy();
  }
  stateClone.conversationStates  = {...stateClone.conversationStates};
  stateClone.conversationsMarker = {...stateClone.conversationsMarker};
  delete stateClone.conversationStates[conversationId];
  delete stateClone.conversationsMarker[conversationId];
  console.log("removeConversation",stateClone,"conversationId",conversationId);
  return stateClone;
}

export const reducer = createReducer(
  initialChatState,
  on(setParticipantAction,(state,action) => {
    return logChatAction("setParticipantAction",false,state,action,()=>{
      if (state.participantId!=action.participantId) {
        const result = cloneDeep(initialChatState);
        result.participantId = action.participantId;
        result.online = state.online;
        return result;
      }
      return state;
    });
  }),
  // ALL CONVERSATIONS
  on(channelOpenStateAction,(state, action) => {
    return logChatAction("channelOpenStateAction",false,state,action,()=>{
      return {
        ...state,
        online:action.open
      }
    });
  }),
  on(setCurrentConversationIdAction,(state, action) => {
    return logChatAction("setCurrentConversationIdAction",false,state,action,()=>{
      if (state.conversationsState.currentConversationId==action.conversationId) {
        return state;
      } else if (isValidNumber(state.conversationsState.indices[action.conversationId])) {
        return {
          ...state,
          conversationsState: {
            ...state.conversationsState,
            currentConversationId: action.conversationId
          }
        }
      } else {
        state = ensureCoreConversation(state,{
          id: action.conversationId,
          type: undefined,
          version: 0
        });
        state.conversationsState = {
          ...state.conversationsState,
          currentConversationId: action.conversationId
        }
        return state;
      }
    });
  }),
  on(conversationMuteAction,(state,action) => {
    return logChatAction("conversationMuteAction",false,state,action,()=>{
      return updateMainConversation(state,action.conversationId,conversation => {
        if (conversation.timeMuted != action.timeMuted) {
          conversation = {
            ...conversation,
            timeMuted:action.timeMuted
          };
        }
        return conversation;
      });
    });
  }),
  on(setDraftMessageAction,(state, action) => {
    return logChatAction("setDraftMessageAction",false,state,action,()=>{
      return updateMainConversation(state,action.conversationId,conversation=> {
        //console.log("setDraftMessageAction.conversation.1",conversation);
        conversation = {
          ...conversation
        };
        let draftMessageContent:boolean = false;
        if (!!action.draftMessage?.id && !action.draftMessage.timeDeleted) {
          conversation.draftMessage = {...action.draftMessage};
          //console.log("XY.draftMessage.set",conversation.draftMessage);
          draftMessageContent = conversation.draftMessage.attachments?.length>0 || conversation.draftMessage.bodyText?.length>0;
          const entities = (<any>state.conversationStates[action.conversationId]?.entities);
          let index = entities?.backingIndices?.[action.draftMessage.id];
          //console.log("reducer.index",index,conversation.replyMessage,(conversation.replyMessage?.id != conversation.draftMessage.parentId),(conversation.replyMessage?.id != conversation.draftMessage.id));
          if (isValidNumber(index)) {
            if ((conversation.draftMessage.id != conversation.replyMessage?.id) &&
                (!conversation.draftMessage.parentId ||
                  conversation.draftMessage.parentId != conversation.replyMessage?.id)) {
              //console.log("reducer.reply",action.draftMessage);
              conversation.replyMessage = {...action.draftMessage};
            }
          } else if (!!conversation.draftMessage.parentId) {
            if (conversation.replyMessage?.id != conversation.draftMessage.parentId) {
              let index = entities?.backingIndices?.[action.draftMessage.parentId];
              if (isValidNumber(index)) {
                conversation.replyMessage = entities?.backingArray[index];
              } else {
                delete conversation.draftMessage.parentId;
                delete conversation.replyMessage;
              }
            }
          } else {
            delete conversation.draftMessage.parentId;
            delete conversation.replyMessage;
          }
        } else {
          //console.log("XY.draftMessage.delete");
          delete conversation.draftMessage;
          delete conversation.replyMessage;
        }
        //console.log("setDraftMessageAction.conversation.2",conversation);
        conversation.timeSorted = Math.max(
          draftMessageContent ? conversation.draftMessage.timeCreated||0 : 0,
          conversation?.lastMessage?.timeCreated||0,
          conversation?.filteredMessage?.timeCreated||0);
        //console.log("XY.setDraftMessageAction.conversation\n",conversation);
        return conversation;
      });
    });
  }),
  on(setReplyMessageAction,(state, action) => {
    return logChatAction("setReplyMessageAction",false,state,action,()=>{
      return updateMainConversation(state,action.conversationId,conversation=> {
        //console.log("setReplyMessageAction.conversation.1",conversation);
        conversation = {
          ...conversation
        };
        if (!!action.replyMessage) {
          conversation.replyMessage = {...action.replyMessage}
        } else {
          delete conversation.replyMessage;
        }
        delete conversation.draftMessage;
        //console.log("setReplyMessageAction.conversation.2",conversation);
        return conversation;
      });
    });
  }),
  on(setFilteredMessageAction,(state, action) => {
    return logChatAction("setFilteredMessageAction",false,state,action,()=>{
      return updateMainConversation(state,action.conversationId,conversation=> {
        conversation = {
          ...conversation
        };
        if (action.filteredMessage) {
          conversation.filteredMessage = {...action.filteredMessage}
        } else {
          delete conversation.filteredMessage;
        }
        conversation.timeSorted = Math.max(
          conversation?.draftMessage?.timeCreated||0,
          conversation?.lastMessage?.timeCreated||0,
          conversation?.filteredMessage?.timeCreated||0);
        return conversation;
      });
    });
  }),
  on(setTypingMessageAction,(state, action) => {
    return logChatAction("setTypingMessageAction",false,state,action,()=>{
      return updateMainConversation(state,action.conversationId,conversation=> {
        let typingMessages = (conversation.typingMessages||[]).filter(message => message?.from?.id!=action.typingMessage.from.id);
        if (action.typingMessage?.typing) {
          typingMessages.push(action.typingMessage);
          return {
            ...conversation,
            typingMessages
          }
        } else if (typingMessages.length!=conversation.typingMessages?.length) {
          return {
            ...conversation,
            typingMessages
          }
        }
        return conversation;
      });
    });
  }),
  on(addQueueMessagesAction,(state, action) => {
    return logChatAction("addQueueMessagesAction",false,state,action,()=>{
      if (action.messages?.length) {
        const ids = new Set(action.messages.map(m=>{
          return m.id;
        }));
        const queue = [...state.conversationsQueue.filter(m=>!ids.has(m.id)),...action.messages];
        //console.log("addQueueMessagesAction",action,state.conversationsQueue,queue);
        state = {
          ...state,
          conversationsQueue: queue
        }
        //console.log("DELIVERY.QUEUE.addQueueMessagesAction",state,action);
      }
      return state;
    });
  }),
  on(removeQueueMessagesAction,(state, action) => {
    return logChatAction("removeQueueMessagesAction",false,state,action,()=>{
      if (action.messages?.length) {
        const ids   = new Set(action.messages.map(m=>m.id));
        const queue = state.conversationsQueue.filter(m=>{
          return !ids.has(m.id);
        });
        state = {
          ...state,
          conversationsQueue: queue
        }
        //console.log("removeQueueMessagesAction",action,state.conversationsQueue,queue);
        //console.log("DELIVERY.QUEUE.removeQueueMessagesAction",state,action);
      }
      return state;
    });
  }),
  on(conversationsLoadRequestAction,(state, action) => {
    return logChatAction("conversationsLoadRequestAction",false,state,action,()=>{
      let filters  = action.filter?.filters || [];
      let term     = action.filter?.term || '';
      let filterId = filters.length==0 && term.length==0 ? undefined :
                     isEqual(state.conversationsState.filters, filters) &&
                     isEqual(state.conversationsState.term, term) ?
                     state.conversationsState.filterId : createFilterId();
      action.filter = !filterId ? undefined : { id:filterId, filters, term };
      action.newestMessageVersion = state.conversationsState.newestMessageVersion;
      state = { ...state};
      const conversationsMarker:{[key:string]:number} = {};
      Object.keys(state.conversationsMarker).forEach(key=>conversationsMarker[key]=0);
      state.conversationsMarker = conversationsMarker;
      return state;
    });
  }),
  on(conversationsLoadResultAction,(state, action) => {
    return logChatAction("conversationsLoadResultAction",false,state,action,()=>{
      let conversationsFilterId = state.conversationsState.filterId;
      let filterId = action.filter?.id || undefined;
      let filters  = action.filter?.filters || [];
      let term     = action.filter?.term || '';
      let result:ChatState = {
        conversationsState: {
          entities:              state.conversationsState.entities,
          indices:               state.conversationsState.indices,
          newestMessageVersion:  state.conversationsState.newestMessageVersion,
          currentConversationId: state.conversationsState.currentConversationId,
          filterId,filters,term
        },
        conversationStates: {
          ...state.conversationStates
        },
        conversationsMarker: state.conversationsMarker,
        conversationsQueue: state.conversationsQueue,
        participantId: state.participantId,
        error: state.error,
        online: state.online
      };
      Object.keys(result.conversationsMarker).forEach(conversationId=>{
        if (result.conversationsMarker[conversationId]==0) {
          removeConversation(result,conversationId);
        }
      });
  //    console.log("filterId",filterId,"conversationsFilterId",conversationsFilterId);
      if (filterId!=conversationsFilterId && state.conversationsState.entities.length>0) {
        let backingArray   : ConversationData[]  = (<any>state.conversationsState.entities).backingArray;
        let backingIndices : {[key:string]: number} = (<any>state.conversationsState.entities).backingIndices;
        let backingHooks   : IndexedArrayHooks<ConversationData> = (<any>state.conversationsState.entities).backingHooks;
        for (let i=0, max=backingArray.length; i<max; i++) {
          let conversationData  = backingArray[i];
          let conversationId    = conversationData.id;
          let conversationState = result.conversationStates[conversationId];
          backingArray[i] = updateConversationDataLastMessage(conversationData,undefined,conversationData.lastMessage,undefined,false,true);
          if (conversationState.filterId==conversationsFilterId) {
            result.conversationStates[conversationId] = conversationState = {...conversationState, filterId:'',filters:[],term:''};
          }
        }
        backingArray.sort((a,b)=>b.timeSorted-a.timeSorted);
        backingArray.forEach((conversationData,index)=>backingIndices[conversationData.id]=index);
        result.conversationsState.entities = createIndexedArrayProxy(backingHooks,backingArray,backingIndices);
        result.conversationsState.indices  = (<any>state.conversationsState.entities).backingIndicesProxy();
      }
      return result;
    });
  }),
  on(conversationFilterAction,(state, action) => {
    return logChatAction("conversationFilterAction",false,state,action,()=>{
      let conversationId = action.conversation.id;
      let conversationState = state.conversationStates[conversationId];
      action.segments = conversationState?.conversationData?.segments || [];
      return state;
    });
  }),
  on(attachmentPayloadResultAction,(state,action) => {
    return logChatAction("attachmentPayloadResultAction",false,state,action,()=>{
      //console.log("attachmentPayloadResultAction",state,action);
      // data is set currently where it is requested. we need additional field
      // for messages on client... a counter when internally updated. this way
      // trackBy would work....
      return state;
    });
  }),
  on(receiveChatTimelineMessageAction,(state,action) => {
    return logChatAction("receiveChatTimelineMessageAction",true,state,action,()=>{
      //if ('direct.1679741802076687.3-4BZWHXJZRADZ2HDXBPHNYRHW2'==action.message?.conversationId) {
      //  console.log("XY.receiveChatTimelineMessageAction.envelope",action.message);
      //}
      /*
      if ('direct.1-Q8RUGPMZZNEZM2VZ7SHWYRLYL.1679741802076687'==action.message.conversationId)
      console.log("DELIVERY.receiveChatTimelineMessageAction",state,action,"\nconversation:",action.headers?.conversation?.name,action.message.conversationId,
        "\nreceived:",moment(action.headers?.conversation?.timeReceived).format("yyyy.MM.DD HH:mm:ss SSS"),
        "\nviewed:",moment(action.headers?.conversation?.timeViewed).format("yyyy.MM.DD HH:mm:ss SSS"));
      */
      const conversationHeader = <ConversationHeader>action.headers.conversation;
      let   message            = <ChatTimelineMessage>action.message;
      if (!!conversationHeader?.id && !!message?.id && !!message?.conversationId) {
        const latest = conversationHeader.latest;
        //const log    = false;// && message.conversationId == "direct.1-Q8RUGPMZZNEZM2VZ7SHWYRLYL.1679741802076687";
        //console.log("receiveChatTimelineMessageAction.header",{...conversationHeader},"message",message);
        if (message.type==CreateMessageType && state.conversationStates[message.conversationId]?.entities?.length>0) {
          // if we already have a message in this chat, we do not propagate it, but we maybe have to update the header
          // with name etc... so we take last available message....
          const entities = state.conversationStates[message.conversationId].entities;
          //console.log("receive.MESSAGE",message.conversationId,action,message,"entities",entities,entities[entities.length-1]);
          message = entities[entities.length-1];
        }
        //const unseen0 = state?.conversationStates[conversationHeader.id]?.conversationData?.unseen;
        let result:ChatState = prepareConversation(state,message,conversationHeader,!!action.temporary);
        //const unseen1 = result?.conversationStates[conversationHeader.id]?.conversationData?.unseen;
        if (message.type==ChatParticipationsChangeMessageType ||
            message.type==ChatParticipationsRemoveMessageType) {
          const participants = (<ChatParticipationsMessage>message).participants ?? {};
          const currentRole  = participants[result.participantId]?.role;
          if (!!currentRole && (message.type==ChatParticipationsRemoveMessageType || currentRole=='blocked' || currentRole=='none')) {
            removeConversation(result,message.conversationId);
            return result;
          }
        }
        if (!!latest) {
          result.conversationsMarker = {...result.conversationsMarker};
          result.conversationsMarker[message.conversationId]=message.timeCreated;
        }
        let conversationState  = result.conversationStates[conversationHeader.id];
        let messageTimeUpdated = MessageTimeUpdated(message);
        // variables needed for unseen update...
        let messageInserted    = false;
        let messageDeleted     = false;
        let messageUpdated     = false;
        let messageUpdatedTime:number = undefined;
        //console.log("old",state.conversationStates[conversationHeader.id],"\nnew",conversationState,"\nsame",state.conversationStates[conversationHeader.id]===conversationState,"states",state.conversationStates===result.conversationStates,"state",state===result,state.conversationStates===state.conversationStates);
        if (message.persistent || !!message.timeDeleted) {
          let backingHooks    : IndexedArrayHooks<ChatTimelineMessage>  = (<any>conversationState.entities)?.backingHooks || action.hooks;
          let backingArray    : ChatTimelineMessage[]  = (<any>conversationState.entities)?.backingArray || [...conversationState.entities];
          let backingIndices  : {[key:string]: number} = (<any>conversationState.entities)?.backingIndices || {...conversationState.indices};
          let updatedIndex = undefined;
          let updatedSize  = 0;
          if (!!message.timeDeleted) {
            let index = backingIndices[message.id];
            //console.log("receiveChatTimelineMessageAction.deleted",index,"arrayLength",backingArray?.length);
            if (isValidNumber(index)) {
              messageDeleted = true;
              updatedIndex = index;
              updatedSize  = 1;
              delete backingIndices[message.id];
              let removed = backingArray.splice(index,1);
              for (let i=0, max=backingArray.length; i<max; i++) {
                backingIndices[backingArray[i].id] = i;
              }
              backingHooks.onSpliced(backingArray,index,removed,index,[]);
              let update = false;
              if (conversationState.conversationData.draftMessage?.id==message.id) {
                conversationState.conversationData.draftMessage = undefined;
                update = true;
              }
              if (conversationState.conversationData.replyMessage?.id==message.id) {
                conversationState.conversationData.replyMessage = undefined;
                update = true;
              }
              if (conversationState.conversationData.lastMessage?.id==message.id) {
                conversationState.conversationData.lastMessage = undefined;
                update = true;
              }
              if (conversationState.conversationData.filteredMessage?.id==message.id) {
                conversationState.conversationData.filteredMessage = undefined;
                update = true;
              }
              if (update && backingArray.length>0) {
                const m = findLast(backingArray,m=>m.persistent && !m.private) ?? backingArray[backingArray.length-1]
                //console.log("receiveChatTimelineMessageAction.update","\narrayLength",backingArray?.length);
                result = prepareConversation(result,m,{...conversationHeader,latest:true});
                conversationState = result.conversationStates[conversationHeader.id];
              }
            }
          } else {
            let index  = backingIndices[message.id];
            let insert = sortedIndexBy(backingArray,message,m => m.timeCreated);
            if (isValidNumber(index)) {  // already there, so we have to move it if it does not fit...
              let previousMessage = backingArray[index];
              let previousVersion = MessageTimeUpdated(previousMessage);
              let currentVersion  = messageTimeUpdated;
              updateAttachmentPayload(message,previousMessage);
              // if current is persistant or previous no persistent...
              if ((!!message._id || !previousMessage._id) && currentVersion>=previousVersion) {
                if (index!=insert) {
                  messageUpdatedTime = previousMessage.timeCreated;
                  // index=5, insert=10 ... insert=>9, index=>5
                  // index=10, insert=5 ... insert=>5, index=>11
                  // index=5, insert=6  ... insert=>5, index=>5
                  let removed = backingArray.splice(index,1);
                  insert = insert>index ? insert-1 : insert;
                  backingArray.splice(insert, 0, message);
                  index  = insert>index ? index : index+1;
                  for (let i=Math.min(index,insert),max=Math.max(index,insert); i<=max; i++) {
                    backingIndices[backingArray[i].id] = i;
                  }
                  backingHooks.onSpliced(backingArray,index,removed,insert,[message]);
                  updatedIndex = Math.min(index,insert);
                  updatedSize  = Math.max(index,insert)+1-updatedIndex;
                } else {
                  messageUpdated = true;
                  updatedIndex = index;
                  updatedSize  = 1;
                  backingHooks.onSet(backingArray,index,message);
                  backingArray[index] = message;
                }
              }
            } else {
              messageInserted = true;
              updatedIndex = insert;
              updatedSize  = 1;
              backingArray.splice(insert, 0, message);
              for (let i=insert, max=backingArray.length; i<max; i++) {
                backingIndices[backingArray[i].id] = i;
              }
            }
            const currentVersion = messageTimeUpdated;
            conversationState.newestMessageVersion = Math.max(conversationState.newestMessageVersion,currentVersion);
            if (state.participantId!=message.from?.id) {
              conversationState.newestIncomingMessageVersion =
                Math.max(conversationState.newestIncomingMessageVersion,currentVersion);
            }
          }
          // update unseen
          if (!conversationHeader.latest &&
               message.persistent &&
              !message.silent &&
             !!message.from?.id &&
               message.from.id!=state.participantId &&
               message.timeCreated>=conversationState.conversationData.timeViewed) {
            const unseen = conversationState.conversationData.unseen??0;
            if (messageInserted) {
              console.log("UNSEEN+1",message,[...backingArray]);
              conversationState.conversationData.unseen = Math.max(0,unseen+1);
            } else if (messageDeleted) {
              console.log("UNSEEN-1",message);
              conversationState.conversationData.unseen = Math.max(0,unseen-1);
            } else if (messageUpdated && !!messageTimeUpdated && messageTimeUpdated<conversationState.conversationData.timeViewed) {
              console.log("UNSEEN+1",message);
              conversationState.conversationData.unseen = Math.max(0,unseen+1);
            }
            /*
            console.log("UPDATE_UNSEEN",unseen,unseen0,unseen1,"inserted",messageInserted,
              "deleted",messageDeleted,
              "updated",messageUpdated,
              "upd+add",(messageUpdated && !!messageTimeUpdated && messageTimeUpdated<conversationState.conversationData.timeViewed),
              "result",conversationState.conversationData.unseen);*/
          }
          conversationState.entities = createIndexedArrayProxy(backingHooks,backingArray,backingIndices);
          conversationState.indices  = (<any>conversationState.entities).backingIndicesProxy();
          //if (log) console.log("prepareSegments.UPDATE_MESSAGE\nmessages",backingArray.length,"upd",updatedIndex,updatedSize,"latest",latest,"\nsegments",readableSegments(conversationState.conversationData.segments));
          conversationState.conversationData.segments =
            prepareSegments(conversationState,backingArray,backingIndices,conversationState.conversationData.segments,updatedIndex,updatedSize);
          //console.log("receiveChatTimelineMessageAction.CREATE.entities",backingArray,"\nRESULT",result===state,result.conversationStates[conversationHeader.id]===conversationState,result.conversationStates[conversationHeader.id].conversationData===conversationState.conversationData);
        }
        return result;
      }
      return state;
    });
  }),
  on(conversationSynchronizeSegmentAction,(state, action) => {
    return logChatAction("conversationSynchronizeSegmentAction",false,state,action,()=>{
      let conversationId = action.conversation.id;
      let conversationState = state.conversationStates[conversationId];
      action.segments = conversationState?.conversationData?.segments || [];
      let reversed = action.segments.length ? action.segments.slice().reverse() : [];
      action.current = reversed.length
        // last segment to be synchronized...
        ? reversed.find(segment => segment.synchronize)
        : undefined;
      if (!!action.current?.newestMessageInfo?.id && !!action.current?.oldestMessageInfo?.id) {
        const oldestTimeCreated    = action.current.oldestMessageInfo.timeCreated;
        const newestTimeCreated    = action.current.newestMessageInfo.timeCreated;
        const conversationMessages = (<any>conversationState?.entities)?.backingArray;
        const conversationIndices  = (<any>conversationState?.entities)?.backingIndices || {};
        action.current.messages    = [];
        //TODO: can be optimized ...
        for (let i=0, max=conversationMessages.length, started=false, done=false; i<max && !done; i++) {
          const message = conversationMessages[i];
          if (message.timeCreated>=oldestTimeCreated && message.timeCreated<=newestTimeCreated) {
            started = true;
            action.current.messages.push({
              id:message.id,
              timeCreated:message.timeCreated,
              timeUpdated:message.timeUpdated
            });
          } else if (started) {
            done = true;
          }
        }
      }
      // console.log("conversationSynchronizeSegmentAction",state,action,"\nconversationState",conversationState,"segments",action.segments);
      //console.log("conversationSynchronizeSegmentAction.1",state,action);
      return state;
    });
  }),
  on(conversationUpdateSegmentAction,(state,action) => {
    return logChatAction("conversationUpdateSegmentAction",false,state,action,()=>{
      /*
      if ('direct.1-Q8RUGPMZZNEZM2VZ7SHWYRLYL.1679741802076687'==action.conversation.id)
      console.log("DELIVERY.conversationUpdateSegmentAction",state,action,"\nconversation:",action.conversation.name,action.conversation.id,
        "\nreceived:",moment(action.timeReceived).format("yyyy.MM.DD HH:mm:ss SSS"),
        "\nviewed:",moment(action.timeViewed).format("yyyy.MM.DD HH:mm:ss SSS"));
      */
      if (!!action.conversation?.id) {
        const conversationId        = action.conversation.id;
        const conversationState0    = state.conversationStates[conversationId];
        const conversationMessages  = (<any>conversationState0?.entities)?.backingArray;
        const conversationIndices   = (<any>conversationState0?.entities)?.backingIndices || {};
        const currentSegment        = !!action.current?.newestMessageInfo?.id &&
                                      !!action.current?.oldestMessageInfo?.id
                                      ? action.current : undefined;
        const temporary = !!conversationState0?.conversationData?.temporary &&
                            conversationState0.conversationData.type=='direct' &&
                            conversationState0.entities.length<=1 &&
                            currentSegment?.messages?.length<=1;
        /*
        const currentSegmentUpdated = !!currentSegment && !conversationState0?.conversationData?.segments.find(segment=>{
          return segment.checksum == currentSegment.checksum &&
                 segment.size     == currentSegment.size &&
                 segment.newestMessageInfo?.id == currentSegment.newestMessageInfo?.id &&
                 segment.oldestMessageInfo?.id == currentSegment.oldestMessageInfo?.id &&
                 segment.newestMessageInfo?.timeUpdated == currentSegment.newestMessageInfo?.timeUpdated &&
                 segment.oldestMessageInfo?.timeUpdated == currentSegment.oldestMessageInfo?.timeUpdated;
        });*/
        const currentSegmentMessages: ChatTimelineMessage[] = currentSegment?.messages?.length>0 ? action.current.messages.map(stemp => {
          return !!(<any>stemp).type ? <ChatTimelineMessage>stemp : conversationMessages[conversationIndices[stemp.id]];
        }) : [];
        let currentSegmentLength = currentSegmentMessages.length;
        //console.log("segmentLength",currentSegmentLength,currentSegmentMessages,"\nprevLength",conversationState0?.conversationData?.segments?.length,conversationState?.conversationData?.segments,"\nupdated",currentSegmentUpdated);
        if (!currentSegment || currentSegmentLength==0) {
          //if (conversationId=='host.lifechanger.3-CN4LZQRKDBMYQMH7KZZQGZMVC') {
          //  console.log("updateTimeViewed.segment.action",action);
          //}
          let result:ChatState  = prepareConversation(state,conversationMessages?.length>0 ? conversationMessages[conversationMessages.length-1] : undefined,{...action.conversation, timeReceived:action.timeReceived, timeViewed:action.timeViewed}, temporary);
          let conversationState = result.conversationStates[conversationId];
          //console.log("prepareSegments.UPDATE_SEGMENT");
          conversationState.conversationData.segments =
            prepareSegments(conversationState,conversationMessages,conversationIndices,[...action.segments],0,0);
          return result;
        } else if (!!currentSegmentMessages.reduce((prev,curr)=> // ensure valid order of messages, oldest first
                  (!!prev && !!curr && prev.timeCreated<=curr.timeCreated) ? curr : undefined, currentSegmentMessages[0])) {
          let newestMessage = currentSegmentLength>0
            ? currentSegmentMessages[currentSegmentMessages.length-1]
            : undefined;
          if (needsAttachmentPayload(newestMessage) && !!conversationState0) {
            const oldIndex = conversationState0.indices[newestMessage.id];
            if (isValidNumber(oldIndex)) {
              updateAttachmentPayload(newestMessage,conversationState0.entities[oldIndex]);
            }
          }
          //console.log("0",conversationState0?.conversationData?.lastMessage);
          //if (conversationId=='host.lifechanger.3-CN4LZQRKDBMYQMH7KZZQGZMVC') {
          //  console.log("updateTimeViewed.segment.action",action);
          //}
          let result:ChatState = prepareConversation(state,newestMessage,{
            ...action.conversation,
            timeReceived:action.timeReceived,
            timeViewed:action.timeViewed,
            timeSelfReceived:action.timeSelfReceived,
            timeSelfViewed:action.timeSelfViewed
          },temporary);
          let conversationState = result.conversationStates[conversationId];
          //console.log("1",conversationState?.conversationData?.lastMessage);
          let backingHooks   : IndexedArrayHooks<ChatTimelineMessage>  = (<any>conversationState.entities)?.backingHooks || action.hooks;
          let backingArray   : ChatTimelineMessage[]  = (<any>conversationState.entities)?.backingArray || [...conversationState.entities];
          let backingIndices : {[key:string]: number} = (<any>conversationState.entities)?.backingIndices || {...conversationState.indices};
          let backingLength  = backingArray.length;
          let mergedArray    : ChatTimelineMessage[]  = new Array(backingLength+currentSegmentLength);
          let mergedIndices  : {[key:string]: number} = {};
          let backingIndex   = 0;
          let segmentIndex   = 0;
          let mergedIndex    = 0;
          let updatedIndex   = undefined;
          let updatedSize    = 0;
          let addMessage:(message:ChatTimelineMessage,isNew:boolean)=>void = (message,isNew) => {
            const previousIndex  = mergedIndices[message.id];
            const currentVersion = MessageTimeUpdated(message);
            if (isNew && needsAttachmentPayload(message) && backingIndices[message.id]!==undefined) {
              updateAttachmentPayload(message,backingArray[backingIndices[message.id]]);
            }
            if (previousIndex!==undefined) {
              const previousMessage = mergedArray[previousIndex];
              const previousVersion = MessageTimeUpdated(previousMessage);
              if (!!previousMessage._id && !message._id) {
                return;
              } else if ((!!message._id && !previousMessage._id) || currentVersion>previousVersion || (isNew && currentVersion==previousVersion)) {
                mergedArray.splice(previousIndex,1);
                mergedIndex--;
                for (let i=previousIndex; i<mergedIndex; i++) {
                  mergedIndices[mergedArray[i].id] = i;
                }
              } else {
                return;
              }
            }
            conversationState.newestMessageVersion = Math.max(conversationState.newestMessageVersion,currentVersion);
            if (state.participantId!=message.from?.id) {
              conversationState.newestIncomingMessageVersion =
                Math.max(conversationState.newestIncomingMessageVersion,currentVersion);
            }
            mergedIndices[message.id]  = mergedIndex;
            mergedArray[mergedIndex++] = message;
          }
          while (backingIndex<backingLength || segmentIndex<currentSegmentLength) {
            if (backingIndex>=backingLength) {
              updatedIndex = updatedIndex==undefined ? segmentIndex : updatedIndex;
              while (segmentIndex<currentSegmentLength) {
                addMessage(currentSegmentMessages[segmentIndex++],true);
              }
              updatedSize = segmentIndex-updatedIndex;
            } else if (segmentIndex>=currentSegmentLength) {
              while (backingIndex<backingLength) {
                addMessage(backingArray[backingIndex++],false);
              }
            } else {
              let backingMessage = backingArray[backingIndex];
              let segmentMessage = currentSegmentMessages[segmentIndex];
              if (segmentMessage.timeCreated<=backingMessage.timeCreated) {
                updatedIndex = updatedIndex==undefined ? segmentIndex : updatedIndex;
                addMessage(segmentMessage,true);
                segmentIndex++;
                updatedSize  = segmentIndex-updatedIndex;
              } else {
                addMessage(backingMessage,false);
                backingIndex++;
              }
            }
          }
          mergedArray.length = mergedIndex;
          conversationState.entities = createIndexedArrayProxy(backingHooks,mergedArray,mergedIndices);
          conversationState.indices  = (<any>conversationState.entities).backingIndicesProxy();
          // console.log("prepareSegments.UPDATE_SEGMENT\nmessages",mergedArray.length,"upd",updatedIndex,updatedSize);
          const segments = action.segments.map(segment=>segment.newestMessageInfo.id==currentSegment.newestMessageInfo.id
                                               ? {...segment,loaded:true} : segment);
          conversationState.conversationData.segments =
            prepareSegments(conversationState,mergedArray,mergedIndices,segments,updatedIndex,updatedSize);
          return result;
        }
      }
      return state;
    });
  }),
  on(receiveDeliveryMessageAction,(state, action) => {
    return logChatAction("receiveDeliveryMessageAction",true,state,action,()=>{
      return updateMainConversation(state,action.message.conversationId,conversation => {
        /*
        if ('direct.1-Q8RUGPMZZNEZM2VZ7SHWYRLYL.1679741802076687'==conversation.id)
        console.log("DELIVERY.receiveDeliveryMessageAction",state,action,"\nconversation:",conversation?.name,conversation?.id,
          "\nreceived:",moment(action.message?.timeReceived).format("yyyy.MM.DD HH:mm:ss SSS"),
          "\nviewed:",moment(action.message.timeViewed).format("yyyy.MM.DD HH:mm:ss SSS"));
        */
        const prevReceived      = conversation.timeReceived??0;
        const prevViewed        = conversation.timeViewed??0;
        const prevSelfReceived  = conversation.timeSelfReceived??0;
        const prevSelfViewed    = conversation.timeSelfViewed??0;
        const timeReceived      = Math.max(prevReceived,action.message?.timeReceived??0);
        const timeViewed        = Math.max(prevViewed,action.message?.timeViewed??0);
        const timeSelfReceived  = Math.max(prevSelfReceived,action.message?.from?.timeReceived??action.message?.timeReceived??0);
        const timeSelfViewed    = Math.max(prevSelfViewed,action.message?.from?.timeViewed??action.message?.timeViewed??0);
        //if (conversation.id=='host.lifechanger.3-CN4LZQRKDBMYQMH7KZZQGZMVC') {
        //  console.log("updateTimeViewed.delivery",action,"timeViewed",timeViewed);
        //}
        if (prevReceived < timeReceived ||
            prevViewed   < timeViewed ||
            prevSelfReceived < timeSelfReceived ||
            prevSelfViewed < timeSelfViewed) {
          conversation = {
            ...conversation, timeReceived, timeViewed, timeSelfReceived, timeSelfViewed
          };
          // adjust unseen....
          if (prevViewed < timeViewed) {
            const conversationState = state.conversationStates[action.message.conversationId];
            const messages:ChatTimelineMessage[] = (<any>conversationState.entities).backingArray;
            conversation.unseen = countUnseen(messages,state.participantId,timeViewed,conversation.unseen);
          }
        }
        return conversation;
      });
    });
  }),
  on(triggerTimeViewedAction,(state, action) => {
    return logChatAction("triggerTimeViewedAction",false,state,action,()=>{
      return updateMainConversation(state,action.conversationId,conversation => {
        const timeSelfViewed = action.timeViewed;
        //if (conversation?.id=='host.lifechanger.3-CN4LZQRKDBMYQMH7KZZQGZMVC') {
        //  console.log("triggerTimeViewed.action",action,"conversation",conversation);
        //}
        if ((conversation.timeSelfViewed??0) < timeSelfViewed) {
          action.send = true;
          conversation = {
            ...conversation,
            timeSelfViewed
          }
          // adjust unseen....
          const conversationState = state.conversationStates[action.conversationId];
          const messages:ChatTimelineMessage[] = (<any>conversationState.entities).backingArray;
          conversation.unseen = countUnseen(messages,state.participantId,timeSelfViewed,conversation.unseen);
        }
        return conversation;
      });
    });
  }),
  /*
  on(conversationsUpdateFilterAction,(state,{filters})=> (
    isEqual(state.conversations.filters, filters) ? state : {
      conversations: {
        ...state.conversations,
        filters: filters,
        currentCacheId: createCacheId()
      },
      conversation: {
        ...state.conversation
      }
    })),
  on(conversationsUpdateSearchTermAction,(state,{term})=> (
    isEqual(state.conversations.term, term) ? state : {
      conversations: {
        ...state.conversations,
        term: term,
        currentCacheId: createCacheId()
      },
      conversation: {
        ...state.conversation
      }
    })),
  */
  on(testAction,(state, action) => {
    return logChatAction("testAction",false,state,action,()=>{
      action.reducer = true;
      return state;
    });
  }),
);

export function logChatAction<S,A>(name:string,log:boolean,state:S,action:A,reducer:()=>S):S {
  //const conversationId = (<any>action)?.conversationId ?? (<any>action)?.message?.conversationId ?? (<any>action)?.conversation?.id;
  //log = true;//conversationId=='direct.1679741802076687.3-4BZWHXJZRADZ2HDXBPHNYRHW2';
  return logAction(name,log,state,action,reducer);
}

