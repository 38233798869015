<ng-container *ngLet="calendarViewService.view$ | async as view">
  <div class="controls" *ngLet="containsToday(view) as todayDisabled">
    <!--
    <div class="left" matRipple (click)="previous(view)" *ngIf="!periodSelector"><mat-icon>navigate_before</mat-icon></div>
    <div class="today" matRipple [matRippleDisabled]="todayDisabled" [class.disabled]="todayDisabled" (click)="!todayDisabled && today()">
      <span translate>events.filters.today</span>
    </div>
    -->
    <div #viewMenuTrigger="matMenuTrigger" class="select" matRipple [matMenuTriggerFor]="viewMenu">
      <span *ngIf="periodSelector">{{date | date: 'DD MMM'}}</span>
      <mat-icon [class.small]="periodSelector">expand_less</mat-icon>
    </div>
    <div class="right" matRipple (click)="next(view)" *ngIf="!periodSelector"><mat-icon>navigate_next</mat-icon></div>
    <div class="range" matRipple *ngIf="periodSelector" [matMenuTriggerFor]="periodMenu">
      <span [innerText]="('events.filters.period.'+period) | translate"></span>
      <mat-icon class="small">expand_less</mat-icon>
    </div>
    <mat-menu #viewMenu yPosition="above" xPosition="after" class="calendar-context-menu">
      <div mat-menu-item class="calendar" disableRipple (click)="onTapCalendar($event)">
        <mat-calendar #calendar [selected]="date" (selectedChange)="navigate.emit({ date: $event.toDate() })"></mat-calendar>
      </div>
      <div mat-menu-item class="view-switch" disableRipple>
        <button mat-raised-button
                color="primary"
                tabindex="-1"
                [disabled]="todayDisabled"
                (click)="!todayDisabled && today()"><span translate>events.filters.today</span></button>
      </div>
      <!--
      <div mat-menu-item class="view-switch" disableRipple>
        <calendar-view-switch></calendar-view-switch>
      </div>
      -->
    </mat-menu>
    <mat-menu #periodMenu yPosition="above" xPosition="after" class="period-context-menu">
      <div mat-menu-item *ngFor="let period of [ 'day', 'week', 'month', 'quarter', 'year', 'all' ]"
           [class]="period"
           (click)="navigate.emit({ period })"
           [innerText]="'events.filters.period.'+period | translate">
      </div>
    </mat-menu>
    <calendar-view-switch></calendar-view-switch>
  </div>
</ng-container>
