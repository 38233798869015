import {ProvisioningType, ProvisioningTypes} from "./provisioning-type";

export const SeatsTypes = ProvisioningTypes;
export type  SeatsType  = ProvisioningType;
export namespace SeatsType  {
  export const online  : SeatsType = SeatsTypes[0];
  export const offline : SeatsType = SeatsTypes[1];
  export const hybrid  : SeatsType = SeatsTypes[2];
  export function isOnline(type: SeatsType) : boolean  { return ProvisioningType.isOnline(type);  }
  export function isOffline(type: SeatsType): boolean  { return ProvisioningType.isOffline(type); }
  export function isHybrid(type: SeatsType) : boolean  { return ProvisioningType.isHybrid(type);  }
  export function types(): readonly SeatsType[] { return ProvisioningType.types(); }
}

type SeatsMap = { [key in Exclude<SeatsType, 'hybrid'>]?: number };

export class Seats {

  // NOTE: Seats type is a projection of an unique seats key combination
  // i.e. presence or absence of keys is what drives the type resolution logic
  // not the corresponding values themselves
  private seats: SeatsMap = {};

  static create(seats: SeatsMap): Seats {
    const result = new Seats();
    result.seats = seats;
    return result;
  }

  static merge(...seats: Seats[]): Seats {
    let result = new Seats();
    for (let i=0; i< seats?.length; i++) {
      if (seats[i]) {
        result.apply(
          // json deserialization could lead to literal objects instead of Seats instances
          seats[i] instanceof Seats ? seats[i] : Seats.create(seats[i]),
          (c1, c2) =>  c1 ?? c2,
          (value: number) => value,
          true
        )
      }
    }
    return result;
  }

  constructor(online?: number, offline?: number) {
    if (online!=undefined) {
      this.seats.online = online;
    }
    if (offline!=undefined) {
      this.seats.offline = offline;
    }
  }

  get online() {
    return this.seats.online;
  }

  get offline() {
    return this.seats.offline;
  }

  get type(): SeatsType {
    const keys = Object.keys(this.seats);
    return !keys.length
      ? null
      : keys.length==1
        ? SeatsTypes.find(type => type==keys[0])
        : SeatsType.hybrid;
  }

  add(seats: Seats, mix= true): Seats {
    return this.clone().apply(seats, (c1, c2) =>  c1 + c2, (value) => 0, mix);
  }

  remove(seats: Seats, mix = false): Seats {
    return this.clone().apply(seats, (c1, c2) =>  c1 - c2, (value) => 0, mix);
  }

  protected apply(seats: Seats, operator: (c1: number, c2: number) => number, emptyMapper = (value: number) => value, mix: boolean): Seats {
    Object.keys(seats?.seats || {}).forEach((key) => {
      if (Object.keys(this.seats).includes(key) || mix) {
        this.seats[key] = operator(
          this.seats[key]  ?? emptyMapper(this.seats[key]) ,
          seats.seats[key] ?? emptyMapper(seats.seats[key])
        );
      }
    });
    return this;
  }

  protected clone(): Seats {
    return Seats.create({...this.seats});
  }

  toJSON(): SeatsMap {
    return this.seats;
    // return Object.keys(this.seats).reduce((result, key) => {
    //   if (this.seats[key] != undefined) {
    //     result[key] = this.seats[key];
    //   }
    //   return result;
    // }, {});
  }
}

export class SeatsRegistry {

  constructor(public total = new Seats(),
              public available = new Seats()) {
  }

  static create(init: Partial<SeatsRegistry>) {
    const seatsRegistry = Object.assign(new SeatsRegistry(), init);
    Object.keys(seatsRegistry)
      .filter(key => !(seatsRegistry[key] instanceof Seats))
      .forEach(key => seatsRegistry[key] = Seats.create(seatsRegistry[key]));
    return seatsRegistry;
  }

  static merge(...seatsRegistries: Partial<SeatsRegistry>[]): SeatsRegistry {
    let result = new SeatsRegistry();
    for (let i=0; i< seatsRegistries?.length; i++) {
      let seatsRegistry = seatsRegistries[i];
      if (seatsRegistry) {
        if (!(seatsRegistry instanceof SeatsRegistry)) {
          seatsRegistry = SeatsRegistry.create(seatsRegistry);
        }
        Object.keys(result).forEach(key => result[key] = Seats.merge(seatsRegistry[key], result[key] /*?? new Seats(0, 0)*/));
      }
    }
    return result;
  }
}
