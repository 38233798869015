/*
  CONTACT RELATED
*/
import {Message} from "messaging";
import {Contact, VersionedId} from "core";
import {ChatControlMessage} from "chat";

export interface ContactRelatedMessage extends Message {
}

export const ContactSubscriptionMessageType : string = "contact_subscription";
export interface ContactSubscriptionMessage extends ContactRelatedMessage {
  subscribed: VersionedId[]
}

export const ContactUpdateMessageType : string = "contact";
export interface ContactUpdateMessage extends ContactRelatedMessage {
  contact: Contact
}

export interface ContactReachValues {
  id: string;
  label: string;
  array: number[];
}


export const TermTestMessageType : string = "termTest";
export interface TermTestMessage extends ContactRelatedMessage {
  term?: string;   // term to be tested
}

export interface TermTestResult {
  term?: string;   // term to be tested
  calculatedParticipants: number;   // calculated participants
  restrictedParticipants: boolean;  // restricted participants (formula works only in my downline with connected people)
  errorLabel?: string;   // error label to be shown
  errorParameters?: {[param:string]:string};   // error parameters to be shown
}

export const TermTestResultMessageType : string = "termTestResult";
export interface TermTestResultMessage extends ChatControlMessage, TermTestResult {
}



export const MemberIdRequestMessageType = "contact.memberId.request";
export interface MemberIdRequestMessage extends ContactRelatedMessage {
  firstName: string;
  lastName: string;
}

export const MemberIdResponseMessageType = "contact.memberId.response";
export interface MemberIdResponseMessage extends ContactRelatedMessage {
  memberId: string;
  expirationTime: number;
}

