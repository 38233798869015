import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "material";
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {contactFeatureKey, contactReducers} from "./store/reducers";
import {ContactEffects} from "./store/effects";
import {SharedModule} from "shared";
import {ContactListComponent, SafeContactPipe} from "./components/contact-list/contact-list.component";
import {ContactListItemComponent} from "./components/contact-list-item/contact-list-item.component";
import {ContactJoinInfoComponent} from './components/contact-join-info/contact-join-info.component';
import {PartnerDetailComponent} from "./components/partner-detail/partner-detail.component";
import {ContactDetailComponent} from "./components/contact-detail/contact-detail.component";
import {Contact, NULL_CONTACT, NullContact} from "core";
import {AuthenticationModule} from "auth";
import {ContactLocalListComponent} from "./components/contact-local-list/contact-local-list.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MapsModule} from "maps";
import {ContactDomainAccessComponent} from "./components/contact-domain-access/contact-domain-access.component";
import { ContactAddressBookAddDialogComponent } from './components/contact-address-book-add-dialog/contact-address-book-add-dialog.component';
import {LayoutModule} from "layout";
import {SessionModule} from "session";
import {FilterModule} from "filter";
import { ContactDetailFiltersComponent } from './components/contact-detail/contact-detail-filters/contact-detail-filters.component';
import { ContactDetailReachComponent } from './components/contact-detail/contact-detail-reach/contact-detail-reach.component';
import { ContactDetailInfoComponent } from './components/contact-detail/contact-detail-info/contact-detail-info.component';
import {ContactReachComponent} from "./components/contact-reach/contact-reach.component";
import { ContactDetailInterestsComponent } from './components/contact-detail/contact-detail-interests/contact-detail-interests.component';
import {InterestsModule} from "interests";
import {ContactDetailSurveyComponent} from "./components/contact-detail/contact-detail-survey/contact-detail-survey.component";

// const routes: Routes = [
//   { path: '', component: PartnerListContainerComponent, data: { animation: 'contacts' } }
// ];

export function nullContactFactory(): Contact {
  return new NullContact();
}

export const COMPONENTS = [
  ContactListItemComponent,
  ContactListComponent,
  ContactJoinInfoComponent,
  PartnerDetailComponent,
  ContactLocalListComponent,
  ContactDetailComponent,
  ContactDomainAccessComponent,
  ContactAddressBookAddDialogComponent,
  ContactReachComponent,
  SafeContactPipe
];

export const PROVIDERS = [
  // { provide: NULL_CONTACT, useValue: new NullContact() }
  { provide: NULL_CONTACT, useFactory: nullContactFactory },
  SafeContactPipe
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    FilterModule,
    MapsModule,
    AuthenticationModule,
    StoreModule.forFeature(contactFeatureKey, contactReducers),
    EffectsModule.forFeature([ContactEffects]),
    ScrollingModule,
    LayoutModule,
    SessionModule,
    InterestsModule
    // RouterModule.forChild(routes)
  ],
  // exports: [
  //   RouterModule
  // ],
  declarations: [ COMPONENTS,
    ContactDetailFiltersComponent,
    ContactDetailReachComponent,
    ContactDetailInfoComponent,
    ContactDetailInterestsComponent,
    ContactDetailSurveyComponent
  ],
  exports  : [ COMPONENTS/*.filter(c => c!=SafeContactPipe) */],
  providers: PROVIDERS
})
export class ContactModule {}
