import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {ResizeService} from "shared";
import {ImageLink, Media, MediaService, MediaViewerOverlayService} from "media";
import {AttachmentComponent} from "../../core/attachment.component";
import {Attachment} from "messaging";
import {MediaAttachment} from "../../../../store/models";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MediaAttachmentComponent} from "../core/media.attachment.component";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'media-attachment-composer',
  templateUrl: './media-attachment-composer.component.html',
  styleUrls: ['./media-attachment-composer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaAttachmentComposerComponent extends MediaAttachmentComponent implements OnDestroy {
  protected _updated$ = new EventEmitter<AttachmentComponent>();
  protected _deleted$ = new EventEmitter<Attachment>();
  protected confirmAttachment:MediaAttachment;
  protected subscriptions = new Map<string,Subscription>();
  protected observables   = new Map<string,BehaviorSubject<Media>>();

  constructor(
    protected resizeService: ResizeService,
    protected mediaService: MediaService,
    protected mediaViewerOverlayService: MediaViewerOverlayService,
    protected translateService: TranslateService,
    protected snackBar: MatSnackBar,
    protected changeDetectorRef: ChangeDetectorRef) {
    super(mediaViewerOverlayService,changeDetectorRef);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription)=>subscription.unsubscribe());
    this.subscriptions.clear();
    this.observables.clear();
  }

  @Input()
  @Output()
  set attachments(attachments: Attachment[]) {
    //console.log("SET ATTACHMENTS",attachments);
    super.attachments = attachments;
    const subscribed  = new Map<string,Subscription>(this.subscriptions);
    this.mediaAttachments.forEach(mediaAttachment=>{
      const media = mediaAttachment.media;
      if (subscribed.delete(media.id)) {
        this.observables.get(media.id).next(media);
      } else {
        const observable = new BehaviorSubject<Media>(media);
        this.observables.set(media.id,observable);
        this.subscriptions.set(media.id,this.mediaService.getMedia$(media.id,media).subscribe(updatedMedia=>{
          if (observable.value.version<updatedMedia.version) {
            //console.log("MEDIA_ATTACHMENT_COMPOSER.MEDIA",updatedMedia);
            observable.next(updatedMedia);
            mediaAttachment.media = updatedMedia;
            this.updated.next(this);
          }
        }));
      }
    });
    //console.log("SUBSCRIBED.delete",subscribed);
    subscribed.forEach((subscription,id)=>{
      subscription.unsubscribe();
      this.subscriptions.delete(id);
      this.observables.delete(id);
    });
    //console.log("OBSERVABLES",this.observables);
  }

  get attachments():Attachment[] {
    return super.attachments;
  }

  dropArrange(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.attachments, event.previousIndex, event.currentIndex);
    this.updated.emit(this);
  }

  getMedia$(attachment:MediaAttachment):Observable<Media> {
    //console.log("getMedia$",attachment,"\nresult",this.observables.get(attachment.media.id));
    return this.observables.get(attachment.media.id);
  }

  getDimension(media:Media):{width:number,height:number,pct?:number} {
    //console.log("ATTACHMENT.getWidth",media);
    const cover = media?.cover;
    const link  = cover?.width>0 && cover?.height>0
                ? cover
                : <ImageLink>media.links?.find(link=>link.width>0 && link.height>0);
    if (!!link) {
      const width = (link.width/link.height * 66);
      //console.log("ATTACHMENT.getWidth.media",media,"link",link,"width",width);
      return { width:width, height:66 };
    }
    return {width:66,height:66,pct:100};
  }

  clickedOutside(attachment:MediaAttachment) {
    if (this.confirmAttachment===attachment) {
      this.confirmAttachment = undefined;
      this.changeDetectorRef.markForCheck();
    }
  }

  delete(attachment:MediaAttachment) {
    //this.deleted.emit(attachment);
    if (this.confirmAttachment===attachment) {
      this.mediaService.deleteMedia(attachment.media)
        .then(media=>{
          attachment.media = media;
          this.deleted.emit(attachment);
        })
        .catch(error=>{
          this.snackBar.open(this.translateService.instant('chat.error.cannotDeleteAttachment'), this.translateService.instant('actions.close'), {
            duration: 2000,
            horizontalPosition: 'right',
          });
          this.deleted.emit(attachment);
        });
    } else {
      this.confirmAttachment = attachment;
      this.changeDetectorRef.markForCheck();
    }
  }

  get updated(): EventEmitter<AttachmentComponent> {
    return this._updated$;
  }

  get deleted(): EventEmitter<Attachment> {
    return this._deleted$;
  }

  trackBy = (index: number, mediaAttachment: MediaAttachment) => {
    const media = mediaAttachment?.media;
    return !!media ? media.id + media.version : mediaAttachment;
  };
}
