import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ProductAdapter {

  protected readonly separator = '.';

  normalize(pid: string): string {
    return pid.replace(/_/g, this.separator);
  }

  native(pid: string, source: string) {
  }

  type(pid: string): string {
    const parts = this.normalize(pid)?.split(this.separator);
    return parts[parts.length > 1 ? 1 : 0];
  }

  interval(pid: string): {value: number, name: string} {
    const productId = this.normalize(pid);
    const name  = productId?.slice(productId.lastIndexOf(this.separator)+1)?.toLowerCase();
    const value = name == 'monthly'  ? 1  :
                  name == 'biannual' ? 6  :
                  name == 'annual'   ? 12 : 0;
    return { value: value, name: name };
  }

  match(pid1: string, pid2: string): boolean {
    return this.normalize(pid1)==this.normalize(pid2);
  }
}
