

export function addTrack(mediaStream:MediaStream, track: MediaStreamTrack): void {
  if (!!mediaStream && !!track && mediaStream.getTracks().indexOf(track) < 0) {
    mediaStream.addTrack(track);
    mediaStream.dispatchEvent(new MediaStreamTrackEvent('addtrack',{track:track}));
  }
}

export function removeTrack(mediaStream:MediaStream, track: MediaStreamTrack): void {
  if (!!mediaStream && !!track && mediaStream.getTracks().indexOf(track) >= 0) {
    if (mediaStream.getTracks().length === 1) {
      console.log("#C.last track of mediaStream ",mediaStream.id);
    }
    mediaStream.removeTrack(track);
    mediaStream.dispatchEvent(new MediaStreamTrackEvent('removetrack',{track:track}));
  }
}

