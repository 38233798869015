/* angular:styles/component:css;bd70de811958c52e322f5a351f3417c414ad79d7fb517b7b69b0ab719b5f1e13;/Users/mbratoev/Project/fbgroup/client.ng/projects/launcher/src/lib/components/welcome/welcome-overlay.component.ts */
:host {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
button mat-icon {
  margin-right: 6px;
}
button.close {
  position: absolute;
  top: 0;
  right: 0;
}
button.close mat-icon {
  color: #aaa;
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  right: constant(safe-area-inset-right);
  right: env(safe-area-inset-right);
}
/*# sourceMappingURL=welcome-overlay.component-TMLWJIAC.css.map */
