import {Contact, NULL_CONTACT, NullContact, VersionedId} from 'core';
import {Inject, InjectionToken} from "@angular/core";
import {HasTranslations} from "./translations";
import {Message} from "messaging";

export class Calendar extends HasTranslations {
  readonly id: string;
  readonly version : number;
  author: Partial<Contact>;
  name: string;
  info: string;
  timeCreated: number;
  timeUpdated: number;
  countryCodes: string[];
  countryCodesExcluded?: boolean;
  tags: string[];
  filters: {[key: string]: string};
  properties: any;
  language: string;
  editorsTerm: string;
  viewersTerm: string;
  eventsTerm: string;
  type: string;
  color: string;
  permission?: {
    edit?: boolean;
    editEvents?: boolean;
  }

  constructor(init?: Partial<Calendar>) {
    super();
    Object.assign(this, init);
  }

  get isNew(): boolean {
    return !this.id;
  }
}

export class NullCalendar extends Calendar {

  constructor(@Inject(NULL_CONTACT) public author: NullContact) {
    super();
    this.name = '';
    this.info = '';
    this.timeCreated = this.timeUpdated = new Date().getTime();
    this.type = 'private';
    // this.language = this.author.country_code;
  }

  getTranslations(): string[] {
    return [];
  }

  hasTranslations(): boolean {
    return false;
  }
}

export const NULL_CALENDAR = new InjectionToken('NullCalendar');


export interface CalendarRelatedMessage extends Message {
}

export const CalendarSubscriptionMessageType : string = "calendar.subscription";
export interface CalendarSubscriptionMessage extends CalendarRelatedMessage {
  subscribed: VersionedId[];
}

export const CalendarUpdateMessageType : string = "calendar.update";
export interface CalendarUpdateMessage extends CalendarRelatedMessage {
  calendar: Calendar;
}
