/* projects/chat/src/lib/components/attachment/media/viewer/media-attachment-file-viewer.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
}
:host > div.container {
  position: relative;
  display: flex;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
:host > div.container > div.media {
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-flex;
  flex-direction: row;
  max-width: 200px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.2509803922);
  border-radius: 4px;
  padding: 2px 2px 2px 0;
}
:host > div.container > div.media:last-child {
  margin-right: 0;
}
:host > div.container > div.media > mat-icon {
  flex: none;
  display: flex;
  width: 20px;
  height: 20px;
  line-height: 16px;
  font-size: 16px;
  padding: 2px;
  margin-right: 2px;
  color: white;
}
:host > div.container > div.media > mat-icon.download {
  border: 1px solid white;
  border-radius: 50%;
  padding: 1px;
  background-color: var(--color-primary);
  color: white;
  margin-left: 6px;
  cursor: pointer;
}
:host > div.container > div.media.downloading > mat-icon.download {
  cursor: unset;
  border: 1px solid orange;
  background-color: transparent;
}
:host > div.container > div.media > div.name {
  display: flex;
  font-size: 13px;
  overflow: hidden;
}
:host > div.container > div.media > div.name > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
:host > div.container > div.media > div.extension {
  display: flex;
  font-size: 13px;
}
:host > div.container > div.media > a,
:host > div.container > div.media > a:link,
:host > div.container > div.media > a:visited,
:host > div.container > div.media > a:hover,
:host > div.container > div.media > a:active {
  color: transparent;
  text-decoration: none;
}
:host-context(.left) :host > div.container > div.media > mat-icon {
  color: #999;
}
:host-context(.left) :host > div.container > div.media > mat-icon.download {
  color: white;
}
:host-context(.color-scheme-dark .left) :host > div.container > div.media > mat-icon {
  color: white;
}
:host-context(.color-scheme-dark .left) :host > div.container > div.media > mat-icon.download {
  color: white;
}
/*# sourceMappingURL=media-attachment-file-viewer.component-T2WKXZD3.css.map */
