import {ChangeDetectionStrategy, Component, effect, HostBinding, input, signal} from '@angular/core';
import {ChatService} from "../../chat.service";
import {ChatTimelineMessage, ConversationData, MessageTimeUpdated} from "../../store/models";

@Component({
  selector: 'message-delivery-state',
  template: `<mat-icon *ngIf="!!icon()" [class.viewed]="viewed()">{{icon()}}</mat-icon>`,
  styles: [`
    :host {
      display: inline-flex;
      vertical-align: top;
    }
    :host.invalid {
      display: none;
    }
    mat-icon {
      width: 1em;
      height: 1em;
      line-height: 1em;
      font-size: 1em;
      display: inline-flex;
    }
    mat-icon.viewed {
      color: orangered;
    }
    :host-context(.color-scheme-dark)
      mat-icon {
        /*background-color: unset;*/
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDeliveryStateComponent {
  @HostBinding('class.invalid') invalid: boolean = false;

  conversationData = input.required<ConversationData>();
  message = input.required<ChatTimelineMessage>();
  log = input<boolean>(false);

  icon = signal<string>(undefined);
  sent = signal<boolean>(false);
  received = signal<boolean>(false);
  viewed = signal<boolean>(false);

  protected update() {
  }

  constructor(
    protected chatService: ChatService) {
    effect(() => {
      const message = this.message();
      const conversationData = this.conversationData();
      if (!!message?.from?.id && !!conversationData) {
        const userId  = this.chatService.userId;
        const timeUpdated = MessageTimeUpdated(message,-1);
        const sent = message.from.id!=userId || !message.pending
        const received = !message.private && (message.from.id!=userId || (conversationData.timeReceived??0)>=timeUpdated)
        const viewed = received && (conversationData.timeViewed??0)>=timeUpdated;
        const icon:string = !sent ? 'sync' :
            sent && !received ? 'done' :
            sent && received ? 'done_all' : undefined
        this.sent.set(sent);
        this.received.set(received);
        this.viewed.set(viewed);
        this.icon.set(icon);
        this.invalid = !icon;
        /*if (this.log() &&
            message.conversationId == 'group.66c35b15219e71130575085d') {
          console.log("MessageDeliveryStateComponent",message,conversationData,"viewed",viewed,"lastMessage",message == conversationData.lastMessage);
        }*/
      } else {
        this.sent.set(false);
        this.received.set(false);
        this.viewed.set(false);
        this.icon.set(undefined);
        this.invalid = true;
      }
    }, { allowSignalWrites: true });
  }

  now():number {
    return Date.now();
  }
}
