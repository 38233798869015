import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {ENVIRONMENT, Logger} from "core";
import {LayoutService} from "layout";
import {BasePageComponent, MenuService, PressDirective, PressEvent} from "shared";
import {TranslateService} from "@ngx-translate/core";
import {AgmMarker, MapsAPILoader} from "@agm/core";
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CalendarEvent} from "../../models/event";
import MarkerClusterer from '@google/markerclustererplus';
import {takeUntil, withLatestFrom} from "rxjs/operators";
import {MapsEventListener} from "@agm/core/services/google-maps-types";

export type LatLng = { lat: number, lng: number };
export type MapBounds = { northEast: LatLng, southWest: LatLng };
export interface MapPressEvent extends PressEvent {
  latLng: LatLng
}
/*
@Directive({
  selector: 'agm-manipulator',
})
export class AgmManipulator implements AfterViewInit, OnDestroy {

  @Input() map: AgmMap;
  @Input() center: { lat: number, lng: number };
  @Input() markers: Observable<google.maps.Marker[]>;

  @Output() selectionChanged = new EventEmitter<google.maps.Marker[]>();
  protected subscription = new Subscription();
  protected logger = new Logger('AgmManipulator');

  constructor(private wrapper: GoogleMapsAPIWrapper,
              protected propertiesService: PropertiesService,
              @Inject(ENVIRONMENT) protected environment: any) {
    // this.logger.debug('AgmManipulator.ctor()');
  }

  ngAfterViewInit() {
    this.wrapper.getNativeMap()
      .then(map => {
        // all google map events here: https://developers.google.com/maps/documentation/javascript/events
        this.initializeMap(map);
        const markerClusterer = this.initializeMarkerCluster(map);
        this.subscription.add(
          this.markers.subscribe((markers) => {
            markers?.forEach(marker =>
              marker.addListener('click', (event) => {
                this.logger.debug('MARKER click', event);
                this.selectionChanged.emit([ marker ]);
              }));
            this.logger.debug('MARKERS', markers);
            markerClusterer.clearMarkers();
            markerClusterer.addMarkers(markers);
          })
        );
      })
      .catch(error => this.logger.debug(error));
    // this.subscription.add(
    //   this.map.boundsChange.subscribe((bounds: google.maps.LatLngBounds) => {
    //     this.logger.debug('angular bounds', bounds);
    //     this.wrapper.getBounds()
    //       .then(bounds => this.logger.debug('wrapper bounds', bounds))
    //       .catch(error => this.logger.error(error));
    //   })
    // );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private initializeMap(map: google.maps.Map) {
    const options: google.maps.MapOptions = {
      backgroundColor: this.environment.primaryColor,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      zoom: 5,
      // zoomControlOptions: {
      //   position: google.maps.ControlPosition.TOP_RIGHT,
      //   style: google.maps.ZoomControlStyle.SMALL
      // },
      fullscreenControl: false,
      streetViewControl: false
    };
    const user = this.propertiesService.user;
    if (user) {
      const latLng = {...(user ? { lat: user.latitude, lng: user.longitude } : {}), ...this.center };
      options.center = new google.maps.LatLng(latLng.lat, latLng.lng);
    }
    map.setOptions(options);
    map.addListener('click', (event: any) => {
      // map.panTo(event.latLng);
      // this.wrapper.getNativeMap()
      //   .then(map => {
      //     this.logger.debug('MAP', map);
      //     new (window as any).google.maps.Marker({
      //       position: { lat: marker.lat, lng: marker.lng },
      //       map: map,
      //       icon: marker.icon,
      //       title: marker.label
      //     });
      //   })
      //   .catch(error => this.logger.error(error));
    });
    map.addListener('bounds_changed', () => {
      this.logger.debug('native bounds', map.getBounds());
    });
    map.addListener('tilesloaded', () => {
      const polygonCoordinates = [
        {lat: 25.774, lng: -80.190},
        {lat: 18.466, lng: -66.118},
        {lat: 32.321, lng: -64.757},
        {lat: 25.774, lng: -80.190}
      ];
      const polygon = new google.maps.Polygon({
        paths: polygonCoordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });
      // polygon.setMap(map);
    });
  }

  private initializeMarkerCluster(map: google.maps.Map): MarkerClusterer {
    // bypass agm wrapper for marker cluster setup and use directly markerclusterplus lib api
    // this makes possible to receive the marker cluster instance when the cluster is clicked
    // agm does not currently pass this reference which is a known issue.
    // see https://github.com/SebastianM/angular-google-maps/issues/1564)
    const markerClusterer = new MarkerClusterer(map, [], {
      zoomOnClick: false,
      averageCenter: true,
      imagePath: '/assets/images/m'
    });
    google.maps.event.addListener(markerClusterer, "click", (cluster) => {
      this.logger.debug("CLUSTER click!", cluster);
      this.logger.debug("Center of cluster: " + cluster.getCenter());
      this.logger.debug("Number of managed markers in cluster: " + cluster.getSize());
      const markers = cluster.getMarkers();
      const positions = [];
      for (let i = 0; i < markers.length; i++) {
        positions.push(markers[i].getPosition());
      }
      this.logger.debug("Locations of managed markers: " + positions.join(", "));
      this.selectionChanged.emit(markers)
    });
    google.maps.event.addListener(markerClusterer, "mouseover", (cluster) => {
      this.logger.debug("CLUSTER mouseover!");
      this.logger.debug("Center of cluster: " + cluster.getCenter());
      this.logger.debug("Number of managed markers in cluster: " + cluster.getSize());
    });
    google.maps.event.addListener(markerClusterer, "mouseout", (cluster) => {
      this.logger.debug("CLUSTER mouseout!");
      this.logger.debug("Center of cluster: " + cluster.getCenter());
      this.logger.debug("Number of managed markers in cluster: " + cluster.getSize());
    });
    return markerClusterer;
  }
}
*/

@Component({
  selector: 'app-event-map',
  templateUrl: './event-map.component.html',
  styleUrls: ['./event-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventMapComponent extends BasePageComponent {

  @Input() center: LatLng;
  @Input() zoom = 5;
  @Output() boundsChanged = new EventEmitter<MapBounds>();
  @Output() zoomLevelChanged = new EventEmitter<number>();
  @Output() selectionChanged = new EventEmitter<CalendarEvent[]>();
  @Output() eventMoved = new EventEmitter<{event: CalendarEvent, latLng: LatLng}>();
  @Output() mapClick = new EventEmitter<LatLng>();
  @Output() mapPress = new EventEmitter<MapPressEvent>();
  // @ViewChild('map', { static: true }) map: AgmMap;
  // @ViewChild('markerCluster') markerCluster: AgmMarkerCluster;
  @ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;

  protected map$ = new ReplaySubject<google.maps.Map>(1);
  protected map: google.maps.Map;

  protected centerSubscription: Subscription;
  protected eventsSubscription: Subscription;
  protected draftEventSubscription: Subscription;
  protected _events: CalendarEvent[];

  protected markerClusterer: MarkerClusterer;
  protected markers = new BehaviorSubject([] as google.maps.Marker[]);
  protected draftMarker: google.maps.Marker;

  protected static mapsApiLoaded = false;
  protected static markerlLabelColors: { [mapTypeId in google.maps.MapTypeId]: string };
  // protected static GOOGLE_MAPS_KEY = 'AIzaSyDGSn4b6teaUQHoOG1oy8j28RKfkBMdzgs';
  // protected static GOOGLE_MAPS_SRC = `https://maps.googleapis.com/maps/api/js?v=quarterly&callback=agmLazyMapsAPILoader&key=${EventMapComponent.GOOGLE_MAPS_KEY}&libraries=visualization,places,drawing,geometry`;

  // Marker SVG Path: https://material.io/tools/icons/?icon=place&style=baseline
  protected static MARKER_PATH = 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z';
  // protected static MAP_MARKER = "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
  // protected static MAP_MARKER = google.maps.SymbolPath.CIRCLE,
  protected static MARKER_DRAFT_PATH = 'M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm4 8h-3v3h-2v-3H8V8h3V5h2v3h3v2z';

  protected logger = new Logger('EventMapComponent').setSilent(false);
  protected loggerDraft = new Logger('EventMapComponent.DRAFT').setSilent(false);

  constructor(public    layoutService: LayoutService,
              protected menuService: MenuService,
              protected translateService: TranslateService,
              protected httpClient: HttpClient,
              protected elementRef: ElementRef,
              protected mapsApiLoader: MapsAPILoader,
              @Inject(ENVIRONMENT) protected environment: any) {
    super(layoutService, menuService, translateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.map$.pipe(takeUntil(this.onDestroy$)).subscribe(map => this.map = map);
    const mapsApiLoaded = EventMapComponent.mapsApiLoaded || typeof google === 'object' && typeof google.maps === 'object';
    const mapsApiLoadPromise = !mapsApiLoaded
      ? this.mapsApiLoader.load().then(() => { /* loadScript(document, EventMapComponent.GOOGLE_MAPS_SRC) */
          // if (!EventMapComponent.mapsApiLoaded) {
          //   EventMapComponent.markerlLabelColors = {
          //     [google.maps.MapTypeId.TERRAIN]: 'black',
          //     [google.maps.MapTypeId.ROADMAP]: 'black',
          //     [google.maps.MapTypeId.HYBRID]: 'white',
          //     [google.maps.MapTypeId.SATELLITE]: 'white',
          //   };
          //   EventMapComponent.mapsApiLoaded = true;
          // }
        })
      : Promise.resolve();
    mapsApiLoadPromise
      .then(() => {
        if (!EventMapComponent.mapsApiLoaded) {
          EventMapComponent.markerlLabelColors = {
            [google.maps.MapTypeId.TERRAIN]: 'black',
            [google.maps.MapTypeId.ROADMAP]: 'black',
            [google.maps.MapTypeId.HYBRID]: 'white',
            [google.maps.MapTypeId.SATELLITE]: 'white',
          };
          EventMapComponent.mapsApiLoaded = true;
        }
        const centerPromise = this.center
          ? Promise.resolve(this.center)
          : typeof navigator.geolocation?.getCurrentPosition=='function'
              ? new Promise((resolve, reject) =>
                  reject('Geolocation API asks for permissions. Consider negative user impact before enabling the following code')
                  // navigator.geolocation.getCurrentPosition((position) => {
                  //   if (position?.coords) {
                  //     resolve ({ lat: position.coords.latitude, lng: position.coords.longitude });
                  //   } else {
                  //     reject('Failed to retrieve user coordinates');
                  //   }
                  // })
              )
              : Promise.reject('Geolocation API not available')

          return centerPromise.catch((error) => {
            this.logger.error(error);
            // Vienna for now
            return { lat: 48.210033 , lng: 16.363449};
          }).then((center) => {
            return { center, zoom: this.zoom };
          }).then((options: {center: LatLng, zoom: number}) => {
            Object.keys(options).forEach(key => options[key]==undefined && delete options[key]);
            return this.createMap(this.mapContainer, options);
          })
      })
      .then((map) => {
        this.markerClusterer = this.createMarkerCluster(map);
        this.markers
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((markers) => {
            this.logger.debug('MARKERS', markers);
            this.markerClusterer.clearMarkers();
            this.markerClusterer.addMarkers(markers);
          });
        this.map$.next(map);
        this.map$.complete();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    const clearListeners = google.maps.event.clearInstanceListeners;
    this.map$.toPromise().then(map => map && clearListeners(map));
    this.map$.complete();
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers(); //this.markerClusterer.getMarkers()?.forEach(marker => clearListeners(marker));
      clearListeners(this.markerClusterer);
    }
  }

  // @Input() set center(center: LatLng) {
  //   this.centerSubscription?.unsubscribe();
  //   this.centerSubscription = this.map$
  //     .pipe(
  //       withLatestFrom(of(center)),
  //       takeUntil(this.onDestroy$)
  //     )
  //     .subscribe(([map, center]) => {
  //       map.setCenter(center);
  //     });
  // }

  @Input()
  set events(events: Observable<CalendarEvent[]>) {
    this.logger.debug('SET EVENTS!');
      // concat(this.map$, events).pipe(
      //   skip(1),
      //   takeUntil(this.onDestroy$),
      //   filter((events) => !isEqual(events, this._events))
      // )
      this.map$.toPromise().then(map => {
        this.logger.debug('MAP', map);
        this.eventsSubscription?.unsubscribe();
        this.eventsSubscription = events.pipe(
          withLatestFrom(this.map$),
          takeUntil(this.onDestroy$)
        // filter((events) => !isEqual(events, this._events))tap(events => this.logger.debug('EVENTS CHANGED')))
        ).subscribe(([events, map] : [CalendarEvent[], google.maps.Map]) => {
          this.logger.debug('UPDATING EVENT MARKERS...', events);
          this._events = events;
          const markers = [];
          events?.filter(event => !!event).forEach(event => {
            // event will be undefined when the corresponding segment is not loaded yet
            // attempt to access a non existing element will trigger server request to retrieve the missing data
            // when the corresponding response is received the store will be updated and new events will be fed into the map
            const marker = this.createMarker(map.getMapTypeId(), event);
            marker.addListener('click', (event) => {
              this.logger.debug('MARKER click', event);
              this.selectionChanged.emit([(marker as any).event]);
            });
            markers.push(marker);
          });
          if (this.draftMarker) {
            markers.push(this.draftMarker);
          }
          this.markers.next(markers);
        });
      })
  }

  @Input()
  set draftEvent(event: Observable<CalendarEvent>) {
    this.loggerDraft.debug('SET draftEvent');
    this.map$.toPromise().then(map => {
      this.draftEventSubscription?.unsubscribe();
      this.draftEventSubscription = event?.pipe(
        takeUntil(this.onDestroy$),
        withLatestFrom(this.map$),
      ).subscribe(([draftEvent, map]: [CalendarEvent, google.maps.Map]) => {
        this.loggerDraft.debug('UPDATING DRAFT EVENT MARKER', draftEvent);
        if (draftEvent) {
          if (!this.draftMarker) {
            this.draftMarker = this.createMarker(map.getMapTypeId(), draftEvent);
            this.draftMarker.setIcon(this.markerIcon(true));
            this.draftMarker.setDraggable(true);
            // marker.setAnimation(google.maps.Animation.DROP);
            this.draftMarker.addListener('click', (event) => {
              this.loggerDraft.debug('MARKER click', event);
              this.selectionChanged.emit([draftEvent]);
            });
            const dragEventHandler = (event: google.maps.MouseEvent) => {
              this.loggerDraft.debug('MARKER drag', event);
              this.eventMoved.emit({
                event: this.draftMarker['event'],
                latLng: { lat: event.latLng.lat(), lng: event.latLng.lng() }
              });
            };
            this.draftMarker.addListener('drag', dragEventHandler);
            // this.draftMarker.addListener('dragend', dragEventHandler);
            this.markerClusterer.addMarker(this.draftMarker);
          } else {
            /* Perform in-place draft marker update instead of add/remove to avoid flicker effects */
            // https://groups.google.com/g/google-maps-js-api-v3/c/kspTUjVcFsY?pli=1
            this.updateMarker(this.draftMarker, draftEvent, map.getMapTypeId());
            // this.markerClusterer.repaint();
          }
        } else if (this.draftMarker) {
          this.markerClusterer.removeMarker(this.draftMarker);
        }

        // if (this.draftMarker) {
        //   this.markerClusterer.removeMarker(this.draftMarker);
        // }
        // if (draftEvent) {
        //   this.draftMarker = this.createMarker(map.getMapTypeId(), draftEvent);
        //   this.draftMarker.addListener('click', (event) => {
        //     this.loggerDraft.debug('MARKER click', event);
        //     this.selectionChanged.emit([draftEvent]);
        //   });
        //   this.draftMarker.addListener('drag', (event) => {
        //     this.loggerDraft.debug('MARKER drag', event);
        //     this.selectionChanged.emit([draftEvent]);
        //   });
        //   this.draftMarker.addListener('dragend', (event) => {
        //     this.loggerDraft.debug('MARKER drag', event);
        //     this.selectionChanged.emit([draftEvent]);
        //   });
        //   this.markerClusterer.addMarker(this.draftMarker);
        // }
      });
    })
  }

  protected createMap(container: ElementRef, options: Partial<google.maps.MapOptions>): Promise<google.maps.Map> {
    // Mercator projection inflates the size of objects away from the equator which
    // accelerates with increasing latitude to become infinite at the poles.
    // For this reason the map must be truncated at some latitude less than ninety degrees.
    // Much Web-based mapping uses a zoomable version of the Mercator projection with an aspect ratio of one.
    // In this case the maximum latitude attained must correspond to y = ± W/2, or equivalently y/R= π.
    // Any of the inverse transformation formulae may be used to calculate the corresponding latitudes, giving φmax = ±85.05113°.
    // see https://en.wikipedia.org/wiki/Mercator_projection#Truncation_and_aspect_ratio
    const maxLatitude = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI; // inverse transformation formulae for map aspect ratio = 1 (de facto standard for web based mapping)
    const defaultOptions: Partial<google.maps.MapOptions> = {
      backgroundColor: 'transparent',//this.environment.primaryColor,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      // zoom: 5,
      // zoomControlOptions: {
      //   position: google.maps.ControlPosition.TOP_RIGHT,
      //   style: google.maps.ZoomControlStyle.SMALL
      // },
      fullscreenControl: false,
      streetViewControl: false,
      restriction: {
        latLngBounds: { north: maxLatitude, south: -maxLatitude, west: -180, east: 180 },
        strictBounds: true
      },
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM, // ControlPosition.BOTTOM_LEFT
        style: google.maps.ZoomControlStyle.SMALL
      }
    };
    options = { ...defaultOptions, ...options };
    const map = new google.maps.Map(container.nativeElement, options);
    map.addListener('click', (event: any) => {
      const latLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      this.logger.debug('click', event, latLng);
      this.mapClick.emit(latLng);
      // map.panTo(event.latLng);
      // this.wrapper.getNativeMap()
      //   .then(map => {
      //     this.logger.debug('MAP', map);
      //     new (window as any).google.maps.Marker({
      //       position: { lat: marker.lat, lng: marker.lng },
      //       map: map,
      //       icon: marker.icon,
      //       title: marker.label
      //     });
      //   })
      //   .catch(error => this.logger.error(error));
    });

    let latLng: LatLng;
    const eventListeners: {[key: string]: [Function, MapsEventListener][]} = {};
    const press = new PressDirective({ nativeElement: {
        addEventListener: function (type: string, listener: any, options?: any) {
          // map.addListener(type, listener)
          let listeners: [Function, MapsEventListener][] = eventListeners[type];
          const entry: [Function, MapsEventListener] = listeners?.find(entry => entry[0]==listener);
          if (!entry) {
            const mapsEventListener = map.addListener(type, (event) => listener(event.domEvent));
            !listeners && (eventListeners['event'] = listeners = []);
            listeners.push([listener, mapsEventListener]);
          }
        }.bind(this),
        removeEventListener: function (type: string, listener: any, options?: any) {
          let listeners: [Function, MapsEventListener][] = eventListeners[type];
          const index: number = listeners?.findIndex(entry => entry[0]==listener);
          if (index) {
            const entry: [Function, MapsEventListener] = listeners[index]
            entry[1]?.remove();
            listeners.splice(index, 1);
            if (!listener.length) {
              delete eventListeners[type];
            }
          }
        }.bind(this)
      }});
    press.pressTriggerTime = 1000;
    press.onPress.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      this.logger.debug('ON PRESS', { latLng, ...event });
      // if we know the coordinates we can also use:
      // map.getProjection().fromPointToLatLng(new google.maps.Point(x, y))
      this.mapPress.emit({ latLng, ...event });
    });
    map.addListener('mousemove', (event) => {
      latLng =  { lat: event.latLng.lat(), lng: event.latLng.lng() };
    });
    map.addListener('bounds_changed', () => {
      const bounds =  map.getBounds();
      // this.logger.debug('native bounds', bounds);
      this.boundsChanged.emit({
        northEast: bounds.getNorthEast().toJSON(),
        southWest: bounds.getSouthWest().toJSON()
      });
    });
    map.addListener('zoom_changed', () => {
      const zoomLevel = map.getZoom();
      this.logger.debug('native zoomLevel', zoomLevel);
      this.zoomLevelChanged.emit(zoomLevel);
    });
    map.addListener('tilesloaded', () => {
      this.logger.debug('tilesloaded', 'bounds', map.getBounds(), 'zoom', map.getZoom());
      const polygonCoordinates = [
        {lat: 25.774, lng: -80.190},
        {lat: 18.466, lng: -66.118},
        {lat: 32.321, lng: -64.757},
        {lat: 25.774, lng: -80.190}
      ];
      const polygon = new google.maps.Polygon({
        paths: polygonCoordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });
      // polygon.setMap(map);
    });
    map.addListener('maptypeid_changed', () => {
      const markers = this.markerClusterer.getMarkers();
      if (markers?.length) {
        const mapTypeId = map.getMapTypeId();
        this.markerClusterer.getMarkers().forEach(marker => {
          const label = marker.getLabel();
          if (label) {
            label.color = EventMapComponent.markerlLabelColors[mapTypeId];
            marker.setLabel(label);
          }
        });
      }
    });
    return new Promise((resolve, reject) => {
      google.maps.event.addListenerOnce(map, 'idle', function(){
        resolve(map);
      });
    })
  }

  protected createMarkerCluster(map: google.maps.Map): MarkerClusterer {
    // bypass agm wrapper for marker cluster setup and use directly markerclusterplus lib api
    // this makes possible to receive the marker cluster instance when the cluster is clicked
    // agm does not currently pass this reference which is a known issue.
    // see https://github.com/SebastianM/angular-google-maps/issues/1564)
    const markerClusterer = new MarkerClusterer(map, [], {
      zoomOnClick: false, // click displays markers in sidenav list
      averageCenter: true,
      imagePath: '/assets/images/m',
      ignoreHidden: true,
    });
    google.maps.event.addListener(markerClusterer, "click", (cluster) => {
      this.logger.debug("CLUSTER click!", cluster);
      this.logger.debug("Center of cluster: " + cluster.getCenter());
      this.logger.debug("Number of managed markers in cluster: " + cluster.getSize());
      const markers = cluster.getMarkers();
      const positions = [];
      for (let i = 0; i < markers.length; i++) {
        positions.push(markers[i].getPosition());
      }
      // this.logger.debug("Locations of managed markers: " + positions.join(", "));
      markers.length > 0 && this.selectionChanged.emit(markers.map(marker => marker.event))
    });
    google.maps.event.addListener(markerClusterer, "mouseover", (cluster) => {
      // this.logger.debug("CLUSTER mouseover!");
      // this.logger.debug("Center of cluster: " + cluster.getCenter());
      // this.logger.debug("Number of managed markers in cluster: " + cluster.getSize());
    });
    google.maps.event.addListener(markerClusterer, "mouseout", (cluster) => {
      // this.logger.debug("CLUSTER mouseout!");
      // this.logger.debug("Center of cluster: " + cluster.getCenter());
      // this.logger.debug("Number of managed markers in cluster: " + cluster.getSize());
    });
    return markerClusterer;
  }

  protected createMarker(mapTypeId: google.maps.MapTypeId, event?: CalendarEvent): google.maps.Marker {
    let options: google.maps.ReadonlyMarkerOptions = {
      icon: this.markerIcon(),
      draggable: false
    };
    const marker: google.maps.Marker = new google.maps.Marker(options);
    if (event) {
      this.updateMarker(marker, event, mapTypeId);
    }
    return marker;
  }

  protected updateMarker(marker: google.maps.Marker, event: CalendarEvent, mapTypeId: google.maps.MapTypeId) {
    marker.setLabel(
          event.name
        ? { text: event.name,  color: EventMapComponent.markerlLabelColors[mapTypeId] }
        : null
    );
    marker.setPosition(new google.maps.LatLng(event.latitude, event.longitude));
    if (event.tags?.includes('artificial')) {
      marker.setOpacity(0.6);
    }
    marker['event'] = event;
  }

  protected markerIcon(draft = false):  google.maps.ReadonlySymbol {
    return {
      path: draft ? EventMapComponent.MARKER_DRAFT_PATH : EventMapComponent.MARKER_PATH,
      fillColor: draft ? 'rgba(233, 30, 99, 0.8)' : '#e91e63', // '#307897'
      fillOpacity: 1,
      strokeColor: 'hsla(51%, 47%, 291, 1)', // 'white', "#307897"
      strokeWeight: 0.5,
      scale: 1.4,
      rotation: 0,
      anchor: new google.maps.Point(12, 24),
      labelOrigin: new google.maps.Point(25, 0)
    };
  }

  onMapReady(map: google.maps.Map) {
    this.logger.debug('onMapReady', map);
  }

  onMapBoundsChange(bounds: google.maps.LatLngBounds) {
    this.logger.debug('onMapBoundsChange', bounds);
  }

  onMarkerClick(marker: google.maps.Marker, index: number, agmMarker: AgmMarker) {
    this.logger.debug('onMarkerClick', marker);
  }

  onMarkerDragEnd(marker: google.maps.Marker, index: number, event: google.maps.MouseEvent) {
    this.logger.debug('onMarkerDragEnd', marker);
  }

  onMapClick(event: any) {
    this.logger.debug('onMapClick', event);
    const marker = { ...this.markers[0], lat: event.coords.lat, lng: event.coords.lng };
    this.logger.debug('marker', marker);
    this.httpClient
      .post('/v1.0/maps', (({ lat, lng }) => ({ lat, lng }))(marker))
      .subscribe((result: any) => {
        this.logger.debug(result);
        if (result && result.contained) {
          marker.icon = { ...marker.icon, image: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png' };
        }
        const markers = this.markers.getValue();
        this.markers.next(markers.push.apply(markers, marker));
      });
  }

  onClusterClick(cluster: any) {
    // this.logger.debug('onClusterClick', cluster);
    // this.logger.debug(cluster.getMarkers().length);
  }

  onSelectionChanged(markers: google.maps.Marker[]) {
    this.logger.debug('onSelectionChanged', markers);
    this.selectionChanged.emit(markers.map(marker => (marker as any).event));
  }
}
