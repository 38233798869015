import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {TaskEffects} from "./task/effects";
import {TaskListEffects} from "./task-list/effects";

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([TaskListEffects, TaskEffects])
  ]
})
export class TaskStoreModule {}
