import {Challenge} from "core";
import {Attachment, Message, Participant, ParticipantRole} from "messaging";
import {Media} from "media";

/**
 * PREREQUISIT FOR THE CHAT:
 * ChatTimelineMessages are sorted by timeCreated only.
 *
 * ChatConversationsState and
 * ChatConversationState ....
 *      both are filterable (and searchable). if a filter is applied to ChatConversationsState
 *      all ChatConversationStates get unfiltered and if any conversation is viewed, that global
 *      filter is then applied.
 *      if a filter is applied directly to a conversation, the global filter gets wiped out.
 */

export const ChallengeUpdateMessageType : string = "challengeUpdate";
export interface ChallengeUpdateMessage extends Message {
  challenge: Challenge
}

export const VisibilityMessageType : string = "visibility";
export interface VisibilityMessage extends Message {
  visible:boolean;
}

/**
 * Base class for all messages regarding chat
 */
export interface ChatRelatedMessage extends Message {
}

/*
    TIME LINE RELATED
 */
export interface ChatTimelineMessage extends ChatRelatedMessage {
  _id?: string;           // persistent id (so we know it is stored on server...
  type: MessageType;      // chat timeline messages have a specific type...
  filterId?: string;      // optional filter id
  timeDeleted?: number;
  silent?: boolean;
  draft?: boolean;        // is this a draft message only?
  private?: boolean;      // undefined or false if not private, else true (e.g. note message)
  persistent?: boolean;   // CreatedMessage and draft messages are no persistent
  pending?: boolean;      // Sent to server, but no ack right now...
  tags?: string[];        // timeline messages can be tagged
}

export const MediaAttachmentType : string = "media";
export interface MediaAttachment extends Attachment {
  media: Media;
}

export interface AudioRecordingData {
  data?: string;
  wave?: string;
}

export const AudioRecordingAttachmentType : string = "audioRecording";
export interface AudioRecordingAttachment extends Attachment {
  duration:number;
  data: string;
  wave: string;
}

export const CreatedMessageType : string = "created";
export interface CreatedMessage extends ChatTimelineMessage {
}

export interface ChatParticipationsMessage extends ChatTimelineMessage {
  participants : {[key:string]:Participant};
}
export const ChatParticipationsChangeMessageType : string = "participationsChange";
export interface ChatParticipationsChangeMessage extends ChatParticipationsMessage {
}
export const ChatParticipationsAddMessageType : string = "participationsAdd";
export interface ChatParticipationsAddMessage extends ChatParticipationsMessage {
}
export const ChatParticipationsRemoveMessageType : string = "participationsRemove";
export interface ChatParticipationsRemoveMessage extends ChatParticipationsMessage {
}

export const ChatNoteMessageType : MessageType = "note";
export interface ChatNoteMessage extends ChatTimelineMessage {
}

export const CallStatusMessageType : string = "call";
export interface CallStatusMessage extends ChatTimelineMessage {
  conferenceId:string;   // unique conference id!
  participants:Participant[];
  timeScheduled?:number;
  timeStarted?:number;
  timeEnded?:number;
}

export const ENCRYPTION_INFO_TAG = "encryption";
export const SUPPORT_INFO_TAG = "support";
export const TRANSLATE_TAG  = "translate";
export const ChatInfoMessageType : MessageType = "info";
export interface ChatInfoMessage extends ChatTimelineMessage {
}

export const ChatMessageType : MessageType = "chat";
export interface ChatMessage extends ChatTimelineMessage {
}

export const CreateMessageType : MessageType = "create";
export interface CreateMessage extends ChatTimelineMessage {
}

/*
    STATUS RELATED
 */
export interface DeliveryParticipant extends Participant {
  timeReceived:number;
  timeViewed:number;
}

export const DeliveryMessageType : string = "delivery";
export interface DeliveryMessage extends ChatRelatedMessage {
  // client -> server: report the timeReceived and/or timeViewed
  // server -> client: only in case the client subscribed to
  //                   participants delivery....
  from?: DeliveryParticipant;
  // these both are only delivered from server to client and
  // show the general delivery state of the whole conversation
  timeReceived:number;
  timeViewed:number;
}

export const TypingMessageType : string = "typing";
export interface TypingMessage extends ChatRelatedMessage {
  typing: boolean;
  targetType?: string;
}

export const ReactionMessageType : string = "reaction";
export interface ReactionMessage extends ChatRelatedMessage {
  reaction: string;
}

export interface ChatControlMessage extends ChatRelatedMessage {
  // THESE FIELDS ARE TRANSIENT AND SET ON DELIVERY ONLY, SPECIFIC TO CURRENT USER!!
  filterId?: string; // current applied filter id under which this message is delivered
}

export const ConversationMuteMessageType : string = "conversationMute";
export interface ConversationMuteMessage extends ChatControlMessage {
  timeMuted?: number;   // time when set to muted...
}

export const ConversationsLoadMessageType : string = "conversationsLoad";
export interface ConversationsLoadMessage extends ChatControlMessage {
  filter?: ConversationFilter;
  newestMessageVersion: number;
}

export const ConversationsLoadResultMessageType : string = "conversationsLoadResult";
export interface ConversationsLoadResultMessage extends ChatControlMessage {
  filter?: ConversationFilter;
  newestVersion: number;
  oldestVersion: number;
  newestMessageVersion: number;
  hash: number;     // filtered hash, or total hash if no filter
  size: number;     // filtered size, or total size if no filter
  error?: string;
}

export const ConversationCreateOrUpdateMessageType : string = "conversationCreateOrUpdate";
export interface ConversationCreateOrUpdateMessage extends ChatControlMessage {
  conversationId?: string;    // the current conversation
  conversationType: string;   // 'direct', 'group', ...
  conversationName?: string;  // group chat: name
  conversationTerm?: string;  // group chat: term (which participants)
  canSendRole?:ParticipantRole; // role needed to send messages. default is none
  canReactRole?:ParticipantRole;// role needed to react (emoji). default is none
  resourceId?: string;        // for resource chats / remarks...
  participantIds?: string[];  // group chat: ids of all participants if no term..
  updatedIds?: string[];      // all participant ids which are updated...
  tags?: string[];            // tags of the conversation...
}

export const ConversationCreateOrUpdateResultMessageType : string = "conversationCreateOrUpdateResult";
export interface ConversationCreateOrUpdateResultMessage extends ConversationCreateOrUpdateMessage {
  errorLabel?:string;         // error label if not ok
}

export const ConversationFiltereMessageType : string = "conversationFilter";
export interface ConversationFiltereMessage extends ChatControlMessage {
  conversation: Partial<ConversationDefinition>, // the current conversation
  filter: ConversationFilter;       // undefined if unfiltered
  newestTime: number;               // backward from this time....
  segments: ConversationSegment[];  // all conversation segments... oldest firsts
}

export const ConversationFilterResultMessageType : string = "conversationFilterResult";
export interface ConversationFilterResultMessage extends ConversationFiltereMessage {
  conversation: ConversationDefinition, // the current conversation
  // segments content between request and result may differ!
  // if message is part of loaded messages and it is filter operation or segment is verified, we only get id and timeCreated...
  messages?: (ChatTimelineMessage|ChatTimelineMessageInfo)[];   // the requested (whole for unfilterd, partly for filtered) chunk of messages or message ids
  leadingSize: number;      // number of filtered messages before the segment
  trailingSize: number;     // number of filtered messages after the segment
}

export const ConversationSynchronizeMessageType : string = "conversationSynchronize";
export interface ConversationSynchronizeMessage extends ChatControlMessage {
  conversation: Partial<ConversationDefinition>, // the current conversation
  /**
   * current undefined, segments empty []:
   *                load newest segment and return all segments fully defined
   *                (server splits properly)
   *
   * on every conversations we provide all segment infos and get back all verified
   * segment infos. all which are loaded (segmentLoaded==true) and out of sync, will be
   * marked for synchronization (synchronize=true) and the sync should be requested for
   * such segments.
   *
   * current is specified with segmentLoaded==false:
   *                load this segment (all messages), update it's properties if
   *                necessary (e.g. checksum, size).
   *                update all segments fully defined...
   *
   * current is specified with segmentLoaded==true, but marked for synchronization:
   *                client must provide all ChatTimelineMessageInfos for all messages
   *                it has already (id,time,version). server responds with current
   *                fully specified and with all messages. all messages which are
   *                uptodate on client are provided only as ChatTimelineMessageInfos
   *                with id alone, saving packet space!
   */
  current?: Partial<ConversationSegmentData>; // partial segment must be specified
  segments: ConversationSegment[];     // all conversation segments... oldest first
}

export const ConversationSynchronizeResultMessageType : string = "conversationSynchronizeResult";
export interface ConversationSynchronizeResultMessage extends ConversationSynchronizeMessage {
  // segments & current content between request and result may differ!
  // server must ensure that segments are all ok or provide segments.
  // and those which are loaded (having checksum)
  // but out of sync being stripped of the checksum...
  conversation: ConversationDefinition, // the current conversation
  current:  ConversationSegmentData;   // partial segment must be specified (see comment)
  segments: ConversationSegment[];     // all conversation segments... oldest first
  timeReceived?: number;   // last received time (one, some or all participants ... depending on chat type)
  timeViewed?: number;     // last viewed time (one, some or all participants ... depending on chat type)
  timeSelfReceived?: number; // last received time by this user ...
  timeSelfViewed?: number;  // last viewed by this user....
}

/**
 * PERSISTENT AND STORE CONVERSATION DATA!
 *
 * if there are multiple messages with same timeCreated within a conversation,
 * all of them must be kept inside one segment.
 *
 * client requests synchronization with no segments specified.
 * server provides all segments with
 *      loaded: false (only newest/hot segment is transferred and true)
 *      synchronize: true (only newest/hot segment is transferred and false)
 *
 * client keeps all segments and updates only those segments which are loaded
 * on sending/receiving/deleting chat messages:
 *      newestMessageInfo
 *      oldestMessageInfo
 *      version
 *      size
 *      checksum
 *
 * only those
 */
export interface ConversationSegment {
  newestMessageInfo: Partial<ChatTimelineMessageInfo>; // id + time (=timeCreated) of newest message in segment to load
  oldestMessageInfo: Partial<ChatTimelineMessageInfo>; // id + time (=timeCreated) of oldest message in segment to load
  version: number;                // newest timeUpdated of any message in segment if oldestSegmentTime provided
  size: number;                   // size of the segment
  // these 3 properties belong together. only if loaded, we maintain a checksum
  loaded: boolean;                // is this segment loaded?
  checksum?: number;              // only if loaded!
  key?: string;                   // it has a key only if it is persistet, else only the other 2 fields
  // this flag only comes from server side on ConversationSynchronizeMessage result
  // and ConversationFiltereMessage result....
  synchronize?: boolean;          // only if loaded and out of sync, server tells client to sync this segment
}

// persistent segment chunk...
export interface ConversationSegmentData extends ConversationSegment {
  messages: (ChatTimelineMessage|ChatTimelineMessageInfo)[];  // the requested chunk of messages
}

export type ConversationType = 'direct'|'group'|'resource';
export type MessageType = 'chat'|'note'|'info'|'create'|'call';
export const PrivateMessageTypes = new Set<string>(ChatNoteMessageType);

export interface ConversationDefinition {
  type: ConversationType;
  id: string;
  name?: string;            // depending on type, it might not have a name
  term?: string;            // groups can have a term (formula who is participant)
  version: number;          // version of the conversation, needed for avatar...
}

export interface ConversationHeader extends ConversationDefinition {
  filterId?: string;        // if a filter is currently applied
  latest?: boolean;         // returned true on requesting all conversations latest message...
  timeMuted?: number;       // since when is this conversation muted? no muting if undefined..
  timeReceived?: number;    // last received time (one, some or all participants ... depending on chat type)
  timeViewed?: number;      // last viewed time (one, some or all participants ... depending on chat type)
  timeSelfReceived?: number;// last received time by this user ...
  timeSelfViewed?: number;  // last viewed by this user....
  groupId?: string;         // 2022-08-25 kht: added for chat groups as we now deliver all for 1 app!
  canSend?:boolean;         // can this user send messages?
  canReact?:boolean;        // can this user react on messages (emoji)
  unseen?: number;          // number of messages not yet viewed
}

// pesistent segment header...
// a conversation should always have at least the newest 10-20 message segment
export interface ConversationSegmentHeader {
  conversation: ConversationDefinition;
  // all segment infos infos (persisted or non persisted)
  segments: ConversationSegment[];
  // all messages of the last segment (header segment)
  messages: ChatTimelineMessage[];
}

/**
 * DYNAMIC AND STORE CONVERSATION DATA!
 *
 * filterId...            if a filter is applied. there can be only one filter
 *                        applied. either there is a global search and if i then
 *                        select a conversation it is filtered to global search
 *                        criteria, or it is local search within a conversation,
 *                        then the global filter is taken out.
 */
export interface ConversationData extends ConversationDefinition {
  segments: ConversationSegment[];        // last segment is newest and "head" (segmentKey==conversationId).
  timeSorted?: number;                    // either of lastMessage or draftMessage
  // timeCreated of Message is the time it is scheduled for sending
  lastMessage?: ChatTimelineMessage;      // last message according to timeCreated...
  //lastSharedMessage?: ChatTimelineMessage;// last message according to timeCreated and visibility to others (exclude notes etc)...
  filteredMessage?: ChatTimelineMessage;  // last filtered message accoring to timeCreated
  replyMessage?: ChatTimelineMessage;     // message to reply...
  draftMessage?: ChatTimelineMessage;
  typingMessages?: TypingMessage[];
  timeMuted?: number;       // since when is this conversation muted? no muting if undefined..
  timeVisible?: number;     // since when is this conversation visible? no visibility if undefined...
  timeQuiet?: number;       // quiet until... then unmuted...
  timeJoined?: number;      // since when is this user joined?
  timeReceived?: number;    // last received time (one, some or all participants ... depending on chat type)
  timeViewed?: number;      // last viewed time (one, some or all participants ... depending on chat type)
  timeSelfReceived?: number; // last received time by this user ...
  timeSelfViewed?: number;  // last viewed by this user....
  groupId?: string;         // 2022-08-25 kht: added for chat groups as we now deliver all for 1 app!
  canSend?:boolean;         // can this user send messages?
  canReact?:boolean;        // can this user react on messages (emoji)
  unseen?: number;          // number of messages not yet viewed
  temporary?: boolean,      // maybe for notes, but not loaded in chat conversations list....
}

export interface ConversationFilter {
  id: string;
  filters: string[];
  term: string;
}

export interface ChatTimelineMessageInfo {
  id: string;
  timeCreated?: number;  // equals timeCreated of ChatTimelineMessage
  timeUpdated?: number;  // equals timeUpdated of ChatTimelineMessage
}

export type RenderMode = 'bubble'|'reply'|'header';
export type RenderPosition = 'left'|'right'|'center';

export function MessageTimeUpdated(message:ChatTimelineMessage,defaultValue?:number):number {
  if (!!message) {
    return message.timeUpdated??message.timeCreated??defaultValue;
  }
  return defaultValue;
}
