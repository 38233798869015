import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';
import moment from 'moment';
import {
  combineLatest,
  fromEvent,
  merge,
  Observable,
  race,
  Subject,
  Subscriber,
  Subscription,
  takeUntil,
  timer
} from "rxjs";
import {auditTime, filter, map, scan, startWith, take, tap} from "rxjs/operators";
import {
  CalendarDateFormatter,
  CalendarEvent as Event, CalendarEventTitleFormatter,
  CalendarView,
  DateFormatterParams,
  DAYS_OF_WEEK
} from 'angular-calendar';
import {CalendarEvent, NULL_CALENDAR_EVENT} from "../../models/event";
import {ENVIRONMENT, Logger, Platform} from "core";
import {BasicComponent} from "shared";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {EventService} from "../../services/event.service";
import {CalendarService} from "../../services/calendar.service";
import {CalendarViewService, CalendarView as View} from "../../services/calendar.view.service";
import {WeekViewHourSegment} from 'calendar-utils';
import {DOCUMENT, formatDate} from "@angular/common";
import isEqual from "lodash/isEqual";

export interface Timeframe {
  timeFrom?: number,
  timeTo?: number
}

export class TimeframeSelectEvent {
  public timeframe: Timeframe;
  constructor(timeframe: Timeframe) {
    this.timeframe = timeframe;
  }
}

export class TimeframeSelectStartEvent extends TimeframeSelectEvent {
  private _isDefaultPrevented: boolean;
  constructor(timeframe: Timeframe) {
    super(timeframe);
  }

  public isDefaultPrevented() : boolean {
    return this._isDefaultPrevented;
  }

  public preventDefault() : void {
    this._isDefaultPrevented = true;
  }
}

export class TimeframeSelectUpdateEvent extends TimeframeSelectEvent {
}

export class TimeframeSelectEndEvent extends TimeframeSelectEvent {
}

// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale('en', {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0,
  },
});


function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale);
  }
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }
}

// https://mattlewis92.github.io/angular-calendar/#/disable-tooltips
@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  monthTooltip(event: Event, title: string): string {
    return;
  }

  weekTooltip(event: Event, title: string): string {
    return;
  }

  dayTooltip(event: Event, title: string): string {
    return;
  }
}

// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale('en', {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0
  },
});

@Directive({
  selector: '[calendarTimeframeSelector]'
})
export class CalendarTimeframeSelector  {
  constructor(public elementRef: ElementRef) {
  }
}

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent extends BasicComponent implements OnInit, OnDestroy {

  protected static CALENDAR_RESPONSIVE_BREAKPOINTS = {
    small: {
      breakpoint: '(max-width: 576px)',
      daysInWeek: 2,
      monthViewFontSize: '0.7em'
    },
    medium: {
      breakpoint: '(max-width: 768px)',
      daysInWeek: 3,
      monthViewFontSize: '0.8em'
    },
    large: {
      breakpoint: '(max-width: 960px)',
      daysInWeek: 5,
      monthViewFontSize: '0.9em'
    },
  };

  @Input() date: Date;
  @Input() locale = 'en';
  @Input() entities$: Observable<CalendarEvent[]>;
  @Input() draft$: Observable<CalendarEvent>;

  @Output() dayEventsSelect = new EventEmitter<{day:Date,events:CalendarEvent[]}>();
  @Output() daySelect = new EventEmitter<Date>();
  @Output() timeframeSelect = new EventEmitter<TimeframeSelectEvent>();
  @Output() eventSelectionChanged = new EventEmitter<{ index: number; event: CalendarEvent; }>();

  // @HostBinding('class.autoscrollY')
  protected autoScroll$ = new Subject<boolean>();
  protected timeframeSelectionBottom$ = new Subject<number>();

  @ViewChildren(CalendarTimeframeSelector) timeframeSelectors: QueryList<CalendarTimeframeSelector>;

  CalendarView = CalendarView;

  events: Event[] = [];
  daysInWeek = 7;
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  monthViewFontSize: string = null;
  refresh = new Subject<void>();
  view: View = this.calendarViewService.view;

  protected _date: Date = new Date();
  protected selectedTimeframe: Timeframe;
  protected eventsById: { [id: string]: { index: number; event: CalendarEvent; }} = {};
  protected subscription = new Subscription();
  protected logger = new Logger('CalendarComponent').setSilent(false);

  constructor(public eventService: EventService,
              public platform: Platform,
              public calendarViewService: CalendarViewService,
              private calendarService: CalendarService,
              private breakpointObserver: BreakpointObserver,
              private changeDetector: ChangeDetectorRef,
              private renderer: Renderer2,
              @Inject(NULL_CALENDAR_EVENT) protected nullCalendarEvent: CalendarEvent,
              @Inject(DOCUMENT) protected document: Document,
              @Inject(ENVIRONMENT) protected environment: any) {
    super();
  }

  ngOnInit(): void {
    const breakpoints = Object.values(CalendarComponent.CALENDAR_RESPONSIVE_BREAKPOINTS);
    this.breakpointObserver
      .observe(breakpoints.map(({ breakpoint }) => breakpoint))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state: BreakpointState) => {
        const foundBreakpoint = breakpoints.find(({ breakpoint }) => !!state.breakpoints[breakpoint]);
        if (foundBreakpoint) {
          this.daysInWeek = foundBreakpoint.daysInWeek;
          this.monthViewFontSize = foundBreakpoint.monthViewFontSize
        } else {
          this.daysInWeek = 7;
          this.monthViewFontSize = "1em";
        }
        this.changeDetector.markForCheck();
      });

    this.subscription.add(merge(
      this.entities$?.pipe(
        map(events => {
          this.logger.debug('EVENTS > RECEIVED', events);
          const last = this.events.slice(-1)[0];
          const draft = last?.meta?.draft ? last : undefined;
          this.eventsById = draft && this.eventsById[draft.id] ? {[draft.id]: this.eventsById[draft.id]} : {};
          return events?.reduceRight((result, calendarEvent, index) => {
            if (calendarEvent) {
              const event = this.transformCalendarEvent(calendarEvent, !calendarEvent.id);
              this.eventsById[event.id] = { index, event: calendarEvent };
              result.unshift(event);
            }
            return result;
          }, draft ? [draft] : [] as Event[])
        })
      ),
      this.draft$.pipe(map((draft) => {
        this.logger.debug('DRAFT > RECEIVED', draft);
        const events = [...this.events];
        const last = events.pop();
        if (last?.meta?.draft && !isEqual(last, draft)) {
          delete this.eventsById[last.id];
          if (draft) {
            events.splice(events.length, 0, this.transformCalendarEvent(draft, true));
            this.eventsById[draft.id] = {index: this.events.length - 1, event: draft};
          }
        } else {
          const append = last ? [last] : [];
          if (draft) {
            append.push(this.transformCalendarEvent(draft, true));
            this.eventsById[draft.id] = { index: (this.events.length - +!last), event: draft };
          }
          events.splice(events.length, 0, ...append);
        }
        return events;
      }))
    )
    .subscribe((events: Event[]) => {
        this.logger.debug('EVENTS >>>', events);
        this.events = events;
        this.changeDetector.detectChanges();
      })
    );

    const timeframeSelectors = this.timeframeSelectors.changes.pipe(startWith(this.timeframeSelectors));
    const scroller = ((): Observable<HTMLElement> => {
      const element$ = timeframeSelectors.pipe(map((selectors) => {
        this.logger.debug('SELECTORS', selectors);
        const element = selectors.first?.elementRef.nativeElement;
        return element?.querySelector('div.cal-week-view');
      }));
      let current: HTMLElement;
      const subscribers: Subscriber<HTMLElement>[] = [];
      let subscription: Subscription;
      return new Observable(subscriber => {
        subscribers.push(subscriber);
        if (subscribers?.length==1) {
          subscription = element$.subscribe(element => {
            current = element;
            subscribers.forEach(subscriber => subscriber.next(element));
          });
        } else {
          subscriber.next(current);
        }
        return () => {
          const index = subscribers.indexOf(subscriber);
          subscribers.splice(index, 1);
          if (!subscribers.length){
            subscription.unsubscribe();
            subscription = null;
          }
        }
      });
    })().pipe(filter(scroller => !!scroller));

    const autoScroll = this.autoScroll$.pipe(startWith(true));

    combineLatest([ scroller, this.timeframeSelectionBottom$, autoScroll ])
      .pipe(takeUntil(this.onDestroy$), auditTime(10))
      .subscribe(([scroller, timeframeSelectionBottom, autoScroll]: [any, number, boolean]) => {
          // this.logger.debug('SCROLLER', scroller, timeframeSelectionBottom, autoScroll);
          this.renderer.setStyle(scroller, 'overflow-y', autoScroll ? 'auto' : 'hidden');
          if (!autoScroll) {
            const scrollerBoundingRect = scroller.getBoundingClientRect();
            const scrollerBottom = scrollerBoundingRect.top + scrollerBoundingRect.height;
            const segmentHeight = 30;
            let scrollDelta = Math.max(0, timeframeSelectionBottom + segmentHeight - scrollerBottom);
            if (!scrollDelta) {
              const headerHeight = 50;
              const visibleScrollerTop = scrollerBoundingRect.top + headerHeight; // header is absolute positioned and covers the view
              scrollDelta = Math.min(timeframeSelectionBottom - visibleScrollerTop, 0);
            }
            scroller.scrollTop = Math.max(0, Math.min(scroller.scrollTop + scrollDelta, scroller.scrollHeight));
          }
    });

    this.calendarViewService.view$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(view => {
        this.view = view;
        this.changeDetector.detectChanges();
        this.refresh.next();
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onDayClicked(date: Date) {
    //this.daySelect.emit(date);
    const startTime = date.getTime();
    const endTime   = moment(startTime).add(24, "hours").toDate().getTime();
    const events    = Object.values(this.eventsById).filter((item)=>{
      return item.event.timeTo>startTime && item.event.timeFrom<endTime;
    }).map(item => item.event);

    this.dayEventsSelect.emit({ day: date, events });
  }

  onCalendarEventSelected(event: {event: Event; sourceEvent: MouseEvent | KeyboardEvent}) {
    const entry = this.eventsById[event.event?.id];
    if (entry?.event) {
      this.eventSelectionChanged.emit(this.eventsById[event.event?.id]);
    }
  }

  onSegmentMouseDown(segment: WeekViewHourSegment,  segmentElement: HTMLElement) {
    this.logger.debug('onSegmentMouseDown');
    this.startTimeframeSelection(segment, segmentElement);
  }

  /*
  onSegmentPressStart(segment: WeekViewHourSegment, segmentElement: HTMLElement, event: Event) {
    this.logger.debug('onSegmentPressEnd', this.selectedTimeframe);
    // this.selectedTimeframe = undefined;
  }

  onSegmentPressHold(segment: WeekViewHourSegment, segmentElement: HTMLElement, event: Event) {
    if (!this.selectedTimeframe) { //start only on first presshold event
      this.startTimeframeSelection(segment, segmentElement);
    }
  }
  */

  onSegmentTouchStart(segment: WeekViewHourSegment, segmentElement: HTMLElement, event: TouchEvent) {
    this.logger.debug('onSegmentTouchStart', {segment, segmentElement, event});
    const triggerMillis = 700; // start the timeframe selection if touchend (or mouseup) does not come in the next 'triggerMillis'
    const start = timer(triggerMillis);
    const stop = race(
      fromEvent(this.document, 'mouseup'),
      fromEvent(this.document, 'touchend')
    )
    start.pipe(takeUntil(stop)).subscribe(() => this.startTimeframeSelection(segment, segmentElement))
    stop.pipe(takeUntil(start), take(1)).subscribe((event) => { this.logger.debug('startTimeframeSelection -> STOP', event) });
  }

  protected startTimeframeSelection(segment: WeekViewHourSegment, segmentElement: HTMLElement) {
    this.selectedTimeframe = {
      timeFrom: segment.date.getTime(),
      //timeTo: moment(segment.date).add(30, 'minutes').toDate().getTime()
    };
    const timeframeSelectStartEvent = new TimeframeSelectStartEvent({...this.selectedTimeframe});
    this.timeframeSelect.emit(timeframeSelectStartEvent);
    if (!timeframeSelectStartEvent.isDefaultPrevented()) {
      this.autoScroll$.next(false);
      /*
      const initialize = function <T>(fn: (value) => void) {
        return (source: Observable<T>) =>
          // defer the creation of initialization/inner observable until there is a subscription to the source
          // and eliminate negative side-effects as execution context sharing between multiple subscriptions
          defer(() => {
            let initialized = false;
            return source.pipe(
              tap(value => {
                if (!initialized) {
                  fn(value);
                }
                initialized = true;
              })
            );
          });
      }
      */
      this.logger.debug('TIMEFRAME START')
      race(
        fromEvent(this.document, 'mousemove'),
        fromEvent(this.document, 'touchmove')
      )
        // fromEvent(this.document, 'mousemove')
        .pipe(
          scan((timeframe: Timeframe, moveEvent: MouseEvent | TouchEvent) => {
            // this.logger.debug('TIMEFRAME MOVE', moveEvent);
            const clientX = moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0]?.clientX;
            const clientY = moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0]?.clientY;
            const segmentRect = segmentElement.getBoundingClientRect();
            const minutesDiff = ceilToNearest(clientY - segmentRect.top, 30);
            const daysDiff = floorToNearest(clientX - segmentRect.left, segmentRect.width) / segmentRect.width;
            const dateTo = moment(segment.date).add(minutesDiff, 'minutes').add(daysDiff, 'days').toDate();
            timeframe.timeTo = dateTo.getTime();
            this.timeframeSelectionBottom$.next(clientY)
            return timeframe;
          }, {...this.selectedTimeframe}),
          filter(timeframe => {
            const endOfView = moment(this.date).add(7, 'days').toDate(); //endOf("week").toDate();
            const pass = timeframe.timeTo > segment.date.getTime() && timeframe.timeTo < endOfView.getTime();
            // this.logger.debug('TIMEFRAME FILTER', { segment, timeframe, endOfView, pass});
            return pass;
          }),
          takeUntil(race(
            fromEvent(this.document, 'mouseup'),
            fromEvent(this.document, 'touchend')
          ).pipe(tap((event) => this.logger.debug('TIMEFRAME STOP', event)))),
        )
        .subscribe({
          next: (timeframe) => {
            // this.logger.debug('TIMEFRAME UPDATE', timeframe);
            this.selectedTimeframe = timeframe;
            this.timeframeSelect.emit(new TimeframeSelectUpdateEvent({...this.selectedTimeframe}));
          },
          complete: () => {
            this.logger.debug('TIMEFRAME COMPLETE');
            this.timeframeSelect.emit(new TimeframeSelectEndEvent({...this.selectedTimeframe}));
            this.selectedTimeframe = undefined;
            this.autoScroll$.next(true);
          }
        });
    } else {
      this.selectedTimeframe = undefined;
    }
  }

  protected transformCalendarEvent(calendarEvent: CalendarEvent, draft = false): Event {
    const event: Event = calendarEvent ? {
      id: calendarEvent.id,
      start: new Date(calendarEvent.timeFrom),
      end: new Date(calendarEvent.timeTo),
      title: calendarEvent.name,
      color: {
        primary: calendarEvent.calendar?.color ? `#${calendarEvent.calendar?.color}` : 'var(--color-primary-700)',
        secondary: draft
          ? 'var(--color-primary-alpha-05)'
          : 'var(--color-primary-alpha-07)',
        secondaryText: 'white'
      },
      // actions?: EventAction[];
      allDay: false,
      cssClass: 'event',
      resizable: {
        beforeStart: false,
        afterEnd: false
      },
      draggable: false,
      meta: { draft }
    } : calendarEvent as unknown as Event
    return event;
  }
}
