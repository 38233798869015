/* angular:styles/component:css;41e089444ad09a5e77e1085fce87684f9c38c1d16c0d1dc3f42bbfb3f921c308;/Users/mbratoev/Project/fbgroup/client.ng/projects/chat/src/lib/components/attachment/core/attachment.square.container.ts */
:host > div {
  width: 100%;
}
:host {
  display: none;
}
:host.visible {
  display: unset;
}
/*# sourceMappingURL=attachment.square.container-6N3MPJ3Q.css.map */
