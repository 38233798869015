import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output, signal,
  SimpleChanges, TemplateRef
} from '@angular/core';
import {BasicContainerComponent, enterLeaveAnimations} from "shared";
import {PropertiesService} from "properties";
import {Logger, themeMode$, Topic} from "core";
import {ReplaySubject, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {
  PersonalityColor,
  PersonalityColors,
  PersonalityColorsComponent,
} from "./personality.colors.component";
import {InterestsService} from "../../../../interests.service";

export declare type PersonalityResult = {
  colors  : PersonalityColors,
  ready   : boolean;
  display : boolean;
};

@Component({
  selector: 'personality-result',
  templateUrl: './personality.result.component.html',
  styleUrls: ['./personality.result.component.scss'],
  animations: [
    enterLeaveAnimations
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyPersonalityResultComponent extends BasicContainerComponent implements OnChanges {

  @Input() survey: Topic;
  @Input() selectedTags: string[];
  @Input() displayTag: string;
  @Input() missingResultTemplate: TemplateRef<any>;

  protected _badgeData:string = undefined;
  @Input()
  set badgeData(badgeData:string) {
    if (this._badgeData!=badgeData) {
      if (!!badgeData) {
        const params = badgeData.match(PersonalityColorsComponent.pattern);
        this._badgeData = (params?.length == 5) ? badgeData : undefined;
      } else {
        this._badgeData = undefined;
      }
    }
  }

  get badgeData():string {
    return this._badgeData;
  }

  @Input() readOnly = false;
  @Input() defaultInfo = false;

  @Output() onReady = new EventEmitter<boolean>();
  @Output() onDisplay = new EventEmitter<boolean>();

  opened: PersonalityColor = undefined;
  result = signal<PersonalityResult>(undefined);

  protected logger = new Logger('SurveyPersonalityResultComponent');

  constructor(protected propertiesService: PropertiesService,
              protected translateService: TranslateService,
              protected interestsService: InterestsService,
              protected changeDetector: ChangeDetectorRef,
              protected dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    themeMode$.pipe(takeUntil(this.onDestroy$)).subscribe(mode=>{
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
    this.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.update();
  }

  protected update() {
    const previous = this.result();
    const result = this.interestsService.personalityResult(
      this.survey,
      this.selectedTags,
      this.displayTag,
      this.badgeData
    );
    const {ready, display} = {...result};
    if (previous?.ready != ready) this.onReady.emit(ready);
    if (previous?.display != display) this.onDisplay.emit(display);
    this.result.set(result);
  }

  toggle(color: PersonalityColor) {
    if (this.opened != color) {
      this.opened = color;
    } else {
      this.opened = undefined;
    }
  }
}
