import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges, TemplateRef
} from '@angular/core';
import {BasicContainerComponent, enterLeaveAnimations} from "shared";
import {Logger, Topic} from "core";
import {VitalityImagesComponent, VitalityTypes} from "./vitality.images.component";
import {InterestsService} from "../../../../interests.service";

export declare type VitalityResult = {
  vitality: VitalityTypes;
  ready   : boolean;
  display : boolean;
};

@Component({
  selector: 'vitality-result',
  templateUrl: './vitality.result.component.html',
  styleUrls: ['./vitality.result.component.scss'],
  animations: [
    enterLeaveAnimations
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyVitalityResultComponent extends BasicContainerComponent implements OnChanges {

  @Input() survey: Topic;
  @Input() selectedTags: string[];
  @Input() displayTag: string;
  @Input() readOnly = false;
  @Input() defaultInfo = false;
  @Input() missingResultTemplate: TemplateRef<any>;

  @Output() onReady = new EventEmitter<boolean>();
  @Output() onDisplay = new EventEmitter<boolean>();

  result = signal<VitalityResult>(undefined);

  protected _badgeData:string = undefined;
  @Input()
  set badgeData(badgeData:string) {
    //console.log("SURVEY.DATA.badge.set",badgeData);
    if (this._badgeData!=badgeData) {
      if (!!badgeData) {
        const params = badgeData.match(VitalityImagesComponent.pattern);
        this._badgeData = (params?.length == 4) ? badgeData : undefined;
      } else {
        this._badgeData = undefined;
      }
    }
  }
  get badgeData():string {
    return this._badgeData;
  }

  protected logger = new Logger('SurveyVitalityResultComponent');

  constructor(protected interestsService: InterestsService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.update();
  }

  protected update() {
    const previous = this.result();
    const result = this.interestsService.vitalityResult(
      this.survey,
      this.selectedTags,
      this.displayTag,
      this.badgeData
    );
    const {ready, display} = {...result};
    if (previous?.ready != ready) this.onReady.emit(ready);
    if (previous?.display != display) this.onDisplay.emit(display);
    this.result.set(result);
  }
}
