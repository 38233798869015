/* projects/contact/src/lib/components/contact-domain-access/contact-domain-access.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host > div {
  padding: 5px 0 0 24px;
  width: 100%;
}
:host > div > div:first-of-type {
  display: table;
  color: gray;
  line-height: 1em;
  font:
    400 15px Roboto,
    "Helvetica Neue",
    sans-serif;
  width: 100%;
}
:host > div > div:first-of-type > div {
  display: table-row;
}
:host > div > div:first-of-type > div > div {
  display: table-cell;
  padding-top: 5px;
}
:host > div > div:first-of-type > div > div > span.role {
  padding: 0 3px;
  border-radius: 2px;
}
:host > div > div:first-of-type > div > div > span.role.none,
:host > div > div:first-of-type > div > div > span.role.unconfirmed {
  background-color: var(--color-accent);
  color: white;
}
:host > div > div:first-of-type > div > div > span.role.user {
  background-color: var(--color-primary);
  color: white;
}
:host > div > div:first-of-type > div > div > span.role.leader {
  background-color: darkseagreen;
  color: white;
}
:host > div > div:first-of-type > div > div > span.role.admin {
  background-color: darkseagreen;
  color: white;
}
:host > div > div:first-of-type > div > div:first-child {
  padding-right: 5px;
}
:host > div > div:first-of-type > div > div:last-child {
  width: 75%;
}
:host-context(.goodnews) > div > div > div > div > span.role.admin,
:host-context(.goodnews) > div > div > div > div > span.role.leader {
  background-color: lightcoral;
}
/*# sourceMappingURL=contact-domain-access.component-RSRKMBPU.css.map */
