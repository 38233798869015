import {
  AfterViewChecked,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject, InjectionToken,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {APP_ID, Contact, ENVIRONMENT, Logger, PhoneService, Platform, Topic, EMPTY_ARRAY} from 'core';
import {PropertiesService} from "properties";
import {BasicContainerComponent, enterLeaveAnimations, MenuService} from "shared";
import {EmailComposer} from "@ionic-native/email-composer/ngx";
import {CallNumber} from "@ionic-native/call-number/ngx";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ContactService} from "../../contact.service";
import {PaymentService} from "payment";
import {ContactReachValues} from "../../store/models";

export const EMPTY_VALUES:ContactReachValues = {id:undefined,label:undefined,array:EMPTY_ARRAY};

// export declare var google: any;
@Component({
  selector: 'app-contact-reach',
  templateUrl: './contact-reach.component.html',
  styleUrls: ['./contact-reach.component.scss'],
  animations: [enterLeaveAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactReachComponent extends BasicContainerComponent implements AfterViewChecked, OnChanges {

  @Input() contact: Contact;
  @Input() circles: number = 7;
  @Input() valuesArray:  ContactReachValues[] = [];
  @Input() filters: Topic[] = [];
  @Input() selected: Topic = undefined;
  @Output() onFilter = new EventEmitter<Topic>();

  logger = new Logger('ContactReachComponent');

  public values: ContactReachValues = EMPTY_VALUES;
  public valuesIndex:number = undefined;

  protected triggeredContact:Contact;

  constructor(public platform: Platform,
              protected paymentService: PaymentService,
              protected phoneService: PhoneService,
              protected emailComposer: EmailComposer,
              protected callNumber: CallNumber,
              protected dialog: MatDialog,
              public    contactService: ContactService,
              protected translateService: TranslateService,
              protected menuService: MenuService,
              protected propertiesService: PropertiesService,
              protected changeDetector: ChangeDetectorRef,
              @Inject(APP_ID) public appId: number,
              @Inject(ENVIRONMENT) protected environment: any) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewChecked() {
  }

  ngOnChanges(changes: SimpleChanges) {
    let triggerSelected = false;
    if (changes['filters']) {
      const selected = this.filters?.length>0
                      ? this.selected
                        ? this.filters?.find(f=>f.id==this.selected.id) ?? this.filters[0]
                        : this.filters[0]
                      : undefined;
      if (this.selected!=selected) {
        this.selected = selected;
        triggerSelected = true;
      }
    }
    if (changes['valuesArray']) {
      this.valuesIndex = this.valuesArray?.length>0 ? Math.max(0,Math.min(this.valuesIndex??0,this.valuesArray.length-1)): undefined;
      this.values = this.valuesArray?.length>0
        ? this.valuesIndex>=0 && this.valuesIndex<this.valuesArray.length
          ? this.valuesArray[this.valuesIndex]
          : this.valuesArray[this.valuesIndex=0]
        : EMPTY_VALUES;
    }
    if (!!this.contact && !!this.selected && (triggerSelected || this.triggeredContact!==this.contact)) {
      this.triggeredContact = this.contact;
      this.onFilter.emit(this.selected);
    }
  }

  summary(from?:number,to?:number):number {
    let result = 0;
    if (!!this.values) {
      const max = this.values.array?.length ?? 0;
      from = from>0 ? Math.min(max,from) : 0;
      to   = to>=from ? Math.min(max,to) : to<from ? from : max;
      for (let i=from; i<to; i++) {
        result += this.values.array[i];
      }
    }
    return result;
  }

  select(filter: Topic) {
    if (this.selected!=filter) {
      this.selected = filter;
      this.changeDetector.markForCheck();
      this.onFilter.emit(filter);
    }
  }

  nextValues(backward?:boolean) {
    if (this.valuesArray?.length>1) {
      this.valuesIndex += (!!backward) ? -1 : 1;
      if (this.valuesIndex<0) {
        this.valuesIndex = this.valuesArray.length-1;
      } else if (this.valuesIndex>=this.valuesArray.length) {
        this.valuesIndex = 0;
      }
      this.values = this.valuesArray[this.valuesIndex];
    }
  }
}
